import './css/WidgetDetails.min.css';

import React, { useState, Fragment, useEffect } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { Widget } from "../../../domain";
import * as _ from 'lodash';

import { Controlled as CodeMirror } from 'react-codemirror2';
require('codemirror/mode/javascript/javascript.js');
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import moment from 'moment';

import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Translations } from '../../translations/Translations';
import CopyToClipboard from 'react-copy-to-clipboard';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import styles from "./WidgetDetails.module.scss";
import { DateHelper } from '../../utils';

export interface Props {
  closeHandler: () => void,
  saveHandler: (w: any) => void,
  widget: Widget
}

export function WidgetDetails(props: Props) {
  const { closeHandler, saveHandler } = props;
  const [widget, setWidget] = useState(props.widget);
  const tr = Translations.Translate(Translations, 'WidgetManager');

  const setConfigField = (path: string, val: any) => {
    const newConfig = { ...widget.config };
    _.set(newConfig, path, val);
    setWidget({ ...widget, config: newConfig });
  }

  const datePickerForField = (fieldPath: string) => {
    return (
      <div className={'inputButtonWrapper datePickerWrapper'}>

        <Datepicker
          // dateFormat="dd/MM/yyyy HH:mm"
          dateFormat={DateHelper.dailyDatePickerFormatBasedOnLocale()}
          // timeFormat="HH:mm"
          // timeInputLabel="Time:"
          // popperPlacement={'bottom-left'}
          selected={_.get(widget.config, fieldPath) ? new Date(_.get(widget.config, fieldPath)) : null}
          isClearable={true}
          // minDate={new Date()}
          // required={true}
          placeholderText={tr('selectDate')}
          customInput={<Input type={'text'} invalid={_.get(widget.config, fieldPath) ? false : true} disabled />}
          onChangeRaw={(e) => {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            return false;

          }}
          onChange={(d: Date, e: any) => {
            let dateStr = '';
            if (d) {
              try {
                const m = moment(d);
                if (m.isValid()) {
                  dateStr = m.format('YYYY-MM-DD');
                  // console.log('dateStr: ', dateStr);
                  setConfigField(fieldPath, dateStr);
                }

              } catch (error) {
                setConfigField(fieldPath, '');
              }
            } else {
              setConfigField(fieldPath, '');
            }

            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            return false;
          }}
          // showTimeInput
          showMonthDropdown
          showYearDropdown
          // shouldCloseOnSelect={false}
          dropdownMode="select"
        />
      </div>
    )
  }

  const toJSON = (str: string) => {
    try {
      const json = JSON.parse(str);
      if (json && typeof json === 'object') {
        return json;
      }
    } catch (error) {
      return null;
    }
    return null;
  }

  const renderRegistrationWidgetConfigSection = (w: Widget) => {
    return (
      <Fragment>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Start:</Label>
              {datePickerForField('startDate')}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>End:</Label>
              {datePickerForField('endDate')}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Available spots:</Label>
              <Input type='number' value={widget.config.available_spots || ''} onChange={(e) => setWidget({ ...w, config: { ...w.config, available_spots: e.target.value ? _.toNumber(e.target.value) : 0 } })} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}><hr /></Col>
          <Col md={12}>
            <FormGroup>
              <Label>
                <Toggle checked={widget.config.generateTicket ?? false} onChange={(e) => setWidget({ ...w, config: { ...w.config, generateTicket: e.target.checked } })} />
                Generate ticket
              </Label>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label>
                <Toggle style={{ verticalAlign: 'bottom', marginRight: '10px' }} checked={widget.config.showParticipantsInApp ?? false} onChange={(e) => setWidget({ ...w, config: { ...w.config, showParticipantsInApp: e.target.checked } })} />
                Show participants in App
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </Fragment>
    )
  }
  const renderGenericWidgetConfigSection = (w: Widget) => {
    let config = w.config
    if (!_.isString(config)) {
      config = JSON.stringify(widget.config, null, 2);
    }
    return (
      <Row>
        <Col md={12}>
          <FormGroup>
            <Label>Config</Label>
            {/* <Input type='textarea' value={config || ''} onChange={(e) => setWidget({ ...w, config:  e.target.value})} /> */}
            <CodeMirror
              value={config || ''}
              className={''}
              options={{
                // mode: {
                //   name: isCSSTemplate()?"css":"htmlmixed",
                //   tags: {
                //     style: [["type", /^text\/(x-)?scss$/, "text/x-scss"],
                //             [null, null, "css"]],
                //     custom: [[null, null, "customMode"]]
                //   }
                // },
                theme: "material",
                lineNumbers: true,
                // extraKeys: {
                //   "F6": function(cm) {
                //     cm.setOption("fullScreen", !cm.getOption("fullScreen"));
                //     setFullscreen(!fullscreen);
                //   },
                //   // "Esc": function(cm) {
                //   //   if (cm.getOption("fullScreen")){
                //   //     cm.setOption("fullScreen", false);
                //   //     setFullscreen(false);
                //   //   } 
                //   // }
                // }
              }}
              onBeforeChange={(editor, data, value) => {
                setWidget({ ...widget, config: toJSON(value) || value });
              }}
              editorDidMount={(editor) => {
                editor.refresh();
              }}
              onRenderLine={(editor, line, element) => {

              }}
            />
          </FormGroup>
        </Col>

      </Row>
    )
  }

  const renderWidgetConfigSection = (w: Widget) => {
    switch (w.type) {
      case 'event-registration':
        return renderRegistrationWidgetConfigSection(w);
      default:
        return renderGenericWidgetConfigSection(w);
    }
  }

  const closeBtn = <img className="close" onClick={closeHandler} src="/assets/icons/bt-close.svg" />

  const isNewWidget = (widget.id == '-1');


  return (
    <Modal isOpen={true} centered toggle={() => props.closeHandler()} id="WidgetDetails">
      <ModalHeader><div className='headerTitle'><img src="/assets/icons/16/widget.svg" style={{ marginRight: '10px' }}></img> {isNewWidget ? 'Create' : 'Edit'} {widget.type == 'event-registration' ? `event registration` : widget.type} widget </div>{closeBtn}</ModalHeader>
      <ModalBody>
        <Form className={styles.widgetForm}>
          <Row>
            {!isNewWidget &&
              <Col md={12}>
                <FormGroup>
                  <Label>ID:</Label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input defaultValue={widget.id || ''} readOnly={true} style={{ flex: 1, marginRight: '10px' }} />
                    <CopyToClipboard text={widget.id}>
                      <Button color={'secondary'}>copy</Button>
                    </CopyToClipboard>
                  </div>
                </FormGroup>
              </Col>
            }
            <Col md={12}>
              <FormGroup>
                <Label>Title:</Label>
                <Input value={widget.title || ''} onChange={(e) => setWidget({ ...widget, title: e.target.value })} />
              </FormGroup>
            </Col>
            {/* <Col md={6}>
              <FormGroup>
                <Label>Type</Label>
                <Input defaultValue={widget.type || ''} readOnly={true} />
              </FormGroup>
            </Col> */}
          </Row>
          {renderWidgetConfigSection(widget)}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button style={{ flex: 1 }} size={'lg'} outline color="secondary" onClick={closeHandler}>Cancel</Button>
        <Button style={{ flex: 1 }} size={'lg'} color="primary" onClick={() => saveHandler(widget)}>Save</Button>{' '}
      </ModalFooter>
    </Modal>
  )
}