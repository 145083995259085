import React from 'react';
import { ControllerRenderProps, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import { Input, Label } from 'reactstrap';
import { NcSlideConfigFormData, NcSlideshowFormData } from '../subpages/NcSlideshows/NcSlideshowDetails';
import { NarrowcastingHelper } from '../NarrowcastingHelper';

interface Props {
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  fieldName: FieldPath<NcSlideConfigFormData>
  activeIndex: number
  label?: string
  placeholder?: string
  inputWrapperClass?: string
  min?: number
  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSlideConfigNumberInput = ({ field, fieldName, activeIndex, label, placeholder, inputWrapperClass, min }: Props) => {

  const minimumAcceptedValue = min || 0;

  const val = NarrowcastingHelper.getValueOfSlideConfigField(field, activeIndex, fieldName) || '';

  return (
    <>
      <Label>{label}</Label>
      <div className={inputWrapperClass}>
        <Input
          placeholder={placeholder}
          type={'number'}
          min={minimumAcceptedValue}
          value={val !== undefined ? ensureMinValue(_.toNumber(val), minimumAcceptedValue) : ''}
          onChange={(e) => {
            NarrowcastingHelper.setValueOfSlideConfigField(field, activeIndex, fieldName, ensureMinValue(_.toNumber(e.target.value), minimumAcceptedValue))
          }} />
      </div>
    </>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const ensureMinValue = (val: number, min?: number): number => {
  const minimumAcceptedValue = min || 0;
  const v = _.toNumber(val || minimumAcceptedValue);
  return v < minimumAcceptedValue ? minimumAcceptedValue : v;
}
