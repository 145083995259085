import React from 'react'

import { media } from '@imgzine/imgzine-core-frontend';

import styles from './DetailMediaUnsupportedObject.module.scss'

const DetailMediaUnsupportedObject = (props: { mediaObj: media.GenericMedia }) => {
  return <div>
    <div className={styles.obj}>{props.mediaObj.type}</div>
  </div>
}

export default DetailMediaUnsupportedObject