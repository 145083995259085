import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent, Row, Col, Button, Label, Input } from 'reactstrap';
import classnames from 'classnames';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { OrtecLoader, EdErrorHandler, Swal } from '../../../../../widgets'
import { Profile, WarningAnalysis, ErrorAnalysis, ErrorDate, AudienceToErrorDatesBreakdown } from '../../../../../../domain';
import moment from 'moment';
import * as Api from '../../../../../api';
import * as _ from 'lodash';

import { Translations } from '../../../../../translations';
import { CheckCard, Props as CardProps, CheckProps } from './CheckCard';

import * as ch from '../../../../../utils/checksHelper';

const NUMBER_OF_DAYS = 60;
const TITLE = `Last ${NUMBER_OF_DAYS} days`;

export interface Props {
  magazine: number,
  profile: Profile,
  loading: boolean,
  records: any[],
  // location?: string,
}

export class Check_lastXDays extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  check = (): CheckProps => {
    const { records } = this.props;

    if (_.isEmpty(records)) {
      return ch.noDataCard();
    }

    const getLastDates = ch.getLastDates(NUMBER_OF_DAYS);

    const errorRecords: any[] = [];
    const warningRecords: any[] = [];

    const missingDates = _.filter(getLastDates, (d) => {
      const recs = _.filter(records, (r) => r.range === d);
      if (_.isEmpty(recs)) {
        return true;
      }
      else {
        _.map(recs, (r) => {
          if (r.log) {
            try {
              const log = JSON.parse(r.log);
              if (log.warning) {
                warningRecords.push(r);
              } else {
                errorRecords.push(r);
              }
            } catch (error) {
              errorRecords.push(r);
            }
          }
        });
      }
    })


    if (_.isEmpty(missingDates) && _.isEmpty(errorRecords) && _.isEmpty(warningRecords)) { //no errors or warnings
      return {
        icon: 'success',
        result: 'Passed!',
        descr: `Aggregator has run successfully for the past ${NUMBER_OF_DAYS} days`,
        warnings: [],
        errors: [],
        renderErrors: this.renderErrors,
        suggestionHandler: this.suggestionHandler,
      }
    } else { //errors or/and warnings

      const icon = _.isEmpty(errorRecords) && _.isEmpty(missingDates) ? 'success' : 'error';
      const result = _.isEmpty(errorRecords) && _.isEmpty(missingDates) ? 'Passed!' : 'Failed!';
      const descr = _.isEmpty(errorRecords) && _.isEmpty(missingDates) ? `Aggregator has run successfully but with warnings for the past ${NUMBER_OF_DAYS} days.` : `Aggregator has run with errors for the past ${NUMBER_OF_DAYS} days.`;
      return {
        icon,
        result,
        descr,
        warnings: warningRecords,
        errors: [...errorRecords, ...missingDates],
        renderErrors: this.renderErrors,
        suggestionHandler: this.suggestionHandler,
      }
    }

  }

  calcCardProps = (): CardProps => {
    return {
      title: TITLE,
      explanation: `Checks if aggregator has run successfully for the past ${NUMBER_OF_DAYS} days.`,
      loading: this.props.loading,
      ...this.check()
    }
  }

  renderErrors = (warnings: any[], errors: any[]) => {

    if (_.isEmpty(warnings) && _.isEmpty(errors)) {
      return null;
    }
    const hasErrors = !_.isEmpty(errors);

    const alertClass = !hasErrors ? 'warning' : 'danger';

    const warningAnalysis = ch.warningsAnalysis(warnings);


    const errorAnalysis = ch.errorAnalysis(errors);

    return (
      <div className={`moreContainer alert alert-${alertClass}`}>
        {ch.missingDatesError(errorAnalysis.missingDates)}
        {ch.errorDatesError(errorAnalysis)}
        {ch.noStatsWarning(warningAnalysis, hasErrors)}
        {ch.noUsersWarning(warningAnalysis, hasErrors)}
      </div>
    )

  }

  audienceFormatter = (audience: number) => {
    const { profile } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');
    const audiences: any[] = [];
    if (audience === 1) {
      return tr('all_employees');
    }
    return _.get(_.find(profile.audiences, ['id', audience]), 'label', audience);
  }

  suggestionHandler = (warnings: any[], errors: any[]) => {
    const errorAnalysis = ch.errorAnalysis(errors);
    const warningAnalysis = ch.warningsAnalysis(warnings);

    const html = `
      <div>
        ${ch.missingDatesErrorSuggestion(errors, this.props.magazine)}
        ${ch.errorDatesErrorSuggestion(errorAnalysis, this.audienceFormatter.bind(this), this.props.magazine)}
        ${ch.noStatsWarningSuggestion(warningAnalysis, this.audienceFormatter.bind(this), this.props.magazine)}
        ${ch.noUsersWarningSuggestion(warningAnalysis.noUsers.audiences, this.audienceFormatter.bind(this), this.props.magazine)}
      </div>
    `
    Swal.fire({
      title: TITLE,
      type: 'info',
      width: '600px',
      html: html,
      showCloseButton: true,
    })
  }


  render() {
    const tr = Translations.Translate(Translations, 'Monitoring');
    const { loading, title, explanation, icon, result, descr, warnings, errors, renderErrors, suggestionHandler } = this.calcCardProps();

    return (
      <CheckCard
        loading={loading}
        title={title}
        explanation={explanation}
        icon={icon}
        result={result}
        descr={descr}
        warnings={warnings}
        errors={errors}
        renderErrors={renderErrors}
        suggestionHandler={suggestionHandler}
      />
    );
  }
}

export default Check_lastXDays;
