import { AxiosPromise } from 'axios';
import { dashApi, wrapAxios } from "../api";
import { VimeoVideo } from '../components/MediaManagerSelector/domain';

export const getVideos = (magazine: number): AxiosPromise<{ data: VimeoVideo[] }> => {
  return dashApi.get(magazine, `vimeo/videos?per_page=100`)
}

export const deleteVideo = (magazine: number, id: string): AxiosPromise<void> => {
  return dashApi.delete(magazine, `vimeo/videos/${id}`);
}
export const uploadVideo = (magazine: number, file: File): AxiosPromise<{ upload_link: string }> => {
  return dashApi.post(magazine, `vimeo/upload`, { name: file.name, size: file.size });
}