import _ from "lodash";
import { Profile } from "../../domain";


function isModuleDisabled(profile: Profile, module: string): boolean {
    const disabledModules = _.get(profile, `edConfig.disabledModules`, []);
    return _.includes(disabledModules, module);
}

export const ConfigHelper = {
    isModuleDisabled
}