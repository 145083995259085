import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent, Row, Col, Input, Button } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';

import classnames from 'classnames';
import './css/HistoryTable.min.css'
import { Profile } from '../../../../../domain';
import * as _ from 'lodash';
import * as Api from '../../../../api';
import { Translations } from '../../../../translations';
import { DateHelper } from '../../../../utils';

export interface Props {
  magazine: string,
  profile: Profile,
  clickNotificationHandler: (notification: number) => void
}
export interface State {
  notifications?: any[],
}

export class HistoryTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.getNotificationsHistory();

  }
  getNotificationsHistory() {
    Api.getNotificationsHistory(parseInt(this.props.magazine)).then((resp) => {

      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ notifications: [] });
      }
      this.setState({ notifications: resp.data ? this.filterNotificationsBasedOnAccessRights(resp.data) : [] });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }
  filterNotificationsBasedOnAccessRights = (notifications: any[]) => { // editor should see only notifications sent to audiences that he has access to
    const { profile } = this.props;
    const auds = _.map(profile.audienceIds, _.toNumber);
    if (profile.globalAudience) {
      auds.push(1);
    }
    return _.filter(notifications, (n) => {
      return _.some(n.audiences, (a) => _.includes(auds, a));
    })
  }

  renderPaginationPanel(props: any) {
    let max = 1;
    let min = 1;
    if (this.state.notifications) {
      max = (this.state.notifications.length / 10) + 1;
    }
    const tr = Translations.Translate(Translations, 'Tables');

    return (
      <Fragment>
        <Col md={8} className='dropup'>{props.components.pageList} {props.components.sizePerPageDropdown}</Col>
        <Col md={4}>
          <div className="goToPageDiv">
            <input id="goToPageInput" onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                let val = e.target.value;
                if (val < min) {
                  val = min;
                } else if (val > max) {
                  val = max;
                }
                props.changePage(val);
                e.target.value = '';
              }
            }} className='form-control' type="number" placeholder={tr('go_to_page')} style={{ width: 'auto' }} />
            <button className="btn" onClick={(e) => {
              var k: any = document.getElementById('goToPageInput');
              let val = k.value;
              if (val < min) {
                val = min;
              } else if (val > max) {
                val = max;
              }
              props.changePage(val);
              k.value = ''
            }}>{'>'}</button>
          </div>
        </Col>
      </Fragment>
    );
  }
  messageFormatter(cell: any) {
    try {
      const message = JSON.parse(cell);
      return (<div className='ellipsisDiv' data-tippy-content={message.message} data-tippy-placement="left">{message.message}</div>);
    } catch (error) {
      return (<div className='ellipsisDiv'>{cell}</div>);
    }
  }
  dateFormatter(cell: any) {
    try {
      return <>{DateHelper.dateTimeToLocaleString(cell)}</>;
    } catch (error) {
      return <>{cell}</>;
    }
  }
  statusFormatter(cell: any) {
    const tr = Translations.Translate(Translations, 'PushNotifications');
    try {
      const status = parseInt(cell);
      switch (status) {
        case 9:
          return `<i class="fa fa-circle status-sent" aria-hidden="true" data-tippy-content="${tr("sent")}" data-tippy-placement="right"></i>`;
        case 1:
          return `<i class="fa fa-circle status-scheduled" aria-hidden="true" data-tippy-content="${tr("scheduled")}" data-tippy-placement="right"></i>`;
        case 0:
          return `<i class="fa fa-circle status-notSent" aria-hidden="true" data-tippy-content="${tr("cancelled")}" data-tippy-placement="right"></i>`;
        default:
          return <>{status}</>;
      }
    } catch (error) {
      return <>{cell}</>;
    }
  }
  numberFormatter(cell: any, row: any) {
    return cell ? cell : '-';
  }

  requestSuccessesFor: number[] = [];
  successesFormatter(cell: any, row: any) {
    if (cell != undefined) {
      return <>{cell}</>;
    } else {
      if (!_.includes(this.requestSuccessesFor, row.id)) {
        this.requestSuccessesFor.push(row.id);
        Api.getNotification(parseInt(this.props.magazine), row.id).then((resp) => {
          const notification = resp.data;
          if (!notification) {
            this.changeNotificationSuccesses(row.id, '-');
          }
          const successses = _.get(notification, 'successes', '-');
          this.changeNotificationSuccesses(row.id, successses);

        }).catch((error) => {
          console.log(`Error in getting notification results: ${error.response.data.error}`);
          this.changeNotificationSuccesses(row.id, '-');
        })
      }
      return (<i className="fa fa-spinner fa-spin"></i>);
    }

  }
  changeNotificationSuccesses(id: string, successes: string) {
    this.setState({
      notifications: _.map(this.state.notifications, (n) => {
        if (n.id !== id) {
          return n;
        } else {
          return {
            ...n,
            successes
          }
        }
      })
    })
  }
  onRowClick(row: any) {
    this.props.clickNotificationHandler(row.id);
  }

  render() {
    const { magazine, profile } = this.props;
    const { notifications } = this.state;
    const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>
    const tr = Translations.Translate(Translations, 'PushNotifications');
    const trt = Translations.Translate(Translations, 'Tables');

    const options: any = {
      defaultSortName: 'notification_datetime',
      defaultSortOrder: 'desc',
      onRowClick: this.onRowClick.bind(this),
      // paginationShowsTotal:true,
      // toolBar: this.renderToolbar.bind(this),
      paginationPanel: this.renderPaginationPanel.bind(this),
      sizePerPage: 25,
      sizePerPageList: [
        // {text:`10 ${trt('entries')}`,value: 10},
        { text: `25 ${trt('entries')}`, value: 25 },
        { text: `50 ${trt('entries')}`, value: 50 },
        { text: `100 ${trt('entries')}`, value: 100 },
        // {text:'All entries',value: articles?articles.length:0},
      ]
    }
    return (
      <div id="HistoryTable" className={''}>
        {Translations.EnableTranslations(Translations)}

        <div className="titleDiv">{tr('overview')}</div>
        <div className="tableDiv">
          {notifications ?
            <BootstrapTable ref="notificationsHistoryTable" data={notifications} bordered={false} version='4' /*height='285px'*/ options={options} pagination hover>
              <TableHeaderColumn isKey dataField='id' dataSort={false} hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='message' dataSort={false} dataFormat={this.messageFormatter.bind(this)}></TableHeaderColumn>
              <TableHeaderColumn dataField='notification_datetime' dataSort={true} dataAlign='center' dataFormat={this.dateFormatter.bind(this)} width='150px'><img data-tippy-content={tr("scheduledFor")} src="/assets/icons/16/calendar.svg" /></TableHeaderColumn>
              <TableHeaderColumn dataField='status' dataSort={true} dataAlign='center' dataFormat={this.statusFormatter.bind(this)} width='50px'><img data-tippy-content={tr("status")} src="/assets/icons/16/stats.svg" /></TableHeaderColumn>
              <TableHeaderColumn dataField='successes' dataSort={true} dataAlign='center' dataFormat={this.successesFormatter.bind(this)} width='100px'><img data-tippy-content={tr("devices")} src="/assets/icons/16/devices.svg" /></TableHeaderColumn>
              {/* <TableHeaderColumn dataField='failures' dataSort={true} dataAlign='center' dataFormat={this.numberFormatter.bind(this)}><img data-tippy-content={"Failures"} src="/assets/icons/16/failure.svg"/></TableHeaderColumn> */}
            </BootstrapTable>
            : loader
          }
        </div>
      </div>
    );
  }
}

export default HistoryTable;
