import React from 'react';
import { Label } from 'reactstrap';
import styles from './CmsConfirmationSection.module.scss';
import Toggle from 'react-toggle';
import Creatable from 'react-select/creatable';
import { ArticleConfirmation } from '../../../../../../../domain';

interface Props {
  magazine: number,
  confirmation?: ArticleConfirmation
  variationLanguage?: string,
  onChange: (c: ArticleConfirmation) => void
}


const CONFIRMATION_DEFAULT_OPTIONS: Array<{ label: string, value: string }> = [
  { value: 'I read this', label: 'I read this' },
  { value: 'I hereby confirm', label: 'I hereby confirm' },
  { value: 'I will attend', label: 'I will attend' },
]


export const CmsConfirmationSection = ({ magazine, confirmation, variationLanguage, onChange }: Props) => {
  return (
    <div className={styles.CmsConfirmationSection}>
      <div className={styles.sectionTitle}>
        <Label>Confirmation</Label>
        <Toggle disabled={variationLanguage ? true : false} checked={confirmation?.enabled || false} onChange={(e) => { onChange({ label: confirmation?.label || 'I read this', enabled: e.target.checked }) }} />
      </div>
      {confirmation?.enabled &&
        <div>
          <Creatable value={{ value: confirmation.label, label: confirmation.label }} onChange={(newValue) => { onChange({ enabled: confirmation?.enabled || false, label: newValue?.value || 'I read this' }) }} options={variationLanguage ? [] : CONFIRMATION_DEFAULT_OPTIONS} />
        </div>
      }
    </div>
  )
}
