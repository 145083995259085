import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';

import './css/SelectTypeModal.min.css';
import { Translations } from '../../../../translations';


export interface Props {
  magazine: string,
  // profile: Profile
  // statsFilter: StatsFilter,
  // articleId: number,
  createHandler: (source: number, srctype?: string) => void
  closeHandler: () => void
}

export class SelectTypeModal extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { magazine } = this.props;
    const tr = Translations.Translate(Translations, 'Forms');

    // const loader = <div className="loaderContainer"><div className="loaderWrapper" ><i className="fa fa-spinner fa-spin"></i></div></div>
    const closeBtn = <img className="close" onClick={this.props.closeHandler.bind(this)} src="/assets/icons/bt-close.svg" />
    return (
      <Modal isOpen={true} centered id="SelectTypeModal" size={'lg'}>
        {Translations.EnableTranslations(Translations)}
        <ModalHeader><span className="headerTitle"><img src="/assets/icons/24/publications_blue.svg" style={{ marginRight: '10px' }}></img> {tr('selectFormType')}</span> {closeBtn}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <div className="formTypeTile" onClick={(e) => { this.props.createHandler(-1, 's_poll') }}>
                <div className="logo"><img src="/assets/icons/48/icon_poll.svg" style={{}} /></div>
                <div className="header">{tr('poll')}</div>
                <div className="descr">{tr('pollDescr')}</div>
              </div>
            </Col>
            <Col md={4}>
              <div className="formTypeTile" onClick={(e) => { this.props.createHandler(-1, 'survey') }}>
                <div className="logo"><img src="/assets/icons/48/icon_survey.svg" style={{}} /></div>
                <div className="header">{tr('survey')}</div>
                <div className="descr">{tr('surveyDescr')}</div>
              </div>
            </Col>
            <Col md={4}>
              <div className="formTypeTile" onClick={(e) => { this.props.createHandler(-1, 'quiz') }}>
                <div className="logo"><img src="/assets/icons/48/icon_quiz.svg" style={{}} /></div>
                <div className="header">{tr('quiz')}</div>
                <div className="descr">{tr('quizDescr')}</div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        {/* <ModalFooter>
            <Button color="primary" block size="lg" onClick={this.createHandler.bind(this)} style={{textTransform:'uppercase'}}>{tr('create')}</Button>
          </ModalFooter> */}
      </Modal>
    )
  }
}

export default SelectTypeModal;
