import React, { useMemo } from 'react';
import styles from '../NcSlidesManager.module.scss';
import { NcSlideshowFormData } from '../NcSlideshowDetails';
import { ControllerRenderProps, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import _ from 'lodash';
import { Profile } from '../../../../../../domain';
import { NcSlideConfigTextInput } from '../../../components/NcSlideConfigTextInput';
import { TippyReact } from '../../../../../components';
import classNames from 'classnames';

interface Props {
  magazine: number,
  profile: Profile,
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  activeIndex: number
  setError: UseFormSetError<NcSlideshowFormData>
  clearErrors: UseFormClearErrors<NcSlideshowFormData>
}

export const NcSlideTypeConfig_Weather = ({ magazine, profile, field, activeIndex, setError, clearErrors }: Props) => {

  const slide = _.find(field.value, (s) => s.sort === activeIndex);
  const location = _.get(slide?.config, 'location');

  useMemo(() => {
    if (!location) {
      setError(`slides.${activeIndex}.config.location`, { type: 'weatherSlideValidation', message: 'No selected location for weather slide' });
    } else {
      clearErrors(`slides.${activeIndex}.config.location`);
    }
  }, [location, activeIndex, setError, clearErrors])


  return (
    <>
      <div className={styles.flexRow}>
        <TippyReact content={`A location should be entered!`} config={{ placement: 'bottom', disabled: location ? true : false }}>

          <div style={{ flex: 1 }} className={classNames({ [styles.requiredValueMissing]: !location ? true : false })}>
            <NcSlideConfigTextInput
              {...{ field, activeIndex }}
              fieldName={`location`}
              label={`Location:`}
              placeholder={`City name or an Open Weather Map City ID`}
            />
          </div>
        </TippyReact>
      </div>
    </>
  )
};
