import React from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';

import '../css/SideBarItem.min.css';
import ReportedContentsWarningIcon from './ReportedContentsWarningIcon/ReportedContentsWarningIcon';
import { Profile } from '../../domain';
export interface Props {
  text: string,
  router?: string,
  magazine: number,
  profile: Profile
  iconName: string,
  icon?: any
}

function getIconPath(iconName: string, active: boolean) {
  return `/assets/navBarIcons/${iconName}${active ? '_active' : ''}.svg`;
}

export const SideBarItem = (props: Props) => {
    const { iconName, router, magazine, profile } = props;

    const showWarningIcon = iconName === "content" && profile.edConfig?.reportedContent ? true : false;

    return (
      <NavLink className="nav-link" to={router ? router : `/${magazine}/`} exact={true}>
        <div className="SideBarItem">
            <Switch>
              <Route path={router} render={() => {
                return <SideBarItemIcon {...{...props, isActive: true, showWarningIcon }}/>
              }} />
              <Route path='/' render={() => {
                return <SideBarItemIcon {...{...props, isActive: false, showWarningIcon }}/>
              }} />
            </Switch>
        </div>
      </NavLink>
    );
}

export default SideBarItem;

type SidebarItemIconProps = Props & {isActive: boolean, showWarningIcon: boolean}

const SideBarItemIcon = (props: SidebarItemIconProps) => {
  const { text, icon, iconName, magazine, isActive, showWarningIcon} = props;

  return  <>
    {showWarningIcon && <ReportedContentsWarningIcon magazine={magazine} invertedColors={isActive} isInSidebar={true}/>}
    <div className="iconContainer">
      {icon ? icon : <img src={getIconPath(iconName, isActive)} />}
    </div>
    <div className="textContainer">{text}</div>
  </>
}