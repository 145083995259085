import React from 'react';
import styles from './NoSlideshowsBox.module.scss';

interface Props {
  prefix?: string
}

export const NoSlideshowsBox = ({ prefix }: Props) => {
  return (
    <div className={styles.NoSlideshowsBox}>
      No {prefix} slideshows yet
    </div>
  )
}
