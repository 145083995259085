import React from 'react';
import { ModalHeader } from 'reactstrap';
import GenericModalCloseButton from './GenericModalCloseButton';

interface Props {
  onClose?: () => void
  title: string | JSX.Element
  icon?: string
  faIcon?: string
}

const GenericModalHeader = (props: Props) =>
  <ModalHeader
    toggle={props.onClose}
    close={props.onClose && <GenericModalCloseButton onClose={props.onClose} />}
  >
    <div className='headerTitle'>
      {props.icon && <img src={props.icon} style={{ marginRight: '10px' }} />}
      {props.faIcon && <i className={`fa fa-${props.faIcon}`} style={{ marginRight: '10px', fontSize: '18px' }} />}
      {props.title}
    </div>
  </ModalHeader>


export default GenericModalHeader