import React, { useState } from 'react';
import styles from '../NcSettings.module.scss';
import { FullPageModal, FullPageModalHeader, FullPageModalContent } from '../../../../../components/FullPageModal';
import { Media, Profile } from '../../../../../../domain';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EdErrorHandler, OrtecLoader } from '../../../../../widgets';
import { API } from '../../../../../apis';
import galleryStyles from './NcSettingsGallery.module.scss';
import _ from 'lodash';
import { NcSettingsGalleryItem } from './NcSettingsGalleryItem';
import { MediaSelector, TippyReact } from '../../../../../components';
import { Button } from 'reactstrap';
import { NC_DEFAULT_SETTINGS_GALLERY } from '../NarrocastingSettingsDefaults';
import { NcSettingsGalleryMedia } from '../../../NarrowcastingDomain';

interface NcSettingsGalleryFormData {
  gallery: NcSettingsGalleryMedia
}
interface Props {
  magazine: number,
  profile: Profile,
  onClose: () => void,

}

export const NcSettingsGallery = ({ magazine, profile, onClose }: Props) => {

  const [mediaSelector, setMediaSelector] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const ncGalleryQuery = useQuery({
    queryKey: ['ncGallery', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcSettingsGallery(magazine);
        return data;
      } catch (error) {
        if (error?.response?.status === 422) { //not valid schema. probably legacy record.
          return [...NC_DEFAULT_SETTINGS_GALLERY];
        } else if (error?.response?.status === 404) {
          return [...NC_DEFAULT_SETTINGS_GALLERY];
        } else {
          EdErrorHandler(error, `getting NC settings gallery`);
        }
      }
    }
  });


  const ncGalleryUpdateMutation = useMutation({
    mutationKey: ['ncGalleryUpdate', magazine],
    mutationFn: (g: NcSettingsGalleryMedia) => API.narrowcasting.saveNcSettingsGallery(magazine, g),
    onMutate: async (newGallery: NcSettingsGalleryMedia) => {
      await queryClient.cancelQueries({ queryKey: ['ncGallery', magazine] });
      const previousGallery = queryClient.getQueryData(['ncGallery', magazine]);
      queryClient.setQueryData(['ncGallery', magazine], (g: NcSettingsGalleryMedia) => [...newGallery]);
      return { previousGallery }
    },
    onError: (error, newGallery, context) => {
      EdErrorHandler(error, `saving gallery`);
      queryClient.setQueryData(['ncGallery', magazine], context?.previousGallery);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ncGallery', magazine] })
    }
  });

  const isLoading = ncGalleryQuery.isFetching || ncGalleryUpdateMutation.isLoading;

  const onMediaHandlerSelect = async (mediaUrls: string[]) => {
    ncGalleryUpdateMutation.mutate(_.uniq([...(ncGalleryQuery.data || []), ...mediaUrls]));
    setMediaSelector(false);
  }

  const onMediaRemove = async (mediaUrl: string) => {
    ncGalleryUpdateMutation.mutate(_.filter(ncGalleryQuery.data, (u) => u !== mediaUrl));
  }

  return (
    <FullPageModal>
      <FullPageModalHeader isLoading={ncGalleryQuery.isLoading} onClose={onClose}>
        <div className={styles.modalTitle}>Gallery</div>
        <TippyReact config={{ disabled: true }} content={''}>
          <div><Button color={'primary'} onClick={() => { setMediaSelector(true) }}>add images</Button></div>
        </TippyReact>
      </FullPageModalHeader>
      <FullPageModalContent isLoading={ncGalleryQuery.isLoading}>
        <div className={galleryStyles.NcSettingsGallery}>
          <div className={galleryStyles.guidelinesWrapper}>
            These images are used when there is no high quality image available. Recommended size is 1920x1080 (full HD).
          </div>
          <div className={galleryStyles.mediaItemsWrapper}>
            {
              _.map(ncGalleryQuery.data, (u) => {
                return (
                  <NcSettingsGalleryItem
                    key={u}
                    magazine={magazine}
                    url={u}
                    onRemove={onMediaRemove}
                  />
                )
              })
            }
          </div>
          {mediaSelector &&
            <MediaSelector {...{ magazine, profile }}
              closeHandler={() => { setMediaSelector(false) }}
              selectHandler={onMediaHandlerSelect}
              restrictedMediaTypes={['images']}
              dedicatedBucket={`${magazine}_nc`}
              dedicatedPart='protected'
              multipleSelection
            />}
        </div>
      </FullPageModalContent>
    </FullPageModal>
  )
}
