import React, { Component } from 'react';
import { Input, Row, Col } from 'reactstrap';
import moment from 'moment';
import { StatsFilter, Article } from '../../../domain';
import * as Api from '../../api';
import * as _ from 'lodash';
import { ArticleComments } from './ArticleComments';

import './css/CommentsAccordion.min.css';

export interface Props {
  magazine: string
  article?: Article
  deleteSelectedCommentsHanlder: (selectedComments: string[]) => void,
  opened?: boolean,
  toggleCollapsible?: () => void,

}

// export interface State {
//   opened: boolean
// }


export class CommentsAccordion extends Component<Props> {
  constructor(props: Props) {
    super(props);
    // this.state = {
    //   opened: false
    // }
  }


  calcTotalComments = () => {
    const { article } = this.props;

    return article && article.comments ? _.filter(article.comments, (c) => c.status === 1).length : 0;
  }

  // toggleOpened = () => {
  //   this.setState({opened:!this.state.opened});
  // }

  render() {
    const { article } = this.props;
    return (
      <div id="CommentsAccordion" className={`accordion ${this.props.opened ? 'opened' : ''}`}>
        <div className='accordion-header' onClick={() => { if (this.props.toggleCollapsible) { this.props.toggleCollapsible() } }}>Current Comments ({this.calcTotalComments()}) <i className={`fa fa-chevron-${this.props.opened ? 'down' : 'up'}`}></i></div>
        <div className='accordion-body'>
          <ArticleComments article={article} deleteSelectedCommentsHanlder={this.props.deleteSelectedCommentsHanlder.bind(this)} />
        </div>
      </div>
    );
  }
}

export default CommentsAccordion;
