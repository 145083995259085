import { Widget } from "../../domain";
import { dashApi, wrapAxios } from "../api";

export const getWidgets = (magazine: number): Promise<any> => {
  return dashApi.get(magazine, `widgets`)
}
export const saveWidget = (magazine: number, widget: Widget): Promise<any> => {
  return dashApi.post(magazine, `widgets/save`, { widget })
}
export const deleteWidget = (magazine: number, id: string): Promise<any> => {
  return dashApi.delete(magazine, `widget/${id}`)
}
export const getRegisteredUsers = (magazine: number, id: string): Promise<any> => {
  return dashApi.get(magazine, `widget/${id}/registeredUsers`)
}

export const getConfirmedUsers = (magazine: number, id: string): Promise<any> => {
  return dashApi.get(magazine, `widget/${id}/confirmedUsers`)
}