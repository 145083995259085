import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { Profile, StatsFilter } from '../../../domain';
import { Row, Col } from 'reactstrap';
import StatBox from './StatBox';
import * as Api from '../../api';

import './css/EngagementPromotersStats.min.css';
import * as _ from 'lodash';
import styles from './EngagementPromotersStats.module.scss';
import { AccessRightsHelper } from '../../utils';
import { AudienceEstimatedSizeModal } from '../../components';
export interface Props {
  magazine: string,
  profile: Profile,
  statsFilter: StatsFilter
}
export interface State {
  totalActivePublications?: number | string,
  totalNewsletters?: number | string,
  totalPushNotifications?: number | string,
  // totalActiveForms?: number | string,
  totalUsersInAudience?: number | string,
  totalArticleViews?: number | string,
  uniqueVisitors?: number | string,
  audienceKpi?: number
  settingAudienceEstimatedSize?: boolean
}

export class EngagementPromotersStats extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    this.calcChartStats('totalActivePublications');
    // this.calcChartStats('totalNewsletters');
    // this.calcChartStats('totalPushNotifications');
    // this.calcChartStats('totalActiveForms');
    this.calcChartStats('totalArticleViews');
    this.calcChartStats('uniqueVisitors');
    this.calcAudienceKpi();
  }
  calcChartStats(chart: string) {
    Api.loadChartData(chart, this.props.statsFilter, this.props.magazine).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ [chart]: '?' });
      }
      this.setState({ [chart]: resp.data.data ? resp.data.data : '-' });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }
  calcAudienceKpi() {
    Api.loadAudienceKpi(parseInt(this.props.magazine), this.props.statsFilter.audience).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting kpi data');
        this.setState({ audienceKpi: undefined });
      }
      this.setState({ audienceKpi: resp.data.kpi ? resp.data.kpi : 0 });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }

  calcReach(totalUsersInAudience: number | string | undefined, uniqueVisitors: number | string | undefined) {
    return '-';
    // if( totalUsersInAudience === undefined || uniqueVisitors === undefined){
    //   return undefined;
    // }
    // if(!totalUsersInAudience || !_.isNumber(totalUsersInAudience) ||  !_.isNumber(uniqueVisitors) ){
    //   return '-'
    // }
    // return ((uniqueVisitors/totalUsersInAudience)*100).toFixed(1)+'%';
  }

  calcReachBasedOnKPI(kpi: number | undefined, uniqueVisitors: number | string | undefined) {
    if (kpi === undefined || uniqueVisitors === undefined) {
      return undefined;
    }
    if (!kpi || !_.isNumber(kpi) || !_.isNumber(uniqueVisitors)) {
      return '-'
    }
    return Math.round((uniqueVisitors / kpi) * 100) + '%';
  }

  renderEstimatedSizeIcon() {
    const isAdmin = (AccessRightsHelper.getUserRole(this.props.profile) === 'admin') ? true : false;
    if (!isAdmin) {
      return null;
    }

    return (
      <div className={styles.EstimatedSizeIcon} onClick={() => this.openAudienceEstimatedSizeModal()}><img src={`/assets/icons/gear.svg`} /></div>
    )
  }

  openAudienceEstimatedSizeModal() {
    this.setState({ settingAudienceEstimatedSize: true });
  }
  closeAudienceEstimatedSizeModal() {
    this.setState({ settingAudienceEstimatedSize: false });
    this.calcAudienceKpi();
  }

  render() {
    const { totalArticleViews, totalActivePublications, uniqueVisitors, audienceKpi, settingAudienceEstimatedSize } = this.state;
    return (
      <div id="EngagementPromotersStats" className="boxContainer">
        <Row>
          <Col md={3} xl={6}><StatBox scalableValue text={'active_publications'} value={!_.isArray(totalActivePublications) ? totalActivePublications : '-'} icon={'24/publications'} /></Col>
          <Col md={3} xl={6}><StatBox scalableValue text={'unique_users'} value={uniqueVisitors} icon={'24/users'} /></Col>
          <Col md={3} xl={6} className="forceIconSize"><StatBox scalableValue text={'article_views'} value={!_.isArray(totalArticleViews) ? totalArticleViews : '-'} icon={'views'} /></Col>
          <Col md={3} xl={6}><StatBox scalableValue text={'reach'} value={this.calcReachBasedOnKPI(audienceKpi, uniqueVisitors)} icon={'24/reach'} /> {this.renderEstimatedSizeIcon()}</Col>
        </Row>
        <div className="horizontalDevider"></div>
        <div className="verticalDevider"></div>
        {settingAudienceEstimatedSize && this.props.statsFilter.audience && <AudienceEstimatedSizeModal magazine={_.toNumber(this.props.magazine)} profile={this.props.profile} audience={this.props.statsFilter.audience} savedEstimatedSize={audienceKpi} onClose={this.closeAudienceEstimatedSizeModal.bind(this)} />}
      </div>
    );
  }
}

export default EngagementPromotersStats;
