import React from 'react';
import styles from './MediaBucketExplorerGridFolder.module.scss'
import classNames from 'classnames';
import { FolderI } from './MediaBucketExplorer';

interface Props {
  folder: FolderI
  onSelect: () => void
}

export default function MediaBucketExplorerGridFolder(props: Props) {
  const { folder, onSelect } = props
  const { folderName, filesCount } = folder
  return <button onClick={onSelect} className={styles.folder}>
    <div className={styles.iconWrapper}>
      <img src="/assets/icons/48/folder.svg" />
      <div className={classNames(styles.counter, styles.textBold)}>{filesCount} </div>
    </div>
    <span className={classNames(styles.label, styles.textBold)}>{folderName}</span>
  </button>
}