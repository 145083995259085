import React from 'react';
import { Profile, Source } from '../../../../../domain';
import _ from 'lodash';
import { Composed, Feed, RSS, Reporter, Tags, Unsupported } from './types';

export interface SourceTypeSettingsProps {
  magazine: number,
  source: Source,
  onChangeSource: (source: Source) => void
  profile: Profile,
}

export const SourceTypeSettings = (props: SourceTypeSettingsProps) => {
  if (_.endsWith(props.source.srctype, 'feed')) { //Feed source type
    return (<Feed {...props} />)
  }

  switch (props.source.srctype) {
    case 'RSS':
      return (<RSS {...props} />)
    case 'tags':
      return (<Tags {...props} />)
    case 'reporter':
      return (<Reporter {...props} />)
    case 'composed':
      return (<Composed {...props} />)
    default:
      return (<Unsupported {...props} />)
  }
}
