import React from 'react';
import { NcPlaylistGroup } from '../../NarrowcastingDomain';
import styles from './NcPlaylistGroupBox.module.scss';
import _ from 'lodash';
import { NoSlideshowsBox } from './NoSlideshowsBox';
import { NcPlaylistSlideshowBox } from './NcPlaylistSlideshowBox';

interface Props {
  group: NcPlaylistGroup
}

export const NcPlaylistGroupBox = ({ group }: Props) => {
  return (
    <div key={group.id} className={styles.NcPlaylistGroupBox}>
      <div className={styles.groupTitle}>{group.label}</div>
      {_.isEmpty(group.slideshows)
        && <NoSlideshowsBox prefix={'group'} />}
      {_.map(group.slideshows, (gs) => {
        return (
          <NcPlaylistSlideshowBox slideshow={gs} />
        )
      })}
    </div>
  )
}
