import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import cn from 'classnames'
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Label from 'reactstrap/lib/Label';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Toggle from 'react-toggle';
import Badge from 'reactstrap/lib/Badge';
import Alert from 'reactstrap/lib/Alert';

import { EdErrorHandler, LoadingButton, SwalError, OrtecLoader, ToggleButton } from '../../../../../widgets';
import { API } from '../../../../../apis';
import '../css/ChannelDetails.min.css';
import styles from './ChannelDetails.module.scss';
import { ChannelDetail, NEW_CHANNEL_ID, ROOT_NODE_ID } from '../domain';
import { MagazineLanguages, AppMeta, Profile } from '../../../../../../domain';
import { Audience } from '../../Audiences/domain';
import { TippyReact, QrModal } from '../../../../../components';
import { CreatableSingleSelect, CreatableMultiSelect, string2SelectOption } from '../../../../../components/ReactSelect/ReactSelect';
import ChannelAudiencesSelect from './ChannelDetailsAudiences';
import ChannelDetailsTargetting from './ChannelDetailsTargetting';
import SourceSelector from '../SourceSelector';
import { ChannelDetailsTranslations } from './ChannelDetailsTranslations';
import { ChannelsGraph, findChildren, getUsedChannelTypes, getUsedTags } from '../cicChannelsHelper';
import { ChannelAction } from '../Channels';

import { ciHelper } from '../ChannelInfoPairs';
import { PageSwitch } from '../../../../../components/PageSwitch/PageSwitch';
import { Translations } from '../../../../../translations';
import ChannelDetailsChannelInfo from './ChannelDetailsChannelInfo';
import { generateUniversalLink } from '../../../../../utils/genericHelper';
import { ImmutArray } from '../../../../../utils/ImmutablesHelper';
import { NotificationCenterChannelConfig, DEFAULT_CHANNEL_CONFIG } from '../../NotificationCenter/domain';

interface Props {
  profile: Profile,
  magazine: number
  channelId: number | null
  onClose: (save?: boolean) => void
  channelsGraph: ChannelsGraph
  audiences: Audience[]
  languages: MagazineLanguages,
  onChannelAction: (action: ChannelAction, channelId: number) => void
  parents: number[] //inlt for create mode
}


export default function ChannelDetails({ magazine, channelId, onClose, channelsGraph, audiences, languages, parents, onChannelAction, profile }: Props) {
  const usedTags = React.useMemo(() => getUsedTags(channelsGraph), [channelsGraph])
  const usedChannelTypes = React.useMemo(() => getUsedChannelTypes(channelsGraph), [channelsGraph])

  const [saveLoading, setSaveLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const childrenChannels = React.useMemo(() => channelId === null ? [] : findChildren(channelsGraph, channelId), [channelsGraph, channelId])
  const parentChannels = React.useMemo(() => parents.length === 0 ? [ROOT_NODE_ID] : parents, [parents])


  const [channel, setChannel] = useState<ChannelDetail>({
    id: NEW_CHANNEL_ID,
    name: '',
    label: '',
    channelType: '',
    audiences: [],
    sources: [],
    maxVersion: null,
    minVersion: null,
    targeting: [],
    notifications: { ...DEFAULT_CHANNEL_CONFIG }
  });

  const [notFound, setNotFound] = useState(false);
  const [showTranslations, setShowTranslations] = useState(false);

  const isNewChannel = (channelId === null) ? true : false;
  const loading = detailsLoading

  const showTargetingAlert = shouldShowTargetingAlert(channel);

  useEffect(
    () => {
      if (channelId == null) {
        setDetailsLoading(false);
      } else {
        getChannelDetails(channelId)
      }
    },
    [channelId]
  )

  const getChannelDetails = async (channelId: number) => {
    try {
      setDetailsLoading(true);
      const { data: channel } = await API.channels.getChannel(magazine, channelId);
      if (!channel.id || _.toNumber(channel.id) !== channelId) {
        setNotFound(true);
      } else {
        setChannel({ ...channel, combineAudiencesWithAnd: channel.combineAudiencesWithAnd ? true : false });
      }
    } catch (error) {
      EdErrorHandler(error, `getting channel details`);
    } finally {
      setDetailsLoading(false);
    }
  }

  const onCancel = () => onClose(false)

  const onSave = async () => {
    if (!channel.name || !channel.label) {
      SwalError.fire({
        title: 'Error!',
        text: `Channel's Name and Label can not be empty!`,
      });
      return;
    }
    try {
      setSaveLoading(true)
      const channelToBeSaved: ChannelDetail = {
        ...channel,
        channelType: channel.channelType || '',
        channelInfo: ciHelper.removeChannelInfoEmptyValues(channel),
        translations: ciHelper.removeChannelInfoTranslationsForNonExistingFields(channel)
      };
      if (channel.id === NEW_CHANNEL_ID) {
        await API.channels.createChannel(magazine, channelToBeSaved, parentChannels[0] || ROOT_NODE_ID)
      } else {
        await API.channels.saveChannel(magazine, channelToBeSaved);
      }
      setSaveLoading(false);
      onClose(true)
    } catch (e) {
      EdErrorHandler(e, `saving channel details`);
      setSaveLoading(false);
    }

  }

  const updateChannel = (partialChannel: Partial<ChannelDetail>) => {
    const c: ChannelDetail = { ...channel, ...partialChannel }
    setChannel(c)
  }

  const updateChannelNotificationsField = (field: keyof NotificationCenterChannelConfig, value: boolean) => {
    const notifications: NotificationCenterChannelConfig = {
      ...channel.notifications,
      [field]: value
    }
    updateChannel({ notifications });
  }

  const tr = Translations.Translate(Translations, 'Channels');

  const universalLink = generateUniversalLink(profile.appMeta, `/c/${channelId}`)

  return (
    <div id={'ChannelDetails'} >
      <div className={styles.header}>
        <img src="/assets/icons/bt_close_bigger.svg" onClick={onCancel} style={{ cursor: 'pointer' }} />
        {loading || notFound ? '' :
          <>
            {isNewChannel
              ? <div className={styles.headerTitle}>{tr('createChannel')}</div>
              : <>
                <div className={styles.idContainer}>
                  <div className={styles.headerLabel}>ID</div>
                  <div className={styles.headerVal}> {channelId}</div>
                </div>
                <div className={styles.linkContainer}>
                  <div className={styles.headerLabel}>UNIVERSAL LINK</div>
                  <div className={styles.headerVal}> <a href={universalLink} target={'_blank'}>{universalLink}</a></div>
                </div>
                <div className={styles.flexSpacer}></div>
                <ChannelQRCode universalLink={universalLink} />
              </>
            }
            <LoadingButton loading={saveLoading} onClick={() => { onSave() }} text={isNewChannel ? `Create` : `Save`} />
          </>
        }
      </div>
      <div className={cn('content', 'simple')}>
        {loading ? <OrtecLoader /> :
          (notFound || !channel?.id) ? <NotFoundMessage /> :
            <div style={{ width: '100%' }}>
              <PageSwitch optionLeft={'settings'} optionRight={'translations'} isRightActive={showTranslations} onClick={() => { setShowTranslations(!showTranslations); }} />
              {showTranslations
                ? <ChannelDetailsTranslations
                  magazine={magazine}
                  languages={languages}
                  channel={channel}
                  updateChannel={updateChannel}
                />
                : <>
                  <div className='channelSettings'>
                    <div className='channelDetailsDiv'>
                      <Row>
                        <Col md={4}>
                          <Label>Name:</Label>
                          <Input
                            value={channel.name} onChange={(e) => { updateChannel({ name: e.target.value }) }} />
                        </Col>
                        <Col md={4}>
                          <Label>Label:</Label>
                          <Input value={channel.label} onChange={(e) => { updateChannel({ label: e.target.value }) }} />
                        </Col>
                        <Col md={4}>
                          <Label>Type:</Label>
                          <CreatableSingleSelect
                            isClearable
                            value={channel.channelType || undefined}
                            onChange={(channelType) => { updateChannel({ channelType }) }}
                            options={usedChannelTypes.map(string2SelectOption)}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '20px' }}>
                        <Col md={12}>
                          <Label>Tags:</Label>
                          <CreatableMultiSelect
                            value={channel.tags || []}
                            onChange={(tags) => { updateChannel({ tags }) }}
                            options={usedTags.map(string2SelectOption)}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '20px' }}>
                        <Col md={4}>
                          <div>
                            <Label style={{ marginRight: '10px' }}>{tr('parents')} ({parentChannels.filter(c => c !== ROOT_NODE_ID).length}): </Label>
                            {channelId !== null && <Button size='sm' onClick={() => { onChannelAction(ChannelAction.EDIT_PARENTS, channelId) }}>edit</Button>}
                          </div>
                          <div>
                            {parentChannels.map(c => <ChannelBadge key={c} channelId={c} channelsGraph={channelsGraph} />)}
                          </div>
                        </Col>
                        {channelId !== null &&
                          <Col md={8}>
                            <Label style={{ marginRight: '10px' }}>{tr('children')} ({childrenChannels.filter(c => c !== ROOT_NODE_ID).length}):</Label>
                            <Button size='sm' onClick={() => { onChannelAction(ChannelAction.EDIT_CHILDREN, channelId) }}>edit</Button>
                            <div>
                              {childrenChannels.map(c => <ChannelBadge key={c} channelId={c} channelsGraph={channelsGraph} />)}
                            </div>
                          </Col>
                        }
                      </Row>
                      {profile.edConfig?.notificationCenter &&
                        <Row style={{ marginTop: '20px' }}>
                          <Col md={12}>
                            <Label>Notifications:</Label>
                            <div className={styles.channelNotificationsContainer}>
                              <div className={styles.channelNotificationsToggleLabel}>Enabled:</div>
                              <Toggle checked={channel.notifications.enabled ? true : false} onChange={(e) => { updateChannelNotificationsField('enabled', e.target.checked) }} />
                              <div className={styles.channelNotificationsToggleLabel}>User Configurable:</div>
                              <Toggle disabled={channel.notifications.enabled ? false : true} checked={channel.notifications.userConfigurable ? true : false} onChange={(e) => { updateChannelNotificationsField('userConfigurable', e.target.checked) }} />
                              <div className={styles.channelNotificationsToggleLabel}>Default Value:</div>
                              <Toggle disabled={channel.notifications.enabled ? false : true} checked={channel.notifications.defaultValue ? true : false} onChange={(e) => { updateChannelNotificationsField('defaultValue', e.target.checked) }} />
                            </div>
                          </Col>
                        </Row>
                      }
                      <Row style={{ marginTop: '20px' }}>
                        <Col md={8}>
                          <Label>Audiences:</Label>
                          <div>
                            <ChannelAudiencesSelect
                              value={channel.audiences || []}
                              onChange={(audiences) => { updateChannel({ audiences }) }}
                              allAudiences={audiences}
                            />
                            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                              <Toggle checked={channel?.combineAudiencesWithAnd ? true : false} onChange={(e) => { updateChannel({ combineAudiencesWithAnd: e.target.checked ? true : false }) }} />
                              <span style={{ marginLeft: '10px', fontSize: '14px' }}>{tr('partOfAllAudiences')}</span>
                            </div>
                          </div>
                        </Col>
                        <Col md={2} className={'verticalCentered'}>
                          {!showTargetingAlert ? null :
                            <Alert color={'warning'} data-tippy-content={'This channel has been already targeted to specific groups, but it is not associated with the respective audience. Please, consider to target to the correct audience before saving.'}><i className={'material-icons'}>info</i>Attention please</Alert>
                          }
                        </Col>
                        <Col md={2} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                          <ToggleButton onClick={() => { setShowAdvanced(!showAdvanced) }} color={''} active={showAdvanced}>
                            <div>
                              more settings  <img style={{ marginLeft: "5px", verticalAlign: "text-bottom" }} src={`/assets/icons/16/advanced_${showAdvanced ? 'grey' : 'blue'}.svg`} />
                            </div>
                          </ToggleButton>
                        </Col>
                      </Row>
                      {showAdvanced &&
                        <>
                          <Row style={{ marginTop: '30px' }}>
                            <Col md={12}>
                              <ChannelDetailsChannelInfo profile={profile} magazine={magazine} value={channel.channelInfo || ''} onChange={(channelInfo) => { updateChannel({ channelInfo }) }} />
                            </Col>
                          </Row>
                          <ChannelDetailsTargetting channel={channel} updateChannel={updateChannel} />
                        </>
                      }
                    </div>

                    <Row style={{ marginTop: '20px' }}>
                      <Col md={12}>
                        <SourceSelector
                          title={tr('sources')}
                          magazine={String(magazine)}
                          profile={profile}
                          selected={channel.sources}
                          selectionHandler={(sourceId: number, isSelected: boolean) => {
                            const sources = isSelected
                              ? ImmutArray.push(channel.sources || [], sourceId)
                              : ImmutArray.removeBy(channel.sources || [], s => s === sourceId)
                            updateChannel({ sources })
                          }}
                          selectAllHandler={(ids: number[]) => { updateChannel({ sources: ids }) }}
                          clearAllHandler={() => { updateChannel({ sources: [] }) }}
                        />
                      </Col>
                    </Row>
                  </div>
                </>

              }
            </div>

        }
      </div>
    </div>
  )
}

const NotFoundMessage = () => <div className={'notFoundContainer'}><div className="alert alert-danger">{`Channel not found`}</div></div>;

function shouldShowTargetingAlert(channel: ChannelDetail) {
  if (channel.groups && (_.isEmpty(channel.audiences) || _.includes(channel.audiences, 1))) {
    return true;
  }
  return false;
}

const ChannelBadge = ({ channelId, channelsGraph }: { channelId: number, channelsGraph: ChannelsGraph }) => {
  const channel = _.get(channelsGraph, channelId, undefined)?.channel
  const text = channelId === ROOT_NODE_ID
    ? 'Top level'
    : channel
      ? `${channelId} - ${channel.name} - ${channel.label}`
      : channelId
  return <Badge style={{ margin: '4px', opacity: channelId === ROOT_NODE_ID ? 0.5 : undefined }} className={styles.badge}>{text}</Badge>
}

const ChannelQRCode = ({ universalLink }: { universalLink: string }) => {
  const [qrModal, setQrModal] = useState(false);
  const openQRModal = () => { setQrModal(true); }
  const closeQRModal = () => { setQrModal(false); }
  return <div className={styles.qrContainer}>
    <i className={cn(`fa fa-qrcode`, styles.qrToggler)} data-tippy-content={'Click to see QR code'} onClick={openQRModal}></i>
    {qrModal && <QrModal universalLink={universalLink} closeModal={closeQRModal} type={'channel'} noVisibleLink />}
  </div>
}