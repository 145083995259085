import _ from 'lodash'
import { Profile } from '../../../../../domain';
import { CICChannel, ROOT_NODE_ID } from './domain';

interface CICChannelWithParents {
  parents: number[]
  channel: CICChannel
}

export interface ChannelsGraph {
  nodes: Record<number, CICChannelWithParents>
  rootChildren: CICChannel[]
}


export function createChannelsGraph(channels: CICChannel[]): ChannelsGraph {
  const nodes: Record<number, CICChannelWithParents> = {}
  const toProcess: CICChannel[] = []

  function getOrCreateNode(channel: CICChannel) {
    const found = nodes[channel.id]
    if (found) {
      return found
    } else {
      const n: CICChannelWithParents = { channel, parents: [] }
      nodes[channel.id] = n
      return n
    }
  }

  for (const child of channels) {
    const childNode = getOrCreateNode(child)
    childNode.parents = _.uniq([...childNode.parents, ROOT_NODE_ID])
    toProcess.push(child)
  }

  while (true) {
    const channel = toProcess.pop()
    if (!channel) {
      break
    }

    const node = getOrCreateNode(channel)
    for (const child of node.channel.childChannels || []) {
      const childNode = getOrCreateNode(child)
      childNode.parents = _.uniq([...childNode.parents, channel.id])
      toProcess.push(child)
    }
  }
  return { nodes, rootChildren: channels }
}


export function getUsedChannelTypes(graph: ChannelsGraph): string[] {
  return _.compact(_.uniq(
    _.map(graph.nodes, node => node.channel.channelType))
  )
}

export function getUsedTags(graph: ChannelsGraph): string[] {
  return _.compact(_.uniq(_.flatten(
    _.map(graph.nodes, node => node.channel.tags)
  )))
}

export function findAncestors(graph: ChannelsGraph, initialChannelId: number): number[] {
  const ancs: number[] = []
  const toProcess: number[] = [initialChannelId]

  while (true) {
    const channelId = toProcess.pop()
    if (!channelId) {
      break
    }
    const ps = _.difference(findParents(graph, channelId), ancs)
    ancs.push(...ps)
    toProcess.push(...ps)
  }
  return ancs
}

export function findDescendants(graph: ChannelsGraph, initialChannelId: number): number[] {
  const ancs: number[] = []
  const toProcess: number[] = [initialChannelId]

  while (true) {
    const channelId = toProcess.pop()
    if (!channelId) {
      break
    }
    const ps = _.difference(findChildren(graph, channelId), ancs)
    ancs.push(...ps)
    toProcess.push(...ps)
  }
  return ancs
}


export function findParents(graph: ChannelsGraph, channelId: number): number[] {
  if (channelId === ROOT_NODE_ID) {
    return []
  }
  const node = graph.nodes[channelId]
  if (!node) {
    console.warn(`channelId ${channelId} not found`)
    return []
  }
  return node.parents
}

export function findChildren(graph: ChannelsGraph, channelId: number): number[] {
  if (channelId === ROOT_NODE_ID) {
    return graph.rootChildren.map(c => c.id)
  }
  const node = graph.nodes[channelId]
  if (!node) {
    console.warn(`channelId ${channelId} not found`)
    return []
  }
  return (node.channel.childChannels || []).map(c => c.id)
}


export function findChildrenWithExactNumberOfParents(graph: ChannelsGraph, channelId: number, exactNumberOfParents: number): number[] {
  return _
    .filter(graph.nodes, c => (
      exactNumberOfParents === c.parents.length && _.includes(c.parents, channelId)
    ))
    .map(c => c.channel.id)
}

export function formatChannelTitle(profile: Profile, row: CICChannel): string {

  const { edConfig } = profile;
  if (!edConfig) {
    return row.name;
  }
  switch (edConfig.channelGraphName) {
    case 'nameOnly':
      return row.name || row.label || '';
    case 'labelOnly':
      return row.label || row.name || '';
    case 'nameLabel':
      return `${row.name || ''} ${row.label ? `(${row.label})` : ''}`;
    case 'labelName':
      return `${row.label || ''} ${row.name ? `(${row.name})` : ''}`;
    default:
      return row.name;
  }
  // {channel.id} - {channel.name} ({channel.label})
}
