import React from 'react';
import styles from './NcSettingsTile.module.scss';
import classNames from 'classnames';

interface Props {
  title: string,
  description: string,
  additionalInfo?: JSX.Element,
  disabled?: boolean,
  onClick: () => void,
}

export const NcSettingsTile = ({ title, description, additionalInfo, disabled, onClick }: Props) => {

  return (
    <div className={classNames(styles.NcSettingsTile, { [styles.disabledTile]: disabled })} onClick={onClick}>
      <div className={styles.title}>{title}</div>
      <div className={styles.descr}>{description}</div>
      {additionalInfo && <div className={styles.additionalInfo}>{additionalInfo}</div>}
    </div>
  )
}
