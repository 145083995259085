import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './css/QrModal.min.css'

import { Translations } from '../../translations';
import { EdErrorHandler, OrtecLoader } from '../../widgets';

declare const $: any;

export interface Props {
  universalLink: string,
  type: string,
  closeModal: () => void,
  noVisibleLink?: boolean

}

const getTypeTranslation = (type: string): string => {
  const tr = Translations.Translate(Translations, 'QrModal');

  switch (type) {
    case 'article':
      return tr('article');
    case 'form':
      return tr('form');
    default:
      return type;
  }

}


const buildQrCode = (el: HTMLElement, universalLink: string) => {
  if (!el) {
    console.error('could not find el')
    return;
  }

  $(el).html('');
  const size = 200;
  const text = universalLink

  $(el).qrcode({
    size: size,
    render: 'canvas',
    text: text,
    quiet: 5,
    mode: 4,
    ecLevel: 'H',
    minVersion: 1
  });

  const canvas: any = el.childNodes[0];
  var ctx = canvas.getContext('2d');
  ctx.fillStyle = 'black';
  ctx.fillText('powered by', 55, size - 3);
  ctx.font = 'bold 12px tahoma';
  ctx.fillStyle = "#354052";
  ctx.fillText('ORTEC', 55 + 55, size - 3);;

}

export const QrModal = ({ universalLink, type, noVisibleLink, closeModal }: Props) => {

  const qrCodeDivRef = React.useCallback(el => {
    if (el !== null) {
      buildQrCode(el, universalLink);
    }
  }, []);

  const tr = Translations.Translate(Translations, 'QrModal');

  return (
    <Modal isOpen={true} centered toggle={() => closeModal()} id="QrModal" size={noVisibleLink ? 'md' : 'lg'}>
      {Translations.EnableTranslations(Translations)}
      <ModalHeader toggle={() => closeModal()}><i className={'fa fa-qrcode'}></i> QR Code {!noVisibleLink && `& Universal Link`}</ModalHeader>
      <ModalBody>
        <Fragment>
          <div className={'qrCodeContainer'}>
            <div ref={qrCodeDivRef} id='qrCodeDiv'></div>
          </div>
          <div className={'messageContainer'}>{`${tr('scanToVisit')} ${getTypeTranslation(type)} ${!noVisibleLink ? tr('clickLink') : ''}`}</div>
          {!noVisibleLink &&
            <div className={'linkContainer'}><a href={universalLink} target={'_blank'}>{universalLink}</a></div>
          }
        </Fragment>
      </ModalBody>

    </Modal>
  );
}

//  key={props.universalLink} ensures new component mount if universalLink changes
export default (props: Props) => <QrModal key={props.universalLink} {...props} />;
