import { MediaHelper as CoreMediaHelper } from '@imgzine/imgzine-core-frontend';
import _ from 'lodash';
import React from 'react';
import { DateHelper, MediaHelper } from '../../../../../../utils';
import { NlArticle, NlArticleVariation, NlTemplateConfiguration_Content } from '../../domain';
import styles from './ArticlePreview.module.scss';
import { TextWithUIPlaceholder } from './TextWithUIPlaceholder';

interface Props {
  oneColumn?: boolean
  noImage?: boolean
  configuration: NlTemplateConfiguration_Content,
  article?: NlArticle
}

export const ArticlePreview = ({ oneColumn, noImage, configuration, article: baseArticle }: Props) => {

  const article = getArticleVariation(baseArticle, configuration.language) || baseArticle;
  const articleBestImage = baseArticle ? getBestImageUrl(baseArticle.magazine, article) : undefined;

  return (
    <div className={styles.ArticlePreview} key={`preview-${article?.id || ''}`}>
      <TextWithUIPlaceholder
        configuration={configuration.articleDate}
        defaultText={article?.cdate ? DateHelper.dateToLocaleString(article.cdate) : DateHelper.dateToLocaleString(new Date())}
      />
      {/** if(article) I care about the title. if not I am on the template builder, so I want to show the default text */}
      {(!article || article?.title) && <TextWithUIPlaceholder
        configuration={configuration.articleTitle}
        defaultText={article?.title || 'This is the title of the article to test the styling'}
        bold
      />}
      <div style={{ display: 'flex', flexDirection: oneColumn ? 'column' : 'row-reverse', gap: 8 }}>
        {!noImage && (!article || articleBestImage) &&
          <div className={styles.imageContainer} style={{ flex: oneColumn ? 'unset' : 1, backgroundImage: `url("${articleBestImage || '/assets/defaultImg.jpg'}")` }}></div>
        }
        <div className={styles.description} style={{ flex: oneColumn ? 'unset' : 2 }}>
          <TextWithUIPlaceholder
            configuration={configuration.articleDescription}
            defaultText={article ? _.truncate(article?.descr_scrubbed || article?.content_scrubbed, { length: (oneColumn ? 150 : 300), separator: ' ' }) : 'This is the Article description text. You can change its style on the right hand side panel.It will be loaded from the articles you select when creating a new newsletter.'}
          />
        </div>
      </div>
      <div className={styles.readMore}>
        <TextWithUIPlaceholder
          configuration={configuration.readMoreLink}
        />
      </div>
    </div>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const getBestImageUrl = (magazine: number, article: NlArticle | NlArticleVariation | undefined): string | undefined => {
  if (!article) {
    return undefined;
  }

  try {
    const media = CoreMediaHelper.parseMediaArray(_.isString(article.media) ? article.media : JSON.stringify(article.media));
    const bestImage = CoreMediaHelper.getBestImageLink(media);

    return bestImage?.url ? MediaHelper.proxify(magazine, bestImage.url) : undefined;
  } catch (error) {
    console.warn(error);
    return undefined;
  }

}

const getArticleVariation = (article: NlArticle | undefined, langKey: string): NlArticleVariation | undefined => {
  if (!article || _.isEmpty(article.variations)) {
    return undefined;
  }
  return _.find(article.variations, (v) => v.variation === `lang:${langKey}`) || article.variations[0];
}