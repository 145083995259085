
import React, { useState } from 'react';
import { Variations } from "../../../../../../../domain";
import { Label, Input, Button, Modal, ModalHeader, ModalBody, Row, ModalFooter, Col, FormFeedback } from 'reactstrap';
import _ from 'lodash';
import { Translations } from '../../../../../../translations';

export interface Props {
    feedVariations: Variations,
    closeHandler: () => void
    createHandler: (lang: string) => void
    articleVariations: any
    variationsLangs?: string[]
}


export const SelectVariationModal = (props: Props) => {
    const { feedVariations, createHandler, articleVariations } = props;

    const existingLanguages = props.variationsLangs || _.map(articleVariations, (v) => v.variation.split(':')[1]);

    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [showInvalid, setShowInvalid] = useState(false);

    const tr = Translations.Translate(Translations, 'Cms');

    const existsAlready = (key: string): boolean => {
        return _.includes(existingLanguages, key);
    }

    const closeBtn = <img className="close" onClick={props.closeHandler} src="/assets/icons/bt-close.svg" />

    const onCreate = () => {
        if (selectedLanguage !== '') {
            createHandler(selectedLanguage)
        } else {
            setShowInvalid(true);
        }
    }

    const invalid = (selectedLanguage === '');

    return (
        <Modal isOpen={true} centered id="SelectTypeModal">
            {Translations.EnableTranslations(Translations)}
            <ModalHeader><span className="headerTitle"><img src="/assets/icons/24/publications_blue.svg" style={{ marginRight: '10px' }}></img> {tr('select new variation language')}</span> {closeBtn}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={2}><Label style={{ lineHeight: '38px' }}>Language:</Label></Col>
                    <Col md={10}>
                        <Input invalid={showInvalid && invalid} type="select" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                            <option value=''>Select a language</option>
                            {_.map(feedVariations.languages, (l) => {
                                if (l.enabled && l.key !== feedVariations.primary) {
                                    return <option disabled={existsAlready(l.key)} key={l.key} value={l.key}>{_.capitalize(l.name)}</option>
                                }
                            })}
                        </Input>
                        {showInvalid && invalid && <FormFeedback>You have to select a language</FormFeedback>}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" block size="lg" onClick={() => onCreate()} style={{ textTransform: 'uppercase' }}>{tr('create')}</Button>
            </ModalFooter>
        </Modal>
    )
}
