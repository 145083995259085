import { z } from "zod"

export type ArticleRetention = { maxNumber: number } | { maxAgeDays: number };

export interface FeedBasicProps {
  id: number,
  label: string,
  apikey: string,
  displayName?: string,
  articleRetention?: ArticleRetention,
}

export interface CMSFeedDetails {
  id: number,
  name: string,
  displayName: string,
  editors: string[]
  variations: Variations | null
  categories: number
  deletable: boolean
}

export interface Variations {
  enabled: boolean,
  primary: string,
  languages: VariationLanguage[]
}

export interface VariationLanguage {
  key: string,
  name: string,
  enabled: boolean
}

export const DEFAULT_VARIATIONS: Variations = {
  enabled: false,
  primary: 'en',
  languages: [
    {
      key: 'en',
      name: 'english',
      enabled: true
    },
    {
      key: 'nl',
      name: 'dutch',
      enabled: false
    },
    {
      key: 'de',
      name: 'german',
      enabled: false
    },
    {
      key: 'es',
      name: 'spanish',
      enabled: false
    },
  ]
}

// ─── CMS Items ───────────────────────────────────────────────────────────────


const CmsStageSchema = z.enum(['published', 'drafts']);

export type CmsStage = z.infer<typeof CmsStageSchema>;

const CmsItemDataSchema = z.object({
  title: z.string().optional(),
  cdate: z.string().optional(),
  author: z.string(),
  tags: z.array(z.string()),
  status: z.number().min(0).max(1),
  id: z.union([z.string(), z.number()]).optional()
});

export type CmsItemData = z.infer<typeof CmsItemDataSchema>;


export const CmsItemSchema = z.object({
  feed: z.number(),
  external_id: z.string(),
  variation: z.string(),
  target_uid: z.string().optional(),
  data: CmsItemDataSchema, //not .passthrough() here as we want to keep the get request size low
  kind: z.enum(['article', 'event'])
});

export type CmsItem = z.infer<typeof CmsItemSchema>;



// ─── CMS Detailed Items ────────────────────────────────────────────────────────────────


const CmsMediaObjectMetaSchema = z.object({
  alt: z.string().optional()
});
export type CmsMediaObjectMeta = z.infer<typeof CmsMediaObjectMetaSchema>;

const CmsMediaObjectSchema = z.object({
  type: z.string(),
  featured: z.literal(1).optional(),
  url: z.string().optional(),
  w: z.number().optional(),
  h: z.number().optional(),
  link: z.string().optional(),
  filename: z.string().optional(),
  extension: z.string().optional(),
  mimeType: z.string().optional(),
  meta: CmsMediaObjectMetaSchema.optional()
});
export type CmsMediaObject = z.infer<typeof CmsMediaObjectSchema>;

const CmsArticleMetaObjectSchema = z.object({
  cmsKind: z.literal('article'),
});
export type CmsArticleMetaObject = z.infer<typeof CmsArticleMetaObjectSchema>;

const CmsAgendaItemMetaObjectSchema = z.object({
  cmsKind: z.literal('event'),
  start: z.string(),
  end: z.string(),
  location: z.string(),
  speaker: z.string(),
  timezone: z.string(),
  tz: z.number(),
  userLocalTime: z.boolean(),
  all_day: z.boolean().optional()
});
export type CmsAgendaItemMetaObject = z.infer<typeof CmsAgendaItemMetaObjectSchema>;

const CmsItemMetaObjectSchema = z.discriminatedUnion('cmsKind', [CmsArticleMetaObjectSchema, CmsAgendaItemMetaObjectSchema]);
export type CmsItemMetaObject = z.infer<typeof CmsItemMetaObjectSchema>;

const CmsItemDetailedDataSchema = CmsItemDataSchema.extend({
  content_scrubbed: z.string().optional(),
  content: z.string().optional(),
  // tags: z.array(z.string()),
  intro: z.string().optional(),
  mdate: z.string(),
  link: z.string(),
  media: z.array(CmsMediaObjectSchema),
  confirmation: z.object({
    enabled: z.boolean(),
    label: z.string()
  }).optional(),
  meta: CmsItemMetaObjectSchema.optional(),
});
export type CmsItemDetailedData = z.infer<typeof CmsItemDetailedDataSchema>;


export const CmsItemDetailedSchema = CmsItemSchema.extend({
  data: CmsItemDetailedDataSchema,
});
export type CmsItemDetailed = z.infer<typeof CmsItemDetailedSchema>;


const CmsItemDetailedWithOtherVariationsSchema = CmsItemDetailedSchema.extend({
  otherVariations: z.array(CmsItemDetailedSchema),
});
export type CmsItemDetailedWithOtherVariations = z.infer<typeof CmsItemDetailedWithOtherVariationsSchema>;