export interface NotificationCenterChannelConfig {
  enabled: boolean,
  userConfigurable: boolean,
  defaultValue: boolean
}

export type ChannelConfigField = keyof NotificationCenterChannelConfig;

export interface NotificationCenterChannelDetails {
  id: number,
  label: string | null,
  name: string | null,
  type: string | null,
}

export interface NotificationCenterChannelWithConfig extends NotificationCenterChannelDetails {
  config?: NotificationCenterChannelConfig
}

export interface NotificationCenterConfigOptions {
  disableAndroidNotifications?: boolean,
  includeAnonymous?: boolean,
  enableCommentNotifications?: boolean,
  enableMentionNotifications?: boolean,
  activeSinceDays?: number,
  uids?: string[]
}


export const DEFAULT_CHANNEL_CONFIG: NotificationCenterChannelConfig = {
  enabled: false,
  userConfigurable: false,
  defaultValue: false,
}