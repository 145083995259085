import classNames from 'classnames';
import React from 'react';
import { MediaHelper } from '../../../../../utils';

import styles from './MediaItem.module.scss';

interface Props {
  magazine: number,
  url: string,
  h?: number,
  w?: number,
  onRemove: () => void
}

export const MediaItem = ({ magazine, url, h, w, onRemove }: Props) => {
  const sa = url.split('/');
  const fullName = sa[sa.length - 1];

  const sxt = fullName.split('.');
  const extension = sxt[sxt.length - 1];


  const height = h ?? 24;
  const width = w ?? 24;

  return (
    <div className={styles.MediaItem}>
      {MediaHelper.isImageExtension(extension) ?
        <img style={{ height: `${height}px`, width: `${width}px`, objectFit: 'contain' }} src={MediaHelper.proxify(magazine, url, (2 * width), (2 * height))} alt={''} />
        :
        <i style={{ fontSize: `${height}px` }} className={`fa ${MediaHelper.extensionFaCode(extension)}`}></i>
      }
      <a href={MediaHelper.proxify(magazine, url)} target={'_blank'}>{fullName ? MediaHelper.convertPlusToSpaces(fullName) : url}</a>
      <i className={classNames('material-icons', styles.removeIcon)} onClick={onRemove}>close</i>
    </div>
  )
}
