import _ from 'lodash';
import React, { useDebugValue, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router'
import { Col, Row } from 'reactstrap';
import { Profile, StatsFilter } from '../../../../../domain';
import { API } from '../../../../apis';
import { AudienceEstimatedSizeModal } from '../../../../components';
import { FilterContextConsumer } from '../../../../FilterContext';
import { AccessRightsHelper } from '../../../../utils';
import { FilterBarWithRouter as FilterBar } from '../../../Overview/FilterBar';
import { StatBox } from '../../../Overview/StatBox';
import styles from './TopNumbersChart.module.scss';

import './css/TopNumbersChart.min.css';

export interface Props {
    magazine: number,
    profile: Profile,
    statsFilter: StatsFilter
}

type NumberChartValue = string | number | undefined;
type TopNumbersChart = 'uniqueVisitors' | 'reach' | 'totalActivePublications' | 'totalActiveForms' | 'totalPushnotifications' | 'totalNewsletters';

interface TopNumbers {
    [chart: string]: NumberChartValue
}
// this.calcChartStats('totalNewsletters');
// this.calcChartStats('totalPushNotifications');
// this.calcChartStats('totalActiveForms');
export default function TopNumbersChart(props: Props) {
    const { magazine, profile, statsFilter } = props;

    const [audienceKPI, setAudienceKPI] = useState<NumberChartValue>(undefined);


    const [uniqueVisitors, setUniqueVisitors] = useState<NumberChartValue>(undefined);
    const [totalActivePublications, setTotalActivePublications] = useState<NumberChartValue>(undefined);
    const [totalActiveForms, setTotalActiveForms] = useState<NumberChartValue>(undefined);
    const [totalPushNotifications, setTotalPushNotifications] = useState<NumberChartValue>(undefined);
    const [totalNewsletters, setTotalNewsletters] = useState<NumberChartValue>(undefined);

    const [settingAudienceEstimatedSize, setSettingAudienceEstimatedSize] = useState(false);

    const calcChartStats = async (chart: TopNumbersChart) => {
        try {
            setTopNumber(chart, undefined);
            const { data: resp } = await API.analytics.loadChartData(chart, magazine, statsFilter);
            setTopNumber(chart, resp.data);
        } catch (error) {
            console.error('An error occured:' + error.message);
            setTopNumber(chart, '!');
        }
    }

    const calcAudienceKPI = async () => {
        const { audience } = statsFilter;
        if (!audience) {
            setAudienceKPI('!');
            return;
        }
        try {
            setAudienceKPI(undefined);
            const { data: resp } = await API.analytics.loadAudienceKpi(magazine, audience);
            setAudienceKPI(resp.kpi || 0);
        } catch (error) {
            console.error('An error occured:' + error.message);
            setAudienceKPI('!');
        }
    }

    const calcReachBasedOnKPI = (): NumberChartValue => {
        if (audienceKPI === undefined || uniqueVisitors === undefined) {
            return undefined;
        }
        if (uniqueVisitors == '!') {
            return '!'
        }
        if (!audienceKPI || !_.isNumber(audienceKPI) || !_.isNumber(uniqueVisitors)) {
            return '-'
        }
        return Math.round((uniqueVisitors / audienceKPI) * 100) + '%';
    }

    const getTopNumber = (chart: TopNumbersChart): NumberChartValue => {
        switch (chart) {
            case 'uniqueVisitors':
                return uniqueVisitors;
            case 'totalActivePublications':
                return totalActivePublications;
            case 'totalActiveForms':
                return totalActiveForms;
            case 'totalPushnotifications':
                return totalPushNotifications;
            case 'totalNewsletters':
                return totalNewsletters;
            case 'reach':
                return calcReachBasedOnKPI();
            default:
                return undefined;
        }
    }
    const setTopNumber = (chart: TopNumbersChart, value: NumberChartValue) => {
        switch (chart) {
            case 'uniqueVisitors':
                return setUniqueVisitors(_.isArray(value) ? 0 : value);
            case 'totalActivePublications':
                return setTotalActivePublications(_.isArray(value) ? 0 : value);
            case 'totalActiveForms':
                return setTotalActiveForms(_.isArray(value) ? 0 : value);
            case 'totalPushnotifications':
                return setTotalPushNotifications(_.isArray(value) ? 0 : value);
            case 'totalNewsletters':
                return statsFilter.audience != 1 ? setTotalNewsletters('-') : setTotalNewsletters(_.isArray(value) ? 0 : value);
        }
    }

    useEffect(() => {
        calcChartStats('uniqueVisitors');
        calcChartStats('totalActivePublications');
        calcChartStats('totalActiveForms');
        calcChartStats('totalPushnotifications');
        calcChartStats('totalNewsletters');
    }, [statsFilter.period, statsFilter.audience,]);

    useEffect(() => {
        calcAudienceKPI();
    }, [statsFilter.audience,])

    const openAudienceEstimatedSizeModal = () => {
        setSettingAudienceEstimatedSize(true);
    }
    const closeAudienceEstimatedSizeModal = () => {
        setSettingAudienceEstimatedSize(false);
        calcAudienceKPI();
    }

    const renderEstimatedSizeIcon = () => {
        const isAdmin = (AccessRightsHelper.getUserRole(profile) === 'admin') ? true : false;
        if (!isAdmin) {
            return null;
        }

        return (
            <div className={styles.EstimatedSizeIcon} onClick={() => openAudienceEstimatedSizeModal()}><img src={`/assets/icons/gear.svg`} /></div>
        )
    }

    return (
        <div id="TopNumbersChart" className="chartContainer">
            <StatBox scalableValue text={'unique_users'} value={getTopNumber('uniqueVisitors')} icon={'24/users'} />
            <div className={'StatBox'} style={{ position: 'relative' }}>
                <StatBox scalableValue text={'reach'} value={getTopNumber('reach')} icon={'24/reach'} />
                {renderEstimatedSizeIcon()}
            </div>
            <StatBox scalableValue text={'publications'} value={getTopNumber('totalActivePublications')} icon={'24/publications'} />
            <StatBox scalableValue text={'active_forms'} value={getTopNumber('totalActiveForms')} icon={'24/forms'} />
            <StatBox scalableValue text={'pushNotifications'} value={getTopNumber('totalPushnotifications')} icon={'24/pushnotification'} info={statsFilter.audience != 1 ? 'Audience Targeting is only supported in ED!' : undefined} />
            <StatBox scalableValue text={'newsletters'} value={getTopNumber('totalNewsletters')} icon={'24/newsletters'} info={statsFilter.audience != 1 ? 'Audience Targeting is only supported in ED!' : undefined} />
            {settingAudienceEstimatedSize && statsFilter.audience && <AudienceEstimatedSizeModal magazine={_.toNumber(magazine)} profile={profile} audience={statsFilter.audience} savedEstimatedSize={_.toNumber(audienceKPI)} onClose={closeAudienceEstimatedSizeModal} />}
        </div>
    )

}
