import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form as RForm, FormGroup } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';

import './css/FormBuilder.min.css';
import { Translations } from '../../../../translations';
import { Form, FormQuestion, Profile } from '../../../../../domain';
import * as FormsHelper from './FormsHelper';
import { SurveySectionBuilder } from './SurveySectionBuilder';
import { SurveySectionList } from './SurveySectionList';
import { QuizSectionList } from './QuizSectionList';
import { QuizSectionBuilder } from './QuizSectionBuilder';
import { QrModal } from '../../../../components/QrModal/QrModal';
import { generateUniversalLink } from '../../../../utils/genericHelper';

export interface Props {
  magazine: string,
  profile: Profile
  form: Form,
  changeForm: (form: Form) => void
}
export interface State {
  activeSection: number,
  activeCategory: string,
  qrModal: boolean,
}

export class FormBuilder extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeSection: 0,
      activeCategory: 'q',
      qrModal: false
    }
  }

  isNewForm = (): boolean => {
    const { form } = this.props;
    return form.id === -1;
  }

  openQRModal = () => {
    if (this.isNewForm()) {
      return;
    }
    this.setState({ qrModal: true });
  }

  closeQRModal = () => {
    this.setState({ qrModal: false });
  }

  changeQuestion = (question: FormQuestion, field: string, value: string) => {
    const { form } = this.props;
    let q = _.find(form.config.questions, ['id', question.id]);
    if (!q) {
      q = { ...FormsHelper.emptyQuestion };
    }
    const newQuestion = { ...q };
    _.set(newQuestion, field, value);

    if (newQuestion.answers) {
      newQuestion.answers = FormsHelper.indexToId(newQuestion.answers);
    }


    const newForm = { ...form };
    newForm.config.questions[0] = newQuestion;
    this.props.changeForm(newForm);
  }

  renderAnswerInput = (removable: boolean, index: number, question?: any,) => {
    return (
      <FormGroup key={index}>
        <div className={'draggableInput'}>
          <i className='material-icons'>drag_indicator</i> <Input placeholder={`Answer ${index + 1}`} value={question && question.answers && question.answers[index] ? question.answers[index].text : ''} onChange={(e) => this.changeQuestion(question, `answers[${index}].text`, e.target.value)}></Input>
          {!removable ? null :
            <i className='material-icons' onClick={(e) => this.removeAnswer(question, index)}>close</i>
          }
        </div>
      </FormGroup>
    )
  }

  renderPollBuilder = () => {
    const { form } = this.props;
    const q = _.isEmpty(form.config.questions) ? { ...FormsHelper.emptyQuestion } : form.config.questions[0];
    return (
      <div className="elementBox">
        <RForm>
          <FormGroup>
            <div className={'header'}>Poll Question</div>
          </FormGroup>
          {/* <FormGroup>
            <Input placeholder={'Title'} value={q?q.title:''} onChange={(e)=>this.changeQuestion(q,'title', e.target.value)}></Input>
          </FormGroup> */}
          <FormGroup>
            <Input type="textarea" placeholder={'Question'} value={q ? q.text : ''} onChange={(e) => this.changeQuestion(q, 'text', e.target.value)}></Input>
          </FormGroup>
          {this.renderAnswerInput(false, 0, q)}
          {this.renderAnswerInput(false, 1, q)}
          {!q.answers ? null : q.answers.map((a: any, index: number) => {
            if (index > 1) {
              return this.renderAnswerInput(form.status == 1 ? true : false, index, q);
            }
          })}
          {form.status !== 1 ? null :
            <FormGroup>
              <div className={'draggableInput'}>
                <Button block onClick={(e: any) => this.addAnswer(q)}>+ Add an extra answer</Button>
              </div>
            </FormGroup>
          }
        </RForm>
      </div>
    )
  }

  addAnswer = (question: any) => {
    const { form } = this.props;

    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: form.config.questions.map(q =>
          q.id === question.id
            ? {
              ...question,
              answers: [...question.answers, {
                id: question.answers.length + 1,
                text: ''
              }]
            }
            : q
        )
      }
    }


    // const newQuestion = {...question};

    // newQuestion.answers.push({
    //   id: newQuestion.answers.length + 1,
    //   text: ''
    // });
    // const newForm = {...form};
    // if(!newForm.config){
    //   return;
    // }

    // newForm.config.questions[0] = newQuestion;
    this.props.changeForm(newForm);
  }

  removeAnswer = (question: any, index: number) => {
    const { form } = this.props;

    const newQuestion = { ...question };

    newQuestion.answers.splice(index, 1);

    newQuestion.answers = FormsHelper.indexToId(newQuestion.answers);

    const newForm = { ...form };
    if (!newForm.config) {
      return;
    }

    newForm.config.questions[0] = newQuestion;
    this.props.changeForm(newForm);
  }

  changeActiveSection = (index: number) => {
    this.setState({ activeSection: index });
  }
  changeQuizActiveSection = (index: number, category: string) => {
    this.setState({ activeSection: index, activeCategory: category });
  }

  renderDeprecatedBuilder = () => {
    const { form } = this.props;
    return (
      <div className="elementBox">
        element box survey
      </div>
    )

  }

  renderSurveyBuilder = () => {
    const { form } = this.props;
    const { activeSection } = this.state;
    return (
      <SurveySectionBuilder form={form} activeSection={activeSection} changeForm={this.props.changeForm} />
    )
  }

  renderQuizBuilder = () => {
    const { form } = this.props;
    const { activeSection, activeCategory } = this.state;
    return (
      <QuizSectionBuilder form={form} activeSection={activeSection} activeCategory={activeCategory} changeForm={this.props.changeForm} />
    )
  }

  renderMainDiv = (type: string) => {
    switch (type) {
      case 'survey':
        return this.renderSurveyBuilder();
      case 'quiz':
        return this.renderQuizBuilder();
      case 's_poll':
        return this.renderPollBuilder();
      default:
        return this.renderDeprecatedBuilder();
    }
  }

  render() {
    const { form, profile } = this.props;
    const { activeSection, activeCategory, qrModal } = this.state;
    const tr = Translations.Translate(Translations, 'Forms');

    const appMeta = profile.appMeta

    return (
      <div id="FormBuilder">
        {Translations.EnableTranslations(Translations)}
        <Row>
          <Col md={_.includes(['survey', 'quiz'], form.type) ? 9 : 12} xl={_.includes(['survey', 'quiz'], form.type) ? 10 : 12}>
            <div className='titleDiv'>{`${tr('formBuilder')} - ${FormsHelper.typeName(form.type)}`} {<i className={`fa fa-qrcode qrToggler ${this.isNewForm() ? 'disabled' : ''}`} data-tippy-content={this.isNewForm() ? 'QR code will be available only after you save!' : 'Click to see QR code and link'} onClick={() => { this.openQRModal() }}></i>}</div>
            <div className='mainDiv'>{this.renderMainDiv(form.type)}</div>
          </Col>
          {form.type != 'survey' ? null :
            <Col xl={2} md={3} className='sectionListContainer'>
              <SurveySectionList form={form} activeSection={activeSection} changeForm={this.props.changeForm} changeActiveSection={this.changeActiveSection} />
            </Col>
          }
          {form.type != 'quiz' ? null :
            <Col xl={2} md={3} className='sectionListContainer'>
              <QuizSectionList form={form} activeSection={activeSection} activeCategory={activeCategory} changeForm={this.props.changeForm} changeActiveSection={this.changeQuizActiveSection} />
            </Col>
          }
        </Row>
        {(!qrModal || !appMeta) ? null :
          <QrModal universalLink={generateUniversalLink(appMeta, `/form/${form.id}`)} type={'form'} closeModal={this.closeQRModal.bind(this)} />
        }
      </div>
    )
  }
}

export default FormBuilder;
