import React from 'react';
import styles from './NcSettingsConfiguration.module.scss';
import { NcSettingsConfigurationRow } from '../../../components/NcSettingsConfigurationRow';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { NcSettingsFormData } from './NcSettingsConfiguration';
import { FormText, Input } from 'reactstrap';
import _ from 'lodash';
import { NcSettingsFontSelect } from '../../../components/NcSettingsFontSelect';
import { NcSettingsToggle } from '../../../components/NcSettingsToggle';
import { NcSettingsColorInput } from '../../../components/NcSettingsColorInput';
import { NcSettingsNumberInput } from '../../../components/NcSettingsNumberInput';
import { NcSettingsTextInput } from '../../../components/NcSettingsTextInput';
import { Profile } from '../../../../../../domain';
import { NcSettingsImageSelect } from '../../../components/NcSettingsImageSelect';
import { SingleSelect } from '../../../../../components/ReactSelect/ReactSelect';
import { createLocaleOptions } from '../../../NarrowcastingHelper';

interface Props {
  magazine: number,
  profile: Profile,
  control: Control<NcSettingsFormData, any>,
  errors: FieldErrors<NcSettingsFormData>
}

const companyLogoText = `
This logo is shown on the corner of the TV. 
The image is shown as-is but the recommended size is maximum 250x125 pixels.
`;

const mobileAppIconText = `
Whenever content is displayed that is also available in the mobile app, 
the App icon can be shown. The uploaded icon should be square and not be larger
than 1024x1024 pixels.
`;


export const NcSettingsConfigurationGeneral = ({ magazine, profile, control, errors }: Props) => {
  return (
    <div>
      <NcSettingsConfigurationRow label={`Company logo:`} imageSelectRow>
        <NcSettingsImageSelect {...{ magazine, profile, control }} fieldName='general.companyLogo.logoUri'
          text={companyLogoText}
        />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Logo position:`}>
        <Controller
          name={'general.companyLogo.logoPosition'}
          control={control}
          render={({ field }) => {
            return (
              <Input type={'select'} value={_.toString(field.value) || ''} onChange={(e) => { field.onChange(_.toNumber(e.target.value)) }} style={{ width: 'auto' }}>
                <option value={'1'}>Top Right</option>
                <option value={'0'}>Top Left</option>
                <option value={'3'}>Bottom Right</option>
                <option value={'2'}>Bottom Left</option>
              </Input>
            )
          }}
        />
      </NcSettingsConfigurationRow>
      <hr />
      <NcSettingsConfigurationRow label={`Article title font:`}>
        <NcSettingsFontSelect {...{ magazine, control }} fieldName='general.fontNames.titleFontName' />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Article body font:`}>
        <NcSettingsFontSelect {...{ magazine, control }} fieldName='general.fontNames.bodyFontName' />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Slides meta font:`}>
        <NcSettingsFontSelect {...{ magazine, control }} fieldName='general.fontNames.metaFontName' />
      </NcSettingsConfigurationRow>
      < hr />
      <NcSettingsConfigurationRow label={`Background Image:`} imageSelectRow>
        <NcSettingsImageSelect {...{ magazine, profile, control }} fieldName='general.backgroundImage' />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Background Color:`}>
        <NcSettingsColorInput {...{ control }} fieldName={'general.backgroundColor'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Slide heading font:`}>
        <NcSettingsColorInput {...{ control }} fieldName='general.slideHeadingFont.color' />
        <NcSettingsFontSelect {...{ magazine, control }} fieldName='general.slideHeadingFont.fontName' flex1 />
        <NcSettingsNumberInput {...{ control }} fieldName='general.slideHeadingFont.size' suffix='px' />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Default Duration:`}>
        <NcSettingsNumberInput {...{ control }} fieldName={'general.defaultDuration'} suffix={'seconds'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Corner Radius:`}>
        <NcSettingsNumberInput {...{ control }} fieldName={'general.cornerRadius'} suffix={'px'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Ken Burns Max Scale:`}>
        <NcSettingsNumberInput {...{ control }} fieldName={'general.kenBurnsMaxScale'} nullable min={0.1} />
        <FormText>Leave empty to not apply any ken burns max scale.</FormText>
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Maximum Image Scale:`}>
        <NcSettingsNumberInput {...{ control }} fieldName={'general.maxScaleForImages'} nullable min={0.1} />
        <FormText>Leave empty to not apply any max scale for images.</FormText>
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Disable shadows:`}>
        <NcSettingsToggle {...{ control }} fieldName={'general.disableShadows'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Disable 3D animations:`}>
        <NcSettingsToggle {...{ control }} fieldName={'general.disable3dAnimations'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Reduce blur effects:`}>
        <NcSettingsToggle {...{ control }} fieldName={'general.reduceBlurEffects'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Locale:`}>
        <Controller
          name={'general.locale'}
          control={control}
          render={({ field }) => {
            return (
              <div style={{ flex: 0.5}}>
                <SingleSelect
                  value={field.value || undefined}
                  placeholder='Global locale'
                  isClearable
                  options={createLocaleOptions()}
                  onChange={(e) => { e === undefined ? field.onChange('') : field.onChange(e)}}
                />
              </div>
            )
          }}
        />
      </NcSettingsConfigurationRow>
      <hr />
      <div className={styles.sectionTitle}>mobile app</div>
      <NcSettingsConfigurationRow label={`Show App icon:`}>
        <NcSettingsToggle {...{ control }} fieldName={'general.mobileApp.mobileAppShowIcon'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`App icon:`} imageSelectRow>
        <NcSettingsImageSelect {...{ magazine, profile, control }} fieldName='general.mobileApp.mobileAppIconUri'
          text={mobileAppIconText}
        />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`App name:`}>
        <NcSettingsTextInput {...{ control }} fieldName={'general.mobileApp.mobileAppName'} placeholder='App name' />
      </NcSettingsConfigurationRow>
    </div>
  )
}
