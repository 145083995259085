import _ from 'lodash';
import { FeedBasicProps } from '../pages/Content/subpages/cms/CmsDomain';

export function findDefaultFeed(magazine: string, feeds: FeedBasicProps[]): number | undefined {
    if (_.isEmpty(feeds)) {
        return undefined;
    }
    const defaultFeed = _.find(feeds, (f) => f.label === `${magazine}-cms`);
    return defaultFeed ? defaultFeed.id : feeds[0].id;
}

export function calcFeedLabel(magazine: string, feed: FeedBasicProps): string {
    if (feed.label == `${magazine}-cms`) {
        return `Default CMS Feed`;
    }
    return feed.displayName || feed.label;
}