import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import BootstrapTable, { ColumnDescription, PageListRendererOptions, SelectRowProps } from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider, PaginationTotalStandalone, } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, TableSearchProps } from 'react-bootstrap-table2-toolkit';
import Toggle from 'react-toggle';
import { Button, Col, Input, Row } from 'reactstrap';
import BatchActions from './BatchActions';
import { BatchAction, SearchConfig, SortConfig } from './domain';
import Pagination from './Pagination';
import styles from './PeopleOverviewTable.module.scss';
import Toolbar from './Toolbar';
// import withFixedColumns from 'react-table-hoc-fixed-columns';
// import 'react-table-hoc-fixed-columns/lib/styles.css';

// const DataTableFixedColumns = withFixedColumns(BootstrapTable);

interface Props {
    data: any[]
    totalData: number,
    columns: ColumnDescription[],
    keyField: string,
    sizePerPage?: number,
    searchConfig: SearchConfig,
    sortConfig: SortConfig,
    currentPage: number,
    exportLoading: boolean,
    exportProgress: number,
    onCurrentPageChange: (page: number) => void,
    setSearchConfig: (searchConfig: SearchConfig) => void
    setSortConfig: (sortConfig: SortConfig) => void
    onRowClick: (row: any) => void,
    onCreateNew: () => void,
    onBatchAction: (action: BatchAction, selectedProfiles: any[]) => void,
    onExport: (all?: boolean) => void,
    onImportModalOpen: () => void
}

export default function PeopleOverviewTable({ data, totalData, columns, keyField, sizePerPage = 20, searchConfig, exportLoading, exportProgress, sortConfig, setSortConfig, currentPage, onCurrentPageChange, setSearchConfig, onRowClick, onCreateNew, onBatchAction, onExport, onImportModalOpen }: Props) {

    // const [dataSize, setDataSize] = useState<number>(totalData)
    const [selectedData, setSelectedData] = useState<any[]>([])

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            if (onRowClick) {
                onRowClick(row);
            }
        }
    }

    const pagination = paginationFactory({
        hideSizePerPage: true,
        pageListRenderer: ({ pages, onPageChange }: PageListRendererOptions) => <Pagination {...{ pages, onPageChange, sizePerPage, total: totalData }} />,
        sizePerPage,
        onPageChange: (page, sizePerPage) => {
            onCurrentPageChange(page);
        },
        totalSize: totalData,
        page: currentPage
    });

    const selectRow: SelectRowProps<any> = {
        mode: 'checkbox',
        clickToSelect: false,
        headerColumnStyle: { width: '40px' },
        onSelect: (row, isSelect, rowIndex, e) => {
            const newSelectedData = isSelect ? [...selectedData, row] : _.filter(selectedData, (s) => s.uid !== row.uid);
            setSelectedData(newSelectedData);
        },
        onSelectAll: (isSelect, rows, e) => {
            setSelectedData(isSelect ? [...rows] : [])
        }
    }

    const remote = {
        sort: true,
        pagination: true
    };

    const onTableChange = (type: string, newState: any) => {
        const { dataField: field, order } = newState
        return false;
    }

    return (
        <div className={classNames(styles.tableDiv)}>
            <ToolkitProvider {...{ data, columns, keyField }} search>{providerProps => (
                <Fragment>
                    <Toolbar {...{ columns, searchConfig, setSearchConfig, sizePerPage, currentPage, totalData, onCreateNew, exportLoading, exportProgress, searchProps: { ...providerProps.searchProps }, onExport, onImportModalOpen }} />
                    <div className={styles.tableOuterWrapper}>
                        <BootstrapTable {...{ ...providerProps.baseProps, pagination, rowEvents, remote, onTableChange }} wrapperClasses={styles.tableInnerWrapper} bootstrap4 hover bordered={false} noDataIndication={'There is no data to display'} selectRow={selectRow} rowClasses={(row, rowIndex) => row.status ? '' : 'disabledProfile'} />
                    </div>
                </Fragment>
            )}</ToolkitProvider>
            {selectedData.length > 1 && <BatchActions {...{ selectedData, onBatchAction }} />}
        </div>
    )
}
