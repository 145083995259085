import React, { Component, Fragment } from 'react';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './css/SourceSelector.min.css'

import { Profile, Source } from '../../../../../domain';
import { Translations } from '../../../../translations';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as _ from 'lodash';
import * as Api from '../../../../api';
import { initTippy } from '../../../../components/TippyReact';
import { SourcesHelper } from '../../../../utils';

export interface Props {
  magazine: string,
  profile: Profile,
  title: string,
  sources?: Source[]
  selected?: Source
  closeHandler: () => void
  selectionHandler: (id: any) => void
  onSelect?: () => void //different from the selectionHandler. this is called when the big select button is clicked

  // clickChannelHandler: (channel: number, parent?: number) => void
}
export interface State {
  // activeTab: string,
  sources?: Source[],
  // selected?: number[]
  // historyLastUpdate: number
}

export class SourceSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // channels: props.channels
      // activeTab: '1',
      // historyLastUpdate: moment().valueOf()
    };
  }

  componentDidMount() {
    this.getSources();
    this.initTooltips();
  }
  componentDidUpdate() {
    this.initTooltips();
  }
  initTooltips() {
    initTippy('#SelectTypeModal')
  }



  getSources = () => {
    Api.getSources(parseInt(this.props.magazine)).then((resp) => {

      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ sources: [] });
      }
      this.setState({ sources: resp.data ? resp.data : [] });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }

  getAudiencesIds = () => {
    const { profile } = this.props;

    let audiences: number[] = [];

    if (!_.isEmpty(profile.audienceIds)) {
      audiences = _.map(profile.audienceIds, _.toInteger);
    }

    if (profile.globalAudience) {
      audiences.unshift(1);
    }

    return audiences;

  }

  audienceFormatter = (cell: any, row: any) => {
    const { profile } = this.props;
    const tr = Translations.Translate(Translations, 'Channels');
    const audiences: any[] = [];
    if (!cell) {
      return '-';
    }

    cell.map((a: number) => {
      audiences.push((a == 1) ? tr('all_employees') : _.get(_.find(profile.audiences, ['id', a]), 'label'));
    });

    if (audiences.length == 0) {
      return '-'
    } else if (audiences.length == 1) {
      return audiences[0];
    } else {
      return <span data-tippy-content={audiences.join(', ')}>{audiences.length}</span>;
    }
  }

  renderToolbar = (props: any) => {
    const tr = Translations.Translate(Translations, 'Channels');
    return (
      <Fragment>
        <Col md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <div className='titleDiv'>{this.props.title}</div>
        </Col>
        <Col md={6}>{props.components.searchField}</Col>
      </Fragment>
    );
  }

  renderPaginationPanel(props: any) {
    let max = 1;
    let min = 1;
    if (this.state.sources) {
      max = (this.state.sources.length / 10) + 1;
    }
    const tr = Translations.Translate(Translations, 'Tables');

    return (
      <Fragment>
        <Col md={8}>{props.components.pageList} {/*props.components.sizePerPageDropdown*/}</Col>
        <Col md={4}>
          <div className="goToPageDiv">
            <input id="goToPageInput" onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                let val = e.target.value;
                if (val < min) {
                  val = min;
                } else if (val > max) {
                  val = max;
                }
                props.changePage(val);
                e.target.value = '';
              }
            }} className='form-control' type="number" placeholder={tr('go_to_page')} style={{ width: '115px' }} />
            <button className="btn" onClick={(e) => {
              var k: any = document.getElementById('goToPageInput');
              let val = k.value;
              if (val < min) {
                val = min;
              } else if (val > max) {
                val = max;
              }
              props.changePage(val);
              k.value = ''
            }}>{'>'}</button>
          </div>
        </Col>
      </Fragment>
    );
  }

  onRowSelect = (row: any, isSelected: boolean, e: any) => {
    this.props.selectionHandler(row)
  }

  render() {
    const { magazine, profile, selected } = this.props;
    const { sources } = this.state;
    const tr = Translations.Translate(Translations, 'Sources');
    const trt = Translations.Translate(Translations, 'Tables');

    const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>;

    const options: any = {
      toolBar: this.renderToolbar,
      paginationPanel: this.renderPaginationPanel.bind(this),
      defaultSortName: 'cdate',  // default sort column name
      defaultSortOrder: 'desc',  // default sort order
      sizePerPage: 25,
      sizePerPageList: [
        // { text: `10 ${trt('entries')}`, value: 10 },
        { text: `25 ${trt('entries')}`, value: 25 },
        { text: `50 ${trt('entries')}`, value: 50 },
        { text: `100 ${trt('entries')}`, value: 100 },
        // {text:'All entries',value: articles?articles.length:0},
      ]
    }


    const selectRow: any = {
      mode: 'radio',
      clickToSelect: true,
      selected: selected ? [selected.id] : [],
      onSelect: this.onRowSelect,
    }

    const closeBtn = <img className="close" onClick={this.props.closeHandler.bind(this)} src="/assets/icons/bt-close.svg" />



    return (
      <Modal isOpen={true} centered id="SelectTypeModal" size={'lg'} keyboard={true} toggle={this.props.closeHandler}>
        {Translations.EnableTranslations(Translations)}
        <ModalHeader><span className="headerTitle"><img src="/assets/icons/24/publications_blue.svg" style={{ marginRight: '10px' }}></img> {tr('selectSource')}</span> {closeBtn}</ModalHeader>
        <ModalBody>
          <div id="SourceSelector" className="">
            <div className="tableDiv">
              {Translations.EnableTranslations(Translations)}
              {sources ?
                <BootstrapTable ref="sourcesSelector" data={sources} bordered={false} search={true} hover={false} options={options} version='4' selectRow={selectRow} pagination={true}>
                  <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
                  <TableHeaderColumn dataField='name' tdStyle={{ cursor: 'pointer' }} dataFormat={(cell, row) => SourcesHelper.sourceEdConfigName(row, profile.edConfig)}></TableHeaderColumn>
                  <TableHeaderColumn dataField='srctype' dataAlign='center' width='200px'>
                    <img data-tippy-content={tr("sourceType")} src="/assets/icons/16/form_type.svg" />
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField='articleCount' dataAlign='center' width='50px'>
                    <img data-tippy-content={tr("articles")} src="/assets/icons/16/publications.svg" />
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField='cdate' hidden></TableHeaderColumn>
                </BootstrapTable>
                : loader
              }
            </div>

          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" block size="lg" onClick={this.props.onSelect ? this.props.onSelect.bind(this) : this.props.closeHandler.bind(this)} style={{ textTransform: 'uppercase' }}>{tr('select')}</Button>
        </ModalFooter>
      </Modal>

    );
  }
}

export default SourceSelector;
