import React, { Fragment } from 'react';
import styles from './MediaBucketExplorerBreadcrumb.module.scss'
import _ from 'lodash';

interface Props {
  currentFolder: string
  onChangeFolder: (currentFolder: string) => void
  onChangeFolderWithoutSearchReset: (currentFolder: string) => void
  searchMode: boolean
}

export default function MediaBucketExplorerBreadcrumb(props: Props) {
  const { currentFolder, onChangeFolder, searchMode, onChangeFolderWithoutSearchReset } = props
  const parts = currentFolder.split('/')
  return <div className={styles.breadcrumb}>
    <button className={styles.button} onClick={() => { onChangeFolder(parts.slice(0, -1).join('/')) }}>
      <img src="/assets/icons/16/arrow-left.svg" />
    </button>
    <button className={styles.button} onClick={() => { onChangeFolder('') }}>
      <img src="/assets/icons/16/home.svg" />
    </button>
    {
      parts.map((part, index) => {
        const path = parts.slice(0, index + 1).join('/')

        return <Fragment key={path}>
          <span className={styles.minorLabel}>/</span>
          {index < parts.length - 1
            ? <button onClick={() => { onChangeFolder(path) }} className={styles.button}>
              <img src="/assets/icons/16/folder.svg" />
              <span className={styles.minorLabel}>{part}</span>
            </button>
            : <>
              <img src="/assets/icons/24/folder.svg" />
              <span className={styles.majorLabel}>{part}</span>
            </>
          }

        </Fragment>
      })
    }
    {searchMode && <button className='btn btn-secondary' onClick={() => { onChangeFolderWithoutSearchReset('') }}>Search in all folders</button>}
  </div>
}