import _ from 'lodash';
import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from "react-beautiful-dnd";


import styles from './SortingModal.module.scss';
import classNames from 'classnames';
import { LoadingButton, OrtecLoader, SwalError } from '../../widgets';
import { ImmutArray } from '../../utils/ImmutablesHelper';
import GenericModalCloseButton from '../GenericModal/GenericModalCloseButton';
import GenericModalHeader from '../GenericModal/GenericModalHeader';

export interface SortingColumnDescription<T> {
  getValue: (item: T) => string,
  label: string,
  // valueFormatter?: (cell: any, row: T) => JSX.Element
}

/**
 * on change handler returns the updated list + source and destination indexes
 */
export type SortingOnChangeHandler<T> = (items: T[], sourceIndex: number, destinationIndex: number) => void

interface Props<T> {
  title: string,
  columns: SortingColumnDescription<T>[],
  getItemId: (item: T) => string,
  dataItems: T[],
  close: () => void,
  onChange: SortingOnChangeHandler<T>
  loading?: boolean      // modal loader
  onSave?: () => void    // save button handler. if omitted then whole ModalHeader is omitted
  saveLoading?: boolean  // save button loader
}

export const SortingModal = <T,>({ title, columns, getItemId, dataItems, close, onSave, onChange, saveLoading, loading }: Props<T>) => {

  if (dataItems.length > 1000) {
    SwalError.fire({
      title: `Large amount of data`,
      text: `Sorting functionality supports datasets with no more than 1000 items.`,
      footer: `<div class="alert alert-info"> You can fix order by following the steps below: 
      <ol>
        <li>Export CSV</li>
        <li>Delete All</li> 
        <li>Open CSV in excel, fix sorting and save </li>
        <li>Import the CSV file with the correct sorting</li>
      </ol>
      </div>`
    }).then(() => {
      close();
    });
    return null;
  }

  const reorder = (listItems: T[], sourceIndex: number, destinationIndex: number): T[] => {
    return ImmutArray.move(listItems, sourceIndex, destinationIndex);
  }

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }
    const source = result.source.index
    const destination = result.destination.index
    const newList = reorder(dataItems, source, destination)
    onChange(newList, source, destination);
  }

  const onClose = () => {
    if (!saveLoading) {
      close();
    }
  }

  return (
    <Modal isOpen={true} className={styles.SortingModal} toggle={onClose} centered={false} size={'lg'} /*keyboard={false} onKeyDown={handleEsc}*/>
      <GenericModalHeader onClose={saveLoading || loading ? undefined : onClose} title={title} />
      <ModalBody className={styles.scrollable}>
        <div>
          {loading && <OrtecLoader />}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'droppable'}>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={{}}>
                  {_.map(dataItems, (d, index) => {
                    const itemId = getItemId(d)
                    return (
                      <Draggable key={itemId} draggableId={itemId} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{ ...provided.draggableProps.style }}
                            className={classNames(styles.sortableItem, { [styles.isDragging]: snapshot.isDragging })}
                          >
                            <div className={styles.sortableIndex}>{index + 1}</div>
                            {_.map(columns, (c, index) => {
                              return (
                                <div key={itemId + "_" + index} className={styles.sortableColumn}>
                                  <div className={styles.sortableItemLabel}>{c.label}</div>
                                  <div>{c.getValue(d)}</div>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </ModalBody>
      {onSave && // if no onSave function skip ModalFooter
        <ModalFooter>
          <div style={{ flexGrow: 1 }}></div>
          <LoadingButton loading={saveLoading || loading} onClick={onSave} text={'Save sorting'} />
          {' '}
          <Button disabled={saveLoading} color="outline-secondary" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      }
    </Modal>
  )
};
