import React, { useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EdErrorHandler, OrtecLoader, SwalDelete, SwalSuccess } from '../../../../../widgets';
import { API } from '../../../../../apis';
import DataTable, { ExtendedColumnDescription } from '../../../../../components/DataTable/DataTable';
import { Profile, TranslateFunction } from '../../../../../../domain';
import _ from 'lodash';
import { Translations } from '../../../../../translations';
import { CategoriesHelper, DataTableHelper, HistoryHelper } from '../../../../../utils';
import { CmsItem, CmsStage, Variations } from '../CmsDomain';
import { Badge, Button } from 'reactstrap';
import styles from './CmsOverview.module.scss';
import { CmsDuplicateDialog } from '../CmsDuplicateDialog/CmsDuplicateDialog';
import { CmsFeedInfo } from '../CmsItemDetails/CmsItemDetails';
import { useHistory } from 'react-router-dom';
import { CmsItemsTabs } from './CmsItemsTabs';
import classNames from 'classnames';
import { TippyReact } from '../../../../../components';


interface Props {
  magazine: number,
  profile: Profile,
  activeFeed: CmsFeedInfo,
  activeTab: CmsStage
  agendaMode?: boolean
  clickItemHandler: (draftId: string, subTab?: CmsStage) => void
  // subTabs: JSX.Element
  // createNewItemButton: JSX.Element
}

export const CmsItemsOverviewTable = ({ magazine, profile, activeFeed, activeTab, agendaMode, clickItemHandler }: Props) => {


  const [duplicateItem, setDuplicateItem] = useState<{ externalId: string, title: string } | undefined>(undefined);
  const [showAllItems, setShowAllItems] = useState<boolean>(false);


  const history = useHistory();

  const setActiveTab = (subTab: CmsStage) => {
    HistoryHelper.setQueryParams(history, { subTab });
  }

  const feedId = useMemo(() => activeFeed.id, [activeFeed.id]);
  const feedVariations = useMemo(() => activeFeed.variations, [activeFeed.variations]);

  const inPublishedStage = useMemo(() => activeTab === 'published', [activeTab]);

  const queryClient = useQueryClient();

  const cmsItemsQuery = useQuery({
    queryKey: ['cmsItems', magazine, feedId, activeTab, agendaMode],
    queryFn: async () => {
      try {
        const { data } = await API.cms.getCmsItems(magazine, feedId, activeTab, agendaMode);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting ${activeTab} CMS ${agendaMode ? 'agenda items' : 'articles'}`);
      }
    }
  });

  const cmsDraftDeleteMutation = useMutation({
    mutationKey: ['cmsDraftDelete'],
    mutationFn: (d: CmsItem) => API.cms.deleteCmsDraft(magazine, feedId, encodeURIComponent(d.external_id)),
    onError: (error, d) => {
      EdErrorHandler(error, `deleting CMS draft ${d.data.title}`);
    },
    onSuccess: (data, d) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `CMS draft <${d.data.title}> has been deleted successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['cmsItems', magazine, feedId, activeTab, agendaMode] })
    }
  })


  const isLoading = cmsItemsQuery.isFetching || cmsDraftDeleteMutation.isLoading;

  const tr: TranslateFunction = Translations.Translate(Translations, 'Cms');

  const onEdit = (cmsItem: CmsItem) => {
    clickItemHandler(cmsItem.external_id, activeTab);
  }

  const onDuplicateClick = async (cmsItem: CmsItem) => {
    setDuplicateItem({ externalId: cmsItem.external_id, title: cmsItem.data.title || '' });
  }


  const onDelete = async (cmsDraft: CmsItem) => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      html: `This action will delete draft <code> ${cmsDraft.data.title}</code>.`
    });
    if (!confirm) {
      return;
    }
    cmsDraftDeleteMutation.mutate(cmsDraft);
  }


  const items = useMemo(() => {
    if (!inPublishedStage) {
      return cmsItemsQuery.data;
    } else {
      return _.filter(cmsItemsQuery.data, (i) => showAllItems ? true : i.data.status === 1);
    }
  }, [cmsItemsQuery.data, inPublishedStage, showAllItems]);


  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'external_id',
      hidden: true,
      text: 'Id',
    },
    {
      dataField: 'data.id',
      hidden: true,
      text: 'Id',
    },
    {
      dataField: 'data.status',
      sort: false,
      hidden: !inPublishedStage,
      text: '',
      align: 'center',
      headerAlign: 'center',
      headerStyle: { width: '50px' },
      headerFormatter: (column, colIndex) => {
        return <><img data-tippy-content={'Status'} src={'/assets/icons/16/stats.svg'} /><span></span></>
      },
      formatter: (cell: number, row: CmsItem) => {
        try {
          const status = cell ? 'visible' : 'hidden';
          const tippyContent = _.capitalize(status);

          return <i className={`fa fa-circle status status-${status}`} data-tippy-content={tippyContent}></i>;

        } catch (error) {
          return <>{cell}</>
        }

      },

    },
    {
      dataField: 'data.title',
      text: '',
      headerFormatter: (column, colIndex) => {
        if (!inPublishedStage) {
          return <></>;
        }
        return (
          <div className={styles.statusFilters}>
            <span className={!showAllItems ? styles.showOption : styles.showSelectedOption} onClick={() => { setShowAllItems(true) }}>Show all</span>
            <span className={styles.filterSeparator}>/</span>
            <span className={showAllItems ? styles.showOption : styles.showSelectedOption} onClick={() => { setShowAllItems(false) }}>Show visible only</span>
          </div>
        )
      },
      formatter: (title: string, row: CmsItem) => {
        const nonPrimaryLang = nonPrimaryVariationLanguage(feedVariations, row.variation);
        return (
          <div className={styles.titleWrapper}>
            <span style={{ flex: 1 }}>{title}</span>
            {nonPrimaryLang !== undefined &&
              <TippyReact content={`Variation in the feed's primary language has not been found.`}>
                <div>
                  <Badge className={classNames(styles.langBadge, { [styles.empty]: nonPrimaryLang ? false : true })} color={'warning'}>{nonPrimaryLang || 'No Lang'}</Badge>
                </div>
              </TippyReact>
            }
          </div>
        )
      },
    },
    {
      dataField: 'target_uid',
      hidden: !activeFeed.isPersonalized,
      text: '',
      align: 'center',
      headerAlign: 'center',
      headerStyle: { width: '170px', cursor: 'pointer' },
      headerFormatter: (column, colIndex) => {
        return <><img data-tippy-content={tr('targetedUser')} src={'/assets/icons/16/audience.svg'} /><span></span></>
      },
      formatter: (cell: string, row: CmsItem) => {
        return <span data-tippy-content={cell}>{cell}</span>;
      }


    },
    {
      dataField: 'data.author',
      text: '',
      align: 'center',
      headerAlign: 'center',
      headerStyle: { width: '170px', cursor: 'pointer' },
      // classes: 'hideOnHover',
      headerFormatter: (column, colIndex) => {
        return <><img data-tippy-content={tr('author')} src={'/assets/icons/16/author.svg'} /><span></span></>
      },
      formatter: (cell: string, row: CmsItem) => {
        return <span data-tippy-content={cell}>{cell}</span>;
      }

    },
    {
      dataField: 'data.tags',
      text: '',
      align: 'center',
      headerAlign: 'center',
      headerStyle: { width: '130px', cursor: 'pointer' },
      headerFormatter: (column, colIndex) => {
        return <><img data-tippy-content={tr('categories')} src={'/assets/icons/16/form_type.svg'} /><span></span></>
      },
      formatter: (cell: string, row: CmsItem) => {
        if (!cell) {
          return '';
        }
        const categories = CategoriesHelper.extractCategoriesFromTags(row.data.tags, activeFeed.label);

        if (_.isEmpty(categories)) {
          return '';
        }

        const text = categories.length == 1 ? categories : `${categories.length} categories`;
        return <span data-tippy-content={categories.join(' , ')}>{text}</span>;
      }

    },
    {
      dataField: 'dfTags',
      isDummyField: true,
      text: '',
      align: 'center',
      headerAlign: 'center',
      headerStyle: { width: '100px', cursor: 'pointer' },
      headerFormatter: (column, colIndex) => {
        return <><img data-tippy-content={tr('tags')} src={'/assets/icons/16/tags_icon.svg'} /><span></span></>
      },
      formatter: (cell: string, row: CmsItem) => {
        const tags = _.uniq(_.filter(row.data.tags, (t) => !_.startsWith(t, '_cat:')));
        if (_.isEmpty(tags)) {
          return <></>;
        }
        const text = tags.length == 1 ? tags : `${tags.length} tags`;
        return <span data-tippy-content={tags}>{text}</span>;
      }

    },
    {
      dataField: 'data.cdate',
      sort: true,
      text: '',
      align: 'center',
      headerAlign: 'center',
      headerStyle: { width: '150px', cursor: 'pointer' },
      classes: 'hideOnHover',
      headerFormatter: (column, colIndex) => {
        return <><img data-tippy-content={tr(inPublishedStage ? 'publishingDate' : 'creationDate')} src={'/assets/icons/16/calendar.svg'} /><span></span></>
      },
      formatter: DataTableHelper.dateFormatter

    },
    {
      dataField: 'dfActions',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatter: (cell, row: CmsItem) => {
        return (
          <div className={'actionHoveringDiv'}>
            {!inPublishedStage &&
              <Button className={styles.deleteButton} color={'delete'} style={{ marginRight: '8px' }} onClick={(e) => { onDelete(row); e.preventDefault(); e.stopPropagation(); return false; }}>Delete</Button>
            }
            <Button color={'secondary'} style={{ marginRight: '8px' }} onClick={(e) => { onDuplicateClick(row); e.preventDefault(); e.stopPropagation(); return false; }}>Duplicate</Button>
            {/* <Button color={'secondary'} onClick={(e) => { onEdit(row); e.preventDefault(); e.stopPropagation(); return false; }}>Edit</Button> */}
          </div>
        )
      }
    },
    {
      dataField: 'dfSearch',
      isDummyField: true,
      hidden: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      formatter: (cell, row: CmsItem) => {
        return `${row.data.status ? 'visible' : 'hidden'} ${row.data.title} ${row.data.author} ${row.data.tags.join(' ')}`
      }
    },
  ];


  const createNewItemButton = <Button color={'primary'} onClick={() => { clickItemHandler('-1', `drafts`) }}>{agendaMode ? tr('createNewAgendaItem') : tr('createNewArticle')}</Button>;

  const subTabs = <CmsItemsTabs activeTab={activeTab} setActiveTab={setActiveTab} />

  return (
    <div key={`${feedId}-${activeTab}-${JSON.stringify(activeFeed.variations)}`} className={'tableDiv withLoader'} >
      {isLoading && <OrtecLoader />}
      <DataTable
        data={items || []}
        columns={columns}
        keyField={'external_id'}
        defaultSorted={[{
          dataField: 'data.cdate',
          order: 'desc'
        }]}
        defaultSizePerPage={25}
        onRowClick={onEdit}
        onlySearchBar
        searchFilters={subTabs}
        secondaryElements={createNewItemButton}
        toolbarStyle={{ marginBottom: '0px' }}
        searchFiltersStyle={{ marginBottom: '0px' }}
      />
      {
        duplicateItem &&
        <CmsDuplicateDialog
          activeTab={activeTab}
          duplicateItem={duplicateItem}
          agendaMode={agendaMode}
          magazine={magazine}
          profile={profile}
          activeFeed={activeFeed}
          openItem={clickItemHandler}
          onClose={() => setDuplicateItem(undefined)}
        />
      }
    </div >
  )
}

export const nonPrimaryVariationLanguage = (variations: Variations | undefined, varLang: string): string | undefined => {
  if (!variations?.enabled) {
    return undefined;
  }

  if (varLang == '') {
    return '';
  }

  if (varLang === `lang:${variations.primary}`) {
    return undefined;
  }

  const lang = _.find(variations.languages, (l) => varLang === `lang:${l.key}`);

  return lang ? _.capitalize(lang.name) : varLang;

}
