import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent, Row, Col, Button, Label, Input } from 'reactstrap';
import classnames from 'classnames';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { OrtecLoader, EdErrorHandler, Swal } from '../../../../../widgets'
import { Profile } from '../../../../../../domain';
import moment from 'moment';
import * as Api from '../../../../../api';
import * as _ from 'lodash';

import { Translations } from '../../../../../translations';
import { CheckCard, Props as CardProps, CheckProps } from './CheckCard';

import * as ch from '../../../../../utils/checksHelper';

const TITLE = 'Last run';


export interface Props {
  magazine: number,
  profile: Profile,
  loading: boolean,
  records: any[],
  // location?: string,
}


export class Check_lastRun extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  findStartDate = (records: any[]): string => {
    try {
      const lastRunRecords = _.filter(records, (r) => r.range === '__aggregator');
      if (_.isEmpty(lastRunRecords)) {
        return '';
      }

      return moment(lastRunRecords[0].startDate).format('YYYY-MM-DD HH:mm');
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  check = (): CheckProps => {
    const { records } = this.props;

    const lastRunRecords = _.filter(records, (r) => r.range === '__aggregator');

    if (_.isEmpty(lastRunRecords)) {
      return ch.noDataCard();
    }

    if (_.every(lastRunRecords, (r) => _.isNil(r.log))) { //no errors or warnings
      return {
        icon: 'success',
        result: 'Passed!',
        descr: `Aggregator has run successfully for all audiences`,
        warnings: [],
        errors: [],
        renderErrors: this.renderErrors,
        suggestionHandler: this.suggestionHandler,
      }
    } else { //errors or/and warnings
      const errorRecords: any[] = [];
      const warningRecords: any[] = [];

      _.map(lastRunRecords, (r) => {
        if (r.log) {
          try {
            const log = JSON.parse(r.log);
            if (log.warning) {
              warningRecords.push(r);
            } else {
              errorRecords.push(r);
            }
          } catch (error) {

            errorRecords.push(r);
          }
        }
      });

      const icon = _.isEmpty(errorRecords) ? 'success' : 'error';
      const result = _.isEmpty(errorRecords) ? 'Passed!' : 'Failed!';
      const descr = _.isEmpty(errorRecords) ? `Aggregator run successfully but with warnings.` : `Aggregator has run with errors.`;
      return {
        icon,
        result,
        descr,
        warnings: warningRecords,
        errors: errorRecords,
        renderErrors: this.renderErrors,
        suggestionHandler: this.suggestionHandler,
      }
    }

  }

  calcCardProps = (): CardProps => {
    const runStart = this.findStartDate(this.props.records);
    return {
      title: `${TITLE} ${runStart ? '*' : ''}`,
      explanation: `Checks if yesterday's aggregator has run successfully for all audiences.`,
      loading: this.props.loading,
      footer: runStart ? `* Last run on: ${runStart}` : undefined,
      ...this.check()
    }
  }

  renderErrors = (warnings: any[], errors: any[]) => {

    if (_.isEmpty(warnings) && _.isEmpty(errors)) {
      return null;
    }
    const hasErrors = !_.isEmpty(errors);

    const alertClass = !hasErrors ? 'warning' : 'danger';

    const warningAnalysis = ch.warningsAnalysis(warnings);
    const errorAnalysis = ch.errorAnalysis(errors);

    return (
      <div className={`moreContainer alert alert-${alertClass}`}>
        {ch.errorAudiencesError(errorAnalysis)}
        {ch.noUsersWarning(warningAnalysis, hasErrors)}
      </div>
    )

  }

  audienceFormatter = (audience: number) => {
    const { profile } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');
    const audiences: any[] = [];
    if (audience === 1) {
      return tr('all_employees');
    }
    return _.get(_.find(profile.audiences, ['id', audience]), 'label', audience);
  }

  suggestionHandler = (warnings: any[], errors: any[]) => {

    const warningAnalysis = ch.warningsAnalysis(warnings);
    const errorAnalysis = ch.errorAnalysis(errors);

    const html = `
      <div>
        ${ch.errorAudiencesErrorSuggestion(errorAnalysis, this.audienceFormatter.bind(this), this.props.magazine)}
        ${ch.noUsersWarningSuggestion(warningAnalysis.noUsers.audiences, this.audienceFormatter.bind(this), this.props.magazine)}
      </div>
    `
    Swal.fire({
      title: TITLE,
      type: 'info',
      html: html,
      width: '600px',
      showCloseButton: true,
    })
  }

  render() {
    const tr = Translations.Translate(Translations, 'Monitoring');
    const { loading, title, explanation, icon, result, descr, warnings, errors, renderErrors, suggestionHandler, footer } = this.calcCardProps();

    return (
      <CheckCard
        loading={loading}
        title={title}
        explanation={explanation}
        icon={icon}
        result={result}
        descr={descr}
        warnings={warnings}
        errors={errors}
        renderErrors={renderErrors}
        suggestionHandler={suggestionHandler}
        footer={footer}
      />
    );
  }
}

export default Check_lastRun;
