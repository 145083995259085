import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ModalBody } from 'reactstrap';
import { MagazineLanguages, Profile } from '../../../../../domain';
import { API } from '../../../../apis';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { GenericModal } from '../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../components/GenericModal/GenericModalHeader';
import { HistoryHelper } from '../../../../utils';
import { EdErrorHandler, OrtecLoader, SwalDelete, SwalSuccess } from '../../../../widgets';
import { NlTemplateset } from './domain';
import { NewsletterTemplatesetDetails } from './NewsletterTemplatesetDetails';
import styles from './NewsletterTemplatesets.module.scss';

interface Props {
  magazine: number
  profile: Profile
  magazineLanguages: MagazineLanguages
  onClose: () => void
}

export const NewsletterTemplatesets = ({ magazine, profile, magazineLanguages, onClose }: Props) => {
  const [templatesets, setTemplatesets] = useState<NlTemplateset[]>([]);
  const [loadingTemplatesets, setLoadingTemplatesets] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const loading = loadingTemplatesets || deleteLoading;

  const history = useHistory();
  const templateParam = HistoryHelper.getQueryParam(history, 't');
  const editTemplatesetId = templateParam ? _.toNumber(templateParam) : undefined;

  useEffect(() => {
    loadTemplatesets();
  }, []);

  const loadTemplatesets = async () => {
    try {
      setLoadingTemplatesets(true);
      const { data } = await API.newsletters.getNewsletterTemplatesets(magazine);
      setTemplatesets(data);
    } catch (error) {
      EdErrorHandler(error, `loading templatesets`)
    } finally {
      setLoadingTemplatesets(false);
    }
  }

  const onCreateTemplateset = () => {
    HistoryHelper.setQueryParams(history, { t: -1 });
  }

  const onOpenTemplateset = (templatesetId: number) => {
    HistoryHelper.setQueryParams(history, { t: templatesetId });
  }

  const onCloseTemplateset = (reload?: boolean) => {
    HistoryHelper.setQueryParams(history, { t: undefined });
    if (reload) {
      loadTemplatesets();
    }
  }

  const onDelete = async (templateset: NlTemplateset) => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      html: `This action will delete template <code>[${templateset.id}] ${templateset.name}</code>.`
    });
    if (!confirm) {
      return;
    }
    await deleteTemplateset(templateset.id);
    loadTemplatesets();
  }

  const deleteTemplateset = async (templatesetId: number) => {
    try {
      setDeleteLoading(true);
      const { data } = await API.newsletters.deleteNewsletterTemplateset(magazine, templatesetId);
      SwalSuccess.fire({
        title: 'Success!',
        text: `Template with id ${templatesetId} has been deleted `,
        customClass: {
          popup: 'noBounce'
        },
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (error) {
      EdErrorHandler(error, `deleting a template`);
    } finally {
      setDeleteLoading(false);
    }
  }

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'name',
      sort: true,
      text: 'Name'
    },
    {
      dataField: 'mail_subject',
      sort: true,
      text: 'subject'
    },
    {
      dataField: 'mail_sender_name',
      sort: true,
      text: 'sender'
    },
    {
      dataField: 'mail_sender_email',
      sort: true,
      text: 'e-mail'
    },
    {
      dataField: 'language',
      sort: true,
      text: 'language',
      headerStyle: { width: '150px' }
    },
    {
      dataField: 'dfActions',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatter: (cell, row: NlTemplateset) => {
        return (
          <div className={'actionHoveringDiv'}>
            <Button color={'secondary'} onClick={(e) => { onDelete(row); e.preventDefault(); e.stopPropagation(); return false; }}>Delete</Button>
          </div>
        )
      }
    }
  ]


  return (
    <Fragment>
      {editTemplatesetId ?
        <NewsletterTemplatesetDetails
          {...{ magazine, profile, magazineLanguages }}
          templatesetId={editTemplatesetId}
          onClose={onCloseTemplateset}
        />
        :
        <GenericModal isOpen size={'xxl'} fullHeight centered toggle={onClose}>
          <GenericModalHeader
            onClose={onClose}
            title={`Newsletter Templates`}
            icon={`/assets/icons/icon-content-feed.svg`}
          />
          <ModalBody>
            <div className={styles.NewsletterTemplatesetsBody}>
              {loading && <OrtecLoader />}
              <DataTable
                data={templatesets}
                columns={columns}
                keyField={'id'}
                defaultSorted={[{ dataField: 'id', order: 'desc' }]}
                onCreateNew={onCreateTemplateset}
                onRowClick={(row: NlTemplateset) => onOpenTemplateset(row.id)}
                createNewButtonText={`create new template`}
              />
            </div>
          </ModalBody>
        </GenericModal>
      }
    </Fragment>
  )
}