import React from 'react';

import styles from './NcSlidePreview.module.scss';
import { NarrowcastingHelper } from '../../NarrowcastingHelper';
import { NcSlideFormData } from './NcSlideshowDetails';
import classNames from 'classnames';
import { MediaHelper } from '../../../../utils';


interface Props {
  magazine: number,
  ncSlide: NcSlideFormData
}

export const NcSlidePreview = ({ magazine, ncSlide }: Props) => {
  return (
    <div className={styles.NcSlidePreview}>
      <div className={styles.previewPlaceholder}>Preview of {NarrowcastingHelper.slideTypeToTitle(ncSlide.type)} slide</div>
      <div className={classNames(styles.typePreview, calcTypePreviewClassname(ncSlide))} style={calcCustomStyle(magazine, ncSlide)}></div>
      {ncSlide.config.showTicker &&
        <div className={styles.newstickerPreview}></div>
      }
    </div>
  )
}

const calcTypePreviewClassname = (ncSlide: NcSlideFormData): string => {
  switch (ncSlide.type) {

    case 'image':
      return styles.typePreview_image
    case 'video':
      return styles.typePreview_video
    case 'weather':
      return styles.typePreview_weather
    case 'imgzine-articles':
      if (ncSlide.config.renderer === '1article') {
        return styles.typePreview_1article;
      } else if (ncSlide.config.renderer === '2articles') {
        return styles.typePreview_2articles;
      } else {
        return styles.typePreview_fullarticle;
      }
    default:
      return '';
  }
}

const calcCustomStyle = (magazine: number, ncSlide: NcSlideFormData): React.CSSProperties | undefined => {
  switch (ncSlide.type) {
    case 'image':
      if (!ncSlide.config.url) {
        return undefined;
      }
      return {
        transform: 'translate(-8px,-8px)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: ncSlide.config.scaleMode === 'fill' ? 'cover' : 'contain',
        backgroundImage: `url('${MediaHelper.proxify(magazine, ncSlide.config.url)}')`
      }
    default:
      return undefined;
  }
}


