import React from 'react';
import { ControllerRenderProps, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import { Label } from 'reactstrap';
import { NcSlideConfigFormData, NcSlideshowFormData } from '../subpages/NcSlideshows/NcSlideshowDetails';
import { ChannelsSelect } from '../../../components';
import { Profile } from '../../../../domain';
import { EdErrorHandler } from '../../../widgets';
import { useQuery } from '@tanstack/react-query';
import { API } from '../../../apis';
import { NarrowcastingHelper } from '../NarrowcastingHelper';

interface Props {
  magazine: number,
  profile: Profile,
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  fieldName: FieldPath<NcSlideConfigFormData>
  activeIndex: number
  label?: string
  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSlideConfigChannelSelect = ({ magazine, profile, field, fieldName, activeIndex, label }: Props) => {

  const ncChannelsQuery = useQuery({
    queryKey: ['channels', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.oldApi.getMagazineChannels(magazine);
        return data.channels;
      } catch (error) {
        EdErrorHandler(error, `getting magazine channels`);
      }
    }
  });

  const val = NarrowcastingHelper.getValueOfSlideConfigField(field, activeIndex, fieldName) || '';

  return (
    <>
      <Label>{label}</Label>
      <ChannelsSelect
        channels={ncChannelsQuery.data}
        selected={val}
        onChange={(id: string) => {
          NarrowcastingHelper.setValueOfSlideConfigField(field, activeIndex, fieldName, _.toNumber(id))
        }}
        channelGraphName={profile.edConfig?.channelGraphName}
      />
    </>
  )
}
