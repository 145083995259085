import React, { useState } from 'react';
import { ControllerRenderProps, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import { Label } from 'reactstrap';
import { NcSlideConfigFormData, NcSlideshowFormData } from '../subpages/NcSlideshows/NcSlideshowDetails';
import { MediaSelectButton, MediaSelector } from '../../../components';
import styles from './NcSlideConfigMediaSelect.module.scss';
import { Profile } from '../../../../domain';
import { MediaItem } from '../../Advanced/subpages/Channels/ChannelInfoPairs/MediaItem';
import { NarrowcastingHelper } from '../NarrowcastingHelper';

interface Props {
  magazine: number,
  profile: Profile,
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  fieldName: FieldPath<NcSlideConfigFormData>
  activeIndex: number
  label?: string
  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSlideConfigMediaSelect = ({ magazine, profile, field, fieldName, activeIndex, label }: Props) => {

  const [mediaSelector, setMediaSelector] = useState(false);

  const openMediaSelector = () => {
    setMediaSelector(true);
  }
  const closeMediaSelector = () => {
    setMediaSelector(false);
  }

  const onMediaHandlerSelect = async (mediaUrl: string[]) => {
    NarrowcastingHelper.setValueOfSlideConfigField(field, activeIndex, fieldName, mediaUrl[0])
    closeMediaSelector();
  }

  const clearSelectedImage = () => {
    NarrowcastingHelper.setValueOfSlideConfigField(field, activeIndex, fieldName, '')
  }

  const val = NarrowcastingHelper.getValueOfSlideConfigField(field, activeIndex, fieldName);


  return (
    <>
      <Label>{label}</Label>
      <div className={styles.NcSlideConfigMediaSelect}>
        <div className={styles.mediaSelectButtonWrapper}>
          <MediaSelectButton
            inputClass={styles.mediaSelectButton}
            text={`Click here to set image`}
            onClick={openMediaSelector}
            type={'images'}
          />
          {val &&
            <div style={{ marginTop: '8px' }}>
              <MediaItem magazine={magazine} url={val} h={38} w={38} onRemove={clearSelectedImage} />
            </div>
          }
        </div>
        {mediaSelector &&
          <MediaSelector {...{ magazine, profile }}
            closeHandler={closeMediaSelector}
            selectHandler={onMediaHandlerSelect}
            restrictedMediaTypes={['images']}
            dedicatedBucket={`${magazine}_nc`}
            dedicatedPart='protected'
          />}
      </div>
    </>
  )
}
