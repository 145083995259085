import React, { ReactNode } from 'react';
import styles from './FullPageModal.module.scss';
import classNames from 'classnames';

interface Props {
  children?: ReactNode
  separator?: boolean
  title: string
  titleButton?: JSX.Element
}

export const FullPageModalSettingsSection = ({ children, separator, title, titleButton }: Props) => {
  return (
    <>
      {separator && <hr />}
      <div className={classNames(styles.FullPageModalSettingsSection)}>
        <div className={styles.FullPageModalSettingsSectionHeader}>
          <div className={styles.FullPageModalSettingsSectionTitle}>{title}</div>
          {titleButton}
        </div>
        <div className={styles.FullPageModalSettingsSectionContent}>
          {children}
        </div>
      </div>
    </>
  )
}
