import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import classnames from 'classnames';
import '../../css/TabPageNavigation.min.css';

import { Channels, Sources, Monitoring, People, Articles, Audiences, NotificationCenter, Sharepoint } from './subpages';
import { Profile } from '../../../domain';
import { Translations } from '../../translations';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import * as ph from '../../utils/routerHelper';
import { WidgetManager } from '../../modules';
import { EdScreenKey } from '../../accessRights/domain';
import { AccessRightsHelper } from '../../utils';
import { ED_PAGES } from '../../accessRights/accessRights';
import _ from 'lodash';

export interface Props extends RouteComponentProps {
  magazine: string,
  profile: Profile,
  updateProfile: () => void
}

export class Advanced extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  toggleTab(tab: string) {
    ph.setQueryParams(this.props.history, { tab });
  }
  shouldUserAccessPage = (page: EdScreenKey) => {
    const { profile } = this.props;
    return AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, page, 'advanced');
  }
  render() {
    const { magazine, profile, updateProfile } = this.props;
    const activeTab = ph.getQueryParam(this.props.location, 'tab');
    if (!activeTab) {
      if (this.shouldUserAccessPage('channels')) {
        return <Redirect to='advanced?tab=channels' />
      }
      return <Redirect to={`advanced?tab=${AccessRightsHelper.findFirstPageKeyWithAccess(profile, _.filter(ED_PAGES, (p) => p.parentPage == 'advanced'))}`} />
    }
    const tr = Translations.Translate(Translations, 'Modules');

    return (
      <div id="Advanced" className={'TabPageNavigation'}>
        {Translations.EnableTranslations(Translations)}
        <div className="PageTitle">{tr('advanced')}</div>
        <Nav tabs>
          {this.shouldUserAccessPage('channels') && <NavItem><NavLink className={classnames({ active: activeTab === 'channels' })} onClick={() => { this.toggleTab('channels') }}>{tr('channels')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('sources') && <NavItem><NavLink className={classnames({ active: activeTab === 'sources' })} onClick={() => { this.toggleTab('sources') }}>{tr('sources')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('articles') && <NavItem><NavLink className={classnames({ active: activeTab === 'articles' })} onClick={() => { this.toggleTab('articles') }}>{tr('articles')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('people') && <NavItem><NavLink className={classnames({ active: activeTab === 'people' })} onClick={() => { this.toggleTab('people') }}>{tr('people')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('audiences') && <NavItem><NavLink className={classnames({ active: activeTab === 'audiences' })} onClick={() => { this.toggleTab('audiences') }}>{tr('audiences')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('notificationCenter') && profile.edConfig?.notificationCenter && <NavItem><NavLink className={classnames({ active: activeTab === 'notificationCenter' })} onClick={() => { this.toggleTab('notificationCenter') }}>{tr('notificationCenter')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('widgets') && <NavItem><NavLink className={classnames({ active: activeTab === 'widgets' })} onClick={() => { this.toggleTab('widgets') }}>{tr('widgets')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('sharepoint') && profile.edConfig?.sharepoint && <NavItem><NavLink className={classnames({ active: activeTab === 'sharepoint' })} onClick={() => { this.toggleTab('sharepoint') }}>{tr('sharepoint')}</NavLink></NavItem>}
          {/* {this.shouldUserAccessPage('monitoring') && <NavItem><NavLink disabled className={classnames({active: activeTab === 'monitoring'})} onClick={()=>{this.toggleTab('monitoring')}}>{tr('monitoring')}</NavLink></NavItem>} */}
        </Nav>
        <TabContent activeTab={activeTab}>
          {this.shouldUserAccessPage('channels') && <TabPane tabId="channels">{activeTab === 'channels' && <Channels magazine={magazine} profile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('sources') && <TabPane tabId="sources">{activeTab === 'sources' && <Sources magazine={magazine} profile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('articles') && <TabPane tabId="articles">{activeTab === 'articles' && <Articles magazine={parseInt(magazine)} profile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('people') && <TabPane tabId="people">{activeTab === 'people' && <People magazine={parseInt(magazine)} propProfile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('audiences') && <TabPane tabId="audiences">{activeTab === 'audiences' && <Audiences magazine={parseInt(magazine)} profile={profile} updateProfile={updateProfile} />}</TabPane>}
          {this.shouldUserAccessPage('notificationCenter') && profile.edConfig?.notificationCenter && <TabPane tabId="notificationCenter">{activeTab === 'notificationCenter' && <NotificationCenter magazine={parseInt(magazine)} profile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('widgets') && <TabPane tabId="widgets">{activeTab === 'widgets' && <WidgetManager magazine={parseInt(magazine)} />}</TabPane>}
          {this.shouldUserAccessPage('sharepoint') && profile.edConfig?.sharepoint && <TabPane tabId="sharepoint">{activeTab === 'sharepoint' && <Sharepoint magazine={parseInt(magazine)} profile={profile} />}</TabPane>}
          {/* {this.shouldUserAccessPage('monitoring') && <TabPane tabId="monitoring">{activeTab === 'monitoring' && <Monitoring magazine={parseInt(magazine)} profile={profile}/>}</TabPane>} */}
        </TabContent>
      </div>
    );
  }
}
export const AdvancedWithRouter = withRouter(Advanced);
export default Advanced;
