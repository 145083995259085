import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { EdErrorHandler, OrtecLoader } from '../../widgets';
import DataTable, { ExtendedColumnDescription } from '../DataTable/DataTable';
import styles from './FeedSelector.module.scss';
import { ConnectedFeed } from '../../../domain';
import { API } from '../../apis';
import { JsonHelper } from '../../utils';

interface Props {
  magazine: number,
  selected: ConnectedFeed[],
  onChangeSelection: (feed: ConnectedFeed, isSelected: boolean) => void,
  multiSelect?: boolean
}

export const FeedSelector = ({ magazine, selected, onChangeSelection, multiSelect }: Props) => {

  const [feeds, setFeeds] = useState<ConnectedFeed[]>([]);
  const [dataLoading, setDataLoading] = useState(true);


  useEffect(() => {
    loadContentFeeds();
  }, [])

  const loadContentFeeds = async () => {
    try {
      setDataLoading(true);
      const { data: feeds } = await API.feeds.getContentFeeds(magazine);
      setFeeds(_.map(feeds, (f) => { return { id: f.id, label: _.get(JsonHelper.jsonParse(f.options), 'displayName', '') || f.name } }));
    } catch (error) {
      EdErrorHandler(error, 'getting magazine content feeds');
      setFeeds([])
    } finally {
      setDataLoading(false);
    }
  }

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      // sort: true,
      hidden: true,
      text: 'Id',
    },

    {
      dataField: 'label',
      sort: true,
      text: 'Name',
      formatter: (cell: any, row: ConnectedFeed) => {
        return `[${row.id}] ${row.label}`;
      },
      sortValue: (cell: any, row: ConnectedFeed) => {
        return `[${row.id}] ${row.label}`;
      }
    },
  ];

  return (
    <div className={styles.FeedSelector}>
      <DataTable
        defaultSorted={[{
          dataField: 'id',
          order: 'asc'
        }]}
        selectRow={{
          mode: multiSelect ? 'checkbox' : 'radio',
          clickToSelect: true,
          selected: _.map(selected, 'id'),
          onSelect: onChangeSelection
        }}
        columns={columns}
        data={feeds}
        keyField={'id'}
        onRowClick={() => { }}
      />
      {dataLoading && <OrtecLoader size={'medium'} />}
    </div>
  )
}
