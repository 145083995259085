import React, { Component, Fragment } from 'react';
import { Input, } from 'reactstrap';
import * as _ from 'lodash';
import * as Api from '../../../../api';
import { Profile, Form, FormQuestion } from '../../../../../domain';

import './css/FormDetails.min.css';
import { Translations } from '../../../../translations';
import Toggle from 'react-toggle';
import "react-toggle/style.css"

import * as FormsHelper from './FormsHelper';
import { SwalSuccess, OrtecLoader, EdErrorHandler, LoadingButton, Swal } from '../../../../widgets';

import FormGenericConfig from './FormGenericConfig';
import FormBuilder from './FormBuilder';
import { initTippy, TippyReact } from '../../../../components/TippyReact';


export interface Props {
  magazine: string,
  profile: Profile,
  formId: number,
  newFormType?: string,
  closeHandler: (updateOverview?: boolean) => void
}

export interface State {
  form: Form,
  channels?: any[],
  notFound?: boolean,
  loading: boolean,
  saveLoading: boolean,

}

export class FormDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // if(props.newFormType){
    //   emptyForm.type = props.newFormType;
    // }
    this.state = {
      loading: true,
      saveLoading: false,
      form: FormsHelper.getEmptyForm(props.newFormType ? props.newFormType : ''),
    }
  }

  componentDidMount() {
    if (this.props.formId == -1) {
      this.setState({ loading: false })
    } else {
      this.getForm();
    }
    this.getMagazineChannels();
    this.initTooltips();
  }

  initTooltips() {
    initTippy('#SurveySectionBuilder');
  }

  getForm = () => {
    this.setState({ loading: true });
    Api.getForm(parseInt(this.props.magazine), this.props.formId).then((resp) => {

      if (!resp.data || resp.data.error) {
        console.log('Error in getting source');
        this.setState({ form: FormsHelper.getEmptyForm(''), loading: false, notFound: true });
      }
      let config;
      try {
        config = JSON.parse(resp.data.config);
        _.set(config, 'config.position.index', _.toInteger(_.get(config, 'config.position.index')));
        let positions = _.get(config, `config.positions`);
        if (!positions || _.isEmpty(positions)) {
          positions = [_.get(config, `config.position`)];
          _.set(config, `config.positions`, positions);
        }
      } catch (error) {
        config = null;
      }
      const newForm = { ...resp.data, config: config };
      this.setState({ form: newForm, loading: false });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
        this.setState({ form: FormsHelper.getEmptyForm(''), loading: false, notFound: true });
      });
  }

  getMagazineChannels = () => {
    Api.getMagazineChannels(parseInt(this.props.magazine)).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting magazine channels');
        this.setState({ channels: [] });
      }

      this.setState({ channels: resp.data.channels });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
      });
  }

  getAudiencesIds = () => {
    const { profile } = this.props;

    let audiences: number[] = [];

    if (!_.isEmpty(profile.audienceIds)) {
      audiences = _.map(profile.audienceIds, _.toInteger);
    }

    if (profile.globalAudience) {
      audiences.unshift(1);
    }

    return audiences;

  }

  closeHandler = () => {
    this.props.closeHandler();
  }


  changeTitle = (e: any) => {
    const v = e.target.value;
    const { form } = this.state;
    if (!form) {
      return;
    }
    this.setState({ form: { ...form, title: v } })
  }

  changeStatus = (status: 1 | 2 | 3) => {
    const { form } = this.state;
    this.setState({ form: { ...form, status } })
  }

  saveAsDraft = () => {
    const { form } = this.state;
    this.setState({ form: { ...form, status: 1 } }, this.save);
  }

  saveAndPublish = async () => {
    const { form } = this.state;

    if (!_.get(form, 'config.config.autoOptIn')) {
      const { value: confirm } = await Swal.fire({
        title: `Attention!`,
        text: `Are you sure you want publish this form with Disable anonymous submissions off? If Disable anonymous submissions is off, all responses will be anonymous and you will not be able to trace them back to users.`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, save & publish it!`,
        focusCancel: true,
      });
      if (!confirm) {
        return;
      }
    }

    this.setState({ form: { ...form, status: 2 } }, this.save);
  }

  save = async () => {
    const { form } = this.state;
    if (!form) {
      return;
    }

    try {
      this.setState({ saveLoading: true });

      const newForm = {
        ...form,
        config: {
          ...form.config,
          questions: _.map(form.config.questions, q => ({
            ...q,
            minSelected: q.minSelected ? Number(q.minSelected) : undefined,
            maxSelected: q.maxSelected ? Number(q.maxSelected) : undefined,
          })),
          config: {
            ...form.config.config,
            positions: (form.config.config.positions && form.config.config.positions[0] && form.config.config.positions[0].channel == '-') ? [form.config.config.positions[0]] : form.config.config.positions,
            position: (form.config.config.positions && !_.isEmpty(form.config.config.positions)) ? form.config.config.positions[0] : FormsHelper.emptyPosition
          }
        }
      };
      await Api.saveForm(parseInt(this.props.magazine), newForm);
      await SwalSuccess.fire({
        // type: 'success',
        title: 'Success!',
        text: `Form has been saved successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 1000,
        // footer: `<div class="alert alert-error">${resp.data.error}</div>`
      });
      this.props.closeHandler(true);

    } catch (error) {
      EdErrorHandler(error, 'saving form');
    } finally {
      this.setState({ saveLoading: false });
    }

  }

  // typeName = (type: string) => {
  //   switch(type){
  //     case 's_poll':
  //       return 'Poll'
  //     case 'survey':
  //       return 'Survey';
  //     default:
  //       return _.capitalize(type);
  //   }
  // }

  changeForm = (form: Form) => {
    this.setState({ form: form });
  }


  render() {
    const { magazine, formId, profile } = this.props;
    const { form, loading, notFound, channels, saveLoading } = this.state;
    const tr = Translations.Translate(Translations, 'Forms');

    // const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>;
    const notFoundMessage = <div className="notFoundContainer alert alert-danger">{tr('formNotFound')}</div>;

    return (
      <div id="FormDetails">
        {Translations.EnableTranslations(Translations)}
        <div className='header'>
          {/* <i className="material-icons closeIcon" onClick={this.closeHandler.bind(this)}>close</i> */}
          <img src="/assets/icons/bt_close_bigger.svg" onClick={this.closeHandler.bind(this)} style={{ cursor: 'pointer' }} />
          {loading || notFound ? '' :
            <Fragment>
              {/* <span className="headerTitle">{formId == -1?`${tr('create')} ${this.typeName(form?form.type:'')}`:`${tr('update')} ${this.typeName(form?form.type:'')} (id: ${formId})`} </span> */}
              <Input className='titleInput' value={form.title} placeholder={`Enter your ${FormsHelper.typeName(form.type)} name here...`} onChange={this.changeTitle}></Input>
              {form.status == 1 ?
                <Fragment>
                  <TippyReact content={`There are validation errors in your form`} config={{disabled: isValidForm(this.state.form)}}>
                    <div>
                      <LoadingButton loading={saveLoading} onClick={this.saveAsDraft} text={tr('saveAsDraft')} color={'secondary'} disabled={!isValidForm(this.state.form)}/>
                    </div>
                  </TippyReact>
                  <TippyReact content={`There are validation errors in your form`} config={{disabled: isValidForm(this.state.form)}}>
                    <div>
                      <LoadingButton loading={saveLoading} onClick={this.saveAndPublish} text={tr('saveAndPublish')} disabled={!isValidForm(this.state.form)}/>
                    </div>
                  </TippyReact>
                </Fragment>
                :
                <Fragment>
                  <TippyReact content={form.status == 2 ? `Form has been published. Click to mark it as finished.` : `Form has been finished. Click to publish it again.`}>
                    <div>
                      <Toggle checked={form.status == 2} onChange={(e) => this.changeStatus(e.target.checked ? 2 : 3)} />
                    </div>
                  </TippyReact>
                  <TippyReact content={`There are validation errors in your form`} config={{disabled: isValidForm(this.state.form)}}>
                    <div>
                      <LoadingButton loading={saveLoading} disabled={!isValidForm(this.state.form)} onClick={this.save} text={tr('save')} />
                    </div>
                  </TippyReact>
                </Fragment>
              }
            </Fragment>
          }
        </div>
        <div className='content'>
          {loading ? <OrtecLoader /> :
            notFound ? notFoundMessage :
              <Fragment>
                <div className={'leftPart'} style={{ flex: 2 }}>
                  <div className='formBuilderWrapper'>
                    <FormBuilder magazine={magazine} profile={profile} form={form} changeForm={this.changeForm.bind(this)} />
                  </div>
                </div>
                <div className={'rightPart'} style={{ flex: 1, minWidth: '350px' }}>
                  <div className='previewContainer'>
                    <FormGenericConfig magazine={magazine} profile={profile} form={form} changeForm={this.changeForm.bind(this)} channels={channels} />
                  </div>
                </div>
              </Fragment>
          }
        </div>
      </div>
    );
  }
}

export default FormDetails;

// ─── Helper Functions ────────────────────────────────────────────────────────

const isValidForm = (form: Form): boolean => {
  if(!form) {
    return false;
  }

  const { config } = form;
  const hasInvalidQuestions = validateQuestions(config.questions);

  if(hasInvalidQuestions) {
    return false;
  }

  //TODO: Add more validation here if needed
  
  return true;
}

const validateQuestions = (questions: FormQuestion[]): boolean => {
  const questionsStatus = _.map(questions, (q: FormQuestion) => (validateQuestion(q)));
  const invalidQs = _.filter(questionsStatus, q => !q);
  
  return !_.isEmpty(invalidQs);
}

const validateQuestion = (q: FormQuestion): boolean => {
  if(FormsHelper.isMaxLessThanMin(q)) {
    return false;
  }

  if(FormsHelper.isMaxMoreThanAnswers(q) || FormsHelper.isMinMoreThanAnswers(q)) {
    return false;
  }

  if(FormsHelper.isLessThanZero(q.minSelected) || FormsHelper.isLessThanZero(q.maxSelected)) {
    return false;
  }

  return true;
}
