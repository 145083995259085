import React from 'react';
import styles from './SourcesSelect.module.scss';
import Select from 'react-select';
import _ from 'lodash';

interface Props {
  sources: Source[],
  selected: string,
  onChange: (id: string) => void,
}

interface SelectOption {
  value: string,
  label: string
}

export interface Source {
  id: number,
  label: string,
  name: string,
  parentChannels: number[]
}

export const SourcesSelect = ({ sources, selected, onChange }: Props) => {

  const sourcesToSelectOptions = (): SelectOption[] => {
    const chans = _.map(sources, (s) => {
      return {
        value: _.toString(s.id),
        label: `[${s.id}] - ${s.label ?? s.name}`,
      }
    });
    return [...chans]
  }

  const sourcesValue = (): SelectOption | undefined => {
    const options = sourcesToSelectOptions();

    if (!selected || selected == '0') {
      return undefined;
    }
    return _.find(options, (o) => {
      return selected == o.value;
    })
  }

  return (
    <div className={styles.sourcesSelect}>
      <Select
        // styles={isInvalid ? { container: () => { return { border: '1px solid red', borderRadius: '5px' } } } : undefined}
        // isDisabled={readOnly}
        options={sourcesToSelectOptions()}
        placeholder={`Select a source`}
        isClearable={true}
        value={sourcesValue()}
        onChange={(o: SelectOption) => {
          onChange(o?.value || '')
        }}
      />
    </div>
  )
}
