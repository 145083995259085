import React from 'react';
import { GenericModal } from '../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../components/GenericModal/GenericModalHeader';
import { ModalBody } from 'reactstrap';

import styles from './NcSlideCreateModal.module.scss';
import { Control, Controller } from 'react-hook-form';
import { NcSlideshowFormData } from './NcSlideshowDetails';
import _ from 'lodash';
import { NarrowcastingHelper } from '../../NarrowcastingHelper';

interface Props {
  control: Control<NcSlideshowFormData, any>,
  onClose: () => void
  setActiveIndex: (i: number) => void

}

export const NcSlideCreateModal = ({ onClose, control, setActiveIndex }: Props) => {
  return (
    <GenericModal isOpen size={'lg'} toggle={onClose}>
      <GenericModalHeader
        onClose={onClose}
        title={`Add new slide`}
      />
      <ModalBody>
        <div className={styles.tileWrapper}>
          <NcTypeTile {...{ control, onClose, setActiveIndex }} type="imgzine-articles" />
          <NcTypeTile {...{ control, onClose, setActiveIndex }} type="image" />
          <NcTypeTile {...{ control, onClose, setActiveIndex }} type="video" />
          <NcTypeTile {...{ control, onClose, setActiveIndex }} type="weather" />
        </div>
      </ModalBody>
    </GenericModal>
  )
}

interface TileProps {
  type: string
  control: Control<NcSlideshowFormData, any>,
  onClose: () => void
  setActiveIndex: (i: number) => void

}

const NcTypeTile = ({ type, control, onClose, setActiveIndex }: TileProps) => {

  return (
    <Controller
      name={'slides'}
      control={control}
      render={({ field }) => {

        const onSelect = (type: string) => {
          field.onChange([
            ...field.value,
            {
              type,
              renderer: '',
              config: { ...NarrowcastingHelper.slideTypeDefaultConfig(type) },
              sort: _.size(field.value)
            }
          ])
          onClose();
          setActiveIndex(_.size(field.value));
        }

        return (
          <div className={styles.tile} onClick={() => onSelect(type)}>
            <div className={styles.tileIcon}>
              <img src={`/assets/icons/48/slideType-${type}.svg`} />
            </div>
            <div className={styles.tileTitle}>{NarrowcastingHelper.slideTypeToTitle(type)}</div>
          </div>
        )
      }}
    />
  )


}
