import React from 'react';
import { ModalBody } from 'reactstrap';
import { GenericModal } from '../../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../../components/GenericModal/GenericModalHeader';
import { proxifyContent } from '../CmsItemDetails/CmsItemDetails';
import { CmsItemDetailed } from '../CmsDomain';
import ArticlePreview from '../../../../../components/ArticlePreview/ArticlePreview';
import { Article } from '../../../../../../domain';
import _ from 'lodash';
import { JsonHelper } from '../../../../../utils';

interface Props {
  magazine: number,
  item: CmsItemDetailed,
  onClose: () => void
}

export const CmsPreviewer = ({ magazine, item, onClose }: Props) => {
  return (
    <GenericModal isOpen={true} centered size={'xxl'}>
      <GenericModalHeader
        onClose={onClose}
        title={`Preview`}
        icon="/assets/icons/24/views.svg"
      />
      <ModalBody>
        <ArticlePreview magazine={_.toString(magazine)} article={cmsDraftDetailedToArticle(magazine, item)} defaultVariationLanguage={item.variation} disableVariationChange={true} />

      </ModalBody>
    </GenericModal>
  )
}

const cmsDraftDetailedToArticle = (magazine: number, draft: CmsItemDetailed): Article => {
  const { external_id } = draft;
  const { title, cdate, mdate, content, content_scrubbed, author, intro, media } = draft.data;

  const c = proxifyContent(magazine, content || '');

  return {
    id: external_id,
    external_id: external_id,
    title: title || '',
    cdate: cdate || '',
    mdate: mdate || '',
    content: c || '',
    content_scrubbed: content_scrubbed || content || '',
    content_cms: c || '',
    author: author || '',
    intro: intro || '',
    status: 1,
    media: JsonHelper.jsonStringify(media) || '',

  }
}