import React from 'react'
import { media, MediaHelper, UrlProxyMapper } from '@imgzine/imgzine-core-frontend'
import _compact from 'lodash/compact'
import PhotoAlbum, { Photo, PhotoAlbumProps } from "react-photo-album";
import _ from 'lodash';


export interface Props {
  articleId: string
  images: media.Image[]
  urlProxyMapper: UrlProxyMapper,
  // openImageViewer: detailView.OpenImageViewerFunc
}


function GalleryImpl(props: Props & { params?: Partial<PhotoAlbumProps> }) {
  const { urlProxyMapper, images, params } = props
  const items = React.useMemo(
    () => _compact(
      images.map(obj => {
        const link = MediaHelper.extractBestQualityImageLink(obj)
        if (!link) {
          console.warn('image does not have link')
          return undefined
        }
        const ratio = (link.w && link.h) ? (1.0 * link.h / link.w) : 0.75
        return {
          image: obj,
          photo: {
            src: urlProxyMapper(link.url, { w: 800 }),
            width: 1,
            height: ratio
          }
        }
      })
    ),
    [urlProxyMapper, images]
  )

  const photos = React.useMemo(() => items.map(e => e.photo), [items])

  return <PhotoAlbum
    {...params}
    layout='rows'
    spacing={4}
    photos={photos}
    onClick={({ index }) => {
      // if (items[index]) {
      //   const current = items[index].image
      //   openImageViewer(current)
      // }
    }}
  />
}


// ─── Inline ──────────────────────────────────────────────────────────────────


const INLINE_PARAMS: Partial<PhotoAlbumProps> = {
  targetRowHeight: 140
}

export function DetailInlineGallery(props: Props) {
  return <GalleryImpl {...props} params={INLINE_PARAMS} />
}


// ─── Featured ────────────────────────────────────────────────────────────────

const FEATURED_PARAMS: Partial<PhotoAlbumProps> = {
  breakpoints: [1200],
  rowConstraints: {
    minPhotos: 1,
    maxPhotos: 4,
  }
}


export function DetailFeaturedSocialGallery(props: Props) {
  const { urlProxyMapper, images, articleId } = props
  const rows = React.useMemo(() => partitionImages(images), [images])
  const rowKeys = rows.map(row => row.map(img => img.url).join('_'))
  return <>
    {rows.map((row, i) => <div key={i + rowKeys[i]} style={{ paddingTop: i === 0 ? '0px' : '4px' }}>
      <GalleryImpl images={row} articleId={articleId} urlProxyMapper={urlProxyMapper} params={FEATURED_PARAMS} />
    </div>
    )}
  </>
}


function partitionImages(images: media.Image[]): media.Image[][] {
  const distribution = [1, ...calculateDistribution(images.length - 1)]
  let added = 0
  return distribution.map((count): media.Image[] => {
    const row = images.slice(added + 0, added + count)
    added += count
    return row
  })
}

/**
 * https://o4c.atlassian.net/browse/OFCPD-1832?focusedCommentId=104543
 */
function calculateDistribution(thumbnails: number): number[] {
  // For less than 5 thumbnails, they all fit in one row.
  if (thumbnails === 0) {
    return [];
  }
  if (thumbnails <= 4) {
    return [thumbnails];
  }

  let rows = [];
  let remainingThumbnails = thumbnails;

  // Calculate the distribution
  while (remainingThumbnails > 0) {
    // Determine the best fit for the current row
    for (let i = 4; i >= 2; i--) {  // Start from 4 and go down to 2
      // Check if remaining thumbnails can be distributed in the next rows
      if (remainingThumbnails - i >= i - 1) {
        rows.push(i);
        remainingThumbnails -= i;
        break;
      } else if (remainingThumbnails - i === 0) {  // Exact fit for the last row
        rows.push(i);
        remainingThumbnails -= i;
        break;
      }
    }
  }

  return _.reverse(rows);
}