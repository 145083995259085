import React from 'react';
import { Profile } from '../../../../../domain';
import { ImageComponent } from './components/ImageComponent';
import { TextWithUIComponent } from './components/TextWithUIComponent';
import { NlTemplateConfiguration_Footer } from './domain';
import styles from './NewsletterTemplatesetDetails.module.scss';
import { DEFAULT_CONFIGURATION_FOOTER } from './defaults';

interface Props {
  magazine: number,
  profile: Profile,
  configuration: NlTemplateConfiguration_Footer,
  onChange: (newConfiguration: NlTemplateConfiguration_Footer) => void
}

export const NewsletterTemplateFooter = ({ magazine, profile, configuration, onChange }: Props) => {

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>footer</div>
      </div>
      <div className={styles.sectionContent}>
        <TextWithUIComponent
          name={`Footer text`}
          configuration={configuration.footerText}
          onChange={(c) => { onChange({ ...configuration, footerText: c }) }}
          placeholder={'Footer text...'}
          longText
        />
        <hr />
        <ImageComponent
          {...{ magazine, profile }}
          name={`Footer image`}
          extraText={`(640px width)`}
          validImageWidth={640}
          configuration={configuration.image}
          onChange={(c) => onChange({ ...configuration, image: c })}
        />
        <hr />
        <TextWithUIComponent
          name={`Unsubscribe link`}
          configuration={configuration.unsubscribeLink || { ...DEFAULT_CONFIGURATION_FOOTER.unsubscribeLink }}
          onChange={(c) => { onChange({ ...configuration, unsubscribeLink: c }) }}
        // disableTextUI={!configuration.openInBrowserLink.enabled && !configuration.downloadAppLink.enabled}
        />
      </div>
    </div>
  )
}
