import React from 'react'
import _find from 'lodash/find';
import _get from 'lodash/get';
import _compact from 'lodash/compact';
import urlParse from 'url-parse';
import cn from 'classnames'

import { UrlProxyMapper, MediaHelper, media } from '@imgzine/imgzine-core-frontend';

import styles from './DetailContent.module.scss'
import ExternalLink from './DetailContentLink'

import { ArticleDetailStyles } from './ArticlePreview'
import DetailMediaEmbeddedVideo from './DetailMediaEmbeddedVideo';
import { DetailInlineGallery } from './DetailGallery';
import DetailMediaUnsupportedObject from './DetailMediaUnsupportedObject'
import DetailMediaScriptOrIFrame from './DetailMediaScriptOrIFrame';
import DetailMediaAddinVideo from './DetailMediaAddinVideo';

// stubs

const mediaProxy = { forAttachment: (url: string) => url }
function proccessAudioVideoUrl(link: string): string {
  return urlParse(link).host.indexOf('imgzine.com') > -1
    ? mediaProxy.forAttachment(link)
    : link
}

/////

const getImagesOfGallery = (obj: media.Gallery): media.Image[] =>
  (obj.images || []).concat(obj.gallery || [])

export type DetailMediaRenderer = (mediaObj: media.Media) => JSX.Element | null

export const createMediaRenderer = (
  magazine: string,
  articleId: string,
  urlProxyMapper: UrlProxyMapper,
  classes: ArticleDetailStyles
): DetailMediaRenderer => {
  return (mediaObj) => {
    switch (mediaObj.kind) {
      case 'gallery':
        return <DetailInlineGallery
          articleId={articleId}
          images={getImagesOfGallery(mediaObj)}
          urlProxyMapper={urlProxyMapper}
        // openImageViewer={()=>{}}
        />
      case 'pdf':
        {
          const url = mediaObj.url || ''
          const label = mediaObj.label
            || url.substr(url.lastIndexOf('/')).replace(/\.pdf/i, '')
            || 'pdf';
          return <ExternalLink to={mediaProxy.forAttachment(url)} className={classes.link}>{label}</ExternalLink>
        }
      case 'image': {
        const imageLink: MediaHelper.ImageLink | undefined = MediaHelper.extractBestQualityImageLink(mediaObj);
        if (imageLink === undefined) {
          return null
        }
        const imageWidth = MediaHelper.extractInlineImageWidth(mediaObj) || imageLink.w
        const imageSrc = urlProxyMapper(imageLink.url, { w: MediaHelper.extractInlineImageWidth(mediaObj) || imageLink.w });
        const meta = mediaObj.meta || {};
        const mediaObjectClasses = _compact(mediaObj.class?.split(/\s/) || [])
        return <div className={styles.inlineImage} >
          <div className={cn(
            classes.image,
            { featured: mediaObj.featured }, //maybe used in custom.css?

            // if right, left or center apply that...
            mediaObjectClasses?.indexOf('right') > -1 ? classes.imageRight
              : mediaObjectClasses?.indexOf('left') > -1 ? classes.imageLeft
                : mediaObjectClasses?.indexOf('center') > -1 ? undefined //no class for center
                  // ... else float left small images
                  : (imageWidth !== undefined && imageWidth <= 320) ? classes.imageLeft
                    : undefined
          )}>
            <img
              src={imageSrc}
              alt={(meta.alt ? meta.alt : "")}
            />
            {meta.caption && meta.caption.length ? <div className={cn(styles.caption, classes.caption)}>{meta.caption}</div> : null}
          </div>
        </div>
      }
      case 'video':
        return <DetailMediaEmbeddedVideo mediaObj={mediaObj} />
      case 'audio': {
        if (!mediaObj.url) {
          return null
        }
        const src = proccessAudioVideoUrl(mediaObj.url)
        return <div>
          <audio controls>
            <source src={src} />
            Your browser does not support the audio tag.
          </audio>
        </div>
      }
      // case 'audio-list':
      //   return <DetailAudioList mediaObj={mediaObj} proccessAudioVideoUrl={proccessAudioVideoUrl} />
      case 'video-file': {
        const videoURL = mediaObj.video || mediaObj.url;
        if (!videoURL) {
          return null
        }
        const src = proccessAudioVideoUrl(videoURL)
        return <div className={styles.videoFile1}>
          <video src={src} className={styles.videoFile2} controls>Your browser does not support the video tag</video>
        </div>
      }
      case 'soundcloud': {
        if (!mediaObj.url) {
          return null
        }
        return <iframe title={'soundcloud'} frameBorder='no' width='100%' height={mediaObj.h || 166} scrolling='no' src={mediaObj.url}></iframe>
      }
      case 'addin-vimeo':
        return <DetailMediaAddinVideo magazine={magazine} mediaObj={mediaObj} />
      // case 'embedded-tweet':
      //   return <EmbeddedTweet tweetId={mediaObj.tweetId} />
      // case 'o4c-widget':
      //   return <O4CWidget mediaObj={mediaObj} />
      // case 'o4c-form':
      //   return <O4CForm mediaObj={mediaObj} key={mediaObj.id} />
      case 'script':
      case 'iframe':
        return <DetailMediaScriptOrIFrame mediaObj={mediaObj} />
      default:
        // console.warn(`cannot render media object type:${mediaObj.type} kind:${mediaObj.kind}`)
        // return null
        return <DetailMediaUnsupportedObject mediaObj={mediaObj} />
    }
  }
}