import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Button } from 'reactstrap';
import { Profile } from '../../../../../domain';
import { API } from '../../../../apis';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import styles from './ChatGroups.module.scss';
import { ChatGroup } from './domain';
import { MembersModal } from './MembersModal';

export interface Props {
  magazine: number,
  profile: Profile
}

export const ChatGroups = ({ magazine, profile }: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [chatGroups, setChatGroups] = useState<ChatGroup[]>([]);
  const [viewMembersGroup, setViewMembersGroup] = useState<ChatGroup>();

  useEffect(() => {
    loadChatGroups();
  }, []);

  const loadChatGroups = async () => {
    try {
      setLoading(true);
      const { data } = await API.chatGroups.getMagazineChatGroups(magazine);
      setChatGroups(data);
    } catch (error) {
      EdErrorHandler(error, `getting chat groups`);
    } finally {
      setLoading(false);
    }
  }

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'name',
      sort: true,
      text: '',
      sortCaret: (order, column) => {
        return null
      }
    },
    {
      dataField: 'totalMembers',
      sort: true,
      text: 'Members',
      headerStyle: { width: '150px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img data-tippy-content={'Members'} src={'/assets/icons/16/users.svg'} /><span></span></Fragment>
      }
    },
    {
      dataField: 'totalMessages',
      sort: true,
      text: 'Messages',
      headerStyle: { width: '150px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img data-tippy-content={'Messages'} src={'/assets/icons/16/comments.svg'} /><span></span></Fragment>
      }
    },
    {
      dataField: 'messagesPerMember',
      // isDummyField: true,
      sort: true,
      text: 'Messages Per Member',
      headerStyle: { width: '150px' },
      formatter: (cell, row: ChatGroup) => {
        return cell || 0;
      },
      sortValue: (cell, row) => cell || 0,
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img data-tippy-content={'Messages Per Member'} src={'/assets/icons/16/messages-users.svg'} /><span></span></Fragment>
      }

    },
    {
      dataField: 'dfActions',
      sort: false,
      isDummyField: true,
      text: '',
      headerStyle: { width: '0px' },
      classes: 'actionsColumn',
      formatter: (cell, row) => { return <div className='actionHoveringDiv'><Button color={'secondary'} onClick={() => openMembersModal(row)}>View members</Button></div> }
    }

  ];

  const openMembersModal = (group: ChatGroup) => {
    setViewMembersGroup(group);
  }
  const closeMembersModal = () => {
    setViewMembersGroup(undefined);
  }

  return (
    <div className={styles.ChatGroups}>
      {loading ? <OrtecLoader /> :
        <DataTable
          keyField={'id'}
          data={chatGroups}
          columns={columns}
          onRowClick={() => { }}
        // onCreateNew={()=>{}}
        />
      }
      {viewMembersGroup && <MembersModal {...{ magazine, group: viewMembersGroup, onClose: closeMembersModal }} />}
    </div>
  )
}

// export default ChatGroups
