import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { v4 as uuidV4 } from 'uuid'

import { BucketPart, MEDIA_TYPES, MediaType } from '../domain';
import { Media } from '../../../../domain';
import { useTranslations } from '../../../translations';
import { isVimeoBucket, pathJoin, useInvalidateBucketMedia } from '../MediaManagerSelectorHelper';
import { Button, Input } from 'reactstrap';
import { SingleSelect } from '../../ReactSelect/ReactSelect';
import { PageSwitch } from '../../PageSwitch/PageSwitch';
import MediaUploadModal from '../MediaUploadModal/MediaUploadModal';
import { MediaHelper } from '../../../utils';
import styles from './MediaBucketExplorer.module.scss'
import { OrtecLoader } from '../../../widgets';

import ReactList from 'react-list';
import MediaBucketExplorerBreadcrumb from './MediaBucketExplorerBreadcrumb';
import MediaBucketExplorerListHeader from './MediaBucketExplorerListHeader';
import MediaBucketExplorerGridFolder from './MediaBucketExplorerGridFolder';
import MediaBucketExplorerListFolder from './MediaBucketExplorerListFolder';
import MediaBucketExplorerGridFile from './MediaBucketExplorerGridFile';
import MediaBucketExplorerListFile from './MediaBucketExplorerListFile';


interface Props {
  magazine: number
  bucket: string
  part: BucketPart
  allMedia: Media[]
  loading: boolean
  onMediaDelete: (m: Media) => void,
  listModeDefault: boolean

  //only on MediaSelector
  restrictedMediaTypes?: MediaType[],
  onMediaSelect?: (m: Media) => void,
  isMediaSelected?: (m: Media) => boolean
}


type SortType = 'byDate' | 'byName'

export interface FolderI {
  parentPath: string
  folderName: string;
  // files: Media[];
  filesCount: number
  lastModified: string
}

interface FilesAndFolders {
  folders: FolderI[];
  files: Media[];
}


export default function MediaBucketExplorer(props: Props) {

  // media types hooks
  const restrictedMediaTypes: MediaType[] | undefined = useMemo(
    () => props.restrictedMediaTypes || (isVimeoBucket(props.bucket) ? ['videos'] : undefined),
    [props.bucket, props.restrictedMediaTypes]
  )
  const [mediaType, setMediaType] = useState<MediaType | undefined>(getInitMediaType(restrictedMediaTypes));
  React.useEffect(
    () => { setMediaType(getInitMediaType(restrictedMediaTypes)) },
    [restrictedMediaTypes]
  )


  const [listMode, setListMode] = useState<boolean>(props.listModeDefault);
  const [searchText, setSearchText] = useState('');
  const [curFolder, setCurFolder] = useState('');
  const [sortType, setSortType] = useState<SortType>('byDate');
  const [hideFolders, setHideFolders] = useState(false)

  const changeFolder = (s: string) => {
    setSearchText('')
    setCurFolder(s)
  }

  const filteredByCurFolder = useMemo(
    () => curFolder.length === 0
      ? props.allMedia
      : props.allMedia.filter(m => m.folder.startsWith(curFolder)),
    [curFolder, props.allMedia]
  )

  const filteredByCurFolderAndType = useMemo(
    () => getFilteredMediaByType(filteredByCurFolder, mediaType),
    [mediaType, filteredByCurFolder]
  )

  const filesAndFolders = useMemo(
    () => getFoldersAndFilesToShow(filteredByCurFolderAndType, curFolder, searchText),
    [curFolder, filteredByCurFolderAndType, searchText]
  )

  const { folders, files } = useMemo(
    () => sortFilesAndFolders(filesAndFolders, sortType),
    [filesAndFolders, sortType]
  )

  // we force remount of react-list everytime something changes abobe the react-list
  const reactListKey = React.useMemo(
    () => uuidV4(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files, hideFolders, listMode]
  )

  const tr = useTranslations('MediaManagerSelector')

  const mediaTypeOptions: { value: MediaType, label: string }[] = useMemo(
    () => (restrictedMediaTypes || MEDIA_TYPES).map(type => ({ value: type, label: tr(type) })),
    [restrictedMediaTypes, tr]
  )


  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const invalidateBucketMedia = useInvalidateBucketMedia()
  const onUploadModalClose = (reload?: boolean) => {
    setUploadModalOpen(false);
    if (reload) {
      invalidateBucketMedia()
    }
  }

  const selectorMode = props.onMediaSelect !== undefined
  const searchMode = searchText.length > 0
  const ItemComponent = listMode ? MediaBucketExplorerListFile : MediaBucketExplorerGridFile
  const FolderComponent = listMode ? MediaBucketExplorerListFolder : MediaBucketExplorerGridFolder


  return <div className={styles.mediaManagerContainer}>
    {props.loading && <OrtecLoader size={'fullPage'} />}
    <div className={styles.filters}>
      <Input
        type='search'
        className={styles.search}
        placeholder='Search'
        value={searchText}
        onChange={(e) => { setSearchText(e.target.value) }}
        onKeyUp={(e) => { e.stopPropagation() }} // disables ESC bubbling up
      />
      <div className={styles.singleSelect}>
        <SingleSelect
          placeholder='All types'
          isClearable={restrictedMediaTypes === undefined}
          options={mediaTypeOptions}
          value={mediaType}
          onChange={type => { setMediaType(type) }}
        />
      </div>
      <div className={styles.singleSelect}>
        <SingleSelect
          options={sortOptions}
          value={sortType}
          onChange={t => { if (t) { setSortType(t) } }}
        />
      </div>
      <PageSwitch optionLeft={`grid`} optionRight={'list'} isRightActive={listMode} onClick={() => {
        setListMode(!listMode);
        setHideFolders(false)
      }} />
      <Button color={'primary'} className={styles.uploadButton} onClick={() => { setUploadModalOpen(true) }}>
        {mediaType ? `${tr('uploadNew')} ${mediaType}` : tr('uploadNewFiles')}
      </Button>
    </div>
    <div className={styles.gap24} />
    {searchMode && <div className={styles.searchResults}>{`Search results for "${searchText}"`}</div>}
    {curFolder &&
      <MediaBucketExplorerBreadcrumb
        currentFolder={curFolder}
        onChangeFolder={changeFolder}
        onChangeFolderWithoutSearchReset={setCurFolder}
        searchMode={searchMode}
      />
    }
    {
      listMode
        ? <div className={styles.foldersList}>
          {listMode
            && <MediaBucketExplorerListHeader
              searchMode={searchMode}
              selectorMode={selectorMode}
              hideFolders={hideFolders}
              setHideFolders={setHideFolders}
            />
          }
          {!hideFolders
            && folders.map(f => {
              const folderPath = pathJoin(f.parentPath, f.folderName)
              return <FolderComponent
                key={folderPath}
                folder={f}
                searchMode={searchMode}
                onSelect={() => { changeFolder(folderPath) }}
                selectorMode={selectorMode}
              />
            }
            )}
        </div>
        : (!hideFolders && folders.length > 0 &&
          <div className={styles.foldersGrid}>
            {folders.map(f => {
              const folderPath = pathJoin(f.parentPath, f.folderName)
              return <FolderComponent
                key={folderPath}
                folder={f}
                searchMode={searchMode}
                onSelect={() => { changeFolder(folderPath) }}
                selectorMode={selectorMode}
              />
            })}
          </div>
        )
    }

    <div className={listMode ? styles.list : styles.grid}>
      <ReactList
        /*
          We had an issue with ReactList not handling properly cases where list was initially in a not visible position but later on became visible due to less items above.
          e.g. go to another bucket/folder with less folder itesms or click 'Hide folders"
          That resulted in showing only 1 file (equal to default minSize prop) regardless ofthe real size of the files array.
          Problem disappears (missing fields appear) when resizing or scrolling 
          We found out 2 hacks that seem to fix the bug (work independently but also as a combo)
          a) Force a remount of the react-list component on every change that could result in the list changing size or being shifted vertically
          b) Force a minSize={50} i.e. a big enough number so that you always have enough rows to fill the screen. To see rows after row 50 user will have to scroll therefore escape the issue.
        */
        minSize={50}
        key={reactListKey}
        itemRenderer={i => {
          const m = files[i]
          return <ItemComponent
            key={m.resourcePath}
            magazine={props.magazine}
            mediaItem={m}
            onMediaDelete={props.onMediaDelete}
            selected={props.isMediaSelected ? props.isMediaSelected(m) : undefined}
            onSelect={props.onMediaSelect}
            searchMode={searchMode}
            onGoToFolder={() => { changeFolder(m.folder); }}
          />
        }}
        length={files.length}
      />
    </div>


    {uploadModalOpen && <MediaUploadModal
      magazine={props.magazine}
      closeModal={onUploadModalClose}
      bucket={props.bucket}
      part={props.part}
      folderPath={curFolder}
      subFolders={folders.map(f => f.folderName)}
      allBucketMedia={props.allMedia}
    />}
  </div>
}



// ─── Helpers ─────────────────────────────────────────────────────────────────


function getInitMediaType(restrictedMediaTypes?: MediaType[]): MediaType | undefined {
  if (!restrictedMediaTypes || _.isEmpty(restrictedMediaTypes)) {
    return undefined;
  }
  return restrictedMediaTypes[0]
}

function createFolder(parentPath: string, folderName: string, files: Media[]): FolderI {
  return {
    folderName,
    parentPath,
    filesCount: files.length,
    lastModified: _.max(files.map(file => file.lastModified)) || ''
  }
}


function getChildrenFoldersAndfiles(media: Media[], folderPath: string): FilesAndFolders {
  const filteredMedia = folderPath.length === 0
    ? media
    : media.filter(m => m.folder.startsWith(folderPath));

  const depth = folderPath === '' ? 0 : folderPath.split('/').length;
  const groupedFolderNames = _.groupBy(filteredMedia, m => m.folder.split('/')[depth] || '');

  const files = groupedFolderNames[''] || [];
  const folders: FolderI[] = _.map(_
    .omit(groupedFolderNames, ''),
    (folderFiles, folderName) => createFolder(folderPath, folderName, folderFiles)
  )
  return { files, folders }

}

function getAllFoldersOfFiles(media: Media[]): FolderI[] {
  const groupedDescFolderPaths = _.groupBy(media, m => m.folder);
  const folders = _.map(groupedDescFolderPaths, (files, subFolderPath): FolderI => {
    const parts = subFolderPath.split('/')
    const parentPath = parts.slice(0, parts.length - 1).join('/')
    const folderName = _.last(parts) || ''
    return createFolder(parentPath, folderName, files)
  })
  return folders

}

function getFoldersAndFilesToShow(media: Media[], folderPath: string, searchText: string): FilesAndFolders {
  if (!searchText.length) {
    return getChildrenFoldersAndfiles(media, folderPath)
  }
  // perform search
  const toSearch = searchText.toLowerCase()
  const files = media.filter(m => m.fullName.toLowerCase().includes(toSearch))
  const folders = getAllFoldersOfFiles(media).filter(f => f.folderName.toLowerCase().includes(toSearch))
  return { files, folders }
}

function sortFilesAndFolders(filesAndFolders: FilesAndFolders, sortType: SortType): FilesAndFolders {
  return sortType === 'byName'
    ? ({
      folders: _.sortBy(filesAndFolders.folders, folder => folder.folderName),
      files: _.sortBy(filesAndFolders.files, file => file.fullName),
    })
    : ({
      folders: _.reverse(_.sortBy(filesAndFolders.folders, folder => folder.lastModified)),
      files: _.reverse(_.sortBy(filesAndFolders.files, file => file.lastModified)),
    })
}


const sortOptions: { value: SortType, label: string }[] = [
  { value: 'byDate', label: 'Sort by date' },
  { value: 'byName', label: 'Sort by filename' },
]

const getFilteredMediaByType = (media: Media[], activeType: MediaType | undefined) => {
  switch (activeType) {
    case 'images':
      return MediaHelper.includeMediaTypes(media, ['images']);
    case 'videos':
      return MediaHelper.includeMediaTypes(media, ['videos', 'vimeo']);
    case 'audios':
      return MediaHelper.includeMediaTypes(media, ['audios']);
    case 'files':
      return MediaHelper.excludeMediaTypes(media, ['images', 'videos', 'vimeo', 'audios']);
    default:
      return MediaHelper.excludeMediaTypes(media, []);
  }
}

