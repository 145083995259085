import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form as RForm, FormGroup } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';

import './css/FormGenericConfig.min.css';
import { Translations } from '../../../../translations';
import { Form, FormConfig, Profile, FormPosition } from '../../../../../domain';

import Toggle from 'react-toggle';
import { format } from 'util';
import { TargetingWidget, TippyReact } from '../../../../components';
import * as FormsHelper from './FormsHelper';
import { AudiencesTargeting } from '../../../../components/';

export interface Props {
  magazine: string,
  channels?: any[],
  form?: Form
  profile: Profile
  // statsFilter: StatsFilter,
  // articleId: number,
  changeForm: (form: Form) => void
  // closeHandler: () => void
}

export class FormGenericConfig extends Component<Props> {
  constructor(props: Props) {
    super(props);

  }

  changeForm = (value: any, field: string) => {
    if (!this.props.form) {
      return;
    }
    const newForm = { ...this.props.form };
    _.set(newForm, `${field}`, value);
    if (field === 'config.config.sendSubmissions' && value === false) {
      _.set(newForm, `config.config.sendSubmissions`, undefined);
      _.set(newForm, `config.config.sendSubmissionsEmailAddress`, undefined);
    }
    this.props.changeForm(newForm);
  }

  changeFormPositions = (value: FormPosition[]) => {
    this.changeForm(value, `config.config.positions`);
  }

  changeFormPositionsElement = (value: any, index: number, field: string) => {
    const positions = this.getFormPositions();
    const newPositions = _.map(positions, (p, i) => {
      if (i === index) {
        return { ...p, [field]: value }
      }
      return { ...p }
    });

    this.changeFormPositions(newPositions)
  }

  getFormPositions = (): FormPosition[] => {
    const { form } = this.props;
    return (form && form.config.config.positions) ? form.config.config.positions : [];
  }



  renderComponentInput = (position: FormPosition, index: number) => {
    return (
      <Input type="select" value={position.component} onChange={(e) => this.changeFormPositionsElement(e.target.value, index, `component`)}>
        <option value="magazine">Magazine</option>
        <option value="thumb">Thumbnail</option>
        <option value="custom">Custom</option>
      </Input>
    )
  }
  renderChannelInput = (position: FormPosition, index: number) => {
    const { channels } = this.props;
    return (
      <Input type="select" value={position.channel} onChange={(e) => this.changeFormPositionsElement(e.target.value, index, `channel`)}>
        <option value='*'>All channels</option>
        {index == 0 ? <option value='-'>No channel</option> : null}
        <optgroup label={'-------------'}></optgroup>
        {!channels ? null :
          channels.map((c) => {
            return (<option key={c.channel} value={c.channel}>{c.label ? c.label : c.name}</option>);
          })
        }
      </Input>
    )
  }
  renderPositionInput = (position: FormPosition, index: number) => {
    return (<Input type="number" min="1" value={position.index} onChange={(e) => this.changeFormPositionsElement(_.toInteger(e.target.value), index, `index`)}></Input>);
  }

  changeTargeting = (value: number[]) => {
    this.changeForm(value, 'audiences');
  }

  changeCombineAudiencesWithAnd = (value: boolean) => {
    this.changeForm(value, 'combineAudiencesWithAnd');
  }

  renderTargetWidget = () => {
    const { magazine, profile, form } = this.props;
    return (
      <Fragment>
        {/* <TargetingWidget key={`formTargetingWidget-${form && form.audience?form.audience:''}`} magazine={parseInt(magazine)} profile={profile} targetedAudiences={form?form.audience:undefined} changeTargeting={this.changeTargeting.bind(this)} /> */}
        <AudiencesTargeting magazine={_.toInteger(magazine)} profile={profile} changeTargeting={this.changeTargeting.bind(this)} targetedAudiences={form?.audiences || []} combineAudiencesWithAnd={form?.combineAudiencesWithAnd ? true : false} changeCombineAudiencesWithAnd={this.changeCombineAudiencesWithAnd.bind(this)} />
      </Fragment>
    )
  }

  renderPositionElement = (position: FormPosition, index: number, deletable: boolean) => {
    const tr = Translations.Translate(Translations, 'Forms');
    return (
      <div key={index} className="positionElement">
        {!deletable ? null :
          <div className='removeFromChannelDiv'><Button onClick={() => this.removePositionElement(index)}>{tr('removeFromThisChannel')}</Button></div>
        }
        {/* <RForm>
          <FormGroup row>
            <Label md={4}>Channel:</Label>
            <Col md={8}>{this.renderChannelInput(position, index)}</Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>Component:</Label>
            <Col md={8}>{this.renderComponentInput(position, index)}</Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>Position:</Label>
            <Col md={8}>{this.renderPositionInput(position, index)}</Col>
          </FormGroup>
        </RForm> */}
        <RForm>
          <div className='positionRow'>
            <span>Channel:</span>
            {this.renderChannelInput(position, index)}
          </div>
          {position.channel == '-' ? null :
            <div className='positionRow'>
              <span>Position:</span>
              {this.renderPositionInput(position, index)}
              <span>Component:</span>
              {this.renderComponentInput(position, index)}
            </div>
          }
        </RForm>
      </div>
    )
  }

  addPositionElement = (positions: FormPosition[]) => {
    this.changeFormPositions([...positions, FormsHelper.emptyPosition]);
  }
  removePositionElement = (index: number) => {
    const positions = [...this.getFormPositions()];
    const newPositions = _.filter(positions, (p, i) => {
      return (i !== index);
    });
    this.changeFormPositions(newPositions);
  }

  renderPositionWidget = () => {
    const tr = Translations.Translate(Translations, 'Forms');
    const { magazine, form } = this.props;

    if (!form) {
      return;
    }

    const positions = form.config.config.positions;
    if (!positions) {
      return;
    }

    return (
      <div className="section">
        <div className="header">{tr('position')} <Button hidden={(positions[0] && positions[0].channel == '-') ? true : false} onClick={(e) => { this.addPositionElement(positions) }}>{tr(`addToAnotherChannel`)}</Button></div>
        <div className="body">
          {_.map(positions, (p, index) => {
            if (index !== 0 && positions[0] && positions[0].channel == '-') {
              return null;
            }
            return this.renderPositionElement(p, index, index !== 0);
          })}
        </div>
      </div>
    )
  }

  render() {
    const { magazine, form } = this.props;

    const tr = Translations.Translate(Translations, 'Forms');


    return (
      <div id={'FormGenericConfig'}>
        <div className="section">
          <div className="header">{tr('settings')}</div>
          <div className="body">
            <RForm>
              <FormGroup row>
                <Label md={9} style={{ fontWeight: 'bold' }}>Disable anonymous submissions</Label>
                <Col md={3} className='verticalCentered'>
                  <Toggle disabled={form && form.status !== 1 ? true : false} checked={form ? _.get(form, 'config.config.autoOptIn') : false} onChange={(e) => this.changeForm(e.target.checked, 'config.config.autoOptIn')} />
                  {!_.get(form, 'config.config.autoOptIn') &&
                    <TippyReact content={`All responses will be anonymous. This setting is not editable after publishing the form.`}>
                      <i className={'material-icons'} style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '5px', color: '#B00020', cursor: 'default' }}>info</i>
                    </TippyReact>}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md={9}>Allow multiple submissions</Label>
                <Col md={3} className='verticalCentered'><Toggle checked={form ? _.get(form, 'config.config.allowMultipleSubmissions') : false} onChange={(e) => this.changeForm(e.target.checked, 'config.config.allowMultipleSubmissions')} /></Col>
              </FormGroup>
              <FormGroup row>
                <Label md={9}>Allow user to dismiss form</Label>
                <Col md={3} className='verticalCentered'><Toggle checked={form ? _.get(form, 'config.config.allowUserToDismissForm') : false} onChange={(e) => this.changeForm(e.target.checked, 'config.config.allowUserToDismissForm')} /></Col>
              </FormGroup>
              <FormGroup row>
                <Label md={9}>Send submissions to email</Label>
                <Col md={3} className='verticalCentered'><Toggle checked={form && _.get(form, 'config.config.sendSubmissions') ? true : false} onChange={(e) => this.changeForm(e.target.checked, 'config.config.sendSubmissions')} /></Col>
                {!form || !_.get(form, 'config.config.sendSubmissions') ? null :
                  <Col md={12}><Input value={form && _.get(form, 'config.config.sendSubmissionsEmailAddress') ? _.get(form, 'config.config.sendSubmissionsEmailAddress') : ''} placeholder={'Add email address'} onChange={(e) => this.changeForm(e.target.value, 'config.config.sendSubmissionsEmailAddress')}></Input></Col>
                }
              </FormGroup>
              {!form || form.type !== 'quiz' ? null
                :
                <FormGroup row>
                  <Label md={9}>Required correct answers</Label>
                  <Col md={3} className='verticalCentered'><Input type='number' value={form ? _.get(form, 'config.config.requiredCorrectAnswers') : 0} onChange={(e) => this.changeForm(e.target.value, 'config.config.requiredCorrectAnswers')} min={0} style={{ padding: '2px' }} /></Col>
                </FormGroup>
              }
            </RForm>
          </div>
        </div>
        <div className="section">
          <div className="header">{tr('target')}</div>
          <div className="body">{this.renderTargetWidget()}</div>
        </div>
        {this.renderPositionWidget()}

      </div>
    )
  }
}

export default FormGenericConfig;
