import classNames from 'classnames';
import React from 'react';
import { FontSize, TextWithUI } from '../../domain';
import styles from './TextWithUIPlaceholder.module.scss';

interface Props {
  defaultText?: string,
  longText?: boolean
  bold?: boolean
  configuration: TextWithUI
  customClass?: string
}

export const TextWithUIPlaceholder = ({ defaultText, longText, bold, configuration, customClass }: Props) => {
  if (!configuration.enabled) {
    return null;
  }

  return (
    <div className={classNames(fontSizeToClassname(configuration.fontSize), { [styles.longText]: longText, [customClass || '']: customClass ? true : false })} style={{ backgroundColor: configuration.backgroundColor, color: configuration.textColor, fontWeight: bold ? 'bold' : undefined }}>
      <span>{configuration.text || defaultText || ''}</span>
    </div>

  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const fontSizeToClassname = (fontSize: FontSize) => {
  switch (fontSize) {
    case 'small':
      return styles.smallText;
    case 'medium':
      return styles.mediumText;
    case 'large':
      return styles.largeText;
  }
}