import qs from 'query-string';
import * as H from 'history';
import * as _ from 'lodash';

export function setQueryParams(history: H.History, newParams: any) {
  const params = qs.parse(history.location.search)
  const url = `${history.location.pathname}?${qs.stringify({ ...params, ...newParams })}`;
  history.push(url);
}

export function getQueryParams(history: H.History) {
  return qs.parse(history.location.search);
}


export function getQueryParam(history: H.History, param: string): string | undefined {
  return qs.parse(history.location.search)[param];
}

export function getQueryParamNumber(history: H.History, param: string): number | undefined {
  const paramAsStr = qs.parse(history.location.search)[param];
  return paramAsStr ? _.toNumber(paramAsStr) : undefined;
}
