import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment } from 'react'
import { PageListRendererOptions } from 'react-bootstrap-table-next'
import { Col } from 'reactstrap';
import styles from './Pagination.module.scss';


interface Props extends PageListRendererOptions {
    total: number,
    sizePerPage: number,

}
export default function Pagination({ pages, onPageChange, total, sizePerPage }: Props) {
    const min = 1;
    const max = total ? (total / sizePerPage) : 1;

    return (
        <Fragment>
            <Col md={8}>
                <ul className="pagination">
                    {
                        _.map(pages, (p) => {
                            return (
                                <li key={p.page} className={classNames({ 'page-item': true, 'active': p.active })} onClick={() => onPageChange(p.page, sizePerPage)} title={p.title}><a href="#" className="page-link">{p.page}</a></li>
                            )
                        })
                    }
                </ul>
            </Col>
            <Col md={4}>
                <div className="goToPageDiv">
                    <input id="goToPageInput" onKeyDown={(e: any) => {
                        if (e.keyCode === 13) {
                            let val = e.target.value;
                            if (val < min) {
                                val = min;
                            } else if (val > max) {
                                val = max;
                            }
                            onPageChange(val, sizePerPage);
                            e.target.value = '';
                        }
                    }} className='form-control' min={min} max={max} type="number" placeholder={'Go to page'} style={{}} />
                    <button className="btn" onClick={(e) => {
                        var k: any = document.getElementById('goToPageInput');
                        let val = k.value;
                        if (val < min) {
                            val = min;
                        } else if (val > max) {
                            val = max;
                        }
                        onPageChange(val, sizePerPage);
                        k.value = ''
                    }}>{'>'}</button>
                </div>
            </Col>
        </Fragment>
    )
}
