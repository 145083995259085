import React, { Fragment } from 'react';
import { Input } from 'reactstrap';
import { NlTemplateConfiguration_Wrapper } from './domain';
import styles from './NewsletterTemplatesetDetails.module.scss';

interface Props {
  configuration: NlTemplateConfiguration_Wrapper,
  onChange: (newConfiguration: NlTemplateConfiguration_Wrapper) => void
}

export const NewsletterTemplateWrapper = ({ configuration, onChange }: Props) => {

  return (
    <Fragment>
      <div className={styles.flexRow}>
        <div style={{ flex: 1 }}>
          <div className={styles.label} style={{ fontSize: '14px' }}>Font family:</div>
          <Input type={'select'} value={configuration.font_family} onChange={(e) => onChange({ ...configuration, font_family: e.target.value })}>
            <option value={'Roboto'}>Roboto</option>
            <option value={'Open Sans'}>Open Sans</option>
            <option value={'Verdana'}>Verdana</option>
            <option value={'sans-serif'}>sans-serif</option>
          </Input>
        </div>
      </div>
      <div style={{ height: '20px' }}></div>
    </Fragment>
  )
}
