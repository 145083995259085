import React, { ReactNode } from 'react';
import styles from './FullPageModal.module.scss';
import classNames from 'classnames';

interface Props {
  children?: ReactNode
}

export const FullPageModalSettings = ({ children }: Props) => {
  return (
    <div className={classNames(styles.FullPageModalSettings)}>
      {children}
    </div>
  )
}
