import React from 'react';
import { ControllerRenderProps, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import { Input, Label } from 'reactstrap';
import { NcSlideConfigFormData, NcSlideshowFormData } from '../subpages/NcSlideshows/NcSlideshowDetails';
import { NarrowcastingHelper } from '../NarrowcastingHelper';

interface Props {
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  fieldName: FieldPath<NcSlideConfigFormData>
  activeIndex: number
  label?: string
  options: Array<{ value: string, label: string, disabled?: boolean }>
  valueAsNumber?: boolean,
  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSlideConfigSelect = ({ field, fieldName, activeIndex, label, options, valueAsNumber }: Props) => {

  const val = NarrowcastingHelper.getValueOfSlideConfigField(field, activeIndex, fieldName) || '';

  return (
    <>
      <Label>{label}</Label>
      <Input
        type={'select'}
        value={val}
        onChange={(e) => {
          NarrowcastingHelper.setValueOfSlideConfigField(field, activeIndex, fieldName, valueAsNumber ? _.toNumber(e.target.value) : e.target.value)
        }}>
        {_.map(options, (o) => {
          return (
            <option key={o.value} disabled={o.disabled ? true : false} value={o.value}>{o.label}</option>
          )
        })}
      </Input>
    </>
  )
}
