import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent, Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';
import './css/ChannelSelector.min.css'

import { Profile, Channel, Audience } from '../../../../../domain';
import qs from 'query-string';
import moment from 'moment';
import { Translations } from '../../../../translations';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as _ from 'lodash';
import * as Api from '../../../../api';
import { OrtecLoader } from '../../../../widgets';
import { CICChannel } from './domain';

const rootNode: Channel = {
  id: -1,
  name: 'Top level channel',
  label: 'Top level channel',
  channelType: '',
  audiences: [],
  sources: [],
  translations: []
}

export interface Props {
  magazine: string,
  profile: Profile,
  title: string,
  selected?: number[]
  selectionHandler: (id: number, isSelected: boolean) => void
  unselectable?: number[],
  highlighted?: number[],
}
export interface State {
  channels: Channel[],
  expandedRows: number[],
  searchText: string,
  loadingChannels: boolean,

}

export class ChannelSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      channels: [],
      searchText: '',
      expandedRows: [],
      loadingChannels: false
    };
  }

  componentDidMount() {
    this.getCic();
  }



  getCic = () => {
    this.setState({ loadingChannels: true });
    Api.getChannelsInChannel(parseInt(this.props.magazine)).then((resp) => {

      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ channels: [] });
      }
      this.setState({ channels: resp.data ? resp.data : [], loadingChannels: false });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
        this.setState({ loadingChannels: false });
      });
  }

  getAudiencesIds = () => {
    const { profile } = this.props;

    let audiences: number[] = [];

    if (!_.isEmpty(profile.audienceIds)) {
      audiences = _.map(profile.audienceIds, _.toInteger);
    }

    if (profile.globalAudience) {
      audiences.unshift(1);
    }

    return audiences;

  }

  decideNameText = (row: any): string => {

    const { edConfig } = this.props.profile;
    if (!edConfig) {
      return row.name;
    }
    switch (edConfig.channelGraphName) {
      case 'nameOnly':
        return row.name || row.label || '';
      case 'labelOnly':
        return row.label || row.name || '';
      case 'nameLabel':
        return `${row.name || ''} ${row.label ? `(${row.label})` : ''}`;
      case 'labelName':
        return `${row.label || ''} ${row.name ? `(${row.name})` : ''}`;
      default:
        return row.name;
    }
  }

  nameFormatter = (cell: any, row: any) => {
    const { highlighted, unselectable } = this.props;
    const n = this.decideNameText(row);
    const name = <span className={classnames({ highlighted: _.includes(highlighted, row.id), unselectable: _.includes(unselectable, row.id), topLevel: row.id == -1 })}>{n}</span>;
    return <>{this.searchHighlighter(name, row)}</>;
  }


  sourcesFormatter = (cell: any, row: any) => {
    if (row.id == -1) {
      return '';
    }
    return <>{this.searchHighlighter(_.size(cell), row)}</>;
  }

  audienceFormatter = (cell: any, row: any) => {
    const { profile } = this.props;
    const tr = Translations.Translate(Translations, 'Channels');
    const audiences: any[] = [];
    if (_.isEmpty(cell)) {
      return <>{tr('all_employees')}</>;
    }

    cell.map((a: number) => {
      audiences.push(_.get(_.find(profile.audiences, ['id', a]), 'label'));
    });

    if (audiences.length == 1) {
      return <>{audiences[0]}</>;
    } else {
      return <span data-tippy-content={audiences.join(', ')}>{audiences.length}</span>;
    }
  }

  renderToolbar = (props: any) => {
    const tr = Translations.Translate(Translations, 'Channels');
    return (
      <Fragment>
        <Col md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <div className='titleDiv'>{this.props.title}</div>
        </Col>
        <Col md={6}>{props.components.searchField}</Col>
      </Fragment>
    );
  }

  isExpandableRow = (row: any) => {
    return (row.childChannels && row.childChannels.length > 0);
  }

  expandComponent = (row: any) => {
    const { searchText } = this.state;
    if (!searchText && !_.includes(this.state.expandedRows, row.id)) {
      return '';
    }
    const { unselectable } = this.props;
    const options: any = {
      onRowClick: this.onRowClick,
      defaultSearch: searchText ? searchText : undefined,
      expanding: searchText ? this.findAllChannelsIds([], row.childChannels) : undefined
    };
    const expandColumnOptions = {
      expandColumnVisible: true,
      expandColumnBeforeSelectColumn: false
      // columnWidth:30
    }
    const selectRow: any = {
      mode: 'checkbox',
      clickToSelect: false,
      clickToExpand: true,
      selected: this.props.selected,
      onSelect: this.onRowSelect,
      unselectable
    }
    return (
      <BootstrapTable key={`channelsSelector-ch-${row.id}-${this.state.searchText || ''}`} ref={`channelsSelector-ch-${row.id}`} data={row.childChannels} bordered={false} hover={false} search={false} headerStyle={{ display: 'none' }} options={options} expandableRow={this.isExpandableRow.bind(this)} expandComponent={this.expandComponent.bind(this)} expandColumnOptions={expandColumnOptions} version='4' selectRow={selectRow} trClassName={this.trClassFormat}>
        <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
        <TableHeaderColumn dataField='name' tdStyle={{ cursor: 'pointer' }} dataFormat={this.nameFormatter.bind(this)}></TableHeaderColumn>
        <TableHeaderColumn dataField='label' hidden></TableHeaderColumn>
        <TableHeaderColumn dataField='channelType' dataAlign='center' width='200px' dataFormat={this.typeFormatter.bind(this)}>
          {/* <img data-tippy-content={tr("channelType")} src="/assets/icons/16/calendar.svg"/> */}
        </TableHeaderColumn>
        <TableHeaderColumn dataField='audiences' hidden dataAlign='center' width='200px' dataFormat={this.audienceFormatter.bind(this)}>
          {/* <img data-tippy-content={tr("audiences")} src="/assets/icons/16/calendar.svg"/> */}
        </TableHeaderColumn>
        <TableHeaderColumn dataField='sources' dataAlign='center' width='50px' dataFormat={this.sourcesFormatter.bind(this)}>
          {/* <img data-tippy-content={tr("sources")} src="/assets/icons/16/calendar.svg"/> */}
        </TableHeaderColumn>
        <TableHeaderColumn dataField={'childChannels'} dataFormat={this.advancedSearchFormatter.bind(this)} filterFormatted hidden></TableHeaderColumn>

        {/* <TableHeaderColumn dataFormat={this.buttonsFormatter.bind(this)} width='350px'></TableHeaderColumn> */}

      </BootstrapTable>
    );
  }

  onRowClick = (row: any) => {
    const { expandedRows } = this.state;
    if (this.isExpandableRow(row)) {
      if (!_.includes(expandedRows, row.id)) {
        this.setState({ expandedRows: [...expandedRows, row.id] });
      }
    }
    return true;
  }
  findAllChannelsIds = (ids: number[], channels: CICChannel[]) => {
    for (const c of channels) {
      ids.push(c.id);
      if (c.childChannels) {
        this.findAllChannelsIds(ids, c.childChannels);
      }
    }
    return ids;
  }

  onRowSelect = (row: any, isSelected: boolean, e: any) => {
    this.props.selectionHandler(row.id, isSelected)
  }

  advancedSearchFormatter = (cell: any, row: any) => {
    const childChannels = _.map(cell, (c) => {
      return { ...c }
    });

    return <>{JSON.stringify(childChannels)}</>;
  }

  onSearchChange = (searchText: string, colInfos: any, multiColumnSearch: any) => {
    this.setState({ searchText: searchText })
  }

  searchHighlighter = (formattedText: any, row: any, searchableText?: any) => {
    const { searchText } = this.state;

    if (!searchText) {
      return formattedText;
    }
    else if (_.includes(_.lowerCase(JSON.stringify(_.omit({ ...row }, ['childChannels']))), _.lowerCase(searchText))) {
      return <div className={'highlighted'}>{formattedText}</div>
    } else {
      return <div className={'faded'}>{formattedText}</div>;

    }

  }

  typeFormatter = (cell: any, row: any) => {

    return <>{this.searchHighlighter(cell, row)}</>;
  }

  trClassFormat = (row: any, rowIndex: number) => {
    let leafClass;
    if (_.isEmpty(row.childChannels)) {
      leafClass = 'leaf';
    } else {
      leafClass = '';
    }

    return `${leafClass}`;
  }


  render() {
    const { magazine, profile, selected, unselectable } = this.props;
    const { searchText, channels, loadingChannels } = this.state;
    const tr = Translations.Translate(Translations, 'Channels');


    const options: any = {
      onRowClick: this.onRowClick,
      onSearchChange: this.onSearchChange,
      toolBar: this.renderToolbar,
      expanding: searchText ? this.findAllChannelsIds([], channels || []) : undefined
    }

    const expandColumnOptions = {
      expandColumnVisible: true,
      expandColumnBeforeSelectColumn: false
    }

    const selectRow: any = {
      mode: 'checkbox',
      clickToSelect: false,
      clickToExpand: true,
      selected: selected,
      onSelect: this.onRowSelect,
      unselectable
    }


    return (
      <Fragment>
        <div id="ChannelSelector" className="">
          <div className="tableDiv" style={{ position: 'relative' }}>
            {Translations.EnableTranslations(Translations)}
            {loadingChannels ? <OrtecLoader size={'medium'} /> :
              <BootstrapTable ref="channelsSelector" data={[rootNode, ...channels]} bordered={false} search={true} hover={false} options={options} expandableRow={this.isExpandableRow} expandComponent={this.expandComponent} expandColumnOptions={expandColumnOptions} version='4' selectRow={selectRow} trClassName={this.trClassFormat}>
                <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
                <TableHeaderColumn dataField='name' tdStyle={{ cursor: 'pointer' }} dataFormat={this.nameFormatter.bind(this)}></TableHeaderColumn>
                <TableHeaderColumn dataField='label' hidden></TableHeaderColumn>
                <TableHeaderColumn dataField='channelType' dataAlign='center' width='200px' dataFormat={this.typeFormatter.bind(this)}>
                  <img data-tippy-content={tr("channelType")} src="/assets/icons/16/form_type.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='audiences' hidden dataAlign='center' width='200px' dataFormat={this.audienceFormatter.bind(this)}>
                  <img data-tippy-content={tr("audiences")} src="/assets/icons/16/audience.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='sources' dataAlign='center' width='50px' dataFormat={this.sourcesFormatter.bind(this)}>
                  <img data-tippy-content={tr("sources")} src="/assets/icons/16/sources.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField={'childChannels'} dataFormat={this.advancedSearchFormatter.bind(this)} filterFormatted hidden></TableHeaderColumn>
                {/* <TableHeaderColumn dataFormat={this.buttonsFormatter.bind(this)} width='350px'></TableHeaderColumn> */}
              </BootstrapTable>
            }
            {/* <div className="tableFooterDiv"><Button>view more</Button></div> */}
          </div>

        </div>
      </Fragment>

    );
  }
}

export default ChannelSelector;
