import React, { Component } from 'react';
import { Profile, StatsFilter } from '../../../domain';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import * as Api from '../../api';
import './css/TopArticlesStats.min.css';

import { I18n, Translate } from '../../translations';
import { LanguageContextConsumer } from '../../LanguageContext';
import * as _ from 'lodash';
import { ArticleHelper } from '../../utils';

//
// ─── ENABLE TRANSLATIONS FIXED CODE ─────────────────────────────────────────────
//
let Language = 'en';
const enableTranslations = () => {
  return (
    <LanguageContextConsumer>
      {languageContext => { Language = languageContext ? languageContext.language : 'en'; return '' }}
    </LanguageContextConsumer>
  )
}
// ────────────────────────────────────────────────────────────────────────────────

const requestTitlesFor: number[] = [];

export interface Props {
  magazine: string,
  profile: Profile,
  statsFilter: StatsFilter,
  clickArticleHandler: (article: number) => void
}

export interface State {
  // topArticles?: any[],
  // topArticles_time?: any[],
  // topArticles_likes?: any[],
  articles?: any[]
  // sortBy?: string,
  // sortOrder?: any
}



export class TopArticlesStats extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // this.setState({sortBy:'views',sortOrder:'desc'});
    // this.calcChartStats('topArticles');
    // this.calcChartStats('topArticles_time');
    // this.calcChartStats('topArticles_likes');
    this.calcChartStats('allArticles');

  }


  calcChartStats(chart: string) {
    // const statsFilter = {...this.props.statsFilter};
    Api.loadChartData(chart, this.props.statsFilter, this.props.magazine).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ articles: [] });
      }
      // console.log(resp.data);

      this.setState({ articles: resp.data.articles ? this.normalizeData(resp.data.articles) : [] });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }
  normalizeData(articles: any[]) {
    return articles.map((a) => {
      return {
        ...a.value,
        id: a.article,
        title: a.title,
        date: a.end
      }
    })
  }
  timeFormatter(cell: any, row: any) {
    const secs = Math.ceil(cell);
    const minutes = Math.floor(secs / 60);
    const seconds = Math.round(secs - minutes * 60);
    return <>{minutes + 'm' + ' ' + seconds + 's'}</>;
  }
  onRowClick(row: any) {
    this.props.clickArticleHandler(row.id);
  }
  titleFormatter(cell: any, row: any, extraData: any) {
    if (cell === '-') {
      if (_.includes(requestTitlesFor, row.id)) {
        return <>{cell}</>;
      } else {

        requestTitlesFor.push(row.id);
        Api.getArticleTitle(parseInt(this.props.magazine), row.id).then((resp) => {
          if (!resp.data || resp.data.error) {
            console.log(`Error in getting article title: ${resp.data.error}`);
            return;
          }
          const title = _.get(resp.data, 'title', null);
          if (title) {
            this.changeArticleTitle(resp.data.id, title);
          }
        })
          .catch((error) => {
            console.log(`Error: ${error.response.data.error}`);
            this.changeArticleTitle(row.id, '-');
          });

        return (<i className="fa fa-spinner fa-spin"></i>);

      }

    }
    return (<div className='ellipsisDiv'>{ArticleHelper.convertToReadableTitle(cell)}</div>);
  }
  changeArticleTitle(id: number, title: string) {
    const { articles } = this.state;
    const article = _.find(articles, ['id', id]);
    article.title = title;
    this.setState({ articles: articles });

  }
  // getTopArticlesList(){
  //   const { topArticles, topArticles_time, topArticles_likes, sortBy } = this.state;
  //   switch(sortBy){
  //     case 'avgTime':
  //       return topArticles_time;
  //     case 'likes':
  //       return topArticles_likes;
  //     default:
  //       return topArticles;
  //   }
  // }
  // onSortChange(sortName:any,sortOrder:any){
  //   this.setState({sortBy:sortName,sortOrder:sortOrder})
  // }
  render() {
    // const { magazine, statsFilter } = this.props;
    const { articles } = this.state;
    const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>
    // const articles = this.state.allArticles; //this.getTopArticlesList();
    const options: any = {
      defaultSortName: 'views',
      defaultSortOrder: 'desc',
      onRowClick: this.onRowClick.bind(this),
      sizePerPage: 10,
      hideSizePerPage: true,
      paginationSize: 1
      // onSortChange: this.onSortChange.bind(this)
    }

    const tr = Translate(Language, 'Metrics');
    return (
      <div id="TopArticlesStats" className="tableWithTitleBox">
        <div className="titleDiv"><I18n t={'top_articles'} n='Metrics' /></div>
        <div className="tableDiv">
          {enableTranslations()}
          {articles ?
            <BootstrapTable ref="topArticlesTable" data={articles} bordered={false} hover={true} version='4' /*height='285px'*/ options={options} pagination>
              <TableHeaderColumn isKey dataField='id' dataSort={true} hidden></TableHeaderColumn>
              <TableHeaderColumn dataField='title' dataFormat={this.titleFormatter.bind(this)} dataSort={false} tdStyle={{ cursor: 'pointer' }}></TableHeaderColumn>
              <TableHeaderColumn dataField='views' dataSort={true} dataAlign='center' width='100px'>
                <img data-tippy-content={tr('article_views')} src="/assets/icons/views.svg" />
              </TableHeaderColumn>
              <TableHeaderColumn dataField='avgTime' dataFormat={this.timeFormatter.bind(this)} dataSort={true} dataAlign='center' width='100px'>
                <img data-tippy-content={tr("avg_reading_time")} src="/assets/icons/time.svg" />
              </TableHeaderColumn>
              <TableHeaderColumn dataField='likes' dataSort={true} dataAlign='center' width='50px'>
                <img data-tippy-content={tr("likes")} src="/assets/icons/smiles.svg" />
              </TableHeaderColumn>
            </BootstrapTable>
            : loader
          }

          <div className="tableFooterDiv"><NavLink to={`analytics?tab=articles`}><Button><I18n t={'view_more'} n='TopArticlesStats' /></Button></NavLink></div>
        </div>

      </div>
    );
  }
}

export default TopArticlesStats;
