import React from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash'

import { GenericModal } from '../../../../components/GenericModal/GenericModal';
import { ImmutArray } from '../../../../utils/ImmutablesHelper';
import { EdErrorHandler, LoadingButton, SwalSuccess } from '../../../../widgets';
import CicSelectorTable, { DisabledChannelsGroup, selectedChannelsLabel } from './cicTables/CicSelectorTable';
import { ChannelsGraph, findAncestors, findChildren, findChildrenWithExactNumberOfParents, formatChannelTitle, } from './cicChannelsHelper';
import { CICChannel, ROOT_NODE_ID } from './domain';
import { API } from '../../../../apis';
import { Translations } from '../../../../translations';
import GenericModalHeader from '../../../../components/GenericModal/GenericModalHeader';
import { Profile } from '../../../../../domain';


interface Props {
  magazine: number
  profile: Profile
  channelsGraph: ChannelsGraph
  channels: CICChannel[]
  channelId: number
  onClose: (saved?: boolean) => void
}

export default function EditChannelChildrenModal(props: Props) {
  const { magazine, channels, channelsGraph, channelId, profile } = props
  const channel = channelId === ROOT_NODE_ID ? undefined : channelsGraph.nodes[channelId]?.channel
  const initialChildren = findChildren(channelsGraph, channelId)

  const ancestors = React.useMemo(() => findAncestors(channelsGraph, channelId), [channelId, channelsGraph])
  const initiallyExpanded = React.useMemo(() => [channelId, ...ancestors], [channelId, ancestors])

  const tr = Translations.Translate(Translations, 'Channels');

  const disabledChannels: DisabledChannelsGroup[] = React.useMemo(() =>
    [
      { message: tr('childValidationSelf'), channels: [channelId], },
      { message: tr('childValidationAncestor'), channels: ancestors },
      { message: tr('childValidationSingleParent'), channels: findChildrenWithExactNumberOfParents(channelsGraph, channelId, 1) },
    ],
    [channelId, ancestors]
  )

  const [selectedChannels, setSelectedChannels] = React.useState(initialChildren)
  const [saveLoading, setSaveLoading] = React.useState(false);

  if (channelId !== ROOT_NODE_ID && !channel) {
    return null
  }
  async function onSave() {
    setSaveLoading(true)
    try {
      const toAdd = _.difference(selectedChannels, initialChildren)
      const toRemove = _.difference(initialChildren, selectedChannels)
      await API.channels.saveChannelChildren(magazine, channelId, toAdd, toRemove)
      SwalSuccess.fire({
        title: 'Success!',
        text: channelId !== ROOT_NODE_ID ? `Channel children saved` : 'Top level channels saved'
      });
      props.onClose(true)
    } catch (e) {
      EdErrorHandler(e, `saving channel children`);
    } finally {
      setSaveLoading(false)
    }
  }
  function onClose() {
    props.onClose(false)
  }


  return <GenericModal isOpen fullHeight toggle={onClose} centered={false} size={'xl'}>
    <GenericModalHeader onClose={onClose} title={channel
      ? `Select children for channel: ${formatChannelTitle(profile, channel)}`
      : `Select top level channels`
    } />
    <ModalBody>
      <CicSelectorTable
        magazine={magazine}
        profile={profile}
        channels={channels}
        selected={selectedChannels}
        disabledChannels={disabledChannels}
        initiallyExpanded={initiallyExpanded}
        onSelect={(channelId, s) => {
          setSelectedChannels(s
            ? ImmutArray.push(selectedChannels, channelId)
            : ImmutArray.removeBy(selectedChannels, c => c === channelId)
          )
        }}
      />
    </ModalBody>
    <ModalFooter>
      <div style={{ flex: 1 }}>{selectedChannelsLabel(selectedChannels)}</div>
      <LoadingButton loading={saveLoading} onClick={onSave} text={'Save'} />
      {' '}
      <Button color="outline-secondary" onClick={() => { onClose() }}>Cancel</Button>
    </ModalFooter>
  </GenericModal>
}
