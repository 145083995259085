export interface CustomPeriod {
  start: string,
  end: string,
}
export interface Report {
  name?: string,
  audience: number,
  period: string | CustomPeriod,
}

export const CUSTOM_REPORT_MAX_RANGE_DAYS = 60;