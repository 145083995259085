import React from 'react';
import { NlTemplateConfiguration_Footer } from '../domain';
import { ImagePlaceholder } from './components/ImagePlaceholder';
import { TextWithUIPlaceholder } from './components/TextWithUIPlaceholder';

import styles from './NewsletterTemplatesetPreview_Footer.module.scss';
import { DEFAULT_CONFIGURATION_FOOTER } from '../defaults';

interface Props {
  magazine: number
  configuration: NlTemplateConfiguration_Footer
}
export const NewsletterTemplatesetPreview_Footer = ({ magazine, configuration }: Props) => {
  return (
    <div className={styles.NewsletterTemplatesetPreview_Footer}>
      <TextWithUIPlaceholder
        longText
        configuration={configuration.footerText}
        defaultText={'This is the Footer text. You can change its style on the right hand side panel and save a prefilled text to have as default for the template.'}
      />
      <ImagePlaceholder
        magazine={magazine}
        configuration={configuration.image}
        text={'footer image'}
        imageWidth={640}
      />
      <TextWithUIPlaceholder
        customClass={styles.unsubscribeLinkWrapper}
        configuration={configuration.unsubscribeLink || { ...DEFAULT_CONFIGURATION_FOOTER.unsubscribeLink }}
      />
    </div>
  )
}
