import React, { Component, Fragment } from 'react';
import { Redirect, Route, RouteComponentProps, withRouter } from "react-router-dom";
import qs from 'query-string';

import * as Pages from './pages';

import './css/Main.min.css';
import { Profile, StatsFilter } from '../domain';

import { initTippy } from './components/TippyReact';

import { FilterContextInterface, FilterContextProvider } from './FilterContext';
import * as _ from 'lodash';
import * as ph from './utils/routerHelper';
import { Footer } from './Footer';
import { AccessRightsHelper } from './utils';
import { ED_PAGES } from './accessRights/accessRights';


const defaultStatsFilter: StatsFilter = {
  period: 'last30days',
  audience: 1
}

export interface State {
  filterContext: FilterContextInterface
}

export interface Props {
  magazine: string
  profile: Profile
  updateProfile: () => void
}

export class Main extends Component<RouteComponentProps & Props, State> {
  constructor(props: RouteComponentProps & Props) {
    super(props);

    this.state = {
      filterContext: this.initFilterContext()
    }
  }
  initFilterContext(): FilterContextInterface {
    const params = qs.parse(this.props.location.search);
    let statsFilter = { ...defaultStatsFilter };
    if (params.p) {
      statsFilter = { ...statsFilter, period: params.p };
    }
    if (params.a) {
      statsFilter = { ...statsFilter, audience: params.a };
    } else {
      //
      // ─── Load first audience THAT IS NOT EXCLUDED FROM ANALYTICS and the user has access ────────────────────────────────────────────────────────────
      //

      const { profile } = this.props;
      let audience: number | undefined = 1;
      if (!profile.globalAudience) {
        const validAudiences = _.filter(profile.audiences, (a) => !a.excludeFromAnalytics);
        if (validAudiences && !_.isEmpty(validAudiences)) {
          audience = _.get(validAudiences[0], 'id', 0);
        } else {
          audience = 0;
        }
      }
      statsFilter = { ...statsFilter, audience };
    }

    // ─────────────────────────────────────────────────────────────────

    return {
      statsFilter: { ...statsFilter },
      periodFilterHandler: (period: string) => {
        ph.setQueryParams(this.props.history, { p: period });
      },
      audienceFilterHandler: (audience: number) => {
        ph.setQueryParams(this.props.history, { a: audience });
      }
    }
  }
  componentDidMount() {
    this.initTooltips();
  }
  componentDidUpdate(prevProps: Props) {
    this.initTooltips();
    if (prevProps != this.props) {
      const filterContext = { ...this.state.filterContext };
      const period = ph.getQueryParam(this.props.location, 'p');
      if (period) {
        filterContext.statsFilter.period = period;
      }
      const audience = ph.getQueryParamNumber(this.props.location, 'a');
      if (audience) {
        filterContext.statsFilter.audience = audience;
      }
      this.setState({ filterContext });
    }
  }
  initTooltips() {
    initTippy('#Main')
  }
  render() {
    const { magazine, profile, updateProfile, location } = this.props;

    if (location.pathname == `/${magazine}/overview` && !AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'overview')) {
      return <Redirect to={`/${magazine}/${AccessRightsHelper.findFirstPageKeyWithAccess(profile, _.filter(ED_PAGES, (p) => p.parentPage === undefined))}`} />;
    }

    return (
      <FilterContextProvider value={this.state.filterContext}>
        <div id="Main">
          {/* <Header magazine={magazine} profile={profile} /> */}
          <div id="Page">
            {AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'overview') && <Route exact={true} path="/:magazine/overview" render={(props: RouteComponentProps) => <Pages.Overview magazine={magazine} profile={profile} />} />}
            {AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'analytics') && <Route path="/:magazine/analytics" render={(props: RouteComponentProps) => <Pages.Analytics magazine={magazine} profile={profile} />} />}
            {AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'content') && <Route path="/:magazine/content" render={(props: RouteComponentProps) => <Pages.Content magazine={magazine} profile={profile} updateProfile={updateProfile} />} />}
            {AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'engage') && <Route path="/:magazine/engage" render={(props: RouteComponentProps) => <Pages.Engage magazine={magazine} profile={profile} />} />}
            {AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'report') && <Route path="/:magazine/report" render={(props: RouteComponentProps) => <Pages.Report magazine={magazine} profile={profile} />} />}
            {AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'narrowcasting') && profile.edConfig?.narrowcasting && <Route path="/:magazine/narrowcasting" render={(props: RouteComponentProps) => <Pages.Narrowcasting magazine={_.toNumber(magazine)} profile={profile} />} />}
            {AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'advanced') && <Route path="/:magazine/advanced" render={(props: RouteComponentProps) => <Pages.Advanced magazine={magazine} profile={profile} updateProfile={updateProfile} />} />}
            {AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'plugins') && <Route path="/:magazine/plugins" render={(props: RouteComponentProps) => <Pages.Plugins {...props} magazine={ _.toNumber(magazine)} profile={profile} />}/>}
            {AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'settings') && <Route path="/:magazine/settings" render={() => <Pages.Settings magazine={magazine} />} />}
          </div>
          <Footer />
        </div>
      </FilterContextProvider>
    );
  }
}

export default withRouter(Main);
