import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import classnames from 'classnames';
import '../../css/TabPageNavigation.min.css';

// import {PushNotifications, Forms} from './subpages';
import { Profile } from '../../../domain';
import { Translations } from '../../translations';
// import qs from 'query-string';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import * as ph from '../../utils/routerHelper';

import { Analytics_articles, ChatGroups, Platform } from './subpages';
import { WidgetManager } from '../../modules';
import { AccessRightsHelper } from '../../utils';
import { ED_PAGES } from '../../accessRights/accessRights';
import { EdPageKey, EdScreenKey } from '../../accessRights/domain';

export interface Props {
  magazine: string,
  profile: Profile
}
// export interface State {
//   activeTab: string,
// }

export class Analytics extends Component<RouteComponentProps & Props> {
  constructor(props: RouteComponentProps & Props) {
    super(props);
    // this.state = {
    //   activeTab: 'articles'
    // };
  }
  // componentDidMount() {
  //   const params = qs.parse(this.props.location.search);
  //   if (params.tab) {
  //     this.setState({activeTab:params.tab});
  //   }else{
  //     ph.setQueryParams(this.props.history,{tab:this.state.activeTab});
  //   }


  // }
  toggleTab(tab: string) {
    ph.setQueryParams(this.props.history, { tab });
    // if(this.state.activeTab !== tab){
    //   ph.setQueryParams(this.props.history,{tab});
    //   this.setState({activeTab:tab});
    // }
  }
  shouldUserAccessPage = (page: EdScreenKey) => {
    const { profile } = this.props;
    return AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, page, 'analytics');
  }
  render() {
    const { magazine, profile } = this.props;
    const activeTab = ph.getQueryParam(this.props.location, 'tab');
    if (!activeTab) {
      return <Redirect to='analytics?tab=platform' />
    }
    // const { activeTab } = this.state;
    const tr = Translations.Translate(Translations, 'Modules');

    return (
      <div id="Analytics" className={'TabPageNavigation'}>
        {Translations.EnableTranslations(Translations)}
        <div className="PageTitle">{tr('analytics')}</div>
        <Nav tabs>
          {this.shouldUserAccessPage('platform') && <NavItem><NavLink className={classnames({ active: activeTab === 'platform' })} onClick={() => { this.toggleTab('platform') }}>{tr('platform')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('articles') && <NavItem><NavLink className={classnames({ active: activeTab === 'articles' })} onClick={() => { this.toggleTab('articles') }}>{tr('articles')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('widgets') && <NavItem><NavLink className={classnames({ active: activeTab === 'widgets' })} onClick={() => { this.toggleTab('widgets') }}>{tr('widgets')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('chatGroups') && <NavItem><NavLink className={classnames({ active: activeTab === 'chatGroups' })} onClick={() => { this.toggleTab('chatGroups') }}>{tr('chatGroups')}</NavLink></NavItem>}
        </Nav>
        <TabContent activeTab={activeTab}>
          {this.shouldUserAccessPage('platform') && <TabPane tabId="platform">{activeTab === 'platform' && <Platform magazine={parseInt(magazine)} profile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('articles') && <TabPane tabId="articles">{activeTab === 'articles' && <Analytics_articles magazine={magazine} profile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('widgets') && <TabPane tabId="widgets">{activeTab === 'widgets' && <WidgetManager magazine={parseInt(magazine)} analyticsMode={true} />}</TabPane>}
          {this.shouldUserAccessPage('chatGroups') && <TabPane tabId="chatGroups">{activeTab === 'chatGroups' && <ChatGroups magazine={parseInt(magazine)} profile={profile} />}</TabPane>}
        </TabContent>
      </div>
    );
  }
}
export const AnalyticsWithRouter = withRouter(Analytics);
export default Analytics;
