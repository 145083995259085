import React from 'react';
import { NlTemplatesetWithTemplateConfigurations } from '../domain';

import styles from './NewsletterTemplatesetPreview.module.scss';
import { NewsletterTemplatesetPreview_Content } from './NewsletterTemplatesetPreview_Content';
import { NewsletterTemplatesetPreview_Footer } from './NewsletterTemplatesetPreview_Footer';
import { NewsletterTemplatesetPreview_Header } from './NewsletterTemplatesetPreview_Header';

interface Props {
  magazine: number
  templateset: NlTemplatesetWithTemplateConfigurations
  noTitle?: boolean
}
export const NewsletterTemplatesetPreview = ({ magazine, templateset, noTitle }: Props) => {

  const { wrapper, header, content, footer } = templateset.configurations;

  return (
    <div className={styles.NewsletterTemplatesetPreview}>
      {!noTitle && <div className={styles.title}>preview newsletter template</div>}
      <div className={styles.wrapper} style={{ fontFamily: wrapper.font_family || 'Roboto' }}>
        <NewsletterTemplatesetPreview_Header magazine={magazine} configuration={header} />
        <NewsletterTemplatesetPreview_Content configuration={content} />
        <NewsletterTemplatesetPreview_Footer magazine={magazine} configuration={footer} />
      </div>
    </div>
  )
}
