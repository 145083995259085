import { Newsletter } from "./domain";

export const parseNewsletterConfiguration = (newsletter: Newsletter): Newsletter => {
  try {
    const c = JSON.parse(newsletter.configuration);
    return {
      ...newsletter,
      edition: c.edition.enabled,
      layout: c.layout,
      slotItems: c.slotItems,
      audienceTargeting: c.audienceTargeting ? c.audienceTargeting : { audiences: [], combineAudiencesWithAnd: false }
    }
  } catch (error) {
    console.log(error);
    return newsletter;

  }
}

export const stringifyNewsletterConfiguration = (newsletter: Newsletter): Newsletter => {
  try {
    const c = JSON.stringify({ edition: { enabled: newsletter.edition }, layout: newsletter.layout, slotItems: newsletter.slotItems, audienceTargeting: newsletter.audienceTargeting, version: 2 });
    return {
      ...newsletter,
      configuration: c,
    }
  } catch (error) {
    console.log(error);
    return newsletter;

  }
}