import React from 'react'
import { Alert, Col } from 'reactstrap'
import { SourceTypeSettingsProps } from '../SourceTypeSettings'

export const Unsupported = ({ source }: SourceTypeSettingsProps) => {
  return (
    <Col md={12}>
      <Alert color={'warning'}>{`Type <${source.srctype}> is not fully supported yet.`}</Alert>
    </Col>
  )
}
