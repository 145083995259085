import React, { Component, Fragment, Profiler } from 'react';
import { Input, Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';
import { StatsFilter, Article, Profile } from '../../../domain';
import * as Api from '../../api';

import './css/ArticleSelector.min.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ArticleQuickView from './ArticleQuickView';
import { Translations } from '../../translations';
import { EdErrorHandler, OrtecLoader } from '../../widgets';
import { ArticleHelper, DateHelper } from '../../utils';
import { API } from '../../apis';
import { ArticleInMagazineChannelsWithTitle } from '../../pages/Advanced/subpages/Articles/domain';
import TippyReact from '../TippyReact';
import { ChannelsSelect } from '../ChannelsSelect/ChannelsSelect';


export interface Props {
  magazine: string,
  limit: number,
  profile: Profile
  // statsFilter: StatsFilter,
  // articleId: number,
  selectHandler: (articleId: number, sourceId: number, title: string) => void
  closeHandler: () => void
  noLeftMargin?: boolean
  extraButtonText?: string,
  extraButtonOnClick?: () => void
  variationLanguage?: string,
  disableVariationChange?: boolean
}

export interface State {
  articles?: ArticleInMagazineChannelsWithTitle[],
  channels?: any[],
  articleInQuickView?: {
    id: number,
    sources: number[],
    title: string
  }
  isLoading?: boolean
}

//TODO: Transform ArticleSelector to functional component
//TODO: Update all typings here
//TODO: Use new version of datatables
export class ArticleSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }
  componentDidMount() {
    this.initLoad();

  }

  initLoad = async () => {
    try {
      this.getMagazineChannels();
      this.getArticlesInMagazineChannels();
    } catch (error) {
      EdErrorHandler(error, `in initial loading`);
    }
  }

  getMagazineChannels = async (): Promise<any[]> => {
    try {
      const resp = await Api.getMagazineChannels(parseInt(this.props.magazine));
      const channels = resp.data.channels;
      this.setState({ channels });
      return channels;
    } catch (error) {
      EdErrorHandler(error, `in getting magazine channels`);
      return [];
    }
  }
  getArticlesInMagazineChannels = async (channels?: number[]): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      const { data: articles } = await API.articles.getArticlesInMagazineChannels(parseInt(this.props.magazine), this.props.limit, channels, this.props.variationLanguage, this.props.variationLanguage ? this.props.profile.magazineLanguages?.primary : undefined);
      // const articles = data.articles;
      this.setState({ articles, isLoading: false });
    } catch (error) {
      EdErrorHandler(error, `in getting articles in magazine channels`);
      this.setState({ articles: [], isLoading: false })
    }
  }

  renderPaginationPanel(props: any) {
    let max = 1;
    let min = 1;
    if (this.state.articles) {
      max = (this.state.articles.length / props.sizePerPage) + 1;
    }
    const trt = Translations.Translate(Translations, 'Tables');

    return (
      <Fragment>
        <Col md={8} className='dropup'>{props.components.pageList} {props.components.sizePerPageDropdown}</Col>
        <Col md={4}>
          <div className="goToPageDiv">
            <input id="goToPageInput" onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                let val = e.target.value;
                if (val < min) {
                  val = min;
                } else if (val > max) {
                  val = max;
                }
                props.changePage(val);
                e.target.value = '';
              }
            }} className='form-control' type="number" placeholder={trt('go_to_page')} style={{ width: 'auto' }} />
            <button className="btn" onClick={(e) => {
              var k: any = document.getElementById('goToPageInput');
              let val = k.value;
              if (val < min) {
                val = min;
              } else if (val > max) {
                val = max;
              }
              props.changePage(val);
              k.value = ''
            }}>{'>'}</button>
          </div>
        </Col>
      </Fragment>
    );
  }

  dateFormatter(cell: any, row: any, extraData: any) {
    return <>{cell ? DateHelper.dateTimeToLocaleString(cell) : '-'}</>;
  }
  titleFormatter(cell: any, row: any, extraData: any) {
    return <>{ArticleHelper.convertToReadableTitle(cell)}</>;
  }

  channelsFormatter(channelIds: number[], row: ArticleInMagazineChannelsWithTitle, extraData: any) {
    return <>{channelIds.join(', ')}</>;
  }

  channelTitlesFormatter(channelIds: number[], row: ArticleInMagazineChannelsWithTitle, extraData: any) {
    if (channelIds.length !== 1) {
      const channelTitles = _.map(channelIds, (id) => findChannelTitle(this.state.channels, id));
      return <TippyReact content={channelTitles.join(', ')}><div>{channelIds.length} channels</div></TippyReact>
    }
    return <>{findChannelTitle(this.state.channels, channelIds[0])}</>;
  }

  previewFormatter(cell: any) {
    const tr = Translations.Translate(Translations, 'ArticleSelector');

    return (<img className="previewIcon" data-tippy-content={tr(`previewArticle`)} src={`/assets/icons/16/views.svg`} />)
  }
  selectFormatter(cell: any, row: any) {
    const tr = Translations.Translate(Translations, 'ArticleSelector');

    return (
      <Button className="selectButton secondary" data-tippy-content={tr(`selectArticle`)}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          this.selectArticle(row.id, row.sources[0], row.title);
          return false;
        }}>
        select article
      </Button>)
  }

  selectArticle(articleId: number, sourceId: number, title: string) {
    this.props.selectHandler(articleId, sourceId, title);
    this.props.closeHandler();
  }

  changeChannel(channelId: string) {
    this.getArticlesInMagazineChannels(channelId ? [parseInt(channelId)] : undefined);
  }

  renderToolbar(props: any) {
    const { channels } = this.state;
    const tr = Translations.Translate(Translations, 'ArticleSelector');

    return (
      <Fragment>
        {Translations.EnableTranslations(Translations)}
        <Col md={4}>{props.components.searchField}</Col>
        <Col md={4}></Col>
        <Col md={4}>
          <div className='channelFilterContainer'>
            <ChannelsSelect
              channels={channels || []}
              selected={''}
              placeholder={tr('allChannels')}
              onChange={this.changeChannel.bind(this)}
              channelGraphName={this.props.profile.edConfig?.channelGraphName}
            />
          </div>
        </Col>
      </Fragment>
    );
  }

  onRowClick(row: ArticleInMagazineChannelsWithTitle) {
    this.setState({ articleInQuickView: { id: row.id, sources: row.sources, title: row.title } });
  }
  closeQuickView() {
    this.setState({ articleInQuickView: undefined });
  }
  articleQuickViewSelectHandler() {
    const { articleInQuickView } = this.state;
    if (articleInQuickView) {
      this.selectArticle(articleInQuickView.id, articleInQuickView.sources[0], articleInQuickView.title);
    }
  }
  channelFormatter(cell: any, row: any) {
    const { channels } = this.state;
    const c = _.find(channels, ['channel', cell]);
    if (!c) {
      return cell;
    }
    return c.label ? c.label : c.name;
  }

  render() {
    const { magazine, extraButtonText, extraButtonOnClick, disableVariationChange } = this.props;
    const { articles, isLoading, articleInQuickView } = this.state;
    const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>
    const trt = Translations.Translate(Translations, 'Tables');
    const tr = Translations.Translate(Translations, 'ArticleSelector');

    const options: any = {
      // defaultSortName:'views',
      // defaultSortOrder:'desc',
      onRowClick: this.onRowClick.bind(this),
      // paginationShowsTotal:true,
      toolBar: this.renderToolbar.bind(this),
      paginationPanel: this.renderPaginationPanel.bind(this),
      sizePerPage: 25,
      sizePerPageList: [
        // { text: `10 ${trt('entries')}`, value: 10 },
        { text: `25 ${trt('entries')}`, value: 25 },
        { text: `50 ${trt('entries')}`, value: 50 },
        { text: `100 ${trt('entries')}`, value: 100 },
        // {text:'All entries',value: articles?articles.length:0},
      ]
    }
    return (
      <div id="ArticleSelector" style={this.props.noLeftMargin ? { left: '0px', width: '100%' } : {}}>
        <div className='header'>
          {/* <i className="material-icons closeIcon" onClick={this.closeHandler.bind(this)}>close</i> */}
          <img src="/assets/icons/bt_close_bigger.svg" onClick={this.props.closeHandler.bind(this)} style={{ cursor: 'pointer' }} />
          <span className="headerTitle">{tr('selectArticle')}</span>
          {extraButtonText && <Button style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translate(-10px,-50%)' }} color={'primary'} onClick={extraButtonOnClick}>{extraButtonText}</Button>}
        </div>
        <div className='content simple' >
          <div className="tableDiv">
            <div style={{ position: 'relative' }}>
              <BootstrapTable ref="topArticlesTable" data={articles ? articles : []} bordered={false} hover={true} version='4' options={options} pagination search /*selectRow={{mode:'radio',clickToSelect:true}}*/ >
                <TableHeaderColumn isKey dataField='id' dataSort={true} hidden></TableHeaderColumn>
                <TableHeaderColumn dataFormat={this.previewFormatter.bind(this)} tdStyle={{ cursor: 'pointer' }} width='60px'></TableHeaderColumn>
                <TableHeaderColumn dataField='title' dataFormat={this.titleFormatter.bind(this)} dataSort={true} tdStyle={{ cursor: 'pointer' }}></TableHeaderColumn>
                <TableHeaderColumn dataField='channels' dataFormat={this.channelsFormatter.bind(this)} dataSort={true} dataAlign='center' width='200px' hidden>Channels</TableHeaderColumn>
                <TableHeaderColumn dataField='channels' dataFormat={this.channelTitlesFormatter.bind(this)} dataSort={true} dataAlign='center' width='200px'>
                  <img data-tippy-content={tr("channels")} src="/assets/icons/16/channels.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='sources' dataSort={true} dataAlign='center' width='200px' hidden>Sources</TableHeaderColumn>
                <TableHeaderColumn dataField='mdate' dataFormat={this.dateFormatter.bind(this)} dataSort={true} dataAlign='center' width='200px'>
                  <img data-tippy-content={tr("lastModifiedInSourrce")} src="/assets/icons/16/calendar.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataFormat={this.selectFormatter.bind(this)} tdStyle={{ cursor: 'pointer' }} width='150px'></TableHeaderColumn>
              </BootstrapTable>
              {!isLoading ? null : <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}><OrtecLoader size='medium' /></div>}
            </div>
          </div>
          {!articleInQuickView ? '' :
            <ArticleQuickView magazine={magazine} articleId={articleInQuickView.id} selectHandler={this.articleQuickViewSelectHandler.bind(this)} closeHandler={this.closeQuickView.bind(this)} defaultVariationLanguage={this.props.variationLanguage || this.props.profile.magazineLanguages?.primary} disableVariationChange={disableVariationChange} />
          }
        </div>
      </div>
    );
  }
}

export default ArticleSelector;


const findChannelTitle = (channels: Array<{ channel: number, label: string, name: string }> | undefined, channelId: number): string => {
  if (!channels) {
    return _.toString(channelId);
  }
  const c = _.find(channels, (c) => c.channel == channelId);
  return c?.label || c?.name || _.toString(channelId);
}