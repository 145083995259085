import React from 'react';
import { Profile } from '../../../../../domain';
import styles from './SharepointSiteDetails.module.scss';
import { FullPageModal, FullPageModalContent, FullPageModalContentPart, FullPageModalHeader } from '../../../../components/FullPageModal';
import { SharepointList, SharepointSite } from './SharepointDomain';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import { API } from '../../../../apis';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { Button } from 'reactstrap';

interface Props {
  magazine: number,
  profile: Profile,
  site: SharepointSite,
  onClose: () => void,
}

export const SharepointSiteDetails = ({ magazine, profile, site, onClose }: Props) => {

  const queryClient = useQueryClient();

  const sharepointListsQuery = useQuery({
    queryKey: ['sharepointLists', magazine, site.customerId, site.url],
    queryFn: async () => {
      try {
        const { data } = await API.sharepoint.getSiteLists(magazine, site.customerId, site.url);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting sharepoint lists`);
      }
    }
  });

  const subscribeToSharepointListMutation = useMutation({
    mutationKey: ['subscribeToSharepointList', magazine],
    mutationFn: (listUrl: string) => API.sharepoint.subscribeToList(magazine, site.customerId, listUrl),
    onError: (error) => {
      EdErrorHandler(error, `subscribing to list`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sharepointLists', magazine, site.customerId, site.url] });
    }
  });

  const unsubscribeFromSharepointListMutation = useMutation({
    mutationKey: ['unsubscribeFromSharepointList', magazine],
    mutationFn: (subscriptionId: string) => API.sharepoint.unsubscribe(magazine, subscriptionId),
    onError: (error) => {
      EdErrorHandler(error, `unsubscribing from list`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sharepointLists', magazine, site.customerId, site.url] });
    }
  });



  const onSubscribe = (list: SharepointList) => {
    subscribeToSharepointListMutation.mutate(list.url);
  }

  const onUnsubscribe = (list: SharepointList) => {
    if (!list.subscription?.id) {
      console.error(`There is not a subscription id`);
      return;
    }
    unsubscribeFromSharepointListMutation.mutate(list.subscription.id);
  }

  const isLoading = sharepointListsQuery.isFetching || subscribeToSharepointListMutation.isLoading || unsubscribeFromSharepointListMutation.isLoading;

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true,
    },
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
    },
    {
      dataField: 'url',
      text: 'List URL',
      sort: true
    },
    {
      dataField: 'isSubscribed',
      text: 'Status',
      sort: true,
      headerStyle: { width: '150px' },
      classes: (cell: boolean, row: SharepointList) => {
        return row.isSubscribed ? 'hideOnHover' : '';
      },
      formatter: (cell: boolean, row: SharepointList) => {
        if (!row.isSubscribed) {
          return (
            <Button onClick={(e) => { onSubscribe(row); e.preventDefault(); e.stopPropagation(); return false; }}>subscribe</Button>
          )
        }

        if (row.subscription?.isExpired) {
          return (
            <span className={styles.expiredText}><i className={'fa fa-warning'}></i>Expired</span>
          )
        } else {
          return (
            <span className={styles.subscribedText}><i className={'fa fa-check'}></i>Subscribed</span>
          )
        }
      }
    },
    {
      dataField: 'dfActions',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatter: (cell, row: SharepointList) => {
        if (!row.isSubscribed) {
          return <></>;
        }
        return (
          <div className={'actionHoveringDiv'}>
            {(row.subscription?.isExpired) && <Button onClick={(e) => { onSubscribe(row); e.preventDefault(); e.stopPropagation(); return false; }}>resubscribe</Button>}
            {(!row.subscription?.isExpired) && <Button color={'custom'} onClick={(e) => { onUnsubscribe(row); e.preventDefault(); e.stopPropagation(); return false; }}>unsubscribe</Button>}
          </div>
        )
      }
    }

  ];

  return (
    <div className={styles.sharepointSiteDetails}>
      <FullPageModal >
        <FullPageModalHeader isLoading={false} onClose={onClose}>
          <div className={styles.idContainer}>
            <div className={styles.headerLabel}>SITE</div>
            <div className={styles.headerVal}> {site.title}</div>
          </div>
          <div className={styles.linkContainer}>
            <div className={styles.headerLabel}>SITE URL</div>
            <div className={styles.headerVal}> <a href={``} target={'_blank'}>{site.url}</a></div>
          </div>
          <div className={styles.flexSpacer}></div>
          {/* <div className={styles.qrContainer}>
            <i className={`fa fa-qrcode qrToggler`} data-tippy-content={'Click to see QR code'} onClick={() => { openQRModal() }}></i>
          </div> */}
        </FullPageModalHeader>
        <FullPageModalContent isLoading={false}>
          <FullPageModalContentPart flex={1} mainPart>
            <div className={styles.listsTableWrapper}>
              {isLoading && <OrtecLoader />}
              <DataTable
                data={sharepointListsQuery.data || []}
                columns={columns}
                keyField={'url'}
                onRowClick={() => { }}
                noHover
              />
            </div>
          </FullPageModalContentPart>
        </FullPageModalContent>
      </FullPageModal>
    </div>
  )
}
