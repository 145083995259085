import * as _ from 'lodash';
import { Form, FormQuestion, FormSection, FormPosition } from '../../../../../domain';

export const typeName = (type: string) => {
    switch (type) {
        case 's_poll':
            return 'poll'
        case 'survey':
            return 'survey';
        default:
            return type;
    }
}

export const indexToId = (arr: any[]) => {
    return arr.map((a: any, index: number) => {
        return { ...a, id: (index + 1) };
    });
}

export const getNewId = (form: Form) => {
    const max = _.maxBy(form.config.questions, q => q.id);
    return max ? max.id + 1 : 1;
}
export const getNewResultsId = (form: Form) => {
    const max = _.maxBy(form.config.results, q => q.id);
    return max ? max.id + 1 : 1;
}

export const questionType = (question: FormQuestion) => {
    switch (question.type) {
        case 2:
            return 'multiple answers';
        case 3:
            return 'single answer';
        case 4:
            return question.multiline ? 'textarea' : 'textfield';
        case 5:
            return 'star rating';
        case 9:
            return 'image';
        default:
            return question.type;
    }
}

export const newEmptyQuestion = (questionType: string) => {
    switch (questionType) {
        case 'textfield':
            return emptyTextfield;
        case 'textarea':
            return emptyTextarea;
        case 'singleAnswer':
            return { ...emptySingleAnswer, answers: !emptySingleAnswer.answers ? undefined : emptySingleAnswer.answers.map((a) => { return { ...a } }) };
        case 'multipleAnswers':
            return { ...emptyMultipleAnswers, answers: !emptyMultipleAnswers.answers ? undefined : emptyMultipleAnswers.answers.map((a) => { return { ...a } }) };
        case 'starRating':
            return emptyStarRating;
        case 'image':
            return emptyImage;
        case 'text':
            return emptyText;
        case 'quiz':
            return { ...emptyQuizQuestion, answers: !emptySingleAnswer.answers ? undefined : emptySingleAnswer.answers.map((a) => { return { ...a } }) };
        default:
            return emptyQuestion;
    }
}




export const emptySection: FormSection = {
    title: '',
    intro: '',
    questions: []
}

export const emptyQuestion: FormQuestion = {
    id: 1,
    type: 3,
    title: '',
    renderer: 'radio',
    text: '',
    answers: []
}

export const emptyTextfield: FormQuestion = {
    id: 1,
    type: 4,
    title: '',
    text: '',
}
export const emptyTextarea: FormQuestion = {
    id: 1,
    type: 4,
    title: '',
    text: '',
    multiline: true,
}
export const emptySingleAnswer: FormQuestion = {
    id: 1,
    type: 3,
    title: '',
    text: '',
    renderer: 'radio',
    answers: [
        { id: 1, text: '' },
        { id: 2, text: '' },
    ]
}
export const emptyMultipleAnswers: FormQuestion = {
    id: 1,
    type: 2,
    title: '',
    text: '',
    minSelected: 1,
    answers: [
        { id: 1, text: '' },
        { id: 2, text: '' },
    ]
}
export const emptyStarRating: FormQuestion = {
    id: 1,
    type: 5,
    title: '',
    text: '',
    renderer: 'star',
    scale: 5,
}
export const emptyImage: FormQuestion = {
    id: 1,
    type: 9,
    title: '',
    text: '',
}
export const emptyText: FormQuestion = {
    id: 1,
    type: 1,
    title: '',
    text: '',
    button: 'Next',
}

export const emptyResults: FormQuestion = {
    id: 1,
    type: 8,
    title: '',
    text: '',
}
export const emptyQuizQuestion: FormQuestion = {
    id: 1,
    type: 3,
    title: '',
    text: '',
    renderer: 'swipe',
    answers: [
        { id: 1, text: '' },
        { id: 2, text: '' },
    ],
    correctAnswer: 1
}
export const emptyOptIn: FormQuestion = {
    id: 1,
    type: 7,
    title: '',
    text: '',
    rejectButton: 'No',
    acceptButton: 'Yes',
}

export const emptyPosition: FormPosition = {
    channel: '*',
    index: 1,
    component: 'magazine',
    inline: true
}

export const emptySurvey = {
    id: -1,
    title: '',
    type: 'survey',
    status: 1,
    cdate: '',
    mdate: '',
    target: '',
    config: {
        questions: [],
        sections: [emptySection],
        config: {
            allowMultipleSubmissions: false,
            allowUserToDismissForm: false,
            autoOptIn: false,
            position: { ...emptyPosition },
            positions: [{ ...emptyPosition }]
        }
    }
}

export const emptyQuiz = {
    id: -1,
    title: '',
    type: 'quiz',
    status: 1,
    cdate: '',
    mdate: '',
    target: '',
    config: {
        questions: [
            { ...emptyQuizQuestion, id: 1 },
            { ...emptyOptIn, id: 2 }
        ],
        results: [
            emptyResults
        ],
        config: {
            allowMultipleSubmissions: false,
            allowUserToDismissForm: false,
            autoOptIn: false,
            position: { ...emptyPosition },
            positions: [{ ...emptyPosition }]
        }
    }
}


export const emptyForm = {

    id: -1,
    title: '',
    type: 's_poll',
    status: 1,
    cdate: '',
    mdate: '',
    target: '',
    config: {
        questions: [],
        sections: [],
        config: {
            allowMultipleSubmissions: false,
            allowUserToDismissForm: false,
            autoOptIn: false,
            position: { ...emptyPosition },
            positions: [{ ...emptyPosition }]
        }
    }
}



export const getEmptyForm = (formType: string): Form => {
    switch (formType) {
        case 'survey':
            return _.cloneDeep(emptySurvey);
        case 'quiz':
            return _.cloneDeep(emptyQuiz);
        default:
            return _.cloneDeep(emptyForm);
    }
}

export const isMaxLessThanMin = (q: FormQuestion): boolean => {
    if(q.minSelected === undefined) {
        return false;
    }

    if(q.maxSelected === undefined) {
        return false;
    }

    return q.minSelected > q.maxSelected;
}

export const isMaxMoreThanAnswers = (q: FormQuestion): boolean => {
    if(q.maxSelected === undefined) {
        return false
    }

    const answers = q.answers?.length ?? 0;
    return q.maxSelected > answers
}

export const isMinMoreThanAnswers = (q: FormQuestion): boolean => {
    if(q.minSelected === undefined) {
        return false
    }

    const answers = q.answers?.length ?? 0;
    return q.minSelected > answers
}

export const isMoreThanAnswers = (q: FormQuestion): boolean => {
    return isMaxMoreThanAnswers(q) || isMinMoreThanAnswers(q); 
}

export const isLessThanZero = (q?: number): boolean => {
    if(q === undefined) {
        return false;
    } 

    return q <= 0;
}

