import './css/Footer.min.css';

import React, { Component } from 'react';

export interface Props {

}

export const Footer = (props: Props) => {

  return (
    <div id="Footer" className="">
      Powered by <img id="footer-ortec-logo" src='/assets/footer-ortec-logo.svg' />
    </div>
  );
}
