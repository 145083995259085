import React from 'react';
import Datepicker from 'react-datepicker3';
import "react-datepicker3/dist/react-datepicker.css";
import moment from 'moment';

import styles from './DatePicker.module.scss';
import { Input } from 'reactstrap';
import { DateHelper } from '../../utils';
import classNames from 'classnames';

interface Props {
  period: string,
  changePeriod: (period: string) => void
  minPeriod?: string
  maxPeriod?: string
  readOnly?: boolean
  notClearable?: boolean
  dayClassName?: (day: Date) => string | null
  popperCustomClassName?: string
  children?: React.ReactNode
  noMaxLimit?: boolean
  fixedPosition?: boolean
}

export const DailyDatePicker = ({ period, changePeriod, minPeriod, maxPeriod, readOnly, notClearable, dayClassName, popperCustomClassName, children, noMaxLimit, fixedPosition }: Props) => {

  const mode = 'daily';
  const placeHolderUnitText = 'date';
  const momentFormat = 'YYYY-MM-DD';

  const maxDate = () => {
    if (noMaxLimit) {
      return undefined;
    }
    if (!maxPeriod) {
      return moment().subtract(1, 'day').toDate();
    }
    try {
      return moment(maxPeriod, momentFormat).toDate()
    } catch (error) {
      return moment().subtract(1, 'day').toDate();
    }
  }

  const minDate = () => {
    if (!minPeriod) {
      return moment('2010-01-01', momentFormat).toDate();
    }
    try {
      return moment(minPeriod, momentFormat).toDate()
    } catch (error) {
      return moment('2010-01-01', momentFormat).toDate();
    }
  }

  return (
    <div className={styles.DatePicker}>
      <Datepicker
        useWeekdaysShort
        dateFormat={DateHelper.dailyDatePickerFormatBasedOnLocale()}
        minDate={minDate()}
        maxDate={maxDate()}
        selected={period ? moment(period, momentFormat).toDate() : null}
        readOnly={readOnly}
        isClearable={readOnly || notClearable ? false : true}
        placeholderText={`Select specific ${placeHolderUnitText}..`}
        customInput={<Input type={'text'} readOnly />}
        onChange={(d: Date, e: any) => {
          changePeriod(DateHelper.dateToString(d, momentFormat));
        }}
        onChangeRaw={(e) => {
          if (e) {
            e.preventDefault();
            e.stopPropagation();
          }
          return false;

        }}
        popperClassName={classNames(mode, popperCustomClassName)}
        popperPlacement={'top'}
        popperProps={!fixedPosition ? undefined : {
          positionFixed: true
        }}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "-3px, -20px"
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport"
          }
        }}
        // startDate={null}
        // endDate={null}
        // showWeekNumbers={false}
        // showMonthYearPicker={false}
        // showYearPicker={false}
        shouldCloseOnSelect={true}
        showMonthDropdown
        showYearDropdown
        dropdownMode={"select"}
        dayClassName={dayClassName}
      // onCalendarOpen={handleCalendarOpen}
      // onCalendarClose={handleCalendarClose}
      >
        {children}
      </Datepicker>
    </div>
  )
}
