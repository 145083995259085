import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Profile } from '../../../domain';
import { ED_PAGES } from '../../accessRights/accessRights';
import { EdScreenKey } from '../../accessRights/domain';
import { Translations } from '../../translations';
import { AccessRightsHelper, HistoryHelper } from '../../utils';
import { NcDevices } from './NcDevices';
import { NcSettings } from './subpages/NcSettings/NcSettings';
import { NcSlideshows } from './subpages/NcSlideshows/NcSlideshows';

interface Props {
  magazine: number,
  profile: Profile
}

const NC_subpages: EdScreenKey[] = _.map(_.filter(ED_PAGES, (p) => p.parentPage === 'narrowcasting'), (sp) => sp.key as EdScreenKey);

export const Narrowcasting = ({ magazine, profile }: Props) => {

  const history = useHistory();
  const activeTab = HistoryHelper.getQueryParam(history, 'tab');

  const tr = Translations.Translate(Translations, 'Modules');

  const toggleTab = (tab: EdScreenKey) => {
    HistoryHelper.setQueryParams(history, { tab });
  }

  const shouldUserAccessPage = (page: EdScreenKey) => {
    return AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, page, 'narrowcasting');
  }

  if (!activeTab) {
    if (shouldUserAccessPage('devices')) {
      return <Redirect to='narrowcasting?tab=devices' />
    }
    return <Redirect to={`narrowcasting?tab=${AccessRightsHelper.findFirstPageKeyWithAccess(profile, _.filter(ED_PAGES, (p) => p.parentPage == 'narrowcasting'))}`} />
  }

  return (
    <div id="Narrowcasting" className={'TabPageNavigation'}>
      <div className="PageTitle">{_.replace(tr('narrowcasting'), ' ', '')}</div>
      <Nav tabs>
        {_.map(NC_subpages, (p) => {
          if (!shouldUserAccessPage(p)) {
            return null;
          }
          return (
            <NavItem key={p}><NavLink className={classnames({ active: activeTab === p })} onClick={() => { toggleTab(p) }}>{tr(p)}</NavLink></NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {_.map(NC_subpages, (p) => {
          if (!shouldUserAccessPage(p)) {
            return null;
          }

          return (
            <TabPane key={p} tabId={p} style={{ marginTop: '20px' }}>{
              activeTab === p && (
                (p === 'devices' && <NcDevices {...{ magazine, profile }} />) ||
                (p === 'slideshows' && <NcSlideshows {...{ magazine, profile }} />) ||
                (p === 'settings' && <NcSettings {...{ magazine, profile }} />)
              )
            }
            </TabPane>
          )
        })}
      </TabContent>
    </div>
  )
}
