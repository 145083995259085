
import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form as RForm, FormGroup } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';

import './css/SurveySectionList.min.css';
import { Translations } from '../../../../translations';
import { Form, FormQuestion, FormSection } from '../../../../../domain';
import * as FormsHelper from './FormsHelper';

export interface Props {
  form: Form,
  activeSection: number
  changeForm: (form: Form) => void
  changeActiveSection: (index: number) => void
}

export class SurveySectionList extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  addSection = () => {
    const { form } = this.props;

    const newForm = {
      ...form,
      config: {
        ...form.config,
        sections: form.config.sections ? [...form.config.sections, FormsHelper.emptySection] : [FormsHelper.emptySection]
      }
    }
    this.props.changeForm(newForm);
  }

  removeSection = (index: number) => {
    const { form } = this.props;

    let newSections = form.config.sections ? [...form.config.sections] : undefined;
    if (newSections) {
      newSections.splice(index, 1);
    }
    if (_.isEmpty(newSections)) {
      newSections = [FormsHelper.emptySection];
    }


    const newForm = {
      ...form,
      config: {
        ...form.config,
        sections: newSections
      }
    }
    this.props.changeForm(newForm);
  }

  renderAddSectionButton = () => {
    return (
      <div className={'addNewSectionButtonDiv'}>
        <Button className={'addNewSectionButton'} onClick={() => this.addSection()}>add section</Button>
      </div>
    )
  }

  renderSectionTile = (section: FormSection, index: number, removable: boolean) => {
    return (
      <div key={`formSection-${index}`} className={`elementBox ${this.props.activeSection == index ? 'active' : ''}`} onClick={(e) => this.props.changeActiveSection(index)}>
        <div className={'iconsDiv'}>
          <i className={'material-icons'}>drag_indicator</i>
          <div className='spacer'></div>
          {!removable ? null :
            <i className={'material-icons'} onClick={() => this.removeSection(index)}>remove_circle_outline</i>
          }
        </div>
        <div className={'sectionTitle'}>{`section ${index + 1}`}</div>
      </div>
    )
  }


  render() {
    const { form, activeSection } = this.props;
    const tr = Translations.Translate(Translations, 'Forms');

    const sections = form && form.config && form.config.sections ? form.config.sections : [FormsHelper.emptySection];

    return (
      <div id="SurveySectionList">
        {Translations.EnableTranslations(Translations)}
        {sections.map((s, index) => {
          return this.renderSectionTile(s, index, form.status == 1 ? true : false);
        })
        }
        {form.status != 1 ? null : this.renderAddSectionButton()}
      </div>
    )
  }
}

export default SurveySectionList;
