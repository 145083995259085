import { HtmlTemplatesHelper } from ".";
import { NlTemplateConfiguration_Footer } from "../domain";

const footerText = (configuration: NlTemplateConfiguration_Footer): string => {

  const { enabled, backgroundColor, text, textColor, fontSize } = configuration.footerText;

  if (!enabled) {
    return '';
  }
  return `
    <tr>
      <td style="
          text-align:left;
          background-color:${backgroundColor};
          color: ${textColor};
          font-size: ${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px;
          padding: 32px 16px;
          font-weight: 400;
      ">
          ${HtmlTemplatesHelper.handleText(text, textColor)}
      </td>
    </tr>
  `;
}
const footerImage = (configuration: NlTemplateConfiguration_Footer): string => {

  const { enabled, src } = configuration.image;

  if (!enabled) {
    return '';
  }
  return `
    <tr align="center">
      <td height="165" width="640" align="center" 
          style="vertical-align:top;
              background-repeat:no-repeat;
              text-align:center;width: 680px; 
              height: 165px;position:relative;
              ">
          <img src="${src}" alt="Footer image" />
      </td>
    </tr>
  `;
}
const unsubscribeLink = (configuration: NlTemplateConfiguration_Footer): string => {

  const { enabled, backgroundColor, text, textColor, fontSize } = configuration.unsubscribeLink;

  if (!enabled) {
    return '';
  }
  return `
    {% if unsubscribeLink %}
    <tr>
      <td style="
          text-align:left;
          background-color:${backgroundColor};
          color: ${textColor};
          font-size: ${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px;
          padding: 8px 4px;
          font-weight: 400;
      ">
        <a target="_blank"
        href="{{unsubscribeLink}}"
        style="
            color:${textColor};
            text-decoration:none;"
        >
          ${HtmlTemplatesHelper.handleText(text, textColor)}
        </a>
      </td>
    </tr>
    {% endif %}
  `;
}

const parse = (configuration: NlTemplateConfiguration_Footer): string => {
  return `
    <table 
    role="presentation"
    align="center" 
    border="0" 
    cellpadding="0" 
    cellspacing="0" 
    width="640" 
    style="
        margin:auto;
        mso-padding-alt: 0px 0px 0px 0px;
  ">
    ${footerText(configuration)}
    ${footerImage(configuration)}
    ${unsubscribeLink(configuration)}
    <tr height="10" style="height:10px;"><td></td></tr>
  </table>
  `
}

export const footer = {
  parse
}