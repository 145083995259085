import axios from 'axios';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import { Button, Input } from 'reactstrap';
import { Profile } from '../../../../../domain';
import { API } from '../../../../apis';
import { ArticleSelector, AudiencesTargeting, TippyReact } from '../../../../components';
import { EdErrorHandler, LoadingButton, OrtecLoader, Swal, SwalSuccess } from '../../../../widgets';
import { DEFAULT_NEWSLETTER } from './defaults';
import { Newsletter, NewsletterTagList, NlLayout, NlLayoutColumnIndex, NlSlotItem, NlSlotItem_article, NlSlotItem_freeText, NlStatus, NlTemplateset, NlTemplatesetWithTemplateConfigurations, NlTemplatesetWithTemplates } from './domain';
import { NewsletterBuilder } from './NewsletterBuilder';
import styles from './NewsletterDetails.module.scss'
import { NewsletterFreeTextEditor } from './NewsletterFreeTextEditor';
import { NewsletterPreviewModal } from './NewsletterPreviewModal';
import { parseTemplateset } from './NewsletterTemplatesetDetails';
const FileDownload = require('js-file-download');
import { CreatableMultiTextInput, SelectOption } from '../../../../components/ReactSelect/ReactSelect';
import Creatable from 'react-select/creatable';
import { NewsletterAnalytics } from './NewsletterAnalytics';
import { parseNewsletterConfiguration, stringifyNewsletterConfiguration } from './NewslettersHelper';
import { hasEmptySlots } from './NewsletterContentBuilderRow';


interface Props {
  magazine: number,
  profile: Profile,
  newsletterId: number,
  onClose: () => void,
  openNewsletter: (newsleterId: number) => void
  reloadOverview: () => void
}

export const NewsletterDetails = ({ magazine, profile, newsletterId, onClose, openNewsletter, reloadOverview }: Props) => {

  const [newsletter, setNewsletter] = useState<Newsletter>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [saveAndSendLoading, setSaveAndSendLoading] = useState<boolean>(false);
  const [templatesets, setTemplatesets] = useState<NlTemplateset[]>([]);
  const [templatesetsLoading, setTemplatesetsLoading] = useState<boolean>(false);
  const [selectedTemplateset, setSelectedTemplateset] = useState<NlTemplatesetWithTemplateConfigurations>();
  const [templatesetLoading, setTemplatesetLoading] = useState<boolean>(false);
  const [showArticleSelector, setShowArticleSelector] = useState<boolean>(false);
  const [activeSlotPosition, setActiveSlotPosition] = useState<{ rowIndex: number, columnIndex: NlLayoutColumnIndex }>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showFreeTextEditor, setShowFreeTextEditor] = useState<boolean>(false);
  const [freeTextEditorLoadText, setFreeTextEditorLoadText] = useState<string>('');
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [tagList, setTagList] = useState<NewsletterTagList>();
  const [tagListLoading, setTagListLoading] = useState<boolean>(false);
  const [campaignListOptions, setCampaignListOptions] = useState<SelectOption<number>[]>();
  const [campaignListLoading, setCampaignListLoading] = useState<boolean>(false);

  const [savedStatus, setSavedStatus] = useState<NlStatus>('draft');



  const loading = dataLoading || templatesetsLoading;

  const isNew = newsletterId === -1;

  const isSent = _.includes(['sent', 'ready'], savedStatus);
  const isReadOnly = !isNew && savedStatus != 'draft';

  useEffect(() => {

    loadTemplatesets();
    loadTagList();
    loadCampaignList();

  }, []);

  useEffect(() => {

    if (isNew) {
      setNewsletter({ ...DEFAULT_NEWSLETTER, magazine });
    } else {
      loadNewsletter()
    }

  }, [newsletterId])

  useEffect(() => {

    loadTemplateset();

  }, [newsletter?.templateset?.id]);

  const loadNewsletter = async () => {
    try {
      setDataLoading(true);
      const { data } = await API.newsletters.getNewsletter(magazine, newsletterId);
      setSavedStatus(data.status);
      // const parsedTemplateset = parseTemplateset(data);
      // console.log(parsedTemplateset);
      setNewsletter(parseNewsletterConfiguration(data));
    } catch (error) {
      EdErrorHandler(error, `loading newsletter`)
    } finally {
      setDataLoading(false);
    }
  }


  const loadTemplatesets = async () => {
    try {
      setTemplatesetsLoading(true);
      const { data } = await API.newsletters.getNewsletterTemplatesets(magazine);
      const orderedTemplatesets = _.orderBy(data, ['id'], ['desc']);
      setTemplatesets(orderedTemplatesets);
      if (isNew && !_.isEmpty(orderedTemplatesets)) {
        const n = newsletter || { ...DEFAULT_NEWSLETTER, magazine }
        setNewsletter({ ...n, templateset: { id: orderedTemplatesets[0].id, name: orderedTemplatesets[0].name } });
      }
    } catch (error) {
      EdErrorHandler(error, `loading templatesets`)
    } finally {
      setTemplatesetsLoading(false);
    }
  }

  const loadTagList = async () => {
    try {
      setTagListLoading(true);
      const { data } = await API.newsletters.getNewsletterTagsForMagazine(magazine);
      setTagList(data.slice(0, 10));
    } catch (error) {
      EdErrorHandler(error, `loading tag list`)
    } finally {
      setTagListLoading(false);
    }
  }

  const loadCampaignList = async () => {
    try {
      setCampaignListLoading(true);
      const { data } = await API.newsletters.getNewsletterCampaignsForMagazine(magazine);
      setCampaignListOptions(_.map(data, (c) => { return { value: c.id, label: c.name } }));
    } catch (error) {
      EdErrorHandler(error, `loading campaign list`)
    } finally {
      setCampaignListLoading(false);
    }
  }

  const loadTemplateset = async () => {
    if (!newsletter?.templateset?.id) {
      return;
    }

    try {
      setTemplatesetLoading(true);
      const { data } = await API.newsletters.getNewsletterTemplateset(magazine, newsletter?.templateset?.id);
      const parsedTemplateset = parseTemplateset(data);
      setSelectedTemplateset(parsedTemplateset);
    } catch (error) {
      EdErrorHandler(error, `loading templateset`)
    } finally {
      setTemplatesetLoading(false);
    }
  }

  const onSave = async () => {

    if (!newsletter) {
      return;
    }

    if (newsletter.status == 'manuallySent') {
      const { value: confirm } = await Swal.fire({
        type: 'question',
        title: 'Are you sure?',
        showCancelButton: true,
        confirmButtonText: 'Yes, I have already sent it!',
        confirmButtonColor: '#96BF0D',
        focusCancel: true,
        html: `This action will mark this newsletter as already sent via an email client. Only title, campaign and tags will be editable after that!`
      });
      if (!confirm) {
        return;
      }
    }

    try {
      setSaveLoading(true);
      const n = stringifyNewsletterConfiguration(newsletter);
      const { data } = await API.newsletters.saveNewsletter(magazine, n);
      setSavedStatus(n.status);
      setNewsletter({ ...newsletter, campaign: data.campaign });
      loadCampaignList();
      reloadOverview();
      SwalSuccess.fire({
        title: 'Success!',
        text: `Newsletter has been saved successfully!`,
        customClass: {
          popup: 'noBounce'
        },
        showConfirmButton: false,
        timer: 1000,
      });

      if (isNew) {
        openNewsletter(data.id);
      }
    } catch (error) {
      EdErrorHandler(error, `saving newsletter`);
    } finally {
      setSaveLoading(false);
    }
  }

  const onSend = async () => {

    if (!newsletter) {
      return;
    }

    const { value: confirm } = await Swal.fire({
      type: 'question',
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, save and send it!',
      confirmButtonColor: '#96BF0D',
      focusCancel: true,
      html: `This action will send this newsletter to the targeted audience(s). Only title, campaign and tags will be editable after that!`
    });
    if (!confirm) {
      return;
    }

    try {
      setSaveAndSendLoading(true);
      const n = stringifyNewsletterConfiguration(newsletter);
      const { data } = await API.newsletters.saveNewsletter(magazine, { ...n, status: 'ready' });
      setSavedStatus('ready');
      setNewsletter({ ...newsletter, status: 'ready', campaign: data.campaign });
      loadCampaignList();
      reloadOverview();
      SwalSuccess.fire({
        title: 'Success!',
        text: `Newsletter has been saved & sent successfully!`,
        customClass: {
          popup: 'noBounce'
        },
        showConfirmButton: false,
        timer: 1000,
      });

      if (isNew) {
        openNewsletter(data.id);
      }

    } catch (error) {
      EdErrorHandler(error, `saving and sending newsletter`);
    } finally {
      setSaveAndSendLoading(false);
    }
  }

  const onExport = async () => {

    if (!newsletter) {
      return;
    }

    try {
      setExportLoading(true);
      const { data } = await axios.get(`/api/${magazine}/dashApi/newsletters/${newsletterId}/export`, { responseType: 'blob', transformRequest: [(data, headers) => { delete headers.get.Pragma; return data }] })
      FileDownload(data, `Newsletter export [${newsletter.name}].eml`);
    } catch (error) {
      EdErrorHandler(error, `exporting template`);
    } finally {
      setExportLoading(false);
    }
  }

  const changeTemplateset = (id: number) => {
    const t = _.find(templatesets, (t) => t.id == id);
    if (newsletter && t) {
      setNewsletter({ ...newsletter, templateset: { id: t.id, name: t.name } });
    }
  }

  const onLayoutChange = (layout: NlLayout, slotItems: NlSlotItem[]) => {
    if (!newsletter) {
      return;
    }

    setNewsletter({ ...newsletter, layout, slotItems })
  }

  const openArticleSelector = (rowIndex: number, columnIndex: NlLayoutColumnIndex, openFreeTextEditorWithText?: string) => {
    setActiveSlotPosition({ rowIndex, columnIndex });
    setShowArticleSelector(true);
    if (openFreeTextEditorWithText) {
      openFreeTextEditor(openFreeTextEditorWithText);
    }
  }

  const closeArticleSelector = () => {
    setActiveSlotPosition(undefined);
    setShowArticleSelector(false);
  }

  const onArticleSelect = (articleId: number, sourceId: number, title: string) => {
    if (!newsletter || !activeSlotPosition) {
      return;
    }
    const { rowIndex, columnIndex } = activeSlotPosition;
    const slotItems: NlSlotItem[] = [..._.filter(newsletter.slotItems, (s) => (s.rowIndex !== rowIndex || s.columnIndex !== columnIndex)), generateArticleProxy(rowIndex, columnIndex, articleId, sourceId)]
    setNewsletter({ ...newsletter, slotItems });
  }

  const openFreeTextEditor = (text: string) => {
    setFreeTextEditorLoadText(text);
    setShowFreeTextEditor(true);
  }

  const closeFreeTextEditor = () => {
    setFreeTextEditorLoadText('');
    setShowFreeTextEditor(false);
  }

  const onFreeTextEditorSave = (text: string) => {
    if (!newsletter || !activeSlotPosition) {
      return;
    }
    const { rowIndex, columnIndex } = activeSlotPosition;
    const slotItems: NlSlotItem[] = [..._.filter(newsletter.slotItems, (s) => (s.rowIndex !== rowIndex || s.columnIndex !== columnIndex)), generateTextProxy(rowIndex, columnIndex, text)]
    setNewsletter({ ...newsletter, slotItems });
    closeFreeTextEditor();
    closeArticleSelector();
  }

  const onOpenPreview = () => {
    setShowPreview(true);
  }

  const onClosePreview = () => {
    setShowPreview(false);
  }

  const onChangeTargetedAudiences = (audiences: number[]) => {
    if (!newsletter) {
      return;
    }
    setNewsletter({ ...newsletter, audienceTargeting: { ...newsletter.audienceTargeting, audiences } })
  }

  const onChangeCombineAudiencesWithAnd = (value: boolean) => {
    if (!newsletter) {
      return;
    }
    setNewsletter({ ...newsletter, audienceTargeting: { ...newsletter.audienceTargeting, combineAudiencesWithAnd: value } })
  }

  const addTagFromTagList = (tag: string) => {
    if (!newsletter || _.includes(newsletter.tags, tag)) {
      return;
    }
    setNewsletter({ ...newsletter, tags: [...newsletter.tags, tag] });
  }

  const hasEmptySlots = checkIfContainsEmptySlots(newsletter);

  const nameHasErrors = !newsletter?.name;

  const hasErrors = nameHasErrors || hasEmptySlots;

  return (
    <div className={styles.NewsletterDetails}>
      <div className={styles.header}>
        <img src="/assets/icons/bt_close_bigger.svg" onClick={() => onClose()} style={{ cursor: 'pointer' }} />
        {newsletter &&
          <Fragment>
            <Input invalid={nameHasErrors} className={styles.nameInput} value={newsletter.name || ''} placeholder={`Enter your newsletter name here...`} onChange={(e) => { setNewsletter({ ...newsletter, name: e.target.value }) }}></Input>
            <TippyReact content={isNew ? 'Export is only available in already created newsletters!' : 'Keep in mind that this will export the last saved work and not the work in progress shown below! Additionally, unsubscribe links are always omitted!'}><div><LoadingButton disabled={isNew} color={'secondary'} loading={exportLoading} onClick={() => { onExport() }} text={'export'} /></div></TippyReact>
            <TippyReact config={{ disabled: !hasErrors }} content={nameHasErrors ? 'Name is required!' : 'There are empty slots in this newsletter!'}><div><LoadingButton disabled={hasErrors || saveAndSendLoading} loading={saveLoading} onClick={() => { onSave() }} text={isNew ? 'create' : 'save'} /></div></TippyReact>
            {!isReadOnly && <TippyReact config={{ disabled: !hasErrors }} content={nameHasErrors ? 'Name is required!' : 'There are empty slots in this newsletter!'}><div><LoadingButton disabled={hasErrors || newsletter.status == 'manuallySent' || saveLoading} className={styles.sendButton} color={'secondary'} loading={saveAndSendLoading} onClick={() => { onSend() }} text={`${isNew ? 'create' : 'save'} and send`} /></div></TippyReact>}
          </Fragment>
        }
      </div>
      <div className={styles.content}>
        {loading || !newsletter ? <OrtecLoader /> :
          <Fragment>
            <div className={styles.leftPart} style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
              <div style={{ flex: 1, overflowY: 'auto', padding: '20px' }}>
                <div className={styles.sectionTitle} style={{ marginBottom: '10px' }}>
                  select a template
                </div>
                <div>
                  <Input disabled={isReadOnly} type={'select'} value={newsletter.templateset?.id} onChange={(e) => { changeTemplateset(_.toNumber(e.target.value)) }}>
                    {_.map(templatesets, (t) => {
                      return (
                        <option key={`t-${t.id}`} value={t.id}>{t.name}</option>
                      )
                    })}
                  </Input>
                </div>
                <div>
                  {templatesetLoading ? <OrtecLoader /> :
                    (selectedTemplateset &&
                      <NewsletterBuilder
                        magazine={magazine}
                        templateset={selectedTemplateset}
                        layout={newsletter.layout || []}
                        onLayoutChange={onLayoutChange}
                        slotItems={newsletter.slotItems}
                        openArticleSelector={openArticleSelector}
                        readOnly={isReadOnly}
                      />
                    )
                  }
                </div>
              </div>
              <div className={styles.additionalToolbar}>
                <TippyReact content={isNew ? 'Preview is only available in already created newsletters!' : 'Keep in mind that preview is always showing the last saved work and not the work in progress shown above! Additionally, unsubscribe links are always omitted!'}><div style={{ flex: 1 }}><Button disabled={isNew} style={{ width: '100%' }} outline color={'secondary'} onClick={onOpenPreview}>Preview</Button></div></TippyReact>
              </div>
            </div>
            <div className={styles.rightPart} style={{ flex: 1, overflowY: 'auto', minWidth: '350px' }}>
              <div className={styles.settingsContainer}>
                <div className={styles.section}>
                  <div className={styles.sectionHeader}>
                    <div className={styles.sectionTitle}> settings</div>
                  </div>
                  <div className={styles.sectionContent}>
                    <div className={styles.flexRow}>
                      <div style={{ flex: 1 }}>
                        <div className={styles.label}>Status</div>
                        <Input disabled={isReadOnly} type={'select'} value={newsletter.status} onChange={(e) => setNewsletter({ ...newsletter, status: e.target.value as NlStatus })}>
                          <option value={'draft'}>Draft</option>
                          <option value={'manuallySent'}>Sent via an email client</option>
                          {isReadOnly && <option value={'sent'} disabled>Sent</option>}
                          {isReadOnly && <option value={'ready'} disabled>Ready</option>}
                        </Input>
                      </div>
                    </div>
                    <div className={styles.toggleRow}>
                      <div className={styles.label}>enable newsletter edition</div>
                      <Toggle disabled={isReadOnly} checked={newsletter.edition || false} onChange={(e) => { setNewsletter({ ...newsletter, edition: e.target.checked }) }} />
                    </div>
                    <hr />
                    <div className={styles.flexRow}>
                      <div style={{ flex: 1 }}>
                        <div className={styles.label}>add to campaign</div>
                        <div>
                          <Creatable
                            isClearable
                            value={_.find(campaignListOptions, (o) => o.value == newsletter.campaign?.id)}
                            onChange={(o) => {

                              if (!o) {
                                setNewsletter({ ...newsletter, campaign: undefined });
                              } else {
                                const id = (typeof o.value == 'string') ? -1 : o.value;
                                setNewsletter({ ...newsletter, campaign: { id, name: o.label } })
                              }

                            }}
                            options={campaignListOptions}
                            placeholder={`No campaign`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.flexRow}>
                      <div style={{ flex: 1 }}>
                        <div className={styles.label}>tags</div>
                        <div className={styles.tagListContainer}>
                          {_.map(tagList, (t) => {
                            return (
                              <div key={t.tag} className={styles.tagListTag} onClick={() => { addTagFromTagList(t.tag) }}>
                                {t.tag} <img src={`/assets/icons/16/plus-circled.svg`} />
                              </div>
                            )
                          })}
                        </div>
                        <div className={styles.tagsInputContainer}>
                          <CreatableMultiTextInput
                            value={newsletter.tags || []}
                            onChange={vs => { setNewsletter({ ...newsletter, tags: vs }) }}
                            placeholder={`Add new tag...`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className={styles.section}>
                  <div className={styles.sectionHeader}>
                    <div className={styles.sectionTitle}> target</div>
                  </div>
                  <div className={styles.sectionContent}>
                    <AudiencesTargeting hideDevices magazine={magazine} profile={profile} targetedAudiences={_.map(newsletter.audienceTargeting.audiences, _.toString)} changeTargeting={onChangeTargetedAudiences} loaderSize={21} combineAudiencesWithAnd={newsletter.audienceTargeting.combineAudiencesWithAnd} changeCombineAudiencesWithAnd={onChangeCombineAudiencesWithAnd} targetingMode={'newsletters'} readOnly={isReadOnly} />
                  </div>
                </div>
                <hr />
                {!isNew &&
                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                      <div className={styles.sectionTitle}> analytics</div>
                    </div>
                    <div className={styles.sectionContent}>
                      <NewsletterAnalytics
                        magazine={magazine}
                        newsletterId={newsletterId}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </Fragment>
        }
      </div>
      {showArticleSelector &&
        <ArticleSelector noLeftMargin magazine={_.toString(magazine)} profile={profile} closeHandler={closeArticleSelector} selectHandler={onArticleSelect} limit={1000} extraButtonText={`or add free text`} extraButtonOnClick={() => openFreeTextEditor('')} variationLanguage={selectedTemplateset?.language} disableVariationChange />
      }
      {showFreeTextEditor &&
        <NewsletterFreeTextEditor loadedText={freeTextEditorLoadText} onSave={onFreeTextEditorSave} onClose={closeFreeTextEditor} />
      }
      {showPreview &&
        <NewsletterPreviewModal magazine={magazine} newsletterId={newsletterId} onClose={onClosePreview} />
      }
    </div>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const generateArticleProxy = (rowIndex: number, columnIndex: NlLayoutColumnIndex, article: number, source: number): NlSlotItem_article => {
  return {
    type: 'article',
    rowIndex,
    columnIndex,
    article,
    source
  }
}
const generateTextProxy = (rowIndex: number, columnIndex: NlLayoutColumnIndex, text: string): NlSlotItem_freeText => {
  return {
    type: 'freeText',
    rowIndex,
    columnIndex,
    text,
  }
}

const checkIfContainsEmptySlots = (newsletter: Newsletter | undefined): boolean => {
  if (!newsletter || !newsletter.layout || !newsletter.slotItems) {
    return false;
  }
  const { layout, slotItems } = newsletter;

  for (let i = 0; i < layout.length; i++) {
    const columns = layout[i];
    if (hasEmptySlots(slotItems, i, columns)) {
      return true;
    }
  }

  return false;
}
