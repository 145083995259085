import React from 'react';
import { NlLayout, NlLayoutColumnIndex, NlLayoutNumberOfColumns, NlSlotItem, NlTemplateConfiguration_Content } from './domain';

import styles from './NewsletterContentBuilder.module.scss';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import _ from 'lodash';
import { NewsletterContentBuilderRow } from './NewsletterContentBuilderRow';
import { ImmutablesHelper } from '../../../../utils';

interface Props {
  magazine: number,
  templateConfiguration: NlTemplateConfiguration_Content
  layout: NlLayout
  onLayoutChange: (layout: NlLayout, slotItems: NlSlotItem[]) => void
  slotItems: NlSlotItem[]
  openArticleSelector: (rowIndex: number, columnIndex: NlLayoutColumnIndex, openFreeTextEditorWithText?: string) => void
  readOnly?: boolean

}
export const NewsletterContentBuilder = ({ magazine, templateConfiguration, layout, onLayoutChange, slotItems, openArticleSelector, readOnly }: Props) => {

  const onAddRow = (columns: NlLayoutNumberOfColumns) => {
    onLayoutChange([...layout, columns], slotItems)
  }

  const onDeleteRow = (index: number) => {
    onLayoutChange(ImmutablesHelper.ImmutArray.remove(layout, index), removeSlotItemsInRow(slotItems, index))
  }

  return (
    <div className={styles.NewsletterContentBuilder}>
      {layout.map((columns, index) => {
        return <NewsletterContentBuilderRow key={`row-${index}`} magazine={magazine} templateConfiguration={templateConfiguration} columns={columns} onDelete={() => onDeleteRow(index)} rowIndex={index} slotItems={slotItems} openArticleSelector={openArticleSelector} readOnly={readOnly} />
      })}
      {!readOnly &&
        <div className={classNames(styles.addRowContainer, styles.flexRow)}>
          <Button color={'secondary'} outline onClick={() => onAddRow(1)}> Add 1 column</Button>
          <Button color={'secondary'} outline onClick={() => onAddRow(2)}> Add 2 columns</Button>
        </div>
      }
    </div>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const removeSlotItemsInRow = (slotItems: NlSlotItem[], rowIndex: number) => {

  const newSlotItems = _.compact(_.map(slotItems, (s, index) => {
    if (s.rowIndex < rowIndex) {
      return s;
    } else if (s.rowIndex > rowIndex) {
      return { ...s, rowIndex: s.rowIndex - 1 }
    } else {
      return null;
    }
  }))

  return newSlotItems;
}
