import axios, { AxiosPromise } from 'axios';
import { dashApi, wrapAxios } from '../api';
import { CICChannel, ChannelDetail } from '../pages/Advanced/subpages/Channels/domain';


// export const getMagazineChannels = (magazine: number) => {
//     return dashApi.get(magazine, `channels`);
// }

export const cic = (magazine: number): AxiosPromise<CICChannel[]> => {
    return wrapAxios(axios.get(`/api/${magazine}/cic`));
}

export const getChannel = (magazine: number, channelId: number): AxiosPromise<ChannelDetail> => {
    return dashApi.get(magazine, `channel/${channelId}`);
}

export const deleteChannel = (magazine: number, channelId: number): AxiosPromise<void> => {
    return dashApi.delete(magazine, `channel/${channelId}`);
}

export const createChannel = (magazine: number, channel: ChannelDetail, parent: number): AxiosPromise<void> => {
    return dashApi.post(magazine, `saveChannel`, { channel, parent });
}

export const saveChannel = (magazine: number, channel: ChannelDetail): AxiosPromise<void> => {
    return dashApi.post(magazine, `saveChannel`, { channel });
}

export const saveChannelChildren = (magazine: number, channelId: number, toAdd: number[], toRemove: number[]): AxiosPromise<void> => {
    return dashApi.post(magazine, `channel/${channelId}/children`, { toAdd, toRemove });
}

export const saveChannelParents = (magazine: number, channelId: number, toAdd: number[], toRemove: number[]): AxiosPromise<void> => {
    return dashApi.post(magazine, `channel/${channelId}/parents`, { toAdd, toRemove });
}

export const moveChild = (magazine: number, parent: number, child: number, moveBeforeChild: number | null): AxiosPromise<void> => {
    return dashApi.post(magazine, `channel/${parent}/moveChild`, { child, moveBeforeChild });
}
