import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';

import moment from 'moment';
import * as _ from 'lodash';
import * as qs from 'query-string';

import { Article, Attachment, AgendaItem, AgendaItemMeta, ArticleSource } from '../../domain';
import { MediaHelper, media, ContentHtmlHelper } from '@imgzine/imgzine-core-frontend';
import ArticleChart from '../components/ArticleDetails/ArticleChart';
import { getLogger } from 'nodemailer/lib/shared';

import * as Mh from './mediaHelper';

const MEDIA_ID_ATTR = 'media_id' //case insensitive!
const mediaPlaceholderRegex = /\[imgzine:media=(\d+)\]/gi;
const HtmlToReact = require('html-to-react');
const HtmlToReactParser = HtmlToReact.Parser;
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

const htmlHelper = (magazine: string) =>
    new ContentHtmlHelper((url, { w, h }) => {
        return `/media/${magazine}?${qs.stringify({ url, w, h })}`;
    })


const renderMediaObject = (mediaObj: media.Media, magazine: string) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlHelper(magazine).generateMediaItemHTML(mediaObj) }} />
}
const renderContent = (article: Article, magazine: string) => {
    // const { article } = this.props;

    const media = MediaHelper.parseMediaArray(article.media);

    // console.log(article.content_scrubbed);


    //step 1 covert `[imgzine:media=?]` to `<imgzine media_id="?"></imgzine>`
    const content = !article.content_scrubbed ? '' : article.content_scrubbed
        //convert p tags to divs
        .replace(/<p>/gi, (): string => `<div class="">`)
        .replace(/<\/p>/gi, (): string => `</div>`)
        //convert imgzine placeholders to imgzine tags
        .replace(mediaPlaceholderRegex, (match: string, mediaId: string): string => `<imgzine ${MEDIA_ID_ATTR}="${mediaId}"></imgzine>`)

    //step 2 html to reactElement 
    let counter = 0
    const inlineMediaIds: number[] = []
    const reactElement = new HtmlToReactParser().parseWithInstructions(
        content,
        () => true,//allnodes
        [{
            // a tags <imgzine> processing
            shouldProcessNode: (node: any) => node.name === 'a',
            processNode: (node: any, children: any[]) =>
                <div className="articleContentLink">{children}</div>
        }, {
            // Custom <imgzine> processing
            shouldProcessNode: (node: any) => node.name === 'imgzine',
            processNode: (node: any) => {
                const mediaId = parseInt(node.attribs[MEDIA_ID_ATTR])
                inlineMediaIds.push(mediaId)
                const mediaObj = _.find(media, aMediaObj => aMediaObj.id === mediaId);
                if (!mediaObj) {
                    return null
                }
                return <div key={`imgzine_media_${counter++}`}>{renderMediaObject(mediaObj, magazine)}</div>
            }
        }, {
            // Anything else
            shouldProcessNode: () => true,
            processNode: processNodeDefinitions.processDefaultNode
        }]
    );

    return { inlineMediaIds, reactElement }

}
export function getArticleCmsContent(article: Article, magazine: string) {
    return ReactDOMServer.renderToStaticMarkup(renderContent(article, magazine).reactElement);
}

export function getArticleFeaturedImage(magazine: string, article: Article): string | undefined {
    const media = MediaHelper.parseMediaArray(article.media);

    const featuredImage = media.find(obj => obj.kind === 'image' && obj.featured === 1);
    if (!featuredImage) {
        return undefined;
    }
    // console.log(featuredImage);

    if (_.get(featuredImage, 'url')) {
        return `/media/${magazine}?${qs.stringify(featuredImage)}`
    }

    if (_.get(featuredImage, 'medium')) {
        return `/media/${magazine}?${qs.stringify(_.get(featuredImage, 'medium'))}`
    }
    if (_.get(featuredImage, 'thumbnail')) {
        return `/media/${magazine}?${qs.stringify(_.get(featuredImage, 'thumbnail'))}`
    }

    return undefined;
}

export function getArticleAttachments(magazine: string, article: Article): Array<Attachment> {
    const media = MediaHelper.parseMediaArray(article.media);
    const mediaAttachements = _.filter(media, (obj) => obj.kind === 'file');
    // console.log('mediaAttachements: ', mediaAttachements);

    return _.map(mediaAttachements, (m: any): Attachment => {
        const sfn = m.filename.split('.');
        const extension = sfn[sfn.length - 1];
        return {
            // link: Mh.proxify(magazine, m.link, 1024, 768),
            link: m.link,
            filename: m.filename,
            extension: extension,
            mimeType: m.mimeType
        }
    })
}

export function getItemMeta(item: any): AgendaItemMeta {
    try {
        if (!item.meta) {
            return emptyEventMeta;
        }

        return _.isString(item.meta) ? JSON.parse(item.meta) : item.meta;
    } catch (error) {
        console.log('Error in parsing item meta: ', item.meta);
        return emptyEventMeta;
    }
}
export const emptyArticle: Article = {
    id: '-1',
    external_id: '',
    title: '',
    cdate: '',
    mdate: '',
    content: '',
    content_scrubbed: '',
    content_cms: '',
    author: '',
    intro: '',
    status: 1,
    media: '',
    comments: [],
    channels: [],
    sources: [],
    sourcesStatus: 1,
}

export const emptyEventMeta: AgendaItemMeta = {
    cmsKind: 'event',
    start: '',
    end: '',
    location: '',
    speaker: '',
    timezone: moment().format('Z'),
    tz: moment().utcOffset(),
    userLocalTime: false
}

export const emptyEvent: AgendaItem = {
    ...emptyArticle,
    meta: emptyEventMeta
}

export const timeZoneOffsets: string[] = [
    '-12:00',
    '-11:00',
    '-10:00',
    '-09:00',
    '-08:00',
    '-07:00',
    '-06:00',
    '-05:00',
    '-04:00',
    '-03:00',
    '-02:00',
    '-01:00',
    '00:00',
    '+01:00',
    '+02:00',
    '+03:00',
    '+04:00',
    '+05:00',
    '+06:00',
    '+07:00',
    '+08:00',
    '+09:00',
    '+10:00',
    '+11:00',
    '+12:00',
]

export function convertToReadableTitle(title: string): JSX.Element | string {
    if (_.startsWith(title, `_author_`)) {
        return <span>Post by <i>{_.replace(title, /_author_/g, '') || 'unknown author'}</i></span>;
    }

    return title;
}

export function getArticleFeaturedImageAltText(magazine: string, article: Article): string | undefined {
    const media = MediaHelper.parseMediaArray(article.media);

    const featuredImage = media.find(obj => obj.kind === 'image' && obj.featured === 1);
    if (!featuredImage) {
        return undefined;
    }

    return _.get(featuredImage, 'meta.alt');
}