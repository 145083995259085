import React, { useState } from 'react';
import { ChromePicker, Color } from '@hello-pangea/color-picker';
import { Input } from 'reactstrap';
import styles from './ColorPicker.module.scss';

interface Props {
  color: Color,
  onChange: (c: Color) => void
  disabled?: boolean
}

export const ColorPicker = ({ color, onChange, disabled }: Props) => {

  const [showPicker, setShowPicker] = useState(false);

  const togglePicker = () => {
    setShowPicker(!showPicker);
  }

  return (
    <div className={styles.ColorPicker}>
      <Input readOnly disabled={disabled} onClick={togglePicker} value={color.toString()} onChange={(e) => onChange(e.target.value)} />
      <div className={styles.colorPreview} style={{ backgroundColor: color.toString() || '#000' }}></div>
      {showPicker &&
        <div className={styles.pickerWrapper}>
          <div className={styles.pickerClose} onClick={() => { setShowPicker(false) }}></div>
          <ChromePicker color={color} onChange={(c) => onChange(c.hex)} />
        </div>
      }
    </div>
  )
}
