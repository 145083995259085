import React, { useMemo, useState } from 'react';
import { StatsFilter } from '../../../domain';
import { useQuery } from '@tanstack/react-query';
import { EdErrorHandler, OrtecLoader } from '../../widgets';
import { API } from '../../apis';
import _ from 'lodash';
import DataTable, { ExtendedColumnDescription } from '../../components/DataTable/DataTable';
import { Translations } from '../../translations';
import { Button, ModalBody } from 'reactstrap';
import classNames from 'classnames';

import styles from './TopChannels.module.scss';
import { GenericModal } from '../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../components/GenericModal/GenericModalHeader';

const excludeTypes = [ //type of channels that should be excluded from top channels table
  'search_channel',
  'user_channel',
  'user_posts',
  'fav_folder',
  null,
];

interface Props {
  magazine: number,
  statsFilter: StatsFilter
}

export const TopChannels = ({ magazine, statsFilter }: Props) => {

  const [showAllChannelsModal, setShowAllChannelsModal] = useState<boolean>(false);

  const analyticsTopChannelsQuery = useQuery({
    queryKey: ['analyticsTopChannels', magazine, statsFilter],
    queryFn: async () => {
      try {
        const { data } = await API.oldApi.loadChartData('topChannels', statsFilter, _.toString(magazine));
        return data.data;
      } catch (error) {
        EdErrorHandler(error, `getting top tiles chart data`);
      }
    }
  });

  const filteredChannels = useMemo(() => {
    return _.filter(analyticsTopChannelsQuery.data, (c) => !_.includes(excludeTypes, c.type) && !_.startsWith(c.channelType, '_'))
  }, [analyticsTopChannelsQuery.data])

  const isLoading = analyticsTopChannelsQuery.isFetching;

  const tr = Translations.Translate(Translations, 'Metrics');

  return (
    <div className={classNames(styles.TopChannels, "tableWithTitleBox")}>
      <div className="titleDiv">{tr('top_channels')}</div>
      <div className="tableDiv">
        {isLoading ? <AnalyticsLoader /> :
          <TopChannelsTable
            topChannels={filteredChannels}
            topX={10}
            hideSearchBar
          />
        }
        <div className="tableFooterDiv" ><Button onClick={() => { setShowAllChannelsModal(true) }}>view more</Button></div>
      </div>
      {showAllChannelsModal &&
        <GenericModal isOpen={true} centered >
          <GenericModalHeader
            onClose={() => { setShowAllChannelsModal(false) }}
            title={`Top Tiles`}
            icon={`/assets/icons/24/channels.svg`}
          />
          <ModalBody style={{ minHeight: '225px' }} >
            <div className="tableDiv">
              {isLoading ? <OrtecLoader size={'small'} /> :
                <TopChannelsTable
                  topChannels={filteredChannels}
                />
              }
            </div>
          </ModalBody>
        </GenericModal>
      }

    </div>
  )
}


// ─── Analytics Loader ────────────────────────────────────────────────────────

export const AnalyticsLoader = () => {
  return (
    <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>
  )
}

// ─── Top Channels Table ──────────────────────────────────────────────────────

interface TopChannelsTableProps {
  topChannels: any[]
  topX?: number
  hideSearchBar?: boolean
}

export const TopChannelsTable = ({ topChannels, topX, hideSearchBar }: TopChannelsTableProps) => {

  const tr = Translations.Translate(Translations, 'Metrics');

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'title',
      sort: false,
      text: '',
      formatter: (cell, row) => {
        if (!cell) {
          return (<div className='ellipsisDiv'><span style={{ color: 'grey' }}><i>No title</i> [id: {row.id}]</span></div>)
        }
        return (<div className='ellipsisDiv'>{cell}</div>);
      },
    },
    {
      dataField: 'views',
      sort: true,
      text: 'views',
      headerStyle: { width: '100px' },
      headerAlign: 'center',
      align: 'center',
      headerFormatter: () => {
        return <><img data-tippy-content={tr("channel_views")} src="/assets/icons/views.svg" /><span></span></>
      },
    },
  ]
  return (
    <DataTable
      data={topX ? topChannels.slice(0, topX) : topChannels}
      columns={columns}
      keyField={'id'}
      onRowClick={() => { }}
      hideSearchBar={hideSearchBar}
      toolbarStyle={hideSearchBar ? { display: 'none' } : undefined}
      onlySearchBar={!hideSearchBar}
      searchbarPlacholder={`Search for channel names or ids`}
      noSearchFormatted
      noPagination
      noHover
      defaultSorted={[{
        dataField: 'views',
        order: 'desc'
      }]}
    />
  )
}
