import React, { useEffect, useMemo, useState } from 'react';
import { GenericModal } from '../../../../../components/GenericModal/GenericModal';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import GenericModalHeader from '../../../../../components/GenericModal/GenericModalHeader';
import { EdErrorHandler, LoadingButton, OrtecLoader, SwalSuccess } from '../../../../../widgets';
import { CmsStage } from '../CmsDomain';
import { CmsFeedInfo } from '../CmsItemDetails/CmsItemDetails';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../../../apis';
import _ from 'lodash';
import styles from './CmsDuplicateDialog.module.scss';
import { Profile } from '../../../../../../domain';

export interface DuplicateItem {
  externalId: string,
  title: string,
}

interface Props {
  magazine: number,
  activeFeed: CmsFeedInfo,
  profile: Profile,
  duplicateItem: DuplicateItem,
  activeTab: CmsStage,
  agendaMode?: boolean,
  openItem: (itemId: string, subTab?: CmsStage) => void,
  onClose: () => void
}

export const CmsDuplicateDialog = ({ magazine, activeFeed, profile, duplicateItem, activeTab, agendaMode, openItem, onClose }: Props) => {

  const queryClient = useQueryClient();

  const [selectedVariations, setSelectedVariations] = useState<string[]>([])

  const cmsItemVariationsLangsQuery = useQuery({
    queryKey: ['cmsVariationsLangs', encodeURIComponent(duplicateItem.externalId)],
    enabled: activeFeed.variations?.enabled,
    queryFn: async () => {
      try {
        const { data } = await API.cms.getCmsItemVariationsLangs(magazine, activeFeed.id, activeTab, encodeURIComponent(duplicateItem.externalId));
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting CMS item variations languages`);
      }
    }
  });


  const cmsDuplicateMutation = useMutation({
    mutationKey: ['cmsItemDuplicate', activeFeed.id, activeTab, encodeURIComponent(duplicateItem.externalId)],
    mutationFn: (d: { item: DuplicateItem, variations: string[] }) => API.cms.duplicateCmsItem(magazine, activeFeed.id, profile.displayName, activeTab, encodeURIComponent(d.item.externalId), d.variations),
    onError: (error, d) => {
      EdErrorHandler(error, `duplicating ${itemPrefix} ${d.item.title}`);
    },
    onSuccess: (resp, d) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `CMS ${itemPrefix} <${d.item.title}> has been duplicated successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['cmsItems', magazine, activeFeed.id, `drafts`, agendaMode] });
      onClose();
      openItem(resp.data.duplicateItemId, `drafts`);
    }
  });



  const itemPrefix = useMemo(() => {
    return `${activeTab == 'drafts' ? 'draft ' : ''}${agendaMode ? 'agenda item' : 'article'}`
  }, [activeTab, agendaMode])

  const defaultVariation = useMemo(() => {
    return _.find(activeFeed.variations?.languages, (l) => l.key === activeFeed.variations?.primary);

  }, [activeFeed.variations]);

  const existingVariations = useMemo(() => {
    return _.filter(activeFeed.variations?.languages, (l) => {
      return l.key !== activeFeed.variations?.primary && _.includes(cmsItemVariationsLangsQuery.data, `lang:${l.key}`)
    })
  }, [cmsItemVariationsLangsQuery.data, activeFeed.variations]);

  useEffect(() => {
    setSelectedVariations(_.map(existingVariations, (v) => `lang:${v.key}`));
  }, [existingVariations]);

  const onToggleSelectVariation = (key: string) => {
    if (_.includes(selectedVariations, `lang:${key}`)) {
      setSelectedVariations(_.filter(selectedVariations, (v) => v !== `lang:${key}`))
    } else {
      setSelectedVariations([...selectedVariations, `lang:${key}`]);
    }
  }

  const onDuplicate = () => {
    cmsDuplicateMutation.mutate({
      item: duplicateItem,
      variations: selectedVariations
    })
  }

  const loading = cmsItemVariationsLangsQuery.isFetching;

  return (
    <GenericModal isOpen={true} centered >
      <GenericModalHeader
        onClose={onClose}
        title={`Duplicate ${itemPrefix}`}
      />
      <ModalBody className={styles.CmsDuplicateDialog}>
        {loading ? <OrtecLoader size={'medium'} /> :
          <>
            {!activeFeed.variations?.enabled || _.isEmpty(existingVariations) ?
              <div>
                Are you sure that you want to duplicate <code>{duplicateItem.title}</code>?
              </div>
              :
              <div>
                The {itemPrefix} <code>{duplicateItem.title}</code> has {existingVariations.length} language variation{existingVariations.length !== 1 ? 's' : ''}.
                In addition to the <b>{_.capitalize(defaultVariation?.name)} (Default)</b> one, which variations would you like to duplicate?
                <ul className={styles.variationsList}>
                  {defaultVariation &&
                    <li className={styles.disabled} key={defaultVariation.key}>
                      <input disabled type={'checkbox'} checked={true} />
                      {defaultVariation.name} (Default)
                    </li>
                  }
                  {_.map(existingVariations, (v) => {
                    return (
                      <li key={v.key} onClick={() => { onToggleSelectVariation(v.key) }}>
                        <input type={'checkbox'} checked={_.includes(selectedVariations, `lang:${v.key}`)} />
                        {v.name}
                      </li>
                    )
                  })}
                </ul>
              </div>
            }
          </>
        }
      </ModalBody>
      <ModalFooter>
        <Button style={{ flex: 1 }} size={'lg'} outline color="secondary" onClick={onClose}>Cancel</Button>
        <LoadingButton style={{ flex: 1 }} text={'duplicate'} loading={cmsDuplicateMutation.isLoading} onClick={() => { onDuplicate() }} />
      </ModalFooter>
    </GenericModal>
  )
}
