import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Profile } from '../../../../../domain';
import { API } from '../../../../apis';
import { ArticleDiscussion, QrModal } from '../../../../components';
import ArticlePreview from '../../../../components/ArticlePreview/ArticlePreview';
import { generateUniversalLink } from '../../../../utils/genericHelper';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import { ArticleWithDetails } from '../../../Advanced/subpages/Articles/domain';
import styles from './CommentDetails.module.scss';

interface Props {
  magazine: number,
  profile: Profile,
  articleId: number,
  activeCommentId?: string,
  onClose: () => void
}

export const CommentDetails = ({ magazine, profile, articleId, activeCommentId, onClose }: Props) => {

  const [detailsLoading, setDetailsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const [article, setArticle] = useState<ArticleWithDetails>();

  const [qrModal, setQrModal] = useState(false);

  const loading = detailsLoading;

  const universalLink = generateUniversalLink(profile.appMeta, `/a/${articleId}`)


  useEffect(() => {
    getArticleDetails();
  }, [])

  const getArticleDetails = async () => {
    try {
      setDetailsLoading(true);
      const { data } = await API.articles.getArticleDetails(magazine, articleId);
      if (!data.id || _.toNumber(data.id) !== articleId) {
        setNotFound(true);
      } else {
        setArticle(data);
      }
    } catch (error) {
      EdErrorHandler(error, `getting article details`);
    } finally {
      setDetailsLoading(false);
    }
  }

  const openQRModal = () => {
    setQrModal(true);
  }

  const closeQRModal = () => {
    setQrModal(false);
  }

  const notFoundMessage = <div className={styles.notFoundMessage}><div className="alert alert-danger">{`Article not found`}</div></div>;


  return (
    <div className={styles.CommentDetails}>
      <div className={styles.header}>
        <img src="/assets/icons/bt_close_bigger.svg" onClick={onClose} style={{ cursor: 'pointer' }} />
        {loading || notFound ? '' :
          <Fragment>
            <div className={styles.idContainer}>
              <div className={styles.headerLabel}>ARTICLE ID</div>
              <div className={styles.headerVal}> {articleId}</div>
            </div>
            <div className={styles.linkContainer}>
              <div className={styles.headerLabel}>UNIVERSAL LINK</div>
              <div className={styles.headerVal}> <a href={universalLink} target={'_blank'}>{universalLink}</a></div>
            </div>
            <div className={styles.flexSpacer}></div>
            <div className={styles.qrContainer}>
              <i className={`fa fa-qrcode qrToggler`} data-tippy-content={'Click to see QR code'} onClick={() => { openQRModal() }}></i>
            </div>
          </Fragment>
        }
      </div>
      <div className={styles.content}>
        {loading ? <OrtecLoader /> :
          (notFound || !article?.id ? notFoundMessage :
            <Fragment>
              <div className={styles.leftPart} style={{ flex: 1 }}>
                <div className={styles.articlePreviewWrapper}>
                  <div className={styles.articlePreviewContainer}>
                    <ArticlePreview magazine={_.toString(magazine)} article={{ ...article, id: _.toString(articleId) }} defaultVariationLanguage={profile.magazineLanguages?.primary} />
                  </div>
                </div>
              </div>
              <div className={styles.rightPart} style={{ flex: 1, padding: '20px' }}>
                <div className={styles.commentsSection}>
                  <div className={styles.sectionLabel}>comments</div>
                </div>
                <div className={styles.articleDiscussionWrapper}>
                  <ArticleDiscussion magazine={magazine} articleId={articleId} activeCommentId={activeCommentId} helpText={'Select the comments you want to delete'} helpTextClass={styles.sectionHelpText} helpTextNotFound={'No comments found for this article'} />
                </div>
              </div>
              {qrModal && <QrModal universalLink={universalLink} closeModal={closeQRModal} type={'article'} noVisibleLink />}
            </Fragment>
          )}
      </div>
    </div>
  )
}

