import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { GenericModal } from '../../../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../../../components/GenericModal/GenericModalHeader';
import { Alert, Button, ModalBody, ModalFooter } from 'reactstrap';

interface Props {
  when: boolean
  saveUnsavedWork: () => void
  isDraft: boolean
}

export const CmsNavigationPrompt = ({ when, saveUnsavedWork, isDraft }: Props) => {
  return (
    <NavigationPrompt
      when={when}
    >
      {({ isActive, onConfirm, onCancel }) => {
        if (isActive) {
          return (
            <CmsNavigationPromptModal
              onConfirm={onConfirm}
              onCancel={onCancel}
              saveUnsavedWork={saveUnsavedWork}
              isDraft={isDraft}
            />
          )

        }
      }
      }
    </NavigationPrompt>
  )
}

interface ModalProps {
  onConfirm: () => void,
  onCancel: () => void,
  saveUnsavedWork: () => void,
  isDraft: boolean

}

export const CmsNavigationPromptModal = ({ onConfirm, onCancel, saveUnsavedWork, isDraft }: ModalProps) => {

  const onSave = () => {
    saveUnsavedWork();
    onCancel();
  }

  return (
    <GenericModal isOpen={true} >
      <GenericModalHeader
        onClose={onCancel}
        title={`Save Changes`}
        icon={'/assets/icons/16/failure_alert.svg'}
      />
      <ModalBody>
        <div style={{ padding: '8px' }}>You have unsaved changes. Do you want to save {isDraft ? '' : 'and publish '}them before leaving?</div>
      </ModalBody>
      <ModalFooter>
        <Button style={{ flex: 1 }} size={'lg'} outline color="secondary" onClick={onConfirm}>leave</Button>
        <Button style={{ flex: 1 }} size={'lg'} color="primary" onClick={onSave}>{isDraft ? 'save' : 'publish'}</Button>
      </ModalFooter>
    </GenericModal>
  )
}
