import React, { useMemo } from "react";
import styles from "./ReportedContentsWarningIcon.module.scss";
import classNames from "classnames";

import { useQueryClient } from '@tanstack/react-query';
import _ from "lodash";
import { ReportedContent } from "../../pages/Content/subpages/reportedContent/ReportedContentDomain";

interface Props {
    magazine: number;
    invertedColors?: boolean;
    isInSidebar?: boolean;
}

const ReportedContentsWarningIcon = (props: Props) => {
    const { magazine, invertedColors, isInSidebar } = props;

    const queryClient = useQueryClient();

    const data: ReportedContent[] | undefined = queryClient.getQueryData(['reportedContents', Number(magazine)]);

    const badgeCount = useMemo(() => {
        if(_.isEmpty(data)) {
            return '';
        }

        const activeReportsAmount =  _.filter(data, (r: ReportedContent) => r.reviewedReports !== r.allReports).length;
        if(!activeReportsAmount) {
            return '';
        }
        
        return activeReportsAmount >= 10 ? `9+` : `${activeReportsAmount}`;
    }, [data])

    if(!badgeCount) {
        return null;
    }

    const badge = (<div className={styles.iconWithBadgeWrapper}>
        <div className={classNames(styles.iconWithBadge, {[styles.invertedColors]: invertedColors })}>
            <i className={"fa fa-warning"}></i>
            {badgeCount && (
                <span className={classNames(styles.iconBadge, {[styles.invertedColors]: invertedColors })}><div className={styles.badgeCount}>{badgeCount}</div></span>
            )}
        </div>
    </div>)

    if(isInSidebar) {
        return <div className={styles.badgeContainer}>
            {badge}
        </div>
        
    }

    return <div className={styles.tabContainer}>
        {badge}
    </div>;
};

export default ReportedContentsWarningIcon;
