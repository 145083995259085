import classNames from 'classnames';
import React from 'react';
import { NlTemplateImage } from '../../domain';
import styles from './ImagePlaceholder.module.scss';
import { MediaHelper } from '../../../../../../utils';

interface Props {
  magazine: number
  configuration: NlTemplateImage
  text: string
  imageWidth?: number //in pixels
}

export const ImagePlaceholder = ({ magazine, configuration, text, imageWidth }: Props) => {
  if (!configuration.enabled) {
    return null;
  }
  return (
    <div className={classNames(styles.ImagePlaceholder, { [styles.noBorder]: configuration.src ? true : false })}>
      {configuration.src ?
        <img src={MediaHelper.proxify(magazine, configuration.src, imageWidth)} />
        :
        <span>{text}</span>
      }
    </div>
  )
}
