import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { PluginWithEntrypoints } from './pluginsDomain';


interface SelectOption {
  label: string,
  value: string
}

interface SelectGroup {
  label: string,
  options: SelectOption[]
}


interface Props {
  fieldKey: string,
  value: string,
  plugins: PluginWithEntrypoints[],
  onChange: (key: string, value: string) => void
}

export const PluginEntrypointSelect = ({ plugins, fieldKey, value, onChange }: Props) => {

  const pluginsToOptions = (): SelectGroup[] => {
    return _.map(plugins, (p) => {
      return {
        label: p.settings.name,
        options: _.map(p.entrypoints, (e) => {
          return {
            label: `${p.settings.name} | ${e.name}`,
            value: `${e.targetUrl}`
          }
        })
      }
    })
  }

  const options = pluginsToOptions();

  const findEntrypointOption = (options: SelectGroup[], value: string) => {
    const allOptions = _.flatMap(_.map(options, (o) => o.options));
    return _.find(allOptions, (o) => o.value == value);
  }

  return (

    <div>
      <Select
        options={options}
        value={findEntrypointOption(options, value)}
        onChange={(selectedOption) => {
          onChange(fieldKey, selectedOption?.value || '');
        }}
        placeholder={`Select plugin`}
        isClearable
      />
    </div>
  )
}
