import _ from "lodash";
import moment from "moment"

export const formatDateTimeStr = (dateStr: string): string => {
  try {
    return moment(dateStr).format('DD/MM/YYYY - HH:mm');
  } catch (error) {
    console.warn(error);
    return dateStr;
  }
}

export const stringToDate = (d: string): Date | null => {
  try {
    if (!d) {
      return null;
    }
    const t = Date.parse(d);
    return !_.isNaN(t) ? new Date(t) : null;
  } catch (error) {
    return null;
  }
}

export const dateToString = (d: Date, momentFormat: string): string => {
  if (!d) {
    return '';
  }

  try {
    const m = moment(d);
    if (m.isValid()) {
      return m.format(momentFormat);
    }
    return '';
  } catch (error) {
    return ''
  }

}

export const todayAsString = (): string => {
  return moment().format('YYYY-MM-DD');
}

// ─── Date Formatting Based On Location ───────────────────────────────────────

const DEFAULT_LOCALE_DATE_STRING_OPTIONS: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
}
const DEFAULT_LOCALE_DATETIME_STRING_OPTIONS: Intl.DateTimeFormatOptions = {
  ...DEFAULT_LOCALE_DATE_STRING_OPTIONS,
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,


}

export const dateToLocaleString = (date: Date | string, locale?: string, options?: Intl.DateTimeFormatOptions): string => {

  const d = (typeof date === 'string') ? stringToDate(date) : date;

  if (!d) {
    console.warn(`Could not parse date string "${date}" to valid date`);
    return date as string;
  }

  return d.toLocaleDateString(locale || navigator.language, options || DEFAULT_LOCALE_DATE_STRING_OPTIONS);
}

export const dateTimeToLocaleString = (date: Date | string, locale?: string, options?: Intl.DateTimeFormatOptions): string => {

  const d = (typeof date === 'string') ? stringToDate(date) : date;

  if (!d) {
    console.warn(`Could not parse date string "${date}" to valid date`);
    return date as string;
  }

  return d.toLocaleDateString(locale || navigator.language, options || DEFAULT_LOCALE_DATETIME_STRING_OPTIONS).replace(',', ' -');
}

export const dailyDatePickerFormatBasedOnLocale = (locale?: string): 'dd/MM/yyyy' | 'MM/dd/yyyy' => {
  const l = locale || navigator.language;
  const country = l.split('-')[1];
  return country === 'US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
}
