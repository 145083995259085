import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react'

import { RouteComponentProps, withRouter } from "react-router-dom";
import { Profile } from "../../../../../domain";
import { Channel } from '../../../../components/ChannelsSelect/ChannelsSelect';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';

import styles from './Articles.module.scss';
import { ArticlesFilters } from './ArticlesFilters';
import { Article, PlatformSearchFilter, SearchFilter, SearchingFilters, SearchingProps, SearchQuery } from './domain';

import * as Api from '../../../../api';
import { Source } from '../../../../components/SourcesSelect/SourcesSelect';
import { API } from '../../../../apis';
import _ from 'lodash';

import * as ph from '../../../../utils/routerHelper';
import { ArticleDetails } from './ArticleDetails';
import { DateHelper } from '../../../../utils';


export interface Props extends RouteComponentProps {
  magazine: number,
  profile: Profile,
}

export default function Articles({ magazine, profile, location, history }: Props) {

  const [filtersEnabled, setFiltersEnabled] = useState(false);

  const [articles, setArticles] = useState<Article[]>([]);

  const [totalSize, setTotalSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchText, setSearchText] = useState('');

  const [filters, setFilters] = useState<SearchingFilters>({
    channel: '',
    source: '',
    categories: [],
    tags: [],
    since: '',
    until: ''
  });

  const [channels, setChannels] = useState<Channel[]>([]);
  const [sources, setSources] = useState<Source[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const [loadingChannels, setLoadingChannels] = useState(true);
  const [loadingSources, setLoadingSources] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingTags, setLoadingTags] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(true);

  // const [viewArticle, setViewArticle] = useState<number>();

  const loadingModule = loadingChannels || loadingSources || loadingTags || loadingCategories;

  const viewArticle = ph.getQueryParamNumber(location, 'article');

  useEffect(() => {
    loadChannels();
    loadSources();
    loadCategories();
    loadTags();
  }, [])

  useEffect(() => {
    searchArticles();
  }, [searchText, currentPage, filtersEnabled, filters.channel, filters.source, filters.categories, filters.tags, filters.since, filters.until])

  const loadChannels = async () => {
    try {
      setLoadingChannels(true);
      const { data } = await Api.getMagazineChannels(magazine);
      setChannels(data.channels);
    } catch (error) {
      EdErrorHandler(error, 'getting magazine channels');
      setChannels([])
    } finally {
      setLoadingChannels(false);
    }
  }

  const loadSources = async () => {
    try {
      setLoadingSources(true);
      const { data: sources } = await Api.getSources(magazine);
      setSources(sources);
    } catch (error) {
      EdErrorHandler(error, 'getting magazine sources');
      setSources([])
    } finally {
      setLoadingSources(false);
    }
  }

  const loadTags = async () => {
    try {
      setLoadingTags(true);
      const { data: tags } = await API.articles.getMagazineArticleTags(magazine);
      setTags(tags);
    } catch (error) {
      EdErrorHandler(error, 'getting magazine article tags');
      setTags([])
    } finally {
      setLoadingTags(false);
    }
  }

  const loadCategories = async () => {
    try {
      setLoadingCategories(true);
      const { data: cats } = await API.articles.getMagazineArticleCategories(magazine);
      setCategories(cats);
    } catch (error) {
      EdErrorHandler(error, 'getting magazine article categories');
      setCategories([])
    } finally {
      setLoadingCategories(false);
    }
  }

  const filtersToSearchFilter = (): SearchFilter | undefined => {

    if (!filtersEnabled) {
      return undefined;
    }

    const f: SearchFilter = {
      sources: filters.source ? [_.toNumber(filters.source)] : undefined,
      tags: _.isEmpty(filters.tags) && _.isEmpty(filters.categories) ? undefined : [...filters.categories, ...filters.tags],
      since: filters.since ? new Date(filters.since) : undefined,
      until: filters.until ? moment(filters.until).endOf('day').toDate() : undefined,
    };

    const searchFilter = _.omitBy(f, _.isNil);

    return !_.isEmpty(searchFilter) ? f : undefined;

  }

  const filtersToPlatformSearchFilter = (): PlatformSearchFilter | undefined => {

    if (!filtersEnabled) {
      return undefined;
    }

    const p: PlatformSearchFilter = {
      channels: filters.channel ? [_.toNumber(filters.channel)] : undefined,
    };

    const platformSearchFilter = _.omitBy(p, _.isNil);

    return !_.isEmpty(platformSearchFilter) ? p : undefined;

  }

  const searchTextToSearchQuery = (): SearchQuery => {
    const q: SearchQuery = { type: "all", from: (currentPage - 1) * 10 };

    if (!searchText) {
      return { ...q, sort: { field: "cdate", direction: "desc" } };
    }

    if (searchText.match(/http:\/\/|https:\/\/|relevance-cms:\/\/.*/gm)) {
      return { ...q, type: "link", link: searchText };
    }

    return { ...q, type: "generic", query: searchText }

    // if (!isNaN(parseInt(searchText))) {
    //   return { ...q, type: "id", id: parseInt(searchText) };
    // }
    // return { ...q, type: "title", title: searchText };
  }

  const searchArticles = async (query?: SearchQuery) => {
    try {
      setLoadingSearch(true);
      const q: SearchQuery = query ?? searchTextToSearchQuery();
      const f = filtersToSearchFilter();
      const p = filtersToPlatformSearchFilter();
      const { data } = await API.search.searchMagazineArticles(magazine, q, f, p);
      setArticles(data.articles);
      setTotalSize(data.articlesFound);
    } catch (error) {
      EdErrorHandler(error, 'searching articles');
    } finally {
      setLoadingSearch(false);
    }
  }

  const toggleFilters = () => {
    setFiltersEnabled(!filtersEnabled);
  }

  const changeFilters = (field: string, newVal: any) => {
    setCurrentPage(1);
    setFilters({ ...filters, [field]: newVal });
  }
  const changeSearchText = (newVal: string) => {
    setCurrentPage(1);
    setSearchText(newVal);
  }

  const onViewModalClose = () => {
    ph.setQueryParams(history, { article: undefined });
    // setViewArticle(undefined);
    searchArticles();

  }

  const onArticleView = (a: Article) => {
    ph.setQueryParams(history, { article: a.id });
    // setViewArticle(a.id);
  }


  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'distinctStatuses',
      sort: false,
      text: '',
      headerStyle: { width: '50px' },
      align: 'center',
      headerAlign: 'center',
      formatter: (cell: any, row: Article) => {
        let status = 'visible';
        if (row.articleStatus == 0) {
          status = 'hidden';
        } else if (!cell || !_.isArray(cell)) {
          status = 'hidden'
        } else if (_.includes(cell, 0)) {
          status = 'partial'
        }
        return <i className={`fa fa-circle status status-${status}`} data-tippy-content={status == 'partial' ? `partially visible` : status}></i>;
      }
    },
    {
      dataField: 'title',
      // sort: true,
      text: 'title',
      formatter: (cell: any, row: Article) => {
        return _.trim(cell) ? cell : _.truncate(row.content, { length: 50, separator: ' ' });
      }
    },
    {
      dataField: 'channels',
      sort: false,
      text: 'Channels',
      headerStyle: { width: '200px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img data-tippy-content={'Channels'} src={'/assets/icons/16/channels.svg'} /><span></span></Fragment>
      },
      formatter: (cell: any, row: Article) => {
        if (cell?.length !== 1) {
          return `${cell?.length || 'No'} channels`;
        }
        const c = _.find(channels, (c) => c.channel == _.toNumber(cell[0]));
        return c ? c.label || c.name : cell;
      }
    },
    {
      dataField: 'sources',
      sort: false,
      text: 'Sources',
      headerStyle: { width: '200px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img data-tippy-content={'Sources'} src={'/assets/icons/16/sources.svg'} /><span></span></Fragment>
      },
      formatter: (cell: any, row: Article) => {
        if (cell?.length !== 1) {
          return `${cell?.length || 'No'} sources`;
        }
        const s = _.find(sources, (s) => s.id == _.toNumber(cell[0]));
        return s ? s.label || s.name : cell;
      }
    },
    {
      dataField: 'tags',
      sort: false,
      text: 'Categories',
      headerStyle: { width: '200px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img data-tippy-content={'Categories'} src={'/assets/icons/16/tags_icon.svg'} /><span></span></Fragment>
      },
      formatter: (cell: any, row: Article) => {
        const categories = _.filter(cell, (t) => {
          return _.startsWith(t, '_cat:');
        })
        return categories.length == 1 ? categories[0].replace('_cat:', '') : `${categories.length || 'No'} categories`
      }
    },
    {
      dataField: 'cdate',
      sort: false,
      text: 'Creation Date',
      headerStyle: { width: '200px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img data-tippy-content={'Creation Date'} src={'/assets/icons/16/calendar.svg'} /><span></span></Fragment>
      },
      formatter: (cell: any, row: Article) => {
        return cell ? DateHelper.dateTimeToLocaleString(cell) : ''
      }
    },
  ];

  const searchingProps: SearchingProps = {
    searchText,
    filtersEnabled,
    filters
  }

  return (
    <Fragment>
      <div className={styles.Articles}>
        {loadingModule && !viewArticle ? <OrtecLoader /> :
          <Fragment>
            <ArticlesFilters {...searchingProps} toggleFilters={toggleFilters} changeFilters={changeFilters} changeSearchText={changeSearchText} channels={channels} sources={sources} tags={tags} categories={categories} profile={profile} />
            <DataTable
              data={articles}
              columns={columns}
              keyField={'id'}
              hideSearchBar
              onRowClick={(row: Article) => { onArticleView(row) }}
              remote
              totalSize={totalSize}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
            {loadingSearch && !viewArticle && <OrtecLoader />}
          </Fragment>
        }
      </div>
      {viewArticle && <ArticleDetails magazine={magazine} profile={profile} onClose={onViewModalClose} articleId={viewArticle} />}
    </Fragment>
  )
}

export const ArticlesWithRouter = withRouter(Articles);
