import React, { useMemo, useState } from 'react';
import { CmsItemFormData, CmsFeedInfo } from '../CmsItemDetails';
import { Profile, Variations } from '../../../../../../../domain';
import styles from './CmsVariations.module.scss';
import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { API } from '../../../../../../apis';
import { EdErrorHandler, OrtecLoader } from '../../../../../../widgets';
import { SelectVariationModal } from './SelectVariationModal';
import { CmsVariationDetails } from './CmsVariationDetails';
import { Control, UseFormGetValues } from 'react-hook-form';
import { CmsStage, VariationLanguage } from '../../CmsDomain';

interface Props {
  magazine: number,
  profile: Profile,
  itemId: string,
  activeFeed: CmsFeedInfo,
  cmsStage: CmsStage,
  agendaMode?: boolean,
  getParentValues: UseFormGetValues<CmsItemFormData>

}

export const CmsVariations = ({ magazine, profile, itemId, activeFeed, cmsStage, agendaMode, getParentValues }: Props) => {

  const isNew = (itemId === '-1');

  const [showSelectVariationModal, setShowSelectVariationModal] = useState<boolean>(false);
  const [shownVariationDetails, setShownVariationDetails] = useState<{ variationLanguage: VariationLanguage | undefined, isNew: boolean }>({
    variationLanguage: undefined,
    isNew: false,
  });

  const cmsDraftVariationsLangsQuery = useQuery({
    queryKey: ['cmsItemVariationsLangs', itemId],
    enabled: !isNew && activeFeed.variations?.enabled,
    queryFn: async () => {
      try {
        const { data } = await API.cms.getCmsItemVariationsLangs(magazine, activeFeed.id, cmsStage, itemId);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting CMS item variations languages`);
      }
    }
  });

  const defaultVariation = useMemo(() => {
    return _.find(activeFeed.variations?.languages, (l) => l.key === activeFeed.variations?.primary);

  }, [activeFeed.variations]);

  const missingVariations = useMemo(() => {
    return _.filter(activeFeed.variations?.languages, (l) => {
      return l.key !== activeFeed.variations?.primary && !_.includes(cmsDraftVariationsLangsQuery.data, `lang:${l.key}`)
    })
  }, [cmsDraftVariationsLangsQuery.data, activeFeed.variations]);

  const existingVariations = useMemo(() => {
    return _.filter(activeFeed.variations?.languages, (l) => {
      return l.key !== activeFeed.variations?.primary && _.includes(cmsDraftVariationsLangsQuery.data, `lang:${l.key}`)
    })
  }, [cmsDraftVariationsLangsQuery.data, activeFeed.variations]);




  const loading = cmsDraftVariationsLangsQuery.isFetching;

  const onCreateVariation = (lang: string) => {
    setShownVariationDetails({ variationLanguage: getVariationLanguage(lang, activeFeed.variations), isNew: true });
    setShowSelectVariationModal(false);
  }

  const onOpenVariation = (lang: string) => {
    setShownVariationDetails({ variationLanguage: getVariationLanguage(lang, activeFeed.variations), isNew: false });
    setShowSelectVariationModal(false);
  }

  const onCloseVariation = () => {
    setShownVariationDetails({ variationLanguage: undefined, isNew: false });
    setShowSelectVariationModal(false);
  }




  return (
    <>
      <div className={styles.CmsVariations}>
        {loading ? <OrtecLoader size={'small'} /> :
          <>
            <ul>
              {defaultVariation &&
                <li key={defaultVariation.key}>{defaultVariation.name} (Default)</li>
              }
              {_.map(existingVariations, (v) => {
                return (
                  <li className={styles.clickable} key={v.key} onClick={() => { onOpenVariation(v.key) }}>{v.name}</li>
                )
              })}
            </ul>
            {!_.isEmpty(missingVariations) &&
              <div className={styles.addItemButton} onClick={() => setShowSelectVariationModal(true)}>+ Add Variation</div>
            }
          </>
        }
      </div>
      {showSelectVariationModal && activeFeed.variations &&
        <SelectVariationModal
          feedVariations={activeFeed.variations}
          articleVariations={undefined}
          variationsLangs={_.map(cmsDraftVariationsLangsQuery.data, (v) => v.split(':')[1])}
          createHandler={onCreateVariation}
          closeHandler={() => setShowSelectVariationModal(false)}
        />
      }
      {shownVariationDetails.variationLanguage &&
        <CmsVariationDetails
          magazine={magazine}
          profile={profile}
          itemId={itemId}
          activeFeed={activeFeed}
          cmsStage={cmsStage}
          variationLanguage={shownVariationDetails.variationLanguage}
          isNew={shownVariationDetails.isNew}
          getParentValues={getParentValues}
          onClose={onCloseVariation}
          agendaMode={agendaMode}
        />
      }
    </>
  )
}

const getVariationLanguage = (key: string, feedVariations?: Variations): VariationLanguage | undefined => {
  if (!feedVariations) {
    return undefined;
  }
  return _.find(feedVariations.languages, (l) => {
    return l.key === key
  });

}