import _ from 'lodash';
import React from 'react';
import cn from 'classnames'
import Row from 'reactstrap/lib/Row';
import Label from 'reactstrap/lib/Label';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Toggle from 'react-toggle';
import { ChannelDetail, ChannelTargeting, ChannelTargetingPlatform, ChannelTargetingEntry } from '../domain';
import { SingleSelect } from '../../../../../components/ReactSelect/ReactSelect';
import { ImmutArray } from '../../../../../utils/ImmutablesHelper';

import styles from './ChannelDetailsTargetting.module.scss'

interface Props {
  channel: ChannelDetail
  updateChannel: (channel: Partial<ChannelDetail>) => void
}

function determineInitialTargetingType(targeting: ChannelTargeting): ChannelTargetingType {
  if (!targeting || targeting.length === 0) {
    return 'all';
  } else if (_.find(targeting, t => t.platform === 'none')) {
    return 'none';
  } else {
    return 'custom';
  }
}

function resetTargeting(type: ChannelTargetingType): ChannelTargeting {
  switch (type) {
    case 'all':
      return [];
    case 'custom':
    case 'none':
      return [{ platform: 'none', minVersion: null, maxVersion: null }];
  }
}

// add platform entry and remove 'none' entry if exists
function enablePlatformTargeting(targeting: ChannelTargeting, platform: ChannelTargetingPlatform): ChannelTargeting {
  return ImmutArray
    .push(targeting, { platform, minVersion: null, maxVersion: null })
    .filter(te => te.platform !== 'none')
}

// remove platform entry. If empty array add 'none' entry
function disablePlatformTargeting(targeting: ChannelTargeting, platform: ChannelTargetingPlatform): ChannelTargeting {
  const newTargeting = ImmutArray.removeBy(targeting, te => te.platform === platform)
  return newTargeting.length > 0
    ? newTargeting
    : [{ platform: 'none', minVersion: null, maxVersion: null }]
}

const ChannelTargetingOptions: { value: ChannelTargetingType, label: string }[] = [
  { value: 'all', label: 'All' },
  { value: 'custom', label: 'Custom' },
  { value: 'none', label: 'None' },
]

type ChannelTargetingType = 'all' | 'custom' | 'none'

const PLATFORMS: ChannelTargetingPlatform[] = ['ipad', 'iphone', 'android-tablet', 'android-phone', 'webapp']

export default function ChannelDetailsTargetting({ channel, updateChannel }: Props) {
  const [channelTargetingType, setChannelTargetingType] = React.useState<'all' | 'custom' | 'none'>(determineInitialTargetingType(channel.targeting))

  function updateChannelTargeting(type: ChannelTargetingType) {
    if (type !== channelTargetingType) {
      setChannelTargetingType(type)
      // when we switch to a diff type we have to reset the targeting array
      updateChannel({ targeting: resetTargeting(type) })
    }
  }

  const changePlatformTargeting = (platform: ChannelTargetingPlatform, checked: boolean) => {
    const targeting = checked
      ? enablePlatformTargeting(channel.targeting, platform)
      : disablePlatformTargeting(channel.targeting, platform)
    updateChannel({ targeting })
  }


  const changePlatformTargetingVersion = (platform: ChannelTargetingPlatform, field: 'maxVersion' | 'minVersion', value: string) => {
    const targeting = channel.targeting?.map(te => te.platform === platform
      ? { ...te, [field]: value || null }
      : te
    )
    updateChannel({ targeting })
  }

  return <>
    <Row className={styles.row}>
      <Col md={4}>
        <Label>Platform targeting:</Label>
        <SingleSelect
          options={ChannelTargetingOptions}
          value={channelTargetingType}
          onChange={(v: ChannelTargetingType) => updateChannelTargeting(v)} />
      </Col>
      <Col md={4}>
        <Label>Application Min Version:</Label>
        <Input value={channel.minVersion || ''} onChange={(e) => { updateChannel({ minVersion: e.target.value || null }) }} />
      </Col>
      <Col md={4}>
        <Label>Application Max Version:</Label>
        <Input value={channel.maxVersion || ''} onChange={(e) => { updateChannel({ maxVersion: e.target.value || null }) }} />
      </Col>
    </Row>
    {channelTargetingType === 'custom' &&
      <>
        <Row className={styles.row}>
          <Col md={4}><Label>Platform:</Label></Col>
          <Col md={4}><Label>Min Version:</Label></Col>
          <Col md={4}><Label>Max Version:</Label></Col>
        </Row>
        {PLATFORMS
          .map(platform => {
            const te = _.find(channel.targeting, t => t.platform === platform);
            const checked = te !== undefined
            const maxVersion = te && te.maxVersion ? te.maxVersion : ''
            const minVersion = te && te.minVersion ? te.minVersion : ''
            return <Row key={platform} className={styles.platformRow}>
              <Col md={4} >
                <label className={styles.platformLabel} >
                  <Toggle className={styles.platformToggle} checked={checked} onChange={(e) => changePlatformTargeting(platform, e.target.checked)} />
                  <span>{platform}</span>
                </label>
              </Col>
              <Col md={4}>
                <Input disabled={!checked} value={minVersion} onChange={(e) => changePlatformTargetingVersion(platform, 'minVersion', e.target.value)} />
              </Col>
              <Col md={4}>
                <Input disabled={!checked} value={maxVersion} onChange={(e) => changePlatformTargetingVersion(platform, 'maxVersion', e.target.value)} />
              </Col>
            </Row>
          })
        }
      </>
    }
  </>
}