import React, { useMemo } from "react";
import { PluginWithEntrypoints } from "../../Advanced/subpages/Channels/ChannelInfoPairs/pluginsDomain";
import styles from "./PluginContainer.module.scss";
import { EdErrorHandler, OrtecLoader } from "../../../widgets";
import _ from "lodash";
import { ErrorMessage } from "../../../Error";
import { useQuery } from "@tanstack/react-query";
import { API } from "../../../apis";

interface Props {
    plugin: PluginWithEntrypoints;
    magazine: number;
    uid: string;
}

export const PluginContainer = ({ plugin, magazine, uid }: Props) => {

    const entrypoint = useMemo(() => {
        return _.find(
            plugin.entrypoints,
            (ep) => ep.entrypointType === "ed-entrypoint"
        );
    }, [plugin]);

    const {data: pluginURL, isFetching: loading} = useQuery({
        queryKey: ['plugin_src_url', plugin.id],
        enabled: entrypoint ? true : false,
        queryFn: async () => {
            if(!entrypoint) {
                return '';
            }
            
            try {
                const { data } = await API.plugins.getPluginUrl(magazine, plugin.id, entrypoint.id, uid);
                return data;
            } catch (error) {
                EdErrorHandler(error, `getting plugin src url`);
            }
        },
    })

    if (!entrypoint) {
        return (
            <div className={styles.pluginContainer}>
                <InvalidEntryPointMessage magazine={magazine}/>
            </div>
        );
    }

    return (
        <div className={styles.pluginContainer}>
            {loading ? (
                <OrtecLoader />
            ) : (
                <iframe className={styles.externalIFrame} src={pluginURL} />
            )}
        </div>
    );
};

const InvalidEntryPointMessage = ({ magazine }: {magazine: number}) => {
    return (
        <div style={{ padding: "10px" }}>
            <ErrorMessage
                otherType={"info"}
                magazine={`${magazine}`}
                message={
                    <div>
                        Don't panic! There is something wrong with the plugin configuration. 
                        There are two reasons for this to happen:
                        <br /> 1. The entrypoint for this plugin does not exist. Contact your 
                        company app administrator to check what's wrong.
                        <br /> 2. You don't have the rights to access
                        this plugin. Ask your company app administrator or
                        create a Freshdesk ticket if you should see the
                        plugin.
                    </div>
                }
            />
        </div>
    );
}
