import { AxiosPromise } from 'axios';
import { Crop } from 'react-image-crop';

export type BucketPart = 'public' | 'protected';
export type BucketGroup = 'default' | 'feed' | 'other';

export interface BasicBucket {
    key: string,
    label: string,
}

export interface Bucket extends BasicBucket {
    useOnlyPart?: BucketPart,
    defaultPart?: BucketPart,
    group?: BucketGroup,
    nonAdmin?: boolean
}

export interface UploadPromise {
    fileName: string,
    promise: () => AxiosPromise<any>
}

export interface UploadPromiseError {
    fileName: string,
    error: any
}

export type MediaType = 'images' | 'audios' | 'videos' | 'files'

export const MEDIA_TYPES: MediaType[] = ['images', 'audios', 'videos', 'files']


export interface VimeoVideo {
    id: string //that is derived by url from dapi
    uri: string,
    name: string,
    duration: number, //seconds
    width: number,
    height: number,
    embed: {
        html: string
    },
    created_time: string,
    pictures: {
        base_link: string // https://i.vimeocdn.com/...
    },
    parent_folder: { resource_key: string } | null
    status: string,
    is_playable: boolean
}