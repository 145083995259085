import { Endpoint } from "../../server/utils/accessUtil";
import { EdPage, EdRole, EdRoleLabels } from "./domain";

export const ED_ROLES: EdRole[] = [
  'admin',
  'division admin',
  'editor',
  'editorNoPush',
  'content manager',
  'data analyst',
  'comment reviewer',
  'external content manager',
  'ess+',
  'none'
]

export const ED_ROLES_LABELS: EdRoleLabels = {
  'admin': 'Magazine administrator (global audience)',
  'division admin': 'Division Administrator',
  'editor': 'Editor',
  'editorNoPush': 'Editor (no push)',
  'content manager': 'Content manager',
  'data analyst': 'Data analyst',
  'comment reviewer': 'Comment reviewer',
  'external content manager': 'External content manager',
  'ess+': 'ESS+ user',
  'none': ''
}

export const ED_PAGES: EdPage[] = [
  {
    key: 'overview',
    notAccessibleToRoles: ['external content manager'],
  },
  {
    key: 'analytics',
    notAccessibleToRoles: ['external content manager'],
  },
  {
    key: 'platform',
    parentPage: 'analytics',
  },
  {
    key: 'articles',
    parentPage: 'analytics',
  },
  {
    key: 'widgets',
    parentPage: 'analytics',
    notAccessibleToRoles: ['data analyst', 'comment reviewer', 'ess+'],
    endpoints: [
      Endpoint('get', '/dashApi/widgets'),
      Endpoint('get', /\/dashApi\/widget\/.+\/registeredUsers/),
    ]
  },
  {
    key: 'chatGroups',
    parentPage: 'analytics',
    notAccessibleToRoles: ['data analyst', 'content manager', 'comment reviewer'],
  },
  {
    key: 'content',
    notAccessibleToRoles: ['data analyst'],
  },
  {
    key: 'articles',
    parentPage: 'content',
    endpoints: [
      Endpoint('get', '/dashApi/widgets'),
      Endpoint('post', '/dashApi/widgets/save'),
      Endpoint('delete', /\/dashApi\/widget\/.+/),
      Endpoint('get', /\/dashApi\/feed\/.+\/details/, ['division admin', 'editor', 'editorNoPush']),
      Endpoint('post', /\/dashApi\/feed\/.+\/details/, ['division admin', 'editor', 'editorNoPush']),
      Endpoint('delete', /\/dashApi\/feed\/\d+$/, ['division admin', 'editor', 'editorNoPush']),
    ]
  },
  {
    key: 'agenda',
    parentPage: 'content',
    notAccessibleToRoles: ['comment reviewer', 'ess+'],
    endpoints: [
      Endpoint('get', '/dashApi/widgets'),
      Endpoint('post', '/dashApi/widgets/save'),
      Endpoint('delete', /\/dashApi\/widget\/.+/),
      Endpoint('get', /\/dashApi\/feed\/.+\/details/, ['division admin', 'editor', 'editorNoPush']),
      Endpoint('post', /\/dashApi\/feed\/.+\/details/, ['division admin', 'editor', 'editorNoPush']),
      Endpoint('delete', /\/dashApi\/feed\/\d+$/, ['division admin', 'editor', 'editorNoPush']),
    ]
  },
  {
    key: 'categories',
    parentPage: 'content',
    notAccessibleToRoles: ['external content manager', 'comment reviewer'],
    endpoints: [
      Endpoint('get', /\/dashApi\/feed\/.+\/details/, ['division admin', 'editor', 'editorNoPush']),
      Endpoint('post', /\/dashApi\/feed\/.+\/details/, ['division admin', 'editor', 'editorNoPush']),
      Endpoint('delete', /\/dashApi\/feed\/d+$/, ['division admin', 'editor', 'editorNoPush']),
    ]
  },
  {
    key: 'comments',
    parentPage: 'content',
    notAccessibleToRoles: ['external content manager'],
    endpoints: [
      Endpoint('get', '/sources'),
      Endpoint('get', /\/dashApi\/articles\/.+\/details/),
    ]
  },
  {
    key: 'mediaManager',
    parentPage: 'content',
    notAccessibleToRoles: ['comment reviewer'],
  },
  {
    key: 'reportedContent',
    parentPage: 'content',
    notAccessibleToRoles: ['external content manager'],
    endpoints: [
      Endpoint('get', /\/dashApi\/articles\/.+\/details/),
    ]
  },
  {
    key: 'engage',
    notAccessibleToRoles: ['data analyst', 'content manager', 'external content manager', 'comment reviewer'],
  },
  {
    key: 'pushNotifications',
    parentPage: 'engage',
    notAccessibleToRoles: ['ess+', 'editorNoPush'],
  },
  {
    key: 'forms',
    parentPage: 'engage',
  },
  {
    key: 'newsletters',
    parentPage: 'engage',
    notAccessibleToRoles: ['ess+'],
    endpoints: [
      Endpoint('get', '/dashApi/languages'),
    ]
  },
  {
    key: 'alerts',
    parentPage: 'engage',
  },
  {
    key: 'report',
    notAccessibleToRoles: ['external content manager', 'comment reviewer'],
  },
  {
    key: 'narrowcasting',
    accessibleOnlyToRoles: ['admin', 'division admin', 'editor', 'editorNoPush', 'none'],
    endpoints: [
      Endpoint('post', '/dashApi/articles/titles'),
    ]
  },
  {
    key: 'devices',
    parentPage: 'narrowcasting',
    accessibleOnlyToRoles: ['admin', 'division admin'],
  },
  {
    key: 'slideshows',
    parentPage: 'narrowcasting',
  },
  {
    key: 'settings',
    parentPage: 'narrowcasting',
    accessibleOnlyToRoles: ['admin', 'division admin'],
  },
  {
    key: 'advanced',
    accessibleOnlyToRoles: ['admin', 'division admin', 'ess+'],
  },
  {
    key: 'channels',
    parentPage: 'advanced',
    notAccessibleToRoles: ['ess+'],
    endpoints: [
      Endpoint('get', '/dashApi/languages'),
      Endpoint('get', '/cic'),
      Endpoint('get', '/sources'),
      Endpoint('get', /\/dashApi\/translations\/channel\/.+/),
      Endpoint('get', /channel\/.+/),
      Endpoint('delete', /channel\/.+/),
      Endpoint('post', "/saveChannel"),
      Endpoint('post', "/channelParents"),
      Endpoint('post', "/channelSorting"),
    ]
  },
  {
    key: 'sources',
    parentPage: 'advanced',
    notAccessibleToRoles: ['ess+'],
    endpoints: [
      Endpoint('get', '/dashApi/languages'),
      Endpoint('get', '/cic'),
      Endpoint('get', '/sources'),
      Endpoint('get', /\/dashApi\/translations\/source\/.+/),
      Endpoint('get', /source\/.+/),
      Endpoint('delete', /source\/.+/),
      Endpoint('post', "/source"),
      Endpoint('post', '/dashApi/search/articles'),

    ]
  },
  {
    key: 'articles',
    parentPage: 'advanced',
    // notAccessibleToRoles: ['ess+'],
    accessibleOnlyToRoles: ['admin'],
    endpoints: [
      Endpoint('*', /\/dashApi\/articles\/.+/),
      Endpoint('post', '/dashApi/search/articles'),
      Endpoint('get', '/sources'),
    ]
  },
  {
    key: 'people',
    parentPage: 'advanced',
    endpoints: [
      Endpoint('*', /\/dashApi\/people\/.+/),
    ]
  },
  {
    key: 'audiences',
    parentPage: 'advanced',
    notAccessibleToRoles: ['ess+'],
    endpoints: [
      Endpoint('post', '/dashApi/audiences'),
    ]
  },
  {
    key: 'notificationCenter',
    parentPage: 'advanced',
    // notAccessibleToRoles: ['ess+'],
    accessibleOnlyToRoles: ['admin'],
    endpoints: [
      Endpoint('*', '/dashApi/notificationCenterAdvancedOptions'),
      Endpoint('post', '/dashApi/getNotificationCenterChannelsWithConfig'),
      Endpoint('post', '/dashApi/toggleNotificationCenterChannelConfigField'),
      Endpoint('post', '/dashApi/toggleNotificationCenterAllChannels'),
    ]
  },
  {
    key: 'widgets',
    parentPage: 'advanced',
    notAccessibleToRoles: ['ess+'],
    endpoints: [
      Endpoint('get', '/dashApi/widgets'),
      Endpoint('post', '/dashApi/widgets/save'),
      Endpoint('delete', /\/dashApi\/widget\/.+/),
    ]
  },
  {
    key: 'sharepoint',
    parentPage: 'advanced',
    accessibleOnlyToRoles: ['admin'],

  },
  {
    key: 'monitoring',
    parentPage: 'advanced',
    accessibleOnlyToRoles: ['admin'],
    endpoints: [
      Endpoint('get', '/monitoring'),
    ]
  },
  {
    key: 'plugins',
    accessibleOnlyToRoles: ['admin', 'division admin', 'editor', 'editorNoPush', 'none']
  }
]; 