import { dashApi, endpointWithParams, wrapAxios } from "../api";
import _ from "lodash";
import axios, { AxiosPromise } from "axios";
import * as queryString from 'query-string';
import { Media } from '../../domain';
import { BasicBucket } from '../components/MediaManagerSelector/domain';

export const getMagazineFeedBuckets = (magazine: number): AxiosPromise<BasicBucket[]> => {
  return dashApi.get(magazine, `ics/feedBuckets`);
}

export const getIcsBucketPartMedia = (magazine: number, bucket: string, part: string): AxiosPromise<Media[]> => {
  return dashApi.get(magazine, `ics/bucket/${bucket}/part/${part}/media`);
}

export const deleteBucketPartMedia = (magazine: number, bucket: string, part: string, resourcePath: string): AxiosPromise<void> => {
  return dashApi.delete(magazine, `ics/bucket/${bucket}/part/${part}/deleteMedia?${queryString.stringify({ resourcePath })}`)
}

export const uploadBucketPartMedia = (magazine: number, bucket: string, part: string, data: any, zipMode?: boolean): AxiosPromise<void> => {
  return wrapAxios(axios.post(`/api/${magazine}/ics/bucket/${bucket}/part/${part}/uploadMedia${zipMode ? '?zipMode=true' : ''}`, data, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString'
    }
  }));
}