import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';
import { StatsFilter, Article, Profile } from '../../../domain';
import * as Api from '../../api';

import './css/ArticleQuickView.min.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ArticleContent from '../ArticlePreview/ArticlePreview';
import { Translations } from '../../translations';


export interface Props {
  magazine: string,
  articleId: number,
  hideSelect?: boolean,
  defaultVariationLanguage?: string
  // profile: Profile
  // statsFilter: StatsFilter,
  // articleId: number,
  selectHandler: () => void
  closeHandler: () => void
  disableVariationChange?: boolean
}

export interface State {
  isLoading?: boolean,
  noContent?: boolean,
  article?: Article
}

export class ArticleQuickView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }
  componentDidMount() {
    this.getArticleContent();
  }

  getArticleContent() {

    Api.loadArticleContent(this.props.articleId, this.props.magazine, false, true).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting article content');
        this.setState({ noContent: true, isLoading: false });
      }
      this.setState({ article: resp.data, isLoading: false });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
        this.setState({ noContent: true, isLoading: false });
      });
  }
  render() {
    const { magazine, hideSelect, defaultVariationLanguage, disableVariationChange } = this.props;
    const { isLoading, article, noContent } = this.state;
    const tr = Translations.Translate(Translations, 'ArticleSelector');

    const loader = <div className="loaderContainer"><div className="loaderWrapper" ><i className="fa fa-spinner fa-spin"></i></div></div>
    const closeBtn = <img className="close" onClick={this.props.closeHandler.bind(this)} src="/assets/icons/bt-close.svg" />
    return (
      <Modal isOpen={true} centered size={'lg'} id="ArticleQuickView">
        {Translations.EnableTranslations(Translations)}
        <ModalHeader><span className="headerTitle"><img src="/assets/icons/24/publications_blue.svg" style={{ marginRight: '10px' }}></img> {tr('articleQuickView')}</span> {closeBtn}</ModalHeader>
        <ModalBody>
          <div className='content'>
            {noContent ?
              tr('couldNotLoadArticleContent')
              :
              isLoading || !article ? loader :
                <ArticleContent magazine={magazine} article={article} defaultVariationLanguage={defaultVariationLanguage} disableVariationChange={disableVariationChange} />
            }
          </div>
        </ModalBody>
        {hideSelect ? null :
          <ModalFooter>
            <Button color="primary" block className="btn-lg" onClick={this.props.selectHandler} style={{ textTransform: 'uppercase' }}>{tr('selectArticle')}</Button>
          </ModalFooter>
        }
      </Modal>
    )
  }
}

export default ArticleQuickView;
