import React, { Component, Fragment, PureComponent } from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { Notification } from '../../../../../domain';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import './css/NotificationResults.min.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Row, Button } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Translations } from '../../../../translations';

export interface Props {
  results: any[],
}


class CustomTooltip extends PureComponent<any>{
  constructor(props: any) {
    super(props);
  }
  render() {
    const { active, payload, label } = this.props;
    const tr = Translations.Translate(Translations, 'PushNotifications');

    if (active && payload) {
      const targetDevices = _.add(payload[0].payload.successes, payload[0].payload.failures);
      const successes = payload[0].payload.successes;
      return (
        <div className="CustomChartTooltip">
          {/* TargetDevices = {targetDevices} | Successes = {successes} | {_.round((successes*100)/targetDevices,1)}% */}
          {tr('deliveredTo')} {label} {tr('devices')} = {successes}
        </div>
      )
    }
    return null;
  }
}

class CustomAxisTick extends PureComponent<any>{
  constructor(props: any) {
    super(props);
  }
  render() {
    const { x, y, stroke, payload, } = this.props;
    return <g transform={`translate(${x},${y - 8})`}><image href={`/assets/icons/16/${payload.value}.svg`} x="0" y="0" height="16px" width="16px" /></g>
  }
}


export class NotificationResults extends Component<Props> {


  xTickFormatter(t: any) {
    return t;
  }

  tooltipFormatter(t: any) {
    return t;
  }

  prepareChartData() {
    const { results } = this.props;
    const data = [
      {
        name: 'ios',
        successes: _.size(_.filter(results, (r: any) => r.os === 'ios' && r.type === 'success')),
        failures: _.size(_.filter(results, (r: any) => r.os === 'ios' && r.type === 'failure')),
      },
      {
        name: 'android',
        successes: _.size(_.filter(results, (r: any) => r.os === 'android' && r.type === 'success')),
        failures: _.size(_.filter(results, (r: any) => r.os === 'android' && r.type === 'failure')),
      }
    ];
    return data;
  }

  render() {
    const { results } = this.props;
    const tr = Translations.Translate(Translations, 'PushNotifications');

    return (
      <div id="NotificationResults">
        {Translations.EnableTranslations(Translations)}
        <div className='headerDiv'>{tr('results')}</div>
        <div className='chartDiv'>
          <ResponsiveContainer height={180}>
            <BarChart data={this.prepareChartData()}
              layout="vertical"
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <CartesianGrid horizontal={false} />
              <XAxis tickFormatter={(t) => this.xTickFormatter(t)} allowDecimals={false} type="number" />
              <YAxis dataKey="name" tickMargin={30} tickLine={false} tick={<CustomAxisTick />} type="category" />
              {/* <Tooltip labelFormatter={this.xTickFormatter.bind(this)} formatter={this.tooltipFormatter.bind(this)}/> */}
              <Tooltip content={<CustomTooltip />} offset={-12} />
              {/* <Bar dataKey="failures" name={'Failures'} fill={'#d0021b'} stackId='a' animationDuration={500} barSize={35}/> */}
              <Bar dataKey="successes" name={'Successes'} fill={'#7ed321'} stackId='a' animationDuration={500} barSize={35} />

            </BarChart>

          </ResponsiveContainer>
        </div>
        {/* <div className='footerDiv'><Button className="secondary">copy device tokens of failures</Button></div> */}

      </div>
    );
  }
}

export default NotificationResults;
