import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import { Button, Input, ModalBody, ModalFooter } from 'reactstrap';
import { GenericModal } from '../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../components/GenericModal/GenericModalHeader';
import styles from './NewsletterFreeTextEditor.module.scss';

interface Props {
  loadedText: string,
  onSave: (text: string) => void
  onClose: () => void
}

export const NewsletterFreeTextEditor = ({ loadedText, onSave, onClose }: Props) => {

  const [text, setText] = useState<string>(loadedText);

  useEffect(() => {
    console.log(loadedText);

    setText(loadedText);
  }, [loadedText])


  return (
    <GenericModal isOpen size={'lg'} centered toggle={onClose}>
      <GenericModalHeader
        onClose={onClose}
        title={`Free text editor`}
        icon={`/assets/icons/24/newsletters.svg`}
      />
      <ModalBody>
        <div className={styles.freeTextEditorContainer}>
          {/* <Input type={'textarea'} value={text} onChange={(e)=>setText(e.target.value)}/> */}
          <Editor
            apiKey='v9p14euas3zrlnc7xrqdijyq8vqh2py2b251t9k62eup96n7'
            value={text}
            init={{
              deprecation_warnings: false,
              branding: false,
              menubar: false,
              resize: false,
              toolbar_sticky: true,
              content_css: '/assets/TinyContent.css',
              plugins: 'lists',
              toolbar: `undo redo | bold italic underline | formatselect | forecolor backcolor | alignleft aligncenter alignright | outdent indent |  numlist bullist | removeformat`,

            }}
            onEditorChange={(c) => setText(c)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button outline style={{ flex: 1 }} size={'lg'} color={'secondary'} onClick={onClose}>Cancel</Button>
        <Button style={{ flex: 1 }} size={'lg'} color={'primary'} onClick={() => onSave(text)}>Save</Button>
      </ModalFooter>
    </GenericModal>
  )
}