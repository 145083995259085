import React from 'react';
import styles from './ChangelogModal.module.scss';
import { GenericModal } from '../GenericModal/GenericModal';
import GenericModalHeader from '../GenericModal/GenericModalHeader';
import { ModalBody } from 'reactstrap';
import Markdown from 'markdown-to-jsx';
import { EdErrorHandler, OrtecLoader } from '../../widgets';
import { useQuery } from '@tanstack/react-query';
import { API } from '../../apis';

interface Props {
  magazine: number
  onClose: () => void
}

export const ChangelogModal = ({ magazine, onClose }: Props) => {

  const changelogContentQuery = useQuery({
    queryKey: ['markdownContent', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.oldApi.getChangelogContent(magazine)
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting changelog content`);
        return 'not found'
      }
    }
  });

  const loading = changelogContentQuery.isFetching;


  return (
    <GenericModal className={styles.ChangelogModal} isOpen={true} size={'xxl'} fullHeight>
      <GenericModalHeader
        onClose={onClose}
        title={`ED Changelog`}
        icon={`/assets/icons/16/tags_icon.svg`}
      />
      <ModalBody className={styles.changelogWrapper}>
        {loading && <OrtecLoader size={'medium'} />}
        {changelogContentQuery.data &&
          <Markdown>
            {changelogContentQuery.data || `loading`}
          </Markdown>
        }
      </ModalBody>
    </GenericModal>
  )
}
