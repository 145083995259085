import React, { Fragment, useMemo, useState } from 'react';
import moment from 'moment';
import { Article } from '../../../domain';
import * as _ from 'lodash';
import { MediaHelper, UrlProxyMapper, media } from '@imgzine/imgzine-core-frontend';
import styles from './ArticlePreview.module.scss';
import styles2 from './DetailContent.module.scss'

import cn from 'classnames'
import * as qs from 'query-string';
import ContentRenderer from './DetailContentRenderer';
import { createMediaRenderer } from './DetailMediaRenderer';
import { DateHelper, VariationsHelper } from '../../utils';
import { Input, Label } from 'reactstrap';
import DetailFeaturedImage from './DetailFeaturedImage';
import DetailMediaAddinVideo from './DetailMediaAddinVideo';
import { DetailFeaturedSocialGallery } from './DetailGallery';


export interface Props {
  magazine: string
  article: Article
  defaultVariationLanguage?: string
  disableVariationChange?: boolean
  showUpdateDate?: boolean
}

export interface ArticleDetailStyles {
  link: string
  caption: string
  imageRight: string
  imageLeft: string
  image: string
}

function formatDate(date: string) {
  // return moment(date).format('DD/MM/YYYY');
  return DateHelper.dateToLocaleString(date);
}

const SOCIAL_WALL_TYPES = ['reporter', 'socialwall']

export const ArticlePreview = (props: Props) => {
  const { article, magazine, defaultVariationLanguage, disableVariationChange, showUpdateDate } = props;
  const { id, variations } = article;

  const isSocialWall = article.sources?.find(s => {
    const type = s.kind || s.srctype
    return SOCIAL_WALL_TYPES.includes(type)
  })


  const availableVariationsLanguages = VariationsHelper.getAvailableVariationsLanguages(variations);

  const [activeVariationLanguage, setActiveVariationLanguage] = useState(defaultVariationLanguage || 'en');

  const variation = VariationsHelper.getVariation(variations, activeVariationLanguage);


  const title = variation ? variation.title : article.title;

  const content = variation ? variation.content_scrubbed : article.content_scrubbed;

  const media = MediaHelper.parseMediaArray(variation ? JSON.stringify(variation.media) : article.media);

  const dateOfUpdateText = useMemo(() => {
    if (showUpdateDate && (article.cdate !== article.mdate)) {
      return <span className={styles.updateDateText}>Updated at {DateHelper.dateTimeToLocaleString(article.mdate)}</span>
    }
    return <></>;
  }, [showUpdateDate, article.cdate, article.mdate]);


  const urlProxyMapper: UrlProxyMapper = (url, { w, h }) => {
    return `/media/${magazine}?${qs.stringify({ url, w, h })}`;
  }

  const contentViewStyles: ArticleDetailStyles = styles as any;

  const mediaRenderer = createMediaRenderer(magazine, id, urlProxyMapper, contentViewStyles);
  const widthClass = isSocialWall ? styles.width600 : styles.width800
  return (
    <Fragment>
      {
        availableVariationsLanguages &&
        <div className={styles.variationSelectContainer}>
          <Label> Variation: </Label>
          <Input type="select" value={activeVariationLanguage} onChange={(e) => { setActiveVariationLanguage(e.target.value) }} disabled={disableVariationChange}>
            {availableVariationsLanguages.map(vl => {
              return <option key={vl} selected={activeVariationLanguage == vl ? true : false} value={vl}>{vl}</option>
            })}
          </Input>
        </div>
      }
      <div className={styles.container} id="ArticleContent">
        <div className={cn(widthClass, styles.date)}>{formatDate(article.cdate)} {dateOfUpdateText} </div>
        <h1 className={cn(widthClass, styles.title)}>{title}</h1>
        {!isSocialWall && <div className={styles.vMargin}>
          <DetailFeaturedMediaObject articleId={id} magazine={magazine} media={media} urlProxyMapper={urlProxyMapper} />
        </div>}
        <div className={cn(widthClass, styles.content, styles2.content)}>
          <ContentRenderer article={{ media, id, content }} mediaRenderer={mediaRenderer} classes={contentViewStyles} />
        </div>
        {isSocialWall && <div className={styles.vMargin}>
          <DetailFeaturedMediaObject articleId={id} magazine={magazine} media={media} urlProxyMapper={urlProxyMapper} isSocialWall />
        </div>}
      </div>
    </Fragment>
  );
}

export default ArticlePreview;

export function DetailFeaturedMediaObject(props: {
  magazine: string
  articleId: string
  media: media.Media[],
  urlProxyMapper: UrlProxyMapper,
  isSocialWall?: boolean
}) {
  const { magazine, media, urlProxyMapper, isSocialWall } = props
  const featuredObject = MediaHelper.getFeaturedMediaObject(media)

  if (featuredObject === undefined) {
    return null
  }
  switch (featuredObject.kind) {
    case 'addin-vimeo':
      return <div className={isSocialWall ? styles.width600 : styles.width800}>
        <DetailMediaAddinVideo magazine={magazine} mediaObj={featuredObject} />
      </div>
    case 'gallery':
      return <div className={isSocialWall ? styles.width600 : styles.width800}>
        <DetailFeaturedSocialGallery articleId={props.articleId} images={featuredObject.images || featuredObject.gallery || []} urlProxyMapper={urlProxyMapper} />
      </div>
    case 'image': {
      const link = MediaHelper.extractBestQualityImageLink(featuredObject)
      if (link === undefined) {
        return null
      }
      return <div className={isSocialWall ? styles.width600 : styles.width1000}>
        <DetailFeaturedImage featuredImage={{ image: featuredObject, link }} urlProxyMapper={urlProxyMapper} social={isSocialWall} />
      </div>
    }
    default:
      return null
  }
}
