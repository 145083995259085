import React from 'react'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { ThreeStateToggleUnsetValue, ThreeStateToggleValue } from './domain';

import styles from './ThreeStateToggle.module.scss';

interface Props {
  value: ThreeStateToggleValue,
  unsetValue: ThreeStateToggleUnsetValue,
  disabled?: boolean
  onChange: (c: ThreeStateToggleValue) => void
}

export const ThreeStateToggle = ({ value, unsetValue, disabled, onChange }: Props) => {

  const isUndefined = value === undefined || value === unsetValue;

  const icons = !isUndefined ? undefined : { checked: null, unchecked: <span style={{ color: 'white', fontSize: '10px' }} className='fa fa-minus'></span> };

  const toggleSet = () => {
    onChange(isUndefined ? true : unsetValue);
  }

  return (
    <div className={styles.ThreeStateToggle}>
      <Toggle checked={value || false} disabled={disabled} onChange={(e) => onChange(e.target.checked)} icons={icons} />
      {!disabled &&
        isUndefined ?
        <div className={styles.undefined}>
          {'(undefined)'}
        </div>
        :
        <div className={styles.setToggle} onClick={() => toggleSet()}>
          {'Unset'}
        </div>
      }
    </div>
  )
}
