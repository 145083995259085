import React from 'react'
import cn from 'classnames'
import { MediaHelper, UrlProxyMapper } from '@imgzine/imgzine-core-frontend'

import styles from './Image.module.scss'

interface Props {
  urlProxyMapper: UrlProxyMapper
  link: MediaHelper.ImageLink
  usePortraitOffset?: boolean
  customRenderer?: (props: { url: string, portraitOffsetEnabled?: boolean }) => JSX.Element

}


const CONTAINER_HEIGHT = 500
const CONTAINER_WIDTH = 1000

/**
 * this is copy-pasted/simplified version of the webcore's generic Image component
 */
export default function Image(props: Props) {

  const { link, customRenderer, usePortraitOffset } = props
  const imageRatio = getAspectRatio(link)
  const containerAspectRatio = CONTAINER_WIDTH / CONTAINER_WIDTH
  const portraitOffsetEnabled = usePortraitOffset && imageRatio !== undefined && imageRatio < 1
  const wider = imageRatio !== undefined && imageRatio > containerAspectRatio

  const url = wider
    ? props.urlProxyMapper(link.url, { h: CONTAINER_HEIGHT })
    : props.urlProxyMapper(link.url, { w: CONTAINER_WIDTH })

  if (customRenderer !== undefined) {
    return customRenderer({ url: url, portraitOffsetEnabled })
  }

  if (portraitOffsetEnabled) {
    return <div className={styles.portraitOffSet1}>
      <img src={url} className={cn(styles.image, styles.portraitOffSet2)} />
    </div>
  }
  return <img src={url} className={cn(styles.image)} />

}

function getAspectRatio(imageLink: { w?: number; h?: number }): number | undefined {
  if (imageLink.h === undefined || imageLink.w === undefined) {
    return undefined
  }
  return imageLink.w / imageLink.h
}