import React from 'react';
import classnames from 'classnames';
import { Label } from 'reactstrap';

import styles from './PageSwitch.module.scss';
export interface Props {
    optionLeft: string,
    optionRight: string,
    isRightActive: boolean,
    onClick: () => void,
    label?: string,
    inline?: boolean
}

export function PageSwitch(props: Props) {
    const { optionLeft, optionRight, isRightActive, onClick, label, inline } = props;
    return (
        <div className={styles.pageSwitchWrapper}>
            <div className={styles.pageSwitchLabel}><Label>{label}</Label></div>
            <div className={styles.pageSwitchContainer}>
                <div className={styles.pageSwitch} onClick={onClick}>
                    <div className={classnames(styles.pageSwitchOption, 'left', { [styles.active]: !isRightActive, [styles.inline]: inline })}>{optionLeft}</div>
                    <div className={classnames(styles.pageSwitchOption, 'right', { [styles.active]: isRightActive, [styles.inline]: inline })}>{optionRight}</div>
                </div>
            </div>
        </div>
    )
}