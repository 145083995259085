import { dashApi, endpointWithParams } from "../api";
import _ from "lodash";
import { AxiosPromise } from "axios";
import { ArticleInMagazineChannelsWithTitle } from "../pages/Advanced/subpages/Articles/domain";

export const getArticleDetails = (magazine: number, article: number): Promise<any> => {
  return dashApi.get(magazine, `articles/${article}/details`);
}

export const saveArticleStatus = (magazine: number, article: number, status: number): Promise<any> => {
  return dashApi.post(magazine, `articles/${article}/status`, { status });
}

export const saveArticleSourceStatus = (magazine: number, article: number, source: number, status: number): Promise<any> => {
  return dashApi.post(magazine, `articles/${article}/sourceStatus`, { source, status });
}

export const getMagazineArticleTags = (magazine: number): Promise<any> => {
  return dashApi.get(magazine, `articles/tags`);
}

export const getMagazineArticleCategories = (magazine: number): Promise<any> => {
  return dashApi.get(magazine, `articles/categories`);
}

export const getArticlesInMagazineChannels = (magazine: number, limit?: number, channels?: number[], preferredVariationLanguage?: string, fallbackVariationLanguage?: string): AxiosPromise<ArticleInMagazineChannelsWithTitle[]> => {
  return dashApi.get(magazine, endpointWithParams(`articles`, { limit, channels, preferredVariationLanguage, fallbackVariationLanguage }));
}

export const getArticlesTitles = (magazine: number, articles: number[]): AxiosPromise<Array<{ article: number, title: string }>> => {
  return dashApi.post(magazine, `articles/titles`, { articles });
}