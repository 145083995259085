import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import { Profile } from '../../../domain';
import { Translations } from '../../translations';
import styles from './AudiencesTargeting.module.scss';
import Select from 'react-select';
import { EdErrorHandler, OrtecLoader } from '../../widgets';
import { API } from '../../apis';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

interface SelectOption {
  label: string,
  value: string
}

interface Props {
  magazine: number
  profile: Profile
  targetedAudiences: string[]
  title?: string
  targetingMode?: string
  combineAudiencesWithAnd: boolean,
  changeCombineAudiencesWithAnd: (value: boolean) => void
  loaderSize?: number
  changeTargeting: (audiences: number[]) => void
  readOnly?: boolean
  hideDevices?: boolean
  hideInfo?: boolean
  emptyMeansEveryone?: boolean
}


export const AudiencesTargeting = ({ magazine, profile, targetedAudiences, title, targetingMode, combineAudiencesWithAnd, changeCombineAudiencesWithAnd, loaderSize, changeTargeting, readOnly, hideDevices, hideInfo, emptyMeansEveryone }: Props) => {

  const [profiles, setProfiles] = useState(0);
  const [devices, setDevices] = useState(0);
  const [calculating, setCalculating] = useState(false);

  useEffect(() => {
    calcTargetNumbers();
  }, [_.join(targetedAudiences), combineAudiencesWithAnd])

  const tr = Translations.Translate(Translations, 'TargetingWidget');
  const tg = Translations.Translate(Translations, 'Default');

  const calcTargetNumbers = async () => {
    try {
      setCalculating(true);
      const { data: resp } = await API.targeting.getProfilesAndDevicesFromAudiences(magazine, _.map(targetedAudiences, _.toInteger), targetingMode, combineAudiencesWithAnd);
      setProfiles(resp.data.totalProfiles);
      setDevices(resp.data.totalDevices);
    } catch (error) {
      EdErrorHandler(error, `calculating targeted profiles and devices`);
    } finally {
      setCalculating(false);
    }
  }

  const calcOptions = (): SelectOption[] => {
    const options: SelectOption[] = [];
    if (profile.globalAudience) {
      options.push({ value: _.toString(1), label: tr('all_employees') })
    }

    for (const a of profile.audiences || []) {
      if (a.id != 1) {
        options.push({ value: _.toString(a.id), label: a.label })
      }
    }

    return options;
  }

  const onChange = (newAudiences: SelectOption[], actionMeta: any) => {
    const auds = _.map(newAudiences, (a) => _.toNumber(a.value));
    if (_.includes(auds, 1)) {
      if (actionMeta.option.value == 1) {
        changeTargeting(emptyMeansEveryone ? [] : [1]);
      } else {
        changeTargeting(_.filter(auds, (a) => a !== 1));
      }
    } else {
      changeTargeting(auds);
    }
  }

  const audiencesAsOptions = calcOptions();



  return (
    <div className={styles.AudiencesTargeting}>
      {!hideInfo &&
        <div className={styles.infoContainer}>
          {title && <div className={styles.title}>{title}</div>}
          <div style={{ flex: 1 }}></div>
          {calculating ? <OrtecLoader size={'icon'} px={loaderSize || 24} /> :
            <div>{profiles} Profiles {!hideDevices && <span>({devices} Devices)</span>}</div>
          }
        </div>
      }
      <div>
        <Select
          isDisabled={readOnly}
          placeholder={tr('selectAudiences')}
          options={audiencesAsOptions}
          isMulti
          value={
            emptyMeansEveryone && _.isEmpty(targetedAudiences) ? _.filter(audiencesAsOptions, (op) => op.value == '1') :
              _.map(targetedAudiences, (ta) => {
                const op = _.find(audiencesAsOptions, (op) => op.value == ta);
                return op || { value: ta, label: ta }
              })}
          onChange={onChange}
        />
        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
          <Toggle disabled={readOnly} checked={combineAudiencesWithAnd ? true : false} onChange={(e) => changeCombineAudiencesWithAnd(e.target.checked)} />
          <span style={{ marginLeft: '10px', fontSize: '14px' }}>{tg('partOfAllAudiences')}</span>
        </div>
      </div>
    </div>
  )
}
