import React from 'react';
import { Alert, Button } from 'reactstrap';

import styles from './NobodyAudienceAlert.module.scss';

interface Props {
  onButtonClick: () => void
}

export const NobodyAudienceAlert = ({ onButtonClick }: Props) => {
  return (
    <Alert className={styles.NobodyAudienceAlert} color={'danger'}>
      Nobody audience is missing for this magazine.
      <div style={{ flex: 1 }}></div>
      <Button onClick={() => onButtonClick()}>Create it</Button>
    </Alert>
  )
}
