
import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form as RForm, FormGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';

import './css/SurveySectionBuilder.min.css';
import { Translations } from '../../../../translations';
import { Form, FormQuestion, FormQuestionAnswer } from '../../../../../domain';
import * as FormsHelper from './FormsHelper';

export interface Props {
  form: Form,
  activeSection: number,
  activeCategory: string,
  changeForm: (form: Form) => void
}

export class QuizSectionBuilder extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  isNewForm = (): boolean => {
    const { form } = this.props;
    return form.id === -1;
  }

  changeQuestion = (question: FormQuestion, field: string, value: string | boolean | number) => {
    const { form, activeCategory } = this.props;
    let q = _.find(activeCategory == 'q' ? form.config.questions : form.config.results, ['id', question.id]);
    if (!q) {
      q = { ...FormsHelper.emptyQuestion };
    }
    const newQuestion = { ...q };
    _.set(newQuestion, field, value);
    const newForm = { ...form };
    if (activeCategory == 'q')
      newForm.config.questions = newForm.config.questions.map((quest, ind) => {
        if (quest.id == question.id) {
          return newQuestion;
        } else {
          return quest;
        }
      });
    else {
      if (!newForm.config.results) {
        return false;
      }
      newForm.config.results = newForm.config.results.map((quest, ind) => {
        if (quest.id == question.id) {
          return newQuestion;
        } else {
          return quest;
        }
      });
    }
    this.props.changeForm(newForm);
  }

  changeSection = (section: any, field: string, value: string) => {
    const { form, activeSection } = this.props;

    const newSection = { ...section };
    _.set(newSection, field, value);
    const newForm = { ...form };
    if (!newForm.config.sections) {
      newForm.config.sections = [];
    }
    newForm.config.sections[activeSection] = newSection;
    this.props.changeForm(newForm);
  }

  renderAnswerInput = (removable: boolean, index: number, question: FormQuestion,) => {
    return (
      <FormGroup key={`answer-${question.id}-${index}`}>
        <div className={'draggableInput'}>
          <i className='material-icons'>drag_indicator</i> <Input placeholder={`Answer ${index + 1}`} value={question && question.answers && question.answers[index] ? question.answers[index].text : ''} onChange={(e) => this.changeQuestion(question, `answers[${index}].text`, e.target.value)}></Input>
          {!removable ? null :
            <i className='material-icons' onClick={(e) => this.removeAnswer(question, index)}>close</i>
          }
        </div>
      </FormGroup>
    )
  }

  addAnswer = (question: FormQuestion) => {
    const { form } = this.props;

    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: form.config.questions.map(q =>
          q.id === question.id
            ? {
              ...question,
              answers: !question.answers ? undefined : [...question.answers, {
                id: question.answers.length + 1,
                text: ''
              }]
            }
            : q
        )
      }
    }
    this.props.changeForm(newForm);
  }

  removeAnswer = (question: any, answerId: number) => {
    const { form } = this.props;
    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: form.config.questions.map(q =>
          q.id === question.id
            ? {
              ...question,
              answers: _.filter(question.answers, a => a.id !== answerId)
            }
            : q
        )
      }
    }
    this.props.changeForm(newForm);
  }

  getActivePage = () => {
    const { form, activeSection, activeCategory } = this.props;
    if (!form || !form.config || !form.config.results) {
      return;
    }
    if (activeCategory == 'q') {
      return form.config.questions[activeSection];
    } else {
      return form.config.results[activeSection];
    }
  }

  addQuestion = (index: number, questionType: string) => {

    const { form, activeSection } = this.props;

    const newQuestionId = FormsHelper.getNewId(form);
    const newQuestions = [...form.config.questions, { ...FormsHelper.newEmptyQuestion(questionType), id: newQuestionId }]

    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: newQuestions,
        sections:
          !form.config.sections
            ? undefined
            : form.config.sections.map((s, i) => {
              if (i == activeSection) {

                const newSectionQuestions = [...s.questions];

                newSectionQuestions.splice(index, 0, newQuestionId);

                return {
                  ...s,
                  questions: newSectionQuestions
                }
              } else {
                return s;
              }
            })
      }
    }
    this.props.changeForm(newForm);
  }

  removeQuestion = (questionId: number) => {
    const { form, activeSection } = this.props;

    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: _.filter(form.config.questions, (q => q.id !== questionId)),
        sections:
          !form.config.sections
            ? undefined
            : form.config.sections.map((s, i) => {
              if (i == activeSection) {
                return {
                  ...s,
                  questions: _.filter(s.questions, (q => q !== questionId))
                }
              } else {
                return s;
              }
            })
      }
    }
    this.props.changeForm(newForm);
  }

  renderAddQuestionButton = (index: number) => {
    return (
      <div className={'addNewQuestionButtonDiv'}>
        {/* <Button className={'addNewQuestionButton'} onClick={()=>this.addQuestion(index)}>add a new question</Button> */}
        {/* <Input className={'addNewQuestionButton btn btn-secondary'} type="select">
          <option value=''>Add a new question</option>
        </Input> */}
        <UncontrolledDropdown className={'addNewQuestionButton'}>
          <DropdownToggle>
            Add a new question
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.addQuestion(index, 'textfield')}>Textfield</DropdownItem>
            <DropdownItem onClick={() => this.addQuestion(index, 'textarea')}>Textarea</DropdownItem>
            <DropdownItem onClick={() => this.addQuestion(index, 'singleAnswer')}>Single Answer</DropdownItem>
            <DropdownItem onClick={() => this.addQuestion(index, 'multipleAnswers')}>Multiple Answers</DropdownItem>
            <DropdownItem onClick={() => this.addQuestion(index, 'starRating')}>Star Rating</DropdownItem>
            <DropdownItem onClick={() => this.addQuestion(index, 'image')}>Image</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    )
  }

  renderAnswersBox = (question: FormQuestion, questionIndex: number, nonDraft?: boolean) => {
    if (!question.answers || question.answers.length !== 2) {
      return null;
    }
    return (
      <Fragment>
        <FormGroup row >
          <Col sm={6}>
            <Input placeholder={'Answer 1'} type={'textarea'} rows={5} value={question.answers[0].text} onChange={(e) => this.changeQuestion(question, 'answers.0.text', e.target.value)} />
            <CustomInput id={`quizAnswers-0`} type="radio" name='quizAnswers' label="This is the correct answer" checked={question.correctAnswer == 1} onChange={(e) => { e.target.checked ? this.changeQuestion(question, 'correctAnswer', 1) : null }} />
          </Col>
          <Col sm={6}>
            <Input placeholder={'Answer 2'} type={'textarea'} rows={5} value={question.answers[1].text} onChange={(e) => this.changeQuestion(question, 'answers.1.text', e.target.value)} />
            <CustomInput id={`quizAnswers-1`} type="radio" name='quizAnswers' label="This is the correct answer" checked={question.correctAnswer == 2} onChange={(e) => { e.target.checked ? this.changeQuestion(question, 'correctAnswer', 2) : null }} />
          </Col>
        </FormGroup>
        <FormGroup>
          <Input placeholder={'Explanation text (Shown after user select an asnwer)'} type="textarea" rows={5} value={question.explanationText ? question.explanationText : ''} onChange={(e) => this.changeQuestion(question, 'explanationText', e.target.value)} />
        </FormGroup>
      </Fragment>
    )
  }
  renderButtonBox = (question: FormQuestion, questionIndex: number) => {
    return (
      <FormGroup>
        <div className={'btn btn-secondary'} style={{ width: '100%', height: 'auto' }}><Input style={{ textAlign: 'center', margin: 'auto' }} value={question.button ? question.button : ''} onChange={(e) => this.changeQuestion(question, 'button', e.target.value)} /></div>
      </FormGroup>
    )

  }
  renderOptInAnswersBox = (question: FormQuestion, questionIndex: number) => {
    return (
      <Fragment>
        <FormGroup row >
          <Col sm={6}>
            <Input placeholder={'Reject text'} type={'textarea'} rows={5} value={question.rejectButton ? question.rejectButton : ''} onChange={(e) => this.changeQuestion(question, 'rejectButton', e.target.value)} />
          </Col>
          <Col sm={6}>
            <Input placeholder={'Accept text'} type={'textarea'} rows={5} value={question.acceptButton ? question.acceptButton : ''} onChange={(e) => this.changeQuestion(question, 'acceptButton', e.target.value)} />
          </Col>
        </FormGroup>
      </Fragment>
    )

  }

  getPageType = (question: FormQuestion) => {
    const tr = Translations.Translate(Translations, 'Forms');

    switch (question.type) {
      case 1:
        return tr('textPage');
      case 3:
        return tr('questionPage');
      case 7:
        return tr('optInPage');
      case 8:
        return tr('resultsPage');
      default:
        return tr('page');
    }
  }

  renderPageBox = (question: FormQuestion, index: number, nonDraft?: boolean) => {
    return (
      <Fragment>
        <div className={`elementBox questionBox ${nonDraft ? 'withMargin' : ''}`} >
          <RForm>
            <FormGroup>
              <div className={'headerDiv'}>
                {/* <i className={'material-icons'}>drag_indicator</i> */}
                <div className={'header'}>{this.getPageType(question)}</div>
                {/* {question.type !== 3?null:
                <Label><Input type='checkbox' checked={question.required?true:false} onChange={(e)=>this.changeQuestion(question,'required',e.target.checked)}/> Required</Label>
                } */}
              </div>
            </FormGroup>
            <FormGroup>
              <Input placeholder={'Title'} value={question ? question.title : ''} onChange={(e) => this.changeQuestion(question, 'title', e.target.value)}></Input>
            </FormGroup>
            <FormGroup>
              <Input rows={5} type='textarea' placeholder={question.type !== 3 ? 'Text' : 'Question'} value={question ? question.text : ''} onChange={(e) => this.changeQuestion(question, 'text', e.target.value)}></Input>
            </FormGroup>
            {question.type !== 1 ? null : this.renderButtonBox(question, index)}
            {question.type !== 3 ? null : this.renderAnswersBox(question, index, nonDraft)}
            {question.type !== 7 ? null : this.renderOptInAnswersBox(question, index)}
          </RForm>
        </div>
      </Fragment>
    )
  }

  render() {
    const { form, activeSection, activeCategory } = this.props;
    const tr = Translations.Translate(Translations, 'Forms');

    const page = this.getActivePage();

    return (
      <div id="SurveySectionBuilder">
        {Translations.EnableTranslations(Translations)}
        <div className={'sectionTitle'}>{`${activeCategory == 'q' ? 'Questions' : 'Results'} - ${tr('page')} ${activeSection + 1}`}</div>
        {this.renderPageBox(page, activeSection, form.status !== 1 ? true : false)}
      </div>
    )
  }
}

export default QuizSectionBuilder;
