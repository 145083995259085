import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Profile } from '../../../../../domain';
import { API } from '../../../../apis';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import { NotificationCenterChannelConfig, ChannelConfigField, DEFAULT_CHANNEL_CONFIG, NotificationCenterChannelWithConfig } from './domain';
import styles from './NotificationCenter.module.scss';
import Toggle from 'react-toggle';
import "react-toggle/style.css";


import { AdvancedConfiguration } from './AdvancedConfiguration';
import classNames from 'classnames';

interface FormatExtraData {
  channels: NotificationCenterChannelWithConfig[]
}

interface Props {
  magazine: number,
  profile: Profile,
}

export const NotificationCenter = ({ magazine, profile }: Props) => {

  const [data, setData] = useState<NotificationCenterChannelWithConfig[]>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [showAdvancedConfiguration, setShowAdvancedConfiguration] = useState(false);

  useEffect(() => {
    loadData();
  }, [])


  const loadData = async () => {
    try {
      setDataLoading(true);
      const { data: channels } = await API.notificationCenter.getNotificationCenterChannelsWithConfig(magazine);
      setData(channels);
    } catch (error) {
      EdErrorHandler(error, `getting notification center channels`)
    } finally {
      setDataLoading(false);
    }
  }

  const toggleChannelConfigFieldValue = async (channelId: number, field: ChannelConfigField, value: boolean) => {
    try {
      setData(changeChannelConfigFieldValue(data, channelId, field, value));
      await API.notificationCenter.toggleNotificationCenterChannelConfigField(magazine, channelId, field, value);
    } catch (error) {
      EdErrorHandler(error, `changing channel's config value`);
      setData(changeChannelConfigFieldValue(data, channelId, field, !value));
    }
  }

  const toggleAllChannels = async (value: boolean) => {
    try {
      setDataLoading(true);
      setData(changeAllChannelsConfigFieldValue(formatExtraData.channels, 'enabled', value));
      await API.notificationCenter.toggleNotificationCenterAllChannels(magazine, value);
    } catch (error) {
      EdErrorHandler(error, `changing all channels config value`);
    } finally {
      setDataLoading(false);
      loadData();
    }
  }

  const formatExtraData: FormatExtraData = {
    channels: [...data]
  };

  const renderToggleColumn = (channel: NotificationCenterChannelWithConfig, formatExtraData: FormatExtraData, field: ChannelConfigField, disablable?: boolean): JSX.Element => {
    const c = _.find(formatExtraData.channels, (c) => c.id === channel.id);
    return (
      <div className={styles.verticalCenteredContent}>
        <Toggle disabled={(!disablable || c?.config?.enabled) ? false : true} checked={c?.config?.[field] ? true : false} onChange={(e) => { toggleChannelConfigFieldValue(channel.id, field, e.target.checked) }} />
      </div>
    )
  }

  const toggleFormatter = (field: ChannelConfigField, disablable?: boolean) => {
    return (config: NotificationCenterChannelConfig, channel: NotificationCenterChannelWithConfig, rowIndex: number, formatExtraData: FormatExtraData) => {
      return renderToggleColumn(channel, formatExtraData, field, disablable);
    }
  }

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'config',
      text: '',
      headerFormatter: () => {
        const all = _.isEmpty(_.filter(formatExtraData.channels, (c) => !c.config?.enabled));
        const none = _.isEmpty(_.filter(formatExtraData.channels, (c) => c.config?.enabled));
        const icons = {
          unchecked: none ? undefined : <span style={{ color: 'white', fontSize: '10px' }} className='fa fa-minus'></span>
        }
        return (
          <div className={styles.verticalCenteredContent}>
            <Toggle icons={icons} className={classNames({ [styles.hidden]: dataLoading })} checked={all} onChange={(e) => toggleAllChannels(e.target.checked)} />
          </div>
        )
      },
      formatter: toggleFormatter('enabled'),
      formatExtraData
    },
    {
      dataField: 'id',
      text: 'Id',
      hidden: true,
    },
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'label',
      text: 'Label'
    },
    {
      dataField: 'type',
      text: 'Type'
    },
    {
      dataField: 'config',
      text: 'User Configurable',
      formatter: toggleFormatter('userConfigurable', true),
      formatExtraData
    },
    {
      dataField: 'config',
      text: 'Default Value',
      formatter: toggleFormatter('defaultValue', true),
      formatExtraData
    },
    {
      dataField: 'config',
      isDummyField: true,
      hidden: true,
      text: 'search',
      formatter: (config: NotificationCenterChannelConfig, channel: NotificationCenterChannelWithConfig) => {
        return `${channel.config?.enabled ? 'enabled' : 'disabled'} ${channel.config?.userConfigurable ? 'user configurable' : ''} ${channel.config?.defaultValue ? 'default value' : ''}`
      },
    },
  ];

  return (
    <div className={styles.NotificationCenter}>
      <DataTable
        noPagination
        noHover
        data={data}
        keyField={'id'}
        columns={columns}
        onRowClick={() => { }}
        onCreateNew={() => setShowAdvancedConfiguration(true)}
        createNewButtonText={`advanced configuration`}
        createNewButtonColor={`secondary`}
      />
      {dataLoading && <OrtecLoader />}
      {showAdvancedConfiguration &&
        <AdvancedConfiguration
          magazine={magazine}
          onClose={() => setShowAdvancedConfiguration(false)}
        />
      }
    </div>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const changeChannelConfigFieldValue = (channels: NotificationCenterChannelWithConfig[], channelId: number, field: ChannelConfigField, value: boolean): NotificationCenterChannelWithConfig[] => {
  return _.map(channels, (c) => {
    if (c.id !== channelId) {
      return c;
    }
    return {
      ...c,
      config: {
        ...(c.config || DEFAULT_CHANNEL_CONFIG),
        [field]: value
      }
    }
  })
}

const changeAllChannelsConfigFieldValue = (channels: NotificationCenterChannelWithConfig[], field: ChannelConfigField, value: boolean): NotificationCenterChannelWithConfig[] => {
  return _.map(channels, (c) => {
    return {
      ...c,
      config: {
        ...(c.config || DEFAULT_CHANNEL_CONFIG),
        [field]: value
      }
    }
  })
}