
import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form as RForm, FormGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormFeedback } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';

import './css/SurveySectionBuilder.min.css';
import { Translations } from '../../../../translations';
import { Form, FormQuestion, FormQuestionAnswer } from '../../../../../domain';
import * as FormsHelper from './FormsHelper';
import TippyReact, { initTippy } from '../../../../components/TippyReact';

export interface Props {
  form: Form,
  activeSection: number
  changeForm: (form: Form) => void
}

export class SurveySectionBuilder extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  isNewForm = (): boolean => {
    const { form } = this.props;
    return form.id === -1;
  }

  changeQuestion = (question: FormQuestion, field: string, value: string | boolean | number) => {
    const { form } = this.props;
    let q = _.find(form.config.questions, ['id', question.id]);
    if (!q) {
      q = { ...FormsHelper.emptyQuestion };
    }
    const newQuestion = { ...q };
    _.set(newQuestion, field, value);
    const newForm = { ...form };
    newForm.config.questions = newForm.config.questions.map((quest, ind) => {
      if (quest.id == question.id) {
        return newQuestion;
      } else {
        return quest;
      }
    });
    this.props.changeForm(newForm);
  }

  changeSection = (section: any, field: string, value: string) => {
    const { form, activeSection } = this.props;

    const newSection = { ...section };
    _.set(newSection, field, value);
    const newForm = { ...form };
    if (!newForm.config.sections) {
      newForm.config.sections = [];
    }
    newForm.config.sections[activeSection] = newSection;
    this.props.changeForm(newForm);
  }

  renderAnswerInput = (removable: boolean, index: number, question: FormQuestion,) => {
    return (
      <FormGroup key={`answer-${question.id}-${index}`}>
        <div className={'draggableInput'}>
          <i className='material-icons'>drag_indicator</i> <Input placeholder={`Answer ${index + 1}`} value={question && question.answers && question.answers[index] ? question.answers[index].text : ''} onChange={(e) => this.changeQuestion(question, `answers[${index}].text`, e.target.value)}></Input>
          {!removable ? null :
            <i className='material-icons' onClick={(e) => this.removeAnswer(question, index)}>close</i>
          }
        </div>
      </FormGroup>
    )
  }

  addAnswer = (question: FormQuestion) => {
    const { form } = this.props;

    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: form.config.questions.map(q =>
          q.id === question.id
            ? {
              ...question,
              answers: !question.answers ? undefined : [...question.answers, {
                id: question.answers.length + 1,
                text: ''
              }]
            }
            : q
        )
      }
    }
    this.props.changeForm(newForm);
  }

  removeAnswer = (question: any, answerId: number) => {
    const { form } = this.props;
    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: form.config.questions.map(q =>
          q.id === question.id
            ? {
              ...question,
              answers: _.filter(question.answers, a => a.id !== answerId)
            }
            : q
        )
      }
    }
    this.props.changeForm(newForm);
  }

  getActiveSection = () => {
    const { form, activeSection } = this.props;
    if (!form || !form.config || !form.config.sections) {
      return;
    }
    return form.config.sections ? form.config.sections[activeSection] : undefined;
  }

  addQuestion = (index: number, questionType: string) => {

    const { form, activeSection } = this.props;

    const newQuestionId = FormsHelper.getNewId(form);
    const newQuestions = [...form.config.questions, { ...FormsHelper.newEmptyQuestion(questionType), id: newQuestionId }]

    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: newQuestions,
        sections:
          !form.config.sections
            ? undefined
            : form.config.sections.map((s, i) => {
              if (i == activeSection) {

                const newSectionQuestions = [...s.questions];

                newSectionQuestions.splice(index, 0, newQuestionId);

                return {
                  ...s,
                  questions: newSectionQuestions
                }
              } else {
                return s;
              }
            })
      }
    }
    this.props.changeForm(newForm);
  }

  removeQuestion = (questionId: number) => {
    const { form, activeSection } = this.props;

    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: _.filter(form.config.questions, (q => q.id !== questionId)),
        sections:
          !form.config.sections
            ? undefined
            : form.config.sections.map((s, i) => {
              if (i == activeSection) {
                return {
                  ...s,
                  questions: _.filter(s.questions, (q => q !== questionId))
                }
              } else {
                return s;
              }
            })
      }
    }
    this.props.changeForm(newForm);
  }

  componentDidMount(): void {
    this.initTooltips();
  }

  initTooltips() {
    initTippy('#SurveySectionBuilder');
  }

  renderAddQuestionButton = (index: number) => {
    return (
      <div className={'addNewQuestionButtonDiv'}>
        {/* <Button className={'addNewQuestionButton'} onClick={()=>this.addQuestion(index)}>add a new question</Button> */}
        {/* <Input className={'addNewQuestionButton btn btn-secondary'} type="select">
          <option value=''>Add a new question</option>
        </Input> */}
        <UncontrolledDropdown className={'addNewQuestionButton'}>
          <DropdownToggle>
            Add a new question
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.addQuestion(index, 'textfield')}>Textfield</DropdownItem>
            <DropdownItem onClick={() => this.addQuestion(index, 'textarea')}>Textarea</DropdownItem>
            <DropdownItem onClick={() => this.addQuestion(index, 'singleAnswer')}>Single Answer</DropdownItem>
            <DropdownItem onClick={() => this.addQuestion(index, 'multipleAnswers')}>Multiple Answers</DropdownItem>
            <DropdownItem onClick={() => this.addQuestion(index, 'starRating')}>Star Rating</DropdownItem>
            <DropdownItem onClick={() => this.addQuestion(index, 'image')}>Image</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    )
  }

  renderAnswersBox = (question: FormQuestion, questionIndex: number, nonDraft?: boolean) => {
    if (question.type !== 2 && question.type !== 3) {
      return null;
    }
    return (
      <Fragment>
        {!question.answers ? null : question.answers.map((a, index: number) => {
          if (index <= 1) {
            return this.renderAnswerInput(false, index, question);
          } else {
            return this.renderAnswerInput(nonDraft ? false : true, index, question);
          }
        })}
        {nonDraft ? null :
          <FormGroup>
            <div className={'draggableInput'}>
              <Button block onClick={(e: any) => this.addAnswer(question)}>+ Add an extra answer</Button>
            </div>
          </FormGroup>
        }
      </Fragment>
    )
  }
  renderOptionsMultipleAnswers = (question: FormQuestion, questionIndex: number) => {
    const [maxTooltipContent, maxInvalid] = getMaxTooltipConfig(question);
    const [minTooltipContent, minInvalid] = getMinTooltipConfig(question)
    return (
      <Fragment>
        <FormGroup>
          <div className={'optionsDiv'}>
            <Label>Minimum answers:</Label>
            <TippyReact content={minTooltipContent} 
              config={{disabled: !minInvalid}}
            >
              <div>
                <Input type='number' 
                  value={question.minSelected} 
                  invalid={minInvalid} 
                  onChange={(e) => this.changeQuestion(question, 'minSelected', parseInt(e.target.value))}
                />
              </div>
            </TippyReact>
            <div className={'spacer'}></div>
            <Label>Maximum answers:</Label>
            <TippyReact content={maxTooltipContent} 
              config={{disabled: !maxInvalid}}
            >
              <div>
                <Input type='number' 
                  value={question.maxSelected} 
                  invalid={maxInvalid} 
                  onChange={(e) => this.changeQuestion(question, 'maxSelected', parseInt(e.target.value))} 
                />
              </div>
            </TippyReact>
          </div>
        </FormGroup>
      </Fragment>
    )
  }
  renderOptionsStarRating = (question: FormQuestion, questionIndex: number) => {
    if (!question.scale) {
      return null;
    }
    const starIcons = [];

    for (let index = 0; index < question.scale; index++) {
      starIcons.push(
        <i key={index} className='material-icons'>star_border</i>
      )

    }
    return (
      <Fragment>
        <FormGroup>
          <div className={'optionsDiv'}>
            <div className='starPreview'>
              {starIcons}
            </div>
            <div className={'spacer'}></div>
            <Label>Number of stars:</Label>
            <Input type='number' min="2" max="10" value={question.scale ? question.scale : ''} onChange={(e) => this.changeQuestion(question, 'scale', _.toNumber(e.target.value))} />
          </div>
        </FormGroup>
      </Fragment>
    )
  }
  renderOptionsImage = (question: FormQuestion, questionIndex: number) => {
    return (
      <Fragment>
        <FormGroup>
          <div className={'imagePreview'}>
            {!question.imageUrl ? null :
              <img src={question.imageUrl} />
            }
          </div>
        </FormGroup>
      </Fragment>
    )
  }
  renderOptionsBox = (question: FormQuestion, questionIndex: number) => {
    switch (question.type) {
      case 2:
        return this.renderOptionsMultipleAnswers(question, questionIndex);
      case 5:
        return this.renderOptionsStarRating(question, questionIndex);
      case 9:
        return this.renderOptionsImage(question, questionIndex);
      default:
        return null;
    }

  }

  renderQuestionBox = (question: FormQuestion, index: number, nonDraft?: boolean) => {
    return (
      <Fragment key={`questionBox-${index}`}>
        <div className={`elementBox questionBox ${nonDraft ? 'withMargin' : ''}`} >
          <RForm>
            <FormGroup>
              <div className={'headerDiv'}>
                <i className={'material-icons'}>drag_indicator</i>
                <div className={'header'}>{`Question ${index + 1} - ${FormsHelper.questionType(question)}`}</div>
                {question.type == 9 ? null :
                  <Label><Input type='checkbox' checked={question.required ? true : false} onChange={(e) => this.changeQuestion(question, 'required', e.target.checked)} /> Required</Label>
                }
                {nonDraft ? null :
                  <i className={'material-icons'} onClick={() => this.removeQuestion(question.id)}>remove_circle_outline</i>
                }
              </div>
            </FormGroup>
            <FormGroup>
              {question.type !== 9 ? //imageQuestion
                <Input placeholder={'Question'} value={question ? question.text : ''} onChange={(e) => this.changeQuestion(question, 'text', e.target.value)}></Input>
                :
                <Input placeholder={'Image URL'} value={question ? question.imageUrl : ''} onChange={(e) => this.changeQuestion(question, 'imageUrl', e.target.value)}></Input>
              }
            </FormGroup>
            {this.renderAnswersBox(question, index, nonDraft)}
            {this.renderOptionsBox(question, index)}
          </RForm>
        </div>
        {nonDraft ? null :
          this.renderAddQuestionButton(index + 1)
        }
      </Fragment>
    )
  }

  render() {
    const { form, activeSection } = this.props;
    const tr = Translations.Translate(Translations, 'Forms');

    const section = this.getActiveSection();

    return (
      <div id="SurveySectionBuilder">
        {Translations.EnableTranslations(Translations)}
        <div className={'sectionTitle'}>{`${tr('section')} ${activeSection + 1}`}</div>
        <div className="elementBox headerBox">
          <RForm>
            <FormGroup>
              <div className={'header'}>header</div>
            </FormGroup>
            <FormGroup>
              <Input placeholder={'Header Title'} value={section ? section.title : ''} onChange={(e) => this.changeSection(section, 'title', e.target.value)}></Input>
            </FormGroup>
            <FormGroup>
              <Input type="textarea" placeholder={'Introduction'} value={section ? section.intro : ''} onChange={(e) => this.changeSection(section, 'intro', e.target.value)}></Input>
            </FormGroup>
          </RForm>
        </div>
        {form.status != 1 ? null : this.renderAddQuestionButton(0)}
        {!section ? null :
          section.questions.map((q, index) => {
            const question = _.find(form.config.questions, ['id', q]);
            if (!question) {
              return;
            }
            return this.renderQuestionBox(question, index, form.status !== 1 ? true : false);
          })
        }
      </div>
    )
  }
}

export default SurveySectionBuilder;

// ─── Helper Functions ────────────────────────────────────────────────────────

const getMaxTooltipConfig = (q: FormQuestion):[string, boolean] => {
  if(FormsHelper.isMaxLessThanMin(q)) {
    return [`Please enter a value no less than ${q.minSelected}`, true];
  }

  if(FormsHelper.isMaxMoreThanAnswers(q)) {
    return [`Maximum can't be more than the question answers`, true];
  }

  if(FormsHelper.isLessThanZero(q.maxSelected)) {
    return [`Maximum can't be less or equal to 0`, true];
  }

  return [``, false];
}

const getMinTooltipConfig = (q: FormQuestion):[string, boolean] => {

  if(FormsHelper.isMinMoreThanAnswers(q)) {
    return [`Minimum can't be more than the question answers`, true];
  }

  if(FormsHelper.isLessThanZero(q.minSelected)) {
    return [`Minimum can't be less or equal to 0`, true];
  }

  return [``, false];
}