import React from 'react';
import styles from './NcSettingsConfigurationPreviews.module.scss';
import { Control } from 'react-hook-form';
import { NcSettingsFormData } from './NcSettingsConfiguration';
import _ from 'lodash';
import { NcSlidePreview } from '../../NcSlideshows/NcSlidePreview';
import { NcSlideFormData } from '../../NcSlideshows/NcSlideshowDetails';

interface Props {
  magazine: number,
  control: Control<NcSettingsFormData, any>,
}

const PROXY_SLIDES_FOR_PREVIEWS: NcSlideFormData[] = [
  {
    type: 'image',
    renderer: '',
    sort: 0,
    config: {
      title: `image slide name`,
      showTicker: true,
      duration: 1,
    }
  },
  {
    type: 'video',
    renderer: '',
    sort: 1,
    config: {
      title: `video slide name`,
      showTicker: true,
      duration: 1,
    }
  },
  {
    type: 'weather',
    renderer: '',
    sort: 2,
    config: {
      title: `weather slide name`,
      showTicker: true,
      duration: 1,
      location: 'City name'
    }
  },
  {
    type: 'imgzine-articles',
    renderer: '',
    sort: 3,
    config: {
      title: `1 headline slide name`,
      showTicker: true,
      duration: 1,
      renderer: '1article'
    }
  },
  {
    type: 'imgzine-articles',
    renderer: '',
    sort: 4,
    config: {
      title: `2 headlines slide name`,
      showTicker: true,
      duration: 1,
      renderer: '2articles'
    }
  },
  {
    type: 'imgzine-articles',
    renderer: '',
    sort: 5,
    config: {
      title: `full article slide name`,
      showTicker: true,
      duration: 1,
      renderer: 'fullarticle'
    }
  },
]

export const NcSettingsConfigurationPreviews = ({ magazine, control }: Props) => {
  return (
    <div className={styles.NcSettingsConfigurationPreviews}>
      {_.map(PROXY_SLIDES_FOR_PREVIEWS, (s) => {
        return (
          <NcSlidePreview magazine={magazine} ncSlide={s} />
        )
      })}
    </div>
  )
}
