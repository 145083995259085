import React, { CSSProperties, Fragment } from 'react';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
// import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import BootstrapTable, { ColumnDescription, PageListRendererOptions, SelectRowProps, SortOrder } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';



import styles from './DataTable.module.scss';
import classNames from 'classnames';
import { Button, Col, Row } from 'reactstrap';
import _ from 'lodash';

export interface ExtendedColumnDescription extends ColumnDescription {
    sortValue?: (cell: any, row: any) => number | string
}

interface Props {
    data: any[],
    columns: ExtendedColumnDescription[]
    keyField: string,
    onRowClick: (row: any) => void
    onCreateNew?: () => void,
    defaultSorted?: [{
        dataField: any,
        order: SortOrder
    }],
    showSizePerPage?: boolean
    defaultSizePerPage?: number
    hideSearchBar?: boolean
    onlySearchBar?: boolean
    noPagination?: boolean
    remote?: boolean
    totalSize?: number
    currentPage?: number
    onPageChange?: (page: number) => void
    noHover?: boolean,
    createNewButtonText?: string,
    createNewButtonColor?: string,
    secondaryElements?: JSX.Element,
    secondaryRow?: JSX.Element,
    searchFilters?: JSX.Element,
    rowClasses?: (row: any, rowIndex: number) => string
    searchbarPlacholder?: string
    selectRow?: SelectRowProps<any>
    noDataText?: string
    searchFiltersStyle?: CSSProperties,
    toolbarStyle?: CSSProperties,
    noSearchFormatted?: boolean
}
export default function DataTable(props: Props) {
    const { data, columns, keyField, onRowClick, onCreateNew, defaultSorted, showSizePerPage, defaultSizePerPage, hideSearchBar, onlySearchBar, noPagination, remote, totalSize, currentPage, onPageChange, noHover, createNewButtonText, createNewButtonColor, secondaryElements, searchFilters, rowClasses, secondaryRow, searchbarPlacholder, selectRow, noDataText, searchFiltersStyle, toolbarStyle, noSearchFormatted } = props
    const pagination = noPagination ? undefined : paginationFactory({
        page: currentPage,
        totalSize: totalSize,
        sizePerPage: defaultSizePerPage || 10,
        showTotal: false,
        hideSizePerPage: !showSizePerPage,
        onPageChange: onPageChange,
        pageListRenderer: ({ pages, onPageChange }: PageListRendererOptions) => {
            const sizePerPage = defaultSizePerPage || 10;
            const min = 1;
            const max = (((remote && totalSize) ? totalSize : data.length) / sizePerPage) + 1;
            return (
                <Fragment>
                    <Col md={8}>
                        <ul className="pagination">
                            {
                                _.map(pages, (p) => {
                                    return (
                                        <li key={p.page} className={classNames({ 'page-item': true, 'active': p.active })} onClick={() => onPageChange(p.page, sizePerPage)} title={p.title}><a href="#" className="page-link">{p.page}</a></li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                    <Col md={4}>
                        <div className="goToPageDiv">
                            <input id="goToPageInput" onKeyDown={(e: any) => {
                                if (e.keyCode === 13) {
                                    let val = e.target.value;
                                    if (val < min) {
                                        val = min;
                                    } else if (val > max) {
                                        val = max;
                                    }
                                    onPageChange(val, sizePerPage);
                                    e.target.value = '';
                                }
                            }} className='form-control' min={min} max={max} type="number" placeholder={'Go to page'} style={{}} />
                            <button className="btn" onClick={(e) => {
                                var k: any = document.getElementById('goToPageInput');
                                let val = k.value;
                                if (val < min) {
                                    val = min;
                                } else if (val > max) {
                                    val = max;
                                }
                                onPageChange(val, sizePerPage);
                                k.value = ''
                            }}>{'>'}</button>
                        </div>
                    </Col>
                </Fragment>
            )
        },

        // sizePerPageList: [10, 20, 30, 50],
    });

    const { SearchBar } = Search;

    const rowEvents = {
        onClick: (e: any, row: any, rowIndex: number) => {
            onRowClick(row);
        }
    }

    const onTableChange = (type: string, newState: any) => {
        const { dataField: field, order } = newState
        return false;
    }

    return (
        <div className={classNames(styles.dataTableContainer)}>
            <ToolkitProvider {...props} search={{ searchFormatted: noSearchFormatted ? false : true }}>{providerProps => (
                <Fragment>
                    <Row className={styles.toolbar} style={{ ...toolbarStyle }}>
                        {onlySearchBar ?
                            <Col md={12}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                    {searchFilters && <div className={styles.searchFiltersContainer} style={{ flex: 'unset', ...searchFiltersStyle }}>{searchFilters}</div>}
                                    <div style={{ flex: 1 }}><SearchBar {...providerProps.searchProps} placeholder={searchbarPlacholder} /></div>
                                    {secondaryElements && <div style={{ marginBottom: '0.5rem' }}>{secondaryElements}</div>}
                                </div>
                            </Col>
                            :
                            <Fragment>
                                <Col md={6}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                        {searchFilters && <div className={styles.searchFiltersContainer} style={{ ...searchFiltersStyle }}>{searchFilters}</div>}
                                        <div style={{ flex: 1 }}>
                                            {!hideSearchBar && <SearchBar {...providerProps.searchProps} placeholder={searchbarPlacholder} />}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className={styles.secondaryElementsContainer}>{secondaryElements || null}</div>
                                        {onCreateNew && <div className={styles.createButtonContainer}><Button color={createNewButtonColor ?? 'primary'} onClick={onCreateNew}>{createNewButtonText ?? 'Create New'}</Button></div>}
                                    </div>
                                </Col>
                            </Fragment>
                        }
                    </Row>
                    {secondaryRow || null}
                    <BootstrapTable {...{ ...providerProps.baseProps, pagination, rowEvents, remote, rowClasses, selectRow }} defaultSorted={defaultSorted} wrapperClasses={`table-responsive`} bootstrap4 hover={!noHover} bordered={false} noDataIndication={noDataText || 'There is no data to display'} onTableChange={remote ? onTableChange : undefined} />
                </Fragment>
            )}</ToolkitProvider>
        </div>
    )
}
