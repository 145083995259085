import React from 'react'
import { useQuery } from '@tanstack/react-query';

import { media } from '@imgzine/imgzine-core-frontend';


import styles from './DetailMediaAddinVideo.module.scss'
import { API } from '../../apis';
import { EdErrorHandler } from '../../widgets';


function getAspectRatio(imageLink: { w?: number; h?: number }): number | undefined {
  if (imageLink.h === undefined || imageLink.w === undefined) {
    return undefined
  }
  return imageLink.w / imageLink.h
}



function DetailMediaAddinVideoInner(props: { magazine: string, mediaObjUrl: string, aspectRatio: number }) {
  const videoUrlQ = useQuery({
    queryKey: ['vimeoAddinToken', props.mediaObjUrl],
    queryFn: async () => {
      try {
        const { data } = await API.vimeoAddins.getAddinTempToken(props.magazine, props.mediaObjUrl);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting vimeo addin token`);
      }
    },
  });


  if (!videoUrlQ.data) {
    return null
  }
  const paddingTop = Math.min(Math.round(100 / props.aspectRatio), 100)
  return <div className={styles.rel} style={{ paddingTop: `${paddingTop}%` }}>
    <div className={styles.abs}>
      <iframe
        scrolling="no"
        allowFullScreen
        allow="autoplay; fullscreen"
        className={styles.iframe}
        src={videoUrlQ.data} ></iframe>
    </div>
  </div>
}


export default function DetailMediaAddinVideo(props: {
  magazine: string,
  mediaObj: media.AddinVimeo
}) {
  const mediaObjUrl = props.mediaObj.url || props.mediaObj.href
  if (!mediaObjUrl) {
    return null
  }
  const aspectRatio = getAspectRatio(props.mediaObj) || 1.3

  return <DetailMediaAddinVideoInner mediaObjUrl={mediaObjUrl} aspectRatio={aspectRatio} magazine={props.magazine} />
}