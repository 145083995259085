import React from 'react';

import _ from 'lodash';
import { Translations } from '../../../../../translations';
import { CreatableMultiSelect, SelectOption } from '../../../../../components/ReactSelect/ReactSelect';
import { Audience } from '../../Audiences/domain';




export default function ChannelAudiencesSelect({ value, onChange, allAudiences }: {
  value: number[],
  allAudiences: Audience[],
  onChange: (v: number[]) => void
}) {
  const tr = Translations.Translate(Translations, 'Channels');
  const allEmployesOption = { value: 1, label: tr('allEmployees') }

  const options: SelectOption<number>[] = [
    allEmployesOption,
    ..._.map(allAudiences, (a) => ({ value: a.id, label: a.label }))
  ];


  return <CreatableMultiSelect
    value={value.length ? value : [1]}
    onChange={(newValue) => {
      const toSet = newValue.length === 0 || (_.includes(newValue, 1) && value.length > 0)
        ? []
        : newValue.filter(v => v !== 1)
      onChange(toSet)
    }}
    options={options}
  />


}