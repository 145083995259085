import React from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash'

import { GenericModal } from '../../../../components/GenericModal/GenericModal';
import { ImmutArray } from '../../../../utils/ImmutablesHelper';
import { EdErrorHandler, LoadingButton, SwalSuccess } from '../../../../widgets';
import CicSelectorTable, { DisabledChannelsGroup, selectedChannelsLabel } from './cicTables/CicSelectorTable';
import { ChannelsGraph, findAncestors, findDescendants, findParents, formatChannelTitle, } from './cicChannelsHelper';
import { CICChannel, } from './domain';
import { API } from '../../../../apis';
import { Translations } from '../../../../translations';
import GenericModalHeader from '../../../../components/GenericModal/GenericModalHeader';
import { Profile } from '../../../../../domain';

interface Props {
  magazine: number
  profile: Profile
  channelsGraph: ChannelsGraph
  channels: CICChannel[]
  channelId: number
  onClose: (saved?: boolean) => void
}

export default function EditChannelParentsModal(props: Props) {
  const { magazine, channels, channelsGraph, channelId, profile } = props
  const channel = channelsGraph.nodes[channelId]?.channel
  const initialParents = findParents(channelsGraph, channelId)
  const [selectedChannels, setSelectedChannels] = React.useState(initialParents)
  const ancestors = React.useMemo(() =>
    findAncestors(channelsGraph, channelId),
    [channelId, channelsGraph])

  const tr = Translations.Translate(Translations, 'Channels');

  const disabledChannels: DisabledChannelsGroup[] = React.useMemo(
    () => [
      { message: tr('parentValidationSelf'), channels: [channelId] },
      { message: tr('parentValidationDescendant'), channels: findDescendants(channelsGraph, channelId) },
    ],
    [channelId, channelsGraph]
  )
  const [saveLoading, setSaveLoading] = React.useState(false);


  if (!channel) {
    return null
  }
  async function onSave() {
    setSaveLoading(true)
    try {
      const toAdd = _.difference(selectedChannels, initialParents)
      const toRemove = _.difference(initialParents, selectedChannels)
      await API.channels.saveChannelParents(magazine, channelId, toAdd, toRemove)
      SwalSuccess.fire({
        title: 'Success!',
        text: `Channel parents saved`
      });
      props.onClose(true)
    } catch (e) {
      EdErrorHandler(e, `saving channel parents`);
      setSaveLoading(false)
    }

  }
  function onClose() {
    props.onClose(false)
  }
  return <GenericModal isOpen fullHeight toggle={onClose} centered={false} size={'xl'}>
    <GenericModalHeader
      onClose={onClose}
      title={`Select parents for channel: ${formatChannelTitle(profile, channel)}`}
    />
    <ModalBody>
      <CicSelectorTable
        magazine={magazine}
        profile={profile}
        channels={channels}
        selected={selectedChannels}
        disabledChannels={disabledChannels}
        initiallyExpanded={ancestors}
        onSelect={(channelId, s) => {
          setSelectedChannels(s
            ? ImmutArray.push(selectedChannels, channelId)
            : ImmutArray.removeBy(selectedChannels, c => c === channelId)
          )
        }}
        showTopLevel
      />
    </ModalBody>
    <ModalFooter>
      <div style={{ flex: 1 }}>{selectedChannelsLabel(selectedChannels)}</div>
      <LoadingButton loading={saveLoading} onClick={onSave} text={'Save'} disabled={selectedChannels.length === 0} />
      {' '}
      <Button color="outline-secondary" onClick={() => { onClose() }}>Cancel</Button>
    </ModalFooter>
  </GenericModal>
}
