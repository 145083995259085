import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import './css/Sources.min.css'

import { MagazineLanguages, Profile } from '../../../../../domain';
import moment from 'moment';
import SourcesOverviewTable from './SourcesOverviewTable';
import { SourceDetails } from './SourceDetails';
import SelectTypeModal from './SelectTypeModal';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as ph from '../../../../utils/routerHelper';
import { DefaultMagazineLanguages } from '../../../../utils/translationsHelper';
import * as Api from '../../../../api';
import { EdErrorHandler } from '../../../../widgets';
import _ from 'lodash';

export interface SelectedArticle {
  id: number,
  source: number,
  title: string
}

export interface Props extends RouteComponentProps {
  magazine: string,
  profile: Profile,
}
export interface State {
  createSource: boolean,
  newSourceType?: string,
  historyLastUpdate: number,
  languages: MagazineLanguages,
}

export class Sources extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      createSource: false,
      historyLastUpdate: moment().valueOf(),
      languages: DefaultMagazineLanguages
    };
  }
  componentDidMount() {
    this.getLanguages();
  }
  getLanguages = async () => {
    try {
      const { data: languages } = await Api.getLanguages(_.toNumber(this.props.magazine));
      this.setState({ languages })
    } catch (err) {
      EdErrorHandler(err, `loading magazine languages`);
    }
  }

  clickSourceHandler(source: number, srctype?: string) {
    ph.setQueryParams(this.props.history, { source });
    if (srctype) {
      this.setState({ newSourceType: srctype, createSource: false });
    }
  }

  refreshHistoryHandler() {
    this.setState({ historyLastUpdate: moment().valueOf() });
  }


  closeSourceDetailsHandler(updateOverview?: boolean) {
    ph.setQueryParams(this.props.history, { source: undefined });
    if (updateOverview) {
      this.refreshHistoryHandler();
    }
  }

  selectTypeModalCloseHandler = () => {
    this.setState({ createSource: false });
  }

  createNewSourceHandler = () => {
    this.setState({ createSource: true });
  }

  render() {
    const { magazine, profile } = this.props;
    const shownSource = ph.getQueryParamNumber(this.props.location, 'source');
    const { historyLastUpdate, createSource, newSourceType, languages } = this.state;
    return (
      <Fragment>
        {!shownSource ? null :
          <SourceDetails magazine={parseInt(magazine)} profile={profile} sourceId={shownSource} newSourceType={newSourceType} onClose={this.closeSourceDetailsHandler.bind(this)} languages={languages} />
        }
        <div className={shownSource ? 'limitedHeight' : ''}>
          <SourcesOverviewTable key={`sourcesOverview-${historyLastUpdate}`} magazine={magazine} profile={profile} clickSourceHandler={this.clickSourceHandler.bind(this)} createNewSourceHandler={this.createNewSourceHandler.bind(this)} />
        </div>
        {!createSource ? null :
          <SelectTypeModal magazine={magazine} createHandler={this.clickSourceHandler.bind(this)} closeHandler={this.selectTypeModalCloseHandler.bind(this)} />
        }

      </Fragment>

    );
  }
}
export const SourcesWithRouter = withRouter(Sources);
export default Sources;
