import React, { useState } from 'react';
import { NcSlideshowFormData } from './NcSlideshowDetails';
import { Control, useController } from 'react-hook-form';
import { FullPageModalSettingsSection, FullPageModalSettingsSectionRow } from '../../../../components/FullPageModal';

import styles from './NcSlideshowPlan.module.scss';
import { Button, Label } from 'reactstrap';
import { SwalDelete } from '../../../../widgets';
import { NcSchedule } from '../../NarrowcastingDomain';
import { NcSlideshowPlanDetails } from './NcSlideshowPlanDetails';
import { ncScheduleDatetimeFormatter } from './NcSlideshows';

interface Props {
  control: Control<NcSlideshowFormData>,
}

export const NcSlideshowPlan = ({ control }: Props) => {

  const { field: schedule, fieldState } = useController({ control, name: 'schedule' });

  const [editingPlan, setEditingPlan] = useState<boolean>(false);

  return (
    <div className={styles.NcSlideshowPlan}>
      <FullPageModalSettingsSection
        title={'slideshow plan'}
        titleButton={<NcSlideshowPlanToolbar schedule={schedule.value} onChange={schedule.onChange} onEdit={() => { setEditingPlan(true) }} />}
      >
        <FullPageModalSettingsSectionRow>
          {schedule.value?.start || schedule?.value?.end ?
            <div className={styles.planInfoWrapper}>
              <div>
                <Label>Start</Label>
                {ncScheduleDatetimeFormatter(schedule.value.start)}
              </div>
              <div>
                <Label>End</Label>
                {ncScheduleDatetimeFormatter(schedule.value.end)}
              </div>
            </div>
            :
            <div>
              No planned schedule. This slideshow will be displayed until removed.
            </div>
          }
        </FullPageModalSettingsSectionRow>
      </FullPageModalSettingsSection>
      {editingPlan &&
        <NcSlideshowPlanDetails
          slideshowControl={control}
          onClose={() => setEditingPlan(false)}
        />
      }
    </div>
  )
}

// ─── Toolbar ─────────────────────────────────────────────────────────────────

interface NcSlideshowPlanToolbarProps {
  schedule: NcSchedule | undefined,
  onChange: (s: NcSchedule | undefined) => void
  onEdit: () => void
}

const NcSlideshowPlanToolbar = ({ schedule, onChange, onEdit }: NcSlideshowPlanToolbarProps) => {

  const onClickRemove = async () => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: `Yes, clear it!`,
      focusCancel: true,
      html: `This action will clear the plan, so the slideshow will be displayed until removed.`
    });
    if (confirm) {
      onChange({});
    }
  }

  return (
    <div className={styles.NcSlideshowPlanToolbar}>
      {schedule?.start || schedule?.end ?
        <>
          <Button onClick={onEdit}>Edit</Button>
          <Button onClick={onClickRemove}>Clear</Button>
        </>
        :
        <Button onClick={onEdit}>Add</Button>
      }

    </div>
  )
}

