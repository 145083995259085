import _ from 'lodash';
import React, { useState } from 'react';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Col, Progress } from 'reactstrap'
import { SwalError, LoadingButton } from '../../../../widgets';
import { ImportProgress } from './domain';
import styles from './PeopleImportModal.module.scss';
interface Props {
    loading: boolean,
    onClose: () => void,
    onImport: (file: File) => void,
    importProgress?: ImportProgress,
}
export default function PeopleImportModal({ loading, onClose, onImport, importProgress }: Props) {

    const [file, setFile] = useState<File | undefined>(undefined);

    const checkAndImport = () => {
        if (!file) {
            SwalError.fire({
                title: `Error!`,
                text: `You have to select a file first`
            });
            return;
        }
        onImport(file);
    }

    return (
        <Modal isOpen={true} id={'ImportModal'} toggle={onClose} centered={true} size={'lg'} keyboard={!loading}>
            <ModalHeader toggle={onClose}>Import CSV file with Profiles</ModalHeader>
            <ModalBody className={''}>
                <Form>
                    <FormGroup row>
                        <Col sm={2}><Label>CSV file</Label></Col>
                        <Col sm={10}>
                            <Input type="file" onChange={(e) => { e.target.files ? setFile(e.target.files[0]) : null }} />
                        </Col>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                {importProgress ?
                    <div style={{ flexGrow: 1 }}>
                        <Progress className={styles.progressBar} max={importProgress.total} value={importProgress.imported}>{importProgress.imported} of {importProgress.total} profiles</Progress>
                    </div>
                    :
                    <>
                        <div style={{ flexGrow: 1 }}></div>
                        <Button outline color="secondary" onClick={onClose}>Cancel</Button>
                    </>
                }
                <LoadingButton text={'Import'} loading={loading} onClick={checkAndImport} />
            </ModalFooter>
        </Modal>
    )
}
