import React from 'react'
import Tippy, { TippyProps } from '@tippyjs/react';
import { MultipleTargets, roundArrow, delegate, Props as TippyConfig } from 'tippy.js';

import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css';

/**
 * non-react tippy (legacy)
 */
export function initTippy(targets: MultipleTargets, tippyConfig?: Partial<TippyConfig>) {
  delegate(targets, {
    arrow: roundArrow,
    target: '[data-tippy-content]',
    theme: 'light-border',
    ...tippyConfig
  })
}

interface Props {
  content: React.ReactNode;
  children?: React.ReactElement<any>;
  config?: Partial<TippyProps>
}

export const TippyReact = (props: Props) => <Tippy
  content={props.content}
  arrow={roundArrow}
  theme='light-border'
  {...props.config}
>
  {props.children}
</Tippy>

export default TippyReact