import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import classnames from 'classnames';
import '../../css/TabPageNavigation.min.css';

import { PushNotifications, Forms } from './subpages';
import { Profile } from '../../../domain';
import { Translations } from '../../translations';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import * as ph from '../../utils/routerHelper';
import { ConfigHelper } from '../../utils/ConfigHelper';
import { EdScreenKey } from '../../accessRights/domain';
import { ED_PAGES } from '../../accessRights/accessRights';
import { AccessRightsHelper } from '../../utils';
import { Newsletters } from './subpages/Newsletters/Newsletters';
import _ from 'lodash';

export interface Props extends RouteComponentProps {
  magazine: string,
  profile: Profile

}

export class Engage extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  toggleTab(tab: string) {
    ph.setQueryParams(this.props.history, { tab });
  }

  shouldUserAccessPage = (page: EdScreenKey) => {
    const { profile } = this.props;
    return AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, page, 'engage');
  }

  render() {
    const { magazine, profile } = this.props;
    const activeTab = ph.getQueryParam(this.props.location, 'tab');
    if (!activeTab) {
      if (this.shouldUserAccessPage('pushNotifications')) {
        return <Redirect to='engage?tab=pushNotifications' />
      }
      return <Redirect to={`engage?tab=${AccessRightsHelper.findFirstPageKeyWithAccess(profile, _.filter(ED_PAGES, (p) => p.parentPage == 'engage'))}`} />
    }
    // const { activeTab } = this.state;
    const tr = Translations.Translate(Translations, 'Modules');

    return (
      <div id="Engage" className={'TabPageNavigation'}>
        {Translations.EnableTranslations(Translations)}
        <div className="PageTitle">{tr('engage')}</div>
        <Nav tabs>
          {this.shouldUserAccessPage('pushNotifications') && <NavItem><NavLink className={classnames({ active: activeTab === 'pushNotifications' })} onClick={() => { this.toggleTab('pushNotifications') }}>{tr('pushNotifications')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('forms') && !ConfigHelper.isModuleDisabled(profile, 'forms') && <NavItem><NavLink className={classnames({ active: activeTab === 'forms' })} onClick={() => { this.toggleTab('forms') }}>{tr('forms')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('newsletters') && profile.edConfig?.newsletters && <NavItem><NavLink className={classnames({ active: activeTab === 'newsletters' })} onClick={() => { this.toggleTab('newsletters') }}>{tr('newsletters')}</NavLink></NavItem>}
          {/* <NavItem><NavLink className={classnames({active: activeTab === '4'})} onClick={()=>{this.toggleTab('4')}} disabled>{tr('alerts')}</NavLink></NavItem> */}
        </Nav>
        <TabContent activeTab={activeTab}>
          {this.shouldUserAccessPage('pushNotifications') && <TabPane tabId="pushNotifications">{activeTab === 'pushNotifications' && <PushNotifications magazine={magazine} profile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('forms') && !ConfigHelper.isModuleDisabled(profile, 'forms') && <TabPane tabId="forms">{activeTab === 'forms' && <Forms magazine={magazine} profile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('newsletters') && profile.edConfig?.newsletters && <TabPane tabId="newsletters">{activeTab === 'newsletters' && <Newsletters magazine={_.toInteger(magazine)} profile={profile} />}</TabPane>}
          {/* <TabPane tabId="4">alerts</TabPane> */}
        </TabContent>
      </div>
    );
  }
}
export const EngageWithRouter = withRouter(Engage);
export default Engage;
