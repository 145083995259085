import _ from "lodash";
import { Profile } from "../../domain";
import { EdPage, EdPageKey, EdRole, EdScreenKey } from "../accessRights/domain";

export const getUserRole = (profile: Profile): EdRole => {
  if (profile.globalAudience) {
    return 'admin';
  }
  return profile.role || 'none';
}

const findEdPage = (edPages: EdPage[], pageKey: EdPageKey | EdScreenKey, parentPage?: EdPageKey): EdPage | undefined => {
  const edPage = _.find(edPages, (p) => {
    return (p.key === pageKey && p.parentPage === parentPage);
  });
  return edPage;
}

const shouldRoleAccessPage = (role: EdRole, edPages: EdPage[], pageKey: EdPageKey | EdScreenKey, parentPage?: EdPageKey): boolean => {
  if (role == 'admin') {
    return true;
  }
  const edPage = findEdPage(edPages, pageKey, parentPage);
  if (!edPage) {
    return true;
  }
  if (edPage.accessibleOnlyToRoles) {
    return _.includes(edPage.accessibleOnlyToRoles, role);
  }
  if (edPage.notAccessibleToRoles) {
    return !_.includes(edPage.notAccessibleToRoles, role);
  }
  return true;
}

export const shouldUserAccessPage = (profile: Profile, edPages: EdPage[], pageKey: EdPageKey | EdScreenKey, parentPage?: EdPageKey): boolean => {
  const userRole = getUserRole(profile);

  return (parentPage ? shouldRoleAccessPage(userRole, edPages, parentPage, undefined) : true) && shouldRoleAccessPage(userRole, edPages, pageKey, parentPage);
}

export const findFirstPageKeyWithAccess = (profile: Profile, edPages: EdPage[]): EdPageKey | EdScreenKey => {
  const edPage = _.find(edPages, (p) => {
    return shouldUserAccessPage(profile, edPages, p.key, p.parentPage);
  });
  return edPage?.key || 'overview';
}