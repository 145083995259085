import { dashApi } from "../api";
import _ from "lodash";

export const getMagazineChatGroups = (magazine: number): Promise<any> => {
  return dashApi.get(magazine, `automatedChatGroups`);
}

export const getGroupMembers = (magazine: number, id: number): Promise<any> => {
  return dashApi.get(magazine, `automatedChatGroups/${id}/members`);
}
