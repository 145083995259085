import React from 'react';
import Collapse from 'reactstrap/lib/Collapse';
import cn from 'classnames'


import styles from './DetailContentAccordion.module.scss'
import { AccordionPanel } from './DetailContentRenderer';


export interface Props {
  panels: AccordionPanel[]
}


export default function DetailContentAccordion(props: Props) {
  const [index, setIndex] = React.useState(-1)

  const { panels } = props
  return <div>
    {
      panels.map((p, pIndex) => {
        const isOpen = index === pIndex
        return <div key={pIndex} className={styles.panel}>

          <div
            className={styles.titleRow}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIndex(index === pIndex ? -1 : pIndex)
            }}
          >
            <div className={styles.arrowIcon} >
              <div className={cn("fa", index === pIndex ? "fa-chevron-up" : " fa-chevron-down")} />
            </div>
            <div className={styles.title}>{p.title}</div>
          </div>

          <Collapse isOpen={isOpen}>
            <div className={styles.body}>{p.body}</div>
          </Collapse>
        </div>
      })
    }
  </div>
}

