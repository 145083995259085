import * as Sw from 'sweetalert2';

export const Swal = Sw.default;

export const SwalError = Sw.default.mixin({
  type: 'error',
  animation: false,
  customClass: {
    popup: 'animated heartBeat'
  }
});

export const SwalSuccess = Sw.default.mixin({
  type: 'success',
  customClass: {
    popup: 'noBounce'
  }
});

export const SwalDelete = Sw.default.mixin({
  type: 'warning',
  animation: false,
  customClass: {
    popup: 'animated pulse',
    confirmButton: 'bg-danger',
  },
});