import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';

import './css/SelectTypeModal.min.css';
import { Translations } from '../../../../translations';


export interface Props {
  magazine: string,
  // profile: Profile
  // statsFilter: StatsFilter,
  // articleId: number,
  createHandler: (source: number, srctype?: string) => void
  closeHandler: () => void
}

export interface State {
  type: string
}

export class SelectTypeModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: 'RSS'
    }
  }

  createHandler = () => {
    this.props.createHandler(-1, this.state.type);
  }
  changeType = (e: any) => {
    this.setState({ type: e.target.value })
  }

  render() {
    const { magazine } = this.props;
    const { type } = this.state;
    const tr = Translations.Translate(Translations, 'Sources');

    // const loader = <div className="loaderContainer"><div className="loaderWrapper" ><i className="fa fa-spinner fa-spin"></i></div></div>
    const closeBtn = <img className="close" onClick={this.props.closeHandler.bind(this)} src="/assets/icons/bt-close.svg" />
    return (
      <Modal isOpen={true} centered id="SelectTypeModal">
        {Translations.EnableTranslations(Translations)}
        <ModalHeader><span className="headerTitle"><img src="/assets/icons/24/publications_blue.svg" style={{ marginRight: '10px' }}></img> {tr('selectSrcType')}</span> {closeBtn}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={2}><Label style={{ lineHeight: '38px' }}>Type:</Label></Col>
            <Col md={10}>
              <Input type="select" value={type} onChange={this.changeType}>
                <option value="RSS">RSS Feed</option>
                <option value="tags">Tags</option>
                <option value="feed">Content Feed</option>
                <option value="reporter">Reporter</option>
                <option value="composed">Composed</option>
              </Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" block size="lg" onClick={this.createHandler.bind(this)} style={{ textTransform: 'uppercase' }}>{tr('create')}</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default SelectTypeModal;
