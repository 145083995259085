import * as oldApi from '../api';
import * as cms from './cms'
import * as analytics from './analytics'
import * as forms from './forms'
import * as people from './people'
import * as audiences from './audiences'
import * as ics from './ics'
import * as chatGroups from './chatGroups'
import * as search from './search'
import * as articles from './articles'
import * as exportData from './exportData'
import * as targeting from './targeting'
import * as widgets from './widgets'
import * as vimeo from './vimeo'
import * as vimeoAddins from './vimeoAddins'
import * as sources from './sources'
import * as profiles from './profiles'
import * as plugins from './plugins'
import * as channels from './channels'
import * as notificationCenter from './notificationCenter'
import * as newsletters from './newsletters'
import * as comments from './comments'
import * as narrowcasting from './narrowcasting'
import * as feeds from './feeds'
import * as applicationMetaInfo from './applicationMetaInfo'
import * as reportedContent from './reportedContent'
import * as sharepoint from './sharepoint'

export const API = {
  oldApi,
  cms,
  analytics,
  forms,
  people,
  audiences,
  ics,
  chatGroups,
  search,
  articles,
  exportData,
  targeting,
  widgets,
  vimeo,
  vimeoAddins,
  sources,
  profiles,
  plugins,
  channels,
  notificationCenter,
  newsletters,
  comments,
  narrowcasting,
  feeds,
  applicationMetaInfo,
  reportedContent,
  sharepoint,
}