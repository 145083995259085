import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import Toggle from 'react-toggle';
import { NcSettingsFormData } from '../subpages/NcSettings/NcSettingsConfiguration/NcSettingsConfiguration';

interface Props {
  control: Control<NcSettingsFormData, any>,
  fieldName: FieldPath<NcSettingsFormData>
  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSettingsToggle = ({ control, fieldName }: Props) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
        return (
          <Toggle checked={_.isBoolean(field.value) ? field.value : false} onChange={(e) => field.onChange(e.target.checked)} />
        )
      }}
    />
  )
}
