import React, { useEffect } from 'react';
import { FullPageModal, FullPageModalHeader, FullPageModalContent, FullPageModalContentPart, FullPageModalSettings, FullPageModalSettingsSection, FullPageModalSettingsSectionRow } from '../../../../../components/FullPageModal';
import { TippyReact } from '../../../../../components';
import { EdErrorHandler, LoadingButton, OrtecLoader, Swal, SwalSuccess } from '../../../../../widgets';
import { Controller, ControllerRenderProps, useController, useForm } from 'react-hook-form';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../../../apis';
import { Profile } from '../../../../../../domain';
import { NcAlertSlideConfig } from '../../../NarrowcastingDomain';
import { NC_DEFAULT_SETTINGS_ALERT_SLIDE } from '../NarrocastingSettingsDefaults';
import { FormGroup, Input, Label } from 'reactstrap';
import styles from './NcSettingsAlert.module.scss';
import Toggle from 'react-toggle';
import _ from 'lodash';
interface Props {
  magazine: number,
  profile: Profile,
  ncAlertSlideQuery: UseQueryResult<NcAlertSlideConfig | undefined>
  onClose: () => void,
}

export const NcSettingsAlert = ({ magazine, profile, ncAlertSlideQuery, onClose }: Props) => {

  const queryClient = useQueryClient();


  const ncGroupsQuery = useQuery({
    queryKey: ['ncGroups', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcGroups(magazine);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting NC groups`);
      }
    },
  });

  const { control, handleSubmit, reset, formState: { errors } } = useForm<NcAlertSlideConfig>({
    defaultValues: ncAlertSlideQuery.data || { ...NC_DEFAULT_SETTINGS_ALERT_SLIDE }
  });

  const ncAlertSlideSaveMutation = useMutation({
    mutationKey: ['ncSlideshowUpdate'],
    mutationFn: (c: NcAlertSlideConfig) => API.narrowcasting.saveNcSettingsAlertSlide(magazine, c),
    onError: (error, s) => {
      EdErrorHandler(error, `saving alert slide config`);
    },
    onSuccess: (data, s) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `Alert slide config has been saved successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['ncAlertSlide', magazine] })
      onClose();
    }
  })

  const onSave = handleSubmit(async (data) => {
    ncAlertSlideSaveMutation.mutate(data);
  })

  const { field: targetAll_field } = useController({ control, name: 'targetAll' });
  const { field: message_field } = useController({ control, name: 'message' });
  const { field: theme_field } = useController({ control, name: 'theme' });

  const isLoading = ncAlertSlideQuery.isFetching;

  return (
    <FullPageModal>
      <FullPageModalHeader isLoading={isLoading} onClose={onClose}>
        <Controller
          name={'title'}
          control={control}
          render={({ field }) => {
            return (
              <Input {...field} maxLength={80} className={styles.nameInput} placeholder={`Slide title`} onChange={(e) => { field.onChange(e.target.value) }}></Input>
            )
          }}
        />
        <Controller
          name={'isEnabled'}
          control={control}
          render={({ field }) => {
            return (
              <Label className={styles.inlineToggle}>
                <Toggle checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                <span>Enable alert slide</span>
              </Label>
            )
          }}
        />
        <div>
        </div>
        <TippyReact config={{ disabled: true }} content={''}>
          <div><LoadingButton disabled={isLoading} loading={ncAlertSlideSaveMutation.isLoading} onClick={() => { onSave() }} text={'save'} /></div>
        </TippyReact>
      </FullPageModalHeader>
      <FullPageModalContent isLoading={isLoading}>
        <FullPageModalContentPart flex={2} mainPart>
          <div className={styles.alertSlideConfig}>
            <label>Message:</label>
            <Input
              maxLength={200}
              value={message_field.value}
              placeholder='This message will appear on the alert slide'
              onChange={(e) => message_field.onChange(e.target.value)}
            />
            <label style={{ marginTop: '16px' }}>Theme:</label>
            <div className={styles.themeRadioWrapper}>
              <NcAlertSlideThemeRadioOption
                optionValue='emergency'
                field={theme_field}
              />
              <NcAlertSlideThemeRadioOption
                optionValue='mourn'
                field={theme_field}
              />
              <NcAlertSlideThemeRadioOption
                optionValue='notification'
                field={theme_field}
              />
              <NcAlertSlideThemeRadioOption
                optionValue='event'
                field={theme_field}
              />
            </div>
          </div>
          <div className={styles.previewSection}>
            <div className={styles.sectionTitle}>preview</div>
            <div className={styles.previewContainerWrapper}>
              <div className={styles.previewContainer}>
                <div className={styles.themeImageContainer} data-type={`${theme_field.value}`}></div>
                <div className={styles.messageContainer}>
                  <div className={styles.messagePlaceholder}>
                    {message_field.value || <i>Your message will appear here</i>}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </FullPageModalContentPart>
        <FullPageModalContentPart withBackground>
          <FullPageModalSettings>
            <FullPageModalSettingsSection title={'target'}>
              <FullPageModalSettingsSectionRow>
                <Label className={styles.inlineToggle}>
                  <Toggle checked={targetAll_field.value} onChange={(e) => targetAll_field.onChange(e.target.checked)} />
                  <span>All devices & groups</span>
                </Label>
              </FullPageModalSettingsSectionRow>
              <FullPageModalSettingsSectionRow>
                <div className={styles.label}>Device groups</div>
                <div className={styles.targetingBox}>
                  {ncGroupsQuery.isFetching ? <OrtecLoader size={'small'} /> :
                    _.isEmpty(ncGroupsQuery.data) ? <div className={''}>There are no groups</div> :
                      <Controller
                        name={'targetGroups'}
                        control={control}
                        render={({ field }) => {

                          return (
                            <>
                              {_.map(ncGroupsQuery.data || [], (g) => {
                                return (
                                  <div key={g.id} className={styles.targetingBoxRow}>
                                    <input id={`ncGroup-${g.id}`} type="checkbox" checked={targetAll_field.value || _.includes(field.value, g.id)} disabled={targetAll_field.value} onChange={(e) => {
                                      if (e.target.checked) {
                                        field.onChange(_.isEmpty(field.value) ? [g.id] : [...field.value, g.id])
                                      } else {
                                        field.onChange(_.filter(field.value, (v) => g.id !== v))
                                      }
                                    }} />
                                    <label htmlFor={`ncGroup-${g.id}`}> {g.label}</label>
                                  </div>
                                )
                              })}
                            </>
                          )
                        }}
                      />
                  }
                </div>
              </FullPageModalSettingsSectionRow>
            </FullPageModalSettingsSection>
          </FullPageModalSettings>
        </FullPageModalContentPart>
      </FullPageModalContent>
    </FullPageModal>
  )
}

interface RadioOptionProps {
  optionValue: string
  field: ControllerRenderProps<NcAlertSlideConfig, 'theme'>
}

const NcAlertSlideThemeRadioOption = ({ optionValue, field }: RadioOptionProps) => {

  return (
    <FormGroup
      inline
      check
      radioGroup='ncAlertSlideTheme'
    >
      <Input type={'radio'}
        id={optionValue}
        value={optionValue}
        checked={field.value === optionValue}
        onChange={(e) => field.onChange(e.target.value)}
      />
      <Label for={optionValue} check>{_.capitalize(optionValue)}</Label>
    </FormGroup>
  )
}