import React from 'react';
import { Label } from 'reactstrap';
import styles from './CmsPersonalizedFeedSection.module.scss';
import AsyncSelect from 'react-select/async';
import { API } from '../../../../../../apis';
import _ from 'lodash';
import classNames from 'classnames';
import { EdErrorHandler } from '../../../../../../widgets';

interface Props {
  magazine: number
  isDisabled?: boolean
  targetUid?: string
  onChange: (newTargetUid?: string) => void
}

export const CmsPersonalizedFeedSection = ({ magazine, isDisabled, targetUid, onChange }: Props) => {

  const loadOptions = async (inputValue: string) => {
    return await searchProfiles(inputValue);
  }

  const searchProfiles = async (searchVal: string) => {
    try {
      const { data } = await API.people.getProfiles(magazine, {
        offset: 0,
        limit: 100,
        sort: '-modified',
        q: searchVal,
        q_f: 'uid',
        q_o: 'c',
        q_t: 'text'
      });
      return _.map(data.profiles, (p) => {
        return {
          value: p.uid,
          label: p.uid //`${p.firstName} ${p.lastName}`
        }
      })
    } catch (error) {
      EdErrorHandler(error, `searching for profiles`);
      return [];
    }
  }

  return (
    <div className={styles.CmsPersonalizedFeedSection}>
      <div className={''}>
        <Label>Target User</Label>
      </div>
      <AsyncSelect
        className={classNames('targetInput', { disabled: isDisabled, invalid: !targetUid })}
        isDisabled={isDisabled}
        isClearable
        // isMulti
        placeholder={'Find user..'}
        value={targetUid ? { label: targetUid, value: targetUid } : undefined}
        loadOptions={loadOptions}
        onChange={(op) => {
          onChange(op?.value || undefined);
        }}
      />
    </div>
  )
}
