import { AxiosPromise } from 'axios';
import { dashApi } from "../api";
import { ChannelConfigField, NotificationCenterChannelWithConfig, NotificationCenterConfigOptions } from '../pages/Advanced/subpages/NotificationCenter/domain';

export const getNotificationCenterChannelsWithConfig = (magazine: number): AxiosPromise<Array<NotificationCenterChannelWithConfig>> => {
  return dashApi.post(magazine, `getNotificationCenterChannelsWithConfig`)
}

export const getNotificationCenterAdvancedOptions = (magazine: number): AxiosPromise<NotificationCenterConfigOptions> => {
  return dashApi.get(magazine, `notificationCenterAdvancedOptions`)
}

export const saveNotificationCenterAdvancedOptions = (magazine: number, options: NotificationCenterConfigOptions): AxiosPromise<void> => {
  return dashApi.post(magazine, `notificationCenterAdvancedOptions`, { options })
}

export const toggleNotificationCenterChannelConfigField = (magazine: number, channel: number, field: ChannelConfigField, value: boolean): AxiosPromise<void> => {
  return dashApi.post(magazine, `toggleNotificationCenterChannelConfigField`, { channel, field, value });
}

export const toggleNotificationCenterAllChannels = (magazine: number, value: boolean): AxiosPromise<void> => {
  return dashApi.post(magazine, `toggleNotificationCenterAllChannels`, { value });
}