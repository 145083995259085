import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { MediaHelper } from '../../utils';
import styles from './ExtensionIcon.module.scss';

interface Props {
  magazine: string | number
  extension: string
  link: string
  size: 24 | 40
}

export const ExtensionIcon = ({ magazine, extension, link, size }: Props) => {
  const className = classNames(styles.ExtensionIcon, {
    [styles.size24]: size === 24,
    [styles.size40]: size === 40,
  })
  if (MediaHelper.isPdfExtension(extension)) {
    return <img className={className} src={'/assets/icons/attachment_pdf.svg'} />
  }
  if (MediaHelper.isNonWebSupportedImage(extension)) {
    return <img className={className} src={'/assets/icons/image.svg'} />
  }
  if (MediaHelper.isImageExtension(extension)) {
    const src = MediaHelper.proxify(magazine, link, size)
    return <img className={className} src={src} loading="lazy"/>
  }
  if (MediaHelper.isAudioExtension(extension)) {
    return <i className={classNames(className, 'material-icons')}>music_note</i>;
  }
  if (MediaHelper.isVideoExtension(extension)) {
    return <i className={classNames(className, 'material-icons')}>play_circle_outline</i>;
  }

  return <img className={className} src={'/assets/icons/attachment.svg'} />;
}