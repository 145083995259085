import _ from "lodash";
import fp from "lodash/fp";
import { NcDevice, NcGroup, NcSlideConfig } from "./NarrowcastingDomain";
import { NcSlideConfigFormData, NcSlideFormData, NcSlideshowFormData } from "./subpages/NcSlideshows/NcSlideshowDetails";
import ISO6391 from 'iso-639-1';
import { ControllerRenderProps, FieldPath } from "react-hook-form";
import { SelectOption } from "../../components/ReactSelect/ReactSelect";

export * as NarrowcastingHelper from './NarrowcastingHelper'

export const findGroupName = (groupId: number, groups: NcGroup[]): string | undefined => {
  const group = _.find(groups, (g) => g.id === groupId);

  return group?.label;
}

export const findDeviceName = (deviceId: number, devices: NcDevice[]): string | undefined => {
  const device = _.find(devices, (d) => d.id === deviceId);

  return device?.label;
}

export const adjustSlideRenderers = (slides: NcSlideFormData[]): NcSlideFormData[] => {
  return _.map(slides, (s) => {
    if (s.type !== 'imgzine-articles') {
      return s;
    }
    return {
      ...s,
      renderer: s.config?.renderer || '',
      config: {
        ...s.config,
        data: !s.config.data ? undefined : {
          ...s.config.data,
          count: adjustArticleCountBasedOnRenderer(s.config?.renderer)
        }
      }
    }
  })
}

const adjustArticleCountBasedOnRenderer = (renderer?: 'fullarticle' | '1article' | '2articles' | 'timed'): number => {
  return renderer === '2articles' ? 2 : 1
}


export const slideTypeToTitle = (type: string): string => {
  switch (type) {
    case 'imgzine-articles':
      return 'article';
    default:
      return type;
  }
}

export const getValueOfSlideConfigField = (slidesField: ControllerRenderProps<NcSlideshowFormData, 'slides'>, index: number, fieldPath: FieldPath<NcSlideConfigFormData>) => {
  return _.get(slidesField.value[index].config, fieldPath);
}

export const setValueOfSlideConfigField = (slidesField: ControllerRenderProps<NcSlideshowFormData, 'slides'>, index: number, fieldPath: FieldPath<NcSlideConfigFormData>, val: any) => {
  return slidesField.onChange(_.map(slidesField.value, (s) => {
    if (s.sort !== index) {
      return s;
    }

    // const newConfig = _.cloneDeep(s.config);
    // _.set(newConfig, fieldPath, val);

    return {
      ...s,
      config: fp.set(fieldPath, val, s.config)
    }
  }))
}

export const createLocaleOptions = (): SelectOption[] => {
  const localeOptions = _.map(ISO6391.getAllCodes(), (langCode) => {
    return {value: langCode, label: ISO6391.getName(langCode)}
  })

  const sortedLocaleOptions = _.sortBy(localeOptions, o => o.label)

  return sortedLocaleOptions
}


// ─── Defaults ────────────────────────────────────────────────────────────────

export const slideTypeDefaultConfig = (type: string): NcSlideConfig => {
  switch (type) {
    case 'image':
      return { ...DEFAULT_SLIDE_CONFIG_IMAGE };
    case 'video':
      return { ...DEFAULT_SLIDE_CONFIG_VIDEO };
    case 'imgzine-articles':
      return { ...DEFAULT_SLIDE_CONFIG_ARTICLE };
    default:
      return { ...DEFAULT_SLIDE_CONFIG_BASE };
  }
}

const DEFAULT_SLIDE_CONFIG_BASE = {
  title: '',
  duration: 10,
  showTicker: true
}


const DEFAULT_SLIDE_CONFIG_IMAGE = {
  ...DEFAULT_SLIDE_CONFIG_BASE,
  scaleMode: 'fill' as const,
  animate: false
}
const DEFAULT_SLIDE_CONFIG_VIDEO = {
  ...DEFAULT_SLIDE_CONFIG_BASE,
  videoType: 'vimeo' as const,
  url: '',
  mute: true,
  playFullVideo: true,
  from: 0,
  to: 1
}

export const DEFAULT_SLIDE_CONFIG_ARTICLE = {
  ...DEFAULT_SLIDE_CONFIG_BASE,
  renderer: 'fullarticle' as const,
  data: {
    type: 'latestFromChannel' as const,
    fromIndex: 0,
    count: 1,
    articleExpiration: false,
    articleMaxAge: 1
  }

}

export const CHESSBOARD_BACKGROUND = `url("/assets/chessboard.png")`;
