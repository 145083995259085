export * as HtmlTemplatesHelper from './HtmlTemplatesHelper';

import { wrapper } from './wrapper';
import { header } from './header';
import { content1 } from './content1';
import { content2 } from './content2';
import { footer } from './footer';
import { edition } from './edition';
import { linkedEditions } from './linkedEditions';
import { landingPage } from './landingPage';
import { unsubscribePage } from './unsubscribePage';

export const HtmlTemplates = {
  wrapper,
  header,
  content1,
  content2,
  footer,
  edition,
  linkedEditions,
  landingPage,
  unsubscribePage,
}