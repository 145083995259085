
import React, { useState } from 'react';
import { Translations } from '../../../../../../translations';
import { Modal, ModalHeader, ModalBody, ToastBody, Toast, ToastHeader, Input, Button, ModalFooter } from 'reactstrap';
import _ from 'lodash';
import styles from './CmsAccordionManager.module.scss';

export interface AccordionPanel {
    header: string,
    body: string
}

export interface AccordionPanelProps {
    panel: AccordionPanel,
    pos: number,
    deletable?: boolean
    changePanel: (pos: number, panel: AccordionPanel) => void
    onDelete: (pos: number) => void
    addPanel: (pos: number) => void
}

function AccordionPanel(props: AccordionPanelProps) {
    const { pos, changePanel, deletable, panel, onDelete, addPanel } = props;
    const { header, body } = panel;
    return (
        <div className={`cmsPanel`}>
            <Toast>
                <ToastHeader toggle={deletable ? () => { onDelete(pos) } : undefined}>
                    <Input value={header} onChange={(e) => changePanel(pos, { ...panel, header: e.target.value })} placeholder={`Insert header of panel ${pos + 1}...`} />
                </ToastHeader>
                <ToastBody>
                    <Input type="textarea" value={body} onChange={(e) => changePanel(pos, { ...panel, body: e.target.value })} placeholder={`Insert body of panel ${pos + 1}...`} />
                </ToastBody>
            </Toast>
            <div className='buttons'>
                <Button color='secondary' onClick={() => addPanel(pos)}>Add panel here</Button>
            </div>
        </div>
    )
}

export interface CmsAccordion {
    panels: AccordionPanel[]
}

const emptyPanel: AccordionPanel = {
    header: '',
    body: ''
}
const newAccrodion: CmsAccordion = {
    panels: [
        { ...emptyPanel },
        { ...emptyPanel },
        // { header:'header 1',body:'body 1' },
        // { header:'header 2',body:'body 2' },
    ]
}


export interface Props {
    magazine: number,
    closeHandler: () => void,
    insertHandler: (html: string) => void
    editingAccordion?: CmsAccordion
}

export const CmsAccordionManager = (props: Props) => {

    const { magazine, closeHandler, insertHandler, editingAccordion } = props;
    // console.log('editingAccordion: ', editingAccordion);

    const [accordion, setAccordion] = useState(editingAccordion ? { ...editingAccordion } : { ...newAccrodion });

    // useEffect(() => {
    //     if(editingAccordion){
    //         setAccordion({...editingAccordion});
    //     }
    // }, [editingAccordion])

    const changePanel = (pos: number, panel: AccordionPanel) => {
        const newPanels = [...accordion.panels];
        newPanels[pos] = panel;
        setAccordion({ ...accordion, panels: [...newPanels] });
    }
    const deletePanel = (pos: number) => {
        const newPanels = [...accordion.panels];
        newPanels.splice(pos, 1);
        setAccordion({ ...accordion, panels: [...newPanels] });
    }
    const addPanel = (pos: number) => {
        const newPanels = [...accordion.panels];
        newPanels.splice(pos + 1, 0, { ...emptyPanel });
        setAccordion({ ...accordion, panels: [...newPanels] });
    }

    const panelToHtml = (panel: AccordionPanel): string => {
        return `
            <div class="panel">
                <div class="title">
                ${panel.header}
                </div>
                <div class="body">
                    <div class="text-component">
                    ${panel.body}
                    </div>
                </div>
            </div>
        `;
    }
    const accordionToHtml = (): string => {
        const { panels } = accordion
        return `
            <p></p>
                <div class="accordion">
                    ${_.join(_.map(panels, (p) => panelToHtml(p)), '')}
                </div>
            <p></p>`
    }

    const tr = Translations.Translate(Translations, 'Cms');

    return (
        <Modal isOpen={true} size={'lg'} centered toggle={() => closeHandler()} className={styles.CmsAccordionManager}>
            <ModalHeader toggle={() => closeHandler()}><i className={'fa fa-clone'}></i> {editingAccordion ? `Edit this accordion` : `Configure a new accordion`}</ModalHeader>
            <ModalBody>
                {_.map(accordion.panels, (p, pos) => {
                    return <AccordionPanel key={`cmsPanel-${pos}`} panel={p} pos={pos} deletable={pos > 0 ? true : false} changePanel={changePanel} onDelete={deletePanel} addPanel={addPanel} />
                })}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" block size="lg" onClick={() => { insertHandler(accordionToHtml()) }} style={{ textTransform: 'uppercase' }}>{editingAccordion ? `Update the accordion` : `Insert the new accordion`}</Button>
            </ModalFooter>
        </Modal>
    )
}