import moment from 'moment';
import * as _ from 'lodash';

export function fillMissingMonthDates(data: any, period: string){
    try {
        if(!data || !data[0]){
            return data;
        }
        const changedData:any = [];
        const dayNumberMode = _.isNumber(data[0]['id']);
        const firstExistingDay = dayNumberMode?`${period}-01`:data[0]['id'];
        const dataIdFormat = dayNumberMode?'D':'YYYY-MM-DD';

        const firstDayOfMonth = moment(firstExistingDay).startOf('month');
        const lastDayOfMonth = moment(firstDayOfMonth).endOf('month');

        const yesterday = moment().subtract(1,'day');

        const lastDay = yesterday<lastDayOfMonth?yesterday:lastDayOfMonth;

        const currentDay = firstDayOfMonth;

        while (currentDay<=lastDay){
            const currentDayStr = moment(currentDay).format(dataIdFormat);
            const currentDayId = dayNumberMode?_.toNumber(currentDayStr):currentDayStr;
            const dayObjInData = _.find(data,['id',currentDayId]);
            const newDayObj = dayObjInData?dayObjInData:{id:currentDayId,value:0};
            changedData.push(newDayObj);
            currentDay.add(1,'day');
        }

        if(currentDay < lastDayOfMonth){
            while (currentDay<=lastDayOfMonth){
                const currentDayStr = moment(currentDay).format(dataIdFormat);
                const currentDayId = dayNumberMode?_.toNumber(currentDayStr):currentDayStr;
                const newDayObj = {id:currentDayId, value: null};
                changedData.push(newDayObj);
                currentDay.add(1,'day');
            }
        }

        return changedData;


    } catch (error) {
        console.log('An error occured in fill missing month days');
        console.log(error);       

        return data;        
        
    }
}
export function fillMissingWeekDates(data: any, period: string){
    try {
        if(!data || !data[0]){
            return data;
        }
        const changedData:any = [];
        const dataIdFormat = 'YYYY-MM-DD';

        const firstDayOfWeek = moment(period,'GGGG-[W]W').startOf('isoWeek');
        const lastDayOfWeek = moment(firstDayOfWeek).endOf('isoWeek');

        const yesterday = moment().subtract(1,'day');

        const lastDay = yesterday<lastDayOfWeek?yesterday:lastDayOfWeek;

        const currentDay = firstDayOfWeek;

        while (currentDay<=lastDay){
            const currentDayNumber = _.toNumber(moment(currentDay).format('D'));
            const currentDayId = moment(currentDay).format(dataIdFormat);
            const dayObjInData = _.find(data,['id',currentDayNumber]);
            const newDayObj = {id:currentDayId,value:dayObjInData?dayObjInData.value:0}
            changedData.push(newDayObj);
            currentDay.add(1,'day');
        }

        if(currentDay < lastDayOfWeek){
            while (currentDay<=lastDayOfWeek){
                const currentDayId = moment(currentDay).format(dataIdFormat);
                const newDayObj = {id:currentDayId, value: null};
                changedData.push(newDayObj);
                currentDay.add(1,'day');
            }
        }

        return changedData;


    } catch (error) {
        console.log('An error occured in fill missing week days');
        console.log(error);       

        return data;        
        
    }
}

export function fillMissingDates(data: any, period: string){
    try {       
        if(!data || !data[0]){
            return data;
        }
        const changedData:any = [];
        const dayNumberMode = _.isNumber(data[0]['id']);
        const firstExistingDay = dayNumberMode?`${period}-${data[0]['id']}`:data[0]['id'];
        const lastExistingDay = dayNumberMode?`${period}-${data[data.length-1]['id']}`:data[data.length-1]['id'];
        const dataIdFormat = dayNumberMode?'D':'YYYY-MM-DD';

        const firstDay = (period == 'last30days')? moment().subtract(1,'day').subtract(30,'day'):moment(firstExistingDay);

        const lastDay = (period == 'last30days')? moment().subtract(1,'day') : moment(lastExistingDay);

        const currentDay = firstDay;

        while (currentDay<=lastDay){
            const currentDayStr = moment(currentDay).format(dataIdFormat);
            const currentDayId = dayNumberMode?_.toNumber(currentDayStr):currentDayStr;
            const dayObjInData = _.find(data,['id',currentDayId]);
            const newDayObj = dayObjInData?dayObjInData:{id:currentDayId,value:0};
            changedData.push(newDayObj);
            currentDay.add(1,'day');
        }

        return changedData;


    } catch (error) {
        console.log('An error occured in fill missing days');
        console.log(error);
        

        return data;        
        
    }
}
export function fillMissingMonths(data: any, period: string){
    try {       
        if(!data || !data[0]){
            return data;
        }
        const changedData:any = [];
        // const dayNumberMode = _.isNumber(data[0]['id']);
        const firstExistingMonth = data[0]['id'];
        const lastExistingMonth = data[data.length-1]['id'];
        const dataIdFormat = 'YYYY-MM';

        const firstMonth = (period == '*')? moment(firstExistingMonth,dataIdFormat):moment(period,'YYYY').startOf('year');
        const yesterdayMonth = moment().subtract(1,'day').startOf('month');
        const lastMonth = (period == '*')? yesterdayMonth : yesterdayMonth<moment(period,'YYYY').endOf('year')?yesterdayMonth:moment(period,'YYYY').endOf('year');

        const currentMonth = firstMonth;

        while (currentMonth<=lastMonth){
            const currentMonthStr = currentMonth.format('YYYY-MM');
            const dayObjInData = _.find(data,['id',currentMonthStr]);
            const newDayObj = dayObjInData?dayObjInData:{id:currentMonthStr,value:0};
            changedData.push(newDayObj);
            currentMonth.add(1,'month');
        }

        return changedData;


    } catch (error) {
        console.log('An error occured in fill missing months');
        console.log(error);
        

        return data;        
        
    }
}

// export function fillMissingMonthDays(data: any, period: any){
//     try {
//         period
        
//         return data;


//     } catch (error) {
//         console.log('An error occured in fill missing month days');
//         console.log(error);

//         return data;        
        
//     }
// }