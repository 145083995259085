import React from 'react';
import styles from './NcSettingsGalleryItem.module.scss';
import { MediaHelper } from '../../../../../utils';
import _ from 'lodash';
import classNames from 'classnames';
import { CHESSBOARD_BACKGROUND } from '../../../NarrowcastingHelper';

interface Props {
  magazine: number
  url: string
  onRemove: (url: string) => void
}

export const NcSettingsGalleryItem = ({ magazine, url, onRemove }: Props) => {
  if (!MediaHelper.isImageExtension(MediaHelper.extractExtensionFromPath(url))) {
    return null;
  }
  return (
    <div className={styles.NcSettingsGalleryItem}>
      <div
        className={styles.img}
        style={{ backgroundImage: `url('${MediaHelper.proxify(magazine, url, 235)}'), ${CHESSBOARD_BACKGROUND}` }}>
        <div className={classNames(styles.actionIcon, styles.deleteIcon)} onClick={() => onRemove(url)}><i className='fa fa-trash'></i></div>
      </div>
      <div className={styles.fullName}>{MediaHelper.convertPlusToSpaces(MediaHelper.extractFullNameFromPath(url))}</div>
    </div>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────
