import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Input, Button } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import { API } from '../../apis';
import { Article, PlatformSearchFilter, SearchFilter, SearchingFilters, SearchQuery } from '../../pages/Advanced/subpages/Articles/domain';
import { DateHelper } from '../../utils';
import { EdErrorHandler, OrtecLoader } from '../../widgets';
import DataTable, { ExtendedColumnDescription } from '../DataTable/DataTable';
import styles from './ArticleLister.module.scss';

export const DefaultEmptySearchingFilters: SearchingFilters = {
  channel: '',
  source: '',
  categories: [],
  tags: [],
  since: '',
  until: ''
};

interface Props {
  magazine: number,
  title?: string,
  filters?: SearchingFilters
}

export const ArticleLister = ({ magazine, title, filters }: Props) => {

  const [loading, setLoading] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [articles, setArticles] = useState<Article[]>([]);
  const [searchText, setSearchText] = useState('');
  const [totalSize, setTotalSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    searchArticles();
  }, [searchText, currentPage])

  const searchArticles = async (query?: SearchQuery) => {
    try {
      setLoadingSearch(true);
      const q: SearchQuery = query ?? searchTextToSearchQuery();
      const f = filtersToSearchFilter();
      const p = filtersToPlatformSearchFilter();
      const { data } = await API.search.searchMagazineArticles(magazine, q, f, p);
      setArticles(data.articles);
      setTotalSize(data.articlesFound);
    } catch (error) {
      EdErrorHandler(error, 'searching articles');
    } finally {
      setLoadingSearch(false);
      setLoading(false);
    }
  }

  const filtersToSearchFilter = (): SearchFilter | undefined => {

    if (!filters) {
      return undefined;
    }

    const f: SearchFilter = {
      sources: filters.source ? [_.toNumber(filters.source)] : undefined,
      tags: _.isEmpty(filters.tags) && _.isEmpty(filters.categories) ? undefined : [...filters.categories, ...filters.tags],
      since: filters.since ? new Date(filters.since) : undefined,
      until: filters.until ? moment(filters.until).endOf('day').toDate() : undefined,
    };

    const searchFilter = _.omitBy(f, _.isNil);

    return !_.isEmpty(searchFilter) ? f : undefined;

  }

  const filtersToPlatformSearchFilter = (): PlatformSearchFilter | undefined => {

    if (!filters) {
      return undefined;
    }

    const p: PlatformSearchFilter = {
      channels: filters.channel ? [_.toNumber(filters.channel)] : undefined,
    };

    const platformSearchFilter = _.omitBy(p, _.isNil);

    return !_.isEmpty(platformSearchFilter) ? p : undefined;

  }

  const searchTextToSearchQuery = (): SearchQuery => {
    const q: SearchQuery = { type: "all", from: (currentPage - 1) * 10 };

    if (!searchText) {
      return { ...q, sort: { field: "cdate", direction: "desc" } };
    }

    if (searchText.match(/http:\/\/|https:\/\/|relevance-cms:\/\/.*/gm)) {
      return { ...q, type: "link", link: searchText };
    }

    return { ...q, type: "generic", query: searchText }
  }

  const changeSearchText = async (val: string) => {
    await setCurrentPage(1);
    setSearchText(val);
  }

  const onChangeSearchInput = useDebouncedCallback(
    // function
    (val: string) => {
      changeSearchText(val);
    },
    // delay in ms
    300
  );

  const openArticleDetailPage = (articleId: number) => {

    const link = `/${magazine}/advanced?article=${articleId}&tab=articles`;
    window.open(link, '_blank');
    return;
  }


  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'distinctStatuses',
      sort: false,
      text: '',
      headerStyle: { width: '50px' },
      align: 'center',
      headerAlign: 'center',
      formatter: (cell: any, row: Article) => {
        let status = 'visible';
        if (row.articleStatus == 0) {
          status = 'hidden';
        } else if (!cell || !_.isArray(cell)) {
          status = 'hidden'
        } else if (_.includes(cell, 0)) {
          status = 'partial'
        }
        return <i className={`fa fa-circle status status-${status}`} data-tippy-content={status == 'partial' ? `partially visible` : status}></i>;
      }
    },
    {
      dataField: 'title',
      // sort: true,
      text: '',
      formatter: (cell: any, row: Article) => {
        return _.trim(cell) ? cell : _.truncate(row.content, { length: 50, separator: ' ' });
      }
    },
    {
      dataField: 'cdate',
      sort: false,
      text: 'Creation Date',
      headerStyle: { width: '200px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img alt={''} data-tippy-content={'Creation Date'} src={'/assets/icons/16/calendar.svg'} /><span></span></Fragment>
      },
      formatter: (cell: any, row: Article) => {
        return cell ? DateHelper.dateTimeToLocaleString(cell) : ''
      }
    },
    {
      dataField: 'actions',
      isDummyField: true,
      sort: false,
      text: '',
      classes: 'actionsColumn',
      headerStyle: { width: '5px' },
      formatter: (cell, row: Article) => {
        return (
          <div className={'actionHoveringDiv'}>
            <Button onClick={(e: any) => { e.preventDefault(); e.stopPropagation(); openArticleDetailPage(row.id); return false; }}>open</Button>
          </div>
        )
      }
    }
  ];

  return (
    <div className={styles.ArticleLister}>
      {loading ? <OrtecLoader /> :
        <Fragment>
          <div className={styles.searchDiv}>
            {title && <div className={styles.titleDiv}>{title}</div>}
            <Input className={styles.searchInput} type="search" placeholder={'Search for Title or ID'} defaultValue={searchText} onChange={(e) => onChangeSearchInput(e.target.value)} />
          </div>
          <div className={styles.tableDiv}>
            <DataTable
              // noHover
              data={articles}
              columns={columns}
              keyField={'id'}
              onRowClick={(row: Article) => {
                // openArticleDetailPage(row.id);
              }}
              remote
              hideSearchBar
              // hideSizePerPage
              totalSize={totalSize}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
            {loadingSearch && <OrtecLoader />}
          </div>
        </Fragment>
      }
    </div>
  )
}
