
import React, { Component, Fragment } from 'react';
import { LanguageContextConsumer } from '../LanguageContext';
import * as _ from 'lodash';


import { TranslateFunction } from '../../domain';
import en from './en';
import nl from './nl';

const translations: any = {
    en: en,
    nl: nl,
}
export interface Props {
    t: string,
    n?: string
}

const styled = false;

export class I18n extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }
    getTranslations = (language: string): TranslateFunction => {
        const file = translations[language] ? translations[language] : translations['en'];
        const { n } = this.props;
        if (n) { //if namespace is given
            // return (key) => (file[n] && file[n][key])?file[n][key]: (translations['en'][n] && translations['en'][n][key])?translations['en'][n][key]: file[key] || translations['en'][key] || key;
            return (key) => _.get(file, `${n}.${key}`) || _.get(translations['en'], `${n}.${key}`) || _.get(file, `${'Default'}.${key}`) || _.get(translations['en'], `${'Default'}.${key}`) || key;
        } else {  //if namespace is NOT given
            // return (key) => file[key]?file[key]:translations['en'][key] || key;
            return (key) => _.get(file, `${'Default'}.${key}`) || _.get(translations['en'], `${'Default'}.${key}`) || key;
        }
    }
    render() {
        const { t } = this.props;
        return (
            <LanguageContextConsumer>
                {languageContext => {
                    if (!languageContext) {
                        return null;
                    }
                    const language = languageContext.language;
                    const tr = this.getTranslations(language);
                    const w = tr(t);

                    return styled ? <span className={`translated ${language}`}>{w}</span> : w;
                }}
            </LanguageContextConsumer>
        )

    }

}

export default I18n;
