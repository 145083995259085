import React from 'react';
import './css/OrtecLoader.min.css';
import classnames from 'classnames';

type Size = 'small' | 'medium' | 'large' | 'fullPage' | 'inline' | 'icon'
interface Props {
    size?: Size,
    px?: number //used if size is icon
}

export const OrtecLoader = (props: Props) => {
    const { size, px } = props;
    const sizeClass: Size = props.size || 'medium'
    return (
        <div id="OrtecLoader"
            className={classnames(size !== 'icon' ? `loaderContainer` : '', sizeClass)}
            style={px ? { width: `${px}px`, height: `${px}px` } : {}}
        >
            <div className={classnames("ortecLoaderWrapperExternal")}>
                <div className="ortecLoaderWrapperInternal">
                    <div className="ortecLoader"></div>
                </div>
            </div>
        </div>
    );
}

export default OrtecLoader;
