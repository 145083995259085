import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import { Input } from 'reactstrap';
import { NcSettingsFormData } from '../subpages/NcSettings/NcSettingsConfiguration/NcSettingsConfiguration';

interface Props {
  control: Control<NcSettingsFormData, any>,
  fieldName: FieldPath<NcSettingsFormData>
  placeholder?: string
  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSettingsTextInput = ({ control, fieldName, placeholder }: Props) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
        return (
          <Input type={'text'} value={_.toString(field.value)} onChange={(e) => field.onChange(_.toString(e.target.value))} placeholder={placeholder} />
        )
      }}
    />
  )
}
