import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';

import './css/ReportTable.min.css'
import { Profile } from '../../../domain';
import * as _ from 'lodash';
import * as Api from '../../api';
import { Translations } from '../../translations';
import Toggle from 'react-toggle';
const FileDownload = require('js-file-download');
import swal from 'sweetalert';
import { OrtecLoader } from '../../widgets';
import { DateHelper } from '../../utils';

export interface Props {
  magazine: string,
  profile: Profile,
}
export interface State {
  reports?: any[],
  reportSending?: number
}

export class ReportTableScheduled extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.getReports();

  }

  filterReportsBasedOnAccessRights = (reports: any[]) => { // editor should see reports for audiences that he has access to
    const { profile } = this.props;
    const auds = _.map(profile.audienceIds, _.toNumber);
    if (profile.globalAudience) {
      auds.push(1);
    }
    return _.filter(reports, (r) => {
      return _.includes(auds, r.audience);
    })
  }

  getReports() {
    Api.getReports(parseInt(this.props.magazine)).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ reports: [] });
      }
      this.setState({ reports: this.prepareReports(this.filterReportsBasedOnAccessRights(resp.data)) });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
        this.setState({ reports: [] });

      });
  }
  generate = (audience: number, period: string, reportId: number) => {
    const { magazine, profile } = this.props;
    const report = {
      period,
      audience,
    }

    this.setState({ reportSending: reportId });

    Api.downloadReport(parseInt(magazine), report).then((resp) => {
      if (!resp.data || resp.data.error) {
        alert(`An error occured: ${resp.data.error}`);
        return;
      }
      const data = resp.data;
      const header = resp.headers['content-disposition'];
      const filename = _.trim(header.split('filename=')[1], '"');

      FileDownload(data, filename);
    })
      .catch((error) => {
        alert('An error occured:' + error.message);
      }).finally(() => {
        this.setState({ reportSending: undefined });
      });
  }
  dateFormatter(cell: any, row: any) {
    if (!cell) {
      return `Never`;
    }
    try {
      const lastSentMonth = cell ? moment(cell).startOf('month') : undefined;
      const prevMonth = moment(lastSentMonth).subtract(1, 'month');
      return (
        <Fragment>
          <div>
            {DateHelper.dateTimeToLocaleString(cell)}
          </div>
          <div style={{ visibility: this.state.reportSending !== undefined ? 'hidden' : 'visible' }}>
            (<a href="#" onClick={() => { this.generate(row.audience, moment(prevMonth).format('YYYY-MM'), row.id) }}>{moment(prevMonth).format('MMMM YYYY')}</a>)
          </div>
        </Fragment>
      );
    } catch (error) {
      return <>{cell}</>;
    }
  }
  nextFormatter(cell: any, row: any) {
    if (!row.active) {
      return `Inactive`
    }
    try {
      const today = moment();
      const tomorrow = moment(today).add(1, 'day');
      const currentMonth = moment(today).startOf('month');
      const prevMonth = moment(currentMonth).subtract(1, 'month');
      const nextMonth = moment(currentMonth).add(1, 'month');
      const lastSentMonth = row.lastSent ? moment(row.lastSent).startOf('month') : undefined;

      if (!lastSentMonth || lastSentMonth < currentMonth) {
        return (
          <Fragment>
            <div>{DateHelper.dateToLocaleString(tomorrow.toDate())}</div>
            <div style={{ visibility: this.state.reportSending !== undefined ? 'hidden' : 'visible' }}>(<a href="#" onClick={() => { this.generate(row.audience, moment(prevMonth).format('YYYY-MM'), row.id) }}>{moment(prevMonth).format('MMMM YYYY')}</a>)</div>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <div>{DateHelper.dateToLocaleString(nextMonth.toDate())}</div>
            <div style={{ visibility: this.state.reportSending !== undefined ? 'hidden' : 'visible' }}>({moment(currentMonth).format('MMMM')} {moment(currentMonth).format('YYYY')})</div>
          </Fragment>
        )
      }

    } catch (error) {
      return `Error`;
    }
  }
  toggleReport = (checked: boolean, row: any) => {
    const { reports } = this.state;
    if (reports) {
      const newReports = [...reports];
      const r = row;
      r.active = checked ? 1 : 0;
      this.setState({ reports: newReports });
    }

    Api.saveReport(parseInt(this.props.magazine), { audience: row.audience, type: row.type, checked }).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in toggling report');
      }

    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
        if (reports) {
          const newReports = [...reports];
          const r = row;
          r.active = checked ? 0 : 1;
          this.setState({ reports: newReports });
        }

      });


  }
  deleteReport = (row: any) => {
    const { reports } = this.state;
    if (reports) {
      const newReports = [...reports];
      _.remove(newReports, { audience: row.audience, type: row.type });
      this.setState({ reports: newReports });
    }

    Api.deleteReport(parseInt(this.props.magazine), { audience: row.audience, type: row.type }).then((resp) => {
      if (!resp.data || resp.data.error) {
        this.setState({ reports: reports });
        console.log('Error in deleting report');
      }
    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
        if (reports) {
          this.setState({ reports: reports });
        }

      });


  }
  sendTest = (row: any) => {
    const { magazine } = this.props;
    const emails = row.emails;
    if (!emails) {
      swal("Error!", "You have not defined an email yet!", "error");
      return;
    }
    const audience = row.audience;
    console.log(`Sending test for audience: ${audience}`);

    const today = moment();
    const currentMonth = moment(today).startOf('month');
    const prevMonth = moment(currentMonth).subtract(1, 'month');
    const period = moment(prevMonth).format('YYYY-MM');


    const report = {
      period,
      audience,
      emails
    }

    this.setState({ reportSending: row.id });

    Api.sendReport(parseInt(magazine), report).then((resp) => {
      if (!resp.data || resp.data.error) {
        swal("Error!", `${resp.data.error}`, "error");
        return;
      }

      const accepted = _.get(resp.data, 'info.accepted');
      if (accepted) {
        swal("Success!", `Report has been sent successfully and received from ${accepted.length} acount${accepted.length > 1 ? 's' : ''}!`, "success", { buttons: [false], timer: 2000 });
      } else {
        swal("Success!", `Report has been sent successfully but no account was able to receive it. Please check the email list':''}!`, "warning");

      }
    })
      .catch((error) => {
        swal("Error!", `${error.message}`, "error");
      }).finally(() => {
        this.setState({ reportSending: undefined });
      });



  }
  emailFormatter(cell: any) {
    const tr = Translations.Translate(Translations, 'Report');
    try {
      const count = _.size(_.omitBy(cell.split(';'), _.isEmpty));
      return (<span data-tippy-content={cell ? cell : null}>{cell && count ? count : tr('add_email')}</span>);
    } catch (error) {
      return (<span>{cell ? cell : tr('add_email')}</span>);
    }
  }
  toggleFormatter(cell: any, row: any) {
    return (<Toggle checked={cell ? true : false} onChange={(e) => this.toggleReport(e.target.checked, row)} />);
  }
  editableFormatter(cell: any, row: any) {
    return (<span>{cell ? cell : 'Add emails'}</span>);
  }
  typeFormatter(cell: any, row: any) {
    const tr = Translations.Translate(Translations, 'Report');
    return (<span data-tippy-content={tr(`${cell}Label`)}>{tr(`${cell}Label`)}</span>);
  }
  labelFormatter(cell: any, row: any) {
    return (<span data-tippy-content={cell ? cell : null}>{cell}</span>);
  }
  buttonFormatter(cell: any, row: any) {
    const tr = Translations.Translate(Translations, 'Report');

    if (this.state.reportSending == row.id) {
      return (
        <div><OrtecLoader size={'icon'} /></div>
      )
    }

    return (
      <div className={'actionHoveringDiv'}>
        <Button className="selectButton secondary" /*data-tippy-content={tr(`selectArticle`)}*/
          disabled={this.state.reportSending !== undefined}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.sendTest(row);
            return false;
          }}>
          {tr('sendTest')}
        </Button>
        <Button className="selectButton secondary" style={{ marginLeft: '10px' }}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.deleteReport(row);
            return false;
          }}>
          {tr('delete')}
        </Button>
      </div>
    );
  }
  prepareReports = (reports: any) => {
    const { profile, magazine } = this.props;
    const tr = Translations.Translate(Translations, 'Report');

    const finalReports: any[] = _.map(reports, (rep) => {
      return {
        id: `${magazine}-${rep.audience}-${rep.type}`,
        audience: rep.audience,
        label: (rep.audience == 1) ? tr('all_employees') : _.get(_.find(profile.audiences, ['id', rep.audience]), 'label'),
        type: _.get(rep, 'type'),
        emails: _.get(rep, 'emails'),
        lastSent: _.get(rep, 'lastSent'),
        active: _.get(rep, 'status') == 1 ? true : false,
      }
    });

    return finalReports;
  }

  afterSaveCell = (row: any, cellName: any, cellValue: any) => {
    const { reports } = this.state;
    let oldValue: string;
    if (reports) {
      const newReports = [...reports];
      const r = row;
      oldValue = _.get(r, 'email');
      _.set(r, cellName, cellValue);
      this.setState({ reports: newReports });
    }
    Api.saveReport(parseInt(this.props.magazine), { audience: row.audience, type: row.type, email: cellValue }).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in saving email');
      }

    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
        if (reports) {
          const newReports = [...reports];
          const r = row;
          r.email = oldValue ? oldValue : '';
          this.setState({ reports: newReports });
        }

      });
  }

  render() {
    const { reports } = this.state;
    const tr = Translations.Translate(Translations, 'Report');

    return (
      <div id="ReportTable" className={''} style={{ position: 'relative', minHeight: '200px' }}>
        {Translations.EnableTranslations(Translations)}

        <div className="tableDiv">
          {reports ?
            <Fragment>
              <div className="titleDiv">{tr('scheduledReports')}</div>
              <BootstrapTable ref="reportTable" data={reports} bordered={false} version='4' /*height='285px'*/ cellEdit={{ mode: 'click', blurToSave: true, afterSaveCell: this.afterSaveCell }}>
                <TableHeaderColumn isKey dataField='id' dataSort={false} hidden></TableHeaderColumn>
                <TableHeaderColumn dataField='active' editable={false} dataFormat={this.toggleFormatter.bind(this)} dataSort={false} width={'80px'}></TableHeaderColumn>
                <TableHeaderColumn dataField='label' editable={false} dataSort={true} dataAlign={'center'} dataFormat={this.labelFormatter.bind(this)}><img data-tippy-content={tr("audience")} src="/assets/icons/16/audience.svg" /></TableHeaderColumn>
                <TableHeaderColumn dataField='type' editable={false} dataSort={true} dataAlign={'center'} dataFormat={this.typeFormatter.bind(this)}><img data-tippy-content={tr("when")} src="/assets/icons/16/calendar.svg" /></TableHeaderColumn>
                <TableHeaderColumn dataField='emails' dataSort={false} dataAlign={'center'} columnClassName={'editable'} dataFormat={this.emailFormatter.bind(this)}><img data-tippy-content={tr("recipientEmails")} src="/assets/icons/16/email.svg" /></TableHeaderColumn>
                <TableHeaderColumn dataField='lastSent' editable={false} dataAlign={'center'} dataFormat={this.dateFormatter.bind(this)} dataSort={true} width={'150px'}><img data-tippy-content={tr("lastSent")} src="/assets/icons/16/sent.svg" /></TableHeaderColumn>
                <TableHeaderColumn dataFormat={this.nextFormatter.bind(this)} dataAlign={'center'} dataSort={false} editable={false} width={'120px'}><img data-tippy-content={tr("nextOn")} src="/assets/icons/16/schedule.svg" /></TableHeaderColumn>
                <TableHeaderColumn editable={false} dataFormat={this.buttonFormatter.bind(this)} dataSort={false} width={'250px'}>{ }</TableHeaderColumn>
              </BootstrapTable>
            </Fragment>
            : <OrtecLoader />
          }
        </div>
      </div>
    );
  }
}

export default ReportTableScheduled;
