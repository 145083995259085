import React from 'react';
import { ArticlePreview } from './components/ArticlePreview';
import { NlTemplateConfiguration_Content } from '../domain';

import styles from './NewsletterTemplatesetPreview_Content.module.scss';

interface Props {
  configuration: NlTemplateConfiguration_Content
}
export const NewsletterTemplatesetPreview_Content = ({ configuration }: Props) => {
  return (
    <div className={styles.NewsletterTemplatesetPreview_Content}>
      <ArticlePreview
        configuration={configuration}
      />
      <div className={styles.flexRow}>
        <div>
          <ArticlePreview
            configuration={configuration}
            oneColumn
          />
        </div>
        <div>
          <ArticlePreview
            configuration={configuration}
            oneColumn
          />
        </div>
      </div>
      <ArticlePreview
        configuration={configuration}
        noImage
      />
      <div className={styles.flexRow}>
        <div>
          <ArticlePreview
            configuration={configuration}
            oneColumn
            noImage
          />
        </div>
        <div>
          <ArticlePreview
            configuration={configuration}
            oneColumn
            noImage
          />
        </div>
      </div>
    </div>
  )
}
