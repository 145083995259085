import './css/FormSelector.min.css';

import React, { Fragment, useEffect, useState } from 'react';
import { Translations } from "../../translations";
import { WidgetManager } from '../../modules/WidgetManager/WidgetManager';
import { Form, Profile } from '../../../domain';
import { API } from '../../apis';
import { EdErrorHandler, OrtecLoader } from '../../widgets';
import _ from 'lodash';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import { Button, Col } from 'reactstrap';

export interface Props {
    magazine: number,
    profile: Profile,
    closeHandler: () => void,
    selectHandler: (w: any) => void
}

export function FormSelector(props: Props) {

    const { magazine, profile, closeHandler, selectHandler } = props;

    const [loading, setLoading] = useState<boolean>(true);
    const [forms, setForms] = useState<Form[]>([]);

    const filterFormsBasedOnAccessRights = (forms: Form[]) => { // editor should see only untargeted forms and forms targeted to audiences that he has access to
        if (profile.globalAudience) { //if editor is super admin then all forms should be visible
            return forms;
        }
        const auds = _.map(profile.audienceIds, _.toNumber);

        return _.filter(forms, (f) => {
            if (!f.audiences) {
                return false;
            }
            return _.isEmpty(f.audiences) || !_.isEmpty(_.intersection(auds, _.map(f.audiences, _.toInteger)));
        })
    }

    const getForms = async () => {
        try {
            setLoading(true);
            const { data } = await API.forms.getForms(magazine);
            setForms(filterFormsBasedOnAccessRights(data));
        } catch (error) {
            EdErrorHandler(error, `getting forms`);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getForms();
    }, [])


    const trfs = Translations.Translate(Translations, 'FormSelector');
    const trt = Translations.Translate(Translations, 'Tables');
    const tr = Translations.Translate(Translations, 'Forms');

    const statusFormatter = (cell: any) => {
        try {
            // const status = (cell);
            switch (cell) {
                case 1:
                    return `<i class="fa fa-circle status-draft" aria-hidden="true" data-tippy-content="${tr("draft")}" data-tippy-placement="right"></i>`
                case 2:
                    return `<i class="fa fa-circle status-published" aria-hidden="true" data-tippy-content="${tr("published")}" data-tippy-placement="right"></i>`
                case 3:
                    return `<i class="fa fa-circle status-finished" aria-hidden="true" data-tippy-content="${tr("finished")}" data-tippy-placement="right"></i>`
                default:
                    return `<i class="fa fa-circle status-deleted" aria-hidden="true" data-tippy-content="${tr("deleted")}" data-tippy-placement="right"></i>`
            }

        } catch (error) {
            return <>{cell}</>;
        }
    }

    const dateFormatter = (cell: any) => {
        try {
            return <>{moment(cell).format('DD/MM/YYYY - HH:mm')}</>
        } catch (error) {
            return <>{cell}</>;
        }
    }
    const resultsFormatter = (cell: any) => {
        return <>{cell ? cell : '-'}</>;
    }
    const typeFormatter = (cell: any) => {
        switch (cell) {
            case 's_poll':
                return 'poll';
            default:
                return <>{cell}</>;
        }
    }

    const titleFormatter = (cell: any) => {
        return (<div className={'ellipsisDiv'} data-tippy-content={cell}>{cell}</div>);
    }

    const buttonsFormatter = (cell: any, row: any) => {

        return (
            <div className='actionHoveringDiv'>
                <Button className="selectButton secondary"
                    onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        selectHandler(row);
                        return false;
                    }}>
                    {tr('select')}
                </Button>
            </div>
        )
    }

    const paginationPanel = (props: any) => {
        let max = 1;
        let min = 1;
        if (forms) {
            max = (forms.length / 10) + 1;
        }
        return (
            <Fragment>
                <Col md={8}>{props.components.pageList} {/*props.components.sizePerPageDropdown*/}</Col>
                <Col md={4}>
                    <div className="goToPageDiv">
                        <input id="goToPageInput" onKeyDown={(e: any) => {
                            if (e.keyCode === 13) {
                                let val = e.target.value;
                                if (val < min) {
                                    val = min;
                                } else if (val > max) {
                                    val = max;
                                }
                                props.changePage(val);
                                e.target.value = '';
                            }
                        }} className='form-control' type="number" placeholder={trt('go_to_page')} style={{ width: '115px' }} />
                        <button className="btn" onClick={(e) => {
                            var k: any = document.getElementById('goToPageInput');
                            let val = k.value;
                            if (val < min) {
                                val = min;
                            } else if (val > max) {
                                val = max;
                            }
                            props.changePage(val);
                            k.value = ''
                        }}>{'>'}</button>
                    </div>
                </Col>
            </Fragment>
        );
    }

    const options: any = {
        // onRowClick: this.onRowClick.bind(this),
        // toolBar: this.renderToolbar,
        paginationPanel,
        defaultSortName: 'cdate',  // default sort column name
        defaultSortOrder: 'desc',  // default sort order
        sizePerPage: 25,
        sizePerPageList: [
            { text: `25 ${trt('entries')}`, value: 25 },
            { text: `50 ${trt('entries')}`, value: 50 },
            { text: `100 ${trt('entries')}`, value: 100 },
        ]
    }

    return (
        <div id="FormSelector">
            <div className='header'>
                <img src="/assets/icons/bt_close_bigger.svg" onClick={closeHandler} style={{ cursor: 'pointer' }} />
                <div className="headerTitle">{trfs('selectForm')}</div>
            </div>
            <div className='content simple'>
                <div className="tableDiv withLoader">
                    {loading ? <OrtecLoader /> :
                        <BootstrapTable data={forms} bordered={false} hover={true} search={true} options={options} version='4' pagination>
                            <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
                            <TableHeaderColumn dataField='status' dataAlign='center' dataFormat={statusFormatter} width='50px' dataSort={true}>
                                <img data-tippy-content={tr("status")} src="/assets/icons/16/stats.svg" />
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn dataField='status' dataAlign='center' dataFormat={this.toggleFormatter.bind(this)} width='70px'></TableHeaderColumn> */}
                            <TableHeaderColumn dataField='title' tdStyle={{ cursor: 'pointer' }} dataFormat={titleFormatter}></TableHeaderColumn>
                            <TableHeaderColumn dataField='type' dataAlign='center' dataSort={true} dataFormat={typeFormatter} width='70px'>
                                <img data-tippy-content={tr("formType")} src="/assets/icons/16/form_type.svg" />
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='results' dataAlign='center' columnClassName='hideOnHover' dataFormat={resultsFormatter} dataSort={true} width='50px'>
                                <img data-tippy-content={tr("formResults")} src="/assets/icons/16/form_results.svg" />
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='cdate' columnClassName='hideOnHover' dataSort={true} dataAlign='center' dataFormat={dateFormatter} width='150px'>
                                <img data-tippy-content={tr("created")} src="/assets/icons/16/calendar.svg" />
                            </TableHeaderColumn>
                            <TableHeaderColumn dataFormat={buttonsFormatter} width='50px'></TableHeaderColumn>
                        </BootstrapTable>
                    }
                </div>
            </div>
        </div>
    )
}