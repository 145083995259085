import React, { Component } from 'react';

export interface Props {
  magazine: string
}

export class Settings extends Component<Props> {
  render() {
    const { magazine } = this.props;
    return (
      <div id="Settings">
        Settings - {magazine}
      </div>
    );
  }
}

export default Settings;
