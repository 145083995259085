import React, { useMemo } from 'react';
import { Profile } from '../../../../../domain';
import { ImageComponent } from './components/ImageComponent';
import { TextWithUIComponent } from './components/TextWithUIComponent';
import { NlTemplateConfiguration_Header } from './domain';
import styles from './NewsletterTemplatesetDetails.module.scss';
import { useQuery } from '@tanstack/react-query';
import { EdErrorHandler } from '../../../../widgets';
import { API } from '../../../../apis';
import _ from 'lodash';

interface Props {
  magazine: number,
  profile: Profile,
  configuration: NlTemplateConfiguration_Header,
  onChange: (newConfiguration: NlTemplateConfiguration_Header) => void
}

export const NewsletterTemplateHeader = ({ magazine, profile, configuration, onChange }: Props) => {

  const applicationMetaInfoQuery = useQuery({
    queryKey: ['applicationMetaInfo', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.applicationMetaInfo.getApplicationMetaInfo(magazine);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting application meta info`);
      }
    }
  });

  const downloadAppLinkWarningText = useMemo(() => {
    if (applicationMetaInfoQuery.isFetching || isDownloadAppLinkValid(applicationMetaInfoQuery.data?.url)) {
      return undefined;
    }
    return `Download App Link is not set correctly in application meta info. Please contact your project manager.`

  }, [applicationMetaInfoQuery.data, applicationMetaInfoQuery.isFetching])

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>header</div>
      </div>
      <div className={styles.sectionContent}>
        <ImageComponent
          {...{ magazine, profile }}
          name={`Header image`}
          extraText={`(640px width)`}
          validImageWidth={640}
          configuration={configuration.image}
          onChange={(c) => onChange({ ...configuration, image: c })}
        />
        <hr />
        <TextWithUIComponent
          name={`Open in browser link`}
          configuration={configuration.openInBrowserLink}
          onChange={(c) => { onChange({ ...configuration, openInBrowserLink: c }) }}
          noTextUI
        />
        {/* <hr /> */}
        <TextWithUIComponent
          name={`Download app link`}
          configuration={configuration.downloadAppLink}
          onChange={(c) => { onChange({ ...configuration, downloadAppLink: c, openInBrowserLink: { ...configuration.openInBrowserLink, backgroundColor: c.backgroundColor, textColor: c.textColor, fontSize: c.fontSize } }) }}
          disableTextUI={!configuration.openInBrowserLink.enabled && !configuration.downloadAppLink.enabled}
          warningText={downloadAppLinkWarningText}
        />
        <hr />
        <TextWithUIComponent
          name={`Introduction text`}
          configuration={configuration.introductionText}
          onChange={(c) => { onChange({ ...configuration, introductionText: c }) }}
          placeholder={'Introduction text...'}
          longText
        />
      </div>
    </div>
  )
}

const isDownloadAppLinkValid = (url?: string | null): boolean => {
  return (url && _.endsWith(url, '/app_download')) ? true : false;
}
