
import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form as RForm, FormGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';

import './css/QuizSectionList.min.css';
import { Translations } from '../../../../translations';
import { Form, FormQuestion, FormSection } from '../../../../../domain';
import * as FormsHelper from './FormsHelper';
import Swal from 'sweetalert2';
import { SwalDelete } from '../../../../widgets';

export interface Props {
  form: Form,
  activeSection: number,
  activeCategory: string,
  changeForm: (form: Form) => void
  changeActiveSection: (index: number, category: string) => void
}

export class QuizSectionList extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  addQuestionsPage = (index: number, type: string) => {
    const { form } = this.props;

    const newQuestionId = FormsHelper.getNewId(form);
    const newQuestionPage = { ...FormsHelper.newEmptyQuestion(type), id: newQuestionId }

    const newQuestions = [...form.config.questions];

    newQuestions.splice(index, 0, newQuestionPage);


    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: newQuestions
      }
    }
    this.props.changeForm(newForm);
  }

  addResultsPage = (index: number, type: string) => {
    const { form } = this.props;

    const newQuestionId = FormsHelper.getNewResultsId(form);
    const newResultPage = { ...FormsHelper.newEmptyQuestion(type), id: newQuestionId }

    const newResults = form.config.results ? [...form.config.results] : [];

    newResults.splice(index, 0, newResultPage);

    const newForm = {
      ...form,
      config: {
        ...form.config,
        results: newResults
      }
    }
    this.props.changeForm(newForm);
  }

  removeQuestionsPage = (index: number) => {
    const { form } = this.props;

    let newSections = form.config.questions;
    if (!_.isEmpty(newSections)) {
      newSections.splice(index, 1);
    }

    const newForm = {
      ...form,
      config: {
        ...form.config,
        questions: newSections
      }
    }
    this.props.changeForm(newForm);
  }
  removeResultsPage = (index: number) => {
    const { form } = this.props;

    let newSections = form.config.results ? [...form.config.results] : [];
    if (!_.isEmpty(newSections)) {
      newSections.splice(index, 1);
    }

    const newForm = {
      ...form,
      config: {
        ...form.config,
        results: newSections
      }
    }
    this.props.changeForm(newForm);
  }

  renderAddButton = (resultMode: boolean, index: number) => {
    return (
      <div className={'addNewSectionButtonDiv'}>
        {!resultMode ?
          // <Button className={'addNewSectionButton'} onClick={()=>this.addSection()}>add page</Button>
          <UncontrolledDropdown className={'addNewSectionButton'}>
            <DropdownToggle>
              Add
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.addQuestionsPage(index, 'text')}>Text Page</DropdownItem>
              <DropdownItem onClick={() => this.addQuestionsPage(index, 'quiz')}>Question</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          :
          <Button className={'addNewSectionButton'} onClick={() => this.addResultsPage(index, 'text')}>add text page</Button>
        }
      </div>
    )
  }

  getTileTitle = (type: number, index: number) => {
    switch (type) {
      case 1:
        return `Text page`
      case 7:
        return `Opt in`
      case 8:
        return `Results`
      default:
        return `Question ${index}`;
    }
  }

  renderTile = (question: FormQuestion, index: number, qIndex: number, removable: boolean, resultMode: boolean) => {
    return (
      <div key={`formSection-${index}`} className={`elementBox ${this.props.activeSection == index && this.props.activeCategory == `${resultMode ? 'r' : 'q'}` ? 'active' : ''}`} onClick={(e) => this.props.changeActiveSection(index, resultMode ? 'r' : 'q')}>
        <div className={'iconsDiv'}>
          <i className={'material-icons'}>drag_indicator</i>
          <div className='spacer'></div>
          {!removable ? null :
            <i className={'material-icons'} onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (resultMode) {
                this.removeResultsPage(index);
              } else if (question.type == 7) {

                SwalDelete.fire({
                  title: 'Are you sure you want to remove the opt-in page?',
                  text: `This will result in anonymous results only. You could also consider setting the auto-opt in for this quiz to true, to receive the personalized results without asking the user to opt-in.`,
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete opt-in page!',
                  focusCancel: true,
                }).then((result) => {
                  if (!result.value) {
                    return false;
                  }
                  if (this.props.activeSection == index) { //opt-in page is active and to be deleted
                    this.props.changeActiveSection(0, 'r');
                  }
                  this.removeQuestionsPage(index)
                });

              } else {
                this.removeQuestionsPage(index);
              }

            }}>
              remove_circle_outline
            </i>
          }
        </div>
        <div className={'sectionTitle'}>{this.getTileTitle(question.type, qIndex)}</div>
      </div>
    )
  }

  renderQuestionsTiles = (questions: FormQuestion[], removable: boolean) => {
    let qIndex = 0;
    return (

      _.map(questions, (q, index) => {
        if (q.type == 3) {
          qIndex++
        }
        return (
          <Fragment key={`tile-${index}`}>
            {this.renderTile(q, index, qIndex, removable && !_.includes([8], q.type) ? true : false, false)}
            {!removable || q.type == 7 ? null : this.renderAddButton(false, (index + 1))}
          </Fragment>
        )
      })
    )
  }
  renderResultsTiles = (questions: FormQuestion[], removable: boolean) => {
    let qIndex = 0;
    return (

      _.map(questions, (q, index) => {
        if (q.type == 3) {
          qIndex++
        }
        return (
          <Fragment key={`tile-${index}`}>
            {this.renderTile(q, index, qIndex, removable && !_.includes([8], q.type) ? true : false, true)}
            {!removable || q.type == 8 ? null : this.renderAddButton(true, (index + 1))}
          </Fragment>
        )
      })
    )
  }


  render() {
    const { form, activeSection } = this.props;
    const tr = Translations.Translate(Translations, 'Forms');


    const results = form.config.results ? form.config.results : [];
    const questions = form.config.questions;



    return (
      <div id="QuizSectionList">
        {Translations.EnableTranslations(Translations)}
        {form.status == 1 ? this.renderAddButton(false, 0) : null}
        {this.renderQuestionsTiles(questions, form.status == 1 ? true : false)}
        <hr />
        {form.status == 1 ? this.renderAddButton(true, 0) : null}
        {this.renderResultsTiles(results, form.status == 1 ? true : false)}
      </div>
    )
  }
}

export default QuizSectionList;
