import React, { useState, Fragment, useEffect } from 'react'
import { Badge } from 'reactstrap';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import styles from './ArticleDetails.module.scss';
import { ArticleWithDetails, ArticleSource, ArticleChannel } from './domain';
import _ from 'lodash';
import ArticlePreview from '../../../../components/ArticlePreview/ArticlePreview';
import { DateHelper, SourcesHelper } from '../../../../utils';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import { API } from '../../../../apis';
import { Profile } from '../../../../../domain';
import { QrModal } from '../../../../components';
import { generateUniversalLink } from '../../../../utils/genericHelper';

interface Props {
  magazine: number,
  profile: Profile,
  articleId: number,
  onClose: () => void
}

export const ArticleDetails = ({ magazine, profile, articleId, onClose }: Props) => {

  const [detailsLoading, setDetailsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const [article, setArticle] = useState<ArticleWithDetails>();

  const [qrModal, setQrModal] = useState(false);

  const loading = detailsLoading;

  useEffect(() => {
    getArticleDetails();
  }, [])

  const getArticleDetails = async () => {
    try {
      setDetailsLoading(true);
      const { data } = await API.articles.getArticleDetails(magazine, articleId);
      if (!data.id || _.toNumber(data.id) !== articleId) {
        setNotFound(true);
      } else {
        setArticle(data);
      }
    } catch (error) {
      EdErrorHandler(error, `getting article details`);
    } finally {
      setDetailsLoading(false);
    }
  }


  const decideChannelNameText = (channel: ArticleChannel): string => {

    const { edConfig } = profile;
    if (!edConfig) {
      return channel.label || channel.name;
    }
    switch (edConfig.channelGraphName) {
      case 'nameOnly':
        return channel.name || channel.label || '';
      case 'labelOnly':
        return channel.label || channel.name || '';
      case 'nameLabel':
        return `${channel.name || ''} ${channel.label ? `(${channel.label})` : ''}`;
      case 'labelName':
        return `${channel.label || ''} ${channel.name ? `(${channel.name})` : ''}`;
      default:
        return channel.label || channel.name;
    }
  }

  const channelLink = (channel: ArticleChannel) => {
    return (
      <div key={channel.id} className={styles.resourceLink}>
        <a target={'_blank'} href={`/${magazine}/advanced?channel=${channel.id}&tab=channels`}>
          {decideChannelNameText(channel)}
        </a>
      </div>
    )
  }

  // const decideSourceNameText = (source:ArticleSource):string => {

  //   const {edConfig} = profile;
  //   if(!edConfig){
  //     return source.label || source.name;
  //   }
  //   switch(edConfig.sourceGraphName){
  //     case 'nameOnly':
  //       return source.name || source.label || '';
  //     case 'labelOnly':
  //       return source.label || source.name || '';
  //     case 'nameLabel':
  //       return `${source.name || ''} ${source.label?`(${source.label})`:''}`;
  //     case 'labelName':
  //       return `${source.label || ''} ${source.name?`(${source.name})`:''}`;
  //     default:
  //       return source.label || source.name;
  //     }
  // }

  const sourceLink = (source: ArticleSource) => {
    return (
      <div key={source.id} className={styles.resourceLink}>
        <i className={`fa fa-circle status-${source.status}`} data-tippy-content={'Toggle article source status'} onClick={() => updateArticleSourceStatus(source.id, source.status ? 0 : 1)}></i>
        <a target={'_blank'} href={`/${magazine}/advanced?source=${source.id}&tab=sources`} style={{ fontStyle: source.label ? 'unset' : 'italic' }}>
          {SourcesHelper.sourceEdConfigName(source, profile.edConfig)}
        </a>
      </div>
    )
  }

  const updateArticleStatus = async (status: boolean) => {
    if (!article) {
      return;
    }
    const oldStatus = article.status;
    const newStatus = status ? 1 : 0;
    setArticle({
      ...article, status: newStatus, sources: _.map(article.sources, (s) => {
        return { ...s, status: newStatus }
      })
    });
    try {
      const { data } = await API.articles.saveArticleStatus(magazine, articleId, newStatus);
      setArticle(data);
    } catch (error) {
      EdErrorHandler(error, `saving article status`);
      setArticle({
        ...article, status: oldStatus, sources: _.map(article.sources, (s) => { //roll back source status change
          return { ...s, status: oldStatus }
        })
      });
    }
  }

  const updateArticleSourceStatus = async (sourceId: number, status: 1 | 0) => {
    if (!article) {
      return;
    }
    setArticle({
      ...article, sources: _.map(article.sources, (s) => {
        if (s.id === sourceId) {
          return { ...s, status }
        }
        return s;
      })
    });
    try {
      const { data } = await API.articles.saveArticleSourceStatus(magazine, articleId, sourceId, status);
      setArticle(data);
    } catch (error) {
      EdErrorHandler(error, `saving article source status`);
      setArticle({
        ...article, sources: _.map(article.sources, (s) => { //roll back source status change
          if (s.id === sourceId) {
            return { ...s, status: status ? 0 : 1 }
          }
          return s;
        })
      });
    }
  }

  const categoryFormatter = (cat: string): string => {
    return cat.replace('_cat:', '');
  }

  const openQRModal = () => {
    setQrModal(true);
  }

  const closeQRModal = () => {
    setQrModal(false);
  }

  const universalLink = generateUniversalLink(profile.appMeta, `/a/${articleId}`)

  const closeBtn = <img className="close" onClick={onClose} src="/assets/icons/bt-close.svg" />
  const notFoundMessage = <div className={styles.notFoundMessage}><div className="alert alert-danger">{`Article not found`}</div></div>;

  const articleCategories = _.filter(article?.tags, (t) => _.startsWith(t, '_cat:'));
  const articleTags = _.filter(article?.tags, (t) => !_.startsWith(t, '_cat:'));

  return (
    <div id={'ArticleDetails'}>
      <div className={styles.header}>
        {/* <i className="material-icons closeIcon" onClick={this.closeHandler.bind(this)}>close</i> */}
        <img src="/assets/icons/bt_close_bigger.svg" onClick={onClose} style={{ cursor: 'pointer' }} />
        {loading || notFound ? '' :
          <Fragment>
            <div className={styles.idContainer}>
              <div className={styles.headerLabel}>ID</div>
              <div className={styles.headerVal}> {articleId}</div>
            </div>
            <div className={styles.linkContainer}>
              <div className={styles.headerLabel}>UNIVERSAL LINK</div>
              <div className={styles.headerVal}> <a href={universalLink} target={'_blank'}>{universalLink}</a></div>
            </div>
            <div className={styles.flexSpacer}></div>
            <div className={styles.qrContainer}>
              <i className={`fa fa-qrcode qrToggler`} data-tippy-content={'Click to see QR code'} onClick={() => { openQRModal() }}></i>
            </div>
          </Fragment>
        }
      </div>
      <div className='content'>
        {loading ? <OrtecLoader /> :
          (notFound || !article?.id) ? notFoundMessage :
            <Fragment>
              <div className={'leftPart'} style={{ flex: 2 }}>
                <div className={styles.articlePreviewWrapper}>
                  <div className={styles.articlePreviewContainer}>
                    <ArticlePreview magazine={_.toString(magazine)} article={{ ...article, id: _.toString(article.id) }} defaultVariationLanguage={profile.magazineLanguages?.primary} />
                  </div>
                </div>
              </div>
              <div className={'rightPart'} style={{ flex: 1, minWidth: '350px', overflowY: 'auto' }}>
                <div className={styles.detailsContainer}>
                  <div className={styles.detailsSection}>
                    <div className={styles.detailsSectionLabel}>visible</div>
                    <div style={{ flex: 1 }}></div>
                    <div><Toggle checked={article.status == 1} onChange={(e) => { updateArticleStatus(e.target.checked) }} /></div>
                  </div>
                  <div className={styles.detailsSection}>
                    <div>
                      <div className={styles.detailsSectionLabel}>creation date</div>
                      <div className={styles.detailsSectionValue}>{DateHelper.dateTimeToLocaleString(article.cdate)}</div>
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div>
                      <div className={styles.detailsSectionLabel}>last updated</div>
                      <div className={styles.detailsSectionValue}>{DateHelper.dateTimeToLocaleString(article.mdate)}</div>
                    </div>
                  </div>
                  <div className={styles.detailsSection}>
                    <div style={{ overflow: "hidden" }}>
                      <div className={styles.detailsSectionLabel}>link</div>
                      <div className={styles.detailsSectionValue}>{<div className={styles.resourceLink}><a href={article.link} target={'_blank'}>{article.link}</a></div> || <i>Not available</i>}</div>
                    </div>
                  </div>
                  <div className={styles.detailsSection}>
                    <div>
                      <div className={styles.detailsSectionLabel}>author</div>
                      <div className={styles.detailsSectionValue}>{article.author || <i>Not available</i>}</div>
                    </div>
                  </div>
                  <div className={styles.detailsSection}>
                    <div>
                      <div className={styles.detailsSectionLabel}>channels</div>
                      <div className={styles.detailsSectionValue}>
                        {_.isEmpty(article.channels) ? <i>No channels</i> :
                          _.map(_.uniqBy(article.channels, 'id'), (c) => {
                            return channelLink(c);
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <div className={styles.detailsSection}>
                    <div>
                      <div className={styles.detailsSectionLabel}>sources</div>
                      <div className={styles.detailsSectionValue}>
                        {_.isEmpty(article.sources) ? <i>No sources</i> :
                          _.map(_.uniqBy(article.sources, 'id'), (s) => {
                            return sourceLink(s);
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <div className={styles.detailsSection}>
                    <div style={{ flex: 1 }}>
                      <div className={styles.detailsSectionLabel}>categories</div>
                      <div className={styles.detailsSectionValue}>
                        <div className={styles.tagsContainer}>
                          {_.isEmpty(articleCategories) ? <i>No categories</i> :
                            _.map(articleCategories, (c) => {
                              return <Badge>{categoryFormatter(c)}</Badge>;
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.detailsSection}>
                    <div style={{ flex: 1 }}>
                      <div className={styles.detailsSectionLabel}>tags</div>
                      <div className={styles.detailsSectionValue}>
                        <div className={styles.tagsContainer}>
                          {_.isEmpty(articleTags) ? <i>No tags</i> :
                            _.map(articleTags, (t) => {
                              return <Badge>{t}</Badge>;
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {qrModal && <QrModal universalLink={universalLink} closeModal={closeQRModal} type={'article'} noVisibleLink />}
            </Fragment>
        }
      </div>
    </div>
  )
}
