import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Profile } from '../../../../../../domain';
import _ from 'lodash';
import { Button, Form } from 'reactstrap';
import styles from './ChannelInfoPairs.module.scss';
import { MediaSelector } from '../../../../../components';
import { EdErrorHandler } from '../../../../../widgets';
import { API } from '../../../../../apis';
import { PluginWithEntrypoints } from './pluginsDomain';
import * as ciHelper from './ChannelInfoHelper';
import { KeyTypeSelect } from './KeyTypeSelect';
import { KeyInput } from './KeyInput';
import { FieldValue } from './FieldValue';
import { AssetType } from '../../../../../components/MediaSelectButton/MediaSelectButton';
interface Props {
    magazine: number,
    profile: Profile
    channelInfo: string,
    changeChannelInfo: (val: string) => void
}

export function ChannelInfoPairs({ magazine, profile, channelInfo, changeChannelInfo }: Props) {

    const [mediaSelector, setMediaSelector] = useState(false);
    const [keyForMediaSelector, setKeyForMediaSelector] = useState<string>();
    const [restrictedTypesForMediaSelector, setRestrictedTypesForMediaSelector] = useState<AssetType[]>();
    const [plugins, setPlugins] = useState<PluginWithEntrypoints[]>([]);

    useEffect(() => {
        loadPlugins();
    }, [magazine]);

    let channelInfoObject = {};

    if (channelInfo) {
        try {
            channelInfoObject = JSON.parse(channelInfo);
        } catch (error) {
            console.log(`Not a valid json value`);
        }
    }

    const loadPlugins = async () => {
        try {
            const { data } = await API.plugins.getPlugins(magazine);
            setPlugins(data);
        } catch (error) {
            EdErrorHandler(error, `getting magazine plugins`)
        }
    }

    const addNewField = () => {
        const newChannelInfoObject = {
            ...channelInfoObject,
            [``]: ''
        };
        changeChannelInfo(JSON.stringify(newChannelInfoObject, null, 2));
    }

    const changeFieldKey = (key: string, val: string) => {
        let newChannelInfoObject = _.mapKeys(channelInfoObject, (v, k) => {
            return (k == key) ? val : k;
        })
        changeChannelInfo(JSON.stringify(newChannelInfoObject, null, 2));
    }

    const changeFieldValue = (key: string, val: string) => {
        let newChannelInfoObject = _.mapValues(channelInfoObject, (v, k) => {
            return (k == key) ? val : v;
        })
        changeChannelInfo(JSON.stringify(newChannelInfoObject, null, 2));
    }

    const removeField = (key: string) => {
        let newChannelInfoObject = _.omit(channelInfoObject, [key]);
        changeChannelInfo(JSON.stringify(newChannelInfoObject, null, 2));
    }

    const changeKeyType = (key: string, newType: string) => {
        changeFieldKey(key, ciHelper.keyToKeyType(newType));
    }

    const doesKeyExist = (key: string): boolean => {
        return _.includes(_.map(channelInfoObject, (v, k) => k), key) && key !== '';
    }

    const openMediaSelector = (key: string, restrictedMediaTypes?: AssetType[]) => {
        setKeyForMediaSelector(key);
        setRestrictedTypesForMediaSelector(restrictedMediaTypes);
        setMediaSelector(true);
    }

    const closeMediaSelector = () => {
        setKeyForMediaSelector(undefined);
        setRestrictedTypesForMediaSelector(undefined);
        setMediaSelector(false);
    }

    const onMediaHandlerSelect = (mediaUrl: string[]) => {
        if (!keyForMediaSelector) {
            return;
        }
        changeFieldValue(keyForMediaSelector, mediaUrl[0]);
        closeMediaSelector();
    }

    return (
        <div className={styles.ChannelInfoPairs}>
            <Form>
                {_.map(channelInfoObject, (value, key) => {
                    return (
                        <div key={key} className={styles.fieldRow}>
                            <div className={styles.fieldRowTypeContainer}>
                                <KeyTypeSelect typeKey={key} typeValue={value} doesKeyExist={doesKeyExist} onChange={changeKeyType} />
                            </div>
                            <div className={styles.fieldRowValueContainer}>
                                <KeyInput fieldKey={key} onChange={changeFieldKey} />
                                <FieldValue {...{ magazine, plugins, openMediaSelector }} fieldKey={key} fieldValue={value} onChange={changeFieldValue} />
                            </div>
                            <Button className={classnames({ iconButton: true, inputButton: true })} onClick={() => { removeField(key) }}><i className='material-icons'>delete</i></Button>
                        </div>
                    )
                })}
                <Button onClick={addNewField}>Add new field</Button>
            </Form>
            {mediaSelector && <MediaSelector magazine={magazine} profile={profile} closeHandler={closeMediaSelector} selectHandler={onMediaHandlerSelect} showPartSelector restrictedMediaTypes={restrictedTypesForMediaSelector} />}
        </div>
    )
}