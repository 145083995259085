import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import '../../css/TabPageNavigation.min.css';

import { Profile } from "../../../domain";
import { Translations } from '../../translations';
import { Redirect, useHistory } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';
import { API } from '../../apis';
import { EdErrorHandler, OrtecLoader } from '../../widgets';
import _ from 'lodash';
import { PluginWithEntrypoints } from '../Advanced/subpages/Channels/ChannelInfoPairs/pluginsDomain';
import { PluginContainer } from './PluginContainer/PluginContainer';
import { HistoryHelper } from '../../utils';

export interface Props{
    magazine: number,
    profile: Profile,
}

export const Plugins = (props: Props) => {
    const { magazine, profile } = props;
    const history = useHistory();
    const activeTab = HistoryHelper.getQueryParam(history, 'tab');
    
    const toggleTab = (tab: string) => {
        HistoryHelper.setQueryParams(history, { tab });
    }

    const {data: pluginsWithEntrypointsQuery, isFetching: isLoading} = useQuery({
        queryKey: ['plugins', magazine],
        queryFn: async () => {
            try {
                const { data } = await API.plugins.getPlugins(magazine);
                return data;
            } catch (error) {
                EdErrorHandler(error, `getting plugins with endpoints`);
            }
        }
    });

    const pluginsWithEDEntrypoint = useMemo(() => {
        if(_.isEmpty(pluginsWithEntrypointsQuery)) {
            return [];
        }
        
        const _pluginsWithEDEntrypoint = _.filter(pluginsWithEntrypointsQuery, (p: PluginWithEntrypoints) => {
            return _.find(p.entrypoints, e => e.entrypointType === 'ed-entrypoint') ? true : false;
        })
    
        const activePlugins = profile.edConfig?.plugins ?? [];
        return _pluginsWithEDEntrypoint.filter(plugin => _.includes(activePlugins, plugin.id));
    }, [pluginsWithEntrypointsQuery])
    
    const tr = Translations.Translate(Translations, 'Modules');

    if (!activeTab) {
        return isLoading ? <OrtecLoader/> : <Redirect to={`plugins?tab=${pluginsWithEDEntrypoint[0].id}`} />
    }
    
    return (
        <div id="Plugins" className={'TabPageNavigation'}>
            {Translations.EnableTranslations(Translations)}
            <div className="PageTitle">{tr('Plugins')}</div>
            {isLoading ? <OrtecLoader/> : 
            <>
                <Nav tabs>
                    {!_.isEmpty(pluginsWithEDEntrypoint) && _.map(pluginsWithEDEntrypoint, p =>
                        <NavItem key={`${p.id}_tab`}>
                            <NavLink className={classnames({ active: activeTab === `${p.id}` })} onClick={() => { toggleTab(`${p.id}`) }}>{p.settings.name}</NavLink>
                        </NavItem>
                    )}
                </Nav>
                <TabContent activeTab={activeTab}>
                    {!_.isEmpty(pluginsWithEDEntrypoint) && _.map(pluginsWithEDEntrypoint, p =>
                        <TabPane key={`${p.id}_tabPane`} tabId={`${p.id}`}><PluginContainer plugin={p} magazine={magazine} uid={profile.uid}/></TabPane>
                    )}
                </TabContent>
            </>}
        </div>
    )
}
