import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import { StatsFilter, ArticleStats, Article, Profile, UsersList } from '../../../domain';
import * as Api from '../../api';

import './css/ArticlePerformance.min.css';
import ArticleContent from '../ArticlePreview/ArticlePreview';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ArticleNumbers from './ArticleNumbers';
import ArticleChart from './ArticleChart';
import ArticleComments from './ArticleComments';
import ArticleInfo from './ArticleInfo';
import ArticleLikesModal from './ArticleLikesModal';
import { FilterBar } from './FilterBar';
import { ArticleCommentsModal } from './ArticleCommentsModal';
import { EdErrorHandler } from '../../widgets';
import { ArticleSharesModal } from './ArticleSharesModal';

export interface Props {
  profile: Profile
  magazine: string,
  statsFilter: StatsFilter,
  articleId: number,
  article?: Article,
  toggleArticleStatusHandler: (status: boolean) => void,
  deleteSelectedCommentsHanlder: (selectedComments: string[]) => void,
  periodFilterHandler: (period: string) => void,
  audienceFilterHandler: (audience: number) => void,
}

export interface State {
  articleStats?: ArticleStats | 'loading',
  likesModal: boolean,
  sharesModal: boolean,
  commentsModal: boolean,
  likesUsersLoading: boolean,
  sharesUsersLoading: boolean,
  likesUsers?: UsersList,
  sharesUsers?: UsersList,

}

export class ArticlePerformance extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      articleStats: 'loading',
      likesModal: false,
      sharesModal: false,
      commentsModal: false,
      likesUsersLoading: false,
      sharesUsersLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ articleStats: 'loading' });
    this.getArticleContent();
  }
  getArticleContent() {

    const params: StatsFilter = {
      ...this.props.statsFilter,
      article: this.props.articleId
    }

    Api.loadChartData('articlePerformance', params, this.props.magazine.toString()).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ articleStats: undefined });
      }
      this.setState({ articleStats: resp.data.data && resp.data.data.value ? resp.data.data.value : undefined });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }

  getLikesUsers = async () => {
    try {
      this.setState({ likesUsersLoading: true, likesUsers: undefined });
      const { articleStats } = this.state;
      if (!articleStats || articleStats === 'loading') {
        return;
      }
      const resp = await Api.getUsers(parseInt(this.props.magazine), articleStats.likes_uids);
      this.setState({ likesUsers: resp.data });
    } catch (error) {
      EdErrorHandler(error, 'getting Likes Users');
    } finally {
      this.setState({ likesUsersLoading: false });
    }
  }
  getSharesUsers = async () => {
    try {
      this.setState({ sharesUsersLoading: true, sharesUsers: undefined });
      const { articleStats } = this.state;
      if (!articleStats || articleStats === 'loading') {
        return;
      }
      const resp = await Api.getUsers(parseInt(this.props.magazine), articleStats.shares_uids);
      this.setState({ sharesUsers: resp.data });
    } catch (error) {
      EdErrorHandler(error, 'getting Shares Users');
    } finally {
      this.setState({ sharesUsersLoading: false });
    }
  }

  openLikesModal = () => {
    this.getLikesUsers();
    this.setState({ likesModal: true });
  }
  closeLikesModal = () => {
    this.setState({ likesModal: false });
  }
  openSharesModal = () => {
    this.getSharesUsers();
    this.setState({ sharesModal: true });
  }
  closeSharesModal = () => {
    this.setState({ sharesModal: false });
  }
  openCommentsModal = () => {
    this.setState({ commentsModal: true });
  }
  closeCommentsModal = () => {
    this.setState({ commentsModal: false });
  }

  render() {
    const { magazine, articleId, statsFilter, article, toggleArticleStatusHandler, deleteSelectedCommentsHanlder, profile } = this.props;
    const { articleStats, likesModal, sharesModal, commentsModal, likesUsersLoading, sharesUsersLoading, likesUsers, sharesUsers } = this.state;
    const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>
    return (
      <div id="ArticlePerformance">
        <div id="StatsSection">
          <FilterBar magazine={magazine} statsFilter={statsFilter} periodFilterHandler={this.props.periodFilterHandler.bind(this)} audienceFilterHandler={this.props.audienceFilterHandler.bind(this)} audiences={profile.audiences ? profile.audiences : []} globalAudience={profile.globalAudience} />
          {articleStats === 'loading' ? loader :
            <Fragment>
              <div className='tableDiv'><ArticleNumbers articleStats={articleStats} onLikesClickHandler={this.openLikesModal.bind(this)} onSharesClickHandler={this.openSharesModal.bind(this)} onCommentsClickHandler={this.openCommentsModal.bind(this)} /></div>
              <ArticleChart articleStats={articleStats} statsFilter={statsFilter} />
            </Fragment>
          }
        </div>
        <div id="InformationSection">
          <ArticleInfo article={article} magazine={magazine} toggleArticleStatusHandler={toggleArticleStatusHandler.bind(this)} />
        </div>

        {/* <Fragment>
        <Row><Col md={12}><FilterBar magazine={magazine} statsFilter={statsFilter} periodFilterHandler={this.props.periodFilterHandler.bind(this)} audienceFilterHandler={this.props.audienceFilterHandler.bind(this)}  audiences={profile.audiences?profile.audiences:[]} globalAudience={profile.globalAudience}/></Col></Row>
        {articleStats === 'loading'?loader:
        <Fragment>
          <Row><Col md={12}><ArticleNumbers articleStats={articleStats} articleLikes={articleLikes} onLikesClickHandler={this.openLikesModal.bind(this)}/></Col></Row>
          <Row><Col md={12}><ArticleChart articleStats={articleStats} statsFilter={statsFilter}/></Col></Row>
        </Fragment>
        }
        <Row style={{flex:1}}><Col md={6}><ArticleComments article={article} deleteSelectedCommentsHanlder={deleteSelectedCommentsHanlder.bind(this)}/></Col><Col md={6}><ArticleInfo article={article} magazine={magazine} toggleArticleStatusHandler={toggleArticleStatusHandler.bind(this)}/></Col></Row>
        {!likesModal?null:
        <ArticleLikesModal articleLikes={articleLikes} closeModal={this.closeLikesModal.bind(this)}/>
        }
      </Fragment> */}
        {!likesModal ? null :
          <ArticleLikesModal closeModal={this.closeLikesModal.bind(this)} loading={likesUsersLoading} users={likesUsers} />
        }
        {!sharesModal ? null :
          <ArticleSharesModal closeModal={this.closeSharesModal.bind(this)} loading={sharesUsersLoading} users={sharesUsers} />
        }
        {!commentsModal ? null :
          <ArticleCommentsModal article={article} closeModal={this.closeCommentsModal.bind(this)} deleteSelectedCommentsHanlder={this.props.deleteSelectedCommentsHanlder.bind(this)} articleStats={articleStats} />
        }
      </div>
    );
  }
}

export default ArticlePerformance;
