import React from 'react';

// styles taken from MediaBucketExplorerListFile 
import styles from './MediaBucketExplorerListFile.module.scss'
import { FolderI } from './MediaBucketExplorer';

interface Props {
  folder: FolderI
  onSelect: () => void
  searchMode: boolean
  selectorMode: boolean
}

export default function MediaBucketExplorerListFolder(props: Props) {
  const { folder, onSelect, selectorMode, searchMode } = props
  const { folderName, parentPath, filesCount } = folder
  return <div className={styles.outer}>
    <button onClick={onSelect} className={styles.inner}>
      {selectorMode && <div className={styles.selectedColumn} />}
      <div className={styles.leftColumn}>
        <img src="/assets/icons/24/folder.svg" />
        <div className={styles.text}>{folderName}</div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.text}>{`~ (${filesCount} files)`}</div>
      </div>
      {searchMode &&
        <div className={styles.rightColumn}>
          <div className={styles.text}>{parentPath}</div>
        </div>
      }
    </button>
  </div>
}