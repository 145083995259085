import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect } from 'react'
import { ColumnDescription } from 'react-bootstrap-table-next';
import { SearchBarProps, Search } from 'react-bootstrap-table2-toolkit';
import ReactDatePicker from 'react-datepicker3';
import "react-datepicker3/dist/react-datepicker.css";

import Toggle from 'react-toggle';
import { Button, Input } from 'reactstrap';
import { SearchConfig } from './domain';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import styles from './Toolbar.module.scss';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { DateHelper } from '../../../../utils';

interface Props {
    columns: ColumnDescription[],
    searchConfig: SearchConfig,
    setSearchConfig: (searchConfig: SearchConfig) => void
    sizePerPage: number
    currentPage: number
    totalData: number
    searchProps: SearchBarProps
    exportLoading: boolean
    exportProgress: number
    onCreateNew: () => void,
    onExport: (all?: boolean) => void
    onImportModalOpen: () => void

}

export default function Toolbar({ columns, searchConfig, setSearchConfig, sizePerPage, currentPage, totalData, exportLoading, exportProgress, searchProps, onCreateNew, onExport, onImportModalOpen }: Props) {

    const searchFieldOptions = () => {
        return _.map(columns, (c) => {
            return !c.hidden ? <option key={c.dataField} value={c.dataField}>{c.text}</option> : null;
        });
    }

    const searchOperatorOptions = () => {
        switch (searchConfig.fieldType) {
            case 'date':
                return dateSearchOperatorOptions;
            case 'checkbox':
                return checkboxSearchOperationOptions;
            default:
                return defaultSearchOperatorOptions;
        }
    }


    const defaultSearchOperatorOptions = <Fragment>
        <option value="c">contains</option>
        <option value="s">starts with</option>
        <option value="e">ends with</option>
    </Fragment>;

    const dateSearchOperatorOptions = <Fragment>
        <option value="c">on</option>
        <option value="s">after start of</option>
        <option value="e">before end of</option>
    </Fragment>;

    const checkboxSearchOperationOptions = <Fragment>
        <option value="c">is</option>
    </Fragment>;

    const onSearchFieldChange = (e: any) => {
        const field = e.target.value;
        const col = _.find(columns, (c) => c.dataField == field);
        if (!col) {
            return;
        }
        const fieldType = col?.formatExtraData?.type || '';

        const operator = fieldType == 'checkbox' ? 'c' : searchConfig.operator;

        const val = fieldType == 'checkbox' ? 'true' : '';

        const includeAnonymous = searchConfig.includeAnonymous;

        setSearchConfig({ field, fieldType, operator, val, includeAnonymous });
    }

    const onSearchOperatorChange = (e: any) => {
        setSearchConfig({ ...searchConfig, operator: e.target.value });
    }

    const toDate = (d: string | boolean) => {
        try {
            if (!d || _.isBoolean(d)) {
                return null;
            }
            const t = Date.parse(d);
            return !_.isNaN(t) ? new Date(t) : null;
        } catch (error) {
            return null;
        }
    }

    const onChangeSearchInput = useDebouncedCallback(
        // function
        (val: string) => {
            setSearchConfig({ ...searchConfig, val });
        },
        // delay in ms
        300
    );

    const renderSearchInput = () => {
        switch (searchConfig.fieldType) {
            case 'checkbox':
                return <Toggle checked={searchConfig.val == 'true' ? true : false} onChange={(e) => setSearchConfig({ ...searchConfig, val: e.target.checked ? 'true' : 'false' })} />
            case 'date':
                return (
                    <div className={styles.datePickerWrapper}>
                        <ReactDatePicker
                            selected={toDate(searchConfig.val)}
                            onChange={(d: Date, e: any) => {
                                console.log(d);
                                if (!d) {
                                    setSearchConfig({ ...searchConfig, val: '' });
                                    return;
                                }
                                try {
                                    const m = moment(d);
                                    if (!m.isValid()) {
                                        setSearchConfig({ ...searchConfig, val: '' });
                                        return;
                                    }
                                    const val = m.format('YYYY-MM-DD');
                                    setSearchConfig({ ...searchConfig, val });
                                    return;
                                } catch (error) {
                                    setSearchConfig({ ...searchConfig, val: '' });
                                    return;
                                }
                            }}
                            onChangeRaw={(e) => {
                                if (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                                return false;

                            }}
                            popperPlacement={'auto'}
                            customInput={<Input type="text" />}
                            dateFormat={DateHelper.dailyDatePickerFormatBasedOnLocale()}
                            dropdownMode={'select'}
                            placeholderText={'select date..'}
                            showMonthDropdown
                            showYearDropdown
                            isClearable
                        />
                    </div>
                )
            default:
                return <Input key={`searchInput-${searchConfig.field}`} type="search" placeholder={`search..`} defaultValue={searchConfig.val} onChange={(e) => onChangeSearchInput(e.target.value)} />
        }
    }

    const renderExportProgressBar = () => {
        return (
            <div className={classNames(styles.exportProgressBarWrapper)}>
                <CircularProgressbarWithChildren value={exportProgress} text={`${exportProgress}%`} styles={buildStyles({
                    textColor: '#002e67',
                    textSize: '24px',
                    pathColor: '#AB0076'
                })} />
            </div>
        )
    }

    return (
        <div className={styles.toolbar}>
            <div className={styles.searchWrapper}>
                <div><Input type="select" value={searchConfig.field} onChange={onSearchFieldChange}>{searchFieldOptions()}</Input></div>
                <div> <Input type="select" value={searchConfig.operator} onChange={onSearchOperatorChange}>{searchOperatorOptions()}</Input></div>
                <div className={styles.searchBarContainer}>{renderSearchInput()}</div>
                <div className={styles.anonymousToggleContainer}> Include anonymous profiles <Toggle checked={searchConfig.includeAnonymous} onChange={(e) => setSearchConfig({ ...searchConfig, includeAnonymous: e.target.checked })} /></div>
            </div>
            <div className={styles.buttonsWrapper}>
                <div className={styles.paginationTotal}>Showing profiles {_.min([(((currentPage - 1) * sizePerPage) + 1), totalData])} to {_.min([(currentPage * sizePerPage), totalData])} of {totalData}</div>
                <div className={styles.spacer}></div>
                {exportLoading ?
                    renderExportProgressBar()
                    :
                    <Fragment>
                        <Button outline color={'secondary'} onClick={() => { onImportModalOpen() }}>import csv</Button>
                        <Button outline color={'secondary'} onClick={() => { onExport() }}>export csv</Button>
                        <Button outline color={'secondary'} onClick={() => { onExport(true) }}>export all</Button>
                    </Fragment>
                }
                <Button color={'primary'} onClick={onCreateNew}>Add new profile</Button>
            </div>
        </div>
    )
}
