import _ from "lodash";
import { CmsCategory } from "../pages/Content/subpages/categories/domain";

export const isDefaultFeedCategoryTag = (categoryTag: string, feedName?: string): boolean => {
  return feedName ? (categoryTag == `_cat:${feedName}-feed`) : false;
}

export const isDefaultFeedCategory = (category: string, feedName?: string): boolean => {
  return feedName ? (category == `${feedName}-feed`) : false;
}

export const isCategoryTag = (tag: string): boolean => {
  return _.startsWith(tag, '_cat:');
}

export const extractCategoriesFromTags = (tags: string[], excludeDefaultOfFeed?: string): string[] => {
  return _.map(_.uniq(_.filter(tags, (t) => isCategoryTag(t) && !isDefaultFeedCategoryTag(t, excludeDefaultOfFeed))), (c) => c.replace(`_cat:`, ``));
}

export const excludeDefaultFeedCategoryFromCategories = (categories: CmsCategory[], feedName?: string): CmsCategory[] => {
  return _.filter(categories, (c) => !isDefaultFeedCategory(c.category.name, feedName));
}

export const extractFeedCategoriesNames = (feedCategories: CmsCategory[]): string[] => {
  return _.uniq(_.flatMap(feedCategories, (c) => {
    return [
      c.category.name,
      ..._.map(c.children, 'name')
    ]
  }))
}

export const extractLegacyCategories = (articleCategories: string[], feedCategories: CmsCategory[],): string[] => {
  const feedCategoriesNames = extractFeedCategoriesNames(feedCategories);
  return _.filter(articleCategories, (c) => !_.includes(feedCategoriesNames, c));
}