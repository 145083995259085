import React from 'react';

// styles taken from MediaBucketExplorerListFile 
import styles from './MediaBucketExplorerListFile.module.scss'
import classNames from 'classnames';

interface Props {
  selectorMode: boolean
  searchMode: boolean
  hideFolders: boolean
  setHideFolders: (v: boolean) => void
}

export default function MediaBucketExplorerListHeader(props: Props) {
  const { searchMode, selectorMode, hideFolders, setHideFolders } = props

  const showFoldersLabel = 'Show folders'
  const hideFoldersLabel = 'Hide folders'
  return <div className={classNames(styles.outer, styles.inner, styles.header)}>
    {selectorMode && <div className={styles.selectedColumn} />}
    <div className={styles.leftColumn}>
      <span className={styles.headerLabel}>Name</span>
      <div className={styles.showHideDiv}>
        {hideFolders
          ? <button className={styles.showHideButton} onClick={() => setHideFolders(false)}>{showFoldersLabel}</button>
          : <span className={styles.showHideLabel}>{showFoldersLabel}</span>
        }
        <span>&nbsp;/&nbsp;</span>
        {hideFolders
          ? <span className={styles.showHideLabel}>{hideFoldersLabel}</span>
          : <button className={styles.showHideButton} onClick={() => setHideFolders(true)}>{hideFoldersLabel}</button>
        }
      </div>
    </div>
    <div className={styles.rightColumn}>
      <span className={styles.headerLabel}>Uploaded date</span>
    </div>
    {searchMode &&
      <div className={styles.rightColumn}>
        <span className={styles.headerLabel}>Location</span></div>
    }
  </div>
}