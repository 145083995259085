import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import { Input } from 'reactstrap';
import { NcSettingsFormData } from '../subpages/NcSettings/NcSettingsConfiguration/NcSettingsConfiguration';

interface Props {
  control: Control<NcSettingsFormData, any>,
  fieldName: FieldPath<NcSettingsFormData>
  suffix?: string
  min?: number
  nullable?: boolean
  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSettingsNumberInput = ({ control, fieldName, suffix, min, nullable }: Props) => {

  const minimumAcceptedValue = min || 0;

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
        return (
          <>
            <Input type={'number'} value={nullable && field.value === null ? '' : ensureMinValue(_.toNumber(field.value), minimumAcceptedValue)} onChange={(e) => field.onChange(nullable && e.target.value === '' ? null : _.toNumber(e.target.value))} min={minimumAcceptedValue} />
            {suffix && <span>{suffix}</span>}
          </>
        )
      }}
    />
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const ensureMinValue = (val: number, min?: number): number => {
  const minimumAcceptedValue = min || 0;
  const v = _.toNumber(val || minimumAcceptedValue);
  return v < minimumAcceptedValue ? minimumAcceptedValue : v;
}
