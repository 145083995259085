import React, { Component } from 'react';
import { Input, Row, Col, Label } from 'reactstrap';
import * as _ from 'lodash';
import { StatsFilter, ArticleStats } from '../../../domain';
import { DashLineChart, DashBarChart } from '../../charts';
import { Translations } from '../../translations';


export interface Props {
  statsFilter: StatsFilter
  articleStats?: ArticleStats
}

export interface State {
  groupBy: string
}


export class ArticleChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { groupBy: this.decideGroupBy(props.statsFilter.period) };
  }

  isDailyPeriod(period?: string): boolean {
    if (!period) {
      return false;
    }
    return (/^\d{4}-\d{2}-\d{2}$/.test(period)) //daily
  }

  isYearlyPeriod(period?: string): boolean {
    if (!period) {
      return false;
    }
    return (/^\d{4}$/.test(period)) //yearly
  }
  isPeriodForMonthlyGroup(period?: string): boolean {
    return this.isYearlyPeriod(period) || period == '*';
  }
  decideGroupBy(period?: string): string {
    return this.isPeriodForMonthlyGroup(period) ? 'month' : 'day';
  }

  prepareDataForChart(data: any[]) {
    if (!data) {
      return [];
    }
    const chartData = data.map((d) => {
      return _.mapKeys(d, (value, key) => {
        switch (key) {
          case 'month':
          case 'day':
          case 'hour':
            return 'id';
          case 'views':
            return 'value';
          default:
            return key;
        }
      })
    });
    return chartData;
  }
  groupFilterHandler(e: any) {
    const value = e.target.value;
    this.setState({ groupBy: value });
  }

  drawChart() {
    const { statsFilter, articleStats } = this.props;
    let data;
    const chartGroupBy = this.isDailyPeriod(statsFilter.period) ? 'hour' : (this.state.groupBy ? this.state.groupBy : this.decideGroupBy(statsFilter.period));

    switch (chartGroupBy) {
      case 'hour':
        data = this.prepareDataForChart(articleStats ? articleStats.viewsPerHour : []);
        return <DashBarChart height={300} data={data} key={JSON.stringify(data)} valueName={'article_views'} chart={'viewsPerHour'} /*secondaryValueName={'Reading Time'} secondaryChart={'visitsPerDay'}*/ title={'views'} magazine={1} statsFilter={statsFilter} groupBy={chartGroupBy} />
      case 'day':
        data = this.prepareDataForChart(articleStats ? articleStats.viewsPerDay : []);
        return <DashLineChart height={300} data={data} key={JSON.stringify(data)} valueName={'article_views'} chart={'viewsPerDay'} /*secondaryValueName={'Reading Time'} secondaryChart={'visitsPerDay'}*/ title={'views'} magazine={1} statsFilter={statsFilter} fillMissingValues={'day'} disableAnimation={true} groupBy={chartGroupBy} />
      case 'month':
        data = this.prepareDataForChart(articleStats ? articleStats.viewsPerMonth : []);
        return <DashLineChart height={300} data={data} key={JSON.stringify(data)} valueName={'article_views'} chart={'viewsPerMonth'} /*secondaryValueName={'Reading Time'} secondaryChart={'visitsPerDay'}*/ title={'views'} magazine={1} statsFilter={statsFilter} groupBy={chartGroupBy} />
      default:
        data = this.prepareDataForChart(articleStats ? articleStats.viewsPerDay : []);
        return <DashLineChart height={300} data={data} key={JSON.stringify(data)} valueName={'article_views'} chart={'viewsPerDay'} /*secondaryValueName={'Reading Time'} secondaryChart={'visitsPerDay'}*/ title={'views'} magazine={1} statsFilter={statsFilter} disableAnimation={true} groupBy={chartGroupBy} />

    }
  }

  render() {
    const { groupBy } = this.state;
    const tr = Translations.Translate(Translations, 'GroupFilter');

    return (
      <div id="ArticleChart">
        {Translations.EnableTranslations(Translations)}

        <div className="groupFilter">
          <Label>{tr('groupBy')}</Label>
          {groupBy == 'month' ?
            <Input type="select" defaultValue={groupBy} >
              <option value="month">{tr('month')}</option>
            </Input>
            :
            <Input type="select" value={groupBy} onChange={this.groupFilterHandler.bind(this)} >
              <option value="hour">{tr('hour')}</option>
              {this.isDailyPeriod(this.props.statsFilter.period) ? null :
                <option value="day">{tr('day')}</option>
              }
            </Input>
          }
        </div>
        {this.drawChart()}
      </div>
    );
  }
}

export default ArticleChart;
