import React from 'react'

export interface Props {
  children?: React.ReactNode
}

export default class ErrorBoundary extends React.Component<Props> {
  state = { hasError: false }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    // You can also log the error to an error reporting service
    console.error(error)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return null
    }

    return this.props.children;
  }
}