import React from 'react';
import { Control, useController } from 'react-hook-form';
import { CmsItemFormData } from '../CmsItemDetails';
import { FormGroup, Label } from 'reactstrap';
import { Input } from 'reactstrap';
import { TranslateFunction } from '../../../../../../../domain';
import { Translations } from '../../../../../../translations';
import { MediaHelper } from '../../../../../../utils';
import styles from './CmsFeaturedImage.module.scss';
import classNames from 'classnames';
import { CmsVariationFormData } from '../CmsVariations/CmsVariationDetails';

interface Props {
  magazine: number,
  control: Control<CmsItemFormData | CmsVariationFormData>
  onOpenMediaSelector: () => void
}

export const CmsFeaturedImage = ({ magazine, control, onOpenMediaSelector }: Props) => {

  const { field, fieldState } = useController({ name: 'featuredImage', control });

  const featuredImage = field.value;

  const tr: TranslateFunction = Translations.Translate(Translations, 'Cms');


  return (
    <FormGroup className={styles.CmsFeaturedImage}>
      <Label>{tr('featuredImage')}</Label>
      <div className={styles.wrapper}>
        <Input readOnly className={styles.inputButton} defaultValue={`Click here to set image`} onClick={onOpenMediaSelector} />
        {featuredImage.url &&
          <i className={classNames(styles.emptyIcon, 'material-icons')} onClick={(e) => { e.preventDefault(); e.stopPropagation(); field.onChange({ url: '', alt: '' }); return false; }}>close</i>
        }
      </div>
      <div className={classNames(styles.featuredImage, { [styles.empty]: featuredImage.url ? false : true })}>{!featuredImage.url ? null : <img src={proxifyUrl(magazine, featuredImage.url)} />}</div>
      {featuredImage.url && <Input maxLength={125} style={{ marginTop: '10px' }} type='text' value={featuredImage.alt} onChange={(e) => { field.onChange({ ...featuredImage, alt: e.target.value }) }} placeholder={'Alternative description'} />}
    </FormGroup>
  )
}

const proxifyUrl = (magazine: number, url?: string): string | undefined => {
  if (!url) {
    return undefined;
  }
  return MediaHelper.proxify(magazine, url, 1024, 768);

}