import React, { useEffect, useState } from 'react';
import { API } from '../../../../apis';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import { NlAnalytics } from './domain';

import styles from './NewsletterAnalytics.module.scss'
import _ from 'lodash';

interface Props {
  magazine: number,
  newsletterId: number,
}

export const NewsletterAnalytics = ({ magazine, newsletterId }: Props) => {
  const [analytics, setAnalytics] = useState<NlAnalytics>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loadAnalytics();
  }, [newsletterId]);

  const loadAnalytics = async () => {
    try {
      setLoading(true);
      const { data } = await API.newsletters.getNewsletterAnalytics(magazine, newsletterId);
      setAnalytics(data);
    } catch (error) {
      EdErrorHandler(error, `loading newsletter analytics`);
    } finally {
      setLoading(false);
    }
  }


  return (
    <div className={styles.NewsletterAnalytics}>
      {loading ?
        <OrtecLoader size={'icon'} />
        :
        <>
          <div className={styles.analyticsRow} style={{ marginBottom: '8px' }}><div className={styles.headerDiv}>Sent:</div><div className={styles.valueDiv}>{analytics?.sent ?? '-'}</div></div>
          <div className={styles.analyticsRow}><div className={styles.headerDiv}>Unsubscribers:</div><div className={styles.valueDiv}>{analytics?.unsubscribed ?? '-'}</div></div>
          <hr />
          <div className={styles.analyticsRow}><div className={styles.headerDiv}>Clicks:</div><div className={styles.valueDiv}>{analytics?.clicked ?? '-'}</div></div>
          <div className={styles.articleAnalytics}>
            {_.map(analytics?.articleClicks, (a) => {
              return (
                <div key={a.id} className={styles.articleAnalyticsRow}>
                  <div className={styles.articleTitle}>{a.title}</div>
                  <div className={styles.articleClicks}>{a.clicks}</div>
                </div>
              )
            })}
          </div>
        </>
      }
    </div>
  )
}
