import React, { CSSProperties, Fragment, useMemo, useState } from 'react';
import { Profile } from "../../../../../../domain";
import { Label, Input, Button } from 'reactstrap';
import _ from 'lodash';

import styles from './CmsFeedSelector.module.scss';
import { CmsFeedDetails } from './CmsFeedDetails';
import { EdRole } from '../../../../../accessRights/domain';
import { AccessRightsHelper, FeedsHelper } from '../../../../../utils';
import { FeedBasicProps } from '../CmsDomain';
import { TippyReact } from '../../../../../components';

const ROLES_WITH_EDIT_ACCESS_RIGHTS: EdRole[] = ['admin', 'division admin', 'editor'];

export interface Props {
    magazine: string,
    profile: Profile,
    feeds: FeedBasicProps[],
    activeFeed: number,
    setFeed: (feed: number) => void,
    updateProfile: () => void,
    cssStyle?: CSSProperties,
}


export const CmsFeedSelector = (props: Props) => {
    const { magazine, profile, feeds, activeFeed, setFeed, updateProfile } = props;
    const [showModalOfFeed, setShowModalOfFeed] = useState<number>();

    const hasEditRights: boolean = _.includes(ROLES_WITH_EDIT_ACCESS_RIGHTS, AccessRightsHelper.getUserRole(profile));

    const articleRetentionOfActiveText: string | undefined = useMemo(() => {
        const active = _.find(feeds, (f) => f.id === activeFeed);

        if (_.get(active?.articleRetention, 'maxNumber')) {
            return `Max number is ${_.get(active?.articleRetention, 'maxNumber')}`;
        }
        if (_.get(active?.articleRetention, 'maxAgeDays')) {
            return `Max age days is ${_.get(active?.articleRetention, 'maxAgeDays')}`;
        }
        return undefined;

    }, [feeds, activeFeed])

    const openFeedModal = (feedId: number) => {
        setShowModalOfFeed(feedId);
    }

    const closeFeedModal = (changeToFeed?: number) => {
        setShowModalOfFeed(undefined);
        if (changeToFeed) {
            setFeed(changeToFeed);
        }
    }

    return (
        <Fragment>
            <div className={styles.CmsFeedSelector} style={{ ...props.cssStyle }}>
                <div className={styles.flexRow} style={{ marginTop: '5px' }}>
                    <Label>Current Feed:</Label>
                    <div className={styles.selectInputWrapper}>
                        <Input type="select" value={_.toString(activeFeed)} disabled={feeds.length > 1 ? false : true} onChange={(e) => { setFeed(_.toNumber(e.target.value)) }}>
                            {_.map(feeds, (f) => {
                                return <option key={`sel-${f.id}`} value={f.id}>{FeedsHelper.calcFeedLabel(magazine, f)}</option>
                            })}
                        </Input>
                        {articleRetentionOfActiveText &&
                            <TippyReact content={`Article Retention enabled: ${articleRetentionOfActiveText}`}>
                                <div className={styles.warningIcon}>
                                    <i className='fa fa-warning'></i>
                                </div>
                            </TippyReact>
                        }
                    </div>
                    {hasEditRights &&
                        <Fragment>
                            <Button className={styles.editButton} onClick={() => openFeedModal(activeFeed)}>edit</Button>
                            <div className={styles.flexSeparator}></div>
                            <Button className={styles.createButton} onClick={() => openFeedModal(-1)}>create new feed</Button>
                        </Fragment>
                    }

                </div>
            </div>
            {showModalOfFeed && <CmsFeedDetails magazine={_.toNumber(magazine)} profile={profile} feedId={showModalOfFeed} close={closeFeedModal} updateProfile={updateProfile} articleRetentionWarning={articleRetentionOfActiveText} />}
        </Fragment>
    )
}
