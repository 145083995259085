import React, { useEffect, useState } from 'react';
import { NlArticle, NlSlotItem_article, NlTemplateConfiguration_Content } from './domain';

import styles from './NewsletterContentArticle.module.scss';
import classNames from 'classnames';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import { API } from '../../../../apis';
import { ArticlePreview } from './preview/components/ArticlePreview';

interface Props {
  magazine: number,
  templateConfiguration: NlTemplateConfiguration_Content
  oneColumn?: boolean
  // rowIndex: number,
  // columnIndex: NlLayoutColumnIndex,
  slotItem: NlSlotItem_article
  onClick: () => void

}

export const NewsletterContentArticle = ({ magazine, templateConfiguration, oneColumn, slotItem, onClick }: Props) => {

  const [article, setArticle] = useState<NlArticle>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadArticleDetails();
  }, [slotItem.article])


  const loadArticleDetails = async () => {
    try {
      setLoading(true);
      const { data } = await API.articles.getArticleDetails(magazine, slotItem.article);
      setArticle({ ...data, magazine });
    } catch (error) {
      EdErrorHandler(error, `loading article`);
    } finally {
      setLoading(false);
    }
  }


  return (
    <div className={classNames(styles.NewsletterContentArticle)} onClick={onClick}>
      {loading ? <OrtecLoader size={'icon'} /> :
        <>
          {/* TODO: Leave it here for the MVP period  and then delete */}
          {/* <div className={styles.metaInfo}>a: {slotItem.article} | s: {slotItem.source}</div>   */}
          <ArticlePreview
            configuration={templateConfiguration}
            oneColumn={oneColumn}
            article={article}
          />
        </>
      }
    </div>

  )

}
