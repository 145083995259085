import React from 'react';
import Datepicker from 'react-datepicker3';
import "react-datepicker3/dist/react-datepicker.css";
import moment from 'moment';

import styles from './DatePicker.module.scss';
import { Input } from 'reactstrap';
import { DateHelper } from '../../utils';

interface Props {
  period: string,
  changePeriod: (period: string) => void
  minPeriod?: string
  maxPeriod?: string
  readOnly?: boolean
}

export const YearlyDatePicker = ({ period, changePeriod, minPeriod, maxPeriod, readOnly }: Props) => {

  const mode = 'yearly';
  const placeHolderUnitText = 'year';
  const dateFormat = 'yyyy';
  const momentFormat = 'YYYY';

  const maxDate = () => {
    if (!maxPeriod) {
      return moment().subtract(1, 'day').toDate();
    }
    try {
      return moment(maxPeriod, momentFormat).toDate()
    } catch (error) {
      return moment().subtract(1, 'day').toDate();
    }
  }

  const minDate = () => {
    if (!minPeriod) {
      return moment('2010-01-01', momentFormat).toDate();
    }
    try {
      return moment(minPeriod, momentFormat).toDate()
    } catch (error) {
      return moment('2010-01-01', momentFormat).toDate();
    }
  }

  return (
    <div className={styles.DatePicker}>
      <Datepicker
        useWeekdaysShort
        dateFormat={dateFormat}
        minDate={minDate()}
        maxDate={maxDate()}
        selected={period ? moment(period, momentFormat).toDate() : null}
        readOnly={readOnly}
        isClearable={readOnly ? false : true}
        placeholderText={`Select specific ${placeHolderUnitText}..`}
        customInput={<Input type={'text'} readOnly />}
        onChange={(d: Date, e: any) => {
          changePeriod(DateHelper.dateToString(d, momentFormat));
        }}
        onChangeRaw={(e) => {
          if (e) {
            e.preventDefault();
            e.stopPropagation();
          }
          return false;

        }}
        popperClassName={mode}
        popperPlacement={'top'}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "-3px, -20px"
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport"
          }
        }}
        // startDate={null}
        // endDate={null}
        // showWeekNumbers={false}
        // showMonthYearPicker={true}
        showYearPicker={true}
        shouldCloseOnSelect={true}
        showMonthDropdown
        showYearDropdown
        dropdownMode={"select"}
      // onCalendarOpen={handleCalendarOpen}
      // onCalendarClose={handleCalendarClose}
      />
    </div>
  )
}
