import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
import { Translations } from '../../../../../../translations';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DateHelper } from '../../../../../../utils';
import moment from 'moment';

export interface Props {
    publicationDate: Date | null,
    onChange: (d: Date | null) => void
}

export const CmsPublishingDate = (props: Props) => {
    const { publicationDate, onChange } = props;
    const tr = Translations.Translate(Translations, 'Cms');

    return (
        <FormGroup>
            <Label>{tr('publishingDate')}</Label>
            <div className={'inputButtonWrapper publishingDateWrapper'}>
                <Datepicker
                    dateFormat={`${DateHelper.dailyDatePickerFormatBasedOnLocale()} - HH:mm`}
                    timeFormat="HH:mm"
                    timeInputLabel="Time:"
                    selected={publicationDate}
                    isClearable={true}
                    placeholderText={tr('Now')}
                    customInput={<Input type={'text'} placeholder={tr('sendImmediately')} readOnly />}
                    onChange={(d: Date, e: any) => {
                        if (d) {
                            try {
                                const m = moment(d);
                                if (m.isValid()) {
                                    if (!d) {
                                        m.hours(8);
                                    }
                                    onChange(new Date(m.toISOString()))
                                }
                            } catch (error) {
                                onChange(null);
                            }
                        } else {
                            onChange(null);
                        }
                        if (e) {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                        return false;
                    }}
                    showTimeInput
                    showMonthDropdown
                    showYearDropdown
                    calendarClassName={publicationDate ? '' : 'hideTimeInput'}
                    shouldCloseOnSelect={publicationDate ? false : true}
                    dropdownMode="select"
                    popperProps={{
                        positionFixed: true
                    }}
                />
            </div>
        </FormGroup>
    )
}