import { AxiosPromise } from 'axios';
import { dashApi } from "../api";
import { PluginWithEntrypoints } from '../pages/Advanced/subpages/Channels/ChannelInfoPairs/pluginsDomain';

export const getPlugins = (magazine: number): AxiosPromise<Array<PluginWithEntrypoints>> => {
  return dashApi.get(magazine, `plugins`)
}

export const getPluginUrl = (magazine: number, plugin: number, entrypoint: number, uid: string): AxiosPromise<string> => {
  return dashApi.post(magazine, `plugins`, { plugin, entrypoint, uid });
}