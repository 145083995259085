import _ from "lodash";
import React from "react";
import { Source } from "../../domain";
import { EdConfigSettings } from "../../domainEdConfig";
import { ArticleSource } from "../pages/Advanced/subpages/Articles/domain";
import { SourceWithFeedInfo } from "../pages/Advanced/subpages/Sources/Sources.domain";

export function sourceTypeFormatter(srctype: string, row: SourceWithFeedInfo) {
  return <>{sourceTypeFilterValue(srctype, row)}</>;
}

export function sourceTypeFilterValue(srctype: string, row: SourceWithFeedInfo): string {
  if (_.endsWith(srctype, '-feed')) {
    return row.feedInfo?.feedDisplayName || row.feedInfo?.feedName || _.capitalize(srctype);
  }

  return _.capitalize(srctype);
}

export function sourceEdConfigName(s: Source | ArticleSource, edConfig?: EdConfigSettings): JSX.Element {

  if (!edConfig?.sourceGraphName) {
    return sourceLabelWithNameFallback(s);
  }
  switch (edConfig.sourceGraphName) {
    case 'nameOnly':
      return sourceNameWithLabelFallback(s);
    case 'labelOnly':
      return sourceLabelWithNameFallback(s);
    case 'nameLabel':
      return <>{sourceNameWithLabelFallback(s)} ({sourceLabelWithNameFallback(s)})</>;
    case 'labelName':
      return <>{sourceLabelWithNameFallback(s)} ({sourceNameWithLabelFallback(s)})</>;
  }
}

const sourceNameWithLabelFallback = (s: Source | ArticleSource): JSX.Element => {
  return <>{s.name || (s.label ? <i>{s.label}</i> : '')}</>
}

const sourceLabelWithNameFallback = (s: Source | ArticleSource): JSX.Element => {
  return <>{s.label || (s.name ? <i>{s.name}</i> : '')}</>
} 