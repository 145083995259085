import React, { useEffect, useState } from 'react';

interface Props {
  imageFile: File | Blob | undefined,
  size?: number
}

export const ImagePreviewIcon = ({ imageFile, size }: Props) => {

  const [objectURL, setObjectURL] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!imageFile) {
      setObjectURL(undefined);
      return;
    }
    const objUrl = URL.createObjectURL(imageFile);
    setObjectURL(objUrl);
    return () => {
      URL.revokeObjectURL(objUrl);
      setObjectURL(undefined);
    }
  }, [imageFile])


  const imgSrc = objectURL || '';

  return (
    <img
      src={imgSrc}
      width={size || 40}
      height={size || 40}
      style={{
        overflow: 'hidden',
        objectFit: 'scale-down'
      }}
    />
  )
}
