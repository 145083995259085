import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Notification, Article, Profile } from '../../../../../domain';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import './css/NotificationInfo.min.css';
import * as _ from 'lodash';
import * as Api from '../../../../api';
import { Translations } from '../../../../translations';
import { DateHelper } from '../../../../utils';

export interface Props {
  profile: Profile,
  notification?: Notification,
  closeHandler: (updateOverview?: boolean) => void
}
export interface State {
  article?: Article,
  targetedAudiences?: string[],
  notification?: Notification

}


export class NotificationInfo extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      notification: props.notification
    };
  }
  statusFormatter(status: number) {
    const tr = Translations.Translate(Translations, 'PushNotifications');

    switch (status) {
      case 9:
        return (<span><i className="fa fa-circle status-sent" aria-hidden="true"></i> {tr("sent")}</span>);
      case 1:
        return (<span><i className="fa fa-circle status-scheduled" aria-hidden="true"></i> {tr("scheduled")}</span>);
      case 0:
        return (<span><i className="fa fa-circle status-notSent" aria-hidden="true"></i> {tr("cancelled")}</span>);
      default:
        return status.toString();
    }

  }
  componentDidMount() {
    const { notification, profile } = this.props;
    const tr = Translations.Translate(Translations, 'PushNotifications');

    if (!notification) {
      return;
    }
    const articleId = this.extractArticleId(notification);
    if (articleId) {
      Api.getArticleTitle(notification.magazine.id, articleId).then((resp) => {
        if (!resp.data || resp.data.error) {
          console.log(`Error in getting article title`);
          this.setState({ article: undefined });
        }
        this.setState({ article: resp.data })
      })
        .catch((error) => {
          console.log('An error occured:' + error.message)
        })
    }
    const targetedAudiences = _.map(notification.audiences, (a) => {
      if (a == 1) {
        return tr('all_employees');
      }
      const audience = _.find(profile.audiences, (aud) => aud.id == a);
      return audience ? audience.label : null;
    });
    this.setState({ targetedAudiences: _.compact(targetedAudiences) });
    // const audiencesQueries = this.extractTargetedAudiencesQueries(notification);

    // if(!_.isEmpty(audiencesQueries)){      
    //   Api.getAudiencesByQueries(notification.magazine.id, audiencesQueries).then((resp)=>{
    //     if(!resp.data || resp.data.error){
    //       console.log(`Error in getting audiences by quires title`);
    //       this.setState({targetedAudiences:undefined});
    //     }
    //     this.setState({targetedAudiences:resp.data})
    //   }) 
    //   .catch((error) => {
    //     console.log('An error occured:' + error.message)
    //   })
    // }
  }
  cancelScheduling(notification: Notification) {
    Api.cancelNotification(notification.magazine.id, notification.id).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log(`An error occured: ${resp.data ? resp.data.error : 'Resp data not found'}`);
        alert(`An error occured: ${resp.data ? resp.data.error : 'Resp data not found'}`);
        return;
      }
      this.setState({ notification: { ...notification, status: 0 } }, () => this.props.closeHandler(true));
    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
      });
  }
  dateFormatter(date: string) {
    return DateHelper.dateTimeToLocaleString(date);
  }
  sentFormatter(notification: Notification) {
    const tr = Translations.Translate(Translations, 'PushNotifications');

    if (notification.scheduledOn === notification.cdate) {
      return tr('immediately')
    }
    return (<div><span style={{ verticalAlign: 'middle' }}>{this.dateFormatter(notification.scheduledOn)}</span> {(notification.status != 1) ? '' : <button onClick={(e) => this.cancelScheduling(notification)} type="button" className="cancelScheduling btn btn-secondary">{/*<i className="material-icons">close</i>*/} {tr('cancelScheduling')}</button>}</div>);
  }
  articleRowRenderer(articleUniversalLink: string, article?: Article,) {
    const tr = Translations.Translate(Translations, 'PushNotifications');
    return (<tr><td>{tr('article')}:</td><td>{!article ? '' : <a target='_blank' href={articleUniversalLink}>{article.title}</a>}</td></tr>)
  }
  audiencesRowRenderer(targetedAudiences?: string[], otherAudiences?: number) {
    const tr = Translations.Translate(Translations, 'PushNotifications');
    let targetedString = '';
    if (targetedAudiences) {
      targetedString = targetedAudiences.join();
      if (otherAudiences) {
        targetedString += ` and ${otherAudiences} other audience${otherAudiences !== 1 ? 's' : ''}`;
      }
    }

    return (<tr><td>{tr('targetedAudiences')}:</td><td>{targetedString}{this.props.notification?.combineAudiencesWithAnd ? ' (User must be part of all selected audiences)' : ''}</td></tr>)
  }
  urlRowRenderer() {
    const { notification } = this.state;
    const { article } = this.state;
    if (!notification) {
      return '';
    }
    var url = notification.url;
    if (!url) {
      return (<tr><td>URL:</td><td>-</td></tr>);
    }

    const articleUniversalLink = this.extractArticleUniversalLink(notification);
    if (articleUniversalLink) {
      return this.articleRowRenderer(articleUniversalLink, article);
    }

    const prefix = 'http://';
    if (url.substr(0, prefix.length) !== prefix) {
      url = prefix + url;
    }
    return (<tr><td>URL:</td><td><a target='_blank' href={url}>{url}</a></td></tr>)
  }
  targetRowRenderer() {
    const tr = Translations.Translate(Translations, 'PushNotifications');

    const { notification } = this.state;
    const { targetedAudiences } = this.state;
    if (!notification) {
      return '';
    }
    var target = notification.target;
    if (!target) {
      return (<tr><td>{tr('target')}:</td><td>-</td></tr>);
    }

    const queries = this.extractTargetedAudiencesQueries(notification);

    if (!_.isEmpty(queries)) {
      const otherAudiences = notification.audiences.length - (targetedAudiences?.length || 0)
      return this.audiencesRowRenderer(targetedAudiences, otherAudiences);
    }

    return (<tr><td>{tr('target')}:</td><td>{target}</td></tr>);
  }
  extractArticleUniversalLink(notification: Notification): string | null {
    const articleId = this.extractArticleId(notification);
    return articleId ? `${notification.magazine.baseUrl}/a/${articleId}` : null;
  }
  extractArticleId(notification: Notification): number | null {
    try {
      var url = notification.url;
      if (!url) {
        return null;
      }
      const split = url.split('|');

      if (split.length !== 2) {
        return null;
      } else {
        const articleId = split[0];
        return _.toNumber(articleId)
      }
    } catch (error) {
      return null;
    }

  }
  extractTargetedAudiencesQueries(notification: Notification): any[] {
    try {
      var target = notification.target;
      if (!target) {
        return [];
      }
      const targetJson = JSON.parse(target);

      if (!_.isEmpty(targetJson['$or'])) {
        return targetJson['$or'];
      } else if (!_.isEmpty(targetJson['$and'])) {
        return targetJson['$and'];
      }
      return [];


    } catch (error) {
      return [];
    }

  }
  render() {
    const { notification } = this.state;
    // const data = [articleStats]
    const tr = Translations.Translate(Translations, 'PushNotifications');

    return (
      <div id="NotificationInfo">
        {Translations.EnableTranslations(Translations)}
        {/* <div className='header'>Information <i className='fa fa-info-circle' data-tippy-content="Shown time here reflects to your local time"></i></div> */}
        <div className="content">
          {!notification ?
            <div>{tr('noInfo')}</div>
            :
            <Fragment>
              <div className="notificationInfoContainer">
                <table className="notificationInfoTable">
                  <tbody>
                    {/* <tr><td>ID:</td><td>{notification.id}</td></tr> */}
                    {/* <tr><td>Magazine:</td><td>{notification.magazine}</td></tr> */}
                    <tr><td>{tr('message')}:</td><td>{notification.message}</td></tr>
                    {/* <tr><td>URL:</td><td>{this.urlFormatter(notification)}</td></tr> */}
                    {this.urlRowRenderer()}
                    <tr><td>{tr('created')}: </td><td>{this.dateFormatter(notification.cdate)}</td></tr>
                    <tr><td>{(notification.status == 9) ? `${tr('sent')}:` : `${tr('scheduledFor')}:`} </td><td>{this.sentFormatter(notification)}</td></tr>
                    {this.targetRowRenderer()}
                    <tr><td>{tr('status')}: </td><td>{this.statusFormatter(notification.status)}</td></tr>
                  </tbody>
                </table>
              </div>
              {/* <Button onClick={this.toggleArticleStatus.bind(this)} className="hideArticleButton" color="primary">{article.sourcesStatus?'hide':'show'} this article</Button> */}
            </Fragment>
          }
        </div>
      </div>
    );
  }
}

export default NotificationInfo;
