import React, { useMemo, useState } from 'react';
import styles from './MediaManager.module.scss';
import { Media, Profile } from '../../../domain';
import _ from 'lodash';
import { Bucket, BucketPart } from './domain';
import { AccessRightsHelper } from '../../utils';
import MediaManagerBucketSelectPane from './MediaManagerBucketSelectPane';
import { createDeleteDialog, useBucketMedia, useDeleteMediaMutation, useMagazineFeedBuckets } from './MediaManagerSelectorHelper';
import useMagazineBuckets from './useMagazineBuckets';
import MediaBucketExplorer from './MediaBucketExplorer/MediaBucketExplorer';


function defaultBucketPartForBucket(activeBucketObj: Bucket | undefined): 'protected' | 'public' {
  return activeBucketObj?.useOnlyPart || 'public'
}


interface Props {
  magazine: number
  profile: Profile
}

export default function MediaManager({ magazine, profile }: Props) {

  // hooks

  const adminRole = useMemo(() => AccessRightsHelper.getUserRole(profile) == 'admin', [profile])
  const [activeBucket, setActiveBucket] = useState<string>(adminRole ? _.toString(magazine) : `${magazine}_cms`);

  const magazineFeedBucketsQ = useMagazineFeedBuckets(magazine)

  const buckets = useMagazineBuckets(magazine, profile, magazineFeedBucketsQ.data)
  const activeBucketObj = useMemo(() => buckets.find((dB) => dB.key == activeBucket), [activeBucket, buckets]);
  const [activeBucketPart, setActiveBucketPart] = useState<BucketPart>(defaultBucketPartForBucket(activeBucketObj));

  const disablePart = useMemo(() => activeBucketObj?.useOnlyPart ? true : false, [activeBucketObj?.useOnlyPart])

  const allMediaQ = useBucketMedia(magazine, activeBucket, activeBucketPart)
  const allMedia = allMediaQ.data || []

  const deleteMediaMutation = useDeleteMediaMutation(magazine, activeBucket, activeBucketPart, allMedia)
  const loading = magazineFeedBucketsQ.isFetching || allMediaQ.isFetching || deleteMediaMutation.isLoading


  // handlers

  const onBucketChange = (newBucket: string) => {
    const newBucketObj = buckets.find((dB) => dB.key === newBucket);
    setActiveBucket(newBucket);
    setActiveBucketPart(defaultBucketPartForBucket(newBucketObj));
  }


  const onDeleteMedia = async (med: Media) => {
    const ok = await createDeleteDialog(med)
    if (ok) {
      deleteMediaMutation.mutate(med)
    }
  }


  return (
    <div className={styles.paddinTop20}>
      <MediaManagerBucketSelectPane
        buckets={buckets}
        activeBucket={activeBucket}
        activeBucketPart={activeBucketPart}
        onBucketChange={onBucketChange}
        disablePart={disablePart}
        onPartChange={(val) => { setActiveBucketPart(val) }}
      />

      <MediaBucketExplorer
        key={[activeBucket, activeBucketPart].join('_')}
        bucket={activeBucket}
        part={activeBucketPart}
        allMedia={allMedia}
        magazine={magazine}
        loading={loading}
        listModeDefault={true}
        onMediaDelete={onDeleteMedia}
      />
    </div>
  )
}