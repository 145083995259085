import _ from 'lodash';
import React, { KeyboardEventHandler } from 'react'
import Select, { MenuPosition, StylesConfig } from 'react-select';
import Creatable from 'react-select/creatable';

export interface SelectOption<T extends string | number = string> {
  value: T,
  label: string,
  isDisabled?: boolean
}

interface BaseSelectProps<T extends string | number = string> {
  invalid?: boolean
  options: SelectOption<T>[]
  isDisabled?: boolean
  isClearable?: boolean
  placeholder?: string;
  menuPosition?: MenuPosition
  // styles?: Partial<StylesConfig> | undefined
}

interface SingleSelectProps<T extends string | number = string> extends BaseSelectProps<T> {
  onChange: (o: T | undefined) => void,
  value: T | undefined
}

interface MultiSelectProps<T extends string | number = string> extends BaseSelectProps<T> {
  onChange: (o: T[]) => void,
  value: T[]

}

export function SingleSelect<T extends string | number = string>(props: SingleSelectProps<T>) {
  return <Select
    {...props}
    styles={getStyles(props.invalid)}
    isMulti={false}
    value={props.value === undefined ? null : props.options.find(o => o.value === props.value) || string2SelectOption(props.value)}
    onChange={(v: SelectOption<T> | null) => { props.onChange(v === null ? undefined : v.value) }}
  />
}
export function NoSearchableSingleSelect<T extends string | number = string>(props: SingleSelectProps<T>) {
  return <Select
    {...props}
    styles={getStyles(props.invalid)}
    isSearchable={false}
    isMulti={false}
    value={props.value === undefined ? null : props.options.find(o => o.value === props.value) || string2SelectOption(props.value)}
    onChange={(v: SelectOption<T> | null) => { props.onChange(v === null ? undefined : v.value) }}
  />
}
export function MultiSelect<T extends string | number = string>(props: MultiSelectProps<T>) {
  return <Select
    {...props}
    styles={getStyles(props.invalid)}
    isMulti={true}
    value={props.value.map((v): SelectOption<T> => props.options.find(o => o.value === v) || string2SelectOption(v))}
    onChange={(v: SelectOption<T>[]) => { props.onChange(v.map(selectOption2String)) }}
  />
}

export function CreatableSingleSelect<T extends string | number = string>(props: SingleSelectProps<T> & {
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
}) {
  return <Creatable
    {...props}
    styles={getStyles(props.invalid)}
    isMulti={false}
    value={props.value === undefined ? null : props.options.find(o => o.value === props.value) || string2SelectOption(props.value)}
    onChange={(v: SelectOption<T> | null) => { props.onChange(v === null ? undefined : v.value) }}
  />
}

export function CreatableMultiSelect<T extends string | number = string>(props: MultiSelectProps<T> & {
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
}) {
  return <Creatable
    {...props}
    styles={getStyles(props.invalid)}
    isMulti={true}
    value={props.value.map((v): SelectOption<T> => props.options.find(o => o.value === v) || string2SelectOption(v))}
    onChange={(v: SelectOption<T>[]) => { props.onChange(v.map(selectOption2String)) }}
  />
}

function getStyles<T extends string | number>(invalid?: boolean): StylesConfig<SelectOption<T>> | undefined {
  return invalid
    ? { container: () => { return { border: '1px solid red', borderRadius: '5px' } } }
    : undefined
}

/**
 * same as CreatableMultiSelect but without dropdown options
 */
export function CreatableMultiTextInput(props: Omit<MultiSelectProps<string>, 'options'>) {
  const [inputValue, setInputValue] = React.useState('');

  const handleKeyDown: KeyboardEventHandler = (event) => {

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault();
        event.stopPropagation();
        if (!inputValue || _.includes(props.value, inputValue)) {
          return;
        }
        setInputValue('');
        props.onChange([...props.value, inputValue]);

    }
  };
  return <Creatable
    {...props}
    isMulti={true}
    value={props.value.map((v): SelectOption<string> => string2SelectOption(v))}
    onChange={(v: SelectOption<string>[]) => { props.onChange(v.map(selectOption2String)) }}
    onInputChange={(newValue) => { setInputValue(newValue) }}
    inputValue={inputValue}
    components={{ DropdownIndicator: null }}
    menuIsOpen={false}
    onKeyDown={handleKeyDown}
  />
}


// helper functions for simple cases where value === label

export function selectOption2String<T extends string | number = string>(o: SelectOption<T>): T {
  return o.value
}

export function string2SelectOption<T extends string | number = string>(s: T): SelectOption<T> {
  return { value: s, label: String(s) }
}