import React, { Component } from 'react';
import { Input, Label } from 'reactstrap';
import moment from 'moment';
import { StatsFilter } from '../../../domain';

import './css/GroupFilter.min.css';
import { Translations } from '../../translations';


export interface Props {
  groupBy: string,
  groupFilterHandler: (groupBy: string) => void,
  isSinceAppStartEnabled: boolean
  isDaily: boolean
}

export class GroupFilter extends Component<Props> {



  render() {
    const { groupBy, groupFilterHandler, isSinceAppStartEnabled, isDaily } = this.props;
    const tr = Translations.Translate(Translations, 'GroupFilter');

    return (
      <div id="GroupFilter">
        {Translations.EnableTranslations(Translations)}
        <div className="groupFilter">
          <Label>{tr('groupBy')}</Label>
          <Input type="select" value={isDaily ? 'hour' : groupBy} onChange={(e) => { groupFilterHandler(e.target.value) }}>
            <option value="hour">{tr('hour')}</option>
            {!isDaily && <option value="weekday">{tr('weekdays')}</option>}
            {isDaily ? null :
              !isSinceAppStartEnabled ?
                <option value="day">{tr('days')}</option>
                :
                <option value="month">{tr('month')}</option>
            }
          </Input>
        </div>
      </div>
    );
  }
}

export default GroupFilter;
