import React, { Fragment } from 'react';
import Toggle from 'react-toggle';
import { Input } from 'reactstrap';
import { ColorPicker, TippyReact } from '../../../../../components';
import { FontSize, TextWithUI } from '../domain';
import styles from '../NewsletterTemplatesetDetails.module.scss';
import TextWithUIComponentStyles from './TextWithUIComponent.module.scss';

interface Props {
  name: string,
  configuration: TextWithUI,
  placeholder?: string
  longText?: boolean
  noText?: boolean
  noTextUI?: boolean
  disableTextUI?: boolean
  noBackgroundColor?: boolean
  warningText?: string
  onChange: (configuration: TextWithUI) => void
}

export const TextWithUIComponent = ({ name, configuration, placeholder, longText, noText, noTextUI, disableTextUI, noBackgroundColor, warningText, onChange }: Props) => {
  return (
    <Fragment>
      <div className={styles.toggleRow}>
        <div className={styles.label}>
          {name}
          {warningText &&
            <TippyReact content={warningText}>
              <div className={TextWithUIComponentStyles.warningTextWrapper}><i className='fa fa-warning'></i></div>
            </TippyReact>
          }
        </div>
        <Toggle checked={configuration.enabled} onChange={(e) => { onChange({ ...configuration, enabled: e.target.checked }) }} />
      </div>
      {(configuration.text === undefined || noText) ? null :
        (longText ?
          <div className={styles.flexRow}>
            <Input disabled={!configuration.enabled} type={'textarea'} placeholder={placeholder} rows={5} value={configuration.text} onChange={(e) => { onChange({ ...configuration, text: e.target.value }) }} />
          </div>
          :
          <div className={styles.flexRow}>
            <div className={styles.inlineLabel}>Text:</div>
            <Input disabled={!configuration.enabled} placeholder={placeholder} value={configuration.text} onChange={(e) => { onChange({ ...configuration, text: e.target.value }) }} />
          </div>
        )}
      {!noTextUI &&
        <div className={styles.flexRow}>
          {!noBackgroundColor &&
            <div style={{ flex: 1 }}>
              <div className={styles.label}>Background colour:</div>
              <ColorPicker disabled={disableTextUI ?? !configuration.enabled} color={configuration.backgroundColor} onChange={(c) => { onChange({ ...configuration, backgroundColor: c.toString() }) }} />
            </div>
          }
          <div style={{ flex: 1 }}>
            <div className={styles.label}>Text colour:</div>
            <ColorPicker disabled={disableTextUI ?? !configuration.enabled} color={configuration.textColor} onChange={(c) => { onChange({ ...configuration, textColor: c.toString() }) }} />
          </div>
          <div style={{ flex: 1 }}>
            <div className={styles.label}>Font size:</div>
            <Input disabled={disableTextUI ?? !configuration.enabled} type={'select'} value={configuration.fontSize} onChange={(e) => { onChange({ ...configuration, fontSize: e.target.value as FontSize }) }}>
              <option value={'small'}>Small</option>
              <option value={'medium'}>Medium</option>
              <option value={'large'}>Large</option>
            </Input>
          </div>
        </div>
      }
    </Fragment>
  )
}
