// NOTE: Code taken as it is from AD Templates

import { FontSize } from "../domain";

//Found this regEx -> https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

//Found this regEx -> https://stackoverflow.com/questions/42407785/regex-extract-email-from-strings
const mailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;


const sanitize = (e: string) => {
    return (e.replace(/<.+?>/g, (c: string) => {
        return '';
    }))

}

const handleNewLines = (e: string): string => {
    return e.replace(/(?:\r\n|\r|\n)/g, function (url: string) {
        return '<br/>';
    });
}

const handleMail = (e: string, color: string): string => {
    return e.replace(mailPattern, function (url: string) {
        return `<a style="color: ${color};" href="mailto:${url}">${url}</a>`
    })
}

export const handleText = (e: string | undefined, color: string) => {
    if (!e) {
        return '';
    }
    e = sanitize(e);
    e = handleNewLines(e);
    e = handleMail(e, color);
    return e.replace(urlRegex, function (url: string) {
        return '<a style="color: ' + color + ';" href="' + url + '">' + url + '</a>';
    });
};

export const fontSizeToPixels = (fontSize: FontSize): number => {
    switch (fontSize) {
        case 'small':
            return 14;
        case 'medium':
            return 18;
        case 'large':
            return 24;
    }
}