import React from 'react';
import styles from './NcSettingsConfiguration.module.scss';
import { NcSettingsConfigurationRow } from '../../../components/NcSettingsConfigurationRow';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { NcSettingsFormData } from './NcSettingsConfiguration';
import { NcSettingsFontSelect } from '../../../components/NcSettingsFontSelect';
import { NcSettingsToggle } from '../../../components/NcSettingsToggle';
import { NcSettingsColorInput } from '../../../components/NcSettingsColorInput';
import { NcSettingsNumberInput } from '../../../components/NcSettingsNumberInput';
import { NcSettingsImageSelect } from '../../../components/NcSettingsImageSelect';
import { Profile } from '../../../../../../domain';
import { ChannelsSelect } from '../../../../../components';
import { Channel } from '../../../../../components/ChannelsSelect/ChannelsSelect';
import { FormText } from 'reactstrap';

interface Props {
  magazine: number,
  profile: Profile
  control: Control<NcSettingsFormData, any>,
  errors: FieldErrors<NcSettingsFormData>
  channels: Channel[]
}

const seperatorIconText = `
The Separator icon is displayed between articles in the bottom news ticker. 
The image should not be bigger than 120x60 pixels. 
If the icon you want to use is square, make sure it's 60x60.
`;

export const NcSettingsConfigurationTicker = ({ magazine, profile, control, errors, channels }: Props) => {
  return (
    <div>
      <NcSettingsConfigurationRow label={`Channel:`}>
        <Controller
          name={'ticker.channelId'}
          control={control}
          render={({ field }) => {
            return (
              <div className={styles.channelSelectWrapper}>
                <ChannelsSelect
                  channels={channels}
                  selected={field.value || ''}
                  onChange={(id: string) => {
                    field.onChange(id);
                  }}
                  channelGraphName={profile.edConfig?.channelGraphName}
                />
              </div>
            )
          }}
        />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Count:`}>
        <NcSettingsNumberInput {...{ control }} fieldName={'ticker.articleCount'} suffix={'articles'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Enabled by default:`}>
        <NcSettingsToggle {...{ control }} fieldName={'ticker.defaultEnabled'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Show date:`}>
        <NcSettingsToggle {...{ control }} fieldName={'ticker.showDate'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Background:`}>
        <NcSettingsColorInput {...{ control }} fieldName={'ticker.backgroundColor'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Separator icon:`} imageSelectRow>
        <NcSettingsImageSelect {...{ magazine, profile, control }} fieldName='ticker.separatorIconUri'
          text={seperatorIconText}
        />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Text font:`}>
        <NcSettingsColorInput {...{ control }} fieldName='ticker.textFont.color' />
        <NcSettingsFontSelect {...{ magazine, control }} fieldName='ticker.textFont.fontName' flex1 />
        <NcSettingsNumberInput {...{ control }} fieldName='ticker.textFont.size' suffix='px' />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Meta font:`}>
        <NcSettingsColorInput {...{ control }} fieldName='ticker.metaFont.color' />
        <NcSettingsFontSelect {...{ magazine, control }} fieldName='ticker.metaFont.fontName' flex1 />
        <NcSettingsNumberInput {...{ control }} fieldName='ticker.metaFont.size' suffix='px' />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Show current time:`}>
        <NcSettingsToggle {...{ control }} fieldName={'ticker.showCurrentTime'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Current time font:`}>
        <NcSettingsColorInput {...{ control }} fieldName='ticker.currentTimeFont.color' />
        <NcSettingsFontSelect {...{ magazine, control }} fieldName='ticker.currentTimeFont.fontName' flex1 />
        <NcSettingsNumberInput {...{ control }} fieldName='ticker.currentTimeFont.size' suffix='px' />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Background current time:`}>
        <NcSettingsColorInput {...{ control }} fieldName={'ticker.currentTimeBackgroundColor'} />
      </NcSettingsConfigurationRow>
      <NcSettingsConfigurationRow label={`Maximum article age:`}>
        <NcSettingsNumberInput {...{ control }} fieldName='ticker.articleMaxAge' suffix='days' nullable min={1} />
        <FormText>Leave empty to not apply any max age filter to articles.</FormText>
      </NcSettingsConfigurationRow>
    </div>
  )
}
