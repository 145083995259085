import React, { useEffect, useState } from 'react';
import styles from '../NcSettings.module.scss';
import stylesConf from './NcSettingsConfiguration.module.scss';
import { FullPageModal, FullPageModalHeader, FullPageModalContent, FullPageModalContentPart, FullPageModalSettings, FullPageModalSettingsSection } from '../../../../../components/FullPageModal';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';
import { NcSettingsConfigurationGeneral } from './NcSettingsConfigurationGeneral';
import { NcSettingsConfigurationTicker } from './NcSettingsConfigurationTicker';
import { NcSettingsConfigurationColors } from './NcSettingsConfigurationColors';
import { useForm } from 'react-hook-form';
import { TippyReact } from '../../../../../components';
import { EdErrorHandler, LoadingButton, SwalSuccess } from '../../../../../widgets';
import { Profile } from '../../../../../../domain';
import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from '../../../../../apis';
import { Channel } from '../../../../../components/ChannelsSelect/ChannelsSelect';
import { NcSettingsConfig } from '../../../NarrowcastingDomain';
import Swal from 'sweetalert2';
import { NC_DEFAULT_SETTINGS_CONFIGURATION } from '../NarrocastingSettingsDefaults';
import { NcSettingsConfigurationPreviews } from './NcSettingsConfigurationPreviews';

type SubTab = 'general' | 'colors' | 'ticker';

//NOTE: There can be some overrides if we add validations
export interface NcSettingsFormData extends NcSettingsConfig {

}

interface Props {
  magazine: number,
  profile: Profile
  onClose: () => void,

}

export const NcSettingsConfiguration = ({ magazine, profile, onClose }: Props) => {

  const [activeSubTab, setActiveSubTab] = useState<SubTab>('general');

  const channelsQuery = useQuery({
    queryKey: ['channels', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.oldApi.getMagazineChannels(magazine);
        return data.channels;
      } catch (error) {
        EdErrorHandler(error, `getting magazine channels`);
      }
    }
  });

  const channels: Channel[] = channelsQuery.data;

  const ncConfigurationQuery = useQuery({
    queryKey: ['ncConfiguration', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcSettingsConfiguration(magazine);
        return data;
      } catch (error) {
        if (error?.response?.status === 422) { //not valid schema. probably legacy record.
          Swal.fire({
            type: 'warning',
            title: 'Invalid schema!',
            text: 'Stored nc settings configuration is in an invalid schema',
            footer: `<div class="alert alert-info" style="width:100%;">The default values will be loaded instead and the new configuration will be stored when you click save.</div>`
          })
        } else if (error?.response?.status === 404) {
          Swal.fire({
            type: 'warning',
            title: 'No settings found!',
            text: 'No Nc settings configuration is currently stored',
            footer: `<div class="alert alert-info" style="width:100%;">The default values will be loaded and the new configuration will be stored when you click save.</div>`
          })
        } else {
          EdErrorHandler(error, `getting NC settings configuration`);
        }
      }
    }
  });

  useEffect(() => {
    if (ncConfigurationQuery.data) {
      reset({
        ...ncConfigurationQuery.data
      })
    }

  }, [ncConfigurationQuery.data])


  const isLoading = channelsQuery.isFetching || ncConfigurationQuery.isFetching;

  const { control, handleSubmit, getValues, watch, setValue, reset, formState: { errors } } = useForm<NcSettingsFormData>({
    defaultValues: { ...NC_DEFAULT_SETTINGS_CONFIGURATION }
  });

  const ncConfigurationUpdateMutation = useMutation({
    mutationKey: ['ncConfigurationUpdate'],
    mutationFn: (c: NcSettingsConfig) => API.narrowcasting.saveNcSettingsConfiguration(magazine, c),
    onError: (error, d) => {
      EdErrorHandler(error, `saving configuration settings`);
    },
    onSuccess: (data, d) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `Configuration settings have been saved successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      // queryClient.invalidateQueries({ queryKey: ['ncGroups'] })
      // queryClient.invalidateQueries({ queryKey: ['ncDevices'] })
      onClose();
    }
  })

  const onSave = handleSubmit(async (data) => {
    const c = convertToNcSettingsConfig(data);
    ncConfigurationUpdateMutation.mutate(c);
  });

  return (
    <FullPageModal>
      <FullPageModalHeader isLoading={false} onClose={onClose}>
        <div className={styles.modalTitle}>Configuration</div>
        <TippyReact config={{ disabled: true }} content={''}>
          <div><LoadingButton disabled={isLoading} loading={ncConfigurationUpdateMutation.isLoading} onClick={() => { onSave() }} text={'save'} /></div>
        </TippyReact>

      </FullPageModalHeader>
      <FullPageModalContent isLoading={isLoading}>
        <FullPageModalContentPart flex={2} cssStyle={{ display: 'flex', flexDirection: 'column', padding: '20px' }} mainPart>
          <Nav tabs>
            <NavItem><NavLink className={classNames({ active: activeSubTab === 'general' })} onClick={() => setActiveSubTab('general')}>General</NavLink></NavItem>
            <NavItem><NavLink className={classNames({ active: activeSubTab === 'colors' })} onClick={() => setActiveSubTab('colors')}>Colors</NavLink></NavItem>
            <NavItem><NavLink className={classNames({ active: activeSubTab === 'ticker' })} onClick={() => setActiveSubTab('ticker')}>Ticker</NavLink></NavItem>
          </Nav>
          <TabContent activeTab={activeSubTab} className={stylesConf.NcSettingsConfiguration}>
            <TabPane tabId={'general'} style={{ marginTop: '20px' }}><NcSettingsConfigurationGeneral {...{ magazine, profile, control, errors }} /></TabPane>
            <TabPane tabId={'colors'} style={{ marginTop: '20px' }}><NcSettingsConfigurationColors {...{ control, errors }} /></TabPane>
            <TabPane tabId={'ticker'} style={{ marginTop: '20px' }}><NcSettingsConfigurationTicker {...{ magazine, profile, control, errors, channels }} /></TabPane>
          </TabContent>
        </FullPageModalContentPart>
        <FullPageModalContentPart cssStyle={{ overflowY: 'auto', minWidth: '600px' }} withBackground>
          <FullPageModalSettings>
            <FullPageModalSettingsSection title={'preview'}>
              <NcSettingsConfigurationPreviews {...{ magazine, control }} />
            </FullPageModalSettingsSection>
          </FullPageModalSettings>
        </FullPageModalContentPart>
      </FullPageModalContent>
    </FullPageModal>
  )
}

//NOTE: For now this is the same but later on it can be changed if we add some validations
const convertToNcSettingsConfig = (c: NcSettingsFormData): NcSettingsConfig => {
  return {
    ...c
  }
}