import React from 'react';
import { API } from '../../../../apis';
import { SortingModal, SortingOnChangeHandler } from '../../../../components/Sortables/SortingModal';
import { EdErrorHandler, SwalSuccess } from '../../../../widgets';
import { CICChannel } from './domain';

interface Props {
  magazine: number
  parent: number
  channels: CICChannel[]
  onClose: (saved?: boolean) => void
}

export default function ReorderChildrenModal(props: Props) {
  const [channels, setChannels] = React.useState(props.channels)
  const [loading, setLoading] = React.useState(false)
  const [changed, setChanged] = React.useState(false) //tracks if at least on change has been made

  const onChange: SortingOnChangeHandler<CICChannel> = async (newItems, sourceIndex, destinationIndex) => {
    if (sourceIndex === destinationIndex) {
      // do nothing
      return
    }
    const oldChannels = [...channels]
    if (destinationIndex > sourceIndex) {
      destinationIndex++
    }
    const childId = oldChannels[sourceIndex].id
    const moveBeforeChild = oldChannels[destinationIndex]?.id || null //null is for last place
    console.log(childId, moveBeforeChild)
    setLoading(true)
    setChannels(newItems)
    try {
      await API.channels.moveChild(props.magazine, props.parent, childId, moveBeforeChild)
      setChanged(true)
    } catch (e) {
      EdErrorHandler(e, `reordering channels`);
      // if saving fails return to previous state
      setChannels(oldChannels)
    } finally {
      setLoading(false)
    }

  }

  return <SortingModal
    getItemId={(channel) => String(channel.id)}
    title={`Channels sorting`}
    columns={[
      {
        getValue: item => String(item.id),
        label: 'id',
      },
      {
        getValue: item => item.name,
        label: 'Name',
      },
      {
        getValue: item => item.label,
        label: 'Label',
      },
    ]}
    loading={loading}
    dataItems={channels}
    onChange={onChange}
    close={() => { props.onClose(changed) }}
  />
}
