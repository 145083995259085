import React, { useMemo } from 'react';
import styles from '../NcSlidesManager.module.scss';
import { NcSlideshowFormData } from '../NcSlideshowDetails';
import { ControllerRenderProps, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { NcSlideConfigToggle } from '../../../components/NcSlideConfigToggle';
import _ from 'lodash';
import { NcSlideConfigSelect } from '../../../components/NcSlideConfigSelect';
import { NcSlideConfigMediaSelect } from '../../../components/NcSlideConfigMediaSelect';
import { Profile } from '../../../../../../domain';
import { TippyReact } from '../../../../../components';
import classNames from 'classnames';

interface Props {
  magazine: number,
  profile: Profile,
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  activeIndex: number
  setError: UseFormSetError<NcSlideshowFormData>
  clearErrors: UseFormClearErrors<NcSlideshowFormData>
}

export const NcSlideTypeConfig_Image = ({ magazine, profile, field, activeIndex, setError, clearErrors }: Props) => {

  const slide = _.find(field.value, (s) => s.sort === activeIndex);
  const url = _.get(slide?.config, 'url');

  useMemo(() => {
    if (!url) {
      setError(`slides.${activeIndex}.config.url`, { type: 'imageSlideValidation', message: 'No selected image for image slide' });
    } else {
      clearErrors(`slides.${activeIndex}.config.url`);
    }
  }, [url, activeIndex, setError, clearErrors])

  return (
    <>
      <div className={styles.flexRow}>
        <TippyReact content={`An image should be selected!`} config={{ placement: 'bottom', disabled: url ? true : false }}>
          <div style={{ flex: 2 }} className={classNames({ [styles.requiredValueMissing]: !url ? true : false })}>
            <NcSlideConfigMediaSelect
              {...{ magazine, profile, field, activeIndex }}
              fieldName={`url`}
              label={`Image:`}
            />
          </div>
        </TippyReact>
        <div style={{ flex: 1 }}>
          <NcSlideConfigSelect
            {...{ field, activeIndex }}
            fieldName={`scaleMode`}
            label={`Scale mode:`}
            options={[
              { value: 'fill', label: 'Fill' },
              { value: 'fit', label: 'Fit' }
            ]}
          />
        </div>
        <div style={{ minWidth: '82px' }}>
          <NcSlideConfigToggle
            {...{ field, activeIndex }}
            fieldName={`animate`}
            label={`Animate:`}
          />
        </div>
      </div>
    </>
  )
};
