import React, { useMemo } from 'react';
import { SelectOption, SingleSelect } from '../ReactSelect/ReactSelect';
import _ from 'lodash';

interface Props {
  value: string
  onChange: (v: string) => void,
  isClearable?: boolean,
  placeholder?: string,
  includeHalftimes?: boolean,
}

export const TimezoneSelect = ({ value, onChange, isClearable, placeholder }: Props) => {

  const options: SelectOption<string>[] = useMemo(() => {
    return constructOptions()
  }, []);

  return (
    <SingleSelect
      value={value === '' ? undefined : value}
      onChange={(o) => { o === undefined ? onChange('') : onChange(o) }}
      options={options}
      isClearable={isClearable}
      placeholder={placeholder || ''}
      menuPosition={'fixed'}
    />
  )
}

export const constructOptions = (): SelectOption<string>[] => {
  const options: SelectOption<string>[] = [];

  for (let i = 0; i <= 14; i++) {
    const v = _.padStart(_.toString(i), 2, '0');
    options.push({
      value: `+${v}`,
      label: `GMT+${v}:00`
    })
  }

  for (let i = 1; i <= 12; i++) {
    const v = _.padStart(_.toString(i), 2, '0');
    options.push({
      value: `-${v}`,
      label: `GMT-${v}:00`
    })
  }

  return options;
}