import { AxiosPromise } from 'axios';
import { dashApi } from "../api";
import { NcAlertSlideConfig, NcDevice, NcDevicePlaylist, NcDevice_update, NcGroup, NcSettingsConfig, NcSettingsFontsMedia, NcSettingsGalleryMedia, NcSlideshow, NcSlideshowWithSlides, NcSlideshow_save } from '../pages/Narrowcasting/NarrowcastingDomain';

export const getNcDevices = (magazine: number): AxiosPromise<NcDevice[]> => {
  return dashApi.get(magazine, `ncDevices`);
}

export const getNcGroups = (magazine: number): AxiosPromise<NcGroup[]> => {
  return dashApi.get(magazine, `ncGroups`);
}

export const disconnectDevice = (magazine: number, deviceId: number): AxiosPromise<void> => {
  return dashApi.post(magazine, `ncDevices/${deviceId}/disconnect`);
}

export const connectDevice = (magazine: number, deviceId: number, reg_code: string): AxiosPromise<void> => {
  return dashApi.post(magazine, `ncDevices/${deviceId}/connect`, { reg_code });
}

export const createNcDevice = (magazine: number, label: string, group_ids: number[]): AxiosPromise<void> => {
  return dashApi.post(magazine, `ncDevices`, { label, group_ids });
}

export const saveNcGroup = (magazine: number, group: NcGroup): AxiosPromise<{ groupId: number }> => {
  return dashApi.post(magazine, `ncGroups`, { group });
}

export const deleteNcGroup = (magazine: number, groupId: number): AxiosPromise<void> => {
  return dashApi.delete(magazine, `ncGroups/${groupId}`);
}

export const getNcDevice = (magazine: number, deviceId: number): AxiosPromise<NcDevice> => {
  return dashApi.get(magazine, `ncDevices/${deviceId}`);
}

export const updateNcDevice = (magazine: number, device: NcDevice_update): AxiosPromise<void> => {
  return dashApi.post(magazine, `ncDevices/${device.id}`, { device });
}

export const deleteNcDevice = (magazine: number, deviceId: number): AxiosPromise<void> => {
  return dashApi.delete(magazine, `ncDevices/${deviceId}`);
}

export const getNcSettingsConfiguration = (magazine: number): AxiosPromise<NcSettingsConfig> => {
  return dashApi.get(magazine, `ncSettings/configuration`);
}

export const saveNcSettingsConfiguration = (magazine: number, config: NcSettingsConfig): AxiosPromise<void> => {
  return dashApi.post(magazine, `ncSettings/configuration`, { config });
}

export const getNcSettingsGallery = (magazine: number): AxiosPromise<NcSettingsGalleryMedia> => {
  return dashApi.get(magazine, `ncSettings/gallery`);
}

export const saveNcSettingsGallery = (magazine: number, gallery: NcSettingsGalleryMedia): AxiosPromise<void> => {
  return dashApi.post(magazine, `ncSettings/gallery`, { gallery });
}

export const getNcSettingsFonts = (magazine: number): AxiosPromise<NcSettingsFontsMedia> => {
  return dashApi.get(magazine, `ncSettings/fonts`);
}

export const saveNcSettingsFonts = (magazine: number, fonts: NcSettingsFontsMedia): AxiosPromise<void> => {
  return dashApi.post(magazine, `ncSettings/fonts`, { fonts });
}

export const getNcSettingsAlertSlide = (magazine: number): AxiosPromise<NcAlertSlideConfig> => {
  return dashApi.get(magazine, `ncSettings/alertSlide`);
}

export const saveNcSettingsAlertSlide = (magazine: number, alertSlideConfig: NcAlertSlideConfig): AxiosPromise<void> => {
  return dashApi.post(magazine, `ncSettings/alertSlide`, { alertSlideConfig });
}

export const getNcSlideshows = (magazine: number): AxiosPromise<NcSlideshow[]> => {
  return dashApi.get(magazine, `ncSlideshows`);
}

export const getNcSlideshow = (magazine: number, slideshowId: number): AxiosPromise<NcSlideshowWithSlides> => {
  return dashApi.get(magazine, `ncSlideshows/${slideshowId}`);
}

export const saveNcSlideshow = (magazine: number, slideshow: NcSlideshow_save): AxiosPromise<void> => {
  return dashApi.post(magazine, `ncSlideshows`, { slideshow });
}

export const deleteNcSlideshow = (magazine: number, slideshowId: number): AxiosPromise<void> => {
  return dashApi.delete(magazine, `ncSlideshows/${slideshowId}`);
}

export const getNcDevicePlaylist = (magazine: number, deviceId: number, group_ids?: number[]): AxiosPromise<NcDevicePlaylist> => {
  return dashApi.post(magazine, `ncDevices/${deviceId}/getPlaylist`, { group_ids });
}