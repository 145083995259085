import React, { useState } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label, Input } from "reactstrap";


export interface Props {
  closeHandler: () => void,
  createHandler: (type: string) => void,
}

export function SelectTypeModal(props: Props) {
  const { closeHandler, createHandler } = props;
  const [type, setType] = useState('event-registration');
  const [customType, setCustomType] = useState('');

  const closeBtn = <img className="close" onClick={closeHandler} src="/assets/icons/bt-close.svg" />

  return (
    <Modal isOpen={true} centered id="SelectTypeModal" toggle={() => closeHandler()}>
      <ModalHeader><div className='headerTitle'><img src="/assets/icons/16/widget.svg" style={{ marginRight: '10px' }}></img> Select Widget Type</div>{closeBtn}</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={2}><Label style={{ lineHeight: '38px' }}>Type:</Label></Col>
          <Col md={10}>
            <Input type="select" value={type} onChange={(e) => setType(e.target.value)}>
              <option value="event-registration">Event Registration</option>
              <option value="other">Other</option>
            </Input>
          </Col>
          {type !== 'other' ? null : <Col md={12}><Input value={customType} onChange={(e) => setCustomType(e.target.value)} /></Col>}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" block size="lg" onClick={() => createHandler(type !== 'other' ? type : customType)} style={{ textTransform: 'uppercase' }}>create instance</Button>
      </ModalFooter>
    </Modal>
  )
}