import React, { useState } from 'react';
import * as _ from 'lodash'
import { useHistory } from 'react-router-dom';


import { CICChannel } from './domain';
import { MagazineLanguages, Profile } from '../../../../../domain';
import { Audience } from '../Audiences/domain';
import { API } from '../../../../apis';
import * as Api from '../../../../api';
import { EdErrorHandler, OrtecLoader, SwalDelete } from '../../../../widgets';
import { HistoryHelper } from '../../../../utils';
import { createChannelsGraph, findParents, findChildren } from './cicChannelsHelper';
import CicOverviewTable from './cicTables/CicOverviewTable';
import { DefaultMagazineLanguages } from '../../../../utils/translationsHelper';
import ReorderChildrenModal from './ReorderChildrenModal';
import ChannelDetails from './channelDetails/ChannelDetails';
import EditChannelChildrenModal from './EditChannelChildrenModal';
import EditChannelParentsModal from './EditChannelParentsModal';


export enum ChannelAction { EDIT_CHANNEL, EDIT_PARENTS, EDIT_CHILDREN, DELETE_CHANNEL, CREATE_CHILD, REORDER_CHILDREN }

export interface Props {
  magazine: string
  profile: Profile
}

export function Channels(props: Props) {
  const history = useHistory();
  const [channels, setChannels] = useState<CICChannel[] | undefined>(undefined);
  const [audiences, setAudiences] = useState<Audience[]>([]);
  const [languages, setLanguages] = useState<MagazineLanguages>({ ...DefaultMagazineLanguages });

  const [loading, setLoading] = useState(true);

  const channelsGraph = React.useMemo(() => channels && createChannelsGraph(channels), [channels])

  const [editChildrenModal, setEditChildrenModal] = React.useState<number | undefined>(undefined)
  const [editParentsModal, setEditParentsModal] = React.useState<number | undefined>(undefined)
  const [createChildModal, setCreateChildModal] = React.useState<number | undefined>(undefined)
  const [reorderChildrenModal, setReorderChildrenModal] = React.useState<number | undefined>(undefined)

  React.useEffect(() => { init(); }, [])

  const magazine = Number(props.magazine)

  const viewChannelParam = HistoryHelper.getQueryParams(history)['channel'];
  const viewChannel = viewChannelParam ? +viewChannelParam : undefined; //convert to number

  function setEditChannelModal(channelId: number | undefined) {
    HistoryHelper.setQueryParams(history, {
      ...HistoryHelper.getQueryParams(history),
      channel: channelId
    })
  }

  async function onClose(saved: boolean | undefined) {
    if (saved) {
      setLoading(true);
      setChannels(await API.channels.cic(magazine).then(resp => resp.data))
      setLoading(false);
    }
  }


  async function onChannelAction(action: ChannelAction, channelId: number) {
    switch (action) {
      case ChannelAction.CREATE_CHILD: {
        setCreateChildModal(channelId); return
      }
      case ChannelAction.EDIT_CHANNEL: {
        setEditChannelModal(channelId); return
      }
      case ChannelAction.REORDER_CHILDREN: {
        setReorderChildrenModal(channelId); return
      }
      case ChannelAction.EDIT_CHILDREN: {
        setEditChildrenModal(channelId); return
      }
      case ChannelAction.EDIT_PARENTS: {
        setEditParentsModal(channelId); return
      }
      case ChannelAction.DELETE_CHANNEL: {
        try {
          const saved = await deleteChannelDialog(magazine, channelId)
          onClose(saved)
        } catch (error) {
          EdErrorHandler(error, 'deleting channel')
          onClose(true)
        }
        return
      }
    }
  }

  const init = async () => {
    try {
      const [cs, auds, ls] = await Promise.all([
        API.channels.cic(magazine).then(resp => resp.data),
        API.audiences.getAudiences(magazine).then(resp => resp.data),
        Api.getLanguages(magazine).then(resp => resp.data),
      ])
      setChannels(cs)
      setAudiences(auds)
      setLanguages(ls)
    } catch (error) {
      EdErrorHandler(error, `getting channels`);
    } finally {
      setLoading(false);
    }
  }

  return <>
    <div style={{ paddingTop: '20px' }}>
      {loading && <div style={{ maxHeight: '800px' }}> <OrtecLoader /> </div>}
      <CicOverviewTable profile={props.profile} audiences={audiences} channels={channels || []} onChannelAction={onChannelAction} />
    </div>
    {channelsGraph &&
      <>
        {viewChannel !== undefined && <ChannelDetails
          magazine={Number(props.magazine)}
          profile={props.profile}
          onClose={saved => { setEditChannelModal(undefined); onClose(saved) }}
          channelId={viewChannel}
          channelsGraph={channelsGraph}
          audiences={audiences}
          languages={languages}
          parents={findParents(channelsGraph, viewChannel)}
          onChannelAction={onChannelAction}
        />}
        {
          createChildModal !== undefined && <ChannelDetails
            magazine={Number(props.magazine)}
            profile={props.profile}
            onClose={saved => { setCreateChildModal(undefined); onClose(saved) }}
            channelId={null}
            channelsGraph={channelsGraph}
            audiences={audiences}
            languages={languages}
            parents={[createChildModal]}
            onChannelAction={onChannelAction}
          />
        }
        {
          editChildrenModal !== undefined && <EditChannelChildrenModal
            magazine={Number(props.magazine)}
            profile={props.profile}
            channels={channels || []}
            channelsGraph={channelsGraph}
            channelId={editChildrenModal}
            onClose={saved => { setEditChildrenModal(undefined); onClose(saved) }}
          />
        }
        {
          editParentsModal !== undefined && <EditChannelParentsModal
            magazine={Number(props.magazine)}
            profile={props.profile}
            channels={channels || []}
            channelsGraph={channelsGraph}
            channelId={editParentsModal}
            onClose={saved => { setEditParentsModal(undefined); onClose(saved) }}
          />
        }
        {
          reorderChildrenModal !== undefined && <ReorderChildrenModal
            magazine={Number(props.magazine)}
            channels={findChildren(channelsGraph, reorderChildrenModal).map(id => channelsGraph.nodes[id].channel)}
            parent={reorderChildrenModal}
            onClose={saved => { setReorderChildrenModal(undefined); onClose(saved) }}
          />
        }
      </>
    }
  </>
}

async function deleteChannelDialog(magazine: number, channelId: number): Promise<boolean> {
  const result = await SwalDelete.fire({
    title: 'Are you sure?',
    text: `This will delete channel: ${channelId}`,
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    focusCancel: true,
  })
  if (result.value) {
    await API.channels.deleteChannel(magazine, channelId)
    return true
  } else {
    return false
  }
}
