import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { UsersList } from '../../../domain';
import { API } from '../../apis';
import { EdErrorHandler, LoadingButton, OrtecLoader } from '../../widgets';
import styles from './UsersModal.module.scss';
const FileDownload = require('js-file-download');

interface Props {
  magazine: number,
  title?: string,
  exportCsvTitle?: string,
  uids: string[],
  closeModal: () => void,
}

export const UsersModal = ({ magazine, title, exportCsvTitle, uids, closeModal }: Props) => {

  const [loading, setLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [users, setUsers] = useState<UsersList>();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      setLoading(true);
      const { data } = await API.profiles.getUserList(magazine, uids);
      setUsers(data);
    } catch (error) {
      EdErrorHandler(error, `loading users`);
    } finally {
      setLoading(false);
    }
  }


  const exportUsers = async (data: any[], fields: Array<{ label: string, value: string }>,) => {

    try {
      setExportLoading(true);
      const { data: csv } = await API.exportData.csv(magazine, data, fields);
      FileDownload(csv, `${exportCsvTitle || `userlist`}.csv`);
    } catch (error) {
      EdErrorHandler(error, `exporting users CSV`);
    } finally {
      setExportLoading(false);
    }
  }

  const exportCSV = () => {
    const fields = [
      { label: 'UID', value: 'uid' },
      { label: 'Name', value: 'name' },
    ];
    const exportData = usersToExportData();
    if (_.isEmpty(exportData)) {
      return;
    }
    exportUsers(exportData, fields);
  }

  const usersToExportData = (): Array<{ uid: string, name: string }> => {
    if (!users || _.isEmpty(users.uids)) {
      return [];
    }


    const namedUsers = _.compact(_.map(users.named, (u) => {

      return {
        uid: u.uid,
        name: `${_.get(u, 'firstName', '')} ${_.get(u, 'lastName', '')}`
      }
    }));

    const anonymousUsers = _.compact(_.map(users.anonymous, (uid) => {
      return {
        uid: uid,
        name: '-',
      }
    }));

    return [...namedUsers, ...anonymousUsers];

  }

  const calcTableData = (): Array<{ id: string, name: string }> => {
    if (!users || _.isEmpty(users.uids)) {
      return [];
    }

    const anonymous = users.anonymous.length;

    const notAvailable = _.filter(users.uids, (uid) => uid == 'n/a').length;

    let userRows = _.compact(_.map(users.named, (u) => {

      const firstName = _.get(u, 'firstName', '');
      const lastName = _.get(u, 'lastName', '');

      return {
        id: u.uid,
        name: (firstName || lastName) ? `${firstName} ${lastName}` : u.uid
      }
    }));

    if (anonymous) {
      userRows = [...userRows, { id: '__anonymous', name: `${_.isEmpty(userRows) ? '' : '...and'} ${anonymous} ${_.isEmpty(userRows) ? '' : ' other'} anonymous user${anonymous > 1 ? 's' : ''}` }];
    }

    if (notAvailable) {
      userRows = [...userRows, { id: '__notAvailable', name: `${_.isEmpty(userRows) ? '' : '...and'} ${notAvailable} ${_.isEmpty(userRows) ? '' : ' other'} response${notAvailable > 1 ? 's' : ''} where uid was "n/a"` }];
    }

    return userRows;

  }


  const options = {
    toolBar: (props: any) => {
      return (
        <Col md={12}>{props.components.searchField}</Col>
      );
    }
  };

  const closeBtn = <img className="close" onClick={closeModal} src="/assets/icons/bt-close.svg" />;

  const tableData = calcTableData();

  return (
    <Modal isOpen={true} centered toggle={closeModal} contentClassName={styles.UsersModal}>
      <ModalHeader>
        <div className='headerTitle'>
          <img src="/assets/icons/24/users.svg" style={{ marginRight: '10px' }}></img>
          {/* {usersPrefix()} Users ({usersTotal()}) */}
          {title || 'Users'}
        </div> {closeBtn}
      </ModalHeader>
      <ModalBody>
        {loading && <OrtecLoader size={'medium'} />}
        <div className="tableDiv">
          <BootstrapTable data={tableData} bordered={false} hover={false} version='4' options={options} search searchPlaceholder={`Search for name`}>
            <TableHeaderColumn dataField='id' hidden isKey></TableHeaderColumn>
            <TableHeaderColumn dataField='name'></TableHeaderColumn>
          </BootstrapTable>
        </div>
      </ModalBody>
      {(!_.isEmpty(tableData) && !(_.isEmpty(users?.anonymous) && _.isEmpty(users?.named))) &&
        <ModalFooter>
          <LoadingButton color="primary" block onClick={() => exportCSV()} text={`Export CSV`} loading={exportLoading} />
        </ModalFooter>
      }
    </Modal>
  )
}
