import React, { Fragment, useState } from 'react';
import { CustomInput, Input } from 'reactstrap';
import { FieldConfig } from '../../../People/domain';
import { BuilderHelper, styles } from '../AudienceBuilder';
import _ from 'lodash';
import Toggle from 'react-toggle';
import Creatable from 'react-select/creatable';
import { Condition, ConditionOperator, ConditionOperatorOption } from '../../domain';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DateHelper } from '../../../../../../utils';
import moment from 'moment';
interface Props {
  index: number,
  condition: Condition,
  peopleConfig: FieldConfig[],
  fields?: string[],
  noRights: boolean,
  onChangeCondition: (index: number, condition: Condition) => void
}

export const ConditionBox = ({ index, condition, peopleConfig, fields, noRights, onChangeCondition }: Props) => {

  const [addingTagValue, setAddingTagValue] = useState('');


  const conditionOperatorOptions: ConditionOperatorOption[] = [
    { op: '$eq', label: 'equal' },
    { op: '$ne', label: 'not equal' },
    { op: '$in', label: 'included in' },
    { op: '$nin', label: 'not included in' },
    { op: '$gt', label: 'greater than' },
    { op: '$lt', label: 'less than' },
    { op: 'lessXdays', label: 'less than X days' },
    { op: 'moreXdays', label: 'more than X days' },
  ];

  const onChangeConditionKey = (key: string) => {
    const fieldType = BuilderHelper.getFieldType(key, peopleConfig);
    const operator = BuilderHelper.adjustConditionOperatorAccordingToConditionFieldType(condition, fieldType);
    const value = BuilderHelper.adjustConditionValueAccordingToConditionOperator(condition, operator, fieldType);
    const c = { ...condition, key, operator, value };
    onChangeCondition(index, c);
  }
  const onChangeConditionOperator = (operator: ConditionOperator) => {
    const value = BuilderHelper.adjustConditionValueAccordingToConditionOperator(condition, operator, BuilderHelper.getConditionFieldType(condition, peopleConfig));
    const c = { ...condition, operator, value };
    onChangeCondition(index, c);
  }
  const onChangeConditionValue = (value: any) => {
    const c = { ...condition, value };
    onChangeCondition(index, c);
  }

  const renderConditionOperatorOptions = () => {

    const disabledOptions: ConditionOperator[] = BuilderHelper.getConditionDisabledOperatorOptions(condition, peopleConfig);
    return (
      <Fragment>
        {_.map(conditionOperatorOptions, (o) => {
          const { op, label } = o;
          return <option value={op} disabled={_.includes(disabledOptions, op)}>{label}</option>
        })}
      </Fragment>
    )
  }

  const renderConditionBoxValueInput = () => {
    const type = BuilderHelper.getConditionFieldType(condition, peopleConfig);
    const { value } = condition;

    const valueAsDays = (): number => {
      if (_.isArray(value)) {
        const v = value[1];
        return BuilderHelper.milisecondsToDays(v);
      }
      return value;

    }

    if (type === 'checkbox') {
      return <Toggle disabled={noRights} checked={(_.isBoolean(value)) ? value : (value === 'true' ? true : false)} onChange={(e: any) => { onChangeConditionValue(e.target.checked) }} />
    }

    if (_.includes(['date'], type)) {
      if (_.includes(value, 'new Date()')) { //lessXdays or moreXdays
        return <Input disabled={noRights} min={0} type={'number'} value={valueAsDays()} onChange={(e) => { onChangeConditionValue(e.target.value) }} />
      }
      return (
        <DatePicker
          selected={DateHelper.stringToDate(value)}
          onChange={(d: any) => {
            onChangeConditionValue(moment(d).format(`YYYY-MM-DD`))
          }}
          dateFormat={"yyyy-MM-dd'T'00:00:00'Z'"}
          isClearable={!noRights}
          placeholderText={'select date..'}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={<Input />}
          disabled={noRights}
        />
      )
    }

    if (_.isArray(value)) {
      const tagsValue = _.map(value, (v) => { return { label: _.toString(v), value: _.toString(v) } });

      const onKeyDown = (e: any) => {
        if (!addingTagValue) {
          return;
        }
        switch (e.key) {
          case 'Enter':
          case 'Tab':
            if (_.find(tagsValue, (t) => t.value == addingTagValue)) {
              e.preventDefault();
              return;
            }
            const newTagsValue = tagsValue ? [...tagsValue, { label: addingTagValue, value: addingTagValue }] : [{ label: addingTagValue, value: addingTagValue }];
            setAddingTagValue('');
            onChangeConditionValue(_.map(newTagsValue, 'value'));
            e.preventDefault();
        }
      }
      return (
        <Creatable
          components={{ DropdownIndicator: null }}
          inputValue={addingTagValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={(newTagsValue) => { onChangeConditionValue(_.map(newTagsValue, 'value')) }}
          onInputChange={setAddingTagValue}
          onKeyDown={onKeyDown}
          placeholder="Add new tag..."
          value={tagsValue}
          isDisabled={noRights}
        />
      )
    }

    if (type == 'number') {
      return <Input disabled={noRights} type={'number'} value={value} onChange={(e) => onChangeConditionValue(_.toNumber(e.target.value))} />
    }

    return <Input disabled={noRights} type={'text'} value={value} onChange={(e) => onChangeConditionValue(e.target.value)} />

  }



  return (
    <div className={styles.elementBoxWrapper}>
      <div className={styles.elementBoxConnector}></div>
      <div className={styles.elementBox}>
        <div className={styles.flexRow}>
          <Input disabled={noRights} type={'select'} style={{ flex: 1, marginRight: '20px' }} value={condition.key} onChange={(e) => { onChangeConditionKey(e.target.value) }}>
            {_.compact(_.map(peopleConfig, (p) => {
              if (!fields || _.includes([...fields, condition.key], p.key)) {
                return (
                  <option key={p.key} disabled={p.disabled} value={p.key}>{p.label}</option>
                )
              }
            }))}
          </Input>
          <Input disabled={noRights} type={'select'} style={{ flex: 1, }} value={condition.operator} onChange={(e) => { onChangeConditionOperator(e.target.value as ConditionOperator) }}>
            {renderConditionOperatorOptions()}
          </Input>
        </div>
        <div className={styles.flexRow}>
          <div style={{ flex: 1 }}>
            {renderConditionBoxValueInput()}
          </div>
        </div>
      </div>
      <div className={styles.elementBoxConnector}></div>
    </div>
  )
}
