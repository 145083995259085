import React, { Component } from 'react';
import { Input, Button, Badge } from 'reactstrap';
import moment from 'moment';
import { StatsFilter, Audience } from '../../../../../domain';
import * as _ from 'lodash';
import './css/FilterBar.min.css';
import { Translations } from '../../../../translations';


export interface Props {
  magazine: string,
  audiences: Audience[],
  globalAudience: boolean,
  statsFilter: StatsFilter,
  // periodFilterHandler: (period: string) => void,
  audienceFilterHandler: (audience: number) => void,
  exportHanlder: (mode: string) => void,
  results: number
}

export class FilterBar extends Component<Props> {

  calcPeriods() { //last 12 months
    let periods: any[] = [];
    const startOfMonth = moment().startOf('month');
    // const startOfYear = moment().startOf('year');

    let currentMonth = startOfMonth;

    const startOfRange = moment(startOfMonth).subtract(12, 'month');

    const tr = Translations.Translate(Translations, 'FilterBar');

    periods.push({ label: `${tr('this')} ${tr('month')}`, value: currentMonth.format('YYYY-MM') });

    currentMonth.subtract(1, 'month');
    periods.push({ label: `${tr('last')} ${tr('month')}`, value: currentMonth.format('YYYY-MM') });

    currentMonth.subtract(1, 'month');
    while (currentMonth >= startOfRange) {
      periods.push({ label: `${tr('last')} ${tr(currentMonth.format('MMMM'))}`, value: currentMonth.format('YYYY-MM') });
      currentMonth.subtract(1, 'month');
    }

    return periods;
  }

  // renderPeriodFilter() {
  //   const {periodFilterHandler, statsFilter} = this.props;
  //   const tr = Translations.Translate(Translations,'FilterBar');

  //   const periods = this.calcPeriods();
  //   return (
  //     <Input type="select" value={statsFilter.period} onChange={(e) => { periodFilterHandler(e.target.value) }} style={{ display: 'inline', width: '180px', marginLeft: '20px' }}>
  //       <option key="last30days" value="last30days">{tr('last30days')}</option> 
  //       <optgroup label={'--------'}>
  //       </optgroup>
  //       {periods.map((p)=>{
  //         return (<option key={p.value} value={p.value}>{p.label}</option>)
  //       })}
  //       <optgroup label={'--------'}>
  //       </optgroup>
  //       <option key={'*'} value='*'>{tr('since_app_start')}</option>
  //     </Input>
  //   )
  // }
  renderAudienceFilter() {
    const { audienceFilterHandler, statsFilter, globalAudience, audiences } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');

    return (
      <Input type="select" value={statsFilter.audience} onChange={(e) => { audienceFilterHandler(parseInt(e.target.value)) }} style={{ display: 'inline', width: '120px', marginLeft: '10px' }}>
        {globalAudience ?
          <option value="1">{tr('all_employees')}</option>
          : ''
        }
        {audiences.map((a) => {
          if (_.toNumber(a.id) != 1) {
            return <option key={a.id} value={a.id}>{a.label}</option>
          }
        })}
      </Input>
    )
  }
  render() {
    const { results } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');
    const trf = Translations.Translate(Translations, 'Forms');

    return (
      <div id="FormResultsFilterBar">
        {Translations.EnableTranslations(Translations)}
        {/* <div className="filterWrapper">
            <img src="/assets/icons/calendar.svg"  style={{marginRight: '10px' }} />
            <span style={{ fontSize: '10px', letterSpacing: '0.6px', textTransform: 'uppercase' }}>{tr('period')}:</span>
            {this.renderPeriodFilter()}
          </div> */}
        <div className="filterWrapper">
          <img src="/assets/icons/target.svg" style={{ marginRight: '10px' }} />
          <span style={{ fontSize: '10px', letterSpacing: '0.6px', textTransform: 'uppercase' }}>{tr('audience')}:</span>
          {this.renderAudienceFilter()}
        </div>
        <Badge className={'responsesBadge'} data-tippy-content={`${results} response${results == 1 ? '' : 's'}`}>{results}</Badge>
        <div className="filterWrapper">
          <img src="/assets/icons/target.svg" style={{ marginRight: '10px' }} />
          <span style={{ fontSize: '10px', letterSpacing: '0.6px', textTransform: 'uppercase' }}>{trf('download')}:</span>
          <Button onClick={() => this.props.exportHanlder('overall')}>{trf('csvOverall')}</Button>
          <Button onClick={() => this.props.exportHanlder('detailed')}>{trf('csvDetailed')}</Button>
        </div>
        <div className="verticalDevider"></div>
      </div>
    );
  }
}

export default FilterBar;
