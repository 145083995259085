import React, { Component, Fragment } from 'react';
import { Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Form, FormGroup } from 'reactstrap';
import moment from 'moment';
import { StatsFilter, Audience } from '../../../domain';
import * as _ from 'lodash';
import './css/FilterBar.min.css';
import { Translations } from '../../translations';
import Datepicker from 'react-datepicker3';
import "react-datepicker3/dist/react-datepicker.css";
import { ActivePeriodSelector } from '../ActivePeriodSlector/ActivePeriodSelector';

export interface Props {
  magazine: string,
  audiences: Audience[],
  globalAudience: boolean,
  statsFilter: StatsFilter,
  periodFilterHandler: (period: string) => void,
  audienceFilterHandler: (audience: number) => void,
}

export class FilterBar extends Component<Props> {

  calcPeriods() { //last 12 months
    let periods: any[] = [];
    const startOfMonth = moment().startOf('month');
    // const startOfYear = moment().startOf('year');

    let currentMonth = startOfMonth;

    const startOfRange = moment(startOfMonth).subtract(12, 'month');

    const tr = Translations.Translate(Translations, 'FilterBar');

    periods.push({ label: `${tr('this')} ${tr('month')}`, value: currentMonth.format('YYYY-MM') });

    currentMonth.subtract(1, 'month');
    periods.push({ label: `${tr('last')} ${tr('month')}`, value: currentMonth.format('YYYY-MM') });

    currentMonth.subtract(1, 'month');
    while (currentMonth >= startOfRange) {
      periods.push({ label: `${tr('last')} ${tr(currentMonth.format('MMMM'))}`, value: currentMonth.format('YYYY-MM') });
      currentMonth.subtract(1, 'month');
    }

    return periods;
  }

  renderCustomPeriodOption = () => {
    if (!_.includes([..._.map(this.calcPeriods(), 'value'), 'last30days', '*'], this.props.statsFilter.period)) {
      return (
        <Fragment>
          <optgroup label={'--------'}>
          </optgroup>
          <option key={this.props.statsFilter.period} value={this.props.statsFilter.period}>{this.props.statsFilter.period}</option>
        </Fragment>
      )
    }
    return null;
  }

  renderPeriodFilter() {
    const { periodFilterHandler, statsFilter } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');

    const periods = this.calcPeriods();
    return (
      <Input type="select" value={statsFilter.period} onChange={(e) => { periodFilterHandler(e.target.value) }} style={{ display: 'inline', width: '180px', marginLeft: '10px' }}>
        <option key="last30days" value="last30days">{tr('last30days')}</option>
        <optgroup label={'--------'}>
        </optgroup>
        {periods.map((p) => {
          return (<option key={p.value} value={p.value}>{p.label}</option>)
        })}
        <optgroup label={'--------'}>
        </optgroup>
        <option key={'*'} value='*'>{tr('since_app_start')}</option>
        {this.renderCustomPeriodOption()}
      </Input>
    )
  }
  isActiveFilter = (f: string) => {
    return f == this.periodToFilter();
  }

  periodToFilterLabel = (): string => {
    const { period } = this.props.statsFilter;
    if (!period) {
      return '';
    }

    const f = this.periodToFilter()
    if (_.includes(['customMonth', 'customWeek', 'customDay', 'customYear'], f)) {
      return period;
    }
    const tr = Translations.Translate(Translations, 'FilterBar');
    return tr(f);
  }

  periodToFilter = (): string => {
    const { period } = this.props.statsFilter;
    if (!period) {
      return '';
    }

    if (period == 'last30days') {
      return 'last30days';
    }
    if (period == '*') {
      return 'since_app_start';
    }

    if (/^\d{4}-\d{2}$/.test(period)) { //monthly      
      const currentMonth = moment().startOf('month');
      const thisMonth = currentMonth.format('YYYY-MM');
      if (period == thisMonth) {
        return 'thisMonth';
      }

      currentMonth.subtract(1, 'month');
      const lastMonth = currentMonth.format('YYYY-MM');
      if (period == lastMonth) {
        return 'lastMonth';
      }

      return 'customMonth';


    }
    if (/^\d{4}-W\d{1,2}$/.test(period)) { //weekly
      const currentWeek = moment().startOf('isoWeek');
      const thisWeek = currentWeek.format('GGGG-[W]W');
      if (period == thisWeek) {
        return 'thisWeek';
      }

      currentWeek.subtract(1, 'week');
      const lastWeek = currentWeek.format('GGGG-[W]W');
      if (period == lastWeek) {
        return 'lastWeek';
      }

      return 'customWeek';

    }
    if (/^\d{4}-\d{2}-\d{2}$/.test(period)) { //daily      
      const currentDay = moment();
      const today = currentDay.format('YYYY-MM-DD');
      if (period == today) {
        return 'today';
      }

      currentDay.subtract(1, 'day');
      const yesterday = currentDay.format('YYYY-MM-DD');
      if (period == yesterday) {
        return 'yesterday';
      }

      return 'customDay';


    }
    if (/^\d{4}$/.test(period)) { //yearly      
      const currentYear = moment().startOf('year');
      const thisYear = currentYear.format('YYYY');
      if (period == thisYear) {
        return 'thisYear';
      }

      currentYear.subtract(1, 'year');
      const lastYear = currentYear.format('YYYY');
      if (period == lastYear) {
        return 'lastYear';
      }
      return 'customYear';
    }

    return '';

  }

  filterToPeriod = (f: string): string => {

    switch (f) {
      case 'last30days':
        return 'last30days';
      case 'since_app_start':
        return '*';
      case 'thisYear':
        return moment().startOf('year').format('YYYY');
      case 'lastYear':
        return moment().startOf('year').subtract(1, 'year').format('YYYY');
      case 'thisMonth':
        return moment().startOf('month').format('YYYY-MM');
      case 'lastMonth':
        return moment().startOf('month').subtract(1, 'month').format('YYYY-MM');
      case 'thisWeek':
        return moment().startOf('isoWeek').format('GGGG-[W]W');
      case 'lastWeek':
        return moment().startOf('isoWeek').subtract(1, 'week').format('GGGG-[W]W');
      case 'yesterday':
        return moment().subtract(1, 'day').format('YYYY-MM-DD');
      default:
        return f;
    }

  }

  changePeriod = (f: string) => {
    const { periodFilterHandler } = this.props;
    periodFilterHandler(this.filterToPeriod(f));
  }
  renderAdvancedPeriodFilter() {
    const { periodFilterHandler, statsFilter } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');
    // const periods = this.calcPeriods();
    return (
      <UncontrolledDropdown className={'filterDropdown'}>
        <DropdownToggle caret>{this.periodToFilterLabel()}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem active={this.isActiveFilter('last30days')} onClick={() => this.changePeriod('last30days')}>{tr('last30days')}</DropdownItem>
          {/* <DropdownItem>Last 7 days</DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem header>Daily</DropdownItem>
          {/* <DropdownItem disabled>{tr('yesterday')}</DropdownItem> */}
          <DropdownItem active={this.isActiveFilter('yesterday')} onClick={() => this.changePeriod('yesterday')}>{tr('yesterday')}</DropdownItem>
          <DropdownItem active={this.isActiveFilter('customDay')}>
            <Form inline>
              <FormGroup>
                <Label>{tr('day')}: </Label>
                {/* <Input placeholder={`Choose a day`}/> */}
                <div className={'inputButtonWrapper publishingDateWrapper'} onClick={(e: any) => {
                  if (e && (e.target.getAttribute('type') == 'text' || e.target.getAttribute('type') == 'button')) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }
                  return true;
                }}>
                  <Datepicker
                    useWeekdaysShort
                    dateFormat="yyyy-MM-dd"
                    maxDate={moment().subtract(1, 'day').toDate()}
                    selected={this.isActiveFilter('customDay') ? (statsFilter.period ? moment(statsFilter.period, 'YYYY-MM-DD').toDate() : null) : null}
                    isClearable={false}
                    placeholderText={'Choose a day'}
                    customInput={<Input type={'text'} placeholder={tr('Choose a day')} readOnly />}
                    onChange={(d: Date, e: any) => {
                      let dateStr = '';
                      if (d) {
                        try {
                          const m = moment(d);
                          if (m.isValid()) {

                            dateStr = m.format('YYYY-MM-DD');
                            this.changePeriod(dateStr);

                          }
                        } catch (error) {
                          this.changePeriod('');
                        }
                      } else {
                        this.changePeriod('');
                      }
                      // if (e) {
                      //   e.preventDefault();
                      //   e.stopPropagation();
                      // }
                      // return false;
                    }}
                    onChangeRaw={(e) => {
                      if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                      return false;

                    }}
                    shouldCloseOnSelect={true}
                  />
                </div>
              </FormGroup>
            </Form>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem header>Weekly</DropdownItem>
          <DropdownItem active={this.isActiveFilter('thisWeek')} onClick={() => this.changePeriod('thisWeek')}>{tr('thisWeek')}</DropdownItem>
          <DropdownItem active={this.isActiveFilter('lastWeek')} onClick={() => this.changePeriod('lastWeek')}>{tr('lastWeek')}</DropdownItem>
          <DropdownItem active={this.isActiveFilter('customWeek')}>
            <Form inline>
              <FormGroup>
                <Label>{tr('week')}: </Label>
                <div className={'inputButtonWrapper publishingDateWrapper weekPickerWrapper'} onClick={(e: any) => {
                  if (e && (e.target.getAttribute('type') == 'text' || e.target.getAttribute('type') == 'button')) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }
                  return true;
                }}>
                  <Datepicker
                    useWeekdaysShort
                    dateFormat="yyyy-'W'w"
                    maxDate={moment().subtract(1, 'day').toDate()}
                    selected={this.isActiveFilter('customWeek') ? (statsFilter.period ? moment(statsFilter.period, 'GGGG-[W]W').toDate() : null) : null}
                    startDate={this.isActiveFilter('customWeek') ? (statsFilter.period ? moment(statsFilter.period, 'GGGG-[W]W').toDate() : null) : null}
                    endDate={this.isActiveFilter('customWeek') ? (statsFilter.period ? moment(statsFilter.period, 'GGGG-[W]W').add(6, 'days').toDate() : null) : null}
                    isClearable={false}
                    placeholderText={'Choose a week'}
                    customInput={<Input type={'text'} placeholder={tr('Choose a week')} readOnly />}
                    onChange={(d: Date, e: any) => {
                      let dateStr = '';
                      if (d) {
                        try {
                          const m = moment(d);
                          if (m.isValid()) {

                            dateStr = m.format('GGGG-[W]W');
                            this.changePeriod(dateStr);

                          }
                        } catch (error) {
                          this.changePeriod('');
                        }
                      } else {
                        this.changePeriod('');
                      }
                      // if (e) {
                      //   e.preventDefault();
                      //   e.stopPropagation();
                      // }
                      // return false;
                    }}
                    onChangeRaw={(e) => {
                      if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                      return false;

                    }}
                    showWeekNumbers
                    shouldCloseOnSelect={true}
                  />
                </div>
                {/* <Input placeholder={`Choose a week`} defaultValue={this.isActiveFilter('customWeek')?statsFilter.period:''}
                  onClick={(e:any)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }}
                  onKeyDown={(e:any)=>{
                    if(e.key == 'Enter' || e.which == 13 || e.keyCode == 13){
                      console.log(e.target.value);
                      e.preventDefault();
                      e.stopPropagation();
                      this.changePeriod(e.target.value);
                      e.target.parentElement.click();
                      return false;
                    
                    }
                  }}
                /> */}
              </FormGroup>
            </Form>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem header>Monthly</DropdownItem>
          <DropdownItem active={this.isActiveFilter('thisMonth')} onClick={() => this.changePeriod('thisMonth')}>{tr('thisMonth')}</DropdownItem>
          <DropdownItem active={this.isActiveFilter('lastMonth')} onClick={() => this.changePeriod('lastMonth')}>{tr('lastMonth')}</DropdownItem>
          <DropdownItem active={this.isActiveFilter('customMonth')}>
            <Form inline>
              <FormGroup>
                <Label>{tr('month')}:</Label>
                <div className={'inputButtonWrapper publishingDateWrapper'} onClick={(e: any) => {
                  if (e && (e.target.getAttribute('type') == 'text' || e.target.getAttribute('type') == 'button')) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }
                  return true;
                }}>
                  <Datepicker
                    useWeekdaysShort
                    dateFormat="yyyy-MM"
                    maxDate={moment().subtract(1, 'day').toDate()}
                    selected={this.isActiveFilter('customMonth') ? (statsFilter.period ? new Date(statsFilter.period) : null) : null}
                    isClearable={false}
                    placeholderText={'Choose a month'}
                    customInput={<Input type={'text'} placeholder={tr('Choose a month')} readOnly />}
                    onChange={(d: Date, e: any) => {
                      let dateStr = '';
                      if (d) {
                        try {
                          const m = moment(d);
                          if (m.isValid()) {

                            dateStr = m.format('YYYY-MM');
                            this.changePeriod(dateStr);

                          }
                        } catch (error) {
                          this.changePeriod('');
                        }
                      } else {
                        this.changePeriod('');
                      }
                      // if (e) {
                      //   e.preventDefault();
                      //   e.stopPropagation();
                      // }
                      // return false;
                    }}
                    onChangeRaw={(e) => {
                      if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                      return false;

                    }}
                    showMonthYearPicker
                    shouldCloseOnSelect={true}
                  />
                </div>
                {/* <Input placeholder={`Choose a month`} defaultValue={this.isActiveFilter('customMonth')?statsFilter.period:''}
                  onClick={(e:any)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }}
                  onKeyDown={(e:any)=>{
                    if(e.key == 'Enter' || e.which == 13 || e.keyCode == 13){
                      console.log(e.target.value);
                      e.preventDefault();
                      e.stopPropagation();
                      this.changePeriod(e.target.value);
                      e.target.parentElement.click();
                      return false;
                    
                    }
                  }}
                /> */}
              </FormGroup>
            </Form>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem header>Yearly</DropdownItem>
          <DropdownItem active={this.isActiveFilter('thisYear')} onClick={() => this.changePeriod('thisYear')}>{tr('thisYear')}</DropdownItem>
          <DropdownItem active={this.isActiveFilter('lastYear')} onClick={() => this.changePeriod('lastYear')}>{tr('lastYear')}</DropdownItem>
          <DropdownItem active={this.isActiveFilter('customYear')}>
            <Form inline>
              <FormGroup>
                <Label>{tr('year')}:</Label>
                <div className={'inputButtonWrapper publishingDateWrapper'} onClick={(e: any) => {
                  if (e && (e.target.getAttribute('type') == 'text' || e.target.getAttribute('type') == 'button')) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }
                  return true;
                }}>
                  <Datepicker
                    useWeekdaysShort
                    dateFormat="yyyy"
                    maxDate={moment().subtract(1, 'day').toDate()}
                    selected={this.isActiveFilter('customYear') ? (statsFilter.period ? new Date(statsFilter.period) : null) : null}
                    isClearable={false}
                    placeholderText={'Choose a year'}
                    customInput={<Input type={'text'} placeholder={tr('Choose a year')} readOnly />}
                    onChange={(d: Date, e: any) => {
                      let dateStr = '';
                      if (d) {
                        try {
                          const m = moment(d);
                          if (m.isValid()) {

                            dateStr = m.format('YYYY');
                            this.changePeriod(dateStr);

                          }
                        } catch (error) {
                          this.changePeriod('');
                        }
                      } else {
                        this.changePeriod('');
                      }
                      // if (e) {
                      //   e.preventDefault();
                      //   e.stopPropagation();
                      // }
                      // return false;
                    }}
                    onChangeRaw={(e) => {
                      if (e) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                      return false;

                    }}
                    showYearPicker
                    shouldCloseOnSelect={true}
                  />
                </div>
                {/* <Input placeholder={`Choose a month`} defaultValue={this.isActiveFilter('customMonth')?statsFilter.period:''}
                  onClick={(e:any)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }}
                  onKeyDown={(e:any)=>{
                    if(e.key == 'Enter' || e.which == 13 || e.keyCode == 13){
                      console.log(e.target.value);
                      e.preventDefault();
                      e.stopPropagation();
                      this.changePeriod(e.target.value);
                      e.target.parentElement.click();
                      return false;
                    
                    }
                  }}
                /> */}
              </FormGroup>
            </Form>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem active={this.isActiveFilter('since_app_start')} onClick={() => this.changePeriod('since_app_start')} >Since app start</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }
  renderAudienceFilter() {
    const { audienceFilterHandler, statsFilter, globalAudience, audiences } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');

    return (
      <Input type="select" value={statsFilter.audience} onChange={(e) => { audienceFilterHandler(parseInt(e.target.value)) }} style={{ display: 'inline', width: '180px', marginLeft: '10px' }}>
        {globalAudience ?
          <option value="1">{tr('all_employees')}</option>
          : ''
        }
        {audiences.map((a) => {
          if (_.toNumber(a.id) != 1 && !a.excludeFromAnalytics) {
            return <option key={a.id} value={a.id}>{a.label}</option>
          }
        })}
        {this.renderInvalidAudienceFilter()}
      </Input>
    )
  }
  calcAudiences = () => {
    const { audiences, globalAudience } = this.props;
    const audiencesIds: number[] = [];
    if (globalAudience) {
      audiencesIds.push(1);
    }
    audiences.map((a) => {
      if (a.id && a.id != 1 && !a.excludeFromAnalytics) {
        audiencesIds.push(a.id);
      }
    })

    return audiencesIds;
  }
  renderInvalidAudienceFilter = () => {
    const audiencesIds = this.calcAudiences();
    const { audience } = this.props.statsFilter;
    if (!_.includes(audiencesIds, _.toNumber(audience))) {
      return (<option key={audience} value={audience}>Invalid Audience ({audience})</option>)
    }
    return null;
  }
  render() {
    const { magazine, periodFilterHandler, audienceFilterHandler, statsFilter } = this.props;
    const tr = Translations.Translate(Translations, 'FilterBar');

    return (
      <div id="ArticleDetailsFilterBar">
        {Translations.EnableTranslations(Translations)}
        <div className="separator"></div>
        <div className="filterWrapper">
          {/* <img src="/assets/icons/calendar.svg"  style={{marginRight: '10px' }} /> */}
          <span style={{ fontSize: '10px', letterSpacing: '0.6px', textTransform: 'uppercase' }}>{tr('period')}:</span>
          <ActivePeriodSelector {...{ magazine, periodFilterHandler, statsFilter, toggleCustomClass: 'smallSelect' }} />
          {/* {this.renderAdvancedPeriodFilter()} */}
        </div>

        <div className="filterWrapper">
          {/* <img src="/assets/icons/target.svg"  style={{marginRight: '10px' }} /> */}
          <span style={{ fontSize: '10px', letterSpacing: '0.6px', textTransform: 'uppercase', marginLeft: '10px' }}>{tr('audience')}:</span>
          {this.renderAudienceFilter()}
        </div>

      </div>
    );
  }
}

export default FilterBar;
