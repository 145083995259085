import { Translation } from '../../../../../domain'
import { NotificationCenterChannelConfig } from '../NotificationCenter/domain'

export interface CICChannel {
  id: number,
  name: string,
  label: string,
  channelType: string,
  audiences: number[],
  sources?: number[],
  tags?: string[]

  childChannels?: CICChannel[],
  sort?: number,
}

export interface ChannelDetail {
  id: number,
  name: string,
  label: string,
  channelType: string,
  audiences?: number[],
  sources?: number[]
  tags?: string[]

  channelInfo?: string,
  combineAudiencesWithAnd?: boolean | null
  minVersion: string | null,
  maxVersion: string | null,
  groups?: string,
  targeting: ChannelTargeting,
  translations?: Translation[]
  notifications: NotificationCenterChannelConfig

}

export type ChannelTargeting = ChannelTargetingEntry[]

export type ChannelTargetingPlatform = 'ipad' | 'iphone' | 'android-tablet' | 'android-phone' | 'webapp' | 'none'

export interface ChannelTargetingEntry {
  platform: ChannelTargetingPlatform,
  minVersion: string | null,
  maxVersion: string | null,
}

export const NEW_CHANNEL_ID = -1
export const ROOT_NODE_ID = 0
