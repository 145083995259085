import _ from 'lodash';

import { SwalSuccess, SwalError, SwalDelete } from '.';

export const EdErrorHandler = (error: any, descr: string, hideErrorDetails?: boolean) => {
    let message = error.message;

    if (error.response && error.response.data && !((error.response.data) instanceof Blob)) {

        message = error.response.data.error ? handleJoiError(error.response.data.error)
            : error.response.data.message ? error.response.data.message
                : stringifyIfJson(error.response.data)
    }

    SwalError.fire({
        // type: 'error',
        showConfirmButton: error?.response?.status === 498 ? false : true,
        title: 'Something went wrong!',
        text: `Error in ${descr}`,
        footer: hideErrorDetails ? undefined : `<div class="alert alert-danger" style="width:100%">[${_.get(error, `response.status`, 500)}] ${message}</div>`
    });
}

export const EdError = (message: string, status?: number) => {
    return {
        response: {
            status,
            data: {
                message
            }
        }
    }
}

const stringifyIfJson = (str: string) => {
    try {
        return JSON.stringify(str);
    } catch (error) {
        return str;
    }
}

const handleJoiError = (error: any): string => {
    if (error.isJoi) {
        return `Joi validation error! <p>Please contact immediately with editors-dashboard support.</p>`
    } else {
        return stringifyIfJson(error);
    }
}