import React, { useState } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import { GenericModal } from '../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../components/GenericModal/GenericModalHeader';
import { Controller, useForm } from 'react-hook-form';
import { EdErrorHandler, LoadingButton, SwalSuccess } from '../../widgets';
import styles from './NcDeviceCreateModal.module.scss';
import DataTable, { ExtendedColumnDescription } from '../../components/DataTable/DataTable';
import { NcDevice, NcGroup } from './NarrowcastingDomain';
import { SelectRowProps } from 'react-bootstrap-table-next';
import _ from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from '../../apis';

interface NewGroupFormData {
  id: number
  label: string
}

interface Props {
  magazine: number
  groups: NcGroup[]
  group: NcGroup
  devices: NcDevice[]
  onClose: () => void
  onCreateAndSelect?: (groupId: number) => void
}

export const NcGroupDetailsModal = ({ magazine, groups, group, devices, onClose, onCreateAndSelect }: Props) => {

  const queryClient = useQueryClient();

  const [selectedDevices, setSelectedDevices] = useState<number[]>(group.device_ids || []);
  const [showOnlySelectedDevices, setShowOnlySelectedDevices] = useState<boolean>(false);

  const isNewGroup = group.id === -1;

  const ncGroupSaveMutation = useMutation({
    mutationKey: ['ncGroupSave'],
    mutationFn: (g: NcGroup) => API.narrowcasting.saveNcGroup(magazine, g),
    onError: (error, g) => {
      EdErrorHandler(error, `${isNewGroup ? 'creating' : 'saving'} group`);
    },
    onSuccess: ({ data }, g) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `Group <${g.label}> has been ${isNewGroup ? 'created' : 'saved'} successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['ncGroups'] })
      queryClient.invalidateQueries({ queryKey: ['ncDevices'] })
      if (onCreateAndSelect) {
        onCreateAndSelect(data.groupId);
      }
      onClose();
    }
  })

  const { control, handleSubmit, reset, formState: { errors } } = useForm<NewGroupFormData>({
    defaultValues: {
      id: group.id,
      label: group.label,
    }
  });


  const onSave = handleSubmit(async (data) => {
    const g: NcGroup = {
      id: data.id,
      label: data.label,
      device_ids: selectedDevices,
      deleted: false,
      magazine
    }

    ncGroupSaveMutation.mutate(g);

  })

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'label',
      text: '',
      headerFormatter: () => {
        return (
          <div className={styles.showFilters}>
            <span className={showOnlySelectedDevices ? styles.showOption : styles.showSelectedOption} onClick={() => { setShowOnlySelectedDevices(false) }}>Show all</span>
            <span className={styles.filterSeparator}>/</span>
            <span className={!showOnlySelectedDevices ? styles.showOption : styles.showSelectedOption} onClick={() => { setShowOnlySelectedDevices(true) }}>Show selected</span>
          </div>
        )
      }
    },
  ];

  const selectRow: SelectRowProps<NcDevice> = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: [...selectedDevices],
    onSelect: (ncDevice, isSelect, rowIndex, e) => {
      const newSelected = isSelect ? [...selectedDevices, ncDevice.id] : _.filter(selectedDevices, (s) => s !== ncDevice.id);
      setSelectedDevices(newSelected);
    },
    onSelectAll: (isSelect, ncDevices, e) => {
      setSelectedDevices(isSelect ? [..._.map(ncDevices, 'id')] : [])
    }
  }

  return (
    <GenericModal isOpen={true} centered size={onCreateAndSelect ? undefined : 'lg'}>
      <GenericModalHeader
        onClose={onClose}
        title={isNewGroup ? `add new group` : `edit group`}
      />
      <ModalBody>
        <FormGroup>
          <Label style={{ fontWeight: 'bold' }}>Group name:</Label>
          <Controller
            name={'label'}
            control={control}
            rules={{ required: true, validate: (v: string) => !_.includes(_.map(_.filter(groups, (g) => g.id !== group.id), (g) => g.label), v) }}
            render={({ field }) => {
              return (
                <Input invalid={errors.label ? true : false} {...field} onChange={(e) => field.onChange(e.target.value)} />
              )
            }}
          />
          {errors.label && <FormFeedback>{errors.label.type == 'required' ? `Group name is required.` : `Group name already exists.`}</FormFeedback>}
        </FormGroup>
        {!onCreateAndSelect &&
          <>
            <hr />
            <FormGroup>
              <Label className={styles.groupLabel}>Devices</Label>
              <DataTable
                data={showOnlySelectedDevices ? _.filter(devices, (d) => _.includes(selectedDevices, d.id)) : devices}
                columns={columns}
                keyField={'id'}
                onRowClick={() => { }}
                selectRow={selectRow}
                hideSearchBar
                noPagination
              />
            </FormGroup>
          </>
        }
      </ModalBody>
      <ModalFooter>
        {!onCreateAndSelect &&
          <Button style={{ flex: 1 }} size={'lg'} outline color="secondary" onClick={onClose}>Cancel</Button>
        }
        <LoadingButton style={{ flex: 1 }} text={onCreateAndSelect ? 'save and select' : 'save'} loading={ncGroupSaveMutation.isLoading} onClick={onSave} />
      </ModalFooter>
    </GenericModal>
  )
}

