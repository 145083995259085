import { StatsFilter } from "../../domain";
import { dashApi, wrapAxios } from "../api";
import axios from 'axios';

export const loadChartData = (chart: string, magazine: number, params: StatsFilter): Promise<any> => {
    return wrapAxios(axios.post(`/api/${magazine}/analytics`, { chart: chart, params: params }));
};

export const loadAudienceKpi = (magazine: number, audience: number): Promise<any> => {
    return wrapAxios(axios.post(`/api/${magazine}/getAudienceKPI`, { audience }));
}