import './css/WidgetManager.min.css';

import React, { useState, Fragment, useEffect } from 'react';
import { Translations } from "../../translations";
import { Widget } from '../../../domain';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { OrtecLoader, EdErrorHandler, SwalDelete } from '../../widgets';
import { Col, Button } from 'reactstrap';
import { WidgetDetails } from './WidgetDetails';
import moment from 'moment';
import { SelectTypeModal } from './SelectTypeModal';
import * as Api from '../../api';
import { WidgetOverview } from './WidgetOverview';
import * as _ from 'lodash';
import { DataModal } from './DataModal';
import { API } from '../../apis';

const newRegistrationWidgetInstance: Widget = {
    id: '-1',
    magazine: 277,
    type: 'event-registration',
    title: '',
    config: {
        startDate: '',
        endDate: '',
        available_spots: 10
    }
}
const newCustomWidgetInstance: Widget = {
    id: '-1',
    magazine: 277,
    type: 'custom',
    title: '',
    config: {}
}

const typesWithAnalytics: string[] = [
    'event-registration',
    'confirmation',
]

const typesExcludedInAdvanced: string[] = [
    'confirmation',
]

export interface Props {
    magazine: number,
    analyticsMode?: boolean,
    selectHandler?: (w: any) => void
}

export function WidgetManager(props: Props) {

    const { magazine, selectHandler, analyticsMode } = props;
    const [widgets, setWidgets] = useState([] as Widget[]);
    const [loading, setLoading] = useState(true);
    const [editWidget, setEditWidget] = useState(undefined as Widget | undefined)
    const [selectTypeModal, setSelectTypeModal] = useState(false);
    const [showData, setShowData] = useState(undefined as Widget | undefined)

    useEffect(() => {
        loadWidgets();
    }, []);

    const tr = Translations.Translate(Translations, 'WidgetManager');

    const loadWidgets = async () => {
        try {
            setLoading(true);
            const resp = await API.widgets.getWidgets(props.magazine);
            setWidgets(analyticsMode ? _.filter(resp.data, (w: Widget) => _.includes(typesWithAnalytics, w.type)) : _.filter(resp.data, (w: Widget) => !_.includes(typesExcludedInAdvanced, w.type)));
        } catch (error) {
            EdErrorHandler(error, `loading widgets`);
        } finally {
            setLoading(false);
        }
    }

    const openWidgetDetailsModal = (widget: Widget) => {
        setEditWidget(widget);
    }
    const closeWidgetDetailsModal = () => {
        setEditWidget(undefined);
    }
    const openDataModal = (widget: Widget) => {
        setShowData(widget);
    }
    const closeDataModal = () => {
        setShowData(undefined);
    }
    const saveWidgetHandler = async (widget: Widget) => {
        try {
            await API.widgets.saveWidget(magazine, _.omit(widget, 'data') as Widget);
            closeWidgetDetailsModal();
            loadWidgets();
        } catch (error) {
            EdErrorHandler(error, `saving widget`);
        }
    }
    const deleteWidgetHandler = async (widget: Widget) => {
        SwalDelete.fire({
            // type: 'warning',
            title: 'Are you sure?',
            text: `This action will delete widget: ${widget.title}`,
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            focusCancel: true,
            // focusConfirm: false,
            // animation: false,
            // customClass: {
            //   popup: 'animated pulse',
            //   confirmButton: 'bg-danger',
            // },
        }).then(async (result) => {
            if (result.value) {
                try {
                    await API.widgets.deleteWidget(magazine, widget.id);
                    closeWidgetDetailsModal();
                    loadWidgets();
                } catch (error) {
                    EdErrorHandler(error, `deleting widget`);
                }
            }
        });
    }
    const openSelectTypeModal = () => {
        setSelectTypeModal(true);
    }
    const closeSelectTypeModal = () => {
        setSelectTypeModal(false);
    }
    const createInstanceHandler = (type: string) => {
        closeSelectTypeModal();
        switch (type) {
            case 'event-registration':
                return openWidgetDetailsModal({ ...newRegistrationWidgetInstance, magazine });
            default:
                return openWidgetDetailsModal({ ...newCustomWidgetInstance, type, magazine });
        }
    }

    return (
        <div id="WidgetManager" style={{ width: "100%" }}>
            {loading ? <OrtecLoader /> :
                <WidgetOverview {...{ widgets, selectHandler, openWidgetDetailsModal, openSelectTypeModal, analyticsMode, deleteWidgetHandler, openDataModal, magazine }} />
            }
            {editWidget && <WidgetDetails widget={editWidget} closeHandler={closeWidgetDetailsModal} saveHandler={saveWidgetHandler} />}
            {selectTypeModal && <SelectTypeModal closeHandler={closeSelectTypeModal} createHandler={createInstanceHandler} />}
            {showData && <DataModal magazine={magazine} widget={showData} closeHandler={closeDataModal} />}
        </div>
    )
}