import classNames from 'classnames';
import React, { Fragment } from 'react'
import { Button } from 'reactstrap'
import { OrtecLoader } from '..'
import styles from './LoadingButton.module.scss';

interface Props {
  text: string
  loading: boolean | undefined
  color?: string
  block?: boolean
  onClick: () => void
  disabled?: boolean
  style?: React.CSSProperties
  className?: string
}

export const LoadingButton = ({ text, loading, color, block, onClick, disabled, style, className }: Props) => {

  const outline = (color == 'secondary') ? true : false;

  return (
    <Button style={style} block={block} color={color || "primary"} className={classNames(className, 'btn-lg', styles.LoadingButton, { [styles.isLoading]: loading })} disabled={loading || disabled} onClick={onClick} outline={outline}>
      <span className={styles.buttonText}>{text}</span>
      <div className={styles.loaderWrapper}><OrtecLoader size={'icon'} /></div>
    </Button>
  )
}
