import React, { ReactNode } from 'react';
import styles from './NcSettingsConfigurationRow.module.scss';
import classNames from 'classnames';

interface Props {
  label: string
  children: ReactNode,
  imageSelectRow?: boolean
}


export const NcSettingsConfigurationRow = ({ label, children, imageSelectRow }: Props) => {
  return (
    <div className={styles.NcSettingsConfigurationRow}>
      <div className={classNames({ [styles.imageSelectRow]: imageSelectRow ? true : false })}>{label}</div>
      <div>{children}</div>
    </div>
  )
}
