import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, FormText, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import { GenericModal } from '../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../components/GenericModal/GenericModalHeader';
import { EdErrorHandler, LoadingButton, OrtecLoader } from '../../../../widgets';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

import styles from './AdvancedConfiguration.module.scss';
import ReactSelectTagsInput from 'react-select/creatable';
import _ from 'lodash';
import { API } from '../../../../apis';
import { NotificationCenterConfigOptions } from './domain';

const delimiters = [
  ' ',
  ',',
  'Enter',
  'Tab'
];

interface TagOption {
  label: string,
  value: string
}


interface Props {
  magazine: number,
  onClose: () => void
}

export const AdvancedConfiguration = ({ magazine, onClose }: Props) => {
  const [advancedOptions, setAdvancedOptions] = useState<NotificationCenterConfigOptions>({});
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [tagsInputValue, setTagsInputValue] = useState('');

  useEffect(() => {
    loadAdvancedOptions();
  }, [])


  const loadAdvancedOptions = async () => {
    try {
      setLoading(true);
      const { data } = await API.notificationCenter.getNotificationCenterAdvancedOptions(magazine);
      setAdvancedOptions(data);
    } catch (error) {
      EdErrorHandler(error, `getting notification center advanced options`)
    } finally {
      setLoading(false);
    }
  }

  const onSave = async () => {
    try {
      setSaveLoading(true);
      await API.notificationCenter.saveNotificationCenterAdvancedOptions(magazine, advancedOptions);
      onClose();
    } catch (error) {
      EdErrorHandler(error, `saving notification center advanced options`)
    } finally {
      setSaveLoading(false);
    }
  }


  const handleTagsInputKeyDown: KeyboardEventHandler = (event) => {
    if (!tagsInputValue) {
      return;
    }
    if (_.includes(delimiters, event.key)) {
      setAdvancedOptions({ ...advancedOptions, uids: [...(advancedOptions.uids || []), tagsInputValue] })
      setTagsInputValue('');
      event.preventDefault();
      event.stopPropagation();
    }
  }

  return (
    <GenericModal isOpen centered toggle={onClose} size={'lg'}>
      <GenericModalHeader
        onClose={onClose}
        title={`advanced configuration`}
        icon={`/assets/icons/icon-content-feed.svg`}
      />
      <ModalBody style={{ minHeight: '400px' }}>
        {loading ? <OrtecLoader /> :
          <Form className={styles.advancedConfigurationForm}>
            <FormGroup row>
              <Label md={4}>Disable Android Notifications:</Label>
              <Col md={8} className={styles.verticalCenteredContent}>
                <Toggle checked={advancedOptions?.disableAndroidNotifications ? true : false} onChange={(e) => setAdvancedOptions({ ...advancedOptions, disableAndroidNotifications: e.target.checked })} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Include Anonymous:</Label>
              <Col md={8} className={styles.verticalCenteredContent}>
                <Toggle checked={advancedOptions?.includeAnonymous ? true : false} onChange={(e) => setAdvancedOptions({ ...advancedOptions, includeAnonymous: e.target.checked })} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Enable Comment Notifications:</Label>
              <Col md={8} className={styles.verticalCenteredContent}>
                <Toggle checked={advancedOptions?.enableCommentNotifications ? true : false} onChange={(e) => setAdvancedOptions({ ...advancedOptions, enableCommentNotifications: e.target.checked })} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Enable Mention Notifications:</Label>
              <Col md={8} className={styles.verticalCenteredContent}>
                <Toggle checked={advancedOptions?.enableMentionNotifications ? true : false} onChange={(e) => setAdvancedOptions({ ...advancedOptions, enableMentionNotifications: e.target.checked })} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Active since (days):</Label>
              <Col md={8} >
                <Input type={'number'} min={0} value={advancedOptions?.activeSinceDays} onChange={(e) => setAdvancedOptions({ ...advancedOptions, activeSinceDays: _.toNumber(e.target.value) })} />
                <FormText>Only users active within the given period will receive notifications.</FormText>
              </Col>
            </FormGroup>
            <hr />
            <FormGroup row>
              <Label md={4}>UIDs:</Label>
              <Col md={8}>
                <ReactSelectTagsInput
                  isMulti
                  isClearable
                  components={{
                    DropdownIndicator: null,
                  }}
                  menuIsOpen={false}
                  placeholder={`UIDs`}
                  inputValue={tagsInputValue}
                  onInputChange={(newValue) => { setTagsInputValue(newValue) }}
                  onChange={(newValue) => { setAdvancedOptions({ ...advancedOptions, uids: tagOptions2Strings(newValue) }) }}
                  value={strings2TagOptions(advancedOptions?.uids)}
                  onKeyDown={handleTagsInputKeyDown}
                />
                <FormText>Only these users will receive notifications. This feature is inteded to facilitate testing.</FormText>
              </Col>
            </FormGroup>
          </Form>
        }
      </ModalBody>
      <ModalFooter>
        {!loading &&
          <div className={styles.footer}>
            <Button color={'secondary'} outline block onClick={onClose}>cancel</Button>
            <LoadingButton
              text={'save'}
              loading={saveLoading}
              onClick={onSave}
            />
          </div>
        }
      </ModalFooter>
    </GenericModal>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const string2TagOption = (label: string): TagOption => {
  return {
    label,
    value: label
  }
}

const strings2TagOptions = (strings: string[] | undefined): TagOption[] => {
  return _.map(strings, (s) => string2TagOption(s));
}

const tagOption2String = (tagOption: TagOption): string => {
  return tagOption.value;
}
const tagOptions2Strings = (tagOptions: readonly TagOption[]): string[] => {
  return _.map(tagOptions, (o) => tagOption2String(o));
}

//TODO: make tags input generic reusable compoenent