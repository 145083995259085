import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import { API } from '../../apis';
import { EdErrorHandler, OrtecLoader } from '../../widgets';
import DataTable, { ExtendedColumnDescription } from '../DataTable/DataTable';
import { Editor } from './domain';
import styles from './EditorsSelector.module.scss';

interface Props {
  magazine: number,
  selected: string[],
  onEditorChange: (uid: string, checked: boolean) => void
  onMultipleEditorsChange: (uids: string[], checked: boolean) => void
}

export const EditorsSelector = ({ magazine, selected, onEditorChange, onMultipleEditorsChange }: Props) => {
  const [loading, setLoading] = useState(true);
  const [editors, setEditors] = useState<Editor[]>([]);

  useEffect(() => {
    loadEditors();
  }, [])

  const loadEditors = async () => {
    try {
      setLoading(true);
      const { data } = await API.targeting.getEditors(magazine);
      setEditors(data);
    } catch (error) {
      EdErrorHandler(error, `getting editors`);
    } finally {
      setLoading(false);
    }
  }

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'dfEnabled',
      isDummyField: true,
      text: '',
      headerStyle: { width: '100px' },
      headerFormatter: () => {
        const checked = _.isEmpty(_.filter(editors, (e) => !_.includes(selected, e.uid)));
        return (
          <Toggle checked={checked} onChange={(e) => onMultipleEditorsChange(_.map(editors, 'uid'), e.target.checked)} />
        )
      },
      formatter: (cell: string[], row: Editor, rowIndex: number, formatExtraData: any) => {
        const checked = _.includes(selected, row.uid);
        return (
          <Toggle checked={checked} onChange={(e) => formatExtraData.onEditorChange(row.uid, e.target.checked)} />
        )
      },
      formatExtraData: { onEditorChange }
    },
    {
      dataField: 'uid',
      text: 'UID'
    },
    {
      dataField: 'firstName',
      text: 'First Name'
    },
    {
      dataField: 'lastName',
      text: 'Last Name'
    },
  ];


  return (
    <div className={styles.EditorsSelector}>
      {loading ? <OrtecLoader /> :
        <DataTable
          key={`editors-${selected.join()}`}
          data={editors}
          keyField={'uid'}
          columns={columns}
          onRowClick={() => { }}
          noPagination
          onlySearchBar
        />
      }
    </div>
  )
}
