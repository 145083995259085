import React from 'react';
import styles from './ChannelsSelect.module.scss';
import Select from 'react-select';
import _ from 'lodash';

interface Props {
  channels: Channel[],
  selected: string,
  onChange: (id: string) => void,
  placeholder?: string
  channelGraphName?: string
}

interface SelectOption {
  value: string,
  label: string
}

export interface Channel {
  channel: number,
  label: string,
  name: string
}

export const ChannelsSelect = ({ channels, selected, onChange, placeholder, channelGraphName }: Props) => {

  const channelsToSelectOptions = (): SelectOption[] => {
    const chans = _.map(channels, (c) => {
      return {
        value: _.toString(c.channel),
        label: `[${c.channel}] - ${decideNameText(c)}`,
      }
    });
    return [...chans]
  }

  const channelsValue = (): SelectOption | undefined => {
    const options = channelsToSelectOptions();

    if (!selected || selected == '0') {
      return undefined;
    }
    return _.find(options, (o) => {
      return selected == o.value;
    })
  }

  const decideNameText = (c: Channel): string => {

    switch (channelGraphName) {
      case 'nameOnly':
        return c.name || c.label || '';
      case 'labelOnly':
        return c.label || c.name || '';
      case 'nameLabel':
        return `${c.name || ''} ${c.label ? `(${c.label})` : ''}`;
      case 'labelName':
        return `${c.label || ''} ${c.name ? `(${c.name})` : ''}`;
      default:
        return c.label ?? c.name;
    }
  }

  return (
    <div className={styles.channelsSelect}>
      <Select
        // styles={isInvalid ? { container: () => { return { border: '1px solid red', borderRadius: '5px' } } } : undefined}
        // isDisabled={readOnly}
        options={channelsToSelectOptions()}
        placeholder={placeholder ?? `Select a channel`}
        isClearable={true}
        value={channelsValue()}
        onChange={(o: SelectOption) => {
          onChange(o?.value || '')
        }}
      />
    </div>
  )
}
