import React, { useState, useEffect } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label, Input } from "reactstrap";
import { Widget, UsersList } from '../../../domain';
import _ from 'lodash';
import { OrtecLoader, EdErrorHandler } from '../../widgets';
import { BootstrapTable, ExportCSVButton, TableHeaderColumn } from 'react-bootstrap-table';

import moment from 'moment';
import { API } from '../../apis';
import { initTippy } from '../../components/TippyReact';
import { DateHelper } from '../../utils';

const FileDownload = require('js-file-download');


export interface Props {
  magazine: number,
  closeHandler: () => void,
  widget: Widget
}

export interface UserListWithRegistrations extends UsersList {
  registrations?: any[],
  confirmations?: any[],
}

export function DataModal(props: Props) {
  const { magazine, closeHandler, widget } = props;

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(undefined as undefined | UserListWithRegistrations);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    initTooltips();
  }, [loading]);

  const initTooltips = () => {
    initTippy('#DataModal')
  }

  const getUsers = async () => {
    switch (widget.type) {
      case 'event-registration':
        return getRegisteredUsers();
      case 'confirmation':
        return getConfirmedUsers();
      default:
        setUsers(undefined);
        setLoading(false);
    }
  }

  const getRegisteredUsers = async () => {
    try {
      setLoading(true);
      const resp = await API.widgets.getRegisteredUsers(magazine, widget.id);
      setUsers(resp.data);
    } catch (error) {
      EdErrorHandler(error, `getting registered users`);
    } finally {
      setLoading(false);
    }
  }

  const getConfirmedUsers = async () => {
    try {
      setLoading(true);
      const resp = await API.widgets.getConfirmedUsers(magazine, widget.id);
      setUsers(resp.data);
    } catch (error) {
      EdErrorHandler(error, `getting confirmed users`);
    } finally {
      setLoading(false);
    }
  }

  const exportFileName = () => {
    switch (widget.type) {
      case 'event-registration':
        return `mag-${magazine}-event-${widget.title}-registeredUsers.csv`;
      case 'confirmation':
        return `mag-${magazine}-article-${widget.article?.id || widget.title}-confirmedUsers.csv`;;
      default:
        return `mag-${magazine}-widget-${widget.title}-users.csv`;
    }
  }

  const exportUsers = async (data: any[], fields: Array<{ label: string, value: string }>,) => {

    try {
      setLoading(true);
      const { data: csv } = await API.exportData.csv(magazine, data, fields);
      FileDownload(csv, exportFileName());
    } catch (error) {
      EdErrorHandler(error, `exporting registered users`);
    } finally {
      setLoading(false);
    }
  }

  const usersToTableData = (): Array<{ id: string, name: string }> => {
    if (!users || _.isEmpty(users.uids)) {
      return [];
    }

    const anonymous = users.anonymous.length;

    const named = _.compact(_.map(users.named, (u) => {

      const registration = _.find(users.registrations, (r) => r.uid == u.uid);
      const confirmation = _.find(users.confirmations, (r) => r.uid == u.uid);

      return {
        id: u.uid,
        name: `${_.get(u, 'firstName', '')} ${_.get(u, 'lastName', '')}`,
        checkIn: registration ? registration.checkIn : null,
        confirmed: confirmation ? confirmation.confirmed : null
      }
    }));

    if (anonymous) {
      return [...named, { id: '__anonymous', name: `${_.isEmpty(named) ? '' : '...and'} ${anonymous} ${_.isEmpty(named) ? '' : ' other'} anonymous user${anonymous > 1 ? 's' : ''}` }];
    }

    return named;

  }

  const registeredUsersToExportData = (): Array<{ uid: string, name: string }> => {
    if (!users || _.isEmpty(users.registrations)) {
      return [];
    }


    const registeredUsers = _.compact(_.map(users.registrations, (r) => {

      const namedUser = _.find(users.named, (u) => r.uid == u.uid);

      return {
        uid: r.uid,
        name: namedUser ? `${_.get(namedUser, 'firstName', '')} ${_.get(namedUser, 'lastName', '')}` : `-`,
        registered: r?.registered ? DateHelper.dateTimeToLocaleString(r.registered) : ``,
        checkedIn: r?.checkIn?.time ? DateHelper.dateTimeToLocaleString(r.checkIn.time) : ``
      }
    }));

    return registeredUsers;

  }

  const confirmedUsersToExportData = (): Array<{ uid: string, name: string }> => {
    if (!users || _.isEmpty(users.confirmations)) {
      return [];
    }


    const confirmedUsers = _.compact(_.map(users.confirmations, (r) => {

      const namedUser = _.find(users.named, (u) => r.uid == u.uid);

      return {
        uid: r.uid,
        name: namedUser ? `${_.get(namedUser, 'firstName', '')} ${_.get(namedUser, 'lastName', '')}` : `-`,
        confirmed: r?.confirmed ? DateHelper.dateTimeToLocaleString(r.confirmed) : ``,
      }
    }));

    return confirmedUsers;

  }

  const checkInFormatter = (cell: any, row: any) => {
    if (!cell) {
      return '';
    }

    const time = DateHelper.dateTimeToLocaleString(cell.time);

    return <i style={{ color: "#96BF0D" }} className="fa fa-calendar-check-o" data-tippy-content={time}></i>;

  }

  const confirmedFormatter = (cell: any, row: any) => {
    if (!cell) {
      return '';
    }

    const time = DateHelper.dateTimeToLocaleString(cell);

    return <i style={{ color: "#96BF0D" }} className="fa fa-calendar-check-o" data-tippy-content={time}></i>;

  }

  const renderToolbar = (props: any) => {
    return (
      <Col md={12}>{props.components.searchField}</Col>
    );
  }

  const usersToExportData = () => {
    switch (widget.type) {
      case 'event-registration':
        return registeredUsersToExportData();
      case 'confirmation':
        return confirmedUsersToExportData();
      default:
        return registeredUsersToExportData();
    }
  }


  const csvExtraFields = () => {
    switch (widget.type) {
      case 'event-registration':
        return [
          { label: 'Registered', value: 'registered' },
          { label: 'CheckedIn', value: 'checkedIn' },
        ];
      case 'confirmation':
        return [
          { label: 'Confirmed', value: 'confirmed' },
        ];
      default:
        return [];
    }
  }

  const exportCSV = () => {
    const fields = [
      { label: 'UID', value: 'uid' },
      { label: 'Name', value: 'name' },
      ...csvExtraFields()
    ];
    const exportData = usersToExportData();
    if (_.isEmpty(exportData)) {
      return;
    }
    exportUsers(exportData, fields);
  }


  const closeBtn = <img className="close" onClick={closeHandler} src="/assets/icons/bt-close.svg" />

  const spots = _.get(widget, 'config.available_spots', 0);
  const registrations = _.get(widget, 'data.registrations', 0);
  const confirmations = _.get(widget, 'data.confirmations', 0);

  const usersPrefix = () => {
    switch (widget.type) {
      case 'event-registration':
        return 'registered';
      case 'confirmation':
        return 'confirmed';
      default:
        return '';
    }
  }
  const usersTotal = () => {
    switch (widget.type) {
      case 'event-registration':
        return `${registrations}/${spots}`;
      case 'confirmation':
        return `${confirmations}`;
      default:
        return '-';
    }
  }

  const renderTimeColumn = () => {
    switch (widget.type) {
      case 'event-registration':
        return <TableHeaderColumn dataField='checkIn' dataFormat={checkInFormatter} width={'50px'}></TableHeaderColumn>
      case 'confirmation':
        return <TableHeaderColumn dataField='confirmed' dataFormat={confirmedFormatter} width={'50px'}></TableHeaderColumn>
      default:
        return null;
    }

  }


  const options = {
    toolBar: renderToolbar,
  };
  const tableData = usersToTableData();

  return (
    <Modal isOpen={true} centered id="DataModal" toggle={() => closeHandler()}>
      <ModalHeader><div className='headerTitle'><img src="/assets/icons/16/registration.svg" style={{ marginRight: '10px' }}></img> {usersPrefix()} Users ({usersTotal()})</div> {closeBtn}</ModalHeader>
      <ModalBody>
        {loading && <OrtecLoader size={'medium'} />}
        <div className="tableDiv">
          <BootstrapTable data={tableData} bordered={false} hover={false} version='4' options={options} search searchPlaceholder={`Search for name`}>
            <TableHeaderColumn dataField='id' hidden isKey></TableHeaderColumn>
            <TableHeaderColumn dataField='name'></TableHeaderColumn>
            {renderTimeColumn()}
          </BootstrapTable>
        </div>
      </ModalBody>
      {!_.isEmpty(tableData) &&
        <ModalFooter>
          <Button color="primary" block size="lg" onClick={() => exportCSV()} style={{ textTransform: 'uppercase' }}>Export CSV</Button>
        </ModalFooter>
      }
    </Modal>
  )
}