import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import classnames from 'classnames';
import '../../css/TabPageNavigation.min.css';

// import {PushNotifications, Forms} from './subpages';
import { Profile, CMSFeed } from '../../../domain';
import { Translations } from '../../translations';
import { Categories, Cms } from './subpages';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import * as ph from '../../utils/routerHelper';
import * as fh from '../../utils/feedsHelper';
import { ErrorMessage } from '../../Error';
import _ from 'lodash';
import { MediaManager } from '../../components/MediaManagerSelector';
import { EdScreenKey } from '../../accessRights/domain';
import { AccessRightsHelper } from '../../utils';
import { ED_PAGES } from '../../accessRights/accessRights';
import { Comments } from './subpages/comments/Comments';
import { ReportedContentModule as ReportedContent } from './subpages/reportedContent/ReportedContent';
import ReportedContentsWarningIcon from '../../components/ReportedContentsWarningIcon/ReportedContentsWarningIcon';

export interface Props extends RouteComponentProps {
  magazine: string,
  profile: Profile,
  updateProfile: () => void
}

export interface State {
  defaultFeed?: number
}

export class Content extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const feeds = props.profile.feeds || [];
    this.state = {
      defaultFeed: fh.findDefaultFeed(props.magazine, feeds)
    }
  }

  // NoAccessMessage = <div style={{padding:'10px'}}><ErrorMessage magazine={this.props.magazine} message={`You don\'t have access to ${this.state.defaultFeed?'this':'any'} CMS feed. Please contact your system administrator.`}/></div>


  hasAccess = (feed?: number): boolean => {
    return feed ? _.includes(this.props.profile.feedsIds, _.toString(feed)) : false;
  }
  toggleTab(tab: string) {
    ph.setQueryParams(this.props.history, { tab });
  }
  setFeed(feed: number) {
    ph.setQueryParams(this.props.history, { feed });
  }
  shouldUserAccessPage = (page: EdScreenKey) => {
    const { profile } = this.props;
    return AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, page, 'content');
  }
  render() {
    const { magazine, profile, updateProfile } = this.props;
    const { defaultFeed } = this.state;


    const activeTab = ph.getQueryParam(this.props.location, 'tab');

    const urlFeed = ph.getQueryParamNumber(this.props.location, 'feed');
    const activeFeed = urlFeed || defaultFeed;
    const activeFeedLabel = _.find(profile.feeds, (f) => f.id == activeFeed)?.label || '';

    if (!activeTab || (!urlFeed && defaultFeed)) {
      return <Redirect to={`content?tab=${activeTab || 'articles'}&feed=${defaultFeed}`} />
    }

    const hasAccess = this.hasAccess(activeFeed);
    const NoAccessMessage = <div style={{ padding: '10px' }}><ErrorMessage otherType={'info'} magazine={magazine} message={
      <div>
        Don't panic! You just don't have access to a CMS. There are two reasons for this to happen:

        <br /> 1. The Relevance CMS is not used (yet) by your organisation. Your organisation might use, for example but not limited to, WordPress, Sharepoint or Sitecore.
        <br /> 2. You don't have the rights to access the CMS. Ask your company app administrator or create a Freshdesk ticket if you should see the feed.
      </div>
    } /></div>



    const tr = Translations.Translate(Translations, 'Modules');

    return (
      <div id="Analytics" className={'TabPageNavigation'}>
        {Translations.EnableTranslations(Translations)}
        <div className="PageTitle">{tr('Content')}</div>
        <Nav tabs>
          {this.shouldUserAccessPage('articles') && <NavItem><NavLink className={classnames({ active: activeTab === 'articles' })} onClick={() => { this.toggleTab('articles') }}>{tr('articles')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('agenda') && <NavItem><NavLink className={classnames({ active: activeTab === 'agenda' })} onClick={() => { this.toggleTab('agenda') }}>{tr('agenda')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('categories') && <NavItem><NavLink className={classnames({ active: activeTab === 'categories' })} onClick={() => { this.toggleTab('categories') }}>{tr('categories')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('comments') && <NavItem><NavLink className={classnames({ active: activeTab === 'comments' })} onClick={() => { this.toggleTab('comments') }}>{tr('comments')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('mediaManager') && <NavItem><NavLink className={classnames({ active: activeTab === 'mediaManager' })} onClick={() => { this.toggleTab('mediaManager') }} >{tr('mediaManager')}</NavLink></NavItem>}
          {this.shouldUserAccessPage('reportedContent') && profile.edConfig?.reportedContent && <NavItem><NavLink className={classnames({ active: activeTab === 'reportedContent' })} onClick={() => { this.toggleTab('reportedContent') }} >{tr('reportedContent')}<ReportedContentsWarningIcon magazine={_.toNumber(magazine)}/></NavLink></NavItem>}
        </Nav>
        <TabContent activeTab={activeTab}>
          {this.shouldUserAccessPage('articles') && <TabPane tabId="articles">{activeTab === 'articles' && (hasAccess && activeFeed) ? <Cms key={`cms-${activeFeed}`} magazine={_.toInteger(magazine)} profile={profile} activeFeed={activeFeed} setFeed={this.setFeed.bind(this)} updateProfile={updateProfile} /> : NoAccessMessage}</TabPane>}
          {this.shouldUserAccessPage('agenda') && <TabPane tabId="agenda">{activeTab === 'agenda' && (hasAccess && activeFeed) ? <Cms key={`agenda-${activeFeed}`} magazine={_.toInteger(magazine)} profile={profile} activeFeed={activeFeed} setFeed={this.setFeed.bind(this)} agendaMode={true} updateProfile={updateProfile} /> : NoAccessMessage}</TabPane>}
          {this.shouldUserAccessPage('categories') && <TabPane tabId="categories">{activeTab === 'categories' && (hasAccess && activeFeed) ? <Categories key={`categories-${activeFeed}`} magazine={_.toInteger(magazine)} profile={profile} activeFeed={activeFeed} activeFeedLabel={activeFeedLabel} setFeed={this.setFeed.bind(this)} updateProfile={updateProfile} /> : NoAccessMessage}</TabPane>}
          {this.shouldUserAccessPage('comments') && <TabPane tabId="comments">{activeTab === 'comments' && <Comments key={`comments-${activeFeed}`} magazine={_.toInteger(magazine)} profile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('mediaManager') && <TabPane tabId="mediaManager">{activeTab === 'mediaManager' && <MediaManager magazine={_.toInteger(magazine)} profile={profile} />}</TabPane>}
          {this.shouldUserAccessPage('reportedContent') && profile.edConfig?.reportedContent && <TabPane tabId="reportedContent">{activeTab === 'reportedContent' && <ReportedContent magazine={_.toInteger(magazine)} profile={profile} />}</TabPane>}
        </TabContent>
      </div>
    );
  }
}
export const ContentWithRouter = withRouter(Content);
export default Content;
