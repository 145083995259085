// core-js/stable and 'regenerator-runtime/runtime' equal of @babel/polyfill (deprecated)
import 'core-js/stable';
import 'regenerator-runtime/runtime';


import React from 'react';
import ReactDOM from 'react-dom';

import App from './client/App';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);