import * as _ from 'lodash';
import { TranslateFunction } from '../../domain';
import en from './en';
import nl from './nl';

const translations: any = {
    en: en,
    nl: nl,
}

export const Translate = (language: string, n?: string): TranslateFunction => {
    const file = translations[language] ? translations[language] : translations['en'];
    if (n) { //if namespace is given
        // return (key) => (file[n] && file[n][key])?file[n][key]: (translations['en'][n] && translations['en'][n][key])?translations['en'][n][key]: file[key] || translations['en'][key] || key;
        return (key) => _.get(file, `${n}.${key}`) || _.get(translations['en'], `${n}.${key}`) || _.get(file, `${'Default'}.${key}`) || _.get(translations['en'], `${'Default'}.${key}`) || key;
    } else {  //if namespace is NOT given
        // return (key) => file[key]?file[key]:translations['en'][key] || key;
        return (key) => _.get(file, `${'Default'}.${key}`) || _.get(translations['en'], `${'Default'}.${key}`) || key;
    }
}