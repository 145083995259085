import React, { ReactNode } from 'react'
import _find from 'lodash/find';
import _get from 'lodash/get';
import _compact from 'lodash/compact';

import { media } from '@imgzine/imgzine-core-frontend';

import InContentLink from './DetailContentLink'


import styles from './DetailContent.module.scss'
import ErrorBoundary from './ErrorBoundary';
import { ArticleDetailStyles } from './ArticlePreview'
import { DetailMediaRenderer } from './DetailMediaRenderer';
import DetailContentAccordion from './DetailContentAccordion';



const HtmlToReact = require('html-to-react');
const HtmlToReactParser = HtmlToReact.Parser;
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);


///// STUBS
const UnsupportedComponent = (props: any) => {
  console.warn('unsupported component')
  console.log(props)
  return null
}
const DetailContentComponent = UnsupportedComponent

export interface Article { id: string, content: string, media: media.Media[] }


/////


const MEDIA_ID_ATTR = 'media_id' //case insensitive!
const MEDIA_TAG = 'o4c:media'
const COMPONENT_TAG = 'o4c:component'
const COMPONENT_ID_ATTR = 'id'

const mediaPlaceholderRegex = /\[imgzine:media=(\d+)\]/gi;

export interface AccordionPanel {
  title: string
  body?: ReactNode
}

interface DomNode {
  name?: string
  type: string
  data?: string
  attribs?: {}
  children?: DomNode[]
}

interface ProccessInstruction {
  shouldProcessNode: (node: DomNode) => boolean
  processNode: (node: DomNode, reactChildren: ReactNode[], index: number) => JSX.Element | null
}

const matchClass = (className: string) => (node: DomNode): boolean =>
  _get(node.attribs, 'class', '') === className


export const DetailContentRenderer = (props: { article: Article, mediaRenderer: DetailMediaRenderer, classes: ArticleDetailStyles }): JSX.Element => {
  const { article, classes, mediaRenderer } = props
  //step 1 covert `[imgzine:media=?]` to `<imgzine media_id="?"></imgzine>`
  const content = !article.content ? '' : article.content
    //convert p tags to divs
    .replace(/<p>/gi, (): string => `<div class=${styles.paragraph}>`)
    .replace(/<\/p>/gi, (): string => `</div>`)
    //convert imgzine placeholders to imgzine tags
    .replace(mediaPlaceholderRegex, (match: string, mediaId: string): string => `<${MEDIA_TAG} ${MEDIA_ID_ATTR}="${mediaId}"/></${MEDIA_TAG}>`)

  //step 2 html to reactElement 

  const proccessInstructions: ProccessInstruction[] = [{
    // a tags <imgzine> processing
    shouldProcessNode: (node: DomNode) => node.name === 'a',
    processNode: (node, reactChildren, index) =>
      <InContentLink
        key={`link_${index}`}
        currentArticle={article}
        to={_get(node.attribs, 'href', '')}
        id={_get(node.attribs, 'id', undefined) || _get(node.attribs, 'name', undefined)}
        className={classes.link}>{reactChildren}
      </InContentLink>
  }, {
    shouldProcessNode: (node: DomNode) => matchClass('accordion')(node),
    processNode: (node, reactChildren, index) => {
      const panels: AccordionPanel[] = _compact((node.children || [])
        .map((panelNode, panelIndex) => {
          if (panelNode.type !== 'tag') {
            return undefined
          }
          if (!matchClass('panel')(panelNode)) {
            console.warn('not a panel')
            return undefined
          }
          const panelElement = reactChildren[panelIndex]
          const titleNode = (panelNode.children || []).find(matchClass('title'))
          if (!titleNode) {
            console.warn('no panel title div')
            return undefined
          }
          const titleTextNode = (titleNode.children || []).find((tc: DomNode) => tc.type === 'text')
          if (!titleTextNode) {
            console.warn('no panel title div')
            return undefined
          }
          const title = titleTextNode.data || ''
          if (!title) {
            console.warn('no panel title text')
            return undefined
          }

          return { title, body: panelElement }
        }))
      return <DetailContentAccordion key={`accordion_${index}`} panels={panels} />
    }
  }, {
    shouldProcessNode: (node: DomNode) => node.name === MEDIA_TAG,
    processNode: (node: DomNode, reactChildren: ReactNode[], index: number) => {
      const mediaId = String(_get(node.attribs, MEDIA_ID_ATTR, ''))
      const mediaObj = _find(article.media, aMediaObj => aMediaObj.id !== undefined && String(aMediaObj.id) === mediaId);
      if (!mediaObj) {
        return null
      }
      return <ErrorBoundary key={`imgzine_media_${index}`}>{mediaRenderer(mediaObj)}</ErrorBoundary>
    }
  }, {
    // Custom <imgzine> processing
    shouldProcessNode: (node: any) => node.name === COMPONENT_TAG,
    processNode: (node: DomNode, reactChildren: ReactNode[], index: number) => {
      const id = String(_get(node.attribs, COMPONENT_ID_ATTR, ""))
      return <DetailContentComponent key={`inline_component_${id}_${index++}`} componentId={id} />
    }
  },
  {
    // Anything else
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode
  }]

  const reactElement = new HtmlToReactParser().parseWithInstructions(content, () => true, proccessInstructions);

  return reactElement || null
}

export default DetailContentRenderer

