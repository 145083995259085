import { HtmlTemplatesHelper } from ".";
import { NlTemplateConfiguration_Content } from "../domain";

const rowSpacer = `<tr height="10" style="height:10px;"><td></td></tr>`;

const articleDate = (configuration: NlTemplateConfiguration_Content): string => {
  const { enabled, textColor, backgroundColor, fontSize } = configuration.articleDate;

  if (!enabled) {
    return '';
  }

  return `
    <tr>
      <td 
        width="100%" 
        style="
          color:${textColor};
          font-size:${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px;
          background-color:${backgroundColor};
          font-weight: 400;
          text-decoration:none;
          text-align:left;
        "
      >
        {{cdate | date("DD/MM/YYYY")}}
      </td>
    </tr>
    ${rowSpacer}
  `;
}

const articleTitle = (configuration: NlTemplateConfiguration_Content): string => {
  const { enabled, textColor, backgroundColor, fontSize } = configuration.articleTitle;

  if (!enabled) {
    return '';
  }

  return `
    {% if title %}
      <tr>
        <td 
          width="100%"
          style="
            color:${textColor};
            font-size:${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px;
            background-color:${backgroundColor};
            text-decoration:none;
            text-align:left;
          "
        >
          {{title}}
        </td>
      </tr>
      ${rowSpacer}
      {% endif %}
  `;
}

const articleDescription = (configuration: NlTemplateConfiguration_Content): string => {
  const { enabled, textColor, backgroundColor, fontSize } = configuration.articleDescription;

  if (!enabled) {
    return '';
  }

  return `
    {% if thumbnail %}
    <tr>
      <td height="190" style="height:190px;" valign="top">
        <a href="{{link}}" style="color: #022938;text-decoration:none;">
          <img style="width:278px;height:190px" src="{{thumbnail}}"  width="278" height="190" alt="Article image" />
        </a>
      </td>
    </tr>
    ${rowSpacer}
    {% endif %}
    <tr>
      <td 
        style="
          color: ${textColor};
          font-size:${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px;
          background-color:${backgroundColor};
          text-align:left;
          line-height: 20px;
          text-decoration:none;
          font-weight: 400;
        "
      >
        {{descr | truncate(150) }}
      </td>
    </tr>
    ${rowSpacer}
    
  `;
}

const readMoreLink = (configuration: NlTemplateConfiguration_Content): string => {
  const { enabled, textColor, backgroundColor, fontSize, text } = configuration.readMoreLink;

  if (!enabled) {
    return '';
  }

  return `
    <tr>
      <td 
        width="100%" 
        style="
          color:${textColor};
          font-size:${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px;
          background-color:${backgroundColor};
          text-align:left;
          font-weight:700;
        "
      >
        <a href="{{link}}" style="color:${textColor}; font-size:${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px; font-weight:700;">${text}</a>
      </td>
    </tr>
    ${rowSpacer}
  `;
}

const parse = (configuration: NlTemplateConfiguration_Content): string => {
  return `
  <table width="278" role="presentation" border="0" cellpadding="0" cellspacing="0" style="width:278px;margin:auto;mso-padding-alt: 0px 0px 0px 0px;">
    ${articleDate(configuration)}
    ${articleTitle(configuration)}
    ${articleDescription(configuration)}
    ${readMoreLink(configuration)}
  </table>
  `
}

export const content2 = {
  parse
}