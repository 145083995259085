import React from 'react'
import { Button } from 'reactstrap'
import styles from './BatchActions.module.scss';
import { BatchAction } from './domain';

interface Props {
    selectedData: any[],
    onBatchAction: (action: BatchAction, selectedProfiles: any[]) => void,

}

export default function BatchActions({ selectedData, onBatchAction }: Props) {
    return (
        <div className={styles.batchActionsContainer}>
            <Button color={'secondary'} onClick={() => { onBatchAction('resetPassword', selectedData) }}>reset password of selected ({selectedData.length})</Button>
            <Button color={'secondary'} onClick={() => { onBatchAction('enable', selectedData) }}>enable selected profiles ({selectedData.length})</Button>
            <Button color={'secondary'} onClick={() => { onBatchAction('disable', selectedData) }}>disable selected profiles ({selectedData.length})</Button>
        </div>
    )
}
