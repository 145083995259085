import React, { IframeHTMLAttributes } from 'react'
import urlParse from 'url-parse';
import isNumber from 'lodash/isNumber'

import { media } from '@imgzine/imgzine-core-frontend';

import styles from './DetailMediaEmbeddedVideo.module.scss'


export interface Props {
  mediaObj: media.Video,
}

function safelyGetNumber(s: number | undefined) {
  if (s === undefined) {
    return undefined
  } else if (!isNumber(s)) {
    console.error(`${s} is not a number`)
    return undefined
  } else {
    return s
  }
}

export default (props: { mediaObj: media.Video }) => {
  const mediaObj = props.mediaObj

  const urlString = mediaObj.embedUrl || mediaObj.url
  if (!urlString) {
    return null
  }

  const classNames = mediaObj.type ? mediaObj.type.replace("-", " ") : '';

  const url = urlParse(urlString, {}, true);
  if (url.protocol === 'http:') {
    url.set('protocol', 'https:');
  }
  const queryObject = {
    ...url.query,
    ...mediaObj.urlParams
  };

  url.set('query', queryObject);

  const iframeAttrs: IframeHTMLAttributes<{}> = {
    ...{
      seamless: true,
      frameBorder: 0,
      allowFullScreen: true,
    },
    ...mediaObj.iframeAttrs || {},
    ...{
      src: url.toString(),
      className: classNames,
      type: 'text/html',
      width: '100%',
      height: '100%',
    }
  };


  const w = safelyGetNumber(mediaObj.w)
  const h = safelyGetNumber(mediaObj.h)
  const aspect = (w && h) ? h / w : 9 / 16

  //implied max-height:600px
  const maxWidth = 600 / aspect


  return <div className={styles.cont1}  >
    <div className={styles.cont2} style={{ maxWidth: `${maxWidth}px` }}>
      <div className={styles.cont3} style={{ paddingTop: `${100 * aspect}%` }}>
        <div className={styles.cont4}>
          <iframe title={'embeddedVideo'} {...iframeAttrs}></iframe>
        </div>
      </div>
    </div>
  </div>

}