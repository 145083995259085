import React, { useEffect, useState, useMemo } from 'react';
import { ArticleConfirmation, Profile, TranslateFunction } from '../../../../../../domain';
import { CmsAgendaItemMetaObject, CmsItemDetailed, CmsItemDetailedData, CmsItemDetailedWithOtherVariations, CmsMediaObject, CmsStage, Variations } from '../CmsDomain';
import { FullPageModal, FullPageModalContent, FullPageModalContentPart, FullPageModalHeader, FullPageModalSettings, FullPageModalSettingsSection, FullPageModalSettingsSectionRow } from '../../../../../components/FullPageModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../../../apis';
import { EdErrorHandler, LoadingButton, Swal, SwalDelete, SwalSuccess } from '../../../../../widgets';
import { Control, Controller, useController, useForm } from 'react-hook-form';
import { Alert, Badge, Button, Input, Label } from 'reactstrap';

import styles from './CmsItemDetails.module.scss';
import { MediaSelector, QrModal, TippyReact } from '../../../../../components';
import { CmsPublishingDate } from './components/CmsPublishingDate';
import _ from 'lodash';
import { CategoriesHelper, MediaHelper } from '../../../../../utils';
import { Translations } from '../../../../../translations';
import { CmsFeaturedImage } from './components/CmsFeaturedImage';
import { Bucket, MediaType } from '../../../../../components/MediaManagerSelector/domain';
import { CmsAttachments } from './components/CmsAttachments';
import { CmsContentEditor } from './components/CmsContentEditor';
import { CmsCategoriesSection } from '../../categories/CmsCategoriesSection';
import { CmsTags } from './components/CmsTags';
import { CmsConfirmationSection } from './components/CmsConfirmationSection';
import { CmsPreviewer } from '../CmsPreviewer/CmsPreviewer';
import { CmsVariations } from './CmsVariations/CmsVariations';
import { CmsVariationFormData } from './CmsVariations/CmsVariationDetails';
import { CmsAgendaItemMeta } from './components/CmsAgendaItemMeta';
import moment from 'moment';
import Toggle from 'react-toggle';
import { nonPrimaryVariationLanguage } from '../CmsOverview/CmsItemsOverviewTable';
import { generateUniversalLink } from '../../../../../utils/genericHelper';
import { useMagazineFeedBuckets } from '../../../../../components/MediaManagerSelector/MediaManagerSelectorHelper';
import useMagazineBuckets from '../../../../../components/MediaManagerSelector/useMagazineBuckets';
import { CmsPersonalizedFeedSection } from './components/CmsPersonalizedFeedSection';
import { getImageSize } from 'react-image-size';
import { CmsPublishDialog } from '../CmsPublishDialog/CmsPublishDialog';
import { CmsNavigationPrompt } from './components/CmsNavigationPrompt';

export interface CmsFeedInfo {
  id: number,
  label: string,
  isPersonalized?: boolean,
  variations?: Variations,
}

export interface CmsFeaturedImageFormData {
  url: string,
  alt: string,
}
export interface CmsItemFormData {
  title: string,
  intro: string,
  author: string,
  link: string,
  content: string,
  categories: string[],
  tags: string[],
  attachments: CmsMediaObject[],
  featuredImage: CmsFeaturedImageFormData,
  publicationDate: Date | null,
  confirmation?: ArticleConfirmation,
  meta?: CmsAgendaItemMetaObject,
  status: boolean,
  target_uid?: string,
}

export interface MediaSelectorConfig {
  enabled: boolean,
  multipleSelection: boolean,
  target: 'featured' | 'attachments' | 'inline',
  targetTypes?: Array<'images' | 'audios' | 'videos'>,
  bucket: string,
  buckets: Bucket[]
}

interface Props {
  magazine: number,
  profile: Profile,
  activeFeed: CmsFeedInfo,
  cmsStage: CmsStage,
  existingTags: any[],
  itemId: string,
  agendaMode?: boolean,
  onClose: (publishedArticleId?: string) => void
}

export const CmsItemDetails = ({ magazine, profile, activeFeed, cmsStage, existingTags, itemId, agendaMode, onClose }: Props) => {

  const [mediaSelectorMode, setMediaSelectorMode] = useState<'featured' | 'attachments' | undefined>(undefined);

  const [openPublishDialog, setOpenPublishDialog] = useState<boolean>(false);

  const mediaSelectorConfig = useMemo<{ multipleSelection: boolean, targetTypes?: MediaType[] } | undefined>(
    () => {
      switch (mediaSelectorMode) {
        case 'attachments':
          return {
            targetTypes: undefined,
            multipleSelection: true
          }
        case 'featured':
          return {
            multipleSelection: false,
            targetTypes: ['images'],
          }
        case undefined:
          return undefined
      }
    },
    [mediaSelectorMode]
  );

  const [previewItem, setPreviewItem] = useState<CmsItemDetailedWithOtherVariations>();
  const [previewLoading, setPreviewLoading] = useState<boolean>(false);
  const [cmsItemId, setCmsItemId] = useState<string | number>();
  const [qrModal, setQrModal] = useState<boolean>(false);

  const isNew = (itemId === '-1');
  const isDraft = (cmsStage === 'drafts');

  const magazineFeedBucketsQ = useMagazineFeedBuckets(magazine);
  const allBuckets = useMagazineBuckets(magazine, profile, magazineFeedBucketsQ.data);

  const cmsBuckets = useMemo(() => {
    const cmsBucketKeys = [
      `${magazine}-feed-${activeFeed.id}`,
      `${magazine}_cms`,
    ];
    return _.compact(cmsBucketKeys.map((k) => {
      return _.find(allBuckets, (b) => b.key === k);
    }))
  }, [magazine, activeFeed.id, allBuckets]);

  const queryClient = useQueryClient();

  const cmsItemQuery = useQuery({
    queryKey: ['cmsItem', itemId],
    enabled: !isNew,
    queryFn: async () => {
      try {
        const { data } = await API.cms.getCmsItem(magazine, activeFeed.id, cmsStage, itemId);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting CMS item`);
      }
    }
  });

  const { control, handleSubmit, getValues, reset, formState: { errors, isDirty } } = useForm<CmsItemFormData>({
    defaultValues: itemDetailedDataToFormData(magazine, profile, {}, activeFeed, agendaMode)
  });

  const { field: featuredImageField, fieldState: featuredImageFieldState } = useController({ name: 'featuredImage', control });
  const { field: attachmentsField, fieldState: attachmentsFieldState } = useController({ name: 'attachments', control });
  const { field: publicationDateField, fieldState: publicationDateFieldState } = useController({ name: 'publicationDate', control });


  useEffect(() => {
    if (cmsItemQuery.data) {
      reset(itemDetailedDataToFormData(magazine, profile, cmsItemQuery.data.data, activeFeed, agendaMode, cmsItemQuery.data.target_uid))
      setCmsItemId(cmsItemQuery.data.data.id);
    }
  }, [cmsItemQuery.data, reset, itemId, magazine, profile, activeFeed, agendaMode]);

  const nonPrimaryVariationWarningLanguage = useMemo(() => {
    if (!cmsItemQuery.data) {
      return undefined;
    }
    return nonPrimaryVariationLanguage(activeFeed.variations, cmsItemQuery.data?.variation)
  }
    , [cmsItemQuery.data, activeFeed])

  const universalLink = useMemo(() => {
    if (isNew || isDraft || !cmsItemQuery.data?.data.id) {
      return undefined;
    }
    return generateUniversalLink(profile.appMeta, `/a/${cmsItemQuery.data?.data.id}`);
  }, [cmsItemQuery.data, isNew, isDraft, profile.appMeta])

  const loading = cmsItemQuery.isFetching;

  const titleHasErrors = errors.title ? true : false;
  const metaHasErrors = errors.meta ? true : false;
  const targetHasErrors = errors.target_uid ? true : false;
  const hasErrors = titleHasErrors || metaHasErrors || targetHasErrors;

  const cmsItemSaveMutation = useMutation({
    mutationKey: ['ncItemUpdate'],
    mutationFn: (d: CmsItemDetailed) => API.cms.saveCmsItem(magazine, activeFeed.id, cmsStage, d, true),
    onError: (error, s) => {
      EdErrorHandler(error, `saving CMS item`);
    },
    onSuccess: (data, d) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `CMS item <${d.data.title}> has been saved successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['cmsItems', magazine, activeFeed.id, cmsStage, agendaMode] })
      onClose();
    }
  })

  const cmsDraftPublishMutation = useMutation({
    mutationKey: ['ncDraftPublish'],
    mutationFn: (d: CmsItemDetailed) => API.cms.publishCmsDraft(magazine, activeFeed.id, d),
    onError: (error, s) => {
      EdErrorHandler(error, `publishing CMS draft`);
    },
    onSuccess: (resp, d) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `CMS draft <${d.data.title}> has been published successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['cmsItems', magazine, activeFeed.id, cmsStage, agendaMode] });
      onClose(resp.data.publishedExternalId);
    }
  })

  const cmsDraftDeleteMutation = useMutation({
    mutationKey: ['cmsDraftDelete', itemId],
    mutationFn: (itemId: string) => API.cms.deleteCmsDraft(magazine, activeFeed.id, itemId),
    onError: (error, d) => {
      EdErrorHandler(error, `deleting CMS draft`);
    },
    onSuccess: (data, d) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `CMS draft has been deleted successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      onClose();
      queryClient.invalidateQueries({ queryKey: ['cmsItems', magazine, activeFeed.id, cmsStage, agendaMode] })
    }
  });


  const onCloseMediaSelector = () => {
    setMediaSelectorMode(undefined)
  }

  const selectMediaHandler = (mediaUrl: string[], file?: any[]) => {
    if (_.isEmpty(mediaUrl)) {
      return;
    }

    if (mediaSelectorMode === 'featured') {
      featuredImageField.onChange({ ...featuredImageField.value, url: mediaUrl[0] })
      onCloseMediaSelector();
      return;
    }
    if (mediaSelectorMode === 'attachments') {
      attachmentsField.onChange([...attachmentsField.value, ...convertSelectedMediaToAttachments(mediaUrl, file)])
      onCloseMediaSelector();
      return;
    }

  }

  const onPreview = handleSubmit(async (data) => {
    const itemDetailed = await formDataToItemDetailed(magazine, itemId, activeFeed, data, agendaMode);
    try {
      setPreviewLoading(true);
      const { data: itemToBePreviewed } = await API.cms.previewCmsItem(magazine, activeFeed.id, cmsStage, itemDetailed);
      setPreviewItem(itemToBePreviewed);
    } catch (error) {
      EdErrorHandler(error, `previewing current item`);
    } finally {
      setPreviewLoading(false);
    }
  });

  const onSave = handleSubmit(async (data) => {

    const itemToBeSaved: CmsItemDetailed = await formDataToItemDetailed(magazine, itemId, activeFeed, data, agendaMode);

    cmsItemSaveMutation.mutate(itemToBeSaved);

    reset(undefined, { keepValues: true });

  });

  const onOpenPublishDialog = handleSubmit(async (data) => {
    setOpenPublishDialog(true);
  });

  const onClosePublishDialog = () => {
    setOpenPublishDialog(false);
  }

  const onPublish = (date: Date | null) => {
    publicationDateField.onChange(date);
    publish();
    onClosePublishDialog();
  }

  const publish = handleSubmit(async (data) => {

    const draftToBePublished: CmsItemDetailed = await formDataToItemDetailed(magazine, itemId, activeFeed, { ...data, status: true }, agendaMode);

    cmsDraftPublishMutation.mutate(draftToBePublished);

    reset(undefined, { keepValues: true });

  });

  const onDelete = async () => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      html: `This action will delete this draft along with its variations.`
    });
    if (!confirm) {
      return;
    }

    cmsDraftDeleteMutation.mutate(itemId);
  }

  const tr: TranslateFunction = Translations.Translate(Translations, 'Cms');

  return (
    <div className={styles.CmsItemDetails}>
      <CmsNavigationPrompt when={isDirty} saveUnsavedWork={onSave} isDraft={isDraft} />
      <FullPageModal>
        <FullPageModalHeader isLoading={loading} onClose={onClose}>
          {!isDraft && cmsItemId &&
            <div className={styles.idContainer}>
              <div className={styles.headerLabel}>ID</div>
              <div className={styles.headerVal}> {cmsItemId}</div>
            </div>
          }
          <Controller
            name={'title'}
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <Input {...field} invalid={titleHasErrors} className={styles.titleInput} placeholder={`Enter your title here...`} onChange={(e) => { field.onChange(e.target.value) }} maxLength={200}></Input>
              )
            }}
          />
          {universalLink &&
            <div className={styles.qrContainer}>
              <i className={`fa fa-qrcode qrToggler`} data-tippy-content={'Click to see QR code'} onClick={() => { setQrModal(true) }}></i>
            </div>
          }
          <LoadingButton color={'secondary'} loading={previewLoading} onClick={() => { onPreview() }} text={'preview'} />
          {isDraft && <Badge className={styles.draftBadge}>Draft</Badge>}
          <TippyReact config={{ disabled: !hasErrors }} content={titleHasErrors ? 'Title is required!' : metaHasErrors ? 'Start and end date are required in agenda items' : targetHasErrors ? 'You need to target a user' : 'There are validation errors in your draft!'}><div><LoadingButton color={isDraft ? 'secondary' : 'primary'} disabled={hasErrors || cmsDraftPublishMutation.isLoading} loading={cmsItemSaveMutation.isLoading} onClick={() => { onSave() }} text={isNew ? 'create' : 'save'} /></div></TippyReact>
          {isDraft && <TippyReact config={{ disabled: !hasErrors }} content={titleHasErrors ? 'Title is required!' : metaHasErrors ? 'Start and end date are required in agenda items' : targetHasErrors ? 'You need to target a user' : 'There are validation errors in your draft!'}><div><LoadingButton disabled={hasErrors} loading={cmsDraftPublishMutation.isLoading} onClick={() => { onOpenPublishDialog() }} text={'publish'} /></div></TippyReact>}
        </FullPageModalHeader>
        {nonPrimaryVariationWarningLanguage !== undefined &&
          <Alert color={'warning'}><b>Primary ({activeFeed.variations?.primary})</b> variation has not been found. We 've copied the content of <b>{nonPrimaryVariationWarningLanguage || 'No Language'}</b> variation for your convenience, but it will be only created after hitting <i>save</i>.</Alert>
        }
        <FullPageModalContent isLoading={loading} cssStyle={nonPrimaryVariationWarningLanguage !== undefined ? { height: 'calc(100% - 140px)' } : undefined}>
          <FullPageModalContentPart flex={3} cssStyle={{ display: 'flex', flexDirection: 'column' }} mainPart>
            {agendaMode &&
              <CmsAgendaItemMeta
                magazine={magazine}
                control={control}
              />
            }
            <CmsContentEditor
              magazine={magazine}
              profile={profile}
              control={control as Control<CmsItemFormData | CmsVariationFormData>}
              cmsBuckets={cmsBuckets}
            />
          </FullPageModalContentPart>
          <FullPageModalContentPart cssStyle={{ overflowY: 'auto', minWidth: '250px' }} withBackground>
            <FullPageModalSettings>
              <FullPageModalSettingsSection title={'summary'} separator={!isNew && !isDraft}>
                {!isNew && isDraft &&
                  <FullPageModalSettingsSectionRow>
                    <Button className={styles.deleteButton} outline color={'danger'} onClick={() => { onDelete() }}>delete draft</Button>
                  </FullPageModalSettingsSectionRow>
                }
                {!isDraft &&
                  <FullPageModalSettingsSectionRow>
                    <Label>Visible</Label>
                    <Controller
                      name={'status'}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <div>
                            <Toggle checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                          </div>
                        )
                      }}
                    />
                  </FullPageModalSettingsSectionRow>
                }
                <FullPageModalSettingsSectionRow>
                  {isDraft ?
                    <>
                      <Label>{tr('publishingDate')}</Label>
                      <div>You can schedule the publishing date after clicking publish.</div>
                    </>
                    :
                    <Controller
                      name={'publicationDate'}
                      control={control}
                      render={({ field }) => {
                        return (
                          <CmsPublishingDate publicationDate={field.value} onChange={field.onChange} />
                        )
                      }}
                    />
                  }
                </FullPageModalSettingsSectionRow>
                <FullPageModalSettingsSectionRow>
                  <CmsFeaturedImage
                    magazine={magazine}
                    control={control as Control<CmsItemFormData | CmsVariationFormData>}
                    onOpenMediaSelector={() => {
                      setMediaSelectorMode('featured')
                    }}
                  />
                </FullPageModalSettingsSectionRow>
                <FullPageModalSettingsSectionRow>
                  <Label>Description</Label>
                  <Controller
                    name={'intro'}
                    control={control}
                    // rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <Input {...field} type={'textarea'} placeholder={`Description text that will be added to the intro.`} onChange={(e) => { field.onChange(e.target.value) }}></Input>
                      )
                    }}
                  />
                </FullPageModalSettingsSectionRow>
                {activeFeed.isPersonalized &&
                  <FullPageModalSettingsSectionRow>
                    <Controller
                      name={'target_uid'}
                      control={control}
                      rules={{ required: activeFeed.isPersonalized ? true : false }}
                      render={({ field }) => {
                        return (
                          <CmsPersonalizedFeedSection
                            magazine={magazine}
                            targetUid={field.value}
                            onChange={(t) => field.onChange(t || '')}
                            isDisabled={!isNew}
                          />
                        )
                      }}
                    />
                  </FullPageModalSettingsSectionRow>
                }
              </FullPageModalSettingsSection>
              <FullPageModalSettingsSection title={'categories'} separator>
                <FullPageModalSettingsSectionRow>
                  <Controller
                    name={'categories'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <CmsCategoriesSection
                          magazine={magazine}
                          activeFeed={activeFeed.id}
                          activeFeedLabel={activeFeed.label}
                          articleCategories={field.value}
                          onCategoryChange={(checked: boolean, category: string) => {
                            if (checked) {
                              if (!_.includes(field.value, category)) {
                                field.onChange([...field.value, category]);
                              }
                            } else {
                              if (_.includes(field.value, category)) {
                                field.onChange(_.filter(field.value, (c) => c != category));
                              }
                            }
                          }}
                        />
                      )
                    }}
                  />
                </FullPageModalSettingsSectionRow>
              </FullPageModalSettingsSection>
              {!isNew && activeFeed.variations?.enabled &&
                <FullPageModalSettingsSection title={'variations'} separator>
                  <FullPageModalSettingsSectionRow>
                    <CmsVariations
                      magazine={magazine}
                      profile={profile}
                      itemId={itemId}
                      activeFeed={activeFeed}
                      cmsStage={cmsStage}
                      getParentValues={getValues}
                      agendaMode={agendaMode}
                    />
                  </FullPageModalSettingsSectionRow>
                </FullPageModalSettingsSection>
              }
              <FullPageModalSettingsSection title={'tags'} separator>
                <FullPageModalSettingsSectionRow>
                  <CmsTags
                    magazine={magazine}
                    control={control}
                    existingTags={existingTags}
                  />
                </FullPageModalSettingsSectionRow>
              </FullPageModalSettingsSection>
              <FullPageModalSettingsSection title={'attachments'} separator>
                <FullPageModalSettingsSectionRow>
                  <CmsAttachments
                    magazine={magazine}
                    control={control}
                    onOpenMediaSelector={() => {
                      setMediaSelectorMode('attachments')
                    }}
                  />
                </FullPageModalSettingsSectionRow>
              </FullPageModalSettingsSection>
              <FullPageModalSettingsSection title={'settings'} separator>
                <FullPageModalSettingsSectionRow>
                  <Controller
                    name={'confirmation'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <CmsConfirmationSection
                          magazine={magazine}
                          confirmation={field.value}
                          onChange={field.onChange}
                        />)
                    }}
                  />
                </FullPageModalSettingsSectionRow>
              </FullPageModalSettingsSection>
              {!isNew &&
                <FullPageModalSettingsSection title={'info'} separator>
                  <FullPageModalSettingsSectionRow>
                    <Label>External ID</Label>
                    <div>{decodeURIComponent(itemId)}</div>
                  </FullPageModalSettingsSectionRow>
                </FullPageModalSettingsSection>
              }
            </FullPageModalSettings>
          </FullPageModalContentPart>
        </FullPageModalContent>
      </FullPageModal>
      {mediaSelectorConfig &&
        <MediaSelector
          magazine={magazine}
          profile={profile}
          closeHandler={onCloseMediaSelector}
          selectHandler={selectMediaHandler}
          dedicatedPart={'protected'}
          availableBuckets={cmsBuckets}
          restrictedMediaTypes={mediaSelectorConfig.targetTypes}
          multipleSelection={mediaSelectorConfig.multipleSelection}
        />}
      {previewItem &&
        <CmsPreviewer
          magazine={magazine}
          item={previewItem}
          onClose={() => setPreviewItem(undefined)}
        />
      }
      {qrModal && universalLink &&
        <QrModal universalLink={universalLink} closeModal={() => { setQrModal(false) }} type={'article'} />
      }
      {openPublishDialog &&
        <CmsPublishDialog
          agendaMode={agendaMode}
          // onChangeDate={(d: Date | null) => { publicationDateField.onChange(d) }}
          onPublish={onPublish}
          onClose={onClosePublishDialog}
        />
      }

    </div>
  )
}


const itemDetailedDataToFormData = (magazine: number, profile: Profile, queryData: Partial<CmsItemDetailedData>, activeFeed: CmsFeedInfo, agendaMode?: boolean, target_uid?: string): CmsItemFormData => {
  const { title, cdate, author, content, tags, intro, mdate, link, media, meta, status } = queryData;

  const featuredImageMedia = media && !_.isEmpty(media) ? _.find(media, (m) => m.featured == 1) : undefined;

  const featuredImage = {
    url: featuredImageMedia?.url || '',
    alt: featuredImageMedia?.meta?.alt || ''
  }

  return {
    title: title || '',
    intro: intro || '',
    author: author || profile.displayName || '',
    link: link || '',
    content: content ? proxifyContent(magazine, content) : '',
    featuredImage: featuredImage,
    publicationDate: cdate ? new Date(cdate) : null,
    attachments: _.filter(media, (m) => m.type == 'file'),
    categories: tags ? CategoriesHelper.extractCategoriesFromTags(tags, activeFeed.label) : [],
    tags: tags ? _.filter(tags, (t) => !_.startsWith(t, '_cat:')) : [],
    confirmation: content ? calcConfirmation(content) : undefined,
    meta: agendaMode ? calcAgendaItemMeta(meta as CmsAgendaItemMetaObject) : undefined,
    status: status ? true : false,
    target_uid: target_uid || ''
  }
}

const formDataToItemDetailed = async (magazine: number, itemId: string, activeFeed: CmsFeedInfo, formData: CmsItemFormData, agendaMode?: boolean): Promise<CmsItemDetailed> => {
  return {
    feed: activeFeed.id,
    external_id: decodeURIComponent(itemId),
    variation: calcVariationLang(activeFeed.variations) || '',
    kind: agendaMode ? 'event' : 'article',
    data: await formDataToItemDetailedData(magazine, formData, agendaMode),
    target_uid: activeFeed.isPersonalized ? formData.target_uid : undefined
  };
}

const formDataToItemDetailedData = async (magazine: number, formData: CmsItemFormData, agendaMode?: boolean): Promise<CmsItemDetailedData> => {
  const { title, intro, author, link, content, featuredImage, publicationDate, attachments, categories, tags, confirmation, meta, status } = formData;

  const featuredImageDimensions = !featuredImage?.url ? undefined : await getImageSize(MediaHelper.proxify(magazine, featuredImage.url));
  const featuredImageMediaObject: CmsMediaObject | undefined = !featuredImage?.url ? undefined :
    {
      type: "img",
      featured: 1,
      url: featuredImage.url,
      w: featuredImageDimensions?.width,
      h: featuredImageDimensions?.height,
      meta: {
        alt: featuredImage.alt
      }
    };

  const attachmentsMedia: CmsMediaObject[] = _.map(attachments, (a) => {
    return {
      link: a.link,
      filename: a.filename,
      extension: a.extension,
      mimeType: a.mimeType,
      type: 'file'
    }
  });

  const media: CmsMediaObject[] = _.compact([featuredImageMediaObject, ...attachmentsMedia]);


  return {
    title: title || '',
    intro: intro || '',
    author: author || '',
    link: link || '',
    content: content ? deProxifyContent(magazine, content) : '',
    cdate: publicationDate ? publicationDate.toISOString() : '',
    mdate: '',
    media: media,
    tags: [..._.map(categories, (c) => `_cat:${c}`), ...tags],
    confirmation,
    meta: agendaMode ? meta : undefined,
    status: status === false ? 0 : 1,

  }
}

const convertSelectedMediaToAttachments = (mediaUrl: string[], file?: any[]): CmsMediaObject[] => {
  return _.map(file, (f, index) => {
    return {
      link: mediaUrl[index],
      filename: MediaHelper.convertPlusToSpaces(f.fullName),
      extension: f.extension,
      mimeType: MediaHelper.getMimeType(f.extension),
      type: 'file'
    }
  })
}

const findConfirmationContent = (content: string): RegExpMatchArray | null => {
  return content.match(/<p><\/p><div data-widget-type=\"confirmation\".*/g);
}

export const calcConfirmation = (content: string): ArticleConfirmation | undefined => {
  try {
    const confirmationContent = findConfirmationContent(content);
    if (!confirmationContent || !confirmationContent[0]) {
      return undefined;
    }
    const labelContent = confirmationContent[0].match(/data-widget-label=\".*\"/g);
    if (!labelContent || !labelContent[0]) {
      return undefined;
    }
    const label = labelContent[0].split('"')[1];
    return {
      enabled: true,
      label
    }
  } catch {
    return undefined;
  }
}

const noConfirmationContent = (content: string): string => {
  if (!content) {
    return '';
  }
  return content.replace(/<p><\/p><div data-widget-type=\"confirmation\".*/g, '');
}


const noIFramesContent = (content: string): string => {
  if (!content) {
    return '';
  }
  return content.replace(/<iframe\b[^>]*>[\s\S]*?<\/iframe>/g, '');
}

export const proxifyContent = (magazine: number, content: string): string => {
  if (!content) {
    return '';
  }
  let proxifiedContent = noConfirmationContent(content);
  const urls = findDeProxifiedUrls(noIFramesContent(content));
  _.map(urls, (u) => {
    proxifiedContent = proxifiedContent.replace(u, MediaHelper.proxify(magazine, u));
  });
  return proxifiedContent;
}



export const deProxifyContent = (magazine: number, content: string): string => {
  if (!content) {
    return '';
  }
  let deProxifiedContent = content;
  const urls = findProxifiedUrls(content);
  _.map(urls, (u) => {
    deProxifiedContent = deProxifiedContent.replace(u, MediaHelper.deProxify(magazine, u));
  });
  return deProxifiedContent;
}

const findProxifiedUrls = (content: string): RegExpMatchArray | null => {
  return content.match(/\.{0,2}\/media\/\d+\?url=.*?(?=")/g);
}

const findDeProxifiedUrls = (content: string): RegExpMatchArray | null => {
  return content.match(/https?:\/\/(?!www).*?(?=")/g);
}

const calcVariationLang = (feedVariations?: Variations): string | undefined => {
  if (!feedVariations || !feedVariations.enabled) {
    return undefined;
  }
  return `lang:${feedVariations.primary}`;
}

const calcAgendaItemMeta = (meta?: CmsAgendaItemMetaObject): CmsAgendaItemMetaObject => {
  return {
    cmsKind: 'event',
    start: meta?.start || '',
    end: meta?.end || '',
    location: meta?.location || '',
    speaker: meta?.speaker || '',
    timezone: meta?.timezone || moment().format('Z'),
    tz: meta?.tz || moment().utcOffset(),
    userLocalTime: meta?.userLocalTime || false,
    all_day: meta?.all_day || false,
  }
}