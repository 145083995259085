import React from 'react';
import Input from 'reactstrap/lib/Input';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import * as _ from 'lodash'
import cn from 'classnames'

import styles from './CicTable.module.scss';


import { CICChannel } from '../domain';
import { API } from '../../../../../apis';
import { EdErrorHandler, OrtecLoader } from '../../../../../widgets';
import { ChannelSearchStatus, countSearchHitsRecursively, getChannelSearchStatus } from './cicTableSearchHelper';
import { TippyReact } from '../../../../../components';
import { ROOT_NODE_ID } from '../domain';
import { formatChannelTitle } from '../cicChannelsHelper';
import { Profile } from '../../../../../../domain';

export interface DisabledChannelsGroup {
  channels: number[]
  message: string
}

interface Props {
  magazine: number,
  profile: Profile
  selected: number[],
  onSelect: (channelId: number, status: boolean) => void
  channels?: CICChannel[]
  disabledChannels: DisabledChannelsGroup[]
  initiallyExpanded: number[]
  showTopLevel?: boolean
}

const EXPANDED_LIMIT = 80


//
// ─── HELPERS ────────────────────────────────────────────────────────────────────
//

function isChannelDisabled(disabledChannels: DisabledChannelsGroup[], channel: number): DisabledChannelsGroup | false {
  return disabledChannels.find(group => group.channels.includes(channel)) || false
}

export function selectedChannelsLabel(selectedChannels: number[]) {
  if (selectedChannels.length === 0) {
    return ''
  }
  const rootText = selectedChannels.includes(ROOT_NODE_ID) ? 'Top level' : ''
  const channelsSize = selectedChannels.filter(c => c !== ROOT_NODE_ID).length
  const channelsText = channelsSize > 0 ? `${channelsSize} channel(s)` : ''
  return _.compact([rootText, channelsText]).join(' and ') + ' selected'
}



//
// ─── COMPONENTS ─────────────────────────────────────────────────────────────────
//

export default function CicSelectorTable(props: Props) {
  const [channels, setChannels] = React.useState<CICChannel[]>(props.channels || []);
  const [channelsLoading, setChannelsLoading] = React.useState(true);
  const [search, setSearch] = React.useState('')

  React.useEffect(() => {
    if (!props.channels) {
      loadChannels();
    } else {
      setChannelsLoading(false);
    }
  }, [])

  const { magazine, selected, onSelect } = props


  const loadChannels = async () => {
    try {
      setChannelsLoading(true);
      const { data } = await API.channels.cic(magazine);
      setChannels(data)
    } catch (error) {
      EdErrorHandler(error, `getting channels`);
    } finally {
      setChannelsLoading(false);
    }
  }
  function onChannelClick(channelId: number, s: boolean) {
    onSelect(channelId, s)
  }

  const hitsCount = React.useMemo(() => countSearchHitsRecursively(search, channels), [channels, search])

  const debouncedSearch = _.debounce((s) => { setSearch(s) }, 300)
  const searchValue = search.toLowerCase()
  const expandedMode = hitsCount < EXPANDED_LIMIT

  const rootSelected = selected.includes(ROOT_NODE_ID)
  const rootDisabled = isChannelDisabled(props.disabledChannels, ROOT_NODE_ID)

  return <div className='tableContainer'>
    {channelsLoading && <div style={{ maxHeight: '800px' }}> <OrtecLoader /></div>}
    <Row className={'react-bs-table-tool-bar'}>
      <Col md={4}>
        <Input type="search" placeholder={'Search'} onChange={(e) => { debouncedSearch(e.target.value) }} />
      </Col>
    </Row>
    <Row className='react-bs-table-pagination'>
      <Col md={12}>
        <span>Showing {hitsCount} rows {!expandedMode ? '(collapsed)' : ''}</span>
      </Col>
    </Row>
    <table className={styles.table}>
      <colgroup>
        <col style={{ minWidth: '400px' }} />
        <col style={{ maxWidth: '150px' }} />
        <col style={{ width: '70px' }} />
      </colgroup>
      <thead className={styles.tableHeader}>
        <tr>
          <th></th>
          <th>Type</th>
          <th>Sources</th>
        </tr>
      </thead>
      <tbody>
        {props.showTopLevel &&
          <tr className={styles.row}>
            <td className={styles.firstCell}>
              <CheckBox checked={rootSelected} disabled={rootDisabled} onClick={() => onChannelClick(ROOT_NODE_ID, !rootSelected)} />
              <span style={{ fontStyle: 'italic' }}>Top level</span>
            </td>
          </tr>
        }
        {channels.map(channel =>
          <ChannelRecursive
            key={channel.id}
            profile={props.profile}
            channel={channel}
            searchValue={searchValue}
            parents={[]}
            expandedMode={expandedMode}
            onChannelClick={onChannelClick}
            selected={selected}
            disabledChannels={props.disabledChannels || []}
            initiallyExpanded={props.initiallyExpanded || []}
          />
        )}
      </tbody>
    </table>
  </div>
}

function ChannelRecursive(props: {
  searchValue: string
  profile: Profile
  channel: CICChannel
  parents: CICChannel[]
  selected: number[],
  expandedMode: boolean
  onChannelClick: (channelId: number, s: boolean) => void
  disabledChannels: DisabledChannelsGroup[]
  initiallyExpanded: number[],
}) {
  const [toggled, setToggled] = React.useState<boolean>(false)
  React.useEffect(() => { setToggled(false) }, [props.searchValue])

  const status = React.useMemo(
    () => getChannelSearchStatus(props.searchValue, props.channel),
    [props.channel, props.searchValue, props.initiallyExpanded]
  )

  const whitelisted = props.searchValue.length === 0 && props.initiallyExpanded.includes(props.channel.id)

  const { channel, searchValue, parents, expandedMode, onChannelClick, selected, disabledChannels } = props
  const expanded = (expandedMode || whitelisted) ? !toggled : toggled

  const thisSelected = _.includes(selected, channel.id)
  if (status === ChannelSearchStatus.NOT_FOUND && !whitelisted) {
    return null
  }
  const disabled = isChannelDisabled(disabledChannels, channel.id)
  return < >
    <tr
      className={cn(styles.row,
        disabled ? styles.lightGrey :
          status === ChannelSearchStatus.DESC_FOUND ? styles.grey :
            undefined
      )}
    >
      <td className={styles.firstCell} style={{ marginLeft: `${parents.length * 20}px` }}>
        {channel.childChannels?.length === 0
          ? <div className={styles.toggle}>&nbsp;</div>
          : <div className={styles.toggle} onClick={(e) => { e.stopPropagation(); setToggled(!toggled) }}>
            <div className={cn("fa", expanded ? "fa-minus" : "fa-plus")} />
          </div>
        }
        <CheckBox checked={thisSelected} disabled={disabled} onClick={() => { onChannelClick(channel.id, !thisSelected) }} />
        <div>{formatChannelTitle(props.profile, channel)}</div>
      </td>

      <td className={styles.textCell}>{channel.channelType}</td>
      <td className={styles.textCell}>{channel.sources?.length}</td>
    </tr>
    {expanded && channel.childChannels &&
      channel.childChannels.map(child =>
        <ChannelRecursive
          key={child.id}
          profile={props.profile}
          channel={child}
          parents={[...parents, channel]}
          searchValue={searchValue}
          expandedMode={expandedMode}
          onChannelClick={onChannelClick}
          selected={selected}
          disabledChannels={disabledChannels}
          initiallyExpanded={props.initiallyExpanded}
        />
      )
    }
  </>
}

function CheckBox({ checked, disabled, onClick }: { checked: boolean, disabled: DisabledChannelsGroup | false, onClick: () => void }) {
  return <div style={{ display: 'flex', justifyContent: 'center', minWidth: '30px' }}>
    {disabled
      ? <TippyReact content={disabled.message}>
        <div style={{ display: 'inline-flex' }}>
          <input type="checkbox" checked={checked} disabled onClick={() => { console.log('bleh') }} />
        </div>
      </TippyReact>
      : <input type="checkbox" checked={checked} onClick={() => { onClick() }} />
    }

  </div>
}
