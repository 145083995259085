import React from 'react';
import { FormFeedback, Input } from 'reactstrap';
import { ciHelper } from '.';

interface Props {
  fieldKey: string,
  fieldValue: string,
  onChange: (key: string, newValue: string) => void,
}

export const FieldValueInput = ({ fieldKey, fieldValue, onChange }: Props) => {

  const inputPlaceholder = (): string => {
    switch (ciHelper.keyToKeyType(fieldKey)) {
      case `url`:
        return `https://`;
      case `intro`:
        return `Intro text`;
      default:
        return `Value`;
    }
  }

  const inputFeedback = (): string => {
    switch (ciHelper.keyToKeyType(fieldKey)) {
      case `url`:
        return `This is not a valid https:// URL.`;
      case `intro`:
        return `This is not a correct intro text.`;
      default:
        return `This is not a correct value.`;
    }
  }

  return (
    <>
      <Input invalid={ciHelper.isValueInvalid(fieldKey, fieldValue, true)} type='text' value={fieldValue} placeholder={inputPlaceholder()} onChange={(e) => onChange(fieldKey, e.target.value)} />
      {ciHelper.isValueInvalid(fieldKey, fieldValue, true) &&
        <FormFeedback>{ciHelper.isValueEmpty(fieldValue) ? `This value is empty.` : inputFeedback()}</FormFeedback>
      }
    </>
  )
}
