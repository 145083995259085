import React from 'react';
import { Profile, Source } from '../../../../../domain';
import { SourceSettings } from './SourceSettings';
import { ArticleLister, DefaultEmptySearchingFilters } from '../../../../components';
import _ from 'lodash';
import { Translations } from '../../../../translations';
import ChannelSelector from '../Channels/ChannelSelector';

interface Props {
  magazine: number,
  profile: Profile,
  source: Source,
  hasSourceName: boolean,
  hasAdvancedSettings: boolean,
  onChangeSource: (source: Source) => void
}

export const SourceConfig = ({ magazine, profile, source, hasSourceName, hasAdvancedSettings, onChangeSource }: Props) => {

  const tr = Translations.Translate(Translations, 'Sources');

  return (
    <div>
      <SourceSettings {...{ magazine, profile, source, hasSourceName, hasAdvancedSettings, onChangeSource }} />
      <div style={{ display: 'flex', gap: '30px' }}>
        <div style={{ flex: 1 }}>
          <ChannelSelector
            magazine={_.toString(magazine)}
            profile={profile}
            title={tr('inChannels')}
            selected={source.parentChannels}
            selectionHandler={(id: number, isSelected: boolean) => {
              const selectedChannels = source.parentChannels || [];
              const newSelected = isSelected ? [...selectedChannels, id] : _.filter(selectedChannels, (k) => k !== id);
              onChangeSource({ ...source, parentChannels: newSelected });
            }}
          />


        </div>
        <div style={{ flex: 1, paddingTop: '20px' }}>
          <ArticleLister
            magazine={magazine}
            filters={{ ...DefaultEmptySearchingFilters, source: _.toString(source.id) }}
            title={`Articles`}
          />
        </div>
      </div>
    </div >
  )
}
