import React, { Component, Fragment } from 'react';
import { Input, Row, Col } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';
import * as Api from '../../../../api';
import { Notification, Profile } from '../../../../../domain';

import './css/NotificationDetails.min.css';
import NotificationInfo from './NotificationInfo';
import NotificationPreview from './NotificationPreview';
import NotificationResults from './NotificationResults';
import { Translations } from '../../../../translations';
import { DateHelper } from '../../../../utils';


export interface Props {
  magazine: string,
  profile: Profile,
  notificationId: number,
  closeHandler: (updateOverview?: boolean) => void
}

export interface State {
  notification?: Notification,
  notFound?: boolean,
  loading: boolean
}

export class NotificationDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.getNotification();
  }

  getNotification() {
    this.setState({ loading: true });
    Api.getNotification(parseInt(this.props.magazine), this.props.notificationId).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting notificaton');
        this.setState({ notFound: true, loading: false });
      }
      this.setState({ notification: this.parseNotification(resp.data), loading: false });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
        this.setState({ notFound: true, loading: false })
      });
  }

  parseNotification(notification: any): Notification {
    try {
      const messageObj = JSON.parse(notification.message);
      return {
        ...notification,
        message: messageObj.message,
        url: messageObj.url,
        scheduledOn: notification.notification_datetime

      }
    } catch (error) {
      console.log('notification message is not a valid json');
      return notification;
    }

  }

  closeHandler() {
    this.props.closeHandler();
  }
  dateFormatter(date: string) {
    return DateHelper.dateTimeToLocaleString(date);
  }
  parseNotificationResults(notification?: Notification): any[] {
    if (!notification || !notification.detailedResults) {
      return [];
    }

    const results = _.concat(_.map(notification.detailedResults.androidResults, (o: any) => _.extend({ os: 'android', type: (o.result === 'success' ? 'success' : 'failure') }, o)), _.map(notification.detailedResults.iosResults, (o: any) => _.extend({ os: 'ios', type: (o.result === 'success' ? 'success' : 'failure') }, o)));

    return results;
  }

  render() {
    const { magazine, notificationId, profile } = this.props;
    const { notification, loading, notFound } = this.state;
    const tr = Translations.Translate(Translations, 'PushNotifications');

    const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>;
    const notFoundMessage = <div className="notFoundContainer alert alert-danger">{tr('pushNotificationsNotFound')}</div>;
    return (
      <div id="NotificationDetails">
        {Translations.EnableTranslations(Translations)}
        <div className='header'>
          {/* <i className="material-icons closeIcon" onClick={this.closeHandler.bind(this)}>close</i> */}
          <img src="/assets/icons/bt_close_bigger.svg" onClick={this.closeHandler.bind(this)} style={{ cursor: 'pointer' }} />
          {loading || notFound ? '' :
            <span className="headerTitle">{tr('pushNotificationFrom')} {notification ? this.dateFormatter(notification.cdate) : ''}</span>
          }
        </div>
        <div className='content'>
          <div className='leftPart infoResultsContainer' style={{ flex: 1 }}>
            {loading ? loader :
              notFound ? notFoundMessage :
                <Fragment>
                  <NotificationInfo profile={profile} notification={notification} closeHandler={this.props.closeHandler} />
                  <NotificationResults results={this.parseNotificationResults(notification)} />
                </Fragment>
            }
          </div>
          <div className='rightPart previewContainer' style={{ flex: 1, minWidth: '550px' }}>
            {loading ? loader :
              notFound ? notFoundMessage :
                <NotificationPreview notification={notification} />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationDetails;
