import _ from 'lodash'
import { CICChannel } from '../domain';

function channelFieldFilter(searchValue: string, v: string): boolean {
  return v.toLowerCase().indexOf(searchValue) > -1
}

/**
 * returns true if channel matches searchValue
 */
function channelFilter(searchValue: string, channel: CICChannel): boolean {
  return !searchValue.length ||
    channelFieldFilter(searchValue, String(channel.id))
    || channelFieldFilter(searchValue, channel.name)
    || channelFieldFilter(searchValue, channel.label)
    || channelFieldFilter(searchValue, channel.channelType)
}

/**
 * returns true if channel or any descendant matches searchValue
 */
function channelRecursiveFilter(searchValue: string, channel: CICChannel): boolean {
  return channelFilter(searchValue, channel) || channel.childChannels?.find(c => channelRecursiveFilter(searchValue, c)) !== undefined
}

/**
 * counts number of channel nodes that match searchValue 
 */
export function countSearchHitsRecursively(searchValue: string, channels: CICChannel[]): number {
  return _.sumBy(channels,
    (c): number => (channelFilter(searchValue, c) ? 1 : 0) + countSearchHitsRecursively(searchValue, c?.childChannels || [])
  )
}

export enum ChannelSearchStatus {
  FOUND,
  DESC_FOUND,
  NOT_FOUND,
}

export function getChannelSearchStatus(searchValue: string, channel: CICChannel): ChannelSearchStatus {
  return channelFilter(searchValue, channel)
    ? ChannelSearchStatus.FOUND
    : channelRecursiveFilter(searchValue, channel)
      ? ChannelSearchStatus.DESC_FOUND
      : ChannelSearchStatus.NOT_FOUND
}
