import { HtmlTemplatesHelper } from ".";
import { NlTemplateConfiguration_Content, NlTemplateConfiguration_Header } from "../domain";

const introductionText = (configuration: NlTemplateConfiguration_Header): string => {

  const { enabled, backgroundColor, text, textColor, fontSize } = configuration.introductionText;

  if (!enabled) {
    return '';
  }
  return `
    <tr>
      <td style="
          background-color: ${backgroundColor};
          color: ${textColor};
          font-size: ${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px;
          padding: 32px 16px;
          font-weight: 400;
      ">
          ${HtmlTemplatesHelper.handleText(text, textColor)}
      </td>
    </tr>
  `;
}
const headerImage = (configuration: NlTemplateConfiguration_Header): string => {

  const { enabled, src } = configuration.image;

  if (!enabled) {
    return '';
  }
  return `
    <tr align="center">
      <td height="165" width="640" align="center" 
        style="vertical-align:top;
            background-repeat:no-repeat;
            text-align:center;width: 640px; 
            height: 165px;position:relative;
            ">
          <img style="margin-bottom:-4px;" src="${src}" alt="Header image" />
      </td>
    </tr>
  `;
}

const areBothHeaderLinksEnabled = (configuration: NlTemplateConfiguration_Header): boolean => {
  const { openInBrowserLink, downloadAppLink } = configuration;

  return openInBrowserLink.enabled && downloadAppLink.enabled ? true : false;

}

const browserLink = (configuration: NlTemplateConfiguration_Header): string => {
  const { enabled, backgroundColor, text, textColor, fontSize } = configuration.openInBrowserLink;

  if (!enabled) {
    return '';
  }
  return `
    <a target="_blank"
      href="{{newsletterEditionUrl}}"
      style="margin-left:${areBothHeaderLinksEnabled(configuration) ? '10' : '0'}px;
          font-size: ${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px;
          color:${textColor};
          background-color:${backgroundColor};
          text-decoration:none;"
    >
      ${text}
    </a>
  `;
}

const appLink = (configuration: NlTemplateConfiguration_Header): string => {
  const { enabled, backgroundColor, text, textColor, fontSize } = configuration.downloadAppLink;

  if (!enabled) {
    return '';
  }
  return `
    <a target="_blank"
    {% if appDownload %}
      href="{{appDownload}}"
      {% elif storeUrls.iOS %}
      href="{{storeUrls.iOS}}"
      {% else %}
      href="{{storeUrls.AndroidOS}}"
      {% endif %}
      style="margin-right:${areBothHeaderLinksEnabled(configuration) ? '10' : '0'}px;
          font-size: ${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px;
          color:${textColor};
          background-color:${backgroundColor};
          text-decoration:none;"
    >
      ${text}
    </a>
  `;
}

const headerLinks = (configuration: NlTemplateConfiguration_Header): string => {
  const { openInBrowserLink, downloadAppLink } = configuration;

  if (!openInBrowserLink.enabled && !downloadAppLink.enabled) {
    return '';
  }

  return `
  <tr>
    <td align="right" style="padding: 8px; background-color: ${openInBrowserLink.backgroundColor || downloadAppLink.backgroundColor};">
    ${appLink(configuration)} 
    ${areBothHeaderLinksEnabled(configuration) ? '|' : ''} 
    ${browserLink(configuration)}
    </td>
  </tr>
  `;
}


const parse = (configuration: NlTemplateConfiguration_Header, articleConfiguration: NlTemplateConfiguration_Content): string => {
  return `
    <style>
      td.nl_articleContainer{
          vertical-align:top;
      }

      td.nl_itemSlot{
          vertical-align:top;
      }
      .nl_freeTextEditorContainer{
          vertical-align:top;
          font-size: ${HtmlTemplatesHelper.fontSizeToPixels(articleConfiguration.articleDescription.fontSize)}px;
          color:${articleConfiguration.articleDescription.textColor};
          line-height:20px;
          text-align:center;
      }

      .nl_freeTextEditorContainer p{
          margin:auto;
      }

      .nl_freeTextEditorContainer h4 {
          line-height:normal;
      }

      .nl_col1-1.nl_freeTextEditorContainer p{
        max-width:608px;
      }
      .nl_col2-1.nl_freeTextEditorContainer p{
        max-width:278px;
      }
      .nl_col2-2.nl_freeTextEditorContainer p{
        max-width:278px;
      }
      
      a[x-apple-data-detectors=true] {
          color: none !important;
          text-decoration: none !important;
      }
      
      a{
          text-decoration:none;
      }
      
      a{
          text-decoration:none !important;
      }
      
    </style>
    <!--[if mso]>
    <style type="text/css">
        .nl_freeTextEditorContainer {
            padding:0 16px;
            mso-padding-alt: 0px 16px 0px 16px;
        }

    </style>
    <![endif]-->
    <table 
      role="presentation"
      align="center" 
      border="0" 
      cellpadding="0" 
      cellspacing="0" 
      width="640" 
      style="
      margin:auto;
      mso-padding-alt: 0px 0px 0px 0px;
    ">
      ${headerImage(configuration)}
      ${headerLinks(configuration)}
      ${introductionText(configuration)}
      <tr height="10" style="height:10px;"><td></td></tr>
    </table>
  `
}

export const header = {
  parse
}