import classNames from 'classnames';
import React, { useState } from 'react';
import { MediaSelectButton, MediaSelector } from '../../../components';
import { Profile } from '../../../../domain';
import styles from './NcSettingsImageSelect.module.scss';
import { Control, Controller, FieldPath } from 'react-hook-form';
import { NcSettingsFormData } from '../subpages/NcSettings/NcSettingsConfiguration/NcSettingsConfiguration';
import { MediaHelper } from '../../../utils';
import { CHESSBOARD_BACKGROUND } from '../NarrowcastingHelper';

interface Props {
  magazine: number,
  profile: Profile
  name?: string,
  text?: string,
  imgSrc?: string,
  onChange?: (imgSrc?: string) => void,
  validImageWidth?: number //in pixels
  control: Control<NcSettingsFormData, any>,
  fieldName: FieldPath<NcSettingsFormData>
}


export const NcSettingsImageSelect = ({ magazine, profile, name, text, imgSrc, onChange, validImageWidth, control, fieldName }: Props) => {

  const [mediaSelector, setMediaSelector] = useState(false);
  const [isImageSizeInvalid, setIsImageSizeInvalid] = useState(false);


  const openMediaSelector = () => {
    setMediaSelector(true);
  }
  const closeMediaSelector = () => {
    setMediaSelector(false);
  }

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => {
          const isImageMissing = !field.value ? true : false;

          const onMediaHandlerSelect = async (mediaUrl: string[]) => {
            field.onChange(mediaUrl[0]);
            closeMediaSelector();
            // setIsImageSizeInvalid(validImageWidth ? await verifyImageSize(mediaUrl[0], validImageWidth) : false);
          }

          const clearSelectedImage = () => {
            field.onChange('');
            // setIsImageSizeInvalid(false);
          }

          return (
            <div className={styles.NcSettingsImageSelect}>
              <div className={styles.mediaSelectButtonWrapper}>
                <MediaSelectButton inputClass={styles.mediaSelectButton} text={`select image`} onClick={openMediaSelector} type={'images'} />
                {field.value && <i className={`fa fa-trash`} onClick={clearSelectedImage}></i>}
              </div>
              {text &&
                <div className={styles.text}>{text}</div>
              }
              {field.value &&
                <>
                  <div className={classNames(styles.imagePreview, { [styles.withError]: isImageSizeInvalid })} style={{ backgroundImage: `url("${MediaHelper.proxify(magazine, field.value as string)}"),${CHESSBOARD_BACKGROUND}` }}>
                    {/* <span className={'fa fa-times'} onClick={clearSelectedImage}></span> */}
                  </div>
                  {isImageSizeInvalid &&
                    <div className={styles.errorMsg}>Image must be {validImageWidth}px wide!</div>
                  }
                </>
              }
              {mediaSelector &&
                <MediaSelector {...{ magazine, profile }}
                  closeHandler={closeMediaSelector}
                  selectHandler={onMediaHandlerSelect}
                  restrictedMediaTypes={['images']}
                  dedicatedBucket={`${magazine}_nc`}
                  dedicatedPart='protected'
                />}

            </div>
          )
        }}
      />
    </>
  )
}
