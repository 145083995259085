import React, { useState } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { SideBarItem } from './components';
import './css/SideBar.min.css';

import TippyReact from './components/TippyReact';
import { Profile } from '../domain';
import { Translations } from './translations';
import _ from 'lodash';
import { AccessRightsHelper, MediaHelper } from './utils';
import { ED_PAGES, ED_ROLES_LABELS } from './accessRights/accessRights';
import { EdPageKey } from './accessRights/domain';
import { ChangelogModal } from './components/ChangelogModal/ChangelogModal';
const pj = require('../../package.json')

const ED_SIDEBAR_PAGES: EdPageKey[] = [
  'overview',
  'analytics',
  'content',
  'engage',
  'report',
  'narrowcasting',
  'advanced',
  'plugins',
]

export interface Props {
  magazine: string
  profile: Profile
}

function SettingsMenu(props: Props) {
  const { profile, magazine } = props
  const tr = Translations.Translate(Translations, 'SideBar');

  const [showChangelogModal, setShowChangelogModal] = useState<boolean>(false);

  const openChangelogModal = () => {
    setShowChangelogModal(true);
  }
  const closeChangelogModal = () => {
    setShowChangelogModal(false);
  }

  return (
    <div id="SideBar_settingsMenu" style={{ cursor: 'auto' }}>
      <TippyReact content={`Role: ${profile.role ? ED_ROLES_LABELS[profile.role] || profile.role : ''}`}>
        <div style={{ whiteSpace: 'nowrap' }} className="menuItem loggedItem"><i className="fa fa-circle"></i> {tr('loggedAs')} {profile ? profile.displayName : '...'} </div>
      </TippyReact>
      <div className="horizontalLine"></div>
      <div style={{ whiteSpace: 'nowrap' }} className="menuItem disabled"><a href="#"> <img src="/assets/navBarIcons/user_settings.svg" /> {tr('userSettings')}</a></div>
      <div style={{ whiteSpace: 'nowrap' }} className="menuItem"><a href={`/auth/${magazine}/logout`}> <img src="/assets/navBarIcons/logout.svg" /> {tr('logout')}</a></div>
      <div className="versionContainer" style={{ cursor: 'pointer' }} onClick={openChangelogModal}>v{pj.version}</div>
      {showChangelogModal &&
        <ChangelogModal
          magazine={_.toNumber(magazine)}
          onClose={closeChangelogModal}
        />
      }
    </div>
  );
}

export function SideBar(props: Props) {
  const { magazine, profile } = props;
  const tr = Translations.Translate(Translations, 'Modules');

  const appMetaIcon = profile.appMeta?.icon || undefined;
  const appMetaName = profile.appMeta?.label || profile.appMeta?.name || ''

  return (
    <div id="SideBar" className="">
      {Translations.EnableTranslations(Translations)}
      <div className="logoSideBar">
        <Nav vertical>
          <NavItem className="logoItem">
            <SideBarItem text="" icon={<img title={appMetaName} alt={appMetaName} style={{ width: '63px', height: '63px', objectFit: 'contain' }} src={appMetaIcon ? MediaHelper.proxify(magazine, appMetaIcon, 63) : `/assets/ortec-logo.png`} />} magazine={_.toNumber(magazine)} router='' iconName='' profile={profile}/>
          </NavItem>
        </Nav>
      </div>
      <div className="mainSideBar">
        <div className="separator"></div>
        <div className="scrollableWrapper">
          <Nav vertical>
            {_.map(ED_SIDEBAR_PAGES, (p) => {
              if (
                !AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, p) ||
                (p === 'narrowcasting' && !profile.edConfig?.narrowcasting) ||
                (p === 'advanced' && !profile.edConfig?.showAdvancedPage) ||
                (p === 'plugins' && _.isEmpty(profile.edConfig?.plugins))
              ) {
                return null;
              }
              return (
                <NavItem key={p}>
                  <SideBarItem text={tr(p)} iconName={p} router={`/${magazine}/${p}`} magazine={_.toNumber(magazine)} profile={profile}/>
                </NavItem>
              )
            })}
          </Nav>
        </div>
        <div className="separator"></div>
      </div>
      <div className="settingsItem">
        <div className="iconContainer">
          <a href='https://ortec.freshdesk.com/' target="_blank"><img src="/assets/icons/support.svg" /></a>
        </div>
        <TippyReact
          content={<SettingsMenu {...props} />}
          config={{ allowHTML: true, placement: 'right-end', arrow: false, trigger: 'click', offset: [5, 0], interactive: true }}
        >
          <div className="iconContainer settingsItemButton">
            <img src="/assets/icons/16/gear.svg" /> <img src="/assets/icons/16/arrow_right_blue.svg" />
          </div>
        </TippyReact>
      </div>
    </div>
  );
}

export default SideBar;
