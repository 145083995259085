import classNames from 'classnames';
import React, { PropsWithChildren, useState } from 'react';
import { Media } from '../../../../domain';
import { MediaHelper } from '../../../utils';
import styles from './MediaBucketExplorerListFile.module.scss';
import { downloadMediaItem } from '../MediaManagerSelectorHelper';
import _ from 'lodash';
import { ExtensionIcon } from '../../ExtensionIcon/ExtensionIcon';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';

interface Props {
  magazine: number
  mediaItem: Media,
  onMediaDelete: (media: Media) => void
  selected?: boolean
  onSelect?: (media: Media) => void
  searchMode: boolean
  onGoToFolder: () => void
}


export default function MediaBucketExplorerListFile(props: Props) {
  const { magazine, mediaItem, onMediaDelete, onSelect, selected, searchMode, onGoToFolder } = props

  const [copying, setCopying] = useState(false);

  const onDownload = () => { downloadMediaItem(downloadUrl, filename) }
  const onDelete = () => onMediaDelete(mediaItem)

  const onCopyUrl = () => {
    setCopying(true);
    setTimeout(() => {
      setCopying(false);
    }, 1000);
  }
  const filename = MediaHelper.convertPlusToSpaces(mediaItem.fullName)
  const downloadUrl = MediaHelper.proxify(magazine, mediaItem.url)

  const onThumbClick = onSelect
    ? () => { onSelect(mediaItem) }
    : undefined

  return (<div className={styles.outer}>
    <Wrapper onClick={onThumbClick} selected={selected}>
      <div className={styles.leftColumn}>
        <ExtensionIcon magazine={magazine} extension={mediaItem.integration ? 'mov' : mediaItem.extension} link={mediaItem.url} size={40} />
        <span className={styles.text}>{filename}</span>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.text}>
          {moment.utc(mediaItem.lastModified).format('YYYY-MM-DD HH:mm')}
        </div>
      </div>
      {searchMode
        && <div className={styles.rightColumn}>
          <div className={styles.text}>
            {mediaItem.folder}
          </div>
        </div>
      }
    </Wrapper>
    <div className={styles.actions}>
      {copying && <span className={styles.copyNotification}>Copied!</span>}
      {searchMode && <ActionButton onClick={onGoToFolder}>Go to folder</ActionButton>}
      <ActionButton onClick={onDownload}>Download</ActionButton>
      <CopyToClipboard text={mediaItem.url}>
        <ActionButton onClick={onCopyUrl}>Copy link</ActionButton>
      </CopyToClipboard>
      <ActionButton onClick={onDelete}>Delete</ActionButton>
    </div>
  </div>
  )
}

const ActionButton = (props: {
  children: string
  onClick: () => void
}) => {
  return <button
    className={classNames('btn', 'btn-secondary')}
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      props.onClick();
    }}
  >{props.children}</button>
}

const Wrapper = (props: PropsWithChildren<{ onClick?: () => void, selected?: boolean }>) => {
  const selectable = props.onClick !== undefined

  if (!selectable) {
    return <div className={styles.inner}>{props.children}</div>
  }

  return <button onClick={props.onClick} className={classNames(styles.inner, styles.selectable)}  >
    <div className={styles.selectedColumn}>
      {props.selected === true && <img src='/assets/icons/16/radio-selector.svg' loading="lazy"/>}
    </div>
    {props.children}
  </button>
}
