export type KeyType = 'thumbIcon' | 'thumbImage' | 'plugin' | 'url' | 'intro' | 'downloadUrl';
export type KeyTypeWithOther = KeyType | '';

export const KEY_TYPES: Array<{ key: KeyType, label: string, placeholder: string }> = [
  { key: 'thumbIcon', label: 'Thumb icon', placeholder: 'Select or upload an icon for this tile.' },
  { key: 'thumbImage', label: 'Thumb image', placeholder: 'Select or upload a background image for this tile.' },
  { key: 'plugin', label: 'Plugin', placeholder: 'Select a plugin from the list. When clicking the tile, the plugin will open.' },
  { key: 'url', label: 'URL', placeholder: 'Type a URL. When clicking the tile, the URL will open.' },
  { key: 'intro', label: 'Intro', placeholder: 'Text that can be used as an introduction in the tile.' },
  { key: 'downloadUrl', label: 'Document', placeholder: 'Select or upload a document. When tile is clicked, the file will be downloaded.' },
];

export const ALL_KEY_TYPES: Array<{ key: KeyTypeWithOther, label: string, placeholder: string }> = [
  ...KEY_TYPES,
  { key: '', label: 'Other', placeholder: 'Advanced settings. Ask ORTEC Support via Freshdesk for more information.' }
];