export class ErrorWithStatus extends Error {
  response: { status: number, data: string };
  constructor(status: number, message: string) {
    super(`[${status}] ${message}`);
    this.name = this.constructor.name;
    this.response = { status, data: message };
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}