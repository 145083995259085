import React, { useState } from 'react';
import * as _ from 'lodash'
import cn from 'classnames'
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';


import { CICChannel, ROOT_NODE_ID } from '../domain';
import { Profile } from '../../../../../../domain';
import { Audience } from '../../Audiences/domain';

import styles from './CicTable.module.scss';
import { Translations } from '../../../../../translations';
import { countSearchHitsRecursively, getChannelSearchStatus, ChannelSearchStatus } from './cicTableSearchHelper';
import { ChannelAction } from '../Channels';
import { TippyReact } from '../../../../../components';
import { formatChannelTitle } from '../cicChannelsHelper';


const EXPANDED_LIMIT = 200

interface Props {
  profile: Profile
  channels: CICChannel[]
  audiences: Audience[]
  onChannelAction: (action: ChannelAction, channelId: number) => void
}


//
// ─── HELPERS ────────────────────────────────────────────────────────────────────
//

function formatTags(tags: string[]): string {
  return tags.length === 1 ? tags[0] : `${tags.length} tags`
}

function formatAudience(audienceId: number, audiences: Audience[]): string {
  return audiences.find(aud => aud.id === audienceId)?.label || String(audienceId)
}

function formatAudiences(audienceIds: number[], audiences: Audience[]): string {
  const tr = Translations.Translate(Translations, 'Channels');
  switch (audienceIds.length) {
    case 0:
      return tr('allEmployees');
    case 1: {
      return formatAudience(audienceIds[0], audiences)
    }
    default:
      // const auds = audienceIds.map(audId => formatAudience(audId, audiences))
      return String(audienceIds.length)
  }
}



//
// ─── COMPONENTS ─────────────────────────────────────────────────────────────────
//

export default function CicOverviewTable(props: Props) {
  const { audiences, channels, onChannelAction } = props

  // this one tracks the value of the search input
  const [searchRaw, setSearchRaw] = useState('')
  // whenever props.channels change (i.e. we saved something and refetched cic) search is cleared
  React.useEffect(() => { setSearchRaw('') }, [props.channels])

  // this one tracks the value that we search on. it should be a debounced version of searchRaw.toLowerCase()
  const [searchDebounced, setSearchDebounced] = useState('')
  const debouncedSetSearch = React.useRef(_.debounce((s: string) => { setSearchDebounced(s) }, 300))
  React.useEffect(() => { debouncedSetSearch.current(searchRaw.toLowerCase()) }, [searchRaw])


  const hitsCount = React.useMemo(() => countSearchHitsRecursively(searchDebounced, channels), [channels, searchDebounced])

  const expandedMode = hitsCount < EXPANDED_LIMIT

  const tr = Translations.Translate(Translations, 'Channels');

  return <div className='tableContainer'>
    <Row className={'react-bs-table-tool-bar'}>
      <Col md={4}>
        <Input value={searchRaw} type="search" placeholder={'Search'} onChange={(e) => { setSearchRaw(e.target.value) }} />
      </Col>

      <Col md={8} className={styles.toolbarRight}>
        <div style={{ flex: 1 }}></div>
        <Button className={styles.btn} color="primary" onClick={() => { onChannelAction(ChannelAction.CREATE_CHILD, ROOT_NODE_ID) }} style={{ marginRight: '10px' }}>Create top level channel</Button>
        <Button className={styles.btn} color="primary" onClick={() => { onChannelAction(ChannelAction.EDIT_CHILDREN, ROOT_NODE_ID) }} style={{ marginRight: '10px' }}>Select top level channels</Button>
        <Button className={styles.btn} color="primary" onClick={() => { onChannelAction(ChannelAction.REORDER_CHILDREN, ROOT_NODE_ID) }}>Sort top level channels</Button>
      </Col>
    </Row>
    <Row className='react-bs-table-pagination'>
      <Col md={12}>
        <span>Showing {hitsCount} rows {!expandedMode ? '(collapsed)' : ''}</span>
      </Col>
    </Row>
    <table className={styles.table}>
      <colgroup>
        <col style={{ minWidth: '400px' }} />
        <col style={{ maxWidth: '150px' }} />
        <col style={{ maxWidth: '150px' }} />
        <col style={{ maxWidth: '150px' }} />
        <col style={{ maxWidth: '50px' }} />
        <col style={{ maxWidth: '0px' }} />
      </colgroup>
      <thead className={styles.tableHeader}>
        <tr>
          <th></th>
          <th><img data-tippy-content={tr("channelType")} src="/assets/icons/16/form_type.svg" /></th>
          <th><img data-tippy-content={tr("audiences")} src="/assets/icons/16/audience.svg" /></th>
          <th><img data-tippy-content={tr("tags")} src="/assets/icons/16/tags_icon.svg" /></th>
          <th><img data-tippy-content={tr("sources")} src="/assets/icons/16/sources.svg" /></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.channels.map(channel =>
          <ChannelRecursive
            key={channel.id}
            profile={props.profile}
            channel={channel}
            searchValue={searchDebounced}
            parents={[]}
            audiences={audiences}
            expandedMode={expandedMode}
            onChannelAction={onChannelAction}
          />
        )}
      </tbody>
    </table>
  </div>
}

function ChannelRecursive(props: {
  profile: Profile
  searchValue: string
  channel: CICChannel
  parents: CICChannel[]
  audiences: Audience[]
  expandedMode: boolean
  onChannelAction: (action: ChannelAction, channelId: number) => void
}) {
  const [toggled, setToggled] = useState<boolean>(false)
  React.useEffect(() => { setToggled(false) }, [props.searchValue])
  const status = React.useMemo(
    () => getChannelSearchStatus(props.searchValue, props.channel),
    [props.channel, props.searchValue]
  )

  const { channel, searchValue, parents, audiences, expandedMode, onChannelAction, profile } = props
  const expanded = expandedMode ? !toggled : toggled
  const tr = Translations.Translate(Translations, 'Channels');

  if (status === ChannelSearchStatus.NOT_FOUND) {
    return null
  }
  return < >
    <tr
      className={cn(styles.row, styles.rowWithOverlay, { [styles.grey]: status === ChannelSearchStatus.DESC_FOUND })}
      onClick={(e) => { e.stopPropagation(); onChannelAction(ChannelAction.EDIT_CHANNEL, channel.id) }}
    >
      <td className={styles.firstCell} style={{ marginLeft: `${parents.length * 40}px` }}>
        {channel.childChannels?.length === 0
          ? <div className={styles.toggle}>&nbsp;</div>
          : <div className={styles.toggle} onClick={(e) => { e.stopPropagation(); setToggled(!toggled) }}>
            <div className={cn("fa", expanded ? "fa-minus" : "fa-plus")} />
          </div>
        }
        <div>{formatChannelTitle(profile, channel)}</div>
      </td>

      <td className={styles.textCell}>{channel.channelType}</td>
      <td className={styles.textCell}>{formatAudiences(channel.audiences, audiences)}</td>
      <td className={styles.textCell}>{formatTags(channel.tags || [])}</td>
      <td className={styles.textCell}>{channel.sources?.length}</td>
      <td>
        <div className={styles.rowOverlay}>
          <Button className={styles.btn} onClick={(e: any) => { e.stopPropagation(); onChannelAction(ChannelAction.CREATE_CHILD, channel.id) }}>{tr('create child channel')}</Button>
          <Button className={styles.btn} onClick={(e: any) => { e.stopPropagation(); onChannelAction(ChannelAction.EDIT_PARENTS, channel.id) }}>{tr('edit parents')}</Button>
          <Button className={styles.btn} onClick={(e: any) => { e.stopPropagation(); onChannelAction(ChannelAction.EDIT_CHILDREN, channel.id) }}>{tr('edit children')}</Button>
          {(channel.childChannels?.length || 0) < 2
            ? <TippyReact content={tr('reorderValidation')}><div><Button className={styles.btn} disabled>{tr('sort children')}</Button></div></TippyReact>
            : <Button className={styles.btn} onClick={(e: any) => { e.stopPropagation(); onChannelAction(ChannelAction.REORDER_CHILDREN, channel.id) }}>{tr('sort children')}</Button>
          }
          {(channel.childChannels?.length || 0) > 0
            ? <TippyReact content={tr('deleteValidation')}><div><Button className={styles.btn} disabled>{tr('delete channel')}</Button></div></TippyReact>
            : <Button className={styles.btn} onClick={(e: any) => { e.stopPropagation(); onChannelAction(ChannelAction.DELETE_CHANNEL, channel.id) }}>{tr('delete channel')}</Button>
          }

        </div>
      </td>
    </tr>
    {expanded && channel.childChannels &&
      channel.childChannels.map(child =>
        <ChannelRecursive
          key={child.id}
          profile={profile}
          channel={child}
          parents={[...parents, channel]}
          searchValue={searchValue}
          audiences={audiences}
          expandedMode={expandedMode}
          onChannelAction={onChannelAction}
        />
      )
    }
  </>
}


