import React, { Fragment } from "react";

import { DateHelper } from "."

export const dateFormatter = (cell: string, row: any) => {
  // return DateHelper.formatDateTimeStr(cell);
  return cell ? DateHelper.dateTimeToLocaleString(cell) : ''
}

export const booleanFormatter = (cell: boolean, row: any) => {
  return cell ? <i className={'fa fa-check'}></i> : ''
}
export const booleanReverseFormatter = (cell: boolean, row: any) => {
  return !cell ? <i className={'fa fa-check'}></i> : ''
}
export const numberFormatter = (cell: number, row: any) => {
  if (cell == undefined) {
    return '-'
  }
  return (cell < 0) ? '?' : cell
}

export const numbersColumnConfig = (icon: string, tooltip: string) => {
  return {
    align: 'center',
    formatter: numberFormatter,
    headerStyle: { width: '150px' },
    headerAlign: 'center',
    headerFormatter: (column: any, colIndex: number) => {
      return <Fragment><img data-tippy-content={tooltip} src={`/assets/icons/16/${icon}.svg`} /><span></span></Fragment>
    },
  }
}

export const dateColumnConfig = (icon: string, tooltip: string) => {
  return {
    align: 'center',
    formatter: dateFormatter,
    headerStyle: { width: '150px' },
    headerAlign: 'center',
    headerFormatter: (column: any, colIndex: number) => {
      return <Fragment><img data-tippy-content={tooltip} src={`/assets/icons/16/${icon}.svg`} /><span></span></Fragment>
    },
  }
}

export const booleanColumnConfig = (icon: string, tooltip: string) => {
  return {
    align: 'center',
    formatter: booleanFormatter,
    headerStyle: { width: '50px' },
    headerAlign: 'center',
    headerFormatter: (column: any, colIndex: number) => {
      return <Fragment><img data-tippy-content={tooltip} src={`/assets/icons/16/${icon}.svg`} /><span></span></Fragment>
    },
  }
}

export const booleanReverseColumnConfig = (icon: string, tooltip: string) => {
  return {
    ...booleanColumnConfig(icon, tooltip),
    formatter: booleanReverseFormatter,
  }
}