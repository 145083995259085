import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { StatsFilter } from '../../../../../domain';
import { API } from '../../../../apis';

import './css/VisitsPerHourOfDayChart.min.css';

export interface Props {
    magazine: number,
    statsFilter: StatsFilter,
}

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const hours = new Array(24).fill(0).map((_, i) => `${i}`);

const totalScales = 10;

type idValuePair = {
    id: string,
    value: number
}
export default function VisitsPerHourOfDayChart(props: Props) {
    const { magazine, statsFilter } = props;

    const [visitsPerWeekdayHour, setVisitsPerWeekdayHour] = useState<idValuePair[] | undefined | "!">(undefined);

    const [loading, setLoading] = useState<boolean>(true);

    const calcVisitsPerWeekdayHour = async () => {
        try {
            setLoading(true);
            setVisitsPerWeekdayHour(undefined);
            const { data: resp } = await API.analytics.loadChartData('visitsPerWeekdayHour', magazine, statsFilter);
            setVisitsPerWeekdayHour(resp.data);
        } catch (error) {
            console.error('An error occured:' + error.message);
            setVisitsPerWeekdayHour('!');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        calcVisitsPerWeekdayHour();
    }, [statsFilter.period, statsFilter.audience])

    const heatMapDataRow = (rowLabel: string, index: number, rowData: any, scaleStep: number) => {

        return (
            <div className="heatMap-row" key={index}>
                <div className="heatMap-cell label-cell">{rowLabel}</div>
                {_.map(rowData, (d, j) => {
                    const sc = calcScale(d, scaleStep)
                    return <div key={`${index}-${j}`} className={`heatMap-cell data-cell ${sc < 0.6 ? 'light' : 'dark'}`} style={{ backgroundColor: `rgba(0,46,103,${sc || (d ? 0.05 : 0.02)})` }}>{d}</div>
                })}
            </div>
        )
    }

    const heatMapXLabelsRow = (rowData: any) => {
        return (
            <div className="heatMap-row">
                <div className="heatMap-cell label-cell"></div>
                {_.map(rowData, (d, i) => {
                    return <div key={i} className="heatMap-cell label-cell">{d}</div>
                })}
            </div>
        )
    }

    const calcData = () => {
        if (!visitsPerWeekdayHour || visitsPerWeekdayHour == '!') {
            return [];
        }

        const data = _.map(days, (d, i) => _.map(hours, (h, j) => {
            const doc = _.find(visitsPerWeekdayHour, ['id', `${(i + 1)}-${h}`]);
            return doc ? doc.value : 0;
        })
        );

        return data;
    }

    const calcScaleStep = () => {
        if (!_.isArray(visitsPerWeekdayHour)) {
            return 0;
        }
        const currentValues = _.map(visitsPerWeekdayHour, (o) => o.value);

        const maxValue = _.max(currentValues);

        if (!maxValue) {
            return 0;
        }
        const scaleStep = maxValue / totalScales;

        return scaleStep;
    }

    const calcScale = (value: number, scaleStep: number) => {
        return _.round(value / scaleStep) / totalScales;
    }


    const drawChart = () => {

        if (loading) {
            return <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>

        }

        const data = calcData();
        const scaleStep = calcScaleStep();


        return (
            <div className='chartBody'>
                <div className={'heatMap'}>
                    {_.map(data, (rowData, index) => {
                        return heatMapDataRow(days[index], index, rowData, scaleStep);
                    })}
                    {heatMapXLabelsRow(hours)}
                </div>
            </div>
        )
    }

    const drawChartPanel = () => {
        const scaleStep = calcScaleStep();
        const scales = new Array(totalScales).fill(0).map((k, i) => {
            return _.round((i + 1) * scaleStep);
        });
        const uniqueScales = _.uniq(_.filter(scales, (sc => sc > 0)));
        return (
            <div className='chartPanel'>
                <div className='chartTitle'>Visits Per Hour of the Day</div>
                <div className="separator"></div>
                {!loading &&
                    <div className="chartScales">
                        {_.map(uniqueScales, (sc) => {
                            return (
                                <div key={sc} className="chartScale">
                                    <div className="chartScaleSquare" style={{ backgroundColor: `rgba(0,46,103,${calcScale(sc, scaleStep)})` }}>{ }</div>
                                    <div className="chartScaleNum">{sc}</div>
                                </div>
                            )
                        })
                        }
                    </div>
                }
            </div>
        )
    }

    return (
        <div id="VisitsPerHourOfDayChart" className="chartContainer">
            {drawChartPanel()}
            {drawChart()}
        </div>
    )

}
