import React from 'react';
// import { StatsFilter, Profile } from '../domain';

export interface LanguageContextInterface {
  language: string,
  // periodFilterHandler: (period: string) => void,
  // audienceFilterHandler: (audience: number) => void,
}

const ctxt = React.createContext<LanguageContextInterface | null>(null);

export const LanguageContextProvider = ctxt.Provider;
export const LanguageContextConsumer = ctxt.Consumer;