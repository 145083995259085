import _ from 'lodash';
import { page } from 'pdfkit';
import urlParse from 'url-parse';
import { EdEndpoint, EdPage, EdPageKey, EdRole, EdScreenKey, EndpointMethod } from '../../client/accessRights/domain';
import { Profile } from '../../domain';


export const findPageAndTab = (referrer?: string): { page: string, tab?: string } | undefined => {
  if (!referrer) {
    return undefined;
  }
  const url = urlParse(referrer, {}, true);
  const tab = url.query['tab'];
  const page = url.pathname.split('/')[2];
  return {
    page,
    tab
  }
}

const findPageKeyAndParent = (referrer?: string): { pageKey: EdPageKey | EdScreenKey, parentPage?: EdPageKey } | undefined => {
  if (!referrer) {
    return undefined;
  }
  const url = urlParse(referrer, {}, true);
  const tab = url.query['tab'] as EdScreenKey;
  const page = url.pathname.split('/')[2] as EdPageKey;
  return {
    pageKey: tab ? tab : page,
    parentPage: tab ? page : undefined
  }
}

export const getUserRole = (profile: Profile): EdRole => {
  if (profile.globalAudience) {
    return 'admin';
  }
  return profile.role || 'none';
}

const findEdPage = (edPages: EdPage[], pageKey: EdPageKey | EdScreenKey, parentPage?: EdPageKey): EdPage | undefined => {
  const edPage = _.find(edPages, (p) => {
    return (p.key === pageKey && p.parentPage === parentPage);
  });
  return edPage;
}

const shouldRoleAccessPage = (role: EdRole, edPages: EdPage[], pageKey: EdPageKey | EdScreenKey, parentPage?: EdPageKey): boolean => {
  if (role == 'admin') {
    return true;
  }
  const edPage = findEdPage(edPages, pageKey, parentPage);
  if (!edPage) {
    return true;
  }
  if (edPage.accessibleOnlyToRoles) {
    return _.includes(edPage.accessibleOnlyToRoles, role);
  }
  if (edPage.notAccessibleToRoles) {
    return !_.includes(edPage.notAccessibleToRoles, role);
  }
  return true;
}

export const shouldUserAccessPage = (profile: Profile, edPages: EdPage[], referrer?: string): boolean => {
  const userRole = getUserRole(profile);
  const p = findPageKeyAndParent(referrer);
  if (!p) {
    return false;
  }
  return (p.parentPage ? shouldRoleAccessPage(userRole, edPages, p.parentPage, undefined) : true) && shouldRoleAccessPage(userRole, edPages, p.pageKey, p.parentPage);
}

export const Endpoint = (method: EndpointMethod, path: RegExp | string, accessibleOnlyToRoles?: EdRole[],): EdEndpoint => {
  return { method, path, accessibleOnlyToRoles };
}

export const getPagesOfEndpoint = (edPages: EdPage[], endpoint: EdEndpoint): EdPage[] => {
  return _.filter(edPages, (p) => {
    return (p.endpoints && _.some(p.endpoints, (e) => {
      return (isSameEndpoint(e, endpoint))
    })) ? true : false;
  });
}

export const getAccessibleOnlyRolesOfEndpoint = (edPages: EdPage[], endpoint: EdEndpoint): EdRole[] => {
  const edRoles: EdRole[] = [];

  for (const p of edPages) {
    if (!p.endpoints) {
      continue;
    }
    for (const e of p.endpoints) {
      if (isSameEndpoint(e, endpoint)) {
        if (e.accessibleOnlyToRoles) {
          edRoles.push(...e.accessibleOnlyToRoles);
        }
      }
    }
  }

  return _.uniq(_.compact(edRoles));
}

export const shouldRoleAccessEndpoint = (role: EdRole, edPages: EdPage[], endpoint: EdEndpoint): boolean => {
  if (role == 'admin') {  //admins should access all endpoints
    return true;
  }
  const pagesOfEndpoint = getPagesOfEndpoint(edPages, endpoint); // editors should access all non mentioned endpoints

  if (_.isEmpty(pagesOfEndpoint)) {
    return true;
  }

  const accessibleOnlyRolesOfEndpoint = getAccessibleOnlyRolesOfEndpoint(pagesOfEndpoint, endpoint);

  if (!_.isEmpty(accessibleOnlyRolesOfEndpoint) && !_.includes(accessibleOnlyRolesOfEndpoint, role)) {
    return false;
  }

  return _.some(pagesOfEndpoint, (p) => {
    return (p.parentPage ? shouldRoleAccessPage(role, edPages, p.parentPage, undefined) : true) && shouldRoleAccessPage(role, edPages, p.key, p.parentPage);
  });
}

export const shouldUserAccessEndpoint = (profile: Profile, edPages: EdPage[], endpoint: EdEndpoint): boolean => {
  const userRole = getUserRole(profile);
  return shouldRoleAccessEndpoint(userRole, edPages, endpoint);
}


const isSameEndpoint = (e: EdEndpoint, endpoint: EdEndpoint): boolean => {
  return (
    (
      (_.isRegExp(e.path) && (endpoint.path as string).match(e.path))
      ||
      e.path == endpoint.path
    )
    &&
    (e.method == '*' || e.method == _.toLower(endpoint.method))
  );
}