import React, { useEffect, useState } from 'react';
import styles from './NcDevicePlaylist.module.scss';
import { NoSlideshowsBox } from './NoSlideshowsBox';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../../apis';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import _ from 'lodash';
import { NcPlaylistSlideshowBox } from './NcPlaylistSlideshowBox';
import { NcPlaylistGroupBox } from './NcPlaylistGroupBox';
import { NcPlaylistGroup, NcPlaylistSlideshow } from '../../NarrowcastingDomain';

interface Props {
  magazine: number,
  deviceId: number,
  group_ids: number[],
}

export const NcDevicePlaylist = ({ magazine, deviceId, group_ids }: Props) => {

  const [directSlideshows, setDirectSlideshows] = useState<NcPlaylistSlideshow[]>([]);
  const [groups, setGroups] = useState<NcPlaylistGroup[]>([]);
  const [alreadyLoaded, setAlreadyLoaded] = useState<boolean>(false);

  const ncPlaylistQuery = useQuery({
    queryKey: ['ncPlaylist', deviceId, group_ids],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcDevicePlaylist(magazine, deviceId, group_ids);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting NC device playlist`);
      }
    }
  });

  useEffect(() => {
    if (ncPlaylistQuery.data) {
      setDirectSlideshows(ncPlaylistQuery.data.direct);
      setGroups(ncPlaylistQuery.data.groups);
      setAlreadyLoaded(true);
    }

  }, [ncPlaylistQuery.data])


  return (
    <div className={styles.NcDevicePlaylist}>
      <div className={styles.playlistSection}>
        <div className={styles.playlistSectionTitle}>direct slideshows </div>
        <div className={styles.playlistSectionContent}>
          {ncPlaylistQuery.isLoading
            && !alreadyLoaded
            && <div className={styles.loaderWrapper}><OrtecLoader /></div>}
          {ncPlaylistQuery.isSuccess
            && _.isEmpty(directSlideshows)
            && <NoSlideshowsBox prefix={'direct'} />}
          {_.map(directSlideshows, (ds) => {
            return (
              <NcPlaylistSlideshowBox slideshow={ds} />
            )
          })}

        </div>
      </div>
      <div className={styles.playlistSection}>
        <div className={styles.playlistSectionTitle}>
          groups slideshows
          {ncPlaylistQuery.isFetching && <div className={styles.inlineLoader}></div>}
        </div>
        <div className={styles.playlistSectionContent}>
          {ncPlaylistQuery.isFetching
            && <div className={styles.loaderWrapper}><OrtecLoader /></div>}
          {!ncPlaylistQuery.isFetching
            && _.isEmpty(groups)
            && <NoSlideshowsBox prefix={'groups'} />}
          {_.map(groups, (g) => {
            return (
              <NcPlaylistGroupBox group={g} />
            )
          })}
        </div>
      </div>
    </div>
  )
}
