import React, { useMemo, useState } from 'react';

import styles from './CmsPublishDialog.module.scss';
import { GenericModal } from '../../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../../components/GenericModal/GenericModalHeader';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { LoadingButton } from '../../../../../widgets';
import { CmsPublishingDate } from '../CmsItemDetails/components/CmsPublishingDate';

interface Props {
  agendaMode?: boolean,
  onPublish: (date: Date | null) => void
  onClose: () => void
}

export const CmsPublishDialog = ({ agendaMode, onPublish, onClose }: Props) => {

  const [publicationDate, setPublicationDate] = useState<Date | null>(null);


  const itemPrefix = useMemo(() => {
    return `${agendaMode ? 'agenda item' : 'article'}`
  }, [agendaMode]);

  return (
    <GenericModal isOpen={true} centered >
      <GenericModalHeader
        onClose={onClose}
        title={`Publish ${itemPrefix}`}
      />
      <ModalBody className={styles.CmsPublishDialog}>
        <div>You are about to publish this {itemPrefix}.</div>
        <div>
          <CmsPublishingDate publicationDate={publicationDate} onChange={setPublicationDate} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button style={{ flex: 1 }} size={'lg'} outline color="secondary" onClick={onClose}>Cancel</Button>
        <LoadingButton style={{ flex: 1 }} text={`publish ${publicationDate === null ? 'now' : (publicationDate < new Date() ? 'in the past' : 'later')}`} loading={false} onClick={() => { onPublish(publicationDate) }} />
      </ModalFooter>
    </GenericModal>
  )
}
