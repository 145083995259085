import React, { useState } from 'react';
import styles from './Sharepoint.module.scss';
import { Profile } from '../../../../../domain';
import { HistoryHelper } from '../../../../utils';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import { API } from '../../../../apis';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { SharepointSite } from './SharepointDomain';
import { SharepointSiteDetails } from './SharepointSiteDetails';

interface Props {
  magazine: number,
  profile: Profile,
}

export const Sharepoint = ({ magazine, profile }: Props) => {

  const [activeSite, setActiveSite] = useState<SharepointSite>()

  // const history = useHistory();
  // const siteId = HistoryHelper.getQueryParamNumber(history, 'site');

  const sharepointSitesQuery = useQuery({
    queryKey: ['sharepointSites', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.sharepoint.getMagazineSites(magazine);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting sharepoint sites`);
      }
    }
  });

  const onOpenSharepointSiteDetails = (site: SharepointSite) => {
    setActiveSite(site);
  }

  const onCloseSharepointSiteDetails = () => {
    setActiveSite(undefined);
  }
  const isLoading = sharepointSitesQuery.isFetching && activeSite === undefined;

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'siteId',
      hidden: true,
      text: 'Id',
    },
    {
      dataField: 'title',
      text: 'Site',
      sort: true,
    },
    {
      dataField: 'url',
      sort: true,
      text: 'url',
    },
  ];



  return (
    <div className={styles.sharepoint}>
      {isLoading && <OrtecLoader />}
      <DataTable
        data={sharepointSitesQuery.data || []}
        columns={columns}
        keyField={'url'}
        onRowClick={(row: SharepointSite) => { onOpenSharepointSiteDetails(row) }}
      />
      {activeSite &&
        <SharepointSiteDetails
          magazine={magazine}
          profile={profile}
          site={activeSite}
          onClose={onCloseSharepointSiteDetails}
        />
      }
    </div>
  )
}
