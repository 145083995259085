import React from 'react';
import { Input } from 'reactstrap';

import styles from './MediaSelectButton.module.scss';

export type AssetType = 'images' | 'audios' | 'videos' | 'files';

interface Props {
  text: string,
  onClick: () => void
  inputClass?: string
  type?: AssetType
  disabled?: boolean
}

export const MediaSelectButton = ({ text, onClick, inputClass, type, disabled }: Props) => {
  return (
    <div className={styles.MediaSelectButton} data-media-type={type}>
      <Input disabled={disabled} className={inputClass} readOnly type='text' value={text} onClick={onClick} />
    </div>
  )
}
