import React from 'react';

import styles from './AccordionItem.module.scss';
import { Collapse } from 'reactstrap';

interface Props {
  title: string
  isOpen: boolean
  toggle: () => void
  children: React.ReactNode
}

export const AccordionItem = ({ title, isOpen, toggle, children }: Props) => {
  return (
    <div className={styles.AccordionItem}>
      <div className={styles.AccordionItemHeader} onClick={toggle}>
        <div className={styles.AccordionItemHeaderTitle}>{title}</div>
        <div className={styles.AccordionItemHeaderIcon}><i className={`fa fa-chevron-${isOpen ? 'up' : 'down'}`}></i></div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className={styles.AccordionItemBody}>
          {children}
        </div>
      </Collapse>
    </div>
  )
}
