import React, { useEffect } from 'react';
import { Progress } from 'reactstrap';

import styles from './UploadProgressBar.module.scss';

export interface UploadingInfo {
  totalItems: number,
  uploaded: number,
}

interface Props {
  uploadingInfo: UploadingInfo
  max: number,
  value: number
}

export const UploadProgressBar = ({ max, value }: Props) => {

  // useEffect(() => {

  // }, [uploadingInfo?.uploaded])

  // if(!uploadingInfo){
  //   return null;
  // }

  // const {totalItems, uploaded} = uploadingInfo;

  const percent = Math.round(value * 100 / max)

  return (
    <div className={styles.UploadProgressBar}>
      <div className={styles.uploadingText}>Uploaded {value} of {max}..</div>
      <Progress
        max={max}
        value={value}
        animated
        striped
      >
        {percent} %
      </Progress>
    </div>
  )
}
