import './css/ActivePeriodSelector.min.css';

import React, { useState } from 'react';
import { Translations } from "../../translations";
import { StatsFilter } from '../../../domain';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from 'lodash';
import moment from 'moment';
import { PeriodGroup } from './PeriodGroup';
import { DateHelper } from '../../utils';

interface Props {
    magazine: string,
    statsFilter: StatsFilter,
    periodFilterHandler: (period: string) => void,
    toggleCustomClass?: string
}

export function ActivePeriodSelector(props: Props) {
    const { periodFilterHandler, statsFilter, toggleCustomClass } = props;
    const { period } = statsFilter

    const tr = Translations.Translate(Translations, 'FilterBar');

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const periodToFilterLabel = (): string => {
        if (!period) {
            return '';
        }

        const f = periodToFilter()
        if (_.includes(['customMonth', 'customWeek', 'customDay', 'customYear'], f)) {
            if (f === 'customDay') {
                return DateHelper.dateToLocaleString(period);
            }
            return period;
        }
        return tr(f);
    }

    const periodToFilter = (): string => {
        if (!period) {
            return '';
        }

        if (period == 'last30days') {
            return 'last30days';
        }
        if (period == '*') {
            return 'since_app_start';
        }

        if (/^\d{4}-\d{2}$/.test(period)) { //monthly      
            const currentMonth = moment().startOf('month');
            const thisMonth = currentMonth.format('YYYY-MM');
            if (period == thisMonth) {
                return 'thisMonth';
            }

            currentMonth.subtract(1, 'month');
            const lastMonth = currentMonth.format('YYYY-MM');
            if (period == lastMonth) {
                return 'lastMonth';
            }

            return 'customMonth';


        }
        if (/^\d{4}-W\d{1,2}$/.test(period)) { //weekly
            const currentWeek = moment().startOf('isoWeek');
            const thisWeek = currentWeek.format('GGGG-[W]W');
            if (period == thisWeek) {
                return 'thisWeek';
            }

            currentWeek.subtract(1, 'week');
            const lastWeek = currentWeek.format('GGGG-[W]W');
            if (period == lastWeek) {
                return 'lastWeek';
            }

            return 'customWeek';

        }
        if (/^\d{4}-\d{2}-\d{2}$/.test(period)) { //daily      
            const currentDay = moment();
            const today = currentDay.format('YYYY-MM-DD');
            if (period == today) {
                return 'today';
            }

            currentDay.subtract(1, 'day');
            const yesterday = currentDay.format('YYYY-MM-DD');
            if (period == yesterday) {
                return 'yesterday';
            }

            return 'customDay';


        }
        if (/^\d{4}$/.test(period)) { //yearly      
            const currentYear = moment().startOf('year');
            const thisYear = currentYear.format('YYYY');
            if (period == thisYear) {
                return 'thisYear';
            }

            currentYear.subtract(1, 'year');
            const lastYear = currentYear.format('YYYY');
            if (period == lastYear) {
                return 'lastYear';
            }
            return 'customYear';
        }

        return '';

    }

    const filterToPeriod = (f: string): string => {

        switch (f) {
            case 'last30days':
                return 'last30days';
            case 'since_app_start':
                return '*';
            case 'thisYear':
                return moment().startOf('year').format('YYYY');
            case 'lastYear':
                return moment().startOf('year').subtract(1, 'year').format('YYYY');
            case 'thisMonth':
                return moment().startOf('month').format('YYYY-MM');
            case 'lastMonth':
                return moment().startOf('month').subtract(1, 'month').format('YYYY-MM');
            case 'thisWeek':
                return moment().startOf('isoWeek').format('GGGG-[W]W');
            case 'lastWeek':
                return moment().startOf('isoWeek').subtract(1, 'week').format('GGGG-[W]W');
            case 'yesterday':
                return moment().subtract(1, 'day').format('YYYY-MM-DD');
            default:
                return f;
        }

    }

    const changePeriod = (f: string) => {
        periodFilterHandler(filterToPeriod(f));
        toggle();
    }

    const activeFilter = periodToFilter();

    return (
        <Dropdown id="ActivePeriodSelector" className={''} isOpen={isOpen} toggle={toggle}>
            <DropdownToggle className={toggleCustomClass || ''} caret>
                {periodToFilterLabel()}
            </DropdownToggle>
            <DropdownMenu>
                <PeriodGroup header={'daily'} periodFilters={['yesterday']} activeFilter={activeFilter} changePeriod={changePeriod} activePeriod={period} customPeriodPickerMode={'daily'} />
                <DropdownItem divider />
                <PeriodGroup header={'weekly'} periodFilters={['thisWeek', 'lastWeek']} activeFilter={activeFilter} changePeriod={changePeriod} activePeriod={period} customPeriodPickerMode={'weekly'} />
                <DropdownItem divider />
                <PeriodGroup header={'monthly'} periodFilters={['thisMonth', 'lastMonth']} activeFilter={activeFilter} changePeriod={changePeriod} activePeriod={period} customPeriodPickerMode={'monthly'} />
                <DropdownItem divider />
                <PeriodGroup header={'yearly'} periodFilters={['thisYear', 'lastYear']} activeFilter={activeFilter} changePeriod={changePeriod} activePeriod={period} customPeriodPickerMode={'yearly'} />
                <DropdownItem divider />
                <PeriodGroup periodFilters={['last30days', 'since_app_start']} activeFilter={activeFilter} changePeriod={changePeriod} activePeriod={period} />
            </DropdownMenu>
        </Dropdown>
    )

}