import _ from 'lodash';
import React from 'react';
import { Input } from 'reactstrap';
import { MagazineLanguages } from '../../../../../domain';
import { NlTemplatesetWithTemplateConfigurations } from './domain';
import styles from './NewsletterTemplatesetDetails.module.scss';

interface Props {
  magazineLanguages: MagazineLanguages
  templateset: NlTemplatesetWithTemplateConfigurations,
  onChange: (newTemplateset: NlTemplatesetWithTemplateConfigurations) => void
}

export const NewsletterTemplateGeneralSettings = ({ magazineLanguages, templateset, onChange }: Props) => {

  return (
    <div className={styles.section} style={{ paddingBottom: '0px' }}>
      <div className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>general settings</div>
      </div>
      <div className={styles.sectionContent}>
        <div className={styles.flexRow}>
          <div style={{ flex: 1 }}>
            <div className={styles.label}>Mail subject:</div>
            <Input placeholder={`The subject that will appear to the user`} value={templateset.mail_subject} onChange={(e) => onChange({ ...templateset, mail_subject: e.target.value })} />
          </div>
          <div>
            <div className={styles.label}>Language:</div>
            <Input type={'select'} value={templateset.language} onChange={(e) => onChange({ ...templateset, language: e.target.value })}>
              {_.map(_.filter(magazineLanguages.languages, (l) => l.enabled == true), (l) => {
                return (<option value={l.key}>{l.key}</option>)
              })}
            </Input>
          </div>
        </div>
        <div className={styles.flexRow}>
          <div style={{ flex: 1 }}>
            <div className={styles.label}>Sender name:</div>
            <Input placeholder={`The name that will appear to the user`} value={templateset.mail_sender_name} onChange={(e) => onChange({ ...templateset, mail_sender_name: e.target.value })} />
          </div>
          <div style={{ flex: 1 }}>
            <div className={styles.label}>Sender email:</div>
            <Input placeholder={`The email that will appear to the user`} value={templateset.mail_sender_email} onChange={(e) => onChange({ ...templateset, mail_sender_email: e.target.value })} />
          </div>
        </div>
      </div>
    </div>
  )
}
