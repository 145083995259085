import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent, Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';
import './css/FormsOverviewTable.min.css'

import { Profile, Form } from '../../../../../domain';
import qs from 'query-string';
import moment from 'moment';
import { Translations } from '../../../../translations';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as _ from 'lodash';
import * as Api from '../../../../api';

import { SwalSuccess, SwalError, SwalDelete, OrtecLoader } from '../../../../widgets';

import Toggle from 'react-toggle';
import "react-toggle/style.css"
import { DateHelper } from '../../../../utils';
import { TippyReact } from '../../../../components';

export interface Props {
  magazine: string,
  profile: Profile,
  clickFormHandler: (form: number) => void
  clickResultsHandler: (form: number) => void
  createNewFormHandler: () => void
}
export interface State {
  activeTab: string,
  forms?: Form[],
  historyLastUpdate: number
}

export class FormsOverviewTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: '1',
      historyLastUpdate: moment().valueOf()
    };
  }

  audienceFormatter = (cell: any, row: any) => {
    const { profile } = this.props;
    const tr = Translations.Translate(Translations, 'Forms');
    const audiences: any[] = [];
    if (!cell) {
      return '-';
    }

    cell.map((a: number) => {
      audiences.push((a == 1) ? tr('all_employees') : _.get(_.find(profile.audiences, ['id', a]), 'label'));
    });

    if (audiences.length == 0) {
      return '-'
    } else if (audiences.length == 1) {
      return audiences[0];
    } else {
      return <span data-tippy-content={audiences.join(', ')}>{audiences.length}</span>;
    }
  }

  channelFormatter = (cell: any, row: any) => {
    const { profile } = this.props;
    const tr = Translations.Translate(Translations, 'Forms');
    const channels: any[] = [];
    if (!cell) {
      return '-';
    }

    // cell.map((a: number) => {
    //   audiences.push((a == 1) ? tr('all_employees') : _.get(_.find(profile.audiences, ['id', a]), 'label'));
    // });

    if (cell.length == 0) {
      return '-'
    } else {
      return <span data-tippy-content={cell.join(', ')}>{cell.length}</span>;
    }
  }

  buttonsFormatter(cell: any, row: any) {
    const tr = Translations.Translate(Translations, 'Forms');

    return (
      <div className='actionHoveringDiv'>
        <Button className="selectButton secondary" /*data-tippy-content={tr(`selectArticle`)}*/
          // style={{ marginLeft: '10px' }}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.duplicateForm(row.id);
            return false;
          }}>
          {tr('duplicate')}
        </Button>
        <Button className="selectButton secondary" /*data-tippy-content={tr(`selectArticle`)}*/
          style={{ marginLeft: '10px' }}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.deleteForm(row.id);
            return false;
          }}>
          {tr('delete')}
        </Button>
        <Button className="selectButton secondary alwaysVisible" /*data-tippy-content={tr(`selectArticle`)}*/
          style={{ marginLeft: '10px' }}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.props.clickResultsHandler(row.id);
            return false;
          }}>
          {tr('viewResults')}
        </Button>
      </div>
    )
  }
  toggleFormatter(cell: any, row: any) {
    const tr = Translations.Translate(Translations, 'Forms');
    let tooltip = `Form has not been published yet. Click to edit and publish.`;
    if (row.status == 2) {
      tooltip = `Form has been published. Click to mark it as finished.`
    } else if (row.status == 3) {
      tooltip = `Form has been finished. Click to publish it again.`
    }

    return (
      <Fragment>
          <TippyReact content={tooltip}>
            <div onClick={(e) => { if (row.status == 1) { return true }; e.preventDefault(); e.stopPropagation(); return false; }}>
              <Toggle disabled={row.status == 1} checked={row.status == 2} onChange={(e) => { this.changeStatus(row.id, e.target.checked ? 2 : 3) }} />
            </div>
          </TippyReact>
      </Fragment>
    )
  }

  deleteForm = (form: number) => {
    SwalDelete.fire({
      // type: 'warning',
      title: 'Are you sure?',
      text: `This action will delete form with id: ${form}`,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      // focusConfirm: false,
      // animation: false,
      // customClass: {
      //   popup: 'animated pulse',
      //   confirmButton: 'bg-danger',
      // },
    }).then((result) => {
      if (result.value) {
        Api.deleteForm(parseInt(this.props.magazine), form).then((resp) => {
          if (!resp.data || resp.data.error) {
            console.log('Error in deleting form');
            SwalError.fire({
              // type: 'error',
              title: 'Something went wrong!',
              text: `Error in deleting form ${form}`,
              footer: `<div class="alert alert-danger">${resp.data.error}</div>`
            });
            return;
          }
          this.getForms();
          SwalSuccess.fire({
            type: 'success',
            title: 'Success!',
            text: `Form with id ${form} has been deleted`,
            // footer: `<div class="alert alert-error">${resp.data.error}</div>`
          });
        })
          .catch((error) => {
            console.log('An error occured:' + error.message)
            SwalError.fire({
              // type: 'error',
              title: 'Something went wrong!',
              text: `Error in deleting form ${form}`,
              footer: `<div class="alert alert-danger">${error.message}</div>`
            });
          });

      }
    })

  }

  duplicateForm = (form: number) => {
    // SwalDelete.fire({
    //   // type: 'warning',
    //   title: 'Are you sure?',
    //   text: `This action will delete form with id: ${form}`,
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes, delete it!',
    //   focusCancel: true,
    //   // focusConfirm: false,
    //   // animation: false,
    //   // customClass: {
    //   //   popup: 'animated pulse',
    //   //   confirmButton: 'bg-danger',
    //   // },
    // }).then((result)=> {
    //   if(result.value){
    Api.duplicateForm(parseInt(this.props.magazine), form).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in deleting form');
        SwalError.fire({
          // type: 'error',
          title: 'Something went wrong!',
          text: `Error in duplicating form ${form}`,
          footer: `<div class="alert alert-danger">${resp.data.error}</div>`
        });
        return;
      }
      this.getForms();
      // SwalSuccess.fire({
      //   type: 'success',
      //   title: 'Success!',
      //   text: `Form with id ${form} has been duplicated`,
      //   // footer: `<div class="alert alert-error">${resp.data.error}</div>`
      // });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
        SwalError.fire({
          // type: 'error',
          title: 'Something went wrong!',
          text: `Error in duplicating form ${form}`,
          footer: `<div class="alert alert-danger">${error.message}</div>`
        });
      });

    //   }
    // })

  }
  changeStatus = (form: number, status: number) => {
    const { forms } = this.state;

    const newForms = _.map(forms, (f) => {
      if (f.id == form) {
        return { ...f, status: status };
      } else {
        return { ...f };
      }
    });
    this.setState({ forms: newForms });
    Api.changeStatus(parseInt(this.props.magazine), form, status).then((resp) => {
      if (!resp.data || resp.data.error) {
        SwalError.fire({
          // type: 'error',
          title: 'Something went wrong!',
          text: `Error in changing status of form ${form}`,
          footer: `<div class="alert alert-danger">${resp.data.error}</div>`
        });
        this.setState({ forms })
        return;
      }
      // this.getForms();

    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
        SwalError.fire({
          title: 'Something went wrong!',
          text: `Error in changing status of form ${form}`,
          footer: `<div class="alert alert-danger">${error.message}</div>`
        });
        this.setState({ forms });
      });

    //   }
    // })

  }

  renderToolbar = (props: any) => {
    const tr = Translations.Translate(Translations, 'Forms');
    return (
      <Fragment>
        <Col md={4}>{props.components.searchField}</Col>
        <Col md={4}></Col>
        <Col md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button onClick={() => this.props.createNewFormHandler()}>{tr('createNewForm')}</Button>
        </Col>
      </Fragment>
    );
  }


  onRowClick = (row: any) => {
    this.props.clickFormHandler(row.id);
  }

  componentDidMount() {
    this.getForms();
  }

  filterFormsBasedOnAccessRights = (forms: Form[]) => { // editor should see only untargeted forms and forms targeted to audiences that he has access to
    const { profile } = this.props;
    if (profile.globalAudience) { //if editor is super admin then all forms should be visible
      return forms;
    }
    const auds = _.map(profile.audienceIds, _.toNumber);

    return _.filter(forms, (f) => {
      if (!f.audiences) {
        return false;
      }
      return _.isEmpty(f.audiences) || !_.isEmpty(_.intersection(auds, _.map(f.audiences, _.toInteger)));
    })
  }

  getForms = () => {
    Api.getForms(parseInt(this.props.magazine)).then((resp) => {

      if (!resp.data || resp.data.error) {
        // console.log('Error in getting chart data');
        this.setState({ forms: [] });
      }
      this.setState({ forms: resp.data ? this.filterFormsBasedOnAccessRights(resp.data) : [] });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }

  getAudiencesIds = () => {
    const { profile } = this.props;

    let audiences: number[] = [];

    if (!_.isEmpty(profile.audienceIds)) {
      audiences = _.map(profile.audienceIds, _.toInteger);
    }

    if (profile.globalAudience) {
      audiences.unshift(1);
    }

    return audiences;

  }


  renderPaginationPanel(props: any) {
    let max = 1;
    let min = 1;
    if (this.state.forms) {
      max = (this.state.forms.length / 10) + 1;
    }
    const tr = Translations.Translate(Translations, 'Tables');

    return (
      <Fragment>
        <Col md={8}>{props.components.pageList} {/*props.components.sizePerPageDropdown*/}</Col>
        <Col md={4}>
          <div className="goToPageDiv">
            <input id="goToPageInput" onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                let val = e.target.value;
                if (val < min) {
                  val = min;
                } else if (val > max) {
                  val = max;
                }
                props.changePage(val);
                e.target.value = '';
              }
            }} className='form-control' type="number" placeholder={tr('go_to_page')} style={{ width: '115px' }} />
            <button className="btn" onClick={(e) => {
              var k: any = document.getElementById('goToPageInput');
              let val = k.value;
              if (val < min) {
                val = min;
              } else if (val > max) {
                val = max;
              }
              props.changePage(val);
              k.value = ''
            }}>{'>'}</button>
          </div>
        </Col>
      </Fragment>
    );
  }

  statusFormatter = (cell: any) => {
    const tr = Translations.Translate(Translations, 'Forms');
    try {
      // const status = (cell);
      switch (cell) {
        case 1:
          return `<i class="fa fa-circle status-draft" aria-hidden="true" data-tippy-content="${tr("draft")}" data-tippy-placement="right"></i>`
        case 2:
          return `<i class="fa fa-circle status-published" aria-hidden="true" data-tippy-content="${tr("published")}" data-tippy-placement="right"></i>`
        case 3:
          return `<i class="fa fa-circle status-finished" aria-hidden="true" data-tippy-content="${tr("finished")}" data-tippy-placement="right"></i>`
        default:
          return `<i class="fa fa-circle status-deleted" aria-hidden="true" data-tippy-content="${tr("deleted")}" data-tippy-placement="right"></i>`
      }

    } catch (error) {
      return <>{cell}</>;
    }
  }

  dateFormatter = (cell: any) => {
    try {
      return <>{DateHelper.dateTimeToLocaleString(cell)}</>;
    } catch (error) {
      return <>{cell}</>;
    }
  }
  resultsFormatter = (cell: any) => {
    return <>{cell ? cell : '-'}</>;
  }
  typeFormatter = (cell: any) => {
    switch (cell) {
      case 's_poll':
        return 'poll';
      default:
        return <>{cell}</>;
    }
  }

  titleFormatter = (cell: any) => {
    return (<div className={'ellipsisDiv'} data-tippy-content={cell}>{cell}</div>);
  }

  // findAllChannelsIds = (ids:number[],channels: Channel[]) => {
  //   for (const c of channels) {
  //     ids.push(c.id);
  //     if(c.childChannels){
  //       this.findAllChannelsIds(ids,c.childChannels);
  //     }
  //   }
  //   return ids;
  // }

  render() {
    const { magazine, profile } = this.props;
    const { forms } = this.state;
    const tr = Translations.Translate(Translations, 'Forms');
    const trt = Translations.Translate(Translations, 'Tables');

    const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>;

    const options: any = {
      onRowClick: this.onRowClick.bind(this),
      toolBar: this.renderToolbar,
      paginationPanel: this.renderPaginationPanel.bind(this),
      defaultSortName: 'cdate',  // default sort column name
      defaultSortOrder: 'desc',  // default sort order
      sizePerPage: 25,
      sizePerPageList: [
        // {text:`10 ${trt('entries')}`,value: 10},
        { text: `25 ${trt('entries')}`, value: 25 },
        { text: `50 ${trt('entries')}`, value: 50 },
        { text: `100 ${trt('entries')}`, value: 100 },
        // {text:'All entries',value: articles?articles.length:0},
      ]
    }


    return (
      <Fragment>
        <div id="FormsOverviewTable" className="">
          <div className="tableDiv withLoader">
            {Translations.EnableTranslations(Translations)}
            {!forms ? <OrtecLoader /> :
              <BootstrapTable ref="formsOverviewTable" data={forms} bordered={false} hover={true} search={true} options={options} version='4' pagination>
                <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
                <TableHeaderColumn dataField='status' dataAlign='center' dataFormat={this.statusFormatter.bind(this)} width='50px' dataSort={true}>
                  <img data-tippy-content={tr("status")} src="/assets/icons/16/stats.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='status' dataAlign='center' dataFormat={this.toggleFormatter.bind(this)} width='70px'></TableHeaderColumn>
                <TableHeaderColumn dataField='title' tdStyle={{ cursor: 'pointer' }} dataFormat={this.titleFormatter.bind(this)}></TableHeaderColumn>
                <TableHeaderColumn dataField='type' dataAlign='center' dataSort={true} dataFormat={this.typeFormatter.bind(this)} width='70px'>
                  <img data-tippy-content={tr("formType")} src="/assets/icons/16/form_type.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='results' dataAlign='center' columnClassName='hideOnHover' dataFormat={this.resultsFormatter.bind(this)} dataSort={true} width='50px'>
                  <img data-tippy-content={tr("formResults")} src="/assets/icons/16/form_results.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='cdate' columnClassName='hideOnHover' dataSort={true} dataAlign='center' dataFormat={this.dateFormatter.bind(this)} width='150px'>
                  <img data-tippy-content={tr("created")} src="/assets/icons/16/calendar.svg" />
                </TableHeaderColumn>

                <TableHeaderColumn dataFormat={this.buttonsFormatter.bind(this)} width='150px'></TableHeaderColumn>
              </BootstrapTable>

            }
            {/* <div className="tableFooterDiv"><Button>view more</Button></div> */}
          </div>

        </div>
      </Fragment>

    );
  }
}

export default FormsOverviewTable;
