import React, { useEffect, useState } from 'react'
import { SourceTypeSettingsProps } from '../SourceTypeSettings'
import { Alert, Button, Col, Label } from 'reactstrap';
import { TippyReact } from '../../../../../components';
import { Source } from '../../../../../../domain';
import { API } from '../../../../../apis';
import _ from 'lodash';
import { EdErrorHandler, OrtecLoader } from '../../../../../widgets';
import { SelectOption, string2SelectOption } from '../../../../../components/ReactSelect/ReactSelect';

import Creatable from 'react-select/creatable';
import styles from '../SourceDetails.module.scss';
import SourceSelector from '../SourceSelector';
import { useQuery } from '@tanstack/react-query';

export const Tags = ({ magazine, profile, source, onChangeSource }: SourceTypeSettingsProps) => {

  const [selectedSourceForTags, setSelectedSourceForTags] = useState<Source>();

  const [openSourceSelector, setOpenSourceSelector] = useState(false);
  const [tagsInputValue, setTagsInputValue] = useState<string>('');

  const selectedSourceQuery = useQuery({
    queryKey: ['selectedSource', source.baseurl],
    queryFn: async () => {
      if (!source.baseurl) {
        return undefined;
      }
      try {
        const { data } = await API.sources.getSource(magazine, _.toNumber(source.baseurl));
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting selected source for tags`);
      }
    },
  });

  useEffect(() => {
    setSelectedSourceForTags(selectedSourceQuery.data);
  }, [selectedSourceQuery.data])

  const urlToTags = (): SelectOption[] => {
    if (!source?.url) {
      return [];
    }
    const tags = source.url.split(',');
    return _.map(tags, (t) => string2SelectOption(t));
  }

  const onKeyDown = (event: any) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        addTag();
        event.preventDefault();
    }
  };

  const addTag = () => {
    const newUrl = source?.url ? `${source.url},${tagsInputValue}` : tagsInputValue;
    onChangeSource({ ...source, url: newUrl });
    setTagsInputValue('');
  }

  const onChange = (tags: SelectOption[]) => {
    const newUrl = _.isEmpty(tags) ? '' : _.uniq(_.map(tags, 'value')).join(',');
    onChangeSource({ ...source, url: newUrl });
  }


  const onSourceSelect = (s: Source) => {
    setSelectedSourceForTags(s);
    onChangeSource({ ...source, baseurl: _.toString(s.id) })
  }

  const openSourceDetailPage = (sourceId: number) => {
    let page = `/${magazine}/advanced?source=${sourceId}&tab=sources`;
    window.open(page, '_blank');
  }

  return (
    <>
      <Col md={6}>
        <Label>Tags:</Label>
        <Creatable
          isMulti
          components={{ DropdownIndicator: null }}
          menuIsOpen={false}
          placeholder={'Add tags...'}
          value={urlToTags()}
          inputValue={tagsInputValue}
          onInputChange={(v: string) => setTagsInputValue(v)}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
      </Col>
      <Col md={2} className={styles.verticalBottomCentered}>
        <Button style={{ width: '100%' }} className={'inputButton'} onClick={() => setOpenSourceSelector(true)}>Select source</Button>
      </Col>
      <Col md={4} className={''}>
        <Label>Selected source:</Label>
        {source.baseurl ?
          <Alert color={'info'}>
            {(selectedSourceQuery.isFetching) ? <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}><OrtecLoader size={'icon'} /></div> :
              (selectedSourceForTags ? <TippyReact content={`Click to open to open source page in a new tab `}><div onClick={() => openSourceDetailPage(selectedSourceForTags.id)} className={styles.linkToDetailPage}>{`[${selectedSourceForTags.id}] ${selectedSourceForTags.label || selectedSourceForTags.name}`}</div></TippyReact> : source.baseurl)
            }
          </Alert> :
          <Alert color={'warning'}>No selected source</Alert>
        }
      </Col>
      {openSourceSelector &&
        <SourceSelector
          magazine={_.toString(magazine)}
          profile={profile}
          title={''}
          closeHandler={() => { setOpenSourceSelector(false) }}
          selectionHandler={onSourceSelect}
          selected={selectedSourceForTags}
        />
      }
    </>
  )
}
