import React from 'react';
import _ from 'lodash';
import { Label, Input } from 'reactstrap';

import styles from './MediaManagerBucketSelectPane.module.scss';
import { Bucket, BucketPart } from './domain';
import { useTranslations } from '../../translations';

interface Props {
  buckets: Bucket[]
  activeBucket: string
  onBucketChange: (b: string) => void
  activeBucketPart: BucketPart
  disablePart: boolean
  onPartChange: (p: 'public' | 'protected') => void
}

export default function MediaManagerBucketSelectPane(props: Props) {
  const { activeBucket, onBucketChange, buckets, activeBucketPart, disablePart, onPartChange } = props

  const [defaultMagazineBuckets, magazineFeedsBuckets] = React.useMemo(
    () => [
      buckets.filter(b => b.group == 'default'),
      buckets.filter(b => b.group == 'feed')
    ],
    [buckets]
  )

  const tr = useTranslations('MediaManagerSelector');

  return <div className={styles.toolbar}>
    <div className={styles.bucketSelectors}>
      <div className={styles.flex2}>
        <Label className={styles.label}>Bucket:</Label>
        <Input type='select' value={activeBucket} onChange={(e) => { onBucketChange(e.target.value) }}>
          <optgroup label={tr(`defaultMagazineBuckets`)}>
            {defaultMagazineBuckets.map(b =>
              <option key={b.key} value={b.key}>{b.label}</option>
            )}
          </optgroup>
          <optgroup label={tr(`magazineFeedsBuckets`)}>
            {magazineFeedsBuckets.map(b =>
              <option key={b.key} value={b.key}>{b.label}</option>
            )}
          </optgroup>
        </Input>
      </div>
      <div className={styles.flex1}>
        <Label className={styles.label}>Access:</Label>
        <Input type='select' disabled={disablePart} value={activeBucketPart} onChange={(e) => { onPartChange(e.target.value as 'public' | 'protected') }}>
          <option value={'public'}>Public</option>
          <option value={'protected'}>Protected</option>
        </Input>
      </div>
    </div>

  </div>
}