import _ from 'lodash';
import React from 'react'
import styles from '../AudienceBuilder.module.scss';

interface Props {
  query: Object
}

export const UnsupportedQueryBox = ({ query }: Props) => {
  let q = _.toString(query);
  try {
    q = JSON.stringify(query, null, 2);
  } catch (error) {

  }
  return (
    <div className={styles.elementBoxWrapper}>
      <div className={styles.elementBoxConnector}></div>
      <div className={styles.elementBox}>
        {q}
      </div>
    </div>
  )
}
