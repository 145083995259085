import React, { useState } from 'react';
import { NcPlaylistSlideshow } from '../../NarrowcastingDomain';
import styles from './NcPlaylistSlideshowBox.module.scss';
import { Collapse } from 'reactstrap';
import _ from 'lodash';
import classNames from 'classnames';
import { NarrowcastingHelper } from '../../NarrowcastingHelper';

interface Props {
  slideshow: NcPlaylistSlideshow
}

export const NcPlaylistSlideshowBox = ({ slideshow }: Props) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }


  return (
    <div key={slideshow.id} className={classNames(styles.NcPlaylistSlideshowBox, { [styles.active]: isOpen })}>
      <div className={styles.boxHeader} onClick={toggle}>
        <div className={styles.slideTitle}>{slideshow.label}</div>
        <div className={styles.totalSlides}>{slideshow.slides.length} slide{slideshow.slides.length !== 1 ? 's' : ''}</div>
        <div> <i className={`fa fa-chevron-${isOpen ? 'up' : 'down'}`}></i></div>
      </div>
      <Collapse isOpen={isOpen}>
        <table className={styles.slidesTable}>
          <thead>
            <th>name</th>
            <th style={{ width: '150px' }}>type</th>
            <th style={{ width: '100px' }}>duration</th>
          </thead>
          <tbody>
            {_.isEmpty(slideshow.slides) ?
              <tr><td colSpan={3} className={styles.noSlidesRow}>No slides yet</td></tr>
              :
              _.map(slideshow.slides, (s) => {
                return (
                  <tr key={s.id}>
                    <td><div className={styles.noWrapDiv}>{s.config?.title}</div></td>
                    <td>{NarrowcastingHelper.slideTypeToTitle(s.type)}</td>
                    <td>{s.config?.duration ? `${s.config.duration}s` : ''}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </Collapse>
    </div>
  )
}
