import React from 'react';
import { CmsStage } from '../CmsDomain';
import classNames from 'classnames';
import styles from './CmsItemsTabs.module.scss';

interface Props {
  activeTab: CmsStage,
  setActiveTab: (newActiveTab: CmsStage) => void
}

export const CmsItemsTabs = ({ activeTab, setActiveTab }: Props) => {

  const onTabClick = (tab: CmsStage) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  }

  return (
    <div className={styles.CmsItemsTabs}>
      <div className={classNames(styles.CmsItemsTab, { [styles.active]: activeTab === 'published' })} onClick={() => onTabClick('published')}><div className={styles.CmsItemsTabInner}><span>Published</span></div></div>
      <div className={classNames(styles.CmsItemsTab, { [styles.active]: activeTab === 'drafts' })} onClick={() => { onTabClick('drafts') }}><div className={styles.CmsItemsTabInner}><span>Drafts</span></div></div>
    </div>
  )
}
