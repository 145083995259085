import _ from 'lodash';

// array


function switchItemsOnIndices<T>(array: T[], index1: number, index2: number): T[] {
  return array.map((v, i) => {
    switch (i) {
      case index1: return array[index2]
      case index2: return array[index1]
      default: return v;
    }
  })
}

function removeBy<T>(array: T[], func: (v: T) => boolean): T[] {
  return array.filter(v => !func(v))
}

function removeItemFromIndex<T>(array: T[], index: number): T[] {
  return array.filter((v, index2) => index !== index2)
}

function updateItemOnIndex<T>(array: T[], index: number, item: T): T[] {
  return array.map((v, index2) => index === index2 ? item : v)
}

function addItem<T>(array: T[], item: T): T[] {
  return [...array, item]
}

function insertItem<T>(array: T[], index: number, item: T): T[] {
  return [
    ...array.slice(0, index),
    // inserted item
    item,
    // part of the array after the specified index
    ...array.slice(index)
  ]
}

function moveItem<T>(array: T[], from: number, to: number): T[] {
  const item = array[from]
  return insertItem(removeItemFromIndex(array, from), to, item)
}



export const ImmutArray = {
  push: addItem,
  insert: insertItem,
  remove: removeItemFromIndex,
  removeBy: removeBy,
  update: updateItemOnIndex,
  switch: switchItemsOnIndices,
  move: moveItem
}


// dictionary

function addItemInDictionary<T>(obj: _.Dictionary<T> | undefined, key: string, item: T): _.Dictionary<T> {
  return {
    ...obj,
    [key]: item
  }
}

function removeItemFromDictionary<T>(obj: _.Dictionary<T> | undefined, key: string): _.Dictionary<T> {
  return _.omit(obj, key)
}

export const ImmutDictionary = {
  put: addItemInDictionary,
  remove: removeItemFromDictionary
}

/// objects

function mergeDataProperties<P>(obj1: P, obj2: Partial<P>): P {
  return { ...obj1, ...obj2 }
}


export const ImmutObject = {
  merge: mergeDataProperties
}
