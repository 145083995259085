import React, { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { CmsItemFormData } from '../CmsItemDetails';
import { Badge, FormGroup } from 'reactstrap';
import { TranslateFunction } from '../../../../../../../domain';
import { Translations } from '../../../../../../translations';
import styles from './CmsTags.module.scss';
import _ from 'lodash';
import Creatable from 'react-select/creatable';

interface TagOption {
  label: string,
  value: string,
}

interface Props {
  magazine: number,
  control: Control<CmsItemFormData>
  existingTags: any[]
}

export const CmsTags = ({ magazine, control, existingTags }: Props) => {

  const [addingTag, setAddingTag] = useState('');

  const { field, fieldState } = useController({ name: 'tags', control });

  const tagOptions = valueToTagOptions(field.value);

  const tr: TranslateFunction = Translations.Translate(Translations, 'Cms');

  const handleKeyDown = (e: any) => {
    if (!addingTag) {
      return;
    }
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        if (_.find(tagOptions, (t) => t.value == addingTag)) {
          e.preventDefault();
          return;
        }
        const newTagOptions = !_.isEmpty(tagOptions) ? [...tagOptions, createOption(addingTag)] : [createOption(addingTag)];
        setAddingTag('');
        field.onChange(tagOptionsToValue(newTagOptions));
        e.preventDefault();
    }
  }

  return (
    <FormGroup className={styles.CmsTags}>
      {/* {!noSectionLabel && <Label>{tr('tags')}</Label>} */}
      <div className={styles.existingTagsContainer}>
        {
          _.map(_.orderBy(existingTags, ['times'], ['desc']).slice(0, 10), (t) => {
            const newTagOptions = !_.isEmpty(tagOptions) ? _.uniqBy([...tagOptions, createOption(t.tag)], 'value') : [createOption(t.tag)];
            return <Badge key={t.tag} className={''} onClick={() => {
              field.onChange(tagOptionsToValue(newTagOptions));
            }}>{t.tag}</Badge>
          })
        }
      </div>
      <div className={''}>
        <Creatable
          components={{ DropdownIndicator: null }}
          inputValue={addingTag}
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={(newTagOptions: TagOption[]) => { field.onChange(tagOptionsToValue(newTagOptions)) }}
          onInputChange={setAddingTag}
          onKeyDown={handleKeyDown}
          placeholder="Add new tag..."
          value={tagOptions}
          styles={{
            multiValue: (provided, state) => {
              return {
                ...provided,
                backgroundColor: '#002e67',
                color: 'white',
                borderRadius: '4px'
              }
            },
            multiValueLabel: (provided, state) => {
              return {
                ...provided,
                color: 'white'
              }
            }
          }}
        />
      </div>
    </FormGroup>
  )
}

const valueToTagOptions = (tagsValue: string[]): TagOption[] => {
  return _.map(tagsValue, (t) => {
    return {
      label: t,
      value: t
    }
  })
}

const tagOptionsToValue = (tagOptions: TagOption[]): string[] => {
  return _.map(tagOptions, (t) => {
    return t.value
  })
}

const createOption = (label: string): TagOption => {
  return {
    label,
    value: label,
  }
};