import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { Badge, Input } from 'reactstrap';
import { Profile } from '../../../domain';
import { DailyDatePicker, MonthlyDatePicker, TippyReact, WeeklyDatePicker, YearlyDatePicker } from '../../components';
import { Translations } from '../../translations';
import { EdErrorHandler, LoadingButton, SwalError } from '../../widgets';
import styles from './GenerateReports.module.scss';
import { ReportTableArchive } from './ReportTableArchive';
import * as Api from '../../api';
import { CUSTOM_REPORT_MAX_RANGE_DAYS, Report } from './domain';
const FileDownload = require('js-file-download');

interface ShortcutProps {
  id: string,
  text: string,
}
interface Props {
  magazine: number,
  profile: Profile
}


type PeriodMode = 'of' | 'between';
type PeriodUnit = 'day' | 'week' | 'month' | 'year';

type UnitDatePickerType = typeof DailyDatePicker | typeof MonthlyDatePicker | typeof WeeklyDatePicker | typeof YearlyDatePicker;

interface GenerateConfig {
  periodMode: PeriodMode,
  periodUnit: PeriodUnit,
  startPeriod: string,
  endPeriod: string,
}

export const GenerateReports = ({ magazine, profile }: Props) => {

  const [name, setName] = useState<string>();
  const [audienceId, setAudienceId] = useState(profile.globalAudience ? 1 : profile.audiences ? profile.audiences[0].id : undefined);
  const [periodMode, setPeriodMode] = useState<PeriodMode>('of');
  const [periodUnit, setPeriodUnit] = useState<PeriodUnit>('month');
  const [startPeriod, setStartPeriod] = useState<string>('');
  const [endPeriod, setEndPeriod] = useState<string>('');
  const [shortcut, setShortcut] = useState<string>('custom');
  const [lastUpdated, setLastUpdated] = useState<number>(moment().valueOf());

  const [generating, setGenerating] = useState<boolean>(false);

  const isActiveShortcut = (shortcut !== 'custom');

  const tr = Translations.Translate(Translations, 'FilterBar');

  const onPeriodModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value as PeriodMode;
    setPeriodMode(v);
    const unit: PeriodUnit = v === 'of' ? 'month' : 'day';
    setPeriodUnit(unit);
    setStartPeriod('');
    setEndPeriod('');
  }

  const onPeriodUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value as PeriodUnit;
    setPeriodUnit(v);
  }

  const clearConfigurator = () => {
    setName(undefined);
    setStartPeriod('');
    setEndPeriod('');
  }

  const renderAudienceFilter = () => {
    const { globalAudience, audiences } = profile;

    return (
      <Input type={'select'} value={audienceId} onChange={(e) => setAudienceId(_.toNumber(e.target.value))}>
        {globalAudience && <option value={1}>{tr('all_employees')}</option>}
        {_.map(audiences, (a) => {
          if (_.toNumber(a.id) != 1 && !a.excludeFromAnalytics) {
            return <option key={a.id} value={a.id}>{a.label}</option>
          }
        })}
      </Input>
    )
  }

  const renderPeriodModeFilter = () => {
    const { globalAudience, audiences } = profile;

    return (
      <Input type={'select'} value={periodMode} onChange={onPeriodModeChange} disabled={isActiveShortcut}>
        <option value={'of'}>of</option>
        <option value={'between'}>between</option>
      </Input>
    )
  }
  const renderPeriodUnitFilter = (readOnly?: boolean) => {
    return (
      <Input type={'select'} disabled={isActiveShortcut || readOnly} value={periodUnit} onChange={onPeriodUnitChange}>
        <option value={'day'} disabled>day</option>
        <option value={'week'} disabled>week</option>
        <option value={'month'}>month</option>
        <option value={'year'} disabled>year</option>
      </Input>
    )
  }
  const UnitDatePicker = createUnitDatePicker(periodUnit);

  const renderStartPeriodUnitSelector = () => {
    return (
      <UnitDatePicker
        period={startPeriod}
        changePeriod={setStartPeriod}
        maxPeriod={endPeriod}
        readOnly={isActiveShortcut}
      />
    )
  }
  const renderEndPeriodUnitSelector = () => {
    return (
      <UnitDatePicker
        period={endPeriod}
        changePeriod={setEndPeriod}
        minPeriod={startPeriod}
        maxPeriod={calcMaxPeriod(startPeriod)}
        readOnly={isActiveShortcut}
      />
    )
  }

  const changeShortcut = (id: string) => {

    let generateConfig: GenerateConfig;

    switch (id) {
      case 'last15days':
        generateConfig = applyLastXDays(15);
        break;
      case 'last6weeks':
        generateConfig = applyLastXWeeks(6);
        break;
      case 'last5months':
        generateConfig = applyLastXMonths(5);
        break;
      case 'thisQuarter':
        generateConfig = applyLastXQuarters(1);
        break;
      case 'last2Quarters':
        generateConfig = applyLastXQuarters(2);
        break;
      case 'last2years':
        generateConfig = applyLastXYears(2);
        break;
      default:
        generateConfig = {
          periodMode: 'of',
          periodUnit: 'month',
          startPeriod: '',
          endPeriod: ''
        }

    }
    setPeriodMode(generateConfig.periodMode);
    setPeriodUnit(generateConfig.periodUnit);
    setStartPeriod(generateConfig.startPeriod);
    setEndPeriod(generateConfig.endPeriod);
    setShortcut(id);
  }

  const Shortcut = ({ id, text }: ShortcutProps) => {
    return (
      <Badge key={id} className={classNames({ 'active': id == shortcut })} onClick={() => changeShortcut(id)}>{text}</Badge>
    )
  }

  const updateArchiveTable = () => {
    setLastUpdated(moment().valueOf());
  }

  const showInvalidReportError = (message: string) => {
    SwalError.fire({
      title: 'Error!',
      text: message,
    });
  }

  const validateReport = (): boolean => {
    if (!startPeriod) {
      showInvalidReportError(`You have to select a specific ${periodUnit} in this mode!`)
      return false;
    }
    if (periodMode === 'between' && (!startPeriod || !endPeriod)) {
      showInvalidReportError(`You have to select both start and end ${periodUnit}s in this mode!`);
      return false;
    }
    if (periodMode === 'between' && calcDifferenceBetweenDates(startPeriod, endPeriod) > CUSTOM_REPORT_MAX_RANGE_DAYS) {
      showInvalidReportError(`Selected range cannot be more than ${CUSTOM_REPORT_MAX_RANGE_DAYS} days!`);
      return false;
    }
    return true;
  }

  const onGenerateReport = async () => {

    if (!validateReport()) {
      return;
    }

    const report: Report = {
      name: name || undefined,
      audience: audienceId || 0,
      period: periodMode === 'of' ? startPeriod : {
        start: startPeriod,
        end: endPeriod
      },
    }

    try {
      setGenerating(true);
      const resp = await Api.generateReport(magazine, report);
      var filename = report.name ? report.name + '.pdf' : null;
      if (!filename) {
        const header = resp.headers['content-disposition'];
        filename = _.trim(header.split('filename=')[1], '"');
      }

      FileDownload(resp.data, filename);
      clearConfigurator();
      updateArchiveTable();

    } catch (error) {
      EdErrorHandler(error, `generating a report`)
    } finally {
      setGenerating(false);
    }

  }

  return (
    <div className={styles.GenerateReports}>
      {/* <div className={styles.shortcuts}>
        <div className={styles.label}>Shortcuts:</div>
        <Shortcut id={'custom'} text={'custom'}/>
        <Shortcut id={'last15days'} text={'last 15 days'}/>
        <Shortcut id={'last6weeks'} text={'last 6 weeks'}/>
        <Shortcut id={'last5months'} text={'last 5 months'}/>
        <Shortcut id={'thisQuarter'} text={'this quarter'}/>
        <Shortcut id={'last2Quarters'} text={'last 2 quarters'}/>
        <Shortcut id={'last2years'} text={'last 2 years'}/>
      </div> */}
      <div className={styles.configuratorContainer}>
        <div className={styles.configurator}>
          <div className={styles.configuratorGroup}>
            <div>I want a report named</div>
            <div> <Input placeholder={calcReportNamePlaceholder(startPeriod, periodUnit)} type="text" value={name ?? ''} onChange={(e) => { setName(e.target.value) }} /></div>
          </div>
          <div className={styles.configuratorGroup}>
            <div>for audience</div>
            <div>{renderAudienceFilter()}</div>
          </div>
          <div className={styles.configuratorGroup}>
            <div className={styles.overPeriodContainer}>
              <span>over the period </span>
              <TippyReact content={'The numbers in custom reports are calculated based on daily metrics. This might cause slight differences in the average values compared to monthly reports, which use pre-calculated monthly metrics.'}>
                <div className={classNames(styles.infoIcon, 'material-icons')}>info</div>
              </TippyReact>
            </div>
            <div className={styles.configuratorRow}>
              <div>{renderPeriodModeFilter()}</div>
              <div>{renderPeriodUnitFilter(true)}</div>
            </div>
            <div style={{ flex: 1 }}>{renderStartPeriodUnitSelector()}</div>
            {periodMode == 'between' &&
              <Fragment>
                <div className={styles.configuratorRow}>
                  <div style={{ textAlign: 'center' }}> and </div>
                  <div>{renderPeriodUnitFilter(true)}</div>
                </div>
                <div style={{ flex: 1 }}>{renderEndPeriodUnitSelector()}</div>
              </Fragment>
            }
          </div>
          <div className={styles.actionButtons}>
            <LoadingButton
              block
              text={'Generate report'}
              loading={generating}
              onClick={onGenerateReport}
            />
          </div>
        </div>
      </div>

      <div className={styles.archiveContainer}>
        <ReportTableArchive key={`reportArchive-${lastUpdated}`} magazine={_.toString(magazine)} profile={profile} />
      </div>

    </div>
  )
}


//
// ─── HELPER FUNCTIONS ───────────────────────────────────────────────────────────
//

const applyLastXDays = (days: number): GenerateConfig => {
  const endDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
  const startDate = moment(endDate, 'YYYY-MM-DD').subtract(days, 'day').format('YYYY-MM-DD');

  return {
    periodUnit: 'day',
    periodMode: 'between',
    startPeriod: startDate,
    endPeriod: endDate,
  }
}

const applyLastXWeeks = (weeks: number): GenerateConfig => {
  const endDate = moment().subtract(1, 'day').format('GGGG-[W]W');
  const startDate = moment(endDate, 'GGGG-[W]W').subtract((weeks - 1), 'week').format('GGGG-[W]W');

  return {
    periodUnit: 'week',
    periodMode: 'between',
    startPeriod: startDate,
    endPeriod: endDate,
  }
}

const applyLastXMonths = (months: number): GenerateConfig => {
  const endDate = moment().subtract(1, 'day').format('YYYY-MM');
  const startDate = moment(endDate, 'YYYY-MM').subtract((months - 1), 'month').format('YYYY-MM');

  return {
    periodUnit: 'month',
    periodMode: 'between',
    startPeriod: startDate,
    endPeriod: endDate,
  }
}

const applyLastXYears = (years: number): GenerateConfig => {
  const endDate = moment().subtract(1, 'day').format('YYYY');
  const startDate = moment(endDate, 'YYYY-MM').subtract((years - 1), 'month').format('YYYY');

  return {
    periodUnit: 'year',
    periodMode: 'between',
    startPeriod: startDate,
    endPeriod: endDate,
  }
}

const applyLastXQuarters = (quarters: number): GenerateConfig => {
  const endDate = moment().subtract(1, 'day').format('YYYY-MM');
  const startDate = moment(endDate, 'Q').subtract((quarters), 'Q').format('YYYY-MM');

  return {
    periodUnit: 'month',
    periodMode: 'between',
    startPeriod: startDate,
    endPeriod: endDate,
  }
}

const calcReportNamePlaceholder = (startPeriod: string, periodUnit: PeriodUnit): string => {
  if (startPeriod) {
    if (periodUnit == 'month') {
      return `${moment(startPeriod, `YYYY-MM`).format('MMMM YYYY')}  Monthly Report`;
    }
  }

  return `Insert report name..`;
}

const createUnitDatePicker = (periodUnit: PeriodUnit): UnitDatePickerType => {
  switch (periodUnit) {
    case 'week':
      return WeeklyDatePicker;
    case 'month':
      return MonthlyDatePicker;
    case 'year':
      return YearlyDatePicker;
    default:
      return DailyDatePicker;
  }
}

const calcMaxPeriod = (startPeriod: string): string | undefined => {
  if (!startPeriod || !CUSTOM_REPORT_MAX_RANGE_DAYS) {
    return undefined;
  }
  const now = moment();
  const afterMaxRange = moment(startPeriod).add(CUSTOM_REPORT_MAX_RANGE_DAYS, 'days');
  if (afterMaxRange > now) {
    return undefined;
  }

  return moment(afterMaxRange).format('YYYY-MM-DD');
}

const calcDifferenceBetweenDates = (startPeriod: string, endPeriod: string): number => {
  return moment(endPeriod).diff(moment(startPeriod), 'days');
}