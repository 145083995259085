import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import Toggle from 'react-toggle';
import { Input } from 'reactstrap';
import { Audience, BuilderQuery } from './domain';
import { API } from '../../../../apis';
import { Translations } from '../../../../translations';
import { EdErrorHandler, LoadingButton, OrtecLoader, SwalError } from '../../../../widgets';
import styles from './AudienceDetails.module.scss';
import { EditorsSelector } from '../../../../components';
import { AudienceBuilder } from './AudienceBuilder/AudienceBuilder';
import { Profile } from '../../../../../domain';

const newAudience: Audience = {
  id: -1,
  magazine: -1,
  label: '',
  query: { "$and": [{ "uid": { "$eq": '' } }] },
  rule: '',
  cdate: '',
  mdate: '',
  excludeFromAnalytics: true,
  status: true,
  type: 'standard'
}

interface Props {
  magazine: number,
  profile: Profile,
  updateProfile: () => void
  audienceId: number,
  onClose: () => void
}

export const AudienceDetails = ({ magazine, profile, updateProfile, audienceId, onClose }: Props) => {

  const [detailsLoading, setDetailsLoading] = useState(false);
  const [audience, setAudience] = useState<Audience>({ ...newAudience, magazine });

  const [notFound, setNotFound] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [showValidations, setshowValidations] = useState(false);


  const loading = detailsLoading;

  const isNobodyAudience = audience.type === 'nobody';

  useEffect(() => {
    if (audienceId !== -1) {
      getAudienceDetails();
    }
  }, [])

  const getAudienceDetails = async () => {
    try {
      setDetailsLoading(true);
      const { data } = await API.audiences.getAudienceDetails(magazine, audienceId);
      setAudience(data);
    } catch (error) {
      // EdErrorHandler(error, `getting audience details`);
      setNotFound(true);
    } finally {
      setDetailsLoading(false);
    }
  }

  const onEditorChange = (uid: string, checked: boolean) => {
    if (!audience) {
      return;
    }
    if (checked) {
      setAudience({ ...audience, editors: [...(audience.editors || []), uid] });
    } else {
      setAudience({ ...audience, editors: _.filter(audience.editors, (e) => e !== uid) });
    }

  }

  const onMultipleEditorsChange = (uids: string[], checked: boolean) => {
    if (!audience) {
      return;
    }
    if (checked) {
      setAudience({ ...audience, editors: uids });
    } else {
      setAudience({ ...audience, editors: [] });
    }
  }

  const onQueryChange = (query: BuilderQuery) => {
    if (!audience) {
      return;
    }
    setAudience({ ...audience, query });
  }

  const onSave = async () => {
    setshowValidations(true);
    if (!audience.label) {
      SwalError.fire({
        title: 'Error!',
        text: `You have to give a label to this audience!`,
      });
      return;
    }

    try {
      setSaveLoading(true);
      await API.audiences.saveAudience(magazine, audience);
      updateProfile();
      onClose();
    } catch (error) {
      EdErrorHandler(error, `saving audience`);
    } finally {
      setSaveLoading(false);
    }

  }

  const tr = Translations.Translate(Translations, 'DetailModals');

  const notFoundMessage = <div className={styles.notFoundMessage}><div className="alert alert-danger">{`Audience not found`}</div></div>;

  return (
    <div className={styles.AudienceDetails}>
      <div className={styles.header}>
        <img src="/assets/icons/bt_close_bigger.svg" onClick={onClose} style={{ cursor: 'pointer' }} />
        {!audience || loading ? null :
          <Fragment>
            <Input disabled={isNobodyAudience} invalid={showValidations && !audience.label} className={styles.labelInput} value={audience.label || ''} placeholder={`Enter your audience label here...`} onChange={(e) => { setAudience({ ...audience, label: e.target.value }) }}></Input>
            <LoadingButton loading={saveLoading} onClick={onSave} text={tr('save')} />
          </Fragment>
        }
      </div>
      <div className={styles.content}>
        {loading ? <OrtecLoader /> :
          (notFound || !audience?.id) ? notFoundMessage :
            <Fragment>
              <div className={classNames(styles.leftPart)} style={{ flex: 3, overflowY: 'auto' }}>
                <AudienceBuilder {...{ magazine, profile, audience, onQueryChange }} />
              </div>
              <div className={classNames(styles.rightPart)} style={{ flex: 2, minWidth: '350px', overflowY: 'auto' }}>
                <div className={styles.settingsContainer}>
                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                      <div className={styles.sectionTitle}>settings</div>
                    </div>
                    <div className={styles.sectionContent}>
                      <div className={styles.toggleRow}>
                        <div className={styles.label}>Enable analytics</div>
                        <Toggle disabled={isNobodyAudience} checked={audience.excludeFromAnalytics ? false : true} onChange={(e) => setAudience({ ...audience, excludeFromAnalytics: !e.target.checked })} />
                      </div>
                    </div>
                    <div className={styles.sectionContent}>
                      If you enable analytics, this audience will register events and will appear on the Analytics section.
                    </div>
                    <div className={styles.sectionContent}>
                      <div className={styles.label}>Estimated size </div>
                      <Input disabled={isNobodyAudience} style={{ marginTop: '10px' }} type={'number'} value={audience.estimatedSize || ''} onChange={(e) => setAudience({ ...audience, estimatedSize: e.target.value ? _.toNumber(e.target.value) : undefined })} min={0} />
                    </div>
                    <div className={styles.sectionContent}>
                      The estimated maximum number of unique profiles that belong to this audience. This will be the 100% when calculating reach metrics in analytics.
                    </div>
                  </div>
                  <hr />
                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                      <div className={styles.sectionTitle}>access rights</div>
                      <div>{`${audience.editors?.length || 0} editor${audience.editors?.length != 1 ? 's' : ''}`}</div>
                    </div>
                    <div className={styles.sectionContent}>
                      <EditorsSelector magazine={magazine} selected={audience.editors || []} onEditorChange={onEditorChange} onMultipleEditorsChange={onMultipleEditorsChange} />
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
        }
      </div>
    </div>
  )
}
