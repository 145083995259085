import { NlTemplateConfiguration } from "../domain";

const parse = (configuration: NlTemplateConfiguration): string => {
    return `
        <!DOCTYPE html>
        <!--[if lt IE 7]>      <html lang="en" class="no-js lt-ie9 lt-ie8 lt-ie7"> <![endif]-->
        <!--[if IE 7]>         <html lang="en" class="no-js lt-ie9 lt-ie8"> <![endif]-->
        <!--[if IE 8]>         <html lang="en" class="no-js lt-ie9"> <![endif]-->
        <!--[if gt IE 8]><!--> <html lang="en" class="no-js"> <!--<![endif]-->
        <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <title>{{appTitle}}</title>
        <meta name="description" content="">
        <meta name="viewport" content="width=device-width, initial-scale=1">

        <style>
            @import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
            .container {
                text-align: center;
            }
            .article {
                text-align: left;
                display: inline-block;
                vertical-align: middle;
                max-width: 500px;
                padding: 10px 20px;
                font-family: Roboto, sans-serif;
                color: #808d96;
            }
            h1 {
                line-height: 152px;
                font-size: 2em;
                padding: 0;
                margin: 0;
                color: #3c5e90;
                font-weight: 100;
            }
            p {
                float: left;
                margin-top: 20px;
                font-size: 18px;
                line-height: 28px;
            }
            a.button, a.button:visited {
                margin-top: 35px;
                display: block;
                float: left;
                text-align: center;
                text-decoration: none;
                color: #3c5e90;
                height: 40px;
                line-height: 40px;
                border: 2px solid #3c5e90;
                width: 100%;
                border-radius: 6px;
            }
            a.button:hover {
                background: #3c5e90;
                color: #fff;
            }

            .Iz_image {
                float: left;
                width: auto;
                margin-right: 0px;
                border-radius: 28px;
                overflow: hidden;
            }
            .Iz_image img {
                display: table-cell;
                width: auto;
            }
            .nl_heading {
                color: #3CA0E0;
                font-size:1.2em;
            }
            </style>
        </head>
        <body>
            <div class="container">
                <div class="article">
                    <div class="Iz_image">
                        <img src="{{appIcon}}" alt="App icon" />
                    </div>
                <div><h1 style="clear: both;">{{appTitle}}</h1></div>
                {% if os=='iOS' %}
                        {% if newsletterEditionUrl %}
                        <script type="text/javascript">window.location.assign('{{newsletterEditionUrl}}')</script>
                        {% endif %}
                        <a href="{{appUrl}}" class="button">Open article in {{appTitle}}</a>
                        <a href="{{storeUrls.iOS}}" class="button">Download {{appTitle}}</a>
                        <a href="{{newsletterEditionUrl}}" class="button">Open newsletter in the browser</a>
                    {% elif os=='AndroidOS' %}
                        {% if newsletterEditionUrl %}
                        <script type="text/javascript">window.location.assign('{{newsletterEditionUrl}}')</script>
                        {% endif %}
                        <a href="{{appUrl}}" class="button">Open article in {{appTitle}}</a>
                        <a href="{{storeUrls.AndroidOS}}" class="button">Download {{appTitle}}</a>
                        <a href="{{newsletterEditionUrl}}" class="button">Open newsletter in the browser</a>
                    {% else %}
                        <a href="{{storeUrls.AndroidOS}}" class="button">Download {{appTitle}}</a>
                    {% endif %}
                </div>
            </div>
        </body>
        </html>

    `;
}

export const landingPage = {
    parse
}