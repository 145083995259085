import _ from 'lodash';
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router'
import { Col, Row } from 'reactstrap';
import { Profile, StatsFilter } from '../../../../../domain';
import { FilterContextConsumer } from '../../../../FilterContext';
import { FilterBarWithRouter as FilterBar } from '../../../Overview/FilterBar';
import AvgArticlesPerUserChart from './AvgArticlesPerUserChart';


import './css/Platform.min.css';
import ScreenAndReadTimeChart from './ScreenAndReadTimeChart';

import TopNumbersChart from './TopNumbersChart';
import UserHabitsChart from './UserHabitsChart';
import ViewsAndReadsChart from './ViewsAndReadsChart';
import VisitsDetailedChart from './VisitsDetailedChart';
import VisitsPerHourOfDayChart from './VisitsPerHourOfDayChart';
import VisitsPerOsChart from './VisitsPerOsChart';

export interface Props {
    magazine: number,
    profile: Profile,
}

function Platform(props: RouteComponentProps & Props) {
    const { magazine, profile } = props;

    return (
        <FilterContextConsumer>
            {filterContext => {
                if (!filterContext) {
                    return null;
                }
                const statsFilter: StatsFilter = filterContext.statsFilter;
                return (
                    <div id="Platform">
                        <FilterBar magazine={_.toString(magazine)} periodFilterHandler={filterContext.periodFilterHandler} audienceFilterHandler={filterContext.audienceFilterHandler} statsFilter={statsFilter} audiences={profile.audiences ? profile.audiences : []} globalAudience={profile.globalAudience} />
                        <TopNumbersChart {...{ magazine, profile, statsFilter }} />
                        <VisitsDetailedChart key={`visitsDetailedChart_p_${statsFilter.period}_a_${statsFilter.audience}`} {...{ magazine, statsFilter }} />
                        <Row>
                            <Col md="8">
                                <VisitsPerHourOfDayChart {...{ magazine, statsFilter }} />
                            </Col>
                            <Col md="4">
                                <VisitsPerOsChart {...{ magazine, statsFilter }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <UserHabitsChart {...{ magazine, statsFilter }} />
                            </Col>
                            <Col md="6">
                                <ScreenAndReadTimeChart key={`screenAndReadTime_p_${statsFilter.period}_a_${statsFilter.audience}`} {...{ magazine, statsFilter }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <AvgArticlesPerUserChart key={`avgArticlesPerUser_p_${statsFilter.period}_a_${statsFilter.audience}`} {...{ magazine, statsFilter }} />
                            </Col>
                            <Col md="6">
                                <ViewsAndReadsChart key={`viewsAndReads_p_${statsFilter.period}_a_${statsFilter.audience}`} {...{ magazine, statsFilter }} />
                            </Col>
                        </Row>
                    </div>
                )
            }}

        </FilterContextConsumer>
    )

}

export const PlatformWithRouter = withRouter(Platform);
