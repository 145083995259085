import React from 'react';

import { Profile } from '../../../../../../domain';
import * as _ from 'lodash';

import { CmsFeedSelector } from '../CmsFeedSelector/CmsFeedSelector';
import { HistoryHelper } from '../../../../../utils';
import { CmsStage } from '../CmsDomain';
import { useHistory } from 'react-router-dom';
import styles from './CmsOverview.module.scss';
import { CmsFeedInfo } from '../CmsItemDetails/CmsItemDetails';
import { CmsItemsOverviewTable } from './CmsItemsOverviewTable';
export interface Props {
  magazine: number,
  profile: Profile,
  activeFeed: CmsFeedInfo,
  agendaMode?: boolean,
  setFeed: (feed: number) => void
  updateProfile: () => void
  onClickItem: (itemId: string, subTab?: CmsStage) => void
}

export const CmsOverview = (props: Props) => {

  const { magazine, profile, activeFeed, agendaMode, setFeed, updateProfile, onClickItem } = props;

  const history = useHistory();
  const activeTab: CmsStage = HistoryHelper.getQueryParam(history, 'subTab') as CmsStage;

  const setActiveTab = (subTab: CmsStage) => {
    HistoryHelper.setQueryParams(history, { subTab });
  }

  if (!activeTab) {
    setActiveTab('published');
  }

  return (
    <>
      <div className={styles.CmsOverview}>
        {profile.feeds &&
          <CmsFeedSelector
            magazine={_.toString(magazine)}
            feeds={profile.feeds}
            activeFeed={activeFeed.id}
            setFeed={setFeed}
            profile={profile}
            updateProfile={updateProfile}
          />}
        {activeFeed && activeTab &&
          <CmsItemsOverviewTable
            magazine={magazine}
            activeFeed={activeFeed}
            profile={profile}
            activeTab={activeTab}
            agendaMode={agendaMode}
            clickItemHandler={onClickItem}
          />
        }
      </div>
    </>
  );

}
