import { dashApi, endpointWithParams, wrapAxios } from "../api";
import { SearchParams } from "../pages/Advanced/subpages/People/domain";
import _ from "lodash";
import axios, { AxiosPromise } from "axios";

export const loadPeopleConfig = (magazine: number): Promise<any> => {
  return dashApi.get(magazine, `people/peopleConfig`);
}

export const getProfiles = (magazine: number, params: SearchParams): Promise<any> => {
  return dashApi.get(magazine, endpointWithParams(`people/profiles`, _.omitBy(params, _.isNil)))
}

export const saveProfile = (magazine: number, profile: any): Promise<any> => {
  return dashApi.post(magazine, `people/profiles`, { profile })
}

export const disableProfile = (magazine: number, uid: string): Promise<any> => {
  return dashApi.delete(magazine, endpointWithParams(`people/profiles`, { uid }))
}

export const resetPassword = (magazine: number, username: string): Promise<any> => {
  return dashApi.post(magazine, `people/resetPassword`, { username })
}

export const exportProfilesStream = (magazine: number, params: any, updateExportStateProgress: (fetched: number) => void): Promise<any> => {
  return dashApi.postStream(magazine, endpointWithParams(`people/profilesExport`, _.omitBy(params, _.isNil)), null, {
    responseType: 'stream', onDownloadProgress: (e: any) => {
      const fetched = e.target.response.split("\n").length - 2;
      updateExportStateProgress(fetched);
    }
  });
}

export const checkImportCSV = (magazine: number, data: any): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/checkImportCSV`, data, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString'
    }
  }));
}

/**
 * The string here is a comma separated list of stringified user-api results. 
 * Each user-api result has a value of {status: "ok"} if it is a success or {error:"xyz"} if it is a fail.
 * Translating the string into array and counting the status: "ok" elementes conveys the number of the successfully
 * imported/updated profiles.
 * 
 * @returns AxiosPromise<string> 
 */

export const importProfilesStream = (magazine: number, data: any, updateImportStateProgress: (imported: number) => void): AxiosPromise<string> => {
  return wrapAxios(axios.post(`/api/${magazine}/profilesImport`, data, {
    headers: {
      'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString'
    },
    responseType: 'stream',
    transformResponse: [
      (data) => {
        if (typeof data === 'object') { // ensure that the result won't be transformed to an object and will stay a string
          return JSON.stringify(data);
        }
        return data;
      }
    ],
    onDownloadProgress: (e: any) => {
      const results = e.target.response.split(',');
      const imported = results.length;
      updateImportStateProgress(imported);
    }
  }));
}