import _ from 'lodash';
import React, { useState } from 'react';
import { Button, Modal } from 'reactstrap';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { FeedSelector } from './FeedSelector';

import styles from './FeedSelectorModal.module.scss';
import { ConnectedFeed } from '../../../domain';

interface Props {
  magazine: number
  preselected: ConnectedFeed[]
  onClose: () => void
  onSelect: (feeds: ConnectedFeed[]) => void
  multiSelect?: boolean
}

export const FeedSelectorModal = ({ magazine, preselected, onClose, onSelect, multiSelect }: Props) => {

  const [selected, setSelected] = useState<ConnectedFeed[]>(preselected);

  const onChangeSelection = (feed: ConnectedFeed, isSelect: boolean) => {
    if (multiSelect) {
      if (isSelect) {
        setSelected([...selected, feed]);
      } else {
        setSelected(_.filter(selected, (s) => s.id !== feed.id));
      }
    } else {
      setSelected(isSelect ? [feed] : []);
    }

  }

  const onClickSelectButton = () => {
    onSelect(selected);
    onClose();
  }

  return (
    <Modal className={styles.FeedSelectorModal} isOpen={true} centered size={'lg'} keyboard={true} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Select feed
      </ModalHeader>
      <ModalBody>
        <FeedSelector
          magazine={magazine}
          selected={selected}
          onChangeSelection={onChangeSelection}
          multiSelect={multiSelect}
        />
      </ModalBody>
      <ModalFooter>
        <Button block color={'primary'} size={'lg'} onClick={() => onClickSelectButton()}>Select</Button>
      </ModalFooter>
    </Modal>
  )
}
