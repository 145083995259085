import React from 'react';
import { Profile } from '../../../../../domain';
import { useHistory } from 'react-router-dom';
import { DateHelper, HistoryHelper } from '../../../../utils';
import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../../apis';
import { EdErrorHandler, OrtecLoader, SwalDelete, SwalSuccess } from '../../../../widgets';

import styles from './NcSlideshows.module.scss';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { NcScheduleDatetime, NcSlideshow, } from '../../NarrowcastingDomain';
import { NarrowcastingHelper } from '../../NarrowcastingHelper';
import { TippyReact } from '../../../../components';
import { Button } from 'reactstrap';
import { NcSlideshowDetails } from './NcSlideshowDetails';

interface Props {
  magazine: number,
  profile: Profile
}

export const NcSlideshows = ({ magazine, profile }: Props) => {

  const history = useHistory();
  const slideshowParam = HistoryHelper.getQueryParam(history, 'slideshow');
  const editSlideshowId = slideshowParam ? _.toNumber(slideshowParam) : undefined;

  const ncDevicesQuery = useQuery({
    queryKey: ['ncDevices', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcDevices(magazine);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting NC devices`);
      }
    }
  });

  const ncGroupsQuery = useQuery({
    queryKey: ['ncGroups', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcGroups(magazine);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting NC groups`);
      }
    },
  });

  const ncSlideshowsQuery = useQuery({
    queryKey: ['ncSlideshows', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcSlideshows(magazine);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting NC slideshows`);
      }
    },
  });

  const queryClient = useQueryClient();

  const ncSlideshowDeleteMutation = useMutation({
    mutationKey: ['ncSlideshowDelete'],
    mutationFn: (s: NcSlideshow) => API.narrowcasting.deleteNcSlideshow(magazine, s.id),
    onError: (error, s) => {
      EdErrorHandler(error, `deleting slideshow [${s.id}] ${s.label}`);
    },
    onSuccess: (data, s) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `Slideshow <${s.label}> has been deleted successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['ncSlideshows'] })
    }
  })

  const onDelete = async (slideshow: NcSlideshow) => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      html: `This action will delete slideshow <code>[${slideshow.id}] ${slideshow.label}</code>.`
    });
    if (!confirm) {
      return;
    }
    ncSlideshowDeleteMutation.mutate(slideshow);
  }

  const isLoading = ncDevicesQuery.isFetching || ncGroupsQuery.isFetching || ncSlideshowsQuery.isFetching || ncSlideshowDeleteMutation.isLoading;

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'label',
      sort: true,
      text: 'slideshow name'
    },
    {
      dataField: 'device_ids',
      sort: false,
      text: 'target devices',
      formatter: (cell: string, row: NcSlideshow) => {
        try {
          const ids = cell || [];
          if (_.isEmpty(ids)) {
            return <></>;
          }
          const names = _.compact(_.map(ids, (id) => NarrowcastingHelper.findDeviceName(_.toNumber(id), ncDevicesQuery.data || [])));

          if (ids.length <= 2) {
            return <span>{names.join(', ')}</span>
          }
          return <TippyReact config={{ placement: 'left' }} content={names.join(', ')}><div><span>{ids.length} devices</span></div></TippyReact>
        } catch (error) {
          return <span>-</span>;
        }
      }
    },
    {
      dataField: 'device_ids',
      hidden: true,
      text: 'device ids',
      formatter: (cell: string, row: NcSlideshow) => {
        const ids = cell || [];
        const names = _.compact(_.map(ids, (id) => NarrowcastingHelper.findDeviceName(_.toNumber(id), ncDevicesQuery.data || [])));
        return names.join(', ');
      }
    },
    {
      dataField: 'group_ids',
      sort: false,
      text: 'target groups',
      formatter: (cell: string, row: NcSlideshow) => {
        try {
          const ids = cell || [];
          if (_.isEmpty(ids)) {
            return <></>;
          }
          const names = _.compact(_.map(ids, (id) => NarrowcastingHelper.findGroupName(_.toNumber(id), ncGroupsQuery.data || [])));

          if (ids.length <= 2) {
            return <span>{names.join(', ')}</span>
          }
          return <TippyReact config={{ placement: 'left' }} content={names.join(', ')}><div><span>{ids.length} groups</span></div></TippyReact>
        } catch (error) {
          return <span>-</span>;
        }
      }
    },
    {
      dataField: 'group_ids',
      hidden: true,
      text: 'group ids',
      formatter: (cell: string, row: NcSlideshow) => {
        const ids = cell || [];
        const names = _.compact(_.map(ids, (id) => NarrowcastingHelper.findGroupName(_.toNumber(id), ncGroupsQuery.data || [])));
        return names.join(', ')
      }
    },
    {
      dataField: 'totalSlides',
      sort: true,
      text: 'slides',
      headerStyle: { width: '100px' },
    },
    {
      dataField: 'config.schedule.start',
      sort: true,
      text: 'start',
      headerStyle: { width: '200px' },
      // align: 'center',
      // headerAlign: 'center',
      formatter: (d: NcScheduleDatetime, row: NcSlideshow) => {
        return ncScheduleDatetimeFormatter(d);
      }
    },
    {
      dataField: 'config.schedule.end',
      sort: true,
      text: 'end',
      headerStyle: { width: '200px' },
      // align: 'center',
      // headerAlign: 'center',
      formatter: (d: NcScheduleDatetime, row: NcSlideshow) => {
        return ncScheduleDatetimeFormatter(d);
      }
    },
    {
      dataField: 'dfActions',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatter: (cell, row: NcSlideshow) => {
        return (
          <div className={'actionHoveringDiv'}>
            <Button color={'secondary'} onClick={(e) => { onDelete(row); e.preventDefault(); e.stopPropagation(); return false; }}>Delete</Button>
          </div>
        )
      },
    }
  ];


  const onOpenSlideshow = (slideshowId: number) => {
    HistoryHelper.setQueryParams(history, { slideshow: slideshowId });
  }

  const onCloseSlideshow = () => {
    HistoryHelper.setQueryParams(history, { slideshow: undefined });
  }

  return (
    <div className={styles.NcSlideshows}>
      {isLoading && !editSlideshowId && <OrtecLoader />}
      <DataTable
        data={ncSlideshowsQuery.data || []}
        columns={columns}
        keyField={'id'}
        onRowClick={(row: NcSlideshow) => { onOpenSlideshow(row.id) }}
        onCreateNew={() => { onOpenSlideshow(-1) }}
        createNewButtonText={`add new`}
      />
      {editSlideshowId && <NcSlideshowDetails {...{ magazine, profile, ncGroupsQuery, ncDevicesQuery }} slideshowId={editSlideshowId} onClose={onCloseSlideshow} slideshows={ncSlideshowsQuery.data || []} />}
    </div>
  )
}


export const ncScheduleDatetimeFormatter = (d?: NcScheduleDatetime): JSX.Element => {
  if (!d) {
    return <></>;
  }
  const dateStr = DateHelper.dateTimeToLocaleString(`${d.date}T${d.time}`)
  if (!d.timezone) {
    return <div>{dateStr}</div>;
  }
  return (
    <>
      <div>{dateStr}</div>
      <div className={styles.timezoneContainer}>{`(GMT${d.timezone}:00)`}</div>
    </>
  )
}
