import React, { useMemo } from 'react';
import { SelectOption, SingleSelect, string2SelectOption } from '../ReactSelect/ReactSelect';
import _ from 'lodash';

interface Props {
  value: string
  onChange: (v: string) => void,
  step?: number,
  h12?: boolean,
  isClearable?: boolean,
  placeholder?: string,
}

export const HoursSelect = ({ value, onChange, step, h12, isClearable, placeholder }: Props) => {

  const options: SelectOption<string>[] = useMemo(() => {
    return constructOptions(0, h12 ? 12 : 24, step || 1)
  }, [step, h12]);

  return (
    <SingleSelect
      value={value === '' ? undefined : value}
      onChange={(o) => { o === undefined ? onChange('') : onChange(o) }}
      options={options}
      isClearable={isClearable}
      placeholder={placeholder !== undefined ? placeholder : 'Hours'}
      menuPosition={'fixed'}
    />
  )
}

export const constructOptions = (min: number, max: number, step: number): SelectOption<string>[] => {
  const options: SelectOption<string>[] = [];
  for (let i = min; i < max; i += step) {
    options.push(string2SelectOption(_.padStart(_.toString(i), 2, '0')))
  }
  return options;
}
