import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import { ColorPicker } from '../../../components';
import { NcSettingsFormData } from '../subpages/NcSettings/NcSettingsConfiguration/NcSettingsConfiguration';

interface Props {
  control: Control<NcSettingsFormData, any>,
  fieldName: FieldPath<NcSettingsFormData>
  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSettingsColorInput = ({ control, fieldName }: Props) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
        return (
          <ColorPicker disabled={false} color={_.toString(field.value)} onChange={(c) => { field.onChange(_.toString(c)) }} />
        )
      }}
    />
  )
}
