import React from 'react';
import { Button } from 'reactstrap';
import { styles } from '../AudienceBuilder';

interface Props {
  noRights: boolean
  onAddNewCondition: () => void
}
export const AddConditionToolbar = ({ noRights, onAddNewCondition }: Props) => {
  return (
    <div className={styles.conditionToolbar}>
      <Button disabled={noRights} color={'secondary'} onClick={onAddNewCondition}>add new condition</Button>
    </div>
  )
}
