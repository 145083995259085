import React, { useMemo, useState } from 'react';
import styles from '../NcSlidesManager.module.scss';
import { NcSlideshowFormData } from '../NcSlideshowDetails';
import { ControllerRenderProps, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { NcSlideConfigToggle } from '../../../components/NcSlideConfigToggle';
import _ from 'lodash';
import fp from 'lodash/fp';
import { NcSlideConfigSelect } from '../../../components/NcSlideConfigSelect';
import { Profile } from '../../../../../../domain';
import { NcSlideConfig, NcSlideConfig_article } from '../../../NarrowcastingDomain';
import { NcSlideConfigNumberInput } from '../../../components/NcSlideConfigNumberInput';
import { NcSlideConfigChannelSelect } from '../../../components/NcSlideConfigChannelSelect';
import { Button } from 'reactstrap';
import { ArticleSelector, TippyReact } from '../../../../../components';
import { useQuery } from '@tanstack/react-query';
import { EdErrorHandler, OrtecLoader } from '../../../../../widgets';
import { API } from '../../../../../apis';
import classNames from 'classnames';
import { DEFAULT_SLIDE_CONFIG_ARTICLE } from '../../../NarrowcastingHelper';


interface Props {
  magazine: number,
  profile: Profile,
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  activeIndex: number
  setError: UseFormSetError<NcSlideshowFormData>
  clearErrors: UseFormClearErrors<NcSlideshowFormData>
}

interface RendererOption {
  label: string,
  value: Pick<NcSlideConfig_article, "renderer">
}

export const NcSlideTypeConfig_Article = ({ magazine, profile, field, activeIndex, setError, clearErrors }: Props) => {

  const [showArticleSelector, setShowArticleSelector] = useState<boolean>(false);

  const slide = _.find(field.value, (s) => s.sort === activeIndex);
  const isTypeLatestFromChannel = _.get(slide?.config, 'data.type') === 'latestFromChannel' ? true : false;

  const articleIds = _.get(slide?.config, 'data.ids', []);

  const channel = _.get(slide?.config, 'data.channel');

  useMemo(() => {
    if (isTypeLatestFromChannel && !channel) {
      setError(`slides.${activeIndex}.config.data.channel`, { type: 'articleSlideValidation', message: 'No channel selected for article slide' });
    } else {
      clearErrors(`slides.${activeIndex}.config.data.channel`);
    }
  }, [isTypeLatestFromChannel, channel, activeIndex, setError, clearErrors])


  const articleExpiration = _.get(slide?.config, 'data.articleExpiration') ? true : false;

  const onArticleSelect = (articleId: number, sourceId: number, title: string) => {
    field.onChange(_.map(field.value, (s) => {
      if (s.sort !== activeIndex) {
        return s;
      }

      const data = s.config.data || { ...DEFAULT_SLIDE_CONFIG_ARTICLE.data, type: 'articleIds', ids: [] }

      const ids = _.uniq([...data.ids || [], articleId])

      return {
        ...s,
        config: {
          ...s.config,
          data: fp.set('ids', ids, data)
        }
      }
    }))
  }

  const onArticleRemove = (articleId: number) => {
    field.onChange(_.map(field.value, (s) => {
      if (s.sort !== activeIndex) {
        return s;
      }

      const data = s.config.data || { ...DEFAULT_SLIDE_CONFIG_ARTICLE.data, type: 'articleIds', ids: [] }

      const ids = _.filter(data.ids || [], (id) => id !== articleId)

      return {
        ...s,
        config: {
          ...s.config,
          data: fp.set('ids', ids, data)
        }
      }
    }))
  }

  return (
    <>
      <div className={styles.flexRow}>
        <div style={{ flex: 1 }}>
          <NcSlideConfigSelect
            {...{ field, activeIndex }}
            fieldName={`renderer`}
            label={`Slide display:`}
            options={[
              { value: 'fullarticle', label: 'Full article' },
              { value: '1article', label: '1 Headline' },
              { value: '2articles', label: '2 Headlines' },
              // { value: 'timed', label: 'X Headlines' },
            ]}
          />
        </div>
        <div style={{ flex: 1 }}>
          <NcSlideConfigSelect
            {...{ field, activeIndex }}
            fieldName={`data.type`}
            label={`From:`}
            options={[
              { value: 'latestFromChannel', label: 'Channel' },
              { value: 'articleIds', label: 'Specific Articles' }
            ]}
          />
        </div>
        <div style={{ flex: 1 }}>
          {isTypeLatestFromChannel &&
            <NcSlideConfigSelect
              {...{ field, activeIndex }}
              fieldName={`data.fromIndex`}
              label={`Starting From:`}
              options={[
                { value: '0', label: 'Newest article' },
                { value: '1', label: '2nd article' },
                { value: '2', label: '3rd article' },
                { value: '3', label: '4th article' },
                { value: '4', label: '5th article' },
              ]}
              valueAsNumber
            />
          }
        </div>
      </div>

      {isTypeLatestFromChannel ?
        <div className={styles.flexRow}>
          <TippyReact content={`A channel should be selected!`} config={{ placement: 'bottom', disabled: channel ? true : false }}>
            <div style={{ flex: 2 }} className={classNames({ [styles.requiredValueMissing]: !channel ? true : false })}>
              <NcSlideConfigChannelSelect
                {...{ magazine, profile, field, activeIndex }}
                fieldName={`data.channel`}
                label={`Channel:`}

              />
            </div>
          </TippyReact>
          {/* expiration group */}
          <div style={{ flex: 1, display: 'flex', gap: '16px' }}>
            <>
              <div style={{ flex: 1 }}>
                <NcSlideConfigToggle
                  {...{ field, activeIndex }}
                  fieldName={`data.articleExpiration`}
                  label={`Article expiration:`}
                />
              </div>
              {articleExpiration &&
                <div style={{ flex: 1 }}>
                  <NcSlideConfigNumberInput
                    {...{ field, activeIndex }}
                    fieldName={`data.articleMaxAge`}
                    label={`Max. age:`}
                    inputWrapperClass={styles.articleMaxAgeInput}
                    min={1}
                  />
                </div>
              }
            </>
          </div>
          {/* end of epxiration group */}
        </div >
        :
        <div className={styles.flexRow} style={{ flexDirection: 'column' }}>
          {!_.isEmpty(articleIds) &&
            <Nc_ArticleListPreview magazine={magazine} articleIds={articleIds} onArticleRemove={onArticleRemove} maxLimit={calcMaxArticleLimit(slide?.config)} />
          }
          <div style={{ display: 'flex' }}>
            <TippyReact content={`Reached article limit based on the selected slide display option.`} config={{ disabled: !isMaxArticlesReached(slide?.config), placement: 'right' }}>
              <div>
                <Button disabled={isMaxArticlesReached(slide?.config)} onClick={() => setShowArticleSelector(true)}>add article</Button>
              </div>
            </TippyReact>
          </div>
          {showArticleSelector &&
            <ArticleSelector noLeftMargin magazine={_.toString(magazine)} profile={profile} closeHandler={() => { setShowArticleSelector(false) }} selectHandler={onArticleSelect} limit={1000} variationLanguage={profile.magazineLanguages?.primary} />

          }
        </div>
      }
    </>
  )
};

// ─────────────────────────────────────────────────────────────────────────────


interface Nc_ArticleListPreviewProps {
  magazine: number
  articleIds: number[]
  maxLimit: number
  onArticleRemove: (articleId: number) => void
}

const Nc_ArticleListPreview = ({ magazine, articleIds, maxLimit, onArticleRemove }: Nc_ArticleListPreviewProps) => {
  if (_.isEmpty(articleIds)) {
    return null;
  }
  return (
    <div className={styles.articleListPreview}>
      <div className={styles.articleListPreviewHeader}>article title</div>
      {_.map(articleIds, (id, index) => {
        return (
          <Nc_ArticlePreview
            key={id}
            magazine={magazine}
            articleId={id}
            onArticleRemove={onArticleRemove}
            rowClass={(index + 1) > maxLimit ? styles.notShownArticle : undefined}
            tooltip={(index + 1) > maxLimit ? `Exceeded article limit. This article won't be shown due to the selected slide display option.` : undefined}
          />
        )
      })}
    </div>
  )
}

interface Nc_ArticlePreviewProps {
  magazine: number
  articleId: number,
  onArticleRemove: (articleId: number) => void
  tooltip?: string
  rowClass?: string
}

const Nc_ArticlePreview = ({ magazine, articleId, onArticleRemove, tooltip, rowClass }: Nc_ArticlePreviewProps) => {

  const ncArticleTitleQuery = useQuery({
    queryKey: ['articleTitle', articleId],
    queryFn: async () => {
      try {
        const { data } = await API.articles.getArticlesTitles(magazine, [articleId]);
        return data[0].title;
      } catch (error) {
        EdErrorHandler(error, `getting article's title`);
      }
    }
  });

  return (
    <TippyReact content={tooltip} config={{ disabled: tooltip ? false : true }}>
      <div className={classNames(styles.articleListPreviewRow, rowClass)}>
        {ncArticleTitleQuery.isLoading ? <OrtecLoader size={'icon'} px={16} /> :
          ncArticleTitleQuery.data || articleId
        }
        <i onClick={(e) => { onArticleRemove(articleId) }} className='fa fa-times'></i>
      </div>
    </TippyReact>
  )
}


// ─── Helper Functions ────────────────────────────────────────────────────────

const isMaxArticlesReached = (articleConfig?: NcSlideConfig): boolean => {
  const articleIds = articleConfig?.data?.ids || [];
  return _.size(articleIds) >= calcMaxArticleLimit(articleConfig);
}

const calcMaxArticleLimit = (articleConfig?: NcSlideConfig): number => {
  switch (articleConfig?.renderer) {
    case '2articles':
      return 2;
    case 'timed':
      return 5;
    default:
      return 1;
  }
}