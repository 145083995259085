import cn from 'classnames'
import React from 'react';

interface Props {
  onClose: () => void
}

import styles from './GenericModalCloseButton.module.scss'

const GenericModalCloseButton = (props: Props) =>
  <img
    className={cn("close", styles.closeBtn)}
    onClick={(e) => {
      e.preventDefault()
      e.stopPropagation()
      props.onClose()
    }}
    src="/assets/icons/bt-close.svg"
  />


export default GenericModalCloseButton