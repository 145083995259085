import React, { useState } from 'react';
import styles from '../NcSettings.module.scss';
import { FullPageModal, FullPageModalHeader, FullPageModalContent } from '../../../../../components/FullPageModal';
import { Profile } from '../../../../../../domain';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../../../apis';
import { EdErrorHandler, Swal, SwalDelete } from '../../../../../widgets';
import { NC_DEFAULT_SETTINGS_FONTS } from '../NarrocastingSettingsDefaults';
import { NcSettingsFontsMedia, NcSettingsFont } from '../../../NarrowcastingDomain';
import _ from 'lodash';
import { MediaSelector, TippyReact } from '../../../../../components';
import { Button } from 'reactstrap';
import fontsStyles from './NcSettingsFonts.module.scss';
import { MediaHelper } from '../../../../../utils';
import DataTable, { ExtendedColumnDescription } from '../../../../../components/DataTable/DataTable';

interface Props {
  magazine: number,
  profile: Profile
  onClose: () => void,

}

export const NcSettingsFonts = ({ magazine, profile, onClose }: Props) => {
  const [mediaSelector, setMediaSelector] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const ncFontsQuery = useQuery({
    queryKey: ['ncFonts', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcSettingsFonts(magazine);
        return data;
      } catch (error) {
        if (error?.response?.status === 422) { //not valid schema. probably legacy record.
          return [...NC_DEFAULT_SETTINGS_FONTS];
        } else if (error?.response?.status === 404) {
          return [...NC_DEFAULT_SETTINGS_FONTS];
        } else {
          EdErrorHandler(error, `getting NC settings fonts`);
        }
      }
    }
  });


  const ncFontsUpdateMutation = useMutation({
    mutationKey: ['ncFontsUpdate', magazine],
    mutationFn: (f: NcSettingsFontsMedia) => API.narrowcasting.saveNcSettingsFonts(magazine, f),
    onMutate: async (newFonts: NcSettingsFontsMedia) => {
      await queryClient.cancelQueries({ queryKey: ['ncFonts', magazine] });
      const previousFonts = queryClient.getQueryData(['ncFonts', magazine]);
      queryClient.setQueryData(['ncFonts', magazine], (f: NcSettingsFontsMedia) => [...newFonts]);
      return { previousFonts }
    },
    onError: (error, newFonts, context) => {
      EdErrorHandler(error, `saving fonts`);
      queryClient.setQueryData(['ncFonts', magazine], context?.previousFonts);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['ncFonts', magazine] })
    }
  });

  const isLoading = ncFontsQuery.isFetching || ncFontsUpdateMutation.isLoading;

  const onMediaHandlerSelect = async (mediaUrls: string[]) => {
    const fonts = queryClient.getQueryData(['ncFonts', magazine]) as NcSettingsFontsMedia
    ncFontsUpdateMutation.mutate(
      _.uniqBy(
        [
          ...(fonts || []),
          ..._.map(mediaUrls, (mu) => { return { uri: mu, name: MediaHelper.convertPlusToSpaces(MediaHelper.extractNameFromPath(mu)) } })
        ], 'uri')
    );
    setMediaSelector(false);
  }

  const onFontRemove = async (mediaUrl: string) => {
    const fonts = queryClient.getQueryData(['ncFonts', magazine]) as NcSettingsFontsMedia
    ncFontsUpdateMutation.mutate(_.filter(fonts, (u) => u.uri !== mediaUrl));
  }

  const onFontRename = async (mediaUrl: string, newName: string) => {
    const fonts = queryClient.getQueryData(['ncFonts', magazine]) as NcSettingsFontsMedia;
    ncFontsUpdateMutation.mutate(_.map(fonts, (f) => {
      if (f.uri == mediaUrl) {
        return {
          ...f,
          name: newName
        }
      }
      return f;
    }));
  }

  const onDelete = async (f: NcSettingsFont) => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      html: `This action will delete font <code> ${f.name || MediaHelper.extractFullNameFromPath(f.uri)}</code>.`
    });
    if (!confirm) {
      return;
    }
    onFontRemove(f.uri);
  }

  const onRename = async (f: NcSettingsFont) => {
    const { value: newName } = await Swal.fire({
      title: 'Rename Font',
      input: 'text',
      inputValue: f.name,
      html: `<div style="text-align:left">File: <b> ${MediaHelper.extractFullNameFromPath(f.uri)}</b></div>`,
      inputPlaceholder: `Enter a font name`,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return `You need to give a name!`
        }
        return null;
      },

    });

    if (newName) {
      onFontRename(f.uri, newName);
    }
  }



  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'name',
      text: 'font name',

    },
    {
      dataField: 'uri',
      text: 'file name',
      formatter: (uri: string) => {
        return MediaHelper.extractFullNameFromPath(uri);
      }
    },
    {
      dataField: 'dfActions',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatter: (cell, row: NcSettingsFont) => {
        return (
          <div className={'actionHoveringDiv'}>
            <Button color={'secondary'} style={{ marginRight: '8px' }} onClick={(e) => { onRename(row); e.preventDefault(); e.stopPropagation(); return false; }}>Rename</Button>
            <Button color={'secondary'} onClick={(e) => { onDelete(row); e.preventDefault(); e.stopPropagation(); return false; }}>Delete</Button>
          </div>
        )
      },
    }

  ];

  return (
    <FullPageModal>
      <FullPageModalHeader isLoading={ncFontsQuery.isLoading} onClose={onClose}>
        <div className={styles.modalTitle}>Fonts</div>
        <TippyReact config={{ disabled: true }} content={''}>
          <div><Button color={'primary'} onClick={() => { setMediaSelector(true) }}>add fonts</Button></div>
        </TippyReact>
      </FullPageModalHeader>
      <FullPageModalContent isLoading={ncFontsQuery.isLoading}>
        <div className={fontsStyles.NcSettingsFonts}>
          <div className={fontsStyles.guidelinesWrapper}>
            There are always 5 variations of Roboto font (i.e. Regular, Thin, Light, Medium, Bold) available by default. Fonts below will be used as alternative options.
          </div>
          <div className={fontsStyles.mediaItemsWrapper}>
            <DataTable
              keyField='uri'
              columns={columns}
              data={ncFontsQuery.data || []}
              onRowClick={() => { }}
              noPagination
              hideSearchBar
            />
          </div>
          {mediaSelector &&
            <MediaSelector {...{ magazine, profile }}
              closeHandler={() => { setMediaSelector(false) }}
              selectHandler={onMediaHandlerSelect}
              restrictedMediaTypes={['files']}
              dedicatedBucket={`${magazine}_nc`}
              dedicatedPart='protected'
              multipleSelection
            />}
        </div>
      </FullPageModalContent>
    </FullPageModal>
  )
}
