import React, { useEffect, useState } from 'react';

import styles from './NcDeviceDetails.module.scss';
import { TippyReact } from '../../components';
import { Button, Input } from 'reactstrap';
import { NcDevice, NcDevice_update, NcGroup } from './NarrowcastingDomain';
import { EdErrorHandler, LoadingButton, OrtecLoader, SwalSuccess } from '../../widgets';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../../apis';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { FullPageModal, FullPageModalContent, FullPageModalContentPart, FullPageModalHeader, FullPageModalSettings, FullPageModalSettingsSection, FullPageModalSettingsSectionRow } from '../../components/FullPageModal';
import { NcGroupDetailsModal } from './NcGroupDetailsModal';
import { DEFAULT_NC_GROUP } from './NcGroupsModal';
import { DateHelper } from '../../utils';
import classNames from 'classnames';
import { ConnectButton, DisconnectLink, calcDeviceStatus } from './NcDevices';
import { NcDevicePlaylist } from './subpages/NcDevicePlaylist/NcDevicePlaylist';
import { NoSearchableSingleSelect, SingleSelect } from '../../components/ReactSelect/ReactSelect';
import { createLocaleOptions } from './NarrowcastingHelper';

interface DeviceFormData {
  id: number
  label: string
  unique_id: string
  group_ids: number[]
  orientation: string,
  locale: string
}

interface Props {
  magazine: number,
  devices: NcDevice[],
  ncGroupsQuery: UseQueryResult<NcGroup[] | undefined>
  deviceId: number,
  onClose: () => void,
}

export const NcDeviceDetails = ({ magazine, devices, ncGroupsQuery, deviceId, onClose }: Props) => {

  const queryClient = useQueryClient();

  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState<boolean>(false);

  const ncDeviceQuery = useQuery({
    queryKey: ['ncDevice', deviceId],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcDevice(magazine, deviceId);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting NC device`);
      }
    }
  });

  const { control, handleSubmit, getValues, watch, setValue, reset, formState: { errors } } = useForm<DeviceFormData>({
    defaultValues: {
      id: deviceId,
      group_ids: []
    }
  });

  const device = getValues();

  useEffect(() => {
    reset({
      id: ncDeviceQuery.data?.id,
      label: ncDeviceQuery.data?.label || '',
      unique_id: ncDeviceQuery.data?.unique_id || '',
      group_ids: ncDeviceQuery.data?.group_ids || [],
      orientation: ncDeviceQuery.data?.config?.orientation || '',
      locale: ncDeviceQuery.data?.config?.locale || '',
    })
  }, [ncDeviceQuery.data, reset])

  const loading = ncDeviceQuery.isFetching;

  const nameHasErrors = errors.label ? true : false;
  const hasErrors = nameHasErrors;

  const ncDeviceUpdateMutation = useMutation({
    mutationKey: ['ncDeviceUpdate'],
    mutationFn: (d: NcDevice_update) => API.narrowcasting.updateNcDevice(magazine, d),
    onError: (error, d) => {
      EdErrorHandler(error, `saving device`);
    },
    onSuccess: (data, d) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `Device <${d.label}> has been saved successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['ncGroups'] })
      queryClient.invalidateQueries({ queryKey: ['ncDevices'] })
      onClose();
    }
  })

  const onSave = handleSubmit(async (data) => {

    // console.log(data);


    const { id, label, group_ids, orientation, locale } = data;

    const d: NcDevice_update = {
      id,
      label,
      group_ids,
      config: (!orientation && !locale) ? undefined : {
        orientation,
        locale
      }
    };

    ncDeviceUpdateMutation.mutate(d);

  });

  const deviceStatus = calcDeviceStatus(ncDeviceQuery.data?.unique_id, ncDeviceQuery.data?.last_seen);

  return (
    <FullPageModal>
      <FullPageModalHeader isLoading={loading} onClose={onClose} >
        <>
          <Controller
            name={'label'}
            control={control}
            rules={{ required: true, validate: (v: string) => !_.includes(_.map(_.filter(devices, (d) => d.id !== deviceId), (d) => d.label), v) }}
            render={({ field }) => {
              return (
                <Input {...field} invalid={nameHasErrors} className={styles.nameInput} placeholder={`Enter your device name here...`} onChange={(e) => { field.onChange(e.target.value) }}></Input>
              )
            }}
          />
          <TippyReact config={{ disabled: !hasErrors }} content={nameHasErrors ? (errors.label?.type == 'required' ? 'Name is required!' : 'Device name already exists!') : 'There are validation errors in your form!'}><div><LoadingButton disabled={hasErrors} loading={ncDeviceUpdateMutation.isLoading} onClick={() => { onSave() }} text={'save'} /></div></TippyReact>
        </>
      </FullPageModalHeader>
      <FullPageModalContent isLoading={loading}>
        <FullPageModalContentPart flex={2} cssStyle={{ display: 'flex', flexDirection: 'column' }} mainPart>
          <Controller
            name={'group_ids'}
            control={control}
            render={({ field }) => {
              return (
                <NcDevicePlaylist
                  magazine={magazine}
                  deviceId={deviceId}
                  group_ids={field.value}
                />
              )
            }} />
        </FullPageModalContentPart>
        <FullPageModalContentPart cssStyle={{ overflowY: 'auto', minWidth: '350px' }} withBackground>
          <FullPageModalSettings>
            <FullPageModalSettingsSection title={'settings'}>
              <>
                <FullPageModalSettingsSectionRow>
                  <div className={styles.label}>Status</div>
                  <div className={styles.statusRow}>
                    <div className={classNames(styles.status, styles[`status-${deviceStatus}`])}>
                      {_.capitalize(deviceStatus)}
                    </div>
                    {ncDeviceQuery.data &&
                      <div className={''}>
                        {deviceStatus !== 'disconnected' ?
                          <DisconnectLink magazine={magazine} device={ncDeviceQuery.data} />
                          :
                          <ConnectButton magazine={magazine} device={ncDeviceQuery.data} />
                        }
                      </div>
                    }
                  </div>
                  <div className={styles.lastSeenRow}>{ncDeviceQuery.data?.last_seen ? `Not seen since ${DateHelper.formatDateTimeStr(ncDeviceQuery.data?.last_seen)}` : `Never seen`}</div>
                </FullPageModalSettingsSectionRow>
                <FullPageModalSettingsSectionRow>
                  <div className={styles.label}>Device Locale</div>
                  <Controller
                    name={'locale'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <SingleSelect
                          value={field.value || undefined}
                          placeholder='Global locale'
                          isClearable
                          options={createLocaleOptions()}
                          onChange={(e) => { e === undefined ? field.onChange('') : field.onChange(e)}}
                        />
                      )
                    }}
                  />

                </FullPageModalSettingsSectionRow>
                <FullPageModalSettingsSectionRow>
                  <div className={styles.label}>Device Orientation</div>
                  <Controller
                    name={'orientation'}
                    control={control}
                    render={({ field }) => {

                      const deviceOrientationOptions = [
                          {value: '0', label:'0° - normal'},
                          {value: '90', label:'90° - '},
                          {value: '180', label:'180° - '},
                          {value: '270', label:'270° - '}
                      ]

                      return (
                        <NoSearchableSingleSelect
                          value={field.value || deviceOrientationOptions[0].value}
                          options={deviceOrientationOptions}
                          onChange={(e) => { e === undefined ? field.onChange('0') : field.onChange(e)}}
                        />
                      )
                    }}
                  />

                </FullPageModalSettingsSectionRow>
              </>
            </FullPageModalSettingsSection>
            <FullPageModalSettingsSection
              title={'Device Groups'}
              titleButton={<Button onClick={() => setIsCreateGroupModalOpen(true)}>add new group</Button>}
              separator
            >
              <div className={styles.groupsContainer}>
                {ncGroupsQuery.isFetching ? <OrtecLoader size={'small'} /> :
                  _.isEmpty(ncGroupsQuery.data) ? <div className={''}>There are no groups</div> :
                    <Controller
                      name={'group_ids'}
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            {_.map(ncGroupsQuery.data || [], (g) => {
                              return (
                                <div className={styles.groupRow}>
                                  <input id={`ncGroup-${g.id}`} type="checkbox" checked={_.includes(field.value, g.id)} onChange={(e) => {
                                    if (e.target.checked) {
                                      field.onChange(_.isEmpty(field.value) ? [g.id] : [...field.value, g.id])
                                    } else {
                                      field.onChange(_.filter(field.value, (v) => g.id !== v))
                                    }
                                  }} />
                                  <label htmlFor={`ncGroup-${g.id}`}> {g.label}</label>
                                </div>
                              )
                            })}
                          </>
                        )
                      }}
                    />
                }
              </div>
            </FullPageModalSettingsSection>
            {device.unique_id &&
              <FullPageModalSettingsSection title={'UID'} separator>
                {device.unique_id}
              </FullPageModalSettingsSection>
            }
          </FullPageModalSettings>
        </FullPageModalContentPart>
      </FullPageModalContent>
      {isCreateGroupModalOpen &&
        <NcGroupDetailsModal
          {...{ magazine }}
          groups={ncGroupsQuery.data || []}
          devices={[]}
          onClose={() => { setIsCreateGroupModalOpen(false) }}
          group={{ ...DEFAULT_NC_GROUP, magazine }}
          onCreateAndSelect={(groupId: number) => {
            setValue('group_ids', _.isEmpty(device.group_ids) ? [groupId] : [...device.group_ids, groupId]);
          }}
        />
      }
    </FullPageModal >
  )
}
