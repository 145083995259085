import { AxiosPromise } from "axios";
import { dashApi, endpointWithParams } from "../api";
import { SharepointList, SharepointSite } from "../pages/Advanced/subpages/sharepoint/SharepointDomain";

export const getMagazineSites = (magazine: number): AxiosPromise<SharepointSite[]> => {
  return dashApi.get(magazine, `sharepoint/sites`);
}
export const getSiteLists = (magazine: number, customerId: string, siteUrl: string): AxiosPromise<SharepointList[]> => {
  return dashApi.get(magazine, endpointWithParams(`sharepoint/lists`, { customerId, siteUrl: encodeURI(siteUrl) }));
}
export const subscribeToList = (magazine: number, customerId: string, listUrl: string): AxiosPromise<void> => {
  return dashApi.post(magazine, `sharepoint/lists/subscribe`, { customerId, listUrl });
}
export const unsubscribe = (magazine: number, subscriptionId: string): AxiosPromise<void> => {
  return dashApi.delete(magazine, `sharepoint/subscriptions/${subscriptionId}`);
}
