import React, { Component, Fragment } from 'react';
import { Col, Button } from 'reactstrap';
import './css/SourcesOverviewTable.min.css'

import { Profile, Source } from '../../../../../domain';
import moment from 'moment';
import { Translations } from '../../../../translations';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as _ from 'lodash';
import * as Api from '../../../../api';

import { SwalSuccess, SwalError, SwalDelete, OrtecLoader } from '../../../../widgets';
import { SourcesHelper } from '../../../../utils';
import { SourceWithFeedInfo } from './Sources.domain';



export interface Props {
  magazine: string,
  profile: Profile,
  clickSourceHandler: (source: number, parent?: string) => void
  createNewSourceHandler: () => void
}
export interface State {
  activeTab: string,
  sources?: SourceWithFeedInfo[],
  historyLastUpdate: number
}

export class SourcesOverviewTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: '1',
      historyLastUpdate: moment().valueOf()
    };
  }

  audienceFormatter = (cell: any, row: any) => {
    const { profile } = this.props;
    const tr = Translations.Translate(Translations, 'Channels');
    const audiences: any[] = [];
    if (!cell) {
      return '-';
    }

    cell.map((a: number) => {
      audiences.push((a == 1) ? tr('all_employees') : _.get(_.find(profile.audiences, ['id', a]), 'label'));
    });

    if (audiences.length == 0) {
      return '-'
    } else if (audiences.length == 1) {
      return audiences[0];
    } else {
      return <span data-tippy-content={audiences.join(', ')}>{audiences.length}</span>;
    }
  }

  channelFormatter = (cell: any, row: any) => {
    const { profile } = this.props;
    const tr = Translations.Translate(Translations, 'Channels');
    const channels: any[] = [];
    if (!cell) {
      return '-';
    }

    // cell.map((a: number) => {
    //   audiences.push((a == 1) ? tr('all_employees') : _.get(_.find(profile.audiences, ['id', a]), 'label'));
    // });

    if (cell.length == 0) {
      return '-'
    } else {
      return <span data-tippy-content={cell.join(', ')}>{cell.length}</span>;
    }
  }

  buttonsFormatter(cell: any, row: any) {
    const tr = Translations.Translate(Translations, 'Channels');

    return (
      <div className={'actionHoveringDiv'}>

        <Button className="selectButton secondary" /*data-tippy-content={tr(`selectArticle`)}*/
          style={{ marginLeft: '10px' }}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.deleteSource(row.id);
            return false;
          }}>
          {tr('delete')}
        </Button>
      </div>
    )
  }

  deleteSource = (source: number) => {
    SwalDelete.fire({
      // type: 'warning',
      title: 'Are you sure?',
      text: `This action will delete source with id: ${source}`,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      // focusConfirm: false,
      // animation: false,
      // customClass: {
      //   popup: 'animated pulse',
      //   confirmButton: 'bg-danger',
      // },
    }).then((result) => {
      if (result.value) {
        Api.deleteSource(parseInt(this.props.magazine), source).then((resp) => {
          if (!resp.data || resp.data.error) {
            console.log('Error in deleting source');
            SwalError.fire({
              // type: 'error',
              title: 'Something went wrong!',
              text: `Error in deleting source ${source}`,
              footer: `<div class="alert alert-danger">${resp.data.error}</div>`
            });
            return;
          }
          this.getSources();
          SwalSuccess.fire({
            type: 'success',
            title: 'Success!',
            text: `Source with id ${source} has been deleted`,
            // footer: `<div class="alert alert-error">${resp.data.error}</div>`
          });
        })
          .catch((error) => {
            console.log('An error occured:' + error.message)
            SwalError.fire({
              // type: 'error',
              title: 'Something went wrong!',
              text: `Error in deleting source ${source}`,
              footer: `<div class="alert alert-danger">${error.message}</div>`
            });
          });

      }
    })

  }

  renderToolbar = (props: any) => {
    const tr = Translations.Translate(Translations, 'Channels');
    return (
      <Fragment>
        <Col md={4}>{props.components.searchField}</Col>
        <Col md={4}></Col>
        <Col md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button onClick={() => this.props.createNewSourceHandler()}>{tr('createNewSource')}</Button>
        </Col>
      </Fragment>
    );
  }


  onRowClick = (row: any) => {
    this.props.clickSourceHandler(row.id);
  }

  componentDidMount() {
    this.getSources();
  }



  getSources = () => {
    Api.getSources(parseInt(this.props.magazine)).then((resp) => {

      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ sources: [] });
      }
      this.setState({ sources: resp.data ? resp.data : [] });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }

  getAudiencesIds = () => {
    const { profile } = this.props;

    let audiences: number[] = [];

    if (!_.isEmpty(profile.audienceIds)) {
      audiences = _.map(profile.audienceIds, _.toInteger);
    }

    if (profile.globalAudience) {
      audiences.unshift(1);
    }

    return audiences;

  }


  renderPaginationPanel(props: any) {
    let max = 1;
    let min = 1;
    if (this.state.sources) {
      max = (this.state.sources.length / 10) + 1;
    }
    const tr = Translations.Translate(Translations, 'Tables');

    return (
      <Fragment>
        <Col md={8}>{props.components.pageList} {/*props.components.sizePerPageDropdown*/}</Col>
        <Col md={4}>
          <div className="goToPageDiv">
            <input id="goToPageInput" onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                let val = e.target.value;
                if (val < min) {
                  val = min;
                } else if (val > max) {
                  val = max;
                }
                props.changePage(val);
                e.target.value = '';
              }
            }} className='form-control' type="number" placeholder={tr('go_to_page')} style={{ width: '115px' }} />
            <button className="btn" onClick={(e) => {
              var k: any = document.getElementById('goToPageInput');
              let val = k.value;
              if (val < min) {
                val = min;
              } else if (val > max) {
                val = max;
              }
              props.changePage(val);
              k.value = ''
            }}>{'>'}</button>
          </div>
        </Col>
      </Fragment>
    );
  }

  statusFormatter = (cell: any) => {
    const tr = Translations.Translate(Translations, 'Advanced');
    try {
      // const status = (cell);
      if (cell === true) {
        return `<i class="fa fa-circle status-active" aria-hidden="true" data-tippy-content="${tr("active")}" data-tippy-placement="right"></i>`;
      } else {
        return `<i class="fa fa-circle status-inactive" aria-hidden="true" data-tippy-content="${tr("inactive")}" data-tippy-placement="right"></i>`;
      }
    } catch (error) {
      return <>{cell}</>;
    }
  }

  render() {
    const { magazine, profile } = this.props;
    const { sources } = this.state;
    const tr = Translations.Translate(Translations, 'Sources');
    const trt = Translations.Translate(Translations, 'Tables');

    const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>;

    const options: any = {
      onRowClick: this.onRowClick.bind(this),
      toolBar: this.renderToolbar,
      paginationPanel: this.renderPaginationPanel.bind(this),
      defaultSortName: 'cdate',  // default sort column name
      defaultSortOrder: 'desc',  // default sort order
      sizePerPage: 25,
      sizePerPageList: [
        { text: `25 ${trt('entries')}`, value: 25 },
        { text: `50 ${trt('entries')}`, value: 50 },
        { text: `100 ${trt('entries')}`, value: 100 },
      ]
    }


    return (
      <Fragment>
        <div id="SourcesOverviewTable" className="">
          <div className="tableDiv withLoader">
            {Translations.EnableTranslations(Translations)}
            {sources ?
              <BootstrapTable ref="sourcesOverviewTable" data={sources} bordered={false} hover={true} search={true} options={options} version='4' pagination >
                <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
                <TableHeaderColumn dataField='name' tdStyle={{ cursor: 'pointer' }} dataFormat={(cell, row) => SourcesHelper.sourceEdConfigName(row, profile.edConfig)}></TableHeaderColumn>
                <TableHeaderColumn dataField='srctype' dataAlign='center' width='200px' dataFormat={SourcesHelper.sourceTypeFormatter} filterValue={SourcesHelper.sourceTypeFilterValue}>
                  <img data-tippy-content={`${tr("sourceType")} / Feed`} src="/assets/icons/16/form_type.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='parentChannels' dataAlign='center' columnClassName='hideOnHover' width='50px' dataFormat={this.channelFormatter.bind(this)}>
                  <img data-tippy-content={tr("inChannels")} src="/assets/icons/16/channels.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='articleCount' dataAlign='center' columnClassName='hideOnHover' width='60px'>
                  <img data-tippy-content={tr("articles")} src="/assets/icons/16/publications.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='status' dataAlign='center' columnClassName='hideOnHover' dataFormat={this.statusFormatter.bind(this)} width='50px'>
                  <img data-tippy-content={tr("status")} src="/assets/icons/16/stats.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataFormat={this.buttonsFormatter.bind(this)} width='50px'></TableHeaderColumn>
                <TableHeaderColumn dataField='cdate' hidden></TableHeaderColumn>
              </BootstrapTable>
              : <OrtecLoader />
            }
          </div>

        </div>
      </Fragment>

    );
  }
}

export default SourcesOverviewTable;
