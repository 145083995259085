import './css/TranslationsComponent.min.css';

import React from 'react';
import classnames from 'classnames';
import { MagazineLanguage, MagazineLanguages } from '../../../domain';
import _ from 'lodash';

export interface Props {
    languages: MagazineLanguages,
    boxElement: JSX.Element
}

export function TranslationsComponent(props: Props) {
    const { languages, boxElement } = props;
    const getLanguage = (key: string) => {
        return _.find(languages.languages, ['key', key]);
    }
    const getNonPrimaryActiveLanguages = () => {
        return _.map(languages.languages, (l) => {
            if (l.key !== languages.primary && l.enabled) {
                return l;
            }
        })
    }
    return (
        <div className={'TranslationsComponent'}>
            <TranslationBox primary={true} language={getLanguage(languages.primary)} boxElement={boxElement} />
            {_.map(getNonPrimaryActiveLanguages(), (l, index) => {
                return <TranslationBox key={`trb-${l ? l.key : index}`} primary={false} language={l} boxElement={boxElement} />
            })}
        </div>
    )
}

interface TranslationBoxProps {
    primary: boolean,
    language?: MagazineLanguage,
    boxElement: JSX.Element,
}

export function TranslationBox(props: TranslationBoxProps) {
    const { primary, language, boxElement } = props;
    if (!language) {
        return null;
    }
    return (
        <div className={classnames('translationBox', { 'primary': primary })}>
            <div className={classnames('titleDiv')}>{primary ? `DEFAULT (${language.name})` : `${language.name}`}</div>
            {{ ...boxElement, props: { ...boxElement.props, primary, language: language.key } }}
        </div>
    )
}

