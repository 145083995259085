import React from 'react'
import { SourceTypeSettingsProps } from '../SourceTypeSettings'
import { Col, FormText, Label } from 'reactstrap';
import { AudiencesTargeting } from '../../../../../components';
import _ from 'lodash';

interface AudienceTargeting {
  audiences: number[],
  combineAudiencesWithAnd?: boolean
}

interface SourceConfiguration_reporter {
  reporterWritePermissions: string,
  reporterAudienceWritePermissions?: AudienceTargeting
}

export const Reporter = ({ magazine, profile, source, onChangeSource }: SourceTypeSettingsProps) => {
  const reporterConfiguration = extractReporterConfig(source.config);

  const hasLegacyWritePermissions: boolean = (!reporterConfiguration?.reporterAudienceWritePermissions && reporterConfiguration?.reporterWritePermissions) ? true : false;

  const changeWriterPermissionsAudiences = (newAudiences: number[]) => {
    onChangeSource({
      ...source, config: {
        ...(reporterConfiguration || {}),
        reporterAudienceWritePermissions: {
          ...(reporterConfiguration?.reporterAudienceWritePermissions || {}),
          audiences: newAudiences
        }
      }
    })
  }
  const changeCombineAudiencesWithAnd = (combineAudiencesWithAnd: boolean) => {
    onChangeSource({
      ...source, config: {
        ...(reporterConfiguration || {}),
        reporterAudienceWritePermissions: {
          ...(reporterConfiguration?.reporterAudienceWritePermissions || {}),
          combineAudiencesWithAnd: combineAudiencesWithAnd
        }
      }
    })
  }

  return (
    <>
      <Col md={12}>
        <Label>Reporter write permissions:</Label>
        <AudiencesTargeting
          {...{
            magazine,
            profile,
            targetedAudiences: _.map((reporterConfiguration?.reporterAudienceWritePermissions?.audiences || []), _.toString),
            changeTargeting: changeWriterPermissionsAudiences,
            combineAudiencesWithAnd: reporterConfiguration?.reporterAudienceWritePermissions?.combineAudiencesWithAnd || false,
            changeCombineAudiencesWithAnd
          }}
          emptyMeansEveryone
          hideInfo
        />
        {hasLegacyWritePermissions ?
          <FormText color='info'>Legacy source from old DASH. The stored targeting rule applies until an update.</FormText>
          :
          <FormText>Determines who can post to this reporter source</FormText>
        }
      </Col>
    </>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const extractReporterConfig = (configuration?: any): SourceConfiguration_reporter | undefined => {
  if (!configuration?.reporterWritePermissions && !configuration?.reporterAudienceWritePermissions) { //check if ther is already a valid reporter configuration
    return undefined;
  }

  return {
    reporterWritePermissions: configuration.reporterWritePermissions,
    reporterAudienceWritePermissions: configuration.reporterAudienceWritePermissions
  }
}