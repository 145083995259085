import classNames from 'classnames';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { Comment } from '../../pages/Content/subpages/comments/domain';
import { DateHelper } from '../../utils';
import styles from './ArticleDiscussionComment.module.scss';
import { FlaggedContent } from '../../pages/Content/subpages/reportedContent/ReportedContentDomain';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  comment: Comment
  replies: Comment[]
  selected: string[]
  activeCommentId?: string
  readOnly?: boolean
  onToggle: (commentId: string, checked: boolean) => void
  changeActiveCommentId?: (commentId: number) => void,
  reportedContent?: FlaggedContent[]
  showOnlyReported?: boolean,
  isParent: boolean,
  isReplyWithReportsInMyTree?: boolean
}

export const ArticleDiscussionComment = ({ comment, replies, selected, activeCommentId, readOnly, onToggle, changeActiveCommentId, reportedContent, isParent, showOnlyReported, isReplyWithReportsInMyTree }: Props) => {



  const dateOfUpdateText = useMemo(() => {
    if ((comment.cdate !== comment.mdate)) {
      return <span className={styles.updateDateText}>Updated at {DateHelper.dateTimeToLocaleString(comment.mdate)}</span>
    }
    return <></>;
  }, [comment.cdate, comment.mdate]);


  const highlighted = activeCommentId && !reportedContent ? _.includes([comment.id, _.toString(comment.parentId)], activeCommentId) : false;


  const reportsOnComment = _.filter(reportedContent, (r) => _.toNumber(comment.id) === r.commentId);

  const hasReports = !_.isEmpty(reportsOnComment);

  const isParentWithReportsInMyTree = useMemo(() => {
    if (!isParent) { //not a parent
      return false
    }
    if (hasReports) { //reports in parent itself
      return true;
    }
    return replies.some((reply) => { //if at least one reply with reports found
      return reportedContent?.find((r) => _.toNumber(reply.id) === r.commentId) ? true : false
    });
  }, [isParent, replies, reportedContent, hasReports]);

  const hidden = useMemo(() => {
    if (showOnlyReported && !isParentWithReportsInMyTree && !isReplyWithReportsInMyTree) {
      return true;
    }

    return false;
  }, [showOnlyReported, isParentWithReportsInMyTree, isReplyWithReportsInMyTree])


  const isClickable = reportedContent && changeActiveCommentId && (activeCommentId !== comment.id) && hasReports;

  const activeWithReports = reportedContent && (activeCommentId === comment.id) && hasReports;

  const totalReviewedReports = _.size(_.filter(reportsOnComment, (r) => r.status !== 'new'));
  const totalReports = _.size(reportsOnComment);

  const isDeleted = comment.status === 0;

  if (isDeleted && (reportedContent === undefined)) {
    return null;
  }



  return (
    <>
      <div className={classNames(styles.ArticleDiscussionComment, { [styles.hidden]: hidden, [styles.highlighted]: highlighted, [styles.clickable]: isClickable, [styles.activeWithReports]: activeWithReports, [styles.deletedComment]: isDeleted })} onClick={isClickable ? () => changeActiveCommentId(_.toNumber(comment.id)) : undefined}>
        {/* <div className={styles.checkboxContainer}> */}
        {!readOnly && <input type={'checkbox'} disabled={isDeleted || _.includes(selected, _.toString(comment.parentId))} checked={_.includes(selected, comment.id)} onChange={(e) => { onToggle(comment.id, e.target.checked) }} />}
        {/* </div> */}
        <div className={styles.commentContainer}>
          <div className={styles.commentText}>
            {comment.text}
          </div>
          <div className={styles.commentInfo}>
            <div className={styles.commentAuthor}>{comment.authorName}</div>
            <div className={styles.commentDate}>{DateHelper.dateTimeToLocaleString(comment.cdate)} {dateOfUpdateText}</div>
          </div>
        </div>
        {hasReports &&
          <div className={classNames(styles.reportsContainer, { [styles.attention]: totalReviewedReports < totalReports })}>
            <span className={'material-icons'}>flag</span>
            <span>{totalReviewedReports}/{totalReports}</span>
          </div>
        }
      </div>
      {!_.isEmpty(replies) &&
        <div className={styles.ArticleDiscussionCommentReplies}>
          {_.map(replies, (r) => {
            return <ArticleDiscussionComment key={r.id} comment={r} replies={[]} selected={selected} onToggle={onToggle} activeCommentId={activeCommentId} readOnly={readOnly} changeActiveCommentId={changeActiveCommentId} reportedContent={reportedContent} showOnlyReported={showOnlyReported} isParent={false} isReplyWithReportsInMyTree={isParentWithReportsInMyTree} />
          })}
        </div>
      }
    </>
  )
}

