import { dashApi } from "../api";
import { Attachment, Article } from "../../domain";
import { CMSFeedDetails, CmsItem, CmsItemDetailed, CmsItemDetailedWithOtherVariations, CmsStage } from "../pages/Content/subpages/cms/CmsDomain";
import { AxiosPromise } from "axios";
import qs from 'query-string';


export const getCmsArticles = (magazine: number, feed: number): Promise<any> => {
    return dashApi.get(magazine, `feed/${feed}/cmsArticles`);
}

export const saveCmsArticle = (magazine: number, feed: number, article: Article, categories: string[], publicationDate: Date | null, featuredImage: string, featuredImageAltText: string, attachments: Attachment[]): Promise<any> => {
    return dashApi.post(magazine, `feed/${feed}/cmsArticles/save`, { article, categories, publicationDate, featuredImage, featuredImageAltText, attachments });
}

export const getCmsSources = (magazine: number, feed: number): Promise<any> => {
    return dashApi.get(magazine, `feed/${feed}/cmsSources`);
}
export const getCmsTags = (magazine: number, feed: number): Promise<any> => {
    return dashApi.get(magazine, `feed/${feed}/cmsTags`);
}

export const updateCmsArticleCategories = (magazine: number, article: number, includeCategories: number[], excludeCategories: number[]): Promise<any> => {
    return dashApi.post(magazine, `cmsArticles/${article}/updateCategories`, { includeCategories, excludeCategories });
}

export const getFeedVariations = (magazine: number, feed: number): Promise<any> => {
    return dashApi.get(magazine, `feed/${feed}/variations`);
}

export const getArticleVariations = (magazine: number, feed: number, article: number): Promise<any> => {
    return dashApi.get(magazine, `feed/${feed}/variations/article/${article}`);
}

export const getEvents = (magazine: number, feed: number): Promise<any> => {
    return dashApi.get(magazine, `feed/${feed}/cmsEvents`);
}

export const getCmsFeed = (magazine: number, feed: number): Promise<any> => {
    return dashApi.get(magazine, `feed/${feed}`);
}

export const getCmsCategories = (magazine: number, feed: number): Promise<any> => {
    return dashApi.get(magazine, `feed/${feed}/categories`);
}
export const createCmsCategory = (magazine: number, feed: number, name: string, parent?: number): Promise<any> => {
    return dashApi.post(magazine, `feed/${feed}/categories`, { name, parent });
}
export const updateCmsCategory = (magazine: number, feed: number, id: number, name: string, parent?: number): Promise<any> => {
    return dashApi.post(magazine, `feed/${feed}/categories/${id}`, { name, parent });
}

export const deleteCmsCategory = (magazine: number, feed: number, id: number): Promise<any> => {
    return dashApi.delete(magazine, `feed/${feed}/categories/${id}`);
}

export const getCmsFeedDetails = (magazine: number, feed: number): AxiosPromise<CMSFeedDetails> => {
    return dashApi.get(magazine, `feed/${feed}/details`);
}

export const saveCmsFeedDetails = (magazine: number, feed: number, feedDetails: CMSFeedDetails): AxiosPromise<CMSFeedDetails> => {
    return dashApi.post(magazine, `feed/${feed}/details`, { feedDetails });
}

export const deleteCmsFeed = (magazine: number, feed: number): AxiosPromise<void> => {
    return dashApi.delete(magazine, `feed/${feed}`);
}

// ─── CMS Items Endpoints ───────────────────────────────────────────────────────────


export const getCmsItems = (magazine: number, feed: number, cmsStage: CmsStage, agendaMode?: boolean): AxiosPromise<CmsItem[]> => {
    return dashApi.get(magazine, `feed/${feed}/cms/${cmsStage}${agendaMode ? '?agendaMode=true' : ''}`);
}

export const getCmsItem = (magazine: number, feed: number, cmsStage: CmsStage, external_id: string, variation?: string): AxiosPromise<CmsItemDetailedWithOtherVariations> => {
    return dashApi.get(magazine, `feed/${feed}/cms/${cmsStage}/${encodeURIComponent(external_id)}${variation ? `?${qs.stringify({ variation })}` : ''}`);
}
export const getCmsItemVariationsLangs = (magazine: number, feed: number, cmsStage: CmsStage, external_id: string): AxiosPromise<string[]> => {
    return dashApi.get(magazine, `feed/${feed}/cms/${cmsStage}/${encodeURIComponent(external_id)}/variationsLangs`);
}

export const deleteCmsDraft = (magazine: number, feed: number, external_id: string, variation?: string): AxiosPromise<void> => {
    return dashApi.delete(magazine, `feed/${feed}/cms/drafts/${encodeURIComponent(external_id)}${variation ? `?${qs.stringify({ variation })}` : ''}`);
}

export const saveCmsItem = (magazine: number, feed: number, cmsStage: CmsStage, item: CmsItemDetailed, isDefault: boolean): AxiosPromise<CmsItemDetailedWithOtherVariations> => {
    return dashApi.post(magazine, `feed/${feed}/cms/${cmsStage}`, { item, isDefault });
}

export const previewCmsItem = (magazine: number, feed: number, cmsStage: CmsStage, item: CmsItemDetailed): AxiosPromise<CmsItemDetailedWithOtherVariations> => {
    return dashApi.post(magazine, `feed/${feed}/cms/${cmsStage}/preview`, { item });
}

export const publishCmsDraft = (magazine: number, feed: number, draft: CmsItemDetailed): AxiosPromise<{ publishedExternalId: string }> => {
    return dashApi.post(magazine, `feed/${feed}/cms/drafts/publish`, { draft });
}

export const duplicateCmsItem = (magazine: number, feed: number, author: string, cmsStage: CmsStage, external_id: string, otherVariations: string[]): AxiosPromise<{ duplicateItemId: string }> => {
    return dashApi.post(magazine, `feed/${feed}/cms/${cmsStage}/${encodeURIComponent(external_id)}/duplicate`, { author, otherVariations });
}