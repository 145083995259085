import React from 'react';
import styles from './NcSettingsConfiguration.module.scss';
import DataTable, { ExtendedColumnDescription } from '../../../../../components/DataTable/DataTable';
import _ from 'lodash';
import { Control, FieldErrors, FieldPath } from 'react-hook-form';
import { NcSettingsFormData } from './NcSettingsConfiguration';
import { NcSettingsColorInput } from '../../../components/NcSettingsColorInput';

interface ColorsDataItemProps {
  name: string
  default: FieldPath<NcSettingsFormData>
  alternative: FieldPath<NcSettingsFormData>
}

interface Props {
  control: Control<NcSettingsFormData, any>,
  errors: FieldErrors<NcSettingsFormData>
}

export const NcSettingsConfigurationColors = ({ control, errors }: Props) => {

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'name',
      text: 'color',
      formatter: (cell: string, row: any) => {
        return _.capitalize(cell);
      }
    },
    {
      dataField: 'default',
      headerStyle: {
        width: '180px'
      },
      classes: styles.colorPickerTd,
      text: 'default',
      formatter: (fieldPath: FieldPath<NcSettingsFormData>) => {
        return (
          <NcSettingsColorInput {...{ control }} fieldName={fieldPath} />
        )
      }
    },
    {
      dataField: 'alternative',
      headerStyle: {
        width: '180px'
      },
      classes: styles.colorPickerTd,
      text: 'alternative',
      formatter: (fieldPath: FieldPath<NcSettingsFormData>) => {
        return (
          <NcSettingsColorInput {...{ control }} fieldName={fieldPath} />
        )
      }
    },
  ];

  const data: ColorsDataItemProps[] = [
    {
      name: 'theme',
      default: 'colors.themeColor',
      alternative: 'colors.alternativeThemeColor',
    },
    {
      name: 'background',
      default: 'colors.boxBackgroundColor',
      alternative: 'colors.alternativeBoxBackgroundColor'
    },
    {
      name: 'title',
      default: 'colors.titleTextColor',
      alternative: 'colors.alternativeTitleTextColor'
    },
    {
      name: 'body',
      default: 'colors.bodyTextColor',
      alternative: 'colors.alternativeBodyTextColor'
    },
    {
      name: 'meta',
      default: 'colors.metaTextColor',
      alternative: 'colors.alternativeMetaTextColor'
    },
    {
      name: 'hashtag',
      default: 'colors.hashtagTextColor',
      alternative: 'colors.alternativeHashtagTextColor'
    },
    {
      name: 'mentions',
      default: 'colors.mentionTextColor',
      alternative: 'colors.alternativeMentionTextColor'
    },
    {
      name: 'link',
      default: 'colors.linkTextColor',
      alternative: 'colors.alternativeLinkTextColor'
    },
    {
      name: 'progress bar',
      default: 'colors.barColor',
      alternative: 'colors.alternativeBarColor'
    },
    {
      name: 'progress bar background',
      default: 'colors.barBackgroundColor',
      alternative: 'colors.alternativeBarBackgroundColor'
    },
  ]

  return (
    <div>
      <div>
        Colors are divided into two sets. The default one and the alternative one. Only one set is used each time.
      </div>
      <div className={styles.colorsTableWrapper}>
        <DataTable
          data={data}
          columns={columns}
          keyField={'id'}
          hideSearchBar
          noPagination
          onRowClick={() => { }}
          noHover
        />
      </div>
    </div>
  )
}
