import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Label, Button, CustomInput, Alert, Badge } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';
import * as Api from '../../../../api';
import { Profile, Audience, Form, FormConfig, StatsFilter } from '../../../../../domain';

import './css/FormResults.min.css';
import { Translations } from '../../../../translations';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import classnames from 'classnames';
import Toggle from 'react-toggle';
import "react-toggle/style.css"

import * as FormsHelper from './FormsHelper';
import { SwalError, SwalSuccess, OrtecLoader } from '../../../../widgets';

import axios from 'axios';
import FormGenericConfig from './FormGenericConfig';
import FormBuilder from './FormBuilder';
import FormResultsDetailed from './FormResultsDetailed';
import FormResultsOverall from './FormResultsOverall';
import { FilterBar } from './FilterBar';

const FileDownload = require('js-file-download');



const emptyForm = { ...FormsHelper.emptyForm };


export interface Props {
  magazine: string,
  profile: Profile,
  formId: number,
  closeHandler: (updateOverview?: boolean) => void
}

export interface State {
  form: Form,
  statsFilter: StatsFilter,
  notFound?: boolean,
  loading: boolean,
  results: any[],
  exportLoading: boolean,
  progressTotal: number,
  exportProgress: number,
  quizSuccesses?: string[]

}

export class FormResults extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      statsFilter: {},
      form: emptyForm,
      results: [],
      exportLoading: false,
      progressTotal: 0,
      exportProgress: 0,
    }
  }

  componentDidMount() {
    if (this.props.formId == -1) {
      this.setState({ loading: false })
    } else {
      Promise.all([Api.getForm(parseInt(this.props.magazine), this.props.formId), Api.getFormResults(parseInt(this.props.magazine), this.props.formId)]).then((resp) => {
        if (!resp[0].data || resp[0].data.error || !resp[1].data || resp[1].data.error) {
          console.log('Error in getting source');
          this.setState({ form: emptyForm, results: [], loading: false, notFound: true });
        }
        let config;
        try {
          config = JSON.parse(resp[0].data.config);
        } catch (error) {
          config = null;
        }
        const newForm = { ...resp[0].data, config: config };
        this.setState({ form: newForm, results: resp[1].data, loading: false }, () => this.calcQuizSuccesses());
      })
        .catch((error) => {
          console.log('An error occured:' + error.message)
          this.setState({ form: emptyForm, results: [], loading: false, notFound: true });
        });
    }
  }

  getForm = () => {
    this.setState({ loading: true });
    Api.getForm(parseInt(this.props.magazine), this.props.formId).then((resp) => {

      if (!resp.data || resp.data.error) {
        console.log('Error in getting source');
        this.setState({ form: emptyForm, loading: false, notFound: true });
      }
      let config;
      try {
        config = JSON.parse(resp.data.config);
      } catch (error) {
        config = null;
      }
      const newForm = { ...resp.data, config: config };
      this.setState({ form: newForm, loading: false });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
        this.setState({ form: emptyForm, loading: false, notFound: true });
      });
  }

  getResults = () => {
    const { statsFilter } = this.state;
    this.setState({ loading: true });
    Api.getFormResults(parseInt(this.props.magazine), this.props.formId, statsFilter.audience ? statsFilter.audience : undefined).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting resutls');
        this.setState({ results: [], loading: false, notFound: true });
      }

      this.setState({ results: resp.data, loading: false, notFound: false }, () => this.calcQuizSuccesses());
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
        this.setState({ results: [], loading: false, notFound: true });
      });
  }

  calcQuizSuccesses = () => {
    const { form, results } = this.state;
    if (form.type !== 'quiz') {
      return;
    }
    const passed: string[] = [];
    _.map(results, (r, ind) => {
      let correctResponses = 0;
      _.map(form.config.questions, (q, index) => {
        if (!_.includes([1, 7, 9], q.type)) {
          const response = _.find(r.responses, ['id', q.id]);
          if (response && response.response == q.correctAnswer) {
            correctResponses++;
          }
        }
      })
      if (correctResponses >= _.get(form.config, 'config.requiredCorrectAnswers', 0)) {
        passed.push(r.uid);
      }
    })
    this.setState({ quizSuccesses: passed });
  }


  closeHandler = () => {
    this.props.closeHandler();
  }

  periodFilterHandler = (value: string) => {
    const statsFilter = { ...this.state.statsFilter };
    statsFilter.period = value;
    this.setState({ statsFilter: statsFilter });
  }
  audienceFilterHandler = (value: number) => {
    const statsFilter = { ...this.state.statsFilter };
    statsFilter.audience = value;
    this.setState({ statsFilter: statsFilter }, () => { this.getResults() });
  }

  exportHandler = (mode: string) => {
    const { statsFilter } = this.state;
    this.setState({ exportLoading: true }, () => {
      Api.exportFormResults(parseInt(this.props.magazine), this.props.formId, mode, statsFilter.audience)
        .then((resp) => {
          const data = resp.data;
          FileDownload(data, `mag-${this.props.magazine}-form-${this.props.formId}-aud-${statsFilter.audience ? statsFilter.audience : 1}-results-${mode}.csv`);
          this.setState({ exportLoading: false })
        })
        .catch((error) => {
          console.log(error.message);
          SwalError.fire({
            // type: 'error',
            title: 'Something went wrong!',
            text: `Error in exporting results of form: ${this.props.formId}`,
            footer: `<div class="alert alert-danger">${error.message}</div>`
          });
          this.setState({ exportLoading: false });
        });
    });
  }

  render() {
    const { magazine, formId, profile } = this.props;
    const { form, results, statsFilter, loading, notFound, quizSuccesses } = this.state;
    const tr = Translations.Translate(Translations, 'Forms');

    // const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>;
    const notFoundMessage = <div className="notFoundContainer alert alert-danger">{tr('formNotFound')}</div>;

    return (
      <div id="FormResults">
        {Translations.EnableTranslations(Translations)}
        <div className='header'>
          {/* <i className="material-icons closeIcon" onClick={this.closeHandler.bind(this)}>close</i> */}
          <img src="/assets/icons/bt_close_bigger.svg" onClick={this.closeHandler.bind(this)} style={{ cursor: 'pointer' }} />
          {loading || notFound ? '' :
            <Fragment>
              <div className="headerTitle">{`${form.title}`}</div>
              <FilterBar magazine={magazine} results={results.length} statsFilter={statsFilter} /*periodFilterHandler={this.periodFilterHandler.bind(this)}*/ audienceFilterHandler={this.audienceFilterHandler.bind(this)} exportHanlder={this.exportHandler.bind(this)} audiences={profile.audiences ? profile.audiences : []} globalAudience={profile.globalAudience} />
            </Fragment>
          }
        </div>
        <div className='content simple'>
          {loading ? <OrtecLoader /> :
            notFound ? notFoundMessage :
              <Fragment>
                <div className="contentRow" style={{}}><FormResultsOverall magazine={parseInt(magazine)} form={form} results={results} quizSuccesses={quizSuccesses} /></div>
                <div className="contentRow" style={{}}><FormResultsDetailed magazine={parseInt(magazine)} form={form} results={results} quizSuccesses={quizSuccesses} /></div>
              </Fragment>
          }
        </div>
      </div>
    );
  }
}

export default FormResults;
