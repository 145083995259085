
export function jsonParse(s: string | undefined | null): object | undefined {
  if (!s) {
    return undefined;
  }
  try {
    return JSON.parse(s)
  } catch (e) {
    console.error('could not parse json');
    return undefined;
  }
}

export function jsonStringify(o: object | undefined | null, formatted?: boolean): string | undefined {
  if (!o) {
    return undefined;
  }
  try {
    return formatted ? JSON.stringify(o, null, 2) : JSON.stringify(o)
  } catch (e) {
    console.error('could not stringify json');
    return undefined;
  }
}

export function isValidJson(value: string | undefined): boolean {
  try {
    if (!value) {
      return false;
    }
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}