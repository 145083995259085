import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import styles from './AudienceBuilder.module.scss';
import { Audience, AudienceCount, BuilderQueryOperator, Condition } from '../domain';
import * as BuilderHelper from './BuilderHelper';
import Toggle from 'react-toggle';

import Creatable from 'react-select/creatable';
import { FieldConfig } from '../../People/domain';
import { EdErrorHandler, OrtecLoader } from '../../../../../widgets';
import { API } from '../../../../../apis';
import { ConditionToolbar, OperatorToolbar, UnsupportedQueryBox, AddConditionToolbar, ConditionBox } from './subcomponents';
import { Profile } from '../../../../../../domain';
import { AccessRightsHelper } from '../../../../../utils';

export { BuilderHelper, styles };

const DEFAULT_AUDIENCE_FIELDS = [
  'uid',
  'accounts.0.username',
  'attributes.email.work',
  'attributes.company',
  'attributes.groups',
  'preferences.groups',
  'preferences.language',
  'attributes.jobTitle'
];

const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
  tab: 9,
}

const delimiters = [
  // KeyCodes.comma,
  // KeyCodes.space,
  KeyCodes.enter,
  KeyCodes.tab,
];
interface Props {
  magazine: number,
  profile: Profile,
  audience: Audience,
  onQueryChange: (query: Object) => void
}

export const AudienceBuilder = ({ magazine, profile, audience, onQueryChange }: Props) => {

  const [loadingPeopleConfig, setLoadingPeopleConfig] = useState(true);
  const [peopleConfig, setPeopleConfig] = useState<FieldConfig[]>([]);
  const [loadingCount, setLoadingCount] = useState(false);
  const [count, setCount] = useState<AudienceCount>()

  const loading = loadingPeopleConfig;

  const { edConfig } = profile;

  useEffect(() => {
    loadPeopleConfig();
    recalcCount(audience.query);
  }, [])

  const loadPeopleConfig = async () => {
    try {
      setLoadingPeopleConfig(true);
      const { data } = await API.people.loadPeopleConfig(magazine);
      setPeopleConfig(data);
    } catch (error) {
      EdErrorHandler(error, `loading people config`);
    } finally {
      setLoadingPeopleConfig(false);
    }
  }
  const recalcCount = async (q: Object) => {
    try {
      setLoadingCount(true);
      const { data } = await API.audiences.getQueryCount(magazine, q);
      setCount(data);
    } catch (error) {
      // EdErrorHandler(error, `recalculating audience count`);
      setCount(undefined)
    } finally {
      setLoadingCount(false);
    }
  }

  const query = _.omit(audience.query, 'magazine') as Object;

  const isQuerySupported = BuilderHelper.isQuerySupported(query);

  const isAdmin = (AccessRightsHelper.getUserRole(profile) === 'admin') ? true : false;

  const queryOperator = BuilderHelper.getQueryOperator(query);
  const expressions = BuilderHelper.getQueryExpressions(query);


  const onAddNewCondition = () => {
    const q = { [queryOperator]: [...expressions, BuilderHelper.convertElementToMongoExpression(BuilderHelper.defaultElementExpression())] };
    recalcCount(q);
    onQueryChange(q);
  }

  const onChangeQueryOperator = (op: BuilderQueryOperator) => {
    const q = { [op]: expressions }
    recalcCount(q);
    onQueryChange(q)
  }
  const onChangeCondition = (elementIndex: number, condition: Condition) => {
    const q = {
      [queryOperator]: _.map(expressions, (el, index) => {
        return (index == elementIndex) ? BuilderHelper.convertElementToMongoExpression(condition, BuilderHelper.getFieldType(condition.key, peopleConfig)) : el;
      })
    }
    recalcCount(q);
    onQueryChange(q);
  }

  const onRemoveCondition = (index: number) => {
    const q = {
      [queryOperator]: _.compact(_.map(expressions, (el, idx) => {
        if (index != idx) {
          return el;
        }
      }))
    }
    recalcCount(q);
    onQueryChange(q);
  }

  return (
    <div className={styles.AudienceBuilder}>
      <div className={styles.builderTitle}>Build Audience</div>
      {loading ? <OrtecLoader /> :
        <Fragment>
          <OperatorToolbar {...{ queryOperator, onChangeQueryOperator, loading: loadingCount, count }} unsupportedQuery={!isQuerySupported} noRights={!isAdmin} />
          {!isQuerySupported && <UnsupportedQueryBox {...{ query }} />}
          {isQuerySupported && _.map(expressions, (expr, index) => {
            return (
              <Fragment key={`ef-${index}`}>
                {index > 0 &&
                  <ConditionToolbar key={`ct-${index}`} {...{ index, builderQueryOperator: queryOperator, onRemove: onRemoveCondition, noRights: !isAdmin }} />}
                <ConditionBox key={`cb-${index}`} condition={BuilderHelper.getExpressionAsCondition(expr)} index={index} peopleConfig={peopleConfig} fields={edConfig?.fieldsInAudiences || DEFAULT_AUDIENCE_FIELDS} onChangeCondition={onChangeCondition} noRights={!isAdmin} />
              </Fragment>
            )
          })
          }
          {isQuerySupported && <AddConditionToolbar {...{ onAddNewCondition, noRights: !isAdmin }} />}
        </Fragment>
      }
    </div>
  )
}
