import classNames from 'classnames';
import React, { Fragment } from 'react'
import { Button } from 'reactstrap'
import styles from './ToggleButton.module.scss';

interface Props {
  color: string
  onClick: () => void
  disabled?: boolean
  style?: React.CSSProperties
  className?: string
  children?: React.ReactElement<any>
  active?: boolean
}

export const ToggleButton = ({ color, onClick, disabled, style, className, children, active }: Props) => {

  const outline = (color == 'primary') ? false : true;

  return (
    <Button style={style} color={color} className={classNames(className, 'btn-lg', styles.ToggleButton, { [styles.active]: active })} disabled={disabled} onClick={onClick} outline={outline}>
      {children}
    </Button>
  )
}
