import React, { PropsWithChildren } from 'react';
import { Media } from '../../../../domain'
import { MediaHelper } from '../../../utils'
import _ from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import { downloadMediaItem } from '../MediaManagerSelectorHelper';

import styles from './MediaBucketExplorerGridFile.module.scss'
import TippyReact from '../../TippyReact';
import classnames from 'classnames';


interface Props {
  magazine: number
  mediaItem: Media,
  onMediaDelete: (media: Media) => void
  selected?: boolean
  onSelect?: (media: Media) => void
}

export default function MediaBucketExplorerGridFile(props: Props) {
  const { magazine, mediaItem, onMediaDelete, selected, onSelect } = props

  const [copying, setCopying] = React.useState(false);

  const copyUrl = () => {
    setCopying(true);
    setTimeout(() => {
      setCopying(false);
    }, 1000);
  }

  const filename = MediaHelper.convertPlusToSpaces(mediaItem.fullName)
  // const filename =`${MediaHelper.convertPlusToSpaces(mediaItem.name)}.${mediaItems.extension}`
  const downloadUrl = MediaHelper.proxify(magazine, mediaItem.url)

  const onClick = onSelect
    ? () => { onSelect(mediaItem) }
    : undefined


  return <div className={styles.item}>
    <Wrapper selected={selected} onClick={onClick}>
      <ItemThumb magazine={magazine} mediaItem={mediaItem} />
    </Wrapper>
    <div className={styles.filename}>{filename}</div>
    <div className={styles.actions}>
      {copying && <span className={styles.copyNotification}>Copied!</span>}
      {!mediaItem.integration?.id &&
        <ActionButton tooltip='Download' icon="/assets/icons/16/download.svg" onClick={() => { downloadMediaItem(downloadUrl, filename) }} />
      }
      <CopyToClipboard text={mediaItem.url}>
        <ActionButton tooltip='Copy link' icon="/assets/icons/16/link.svg" onClick={() => { copyUrl() }} />
      </CopyToClipboard>

      <ActionButton tooltip='Delete' icon="/assets/icons/16/delete.svg" onClick={() => { onMediaDelete(mediaItem) }} />
    </div>
  </div>
}

const Wrapper = (props: PropsWithChildren<{ onClick?: () => void, selected?: boolean }>) => {
  const selectable = props.onClick !== undefined

  return props.onClick
    ? <button
      onClick={props.onClick}
      className={classnames(styles.thumb, {
        [styles.selectable]: selectable,
        [styles.selected]: props.selected
      })}
    >
      {props.children}</button>
    : <div className={styles.thumb}>{props.children}</div>
}



function ActionButton(props: {
  tooltip: string
  icon: string
  onClick: () => void
}) {
  return <TippyReact content={props.tooltip}>
    <button className={styles.button} onClick={() => { props.onClick() }}>
      <img src={props.icon} loading="lazy"/>
    </button>
  </TippyReact>
}


function ItemThumb(props: { magazine: number, mediaItem: Media }) {
  const { integration, extension, url } = props.mediaItem
  if (integration) {
    return integration?.embed
      ? <div dangerouslySetInnerHTML={{ __html: integration.embed }}></div>
      : <ImgThumb src={'/assets/icons/vimeoLogo.png'} />
  }
  const link = MediaHelper.proxify(props.magazine, url, 300)
  if (MediaHelper.isImageExtension(extension)) {
    if (_.includes(['heic', 'heif',], _.toLower(extension))) {
      return <ImgThumb src={'/assets/icons/image.svg'} />
    }
    return <ImgThumb src={link} />
  }
  if (MediaHelper.isAudioExtension(extension)) {
    return <audio controls style={{ width: '100%' }} src={link} preload={'none'}></audio>
  }
  if (MediaHelper.isVideoExtension(extension)) {
    return <video controls style={{ width: '100%' }} src={link} poster={link} preload={'none'}></video>
  }
  switch (_.toLower(extension)) {
    case 'pdf':
      return <ImgThumb src={'/assets/icons/attachment_pdf.svg'} />
    default:
      return <ImgThumb src={'/assets/icons/attachment.svg'} />
  }


}

function ImgThumb(props: { src: string }) {
  return <img src={props.src} className={styles.img} loading={'lazy'} />
}