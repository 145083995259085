import _ from 'lodash';
import React, { useDebugValue, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router'
import { Col, Input, Label, Row } from 'reactstrap';
import { Profile, StatsFilter } from '../../../../../domain';
import { API } from '../../../../apis';
import { DashBarChart, DashLineChart } from '../../../../charts';
import { FilterContextConsumer } from '../../../../FilterContext';
import { Translations } from '../../../../translations';
import { ChartsHelper, GroupBy } from '../../../../utils/ChartsHelper';
import { FilterBarWithRouter as FilterBar } from '../../../Overview/FilterBar';
import { StatBox } from '../../../Overview/StatBox';


import './css/ScreenAndReadTimeChart.min.css';

export interface Props {
    magazine: number,
    statsFilter: StatsFilter,
}

type NumberChartValue = string | number | undefined;

export default function ScreenAndReadTimeChart(props: Props) {
    const { magazine, statsFilter } = props;

    const [avgVisitDuration, setAvgVisitDuration] = useState<NumberChartValue>(undefined);

    const [activeGroupBy, setActiveGroupBy] = useState<GroupBy>(ChartsHelper.decideGroupBy(statsFilter.period));

    useEffect(() => {
        calcAvgVisitDuration();
    }, [])

    const tr = Translations.Translate(Translations, 'GroupFilter');

    const calcAvgVisitDuration = async () => {
        try {
            setAvgVisitDuration(undefined);
            const { data: resp } = await API.analytics.loadChartData('avgVisitDuration', magazine, statsFilter);
            setAvgVisitDuration(resp.data);
        } catch (error) {
            console.error('An error occured:' + error.message);
            setAvgVisitDuration('!');
        }
    }

    const needsBarsChart = (groupBy: GroupBy): boolean => {
        return _.includes(['hour', 'weekday'], groupBy);
    }
    const needsLineChart = (groupBy: GroupBy): boolean => {
        return _.includes(['day', 'month'], groupBy);
    }

    const drawChart = (groupBy: GroupBy) => {
        const capitalizedGroupBy = _.capitalize(groupBy);
        const chartProps = {
            key: `screenTimePer${capitalizedGroupBy}`,
            valueName: `avg_visit_duration`,
            chart: `screenTimePer${capitalizedGroupBy}`,
            secondaryValueName: `avg_time_reading_an_article`,
            secondaryChart: `readTimePer${capitalizedGroupBy}`,
            title: `avg_visit_duration_time_reading_per_${groupBy}`,
            disableAnimation: needsBarsChart(groupBy) ? false : true,
            fillMissingValues: needsLineChart(groupBy) ? groupBy : undefined,
            yAxisType: 'time',
            magazine,
            statsFilter,
            groupBy,
        }

        if (needsBarsChart(groupBy)) {
            return <DashBarChart {...chartProps} />
        }

        return <DashLineChart {...chartProps} avgValue={_.toNumber(avgVisitDuration)} />
    }

    const drawChartPanel = (groupBy: GroupBy) => {
        const isDaily = ChartsHelper.isDailyPeriod(statsFilter.period);
        return (
            <div className='chartPanel'>
                <div className='chartMetrics'>
                    <div className='chartMetric primary'>
                        <span className='metricColor'><i className='fa fa-circle'></i></span>
                        <span className='metricLabel'>Avg. visit duration</span>
                    </div>
                    <div className='chartMetric secondary'>
                        <span className='metricColor'><i className='fa fa-circle'></i></span>
                        <span className='metricLabel'>Avg. time reading</span>
                    </div>

                </div>
                <div className='separator'></div>
                <div className='chartControl'>
                    <div className="groupByFilter">
                        <div className="label">{tr('groupBy')}:</div>
                        <Input type="select" value={isDaily ? 'hour' : groupBy} onChange={(e) => { setActiveGroupBy(e.target.value as GroupBy) }}>
                            <option value="hour">{tr('hour')}</option>
                            {!isDaily &&
                                <option value="weekday">{tr('weekdays')}</option>
                            }
                            {!isDaily &&
                                (ChartsHelper.isPeriodForMonthlyGroup(statsFilter.period) ?
                                    <option value="month">{tr('month')}</option> :
                                    <option value="day">{tr('days')}</option>
                                )}
                        </Input>
                    </div>
                </div>
            </div>
        )
    }

    const chartGroupBy: GroupBy = ChartsHelper.isDailyPeriod(statsFilter.period) ? 'hour' : activeGroupBy;

    return (
        <div id="ScreenAndReadTimeChart" className="chartContainer">
            {drawChartPanel(chartGroupBy)}
            {drawChart(chartGroupBy)}
        </div>
    )

}
