import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import BootstrapTable, { ExpandColumnRendererProps, ExpandRowProps } from 'react-bootstrap-table-next';
import { RouteComponentProps } from 'react-router-dom';
import { Profile } from '../../../../../domain';
import { API } from '../../../../apis';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { EdErrorHandler, OrtecLoader, SwalDelete } from '../../../../widgets';
import styles from './Categories.module.scss';
import dataTableStyles from '../../../../components/DataTable/DataTable.module.scss';
import { CmsCategory, CmsCategoryObject } from './domain';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Button, Col, Row } from 'reactstrap';
import { CmsFeedSelector } from '../cms/CmsFeedSelector/CmsFeedSelector';
import _ from 'lodash';
import { CreateCategoryModal } from './CreateCategoryModal';
import { FaRegSnowflake } from 'react-icons/fa';
import { AccessRightsHelper, CategoriesHelper } from '../../../../utils';
export interface Props {
  magazine: number,
  profile: Profile,
  activeFeed: number,
  activeFeedLabel: string,
  setFeed: (feed: number) => void
  updateProfile: () => void

}

export const Categories = ({ magazine, profile, activeFeed, activeFeedLabel, setFeed, updateProfile }: Props) => {

  const [initLoading, setInitLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [movingCategory, setMovingCategory] = useState<CmsCategoryObject>();
  const [categories, setCategories] = useState<CmsCategory[]>([]);
  const [creatingCategory, setCreatingCategory] = useState<boolean>(false);
  const [creatingCategoryParent, setCreatingCategoryParent] = useState<CmsCategory>();

  const loading = dataLoading || createLoading || updateLoading || deleteLoading;

  useEffect(() => {
    initLoad();
  }, []);

  const initLoad = async () => {
    setInitLoading(true);
    await loadCategories();
    setInitLoading(false);
  }

  const loadCategories = async () => {
    try {
      setDataLoading(true);
      const { data } = await API.cms.getCmsCategories(magazine, activeFeed);
      setCategories(CategoriesHelper.excludeDefaultFeedCategoryFromCategories(data, activeFeedLabel));
    } catch (error) {
      EdErrorHandler(error, `getting categories`);
    } finally {
      setDataLoading(false);
    }
  }

  const createCategory = async (name: string, parent?: number) => {
    try {
      setCreateLoading(true);
      const { data } = await API.cms.createCmsCategory(magazine, activeFeed, name, parent);
    } catch (error) {
      EdErrorHandler(error, `creating a category`);
    } finally {
      setCreateLoading(false);
    }
  }

  const updateCategory = async (id: number, name: string, parent?: number) => {
    try {
      setUpdateLoading(true);
      const { data } = await API.cms.updateCmsCategory(magazine, activeFeed, id, name, parent);
    } catch (error) {
      EdErrorHandler(error, `updating a category`);
    } finally {
      setUpdateLoading(false);
    }
  }

  const deleteCategory = async (id: number) => {
    try {
      setDeleteLoading(true);
      const { data } = await API.cms.deleteCmsCategory(magazine, activeFeed, id);
    } catch (error) {
      EdErrorHandler(error, `deleting a category`);
    } finally {
      setDeleteLoading(false);
    }
  }


  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'category.id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'category.name',
      sort: false,
      text: ''
    },
    {
      dataField: 'category.articles',
      sort: false,
      // isDummyField: true,
      text: '',
      headerStyle: { width: '100px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img data-tippy-content={'Articles'} src={'/assets/icons/16/publications.svg'} /><span></span></Fragment>
      },
      formatter: (cell, row) => {
        return _.toInteger(cell);
      }
    },
    {
      dataField: 'children',
      hidden: true,
      sort: false,
      // isDummyField: true,
      text: '',
      // headerStyle: { width: '100px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img data-tippy-content={'Articles'} src={'/assets/icons/16/publications.svg'} /><span></span></Fragment>
      },
      formatter: (cell: CmsCategoryObject[], row) => {
        return _.isEmpty(cell) ? '' : _.map(cell, (c) => c.name).join()
      }
    },
    {
      dataField: 'dfActions',
      sort: false,
      isDummyField: true,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatExtraData: {
        movingCategoryId: movingCategory?.id
      },
      formatter: (cell, row: CmsCategory, rowIndex, formatExtraData) => {
        if (formatExtraData.movingCategoryId) {
          if (formatExtraData.movingCategoryId == row.category.id) {
            return (
              <div className='actionHoveringDiv'>
                <Button color={'secondary'} onClick={() => exitMovingMode()}>exit sorting mode</Button>
              </div>
            )
          }
          return (
            <div className='actionHoveringDiv'>
              <Button color={'secondary'} onClick={() => { moveInto(row.category.id) }}>child of</Button>
            </div>
          )
        }
        return (
          <div className='actionHoveringDiv'>
            {_.isEmpty(row.children) && !row.category.articles && <Button style={{ marginRight: '10px' }} color={'secondary'} onClick={() => { onDelete(row.category) }}>Delete</Button>}
            {_.isEmpty(row.children) && <Button style={{ marginRight: '10px' }} color={'secondary'} onClick={() => { startMovingMode(row.category) }}>Move</Button>}
            <Button color={'secondary'} onClick={() => openCreateNewCategory(row)}>Create child category</Button>
          </div>
        )

      },
    }
  ];

  const openCreateNewCategory = (parent?: CmsCategory) => {
    setCreatingCategoryParent(parent);
    setCreatingCategory(true);
  }

  const closeCreateNewCategory = () => {
    setCreatingCategoryParent(undefined);
    setCreatingCategory(false);
  }

  const onCreateNewCategory = async (name: string, parentCategory: CmsCategory) => {
    await createCategory(name, parentCategory?.category.id);
    loadCategories();
    closeCreateNewCategory();
  }

  const onDelete = async (category: CmsCategoryObject) => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      text: `This action will delete category <${category.name}>.`,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
    });
    if (!confirm) {
      return;
    }
    await deleteCategory(category.id);
    loadCategories();
  }

  const moveInto = async (parentId?: number) => {
    if (!movingCategory) {
      return;
    }
    await updateCategory(movingCategory.id, movingCategory.name, parentId);
    exitMovingMode();
    loadCategories();
  }

  const startMovingMode = (category: CmsCategoryObject) => {
    setMovingCategory(category);
  }
  const exitMovingMode = () => {
    setMovingCategory(undefined);
  }

  const expandColumns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'arrow',
      isDummyField: true,
      text: '',
      align: 'right',
      headerStyle: { width: '70px' },
      formatter: (cell, row) => {
        return <Fragment><img src={'/assets/icons/16/child_arrow.svg'} /><span></span></Fragment>;
      }
    },
    {
      dataField: 'name',
      sort: false,
      text: '',
    },
    {
      dataField: 'articles',
      sort: false,
      text: '',
      headerStyle: { width: '100px' },
      align: 'center',
    },
    {
      dataField: 'dfActions',
      sort: false,
      isDummyField: true,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatExtraData: {
        movingCategoryId: movingCategory?.id
      },
      formatter: (cell, row: CmsCategoryObject, rowIndex, formatExtraData) => {
        if (!formatExtraData.movingCategoryId) {
          return (
            <div className='actionHoveringDivInExpanding'>
              {!row.articles && <Button style={{ marginRight: '10px' }} color={'secondary'} onClick={() => { onDelete(row) }}>Delete</Button>}
              {<Button style={{ marginRight: '10px' }} color={'secondary'} onClick={() => { startMovingMode(row) }}>Move</Button>}
            </div>
          )
        } else if (formatExtraData.movingCategoryId == row.id) {
          return (
            <div className='actionHoveringDivInExpanding'>
              {<Button style={{ marginRight: '10px' }} color={'secondary'} onClick={() => { moveInto(undefined) }}>Turn into a parent category</Button>}
              {<Button style={{ marginRight: '10px' }} color={'secondary'} onClick={() => { exitMovingMode() }}>Exit sorting mode</Button>}
            </div>
          )
        }
        return <Fragment />
      }
    },
  ];



  const expandables = _.map(_.filter(categories, (c) => {
    return !_.isEmpty(c.children);
  }), (c) => c.category.id);

  const nonExpandables = _.map(_.filter(categories, (c) => {
    return _.isEmpty(c.children);
  }), (c) => c.category.id);

  const expandRowClassesHandler = (row: CmsCategoryObject, rowIndex: number): string => {
    const rowClasses = ['rowInExpanding'];
    if (movingCategory?.id == row.id) {
      rowClasses.push('movingRow');
    }

    return rowClasses.join(' ');

  }

  const expandRow: ExpandRowProps<any, number> = {
    className: `expandingRow`,
    renderer: (row: CmsCategory) => {
      if (_.isEmpty(row.children)) {
        return <div>hello darkness my old friend</div>;
      }
      return <Fragment>
        <BootstrapTable
          keyField={'id'}
          data={row.children}
          columns={expandColumns}
          wrapperClasses={`expandDataTable`}
          headerWrapperClasses={`hiddenHeader`}
          bootstrap4
          hover
          bordered={false}
          rowClasses={expandRowClassesHandler}

        />
      </Fragment>
    },
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return <Fragment />
    },
    expandColumnRenderer: ({ expanded, expandable }) => {
      if (!expandable) {
        return <Fragment />;
      }
      if (expanded) {
        return <b style={{ fontSize: '24px' }}>-</b>;
      }
      return <b style={{ fontSize: '24px' }}>+</b>;
    },
    showExpandColumn: true,
    expanded: expandables,
    nonExpandable: nonExpandables
  }

  const rowClassesHandler = (row: CmsCategory, rowIndex: number): string => {
    const rowClasses = ['nonExpandingRow'];
    if (movingCategory) {
      if (movingCategory.id == row.category.id) {
        rowClasses.push('movingRow');
      } else {
        rowClasses.push('movingTarget');
      }
    }

    return rowClasses.join(' ');

  }

  const { SearchBar } = Search;

  return (
    <div className={styles.Categories}>
      {initLoading ? <OrtecLoader /> :
        <Fragment>
          <CmsFeedSelector magazine={_.toString(magazine)} feeds={profile.feeds || []} activeFeed={activeFeed} setFeed={setFeed} profile={profile} updateProfile={updateProfile} cssStyle={{ marginBottom: '0px' }} />
          <div className={classNames(dataTableStyles.dataTableContainer)}>
            <ToolkitProvider
              keyField={'category.id'}
              data={categories}
              columns={columns}
              search={{ searchFormatted: true }}
            >{providerProps => (
              <Fragment>
                <Row className={dataTableStyles.toolbar} style={{ marginBottom: 0 }}>
                  <Col md={6} style={{ display: 'flex', alignItems: 'center', paddingTop: '25px', paddingBottom: '10px' }}><div style={{ flex: 1 }}><SearchBar {...providerProps.searchProps} /></div></Col>
                  <Col md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>{<Button color={'primary'} onClick={() => openCreateNewCategory()}>Create New Category</Button>}</Col>
                </Row>
                <BootstrapTable key={`categoriesTable`}
                  {...providerProps.baseProps}
                  wrapperClasses={`table-responsive`}
                  bootstrap4
                  hover
                  bordered={false}
                  noDataIndication={'There is no data to display'}
                  expandRow={expandRow}
                  rowClasses={rowClassesHandler}
                />
              </Fragment>

            )}</ToolkitProvider>
            {loading && <OrtecLoader />}
          </div>
          {creatingCategory && <CreateCategoryModal existingCategories={categories} createHandler={onCreateNewCategory} closeHandler={closeCreateNewCategory} parentCategory={creatingCategoryParent} />}
        </Fragment>
      }
    </div>
  )
}
