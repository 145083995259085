import React, { useMemo, useState } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import * as _ from 'lodash';
import { Translations } from '../../translations';
import { Media, Profile } from '../../../domain';
import { Bucket, MediaType } from './domain';
import styles from './MediaSelector.module.scss';
import useMagazineBuckets from './useMagazineBuckets';
import { createDeleteDialog, useBucketMedia, useDeleteMediaMutation, useMagazineFeedBuckets } from './MediaManagerSelectorHelper';
import { GenericModal } from '../GenericModal/GenericModal';
import GenericModalHeader from '../GenericModal/GenericModalHeader';
import { LoadingButton } from '../../widgets';
import { SingleSelect } from '../ReactSelect/ReactSelect';
import MediaBucketExplorer from './MediaBucketExplorer/MediaBucketExplorer';

interface Props {
  magazine: number,
  profile: Profile,
  dedicatedBucket?: string,
  dedicatedPart?: `public` | `protected`,
  availableBuckets?: Bucket[],
  restrictedMediaTypes?: MediaType[]
  multipleSelection?: boolean
  selectHandler: (mediaUrl: string[], file?: Media[]) => void
  closeHandler: () => void
  showPartSelector?: boolean
}

const partOptions = (['public', 'protected'] as const).map(b => ({ value: b, label: b }))

function initBucket(magazine: number, dedicatedBucket?: string, availableBuckets?: Bucket[]) {
  return dedicatedBucket || _.first(availableBuckets)?.key || _.toString(magazine)
}


export function MediaSelector(props: Props) {

  const { magazine, profile, restrictedMediaTypes, availableBuckets, showPartSelector, multipleSelection, dedicatedBucket, dedicatedPart } = props
  const [selectedUrls, setSelectedUrls] = useState<string[]>([])
  const [selectedFiles, setSelectedFiles] = useState<Media[]>([])
  const [bucket, setBucket] = useState(initBucket(magazine, dedicatedBucket, availableBuckets))

  const [part, setPart] = useState<`public` | `protected`>(
    dedicatedPart
    || (showPartSelector || !dedicatedBucket
      ? `public`
      : ((dedicatedBucket == `${magazine}`) ? 'public' : 'protected')))


  const magazineFeedBucketsQ = useMagazineFeedBuckets(magazine)
  const allBuckets = useMagazineBuckets(magazine, profile, magazineFeedBucketsQ.data)

  const bucketOptions = useMemo(
    () => availableBuckets
      ? (!_.isEmpty(availableBuckets) ? availableBuckets : allBuckets)
        .map(b => ({ value: b.key, label: b.label }))
      : undefined,
    [allBuckets, availableBuckets]
  )

  const allMediaQ = useBucketMedia(magazine, bucket, part)
  const allMedia = allMediaQ.data || []
  const deleteMediaMutation = useDeleteMediaMutation(magazine, bucket, part, allMedia)
  const loading = magazineFeedBucketsQ.isFetching || allMediaQ.isFetching || deleteMediaMutation.isLoading

  const onDeleteMedia = async (med: Media) => {
    const ok = await createDeleteDialog(med)
    if (ok) {
      deleteMediaMutation.mutate(med)
    }
  }


  const selectHandler = (url: string, file?: Media) => {
    if (!multipleSelection) {
      setSelectedUrls([url])
      setSelectedFiles(file ? [file] : [])
      return;
    } else {

      const selected = _.includes(selectedUrls, url)
        ? selectedUrls.filter(u => u !== url)
        : selectedUrls ? [...selectedUrls, url] : [url];

      setSelectedUrls(selected)
      if (file) {
        const newSelectedFile = _.includes(selectedUrls, url)
          ? selectedFiles.filter(m => m.url !== url)
          : selectedFiles ? [...selectedFiles, file] : [file];
        setSelectedFiles(newSelectedFile)
      }
    }
  }


  const addMedia = () => {
    if (!selectedUrls) {
      return;
    }
    props.selectHandler(selectedUrls, selectedFiles);
  }

  const tr = Translations.Translate(Translations, 'MediaManagerSelector');

  return (
    <GenericModal isOpen size={'xxl'} fullHeight toggle={() => { props.closeHandler() }} >
      <GenericModalHeader title={tr('selectMedia')} onClose={() => { props.closeHandler() }} icon="/assets/icons/24/analytics.svg" />
      <ModalBody className={styles.modalBody}>

        {(bucketOptions || showPartSelector) &&
          <div className={styles.header}>
            {bucketOptions &&
              <div className={styles.selectorWrapper}>
                <span className={styles.selectorLabel}>Bucket:</span>
                <div className={styles.selector}>
                  <SingleSelect
                    options={bucketOptions}
                    value={bucket}
                    onChange={(s) => { if (s) setBucket(s) }}
                  />
                </div>
              </div>
            }
            {showPartSelector &&
              <div className={styles.selectorWrapper}>
                <span className={styles.selectorLabel}>Access:</span>
                <div className={styles.selector}>
                  <SingleSelect
                    options={partOptions}
                    value={part}
                    onChange={(s) => { if (s) { setPart(s) } }}
                  />
                </div>
              </div>
            }
          </div>
        }

        <div className={styles.content}>
          <MediaBucketExplorer
            key={`${bucket}${part ?? ''}`}
            bucket={bucket}
            part={part}
            allMedia={allMedia}
            magazine={magazine}
            loading={loading}
            listModeDefault={false}
            onMediaDelete={onDeleteMedia}
            isMediaSelected={m => _.includes(selectedUrls, m.url)}
            onMediaSelect={m => { selectHandler(m.url, m) }}
            restrictedMediaTypes={restrictedMediaTypes}
          />
        </div>
      </ModalBody>
      {selectedUrls.length > 0 && <ModalFooter>
        {multipleSelection &&
          <Button color="outline-secondary" onClick={() => { setSelectedUrls([]) }}>Unselect all ({selectedUrls.length})</Button>
        }
        <div style={{ flex: 1 }} />
        <Button color="outline-secondary" onClick={() => { props.closeHandler() }}>Cancel</Button>
        {' '}
        <LoadingButton
          loading={false}
          onClick={() => { addMedia() }}
          text={'Add media' + (multipleSelection ? ` (${selectedUrls.length})` : '')}
          disabled={selectedUrls.length === 0}
        />
      </ModalFooter>}
    </GenericModal>
  );
}

