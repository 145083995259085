import React from 'react';
import { NlLayoutColumnIndex, NlSlotItem, NlSlotItem_article, NlSlotItem_freeText, NlTemplateConfiguration_Content } from './domain';

import styles from './NewsletterContentSlot.module.scss';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { NewsletterContentArticle } from './NewsletterContentArticle';
import { NewsletterContentFreeText } from './NewsletterContentFreeText';

interface Props {
  magazine: number,
  templateConfiguration: NlTemplateConfiguration_Content
  rowIndex: number,
  columnIndex: NlLayoutColumnIndex,
  oneColumn?: boolean
  slotItem?: NlSlotItem
  openArticleSelector: (rowIndex: number, columnIndex: NlLayoutColumnIndex, openFreeTextEditorWithText?: string) => void
  readOnly?: boolean
}

export const NewsletterContentSlot = ({ magazine, templateConfiguration, rowIndex, columnIndex, oneColumn, slotItem, openArticleSelector, readOnly }: Props) => {

  const onAddArticleContent = () => {
    if (readOnly) {
      return;
    }
    openArticleSelector(rowIndex, columnIndex);
  }

  const onEditFreeTextContent = (text: string) => {
    if (readOnly) {
      return;
    }
    openArticleSelector(rowIndex, columnIndex, text);
  }

  return (
    <div className={classNames(styles.NewsletterContentSlot, { [styles.nonEmptySlot]: slotItem ? true : false })}>
      {/* TODO: Leave it here for the MVP period  and then delete */}
      {/* <div className={styles.metaInfo}>Slot: {rowIndex} - {columnIndex}</div> */}
      {slotItem ?
        (slotItem.type == 'article' ?
          <NewsletterContentArticle oneColumn={oneColumn} slotItem={slotItem as NlSlotItem_article} magazine={magazine} templateConfiguration={templateConfiguration} onClick={onAddArticleContent} />
          :
          <NewsletterContentFreeText slotItem={slotItem as NlSlotItem_freeText} templateConfiguration={templateConfiguration} onClick={onEditFreeTextContent} />
        )
        :
        <Button disabled={readOnly} color={'secondary'} outline onClick={onAddArticleContent}>Add {columnIndex == 0 ? 'first' : 'second'} column content</Button>
      }
    </div>

  )

}
