import React, { Component, Fragment } from 'react';
import { Col, Button } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';

import './css/ReportTable.min.css'
import { Profile } from '../../../domain';
import * as _ from 'lodash';
import * as Api from '../../api';
import { Translations } from '../../translations';
import { OrtecLoader } from '../../widgets';
import { DateHelper, JsonHelper } from '../../utils';
import { CustomPeriod } from './domain';
const FileDownload = require('js-file-download');
import { Report } from './domain';

export interface Props {
  magazine: string,
  profile: Profile,
}
export interface State {
  reports?: any[],
  reportDownloading?: number
}

export class ReportTableArchive extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.getReports();

  }
  filterReportsBasedOnAccessRights = (reports: any[]) => { // editor should see reports for audiences that he has access to
    const { profile } = this.props;
    const auds = _.map(profile.audienceIds, _.toNumber);
    if (profile.globalAudience) {
      auds.push(1);
    }
    return _.filter(reports, (r) => {
      return _.includes(auds, r.audience);
    })
  }
  getReports() {
    Api.getReportsArchive(parseInt(this.props.magazine)).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ reports: [] });
      }
      this.setState({ reports: this.filterReportsBasedOnAccessRights(resp.data) });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
        this.setState({ reports: [] });

      });
  }
  download = (audience: number, period: string, name: string, reportId: number, type: string) => {

    const { magazine, profile } = this.props;
    const report: Report = {
      period: type == 'custom' ? JsonHelper.jsonParse(period) as CustomPeriod : period,
      audience,
      name,
    }

    this.setState({ reportDownloading: reportId });

    Api.downloadReport(parseInt(magazine), report).then((resp) => {
      if (!resp.data || resp.data.error) {
        alert(`An error occured: ${resp.data.error}`);
        return;
      }
      const data = resp.data;
      const header = resp.headers['content-disposition'];
      var filename = report.name ? report.name + '.pdf' : null;
      if (!filename) {
        const header = resp.headers['content-disposition'];
        filename = _.trim(header.split('filename=')[1], '"');
      }

      FileDownload(data, filename);
    })
      .catch((error) => {
        alert('An error occured:' + error.message);
      }).finally(() => {
        this.setState({ reportDownloading: undefined });
      });
  }
  dateFormatter(cell: any, row: any) {
    return <>{DateHelper.dateTimeToLocaleString(cell)}</>;
  }

  deleteReport = (row: any) => {
    const { reports } = this.state;
    if (reports) {
      const newReports = [...reports];
      _.remove(newReports, { id: row.id });
      this.setState({ reports: newReports });
    }

    Api.deleteReportsArchive(parseInt(this.props.magazine), { id: row.id }).then((resp) => {
      if (!resp.data || resp.data.error) {
        this.setState({ reports: reports });
        console.log('Error in deleting report');
      }
    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
        if (reports) {
          this.setState({ reports: reports });
        }

      });


  }
  sendTest = (row: any) => {
    const { magazine } = this.props;
    const emails = row.emails;
    if (!emails) {
      alert('You have not defined an email yet');
      return;
    }
    const audience = row.audience;
    console.log(`Sending test for audience: ${audience}`);

    const today = moment();
    const currentMonth = moment(today).startOf('month');
    const prevMonth = moment(currentMonth).subtract(1, 'month');
    const period = moment(prevMonth).format('YYYY-MM');

    const report = {
      period,
      audience,
      emails
    }

    Api.sendReport(parseInt(magazine), report).then((resp) => {
      if (!resp.data || resp.data.error) {
        alert(`An error occured: ${resp.data.error}`);
        return;
      }

      window.open(resp.data.url, '_blank');

    })
      .catch((error) => {
        alert('An error occured:' + error.message);
      });



  }
  emailFormatter(cell: any) {
    const tr = Translations.Translate(Translations, 'Report');
    try {
      const count = _.size(_.omitBy(cell.split(';'), _.isEmpty));
      return (<span data-tippy-content={cell ? cell : null}>{cell && count ? count : tr('add_email')}</span>);
    } catch (error) {
      return (<span>{cell ? cell : tr('add_email')}</span>);
    }
  }
  audienceFormatter(cell: any, row: any) {
    const { profile } = this.props;
    const tr = Translations.Translate(Translations, 'Report');
    const text = (cell == 1) ? tr('all_employees') : _.get(_.find(profile.audiences, ['id', cell]), 'label');
    return (<span data-tippy-content={text ? text : null}>{text}</span>);
  }
  buttonFormatter(cell: any, row: any) {
    const tr = Translations.Translate(Translations, 'Report');

    if (this.state.reportDownloading == row.id) {
      return (
        <div><OrtecLoader size={'icon'} /></div>
      )
    }

    return (

      <div className={'actionHoveringDiv'}>
        <Button className="selectButton secondary" /*data-tippy-content={tr(`selectArticle`)}*/
          disabled={this.state.reportDownloading !== undefined}
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.download(row.audience, row.period, row.name ? row.name : this.autoName(row), row.id, row.type);
            return false;
          }}>
          {tr('download')}
        </Button>
        <Button className="selectButton secondary" style={{ marginLeft: '10px' }}/*data-tippy-content={tr(`selectArticle`)}*/
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            this.deleteReport(row);
            return false;
          }}>
          {tr('delete')}
        </Button>
      </div>
    );
  }

  nameFormatter = (cell: any, row: any) => {
    const tr = Translations.Translate(Translations, 'Report');

    const name = cell ? cell : this.autoName(row);
    const isSent = row.sent ? true : false;
    const iconClass = isSent ? 'status-scheduled' : 'status-sent';
    const tippyContent = isSent ? tr('scheduledReport') : tr('generatedReport');
    return (
      <Fragment><i className={`fa fa-circle ${iconClass}`} aria-hidden="true" data-tippy-content={tippyContent} ></i> <span data-tippy-content={name} style={{ fontStyle: !cell && row.type == 'custom' ? 'italic' : 'normal' }}>{name}</span></Fragment>
    )
  }
  nameFilterValue = (cell: any, row: any) => {
    const status = row.sent ? 'scheduled' : 'generated';
    const name = cell ? cell : this.autoName(row);

    return `${status} ${name}`;
  }
  autoName = (row: any) => {
    switch (row.type) {
      case 'monthly':
        return _.startCase(`${moment(row.period, 'YYYY-MM').format('MMMM YYYY')} Monthly Report`)
      default:
        return `Custom Report`
    }
  }

  periodFormatter = (cell: any, row: any) => {
    const tr = Translations.Translate(Translations, 'Report');

    if (row.type != 'custom') {
      return cell;
    }
    const period = JsonHelper.jsonParse(cell) as CustomPeriod | undefined;
    if (!period) {
      return cell;
    }
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, borderRight: "1px solid #9faaae" }}>
          <div className={'periodTitle'}>From:</div>
          <div>{period.start}</div>
        </div>
        <div style={{ flex: 1 }}>
          <div className={'periodTitle'}>Until:</div>
          <div>{period.end}</div>
        </div>
      </div >
    )
  }
  periodFilterValue = (cell: any, row: any) => {
    if (row.type != 'custom') {
      return cell;
    }
    const period = JsonHelper.jsonParse(cell) as CustomPeriod | undefined;
    if (!period) {
      return cell;
    }
    return `${period.start} ${period.end}`;
  }

  prepareReports = (reports: any) => {
    const { profile, magazine } = this.props;
    const tr = Translations.Translate(Translations, 'Report');

    const finalReports: any[] = _.map(reports, (rep) => {
      return {
        id: rep.id,
        audience: rep.audience,
        label: (rep.audience == 1) ? tr('all_employees') : _.get(_.find(profile.audiences, ['id', rep.audience]), 'label'),
        type: _.get(rep, 'type'),
        cdate: _.get(rep, 'cdate'),
      }
    });

    return finalReports;
  }

  afterSaveCell = (row: any, cellName: any, cellValue: any) => {
    console.log(`${row.id}:${cellValue}`);
    const audience = row.id;
    const { reports } = this.state;
    let oldValue: string;
    if (reports) {
      const newReports = [...reports];
      const r = row;
      oldValue = _.get(r, 'email');
      _.set(r, cellName, cellValue);
      this.setState({ reports: newReports });
    }
    Api.saveReport(parseInt(this.props.magazine), { audience: row.audience, type: row.type, email: cellValue }).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in saving email');
      }

    })
      .catch((error) => {
        console.log('An error occured:' + error.message);
        if (reports) {
          const newReports = [...reports];
          const r = row;
          r.email = oldValue ? oldValue : '';
          this.setState({ reports: newReports });
        }

      });
  }
  renderPaginationPanel = (props: any) => {
    let max = 1;
    let min = 1;
    if (this.state.reports) {
      max = (this.state.reports.length / 10) + 1;
    }
    const tr = Translations.Translate(Translations, 'Tables');

    return (
      <Fragment>
        <Col md={8} className='dropup'>{props.components.pageList} {props.components.sizePerPageDropdown}</Col>
        <Col md={4}>
          <div className="goToPageDiv">
            <input id="goToPageInput" onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                let val = e.target.value;
                if (val < min) {
                  val = min;
                } else if (val > max) {
                  val = max;
                }
                props.changePage(val);
                e.target.value = '';
              }
            }} className='form-control' type="number" placeholder={tr('go_to_page')} style={{ width: 'auto' }} />
            <button className="btn" onClick={(e) => {
              var k: any = document.getElementById('goToPageInput');
              let val = k.value;
              if (val < min) {
                val = min;
              } else if (val > max) {
                val = max;
              }
              props.changePage(val);
              k.value = ''
            }}>{'>'}</button>
          </div>
        </Col>
      </Fragment>
    );
  }


  render() {
    const { reports } = this.state;
    const tr = Translations.Translate(Translations, 'Report');
    const trt = Translations.Translate(Translations, 'Tables');

    const options: any = {
      defaultSortName: 'cdate',
      defaultSortOrder: 'desc',
      paginationPanel: this.renderPaginationPanel.bind(this),
      sizePerPage: 25,
      sizePerPageList: [
        { text: `25 ${trt('entries')}`, value: 25 },
        { text: `50 ${trt('entries')}`, value: 50 },
        { text: `100 ${trt('entries')}`, value: 100 },
      ]
    }

    return (
      <div id="ReportTable" className={''} style={{ position: 'relative', minHeight: '200px' }}>
        {Translations.EnableTranslations(Translations)}

        <div className="tableDiv">
          {reports ?
            <Fragment>
              <div className="titleDiv" style={{ position: 'absolute', top: '10px' }}>{tr('reportsArchive')}</div>
              <BootstrapTable ref="reportTable" data={reports} bordered={false} version='4' /*height='285px'*/ options={options} search pagination>
                <TableHeaderColumn isKey dataField='id' dataSort={false} hidden></TableHeaderColumn>
                <TableHeaderColumn dataField='name' dataSort={true} dataAlign={'left'} filterValue={this.nameFilterValue.bind(this)} dataFormat={this.nameFormatter.bind(this)}></TableHeaderColumn>
                <TableHeaderColumn dataField='audience' dataSort={true} dataAlign={'center'} filterFormatted dataFormat={this.audienceFormatter.bind(this)} width='100px'><img data-tippy-content={tr("audience")} src="/assets/icons/16/audience.svg" /></TableHeaderColumn>
                <TableHeaderColumn dataField='period' dataSort={false} dataAlign={'center'} width='200px' filterValue={this.periodFilterValue.bind(this)} dataFormat={this.periodFormatter.bind(this)}><img data-tippy-content={tr("period")} src="/assets/icons/16/calendar.svg" /></TableHeaderColumn>
                <TableHeaderColumn dataField='cdate' dataAlign={'center'} columnClassName='hideOnHover' filterFormatted dataFormat={this.dateFormatter.bind(this)} dataSort={true} width='150px'><img data-tippy-content={tr("created")} src="/assets/icons/16/sent.svg" /></TableHeaderColumn>
                <TableHeaderColumn editable={false} dataFormat={this.buttonFormatter.bind(this)} dataSort={false} width='50px'>{ }</TableHeaderColumn>
              </BootstrapTable>
            </Fragment>
            : <OrtecLoader />
          }
        </div>
      </div>
    );
  }
}

export default ReportTableArchive;
