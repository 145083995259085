import React from 'react';
import { ModalBody } from 'reactstrap';
import { GenericModal } from '../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../components/GenericModal/GenericModalHeader';
import styles from './NewsletterPreviewModal.module.scss';

interface Props {
  magazine: number,
  newsletterId: number,
  onClose: () => void
}

export const NewsletterPreviewModal = ({ magazine, newsletterId, onClose }: Props) => {
  return (
    <GenericModal isOpen size={'xl'} fullHeight centered toggle={onClose}>
      <GenericModalHeader
        onClose={onClose}
        title={`Newsletter Preview`}
        icon={`/assets/icons/24/newsletters.svg`}
      />
      <ModalBody>
        <div className={styles.iframeContainer}>
          <iframe frameBorder="0" style={{ width: '100%', border: 0, height: '100%' }} src={`/api/${magazine}/dashApi/newsletters/${newsletterId}/preview`} />
        </div>
      </ModalBody>
    </GenericModal>
  )
}