import _ from 'lodash';
import { AppMeta } from '../../domain';
import { ErrorWithStatus } from './ErrorWithStatus';

export const generateUniversalLink = (magazineMeta: AppMeta | undefined, suffix: string): string => {
  if (magazineMeta?.universalLinksBaseUrl) {
    return `${magazineMeta.universalLinksBaseUrl}${suffix}`;
  }
  if (magazineMeta?.globalLandingPage) {
    return `${magazineMeta.globalLandingPage}/${magazineMeta.id}${suffix}`;
  }
  return ``;
}

export const findCurrentMagazineBasedOnLocation = (): number => {
  const MagazineNotFoundError = new ErrorWithStatus(417, `Couldn't find magazine's number in ${window.location.pathname}`);
  try {
    const pathName = window.location.pathname;
    const mag = _.toNumber(pathName.split('/')[1]);
    if (_.isNumber(mag)) {
      return mag;
    }
    throw MagazineNotFoundError;
  } catch (error) {
    throw MagazineNotFoundError;
  }
}