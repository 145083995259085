import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Alert } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';
import { StatsFilter, Article, Profile } from '../../../domain';
import * as Api from '../../api';

import './css/ArticleDetails.min.css';
import ArticleContent from '../ArticlePreview/ArticlePreview';
import ArticlePerformance from './ArticlePerformance';
import FilterBar from './FilterBar';
import { I18n } from '../../translations';
import { ArticleComments } from './ArticleComments';
import { CommentsAccordion } from './CommentsAccordion';
import { EdErrorHandler } from '../../widgets';


export interface Props {
  magazine: string,
  profile: Profile
  statsFilter: StatsFilter,
  articleId: number,
  closeHandler: () => void
}

export interface State {
  statsFilter: StatsFilter,
  article?: Article,
  noContent?: boolean,
  collapsilbeOpen: boolean
}

export class ArticleDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { statsFilter: props.statsFilter, collapsilbeOpen: false };
  }

  componentDidMount() {
    this.getArticleContent();
  }

  getArticleContent() {

    Api.loadArticleContent(this.props.articleId, this.props.magazine, false, true).then((resp) => {
      this.setState({ article: resp.data });
    })
      .catch((error) => {
        this.setState({ noContent: true });
        EdErrorHandler(error, `getting article content`);
      });
  }


  toggleArticleStatusHandler(status: boolean) {
    // const {article} = this.state;
    Api.setArticleStatus(this.props.articleId, status, this.props.magazine).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in setting article status');
      }
      const article: any = { ...this.state.article };
      article.sourcesStatus = status ? 1 : 0;
      this.setState({ article: article });
    })
  }

  closeHandler() {
    this.props.closeHandler();
  }
  periodFilterHandler(value: string) {
    const statsFilter = { ...this.state.statsFilter };
    statsFilter.period = value;
    this.setState({ statsFilter: statsFilter });
  }
  audienceFilterHandler(value: number) {
    const statsFilter = { ...this.state.statsFilter };
    statsFilter.audience = value;
    this.setState({ statsFilter: statsFilter });
  }
  deleteSelectedCommentsHanlder(selectedComments: string[]) {
    Api.deleteSelectedComments(selectedComments, this.props.magazine).then((resp) => {
      if (!resp.data || resp.data.error) {
        console.log('Error in setting article status');
      }
      const article: any = { ...this.state.article };
      const comments = article.comments;
      _.remove(comments, (c: any) => {
        return selectedComments.indexOf(c.id) > -1
      })
      article.comments = comments;
      this.setState({ article: article });
    })
  }

  toggleCollapsible = () => {
    this.setState({ collapsilbeOpen: !this.state.collapsilbeOpen });
  }

  render() {
    const { magazine, articleId, profile } = this.props;
    const { article, statsFilter, noContent, collapsilbeOpen } = this.state;
    const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>
    return (
      <div id="ArticleDetails" className="fullModal">
        <div className='header'>
          {/* <i className="material-icons closeIcon" onClick={this.closeHandler.bind(this)}>close</i> */}
          <img src="/assets/icons/bt_close_bigger.svg" onClick={this.closeHandler.bind(this)} style={{ cursor: 'pointer' }} />
          <span className="headerTitle"><I18n t={'article_details'} n="ArticleDetails" /></span>
          {/* <FilterBar magazine={magazine} statsFilter={statsFilter} periodFilterHandler={this.periodFilterHandler.bind(this)} audienceFilterHandler={this.audienceFilterHandler.bind(this)}  audiences={profile.audiences?profile.audiences:[]} globalAudience={profile.globalAudience}/> */}
        </div>
        <div className='content'>
          {noContent ?
            <div style={{ margin: '20px', flex: 1 }}><Alert color={'warning'}><I18n t={'could_not_load_article_content'} n="ArticleDetails" /></Alert></div> :
            <>
              <div className='leftPart' style={{ flex: 2 }}>
                <ArticlePerformance key={JSON.stringify(statsFilter)} magazine={magazine} profile={profile} statsFilter={statsFilter} articleId={articleId} article={article} toggleArticleStatusHandler={this.toggleArticleStatusHandler.bind(this)} deleteSelectedCommentsHanlder={this.deleteSelectedCommentsHanlder.bind(this)} periodFilterHandler={this.periodFilterHandler.bind(this)} audienceFilterHandler={this.audienceFilterHandler.bind(this)} />

              </div>
              <div className='rightPart' style={{ flex: 1 }}>
                {noContent ?
                  <I18n t={'could_not_load_article_content'} n="ArticleDetails" />
                  :
                  !article ?
                    loader
                    :
                    // loader
                    <Fragment>
                      <div className='nonCollapsible' style={{ flex: collapsilbeOpen ? 0 : 1 }}>
                        <div className='articleContentWrapper' style={{ margin: '20px' }}>
                          <ArticleContent magazine={magazine} article={article} defaultVariationLanguage={profile.magazineLanguages?.primary} />
                        </div>
                      </div>
                      <div className='collapsible' style={collapsilbeOpen ? { height: '100%' } : { height: '60px' }}>
                        <CommentsAccordion magazine={magazine} article={article} deleteSelectedCommentsHanlder={this.deleteSelectedCommentsHanlder.bind(this)} toggleCollapsible={this.toggleCollapsible.bind(this)} opened={this.state.collapsilbeOpen} />
                      </div>
                    </Fragment>
                }
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

export default ArticleDetails;
