import React, { Fragment } from "react";
import { Input } from "reactstrap";
import { OrtecLoader } from "../../../../../../widgets";
import { AudienceCount, BuilderQueryOperator } from "../../domain";

import styles from '../AudienceBuilder.module.scss';

interface Props {
  loading: boolean,
  count?: AudienceCount,
  unsupportedQuery?: boolean,
  noRights: boolean,
  queryOperator: BuilderQueryOperator,
  onChangeQueryOperator: (op: BuilderQueryOperator) => void
}

export const OperatorToolbar = ({ loading, queryOperator, count, unsupportedQuery, noRights, onChangeQueryOperator }: Props) => {
  return (
    <div className={styles.operatorToolbar}>
      {(unsupportedQuery) ? <div className={styles.unsupportedQueryLabel}>query not available for editing</div> :
        <Input type="select" style={{ width: 'auto' }} value={queryOperator} onChange={(e) => { onChangeQueryOperator(e.target.value as BuilderQueryOperator) }} disabled={noRights}>
          <option value="$and">All of the following conditions must be met</option>
          <option value="$or">At least one of the following conditions should be met</option>
        </Input>
      }
      <div style={{ flex: 1 }}><hr /></div>
      <div className={styles.countBox}>{loading ? <OrtecLoader size={'icon'} /> : <Fragment><img src={`/assets/icons/16/id_blue.svg`} /> {count ? (count.totalProfiles < 0 ? '?' : count.totalProfiles) : '-'}</Fragment>}</div>
      <div className={styles.countBox}>{loading ? <OrtecLoader size={'icon'} /> : <Fragment><img src={`/assets/icons/16/device_blue.svg`} /> {count ? (count.totalDevices < 0 ? '?' : count.totalDevices) : '-'}</Fragment>}</div>
    </div>
  )
}