import React, { useState } from 'react'
import { SourceTypeSettingsProps } from '../SourceTypeSettings'
import { Button, Col, Label, ListGroup, ListGroupItem } from 'reactstrap';
import _ from 'lodash';
import SourceSelector from '../SourceSelector';
import { Source } from '../../../../../../domain';
import { useQuery } from '@tanstack/react-query';
import { EdErrorHandler, OrtecLoader } from '../../../../../widgets';
import { API } from '../../../../../apis';
import { TippyReact } from '../../../../../components';
import classNames from 'classnames';
import styles from './Composed.module.scss';

export const Composed = ({ magazine, profile, source, onChangeSource }: SourceTypeSettingsProps) => {
  const composedSourcesIds = extractSourcesOfComposed(source.url);
  const [openSourceSelector, setOpenSourceSelector] = useState(false);
  const [selectedSourceToAdd, setSelectedSourceToAdd] = useState<Source>();

  const onSourceSelect = (s: Source) => {
    setSelectedSourceToAdd(s)
  }

  const onOpenSourceSelector = () => {
    setSelectedSourceToAdd(undefined);
    setOpenSourceSelector(true);
  }

  const onCloseSourceSelector = () => {
    setOpenSourceSelector(false);
  }

  const onSourceSelectorSelect = () => {
    if (selectedSourceToAdd) {
      onChangeSource({ ...source, url: transformSourcesOfComposedToUrl([...composedSourcesIds, selectedSourceToAdd.id]) })
    }
    onCloseSourceSelector();
  }

  const onSourceRemove = (sourceId: number) => {
    onChangeSource({ ...source, url: transformSourcesOfComposedToUrl(_.filter(composedSourcesIds, (s) => s !== sourceId)) })
  }

  return (
    <>
      <Col md={12}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Label>Composed sources:</Label>
          <Button color={'secondary'} onClick={onOpenSourceSelector}>Add source</Button>
        </div>
        <div>
          <ListGroup numbered>
            {_.map(composedSourcesIds, (s) => {
              return (
                <SourceInComposedItem
                  key={s}
                  sourceId={s}
                  magazine={magazine}
                  onRemove={onSourceRemove}
                />
              )
            })}
          </ListGroup>
        </div>

      </Col>
      {openSourceSelector &&
        <SourceSelector
          magazine={_.toString(magazine)}
          profile={profile}
          title={''}
          closeHandler={onCloseSourceSelector}
          onSelect={onSourceSelectorSelect}
          selectionHandler={onSourceSelect}
          selected={selectedSourceToAdd}
        />
      }
    </>
  )
}

interface SourceInComposedItemProps {
  magazine: number,
  sourceId: number,
  tooltip?: string,
  onRemove: (sourceId: number) => void
}

const SourceInComposedItem = ({ magazine, sourceId, tooltip, onRemove }: SourceInComposedItemProps) => {

  const [notFoundError, setNotFoundError] = useState(false);

  const sourceDetailsQuery = useQuery({
    queryKey: ['sourceDetails', sourceId],
    queryFn: async () => {
      try {
        const { data } = await API.sources.getSource(magazine, sourceId);
        return data;
      } catch (error) {
        if (error?.response?.status === 404) {
          setNotFoundError(true);
        } else {
          EdErrorHandler(error, `getting source details`);
        }

      }
    },
  });

  const source: Source | undefined = sourceDetailsQuery.data;

  return (
    <ListGroupItem>
      <TippyReact
        content={notFoundError ? `Source details not found` : tooltip}
        config={{
          disabled: notFoundError || tooltip ? false : true,
          placement: notFoundError ? 'top-start' : 'auto',
          arrow: !notFoundError
        }}
      >
        <div className={classNames(styles.SourceInComposedItem, { [styles.notFoundDetails]: notFoundError })}>
          {sourceDetailsQuery.isLoading ? <OrtecLoader size={'icon'} px={16} /> :
            source ? `[${source.id}] ${source.label || source.name}` : `[${sourceId}]`
          }
          <i onClick={(e) => { onRemove(sourceId) }} className='fa fa-times'></i>
        </div>
      </TippyReact>
    </ListGroupItem>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const extractSourcesOfComposed = (url?: string): number[] => {
  if (!url) {
    return [];
  }

  try {
    return _.map(_.trim(url, '|').split('|'), _.toNumber);
  } catch (error) {
    console.warn(`Url of this composed source is not in the expected format (i.e. |sourceId_1|sourceId_2|sourceId_x| )`);
    return [];
  }
}

const transformSourcesOfComposedToUrl = (composedSourcesIds: number[]): string | undefined => {
  return _.isEmpty(composedSourcesIds) ?
    undefined :
    `|${_.uniq(composedSourcesIds).join('|')}|`;
}