import React, { useEffect, useMemo, useState } from 'react';

import styles from './SourceDetails.module.scss';
import { MagazineLanguages, Profile, Source, Translation } from '../../../../../domain';
import { EdErrorHandler, LoadingButton, OrtecLoader, SwalError, SwalSuccess } from '../../../../widgets';
import _ from 'lodash';
import { SourcesHelper } from '../../../../utils';
import { API } from '../../../../apis';
import { Translations } from '../../../../translations';
import { PageSwitch } from '../../../../components/PageSwitch/PageSwitch';
import { SourcesTranslations } from './SourcesTranslations';
import { SourceConfig } from './SourceConfig';
import { useQuery } from '@tanstack/react-query';

const emptySource: Source = {
  id: -1,
  name: '',
  label: '',
  srctype: 'RSS',
  hyphenation: '',
  audiences: [],
  status: true,
  private: true,
  articleCount: 0,
  translations: [],
}

interface Props {
  magazine: number,
  profile: Profile,
  sourceId: number,
  newSourceType?: string,
  languages: MagazineLanguages
  onClose: (updateOverview?: boolean) => void,
}

export const SourceDetails = ({ magazine, profile, sourceId, newSourceType, languages, onClose }: Props) => {

  const [source, setSource] = useState<Source>();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [showTranslations, setShowTranslations] = useState<boolean>(false);
  const [translations, setTranslations] = useState<Translation[]>([]);

  const isNewSource = sourceId === -1;

  const sourceQuery = useQuery({
    queryKey: ['source', sourceId],
    queryFn: async () => {
      try {
        if (isNewSource) {
          return { ...emptySource, srctype: newSourceType || emptySource.srctype };
        }
        const { data } = await API.sources.getSource(magazine, sourceId);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting source`);
      }
    },
  });

  const translationsQuery = useQuery({
    queryKey: ['translations', sourceId],
    queryFn: async () => {
      try {
        const { data } = await API.oldApi.getTranslations(magazine, 'source', _.toString(sourceId));
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting source translations`);
      }
    },
  });

  useEffect(() => {
    setSource(sourceQuery.data);
  }, [sourceQuery.data]);

  useEffect(() => {
    setTranslations(translationsQuery.data);
  }, [translationsQuery.data]);

  const [hasSourceName, hasAdvancedSettings] = useMemo(() => {
    if (!sourceQuery.data) return [false, false];
    const { name, kind, hyphenation } = sourceQuery.data;
    const hasSourceName = name ? true : false;
    const hasAdvancedSettings = (kind || hyphenation) ? true : false;
    return [hasSourceName, hasAdvancedSettings];
  }, [sourceQuery.data])


  const loading = sourceQuery.isFetching || translationsQuery.isFetching;
  const notFound = sourceQuery.isFetchedAfterMount && !source ? true : false

  const updateTranslationValue = (language: string, key: string, value: string) => {
    const existingTranslation = _.find(translations, (t) => (t.lang == language && t.key == key));
    if (!existingTranslation) {
      const newTranslation = {
        lang: language,
        item_type: 'source',
        item_id: _.toString(sourceId),
        magazine,
        key,
        value
      }
      setTranslations([...translations, newTranslation]);
    } else {
      const newTranslations = _.map(translations, (t) => {
        if (t.lang == language && t.key == key) {
          return { ...t, value };
        }
        return t;
      });
      setTranslations(newTranslations);
    }
  }


  const onSave = async () => {
    if (!source || !validateSource(source)) {
      return;
    }
    try {
      setSaveLoading(true);
      await API.oldApi.saveSource(magazine, {
        ...transformSourceBasicPropsAccordingToSrcType(source),
        translations
      });
      SwalSuccess.fire({
        title: 'Success!',
        text: `Source has been saved successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 1000,
      });
      onClose(true);
    } catch (error) {
      const errorResponseData = (error as any)?.response?.data;
      const s = errorResponseData?.source;
      if (s) {
        SwalError.fire({
          title: 'Something went wrong!',
          text: `Error in saving source`,
          footer: `<div class="alert alert-danger" style="width:100%">
            <div>[${_.get(error, `response.status`, 500)}] ${errorResponseData.message}</div>
              <div><ul><li><a target="_blank" href="/${magazine}/advanced?source=${s.id}&tab=sources">${s.label || s.name} [${s.id}]</a></li></ul></div>
            </div>`
        });
      } else {
        EdErrorHandler(error, `saving source`);
      }
    } finally {
      setSaveLoading(false);
    }
  }

  const typeName = (type: string) => {
    switch (type) {
      case 'RSS':
        return 'RSS'
      case 'tags':
        return 'Tags';
      case 'feed':
        return 'Content-Feed';
      case 'reporter':
        return 'Reporter';
      case 'composed':
        return 'Composed';
      default:
        return _.capitalize(type);
    }
  }

  const tr = Translations.Translate(Translations, 'Sources');

  const notFoundMessage = <div style={{ textAlign: 'center' }} className="alert alert-danger">{tr('sourceNotFound')}</div>;


  return (
    <div className={styles.SourceDetails}>
      <div className={styles.header}>
        <img src="/assets/icons/bt_close_bigger.svg" onClick={() => onClose()} style={{ cursor: 'pointer' }} />
        {loading || notFound ? '' :
          <>
            <div className={styles.idContainer}>
              <div className={styles.headerLabel}>{isNewSource ? 'NEW SOURCE' : 'SOURCE ID'}</div>
              <div className={styles.headerVal}> {isNewSource ? '-' : sourceId}</div>
            </div>
            <div className={styles.typeContainer}>
              <div className={styles.headerLabel}>{!isNewSource && _.endsWith(source?.srctype, '-feed') ? 'FEED NAME' : 'SOURCE TYPE'}</div>
              <div className={styles.headerVal}> {source ? (isNewSource ? typeName(source.srctype) : SourcesHelper.sourceTypeFormatter(source?.srctype || '', source)) : ''}</div>
            </div>
            <div className={styles.flexSpacer}></div>
            <LoadingButton loading={saveLoading} onClick={() => { onSave() }} text={isNewSource ? `Create` : `Save`} />
          </>
        }
      </div>
      <div className={styles.content}>
        {loading ? <OrtecLoader size={'medium'} /> :
          <div className={styles.modalContent}>
            {notFound ? notFoundMessage :
              <>
                <PageSwitch optionLeft={'settings'} optionRight={'translations'} isRightActive={showTranslations} onClick={() => setShowTranslations(!showTranslations)} />
                {showTranslations ?
                  <SourcesTranslations magazine={magazine} source={source} languages={languages} translations={translations} updateTranslationValue={updateTranslationValue} /> :
                  (source && <SourceConfig {...{ magazine, profile, source }} hasSourceName={hasSourceName} hasAdvancedSettings={hasAdvancedSettings} onChangeSource={setSource} />)}
              </>
            }
          </div>
        }
      </div>
    </div>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

//TODO: Improve validation and error messages management to make it more scalable
const validateSource = (source: Source): boolean => {

  if (!source.name) {
    SwalError.fire({
      title: 'Error!',
      text: `You have to add a name before saving!`,
    });
    return false;
  }

  if (!source.label) {
    SwalError.fire({
      title: 'Error!',
      text: `You have to add a label before saving!`,
    });
    return false;
  }

  if (isFeedSourceType(source.srctype) && !source.connectedFeed) {
    SwalError.fire({
      title: 'Error!',
      text: `You have to select one feed before saving!`,
    });
    return false;
  }

  if (isTagsSourceType(source.srctype) && !source.baseurl) {
    SwalError.fire({
      title: 'Error!',
      text: `You have to select one source before saving!`,
    });
    return false;
  }

  if (isTagsSourceType(source.srctype) && !source.url) {
    SwalError.fire({
      title: 'Error!',
      text: `You have to add at least one tag!`,
    });
    return false;
  }

  if (!isFeedSourceType(source.srctype) && !isTagsSourceType(source.srctype) && _.isEmpty(source.parentChannels)) {
    SwalError.fire({
      title: 'Error!',
      text: `You have to select at least one channel before saving!`,
    });
    return false;
  }

  return true;
}

const isFeedSourceType = (srctype: string) => {
  return _.endsWith(srctype, 'feed');
}

const isTagsSourceType = (srctype: string) => {
  return srctype == 'tags';
}

const isComposedSourceType = (srctype: string) => {
  return srctype == 'composed';
}

const transformSourceBasicPropsAccordingToSrcType = (source: Source): Source => {
  if (isFeedSourceType(source.srctype)) {
    return { ...source, srctype: `${source.connectedFeed?.label}-feed` }

  }
  if (isComposedSourceType(source.srctype)) {
    return { ...source, baseurl: `composed` }
  } $

  return source;
}