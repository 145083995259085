import _ from "lodash";
import { ArticleVariation } from "../../domain";

export const getAvailableVariationsLanguages = (variations?: ArticleVariation[]): string[] | undefined => {
  if (!variations || _.isEmpty(variations)) {
    return undefined;
  }
  return _.map(variations, (v) => {
    return v.variation.replace('lang:', '');
  })
}

export const getVariation = (variations?: ArticleVariation[], lang?: string): ArticleVariation | undefined => {

  if (!variations) {
    return undefined;
  }

  const fallbackVariation = getLangVariation(variations, 'en') || getFirstVariation(variations);

  return lang ? (getLangVariation(variations, lang) || fallbackVariation) : fallbackVariation;
}

const getFirstVariation = (variations: ArticleVariation[]): ArticleVariation | undefined => {
  if (_.isEmpty(variations)) {
    return undefined;
  }
  return variations[0];
}

const getLangVariation = (variations: ArticleVariation[], lang: string): ArticleVariation | undefined => {
  if (_.isEmpty(variations)) {
    return undefined;
  }
  return _.find(variations, function (v) {
    return v.variation == `lang:${lang}`;
  });
}
