import React, { Component, Fragment } from 'react';
import { Progress, Badge, Button } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';
import * as Api from '../../../../api';
import { Profile, Audience, Form, FormConfig, FormQuestion } from '../../../../../domain';

import { Translations } from '../../../../translations';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import classnames from 'classnames';
import Toggle from 'react-toggle';
import "react-toggle/style.css"

import * as FormsHelper from './FormsHelper';
import { SwalError, SwalSuccess } from '../../../../widgets';
import { UsersModal } from '../../../../components';

export interface Props {
  magazine: number
  form: Form
  results: any[],
  quizSuccesses?: string[]
}

interface State {
  usersModal: boolean

}

export class FormResultsOverall extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      usersModal: false,
    }
  }

  openUsersModal = () => {
    this.setState({ usersModal: true });
  }

  closeUsersModal = () => {
    this.setState({ usersModal: false });
  }

  renderQuestionCardBody_freeText = (q: FormQuestion) => {
    const { results } = this.props;
    return (
      <table className={`tableResults_freeText`}>
        <tbody>
          {_.isEmpty(results) ?
            <tr><td colSpan={2}>There are no results</td></tr>
            :
            results.map((r, ind) => {
              const response = _.find(r.responses, ['id', q.id]);
              return <tr key={`res-${q.id}-${ind}`}><td><i className={'material-icons'} data-tippy-content={`UID: ${r.uid}`}>person_outline</i></td><td data-tippy-content={response ? response.response : '-'} data-tippy-arrow={false}>{response ? response.response : '-'}</td></tr>
            })}
        </tbody>
      </table>

    )
  }
  renderQuestionCardBody_star = (q: FormQuestion) => {
    const { results } = this.props;

    const maxStars = q.scale ? q.scale : 5;

    const aggregatedResults = _.countBy(results, (r) => {
      const response = _.find(r.responses, ['id', q.id]);
      if (response && response.response) {
        return _.toInteger(response.response);
      }
    });

    const starRows = [];
    for (let i = maxStars; i > 0; i--) {
      const row = <tr key={`res-${q.id}-${i}`}><td>{i} <i className='material-icons'>star_border</i></td><td><Progress value={aggregatedResults[i] ? _.round((aggregatedResults[i] * 100) / results.length, 1) : 0}></Progress></td><td>{aggregatedResults[i] ? _.round((aggregatedResults[i] * 100) / results.length, 1) : 0} %</td><td>{aggregatedResults[i] | 0} <i className='material-icons'>group</i></td></tr>;
      starRows.push(row);
    };

    const noStarsRow = <tr key={`res-${q.id}-noStar`} className={'naRow'}><td> - </td><td><Progress value={aggregatedResults.undefined ? _.round((aggregatedResults.undefined * 100) / results.length, 1) : 0}></Progress></td><td>{aggregatedResults.undefined ? _.round((aggregatedResults.undefined * 100) / results.length, 1) : 0} %</td><td>{aggregatedResults.undefined | 0} <i className='material-icons'>group</i></td></tr>;
    starRows.push(noStarsRow);

    const sumValue = _.sumBy(results, (r) => {
      const response = _.find(r.responses, ['id', q.id]);

      if (response && response.response) {
        return _.toInteger(response.response);
      } else {
        return 0;
      }
    });

    const avgValue = results.length ? sumValue / results.length : 0;
    const avgValueRounded = _.round(avgValue, 1);

    const totalStars = [];

    for (let i = 1; i <= maxStars; i++) {
      let c = 'inherit', icon = 'star_border';
      if (avgValueRounded >= i) {
        c = 'gold';
        icon = 'star'
      }
      else if (avgValueRounded >= (i - 0.5)) {
        c = 'gold';
        icon = 'star_half';
      }
      const row = <i key={`star-${q.id}-${i}`} className={'material-icons'} style={{ color: c }}>{icon}</i>;
      totalStars.push(row);
    };

    const totalRow = <tr className={`totalRow`} key={`res-${q.id}-total`}><td colSpan={4}>Total: <span className={'avgStarsSpan'}>{totalStars}</span> <span className={'avgValueSpan'}>{avgValueRounded}/{maxStars}</span></td></tr>;


    return (
      <table className={`tableResults_star`}>
        <tbody>
          {starRows}
          {totalRow}
        </tbody>
      </table>

    )
  }
  renderQuestionCardBody_singleAnswer = (q: FormQuestion) => {
    const { results } = this.props;


    const aggregatedResults = _.countBy(results, (r) => {
      const response = _.find(r.responses, ['id', q.id]);
      if (response && response.response) {
        if (!_.isString(response.response)) {
          return _.toInteger(response.response);
        } else {
          return 'other';
        }
      }
    });

    // console.log(aggregatedResults);


    const answerRows: any[] = [];
    if (q.answers) {
      q.answers.map((a, index) => {
        const row = <tr key={`res-sa-${index}`}><td data-tippy-content={a.text} className={`${a.id == q.correctAnswer ? 'correct' : ''}`}>{a.text}</td><td><Progress value={aggregatedResults[a.id] ? _.round((aggregatedResults[a.id] * 100) / results.length, 1) : 0}></Progress></td><td>{aggregatedResults[a.id] ? _.round((aggregatedResults[a.id] * 100) / results.length, 1) : 0} %</td><td>{aggregatedResults[a.id] | 0} <i className='material-icons'>group</i></td></tr>;
        answerRows.push(row);
      });
    }
    if (aggregatedResults['other']) {
      const otherRespRow = <tr key={`res-ma-otherResp`}><td data-tippy-content={'Other'}><i>Other</i></td><td><Progress value={aggregatedResults['other'] ? _.round((aggregatedResults['other'] * 100) / results.length, 1) : 0}></Progress></td><td>{aggregatedResults['other'] ? _.round((aggregatedResults['other'] * 100) / results.length, 1) : 0} %</td><td>{aggregatedResults['other'] | 0} <i className='material-icons'>group</i></td></tr>;
      answerRows.push(otherRespRow);
    }

    const noRespRow = <tr key={`res-sa-noResp`}><td data-tippy-content={'-'}>-</td><td><Progress value={aggregatedResults.undefined ? _.round((aggregatedResults.undefined * 100) / results.length, 1) : 0}></Progress></td><td>{aggregatedResults.undefined ? _.round((aggregatedResults.undefined * 100) / results.length, 1) : 0} %</td><td>{aggregatedResults.undefined | 0} <i className='material-icons'>group</i></td></tr>;
    answerRows.push(noRespRow);

    return (
      <table className={`tableResults_singleAnswer`}>
        <tbody>
          {answerRows}
        </tbody>
      </table>

    )
  }
  renderQuestionCardBody_multipleAnswers = (q: FormQuestion) => {
    const { results } = this.props;

    let aggregatedResults: any = {};
    _.map(results, (r) => {
      const response = _.find(r.responses, ['id', q.id]);
      if (response && response.response && _.isArray(response.response) && !_.isEmpty(response.response)) {
        _.map(response.response, (r) => {
          let key = 'other'
          if (_.includes(_.map(q.answers, (a) => a.id), r)) {
            key = r;
          }
          if (!aggregatedResults[key]) {
            aggregatedResults[key] = 1
          } else {
            aggregatedResults[key] = aggregatedResults[key] + 1;
          }
        });
      } else {
        if (!aggregatedResults['undefined']) {
          aggregatedResults['undefined'] = 1
        } else {
          aggregatedResults['undefined'] = aggregatedResults['undefined'] + 1;
        }
      }
    });

    const answerRows: any[] = [];
    if (q.answers) {
      q.answers.map((a, index) => {
        const row = <tr key={`res-ma-${index}`}><td data-tippy-content={a.text}>{a.text}</td><td><Progress value={aggregatedResults[a.id] ? _.round((aggregatedResults[a.id] * 100) / results.length, 1) : 0}></Progress></td><td>{aggregatedResults[a.id] ? _.round((aggregatedResults[a.id] * 100) / results.length, 1) : 0} %</td><td>{aggregatedResults[a.id] | 0} <i className='material-icons'>group</i></td></tr>;
        answerRows.push(row);
      });
    }

    // console.log(aggregatedResults);


    if (aggregatedResults['other']) {
      const otherRespRow = <tr key={`res-ma-otherResp`}><td data-tippy-content={'Other'}><i>Other</i></td><td><Progress value={aggregatedResults['other'] ? _.round((aggregatedResults['other'] * 100) / results.length, 1) : 0}></Progress></td><td>{aggregatedResults['other'] ? _.round((aggregatedResults['other'] * 100) / results.length, 1) : 0} %</td><td>{aggregatedResults['other'] | 0} <i className='material-icons'>group</i></td></tr>;
      answerRows.push(otherRespRow);
    }

    const noRespRow = <tr key={`res-ma-noResp`}><td data-tippy-content={'-'}>-</td><td><Progress value={aggregatedResults['undefined'] ? _.round((aggregatedResults['undefined'] * 100) / results.length, 1) : 0}></Progress></td><td>{aggregatedResults['undefined'] ? _.round((aggregatedResults['undefined'] * 100) / results.length, 1) : 0} %</td><td>{aggregatedResults['undefined'] | 0} <i className='material-icons'>group</i></td></tr>;
    answerRows.push(noRespRow);

    return (
      <table className={`tableResults_singleAnswer`}>
        <tbody>
          {answerRows}
        </tbody>
      </table>

    )
  }
  renderQuestionCardBody = (q: FormQuestion) => {
    switch (q.type) {
      case 2:
        return this.renderQuestionCardBody_multipleAnswers(q);
      case 3:
        return this.renderQuestionCardBody_singleAnswer(q);
      case 4:
        return this.renderQuestionCardBody_freeText(q);
      case 5:
        return this.renderQuestionCardBody_star(q);
      default:
        return null;
    }
  }

  renderQuestionCard = (q: FormQuestion, index: number, inSection?: boolean) => {

    return (
      <div key={`questionCard-${q.id}`} className={`questionCard ${inSection && index == 0 ? 'sectionStart' : ''}`}>
        <div className={'questionCard_header'} data-type={`${q.type}${q.multiline ? 'm' : ''}`}>
          <div className={`squareTile`}>
            <div className={'questionPrefix'}>Question</div>
            <div className={'questionNumber'}>{(index + 1)}</div>
          </div>
          <div className={'questionText'}>{q.text}</div>

        </div>
        <div className={'questionCard_body'}>
          {this.renderQuestionCardBody(q)}
        </div>
      </div>
    )
  }

  renderQuestions = () => {
    const { form } = this.props;
    let num = 0;
    return (
      form.config.questions.map((q, index) => {
        if (!_.includes([1, 7, 9], q.type)) { // omit image type
          return this.renderQuestionCard(q, num++);
        }
      })
    )
  }

  renderQuestions_survey = () => {
    const { form } = this.props;
    const sections = form.config.sections ? form.config.sections : [];
    let num = 0;
    return (
      sections.map((s) => {
        num = 0;
        return s.questions.map((qId, index) => {
          const q = _.find(form.config.questions, ['id', qId]);
          if (q && q.type !== 9) { // omit image type
            return this.renderQuestionCard(q, num++, true);
          }
        })
      })

    )
  }
  renderQuizResults = () => {
    const { quizSuccesses, results } = this.props;
    if (!quizSuccesses || results.length == 0) {
      return null;
    }
    const totalSuccesses = quizSuccesses.length;
    const totalResponses = results.length;
    const rate = _.round((totalSuccesses * 100 / totalResponses));
    return (
      <div id={'quizSuccessesMessage'}>
        {`${totalSuccesses} ${totalSuccesses == 1 ? 'has' : 'have'} passed this quiz. (Success rate: ${rate}%)`}
        {totalSuccesses > 0 && <Button style={{ marginLeft: '20px' }} onClick={this.openUsersModal}>show users who passed</Button>}
      </div>
    )
  }


  render() {
    const { magazine, form, quizSuccesses } = this.props;
    const { usersModal } = this.state;
    // const { results, loading, notFound} = this.state;
    const tr = Translations.Translate(Translations, 'Forms');

    // const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>;
    // const notFoundMessage = <div className="notFoundContainer alert alert-danger">{tr('formNotFound')}</div>;

    return (
      <Fragment>
        <div id="FormResultsOverall" className={form.type == 's_poll' ? 'poll' : ''}>
          {Translations.EnableTranslations(Translations)}
          {form.type == 'quiz' ? this.renderQuizResults() : null}
          {form.type == 'survey' ? this.renderQuestions_survey() : this.renderQuestions()}
        </div>
        {usersModal && <UsersModal title={'Users who passed the quiz'} exportCsvTitle={`${magazine}-quiz-${form.id}-usersWhoPassed`} magazine={magazine} closeModal={this.closeUsersModal.bind(this)} uids={quizSuccesses ?? []} />}
      </Fragment>
    );
  }
}

export default FormResultsOverall;
