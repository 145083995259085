import React from 'react';
import { TextWithUIComponent } from './components/TextWithUIComponent';
import { NlTemplateConfiguration_Content } from './domain';
import styles from './NewsletterTemplatesetDetails.module.scss';

interface Props {
  configuration: NlTemplateConfiguration_Content,
  onChange: (newConfiguration: NlTemplateConfiguration_Content) => void
}

export const NewsletterTemplateContent = ({ configuration, onChange }: Props) => {

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>content</div>
      </div>
      <div className={styles.sectionContent}>
        <TextWithUIComponent
          name={`Article Title`}
          configuration={configuration.articleTitle}
          onChange={(c) => { onChange({ ...configuration, articleTitle: c }) }}
          noText
          noBackgroundColor
        />
        <hr />
        <TextWithUIComponent
          name={`Article Description`}
          configuration={configuration.articleDescription}
          onChange={(c) => { onChange({ ...configuration, articleDescription: c }) }}
          noText
          noBackgroundColor
        />
        <hr />
        <TextWithUIComponent
          name={`Article Date`}
          configuration={configuration.articleDate}
          onChange={(c) => { onChange({ ...configuration, articleDate: c }) }}
          noText
          noBackgroundColor
        />
        <hr />
        <TextWithUIComponent
          name={`Read More Link`}
          configuration={configuration.readMoreLink}
          onChange={(c) => { onChange({ ...configuration, readMoreLink: c }) }}
          noBackgroundColor
        />
      </div>
    </div>
  )
}
