import React, { useState } from 'react';
import { Profile, Source } from '../../../../../domain';
import styles from './SourceDetails.module.scss';
import { Alert, Col, Input, Label, Row } from 'reactstrap';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import { AudiencesTargeting } from '../../../../components';
import _ from 'lodash';
import { ToggleButton } from '../../../../widgets';
import { SourceTypeSettings } from './SourceTypeSettings';


interface Props {
  magazine: number,
  profile: Profile,
  source: Source,
  hasSourceName: boolean,
  hasAdvancedSettings: boolean,
  onChangeSource: (source: Source) => void
}

export const SourceSettings = ({ magazine, profile, source, hasSourceName, hasAdvancedSettings, onChangeSource }: Props) => {

  const [showAdvanced, setShowAdvanced] = useState<boolean>(hasAdvancedSettings);

  return (
    <div className={styles.settings}>
      <Row>
        <Col md={4}>
          <Label>Name:</Label>
          <Input disabled={(source.id === -1 || !hasSourceName) ? false : true} value={source.name || ''} onChange={(e) => onChangeSource({ ...source, name: e.target.value })} />
        </Col>
        <Col md={4}>
          <Label>Label:</Label>
          <Input value={source.label || ''} onChange={(e) => onChangeSource({ ...source, label: e.target.value })} />
        </Col>
        <Col md={2} className={styles.verticalBottomCentered}>
          <Label>
            <Toggle checked={source.status} onChange={(e) => onChangeSource({ ...source, status: e.target.checked })} />
            Active
          </Label>
        </Col>
        <Col md={2} className={styles.verticalBottomCentered}>
          <Label>
            <Toggle checked={source.private} onChange={(e) => onChangeSource({ ...source, private: e.target.checked })} />
            Private
          </Label>
        </Col>
      </Row>
      <Row>
        <SourceTypeSettings {...{ magazine, profile, source, onChangeSource, }} />
      </Row>
      <Row >
        <Col md={8}>
          <Label>Audiences:</Label>
          <AudiencesTargeting
            magazine={magazine}
            profile={profile}
            targetedAudiences={_.map(source.audiences, _.toString)}
            combineAudiencesWithAnd={source.combineAudiencesWithAnd ? true : false}
            changeCombineAudiencesWithAnd={(v) => { onChangeSource({ ...source, combineAudiencesWithAnd: v }) }}
            changeTargeting={(audiences) => { onChangeSource({ ...source, audiences }) }}
            hideInfo
            emptyMeansEveryone
          />
        </Col>
        <Col md={3} className={styles.verticalBottomCentered} style={{ alignItems: 'center' }}>
          <Alert style={{ width: '100%' }} color={'warning'} data-tippy-content={'This will apply as source targeting within all parent channels!'}><i className={'material-icons'}>info</i>Attention please</Alert>
        </Col>
        <Col md={1} className={styles.verticalBottomCentered} style={{ alignItems: 'center' }}>
          <ToggleButton onClick={() => { setShowAdvanced(!showAdvanced) }} color={''} active={showAdvanced}>
            <div>
              <img style={{ marginLeft: "0px", verticalAlign: "text-bottom" }} src={`/assets/icons/16/advanced_${showAdvanced ? 'grey' : 'blue'}.svg`} />
            </div>
          </ToggleButton>
        </Col>
      </Row>
      {showAdvanced &&
        <Row>
          <Col md={4}>
            <Label>Source kind:</Label>
            <Input value={source.kind || ''} onChange={(e) => { onChangeSource({ ...source, kind: e.target.value }) }} />
          </Col>
          <Col md={4}>
            <Label>Hyphenation:</Label>
            <Input value={source.hyphenation || ''} type="select" onChange={(e) => { onChangeSource({ ...source, hyphenation: e.target.value }) }}>
              <option value="">No hyphenation</option>
              <option value="nl-nl">Dutch</option>
              <option value="de-de">German</option>
              <option value="en-us">English (US)</option>
              <option value="en-gb">English (GB)</option>
            </Input>
          </Col>
        </Row>
      }
    </div>
  )
}
