import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { Article, Likes, UsersList } from '../../../domain';
import './css/UsersListModal.min.css';

import * as _ from 'lodash';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import uuid from 'uuid';
import { OrtecLoader } from '../../widgets';



export interface Props {
  // articleLikes: number,
  closeModal: () => void,
  loading: boolean,
  users?: UsersList,
}

export class ArticleLikesModal extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  calcTotalLikes = (): number => {
    const { users } = this.props;
    if (!users) {
      return 0;
    }
    return users.uids.length;
  }

  likesToTableData = (): Array<{ id: string, name: string }> => {
    const { users } = this.props;
    if (!users || _.isEmpty(users.uids)) {
      return [];
    }

    const anonymous = users.anonymous.length;

    const named = _.compact(_.map(users.named, (u) => {

      return {
        id: u.uid,
        name: `${_.get(u, 'firstName', '')} ${_.get(u, 'lastName', '')}`
      }
    }));

    if (anonymous) {
      return [...named, { id: '__anonymous', name: `${_.isEmpty(named) ? '' : '...and'} ${anonymous} ${_.isEmpty(named) ? '' : ' other'} anonymous user${anonymous > 1 ? 's' : ''}` }];
    }

    return named;

  }

  renderToolbar = (props: any) => {
    return (
      <Col md={12}>{props.components.searchField}</Col>
    );
  }

  render() {
    const { loading } = this.props;
    const closeBtn = <img className="close" onClick={this.props.closeModal.bind(this)} src="/assets/icons/bt-close.svg" />
    const options = {
      // noDataText: 'There are no likes for this article',
      toolBar: this.renderToolbar.bind(this),
    };

    const loader = <OrtecLoader size={'medium'} />

    const totalLikes = this.calcTotalLikes();

    return (
      <Modal isOpen={true} centered toggle={() => this.props.closeModal()} id="ArticleLikesModal" className={'usersListModal'}>
        <ModalHeader ><span className="headerTitle"><i className={'fa fa-smile-o'}></i> Who liked this post {totalLikes ? `(${totalLikes})` : ''}</span>{closeBtn}</ModalHeader>
        <ModalBody>
          {loading ? loader : null}
          <div className="tableDiv">
            <BootstrapTable ref="likesUsersTable" data={this.likesToTableData()} bordered={false} hover={false} version='4' options={options} search searchPlaceholder={`Search for name`}>
              <TableHeaderColumn dataField='id' hidden isKey></TableHeaderColumn>
              <TableHeaderColumn dataField='name'></TableHeaderColumn>
            </BootstrapTable>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ArticleLikesModal;
