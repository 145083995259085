import React from 'react';
import { Control, useController } from 'react-hook-form';
import { CmsItemFormData } from '../CmsItemDetails';
import { FormGroup } from 'reactstrap';
import { TranslateFunction } from '../../../../../../../domain';
import { Translations } from '../../../../../../translations';
import { MediaHelper } from '../../../../../../utils';
import styles from './CmsAttachments.module.scss';
import classNames from 'classnames';
import _ from 'lodash';
import { CmsMediaObject } from '../../CmsDomain';
import { ExtensionIcon } from '../../../../../../components/ExtensionIcon/ExtensionIcon';
import { ImmutArray } from '../../../../../../utils/ImmutablesHelper';

interface Props {
  magazine: number,
  control: Control<CmsItemFormData>
  onOpenMediaSelector: () => void
}

export const CmsAttachments = ({ magazine, control, onOpenMediaSelector }: Props) => {

  const { field, fieldState } = useController({ name: 'attachments', control });

  const attachments = field.value;

  const tr: TranslateFunction = Translations.Translate(Translations, 'Cms');

  const removeAttachment = (index: number) => {
    field.onChange(ImmutArray.remove(attachments, index));
  }


  return (
    <FormGroup className={styles.CmsAttachments}>
      {/* <Label>{tr('Attachments')}</Label> */}
      <div className={styles.existingAttachmentsContainer}>
        {_.isEmpty(attachments) ? <i style={{ fontSize: "12px" }}>No attachments</i> :
          <ul>
            {_.map(attachments, (a, index) => {
              return (
                <li key={a.filename}>
                  <CmsAttachmentItem
                    magazine={magazine}
                    mediaObject={a}
                    index={index}
                    onRemove={removeAttachment}
                  />
                </li>
              )
            })}
          </ul>
        }
      </div>
      <FormGroup>
        <div className={styles.addItemButton} onClick={onOpenMediaSelector}>+ Add Attachment</div>
      </FormGroup>
    </FormGroup>
  )
}

interface CmsAttachmentItemProps {
  magazine: number
  mediaObject: CmsMediaObject
  index: number
  onRemove: (index: number) => void
}

const CmsAttachmentItem = ({ magazine, mediaObject, index, onRemove }: CmsAttachmentItemProps) => {
  return (
    <div className={styles.attachmentItem}>
      <ExtensionIcon magazine={magazine} extension={mediaObject.extension || ''} link={mediaObject.link || ''} size={24} />
      <a href={proxifyUrl(magazine, mediaObject.link)} target={'_blank'}>{mediaObject.filename}</a>
      <i className={classNames(styles.emptyIcon, "material-icons")} onClick={() => { onRemove(index) }}>close</i>
    </div>
  )
}

const proxifyUrl = (magazine: number, url?: string): string | undefined => {
  if (!url) {
    return undefined;
  }
  return MediaHelper.proxify(magazine, url);

}