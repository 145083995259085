import React from 'react';
import { SourceTypeSettingsProps } from '../SourceTypeSettings';
import { Button, Col, Input, Label } from 'reactstrap';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import styles from '../SourceDetails.module.scss';
import { EdErrorHandler, SwalSuccess } from '../../../../../widgets';
import { API } from '../../../../../apis';

export const RSS = ({ magazine, source, onChangeSource }: SourceTypeSettingsProps) => {

  const testRss = async () => {
    if (!source.url) {
      EdErrorHandler(new Error(`RSS URL is empty`), `validating RSS URL`);
      return;
    }

    try {
      const { data: feed } = await API.oldApi.parseRSS(magazine, source.url);
      SwalSuccess.fire({
        title: `Working RSS`,
        html: `<div style="text-align:left;"><b>Title:</b> ${feed.title} </div><div style="text-align:left;"><b>Items:</b> ${feed.size}</div>`
      })
    } catch (error) {
      EdErrorHandler(error, `validating RSS URL`);
    }
  }

  return (
    <>
      <Col md={8}>
        <Label>RSS URL:</Label>
        <Input value={source.url} onChange={(e) => onChangeSource({ ...source, url: e.target.value })} />
      </Col>
      <Col md={2} className={styles.verticalBottomCentered}>
        <Button style={{ width: '100%' }} className={'inputButton'} onClick={testRss}>Test RSS</Button>
      </Col>
      <Col md={2} className={styles.verticalBottomCentered}>
        <Label
          data-tippy-content={'Choosing "Replace All" will cause the contents of the source to exactly reflect the articles currently contained in the RSS feed. This means that any articles that were previously present in the feed will be removed (deactivated) from the source and will no longer be served to the app.'}>
          <Toggle checked={source.config?.rss_replaceAll || false}
            onChange={(e) => { onChangeSource({ ...source, config: { rss_replaceAll: e.target.checked } }) }} />
          Replace all
        </Label>
      </Col>
    </>
  )
}
