
import React from 'react';
import qs from 'query-string';
import * as mime from 'mime';
import * as _ from 'lodash';
import { Media } from '../../domain';

export const Extensions: { [type: string]: string[] } = {
    images: ['png', 'jpeg', 'jpg', 'tif', 'gif', 'heic', 'heif', 'svg', 'webp', 'bmp', 'ico'],
    videos: ['3gp', 'avi', 'flv', 'm4v', 'mov', 'mp4', 'mpg', 'mpeg', 'wmv', 'vob'],
    audios: ['mp3'],
    vimeo: ['vimeo']
}

export function proxify(magazine: number | string, url: string, w?: number, h?: number): string {
    const proxyPath = `/media/${magazine}`;
    const proxyParams: any = {
        url
    }
    if (w) {
        proxyParams.w = w;
    }
    if (h) {
        proxyParams.h = h;
    }
    return `${proxyPath}?${qs.stringify(proxyParams)}`;
}

export function deProxify(magazine: number | string, url: string): string {
    const query = url.split('?')[1];
    const queryParams = qs.parse(query);
    return queryParams.url ? queryParams.url : '';
}

export function getMimeType(extension: string) {
    return mime.getType(extension) || '';
}

export function isImageExtension(extension: string) {
    return _.includes(Extensions.images, _.toLower(extension));
}
export function isVideoExtension(extension: string) {
    return _.includes(Extensions.videos, _.toLower(extension));
}
export function isVimeoExtension(extension: string) {
    return extension == 'vimeo';
}
export function isAudioExtension(extension: string) {
    return _.includes(Extensions.audios, _.toLower(extension));
}

export const isArchiveExtension = (extension: string) => {
    return _.includes(Extensions.archives, _.toLower(extension));
}
export const isFontExtension = (extension: string) => {
    return _.includes(Extensions.fonts, _.toLower(extension));
}

export const isJsonExtension = (extension: string) => {
    return _.includes(['json'], _.toLower(extension));
}
export const isPdfExtension = (extension: string) => {
    return _.includes(['pdf'], _.toLower(extension));
}
export const isCssExtension = (extension: string) => {
    return _.includes(['css', 'scss'], _.toLower(extension));
}
export const isExcelExtension = (extension: string) => {
    return _.includes(['xls', 'xlsx', 'ods'], _.toLower(extension));
}
export const isPowerPointExtension = (extension: string) => {
    return _.includes(['ppt', 'pptx'], _.toLower(extension));
}
export const isDocExtension = (extension: string) => {
    return _.includes(['doc', 'docx', 'odt'], _.toLower(extension));
}
export const isHtmlExtension = (extension: string) => {
    return _.includes(['htm', 'html'], _.toLower(extension));
}
export const isTxtExtension = (extension: string) => {
    return _.includes(['txt'], _.toLower(extension));
}
export const isCsvExtension = (extension: string) => {
    return _.includes(['csv'], _.toLower(extension));
}
export const isNonWebSupportedImage = (extension: string) => {
    return _.includes(['heic', 'heif',], _.toLower(extension));
}


export const includeMediaTypes = (media: Media[], mediaTypes: string[]) => {
    if (_.isEmpty(mediaTypes)) {
        return [...media];
    }
    let extensions: string[] = [];

    _.map(mediaTypes, (mt) => {
        if (Extensions[mt] && !_.isEmpty(Extensions[mt])) {
            extensions.push(...Extensions[mt]);
        }
    });

    return _.filter(media, (m) => _.includes(extensions, _.toLower(m.extension)));
}

export const excludeMediaTypes = (media: Media[], mediaTypes: string[]) => {
    if (_.isEmpty(mediaTypes)) {
        return [...media];
    }
    let extensions: string[] = [];

    _.map(mediaTypes, (mt) => {
        if (Extensions[mt] && !_.isEmpty(Extensions[mt])) {
            extensions.push(...Extensions[mt]);
        }
    });

    return _.filter(media, (m) => !_.includes(extensions, _.toLower(m.extension)));
}

export const isVimeoShowableStatus = (status: string) => {
    return _.includes(['available', 'transcoding', 'uploading'], status);
}

export const convertPlusToSpaces = (name: string) => {
    try {
        return name.replace(/\+/g, ' ');
    } catch (error) {
        return name;
    }
}

export const extensionFaCode = (extension: string): string => {
    if (isAudioExtension(extension)) {
        return `fa-file-audio`
    }
    if (isVideoExtension(extension)) {
        return `fa-file-video`
    }
    if (isArchiveExtension(extension)) {
        return `fa-file-archive`
    }
    if (isJsonExtension(extension)) {
        return `fa-file-code`
    }
    if (isPdfExtension(extension)) {
        return `fa-file-pdf`
    }
    if (isFontExtension(extension)) {
        return `fa-font`
    }
    if (isCssExtension(extension)) {
        return `fa-palette`
    }
    if (isExcelExtension(extension)) {
        return `fa-file-excel`
    }
    if (isPowerPointExtension(extension)) {
        return `fa-file-powerpoint`
    }
    if (isDocExtension(extension)) {
        return `fa-file-word`
    }
    if (isHtmlExtension(extension)) {
        return `fa-file-code`
    }
    if (isTxtExtension(extension)) {
        return `fa-file-alt`
    }
    if (isCsvExtension(extension)) {
        return `fa-file-csv`
    }

    return `fa-file`
}

const getEverythingAfter = (str: string, separator: string): string => {
    const s = str.split(separator);
    return s[s.length - 1];
}

export const extractFullNameFromPath = (path: string): string => {
    return getEverythingAfter(path, '/');
}

export const extractExtensionFromPath = (path: string): string => {
    return getEverythingAfter(extractFullNameFromPath(path), '.');
}

export const extractNameFromPath = (path: string): string => {
    return _.replace(extractFullNameFromPath(path), `.${extractExtensionFromPath(path)}`, '');
}

const PROTECTED_ICS_BUCKET_REGEX = new RegExp(/.+\.(imgzine|orteccloud)\.com\/protected\/.+$/);

export const isFromProtectedIcsBucket = (magazine: number, url: string): boolean => {
    const deProxifiedUrl = deProxify(magazine, url) || url;
    return PROTECTED_ICS_BUCKET_REGEX.test(deProxifiedUrl);
}