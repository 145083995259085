import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Notification } from '../../../../../domain';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import './css/NotificationPreview.min.css';
import * as _ from 'lodash';
import { Translations } from '../../../../translations';
import { DateHelper } from '../../../../utils';

export interface Props {
  notification?: Notification,
}


export class NotificationPreview extends Component<Props> {


  // statusFormatter(status:number){
  //     switch(status){
  //       case 9 :
  //       return (<span><i className="fa fa-circle status-sent" aria-hidden="true"></i> Sent</span>);
  //       case 1 :
  //       return (<span><i className="fa fa-circle status-scheduled" aria-hidden="true"></i> Scheduled</span>);
  //       case 0 :
  //       return (<span><i className="fa fa-circle status-notSent" aria-hidden="true"></i> Not sent</span>);
  //       default:
  //       return status.toString();
  //     }

  // }
  dateFormatter(date: string) {
    return DateHelper.dateTimeToLocaleString(date);
  }
  nameFormatter(notification: Notification) {
    return _.get(notification, 'magazine.label') || _.get(notification, 'magazine.name') || 'app name';
  }
  logoFormatter(icon: string) {
    return icon || '/assets/icons/app_icon_default@3x.png';
  }
  render() {
    const { notification } = this.props;
    // const data = [articleStats]
    const tr = Translations.Translate(Translations, 'PushNotifications');

    return (
      <div id="NotificationPreview">
        {Translations.EnableTranslations(Translations)}
        <div className='headerDiv'>{tr('preview')}</div>
        <div className="content">
          {!notification ?
            <div>{tr('noPreview')}</div>
            :
            <Fragment>
              <div className="notificationPreviewContainer">
                <div className='header'>
                  <div className="appLogo"><img src={this.logoFormatter(notification.magazine.icon)} /></div>
                  <div className="appName">{this.nameFormatter(notification)}</div>
                  <div className="date">{this.dateFormatter(notification.scheduledOn)}</div>
                </div>
                <div className="content">{notification.message}</div>
              </div>
            </Fragment>
          }
        </div>
      </div>
    );
  }
}

export default NotificationPreview;
