import _ from "lodash";
import { KeyTypeWithOther, KeyType, KEY_TYPES, ALL_KEY_TYPES } from "./domain";
import validator from 'validator';
import { Channel, Translation } from "../../../../../../domain";
import { ChannelDetail } from '../domain';

export const isOtherKeyType = (key: string): boolean => {
  return !_.includes(_.map(KEY_TYPES, 'key'), key);
}

export const keyToKeyType = (key: string): KeyTypeWithOther => {
  return isOtherKeyType(key) ? '' : key as KeyType;
}

export const keyTypePlaceholder = (key: string): string => {
  const keyType = _.find(ALL_KEY_TYPES, (k) => k.key == keyToKeyType(key));
  return keyType?.placeholder || '';
}

export const isValueEmpty = (value: string): boolean => {
  return value ? false : true;
}

export const isValidUrl = (value: string): boolean => {
  return validator.isURL(value, { protocols: ['https'] });
}

export const isValueInvalid = (key: string, value: string, allowEmpty?: boolean): boolean => {
  if (isValueEmpty(value) && !allowEmpty) {
    return false;
  }
  switch (keyToKeyType(key)) {
    case 'url':
      return !isValueEmpty(value) && !isValidUrl(value);
    default:
      return false;
  }
}

export const removeChannelInfoTranslationsForNonExistingFields = (channel: ChannelDetail): Translation[] => {

  const translations = channel.translations || [];

  const channelInfoTranslations = _.filter(translations, (t) => t.key === 'channelinfo');
  const otherTranslations = _.filter(translations, (t) => t.key !== 'channelinfo');

  //if not translations related to channel info then no filtering is needed. Return all the translations.
  if (_.isEmpty(channelInfoTranslations)) {
    return translations;
  }

  const channelInfoKeys = getChannelInfoKeys(channel);

  const newChannelInfoTranslations = _.map(channelInfoTranslations, (t) => {
    try {
      const valueObj = JSON.parse(t.value);
      const newValueObj = _.pick(valueObj, channelInfoKeys);
      return {
        ...t,
        value: JSON.stringify(newValueObj, null, 2)
      }
    } catch (error) {
      console.info(`Error in JSON parsing translation value.`);
      return t;
    }
  });

  return [...otherTranslations, ...newChannelInfoTranslations];

}

const getChannelInfoKeys = (channel: ChannelDetail): string[] => {
  if (!channel.channelInfo) {
    return [];
  }
  try {
    const channelInfoObject = JSON.parse(channel.channelInfo);
    return _.keys(channelInfoObject);
  } catch (error) {
    console.info(`Error in JSON parsing channelInfo.`);
    return [];
  }
}

export const removeChannelInfoEmptyValues = (channel: ChannelDetail): string | undefined => {
  if (!channel.channelInfo) {
    return undefined;
  }
  try {
    const channelInfoObject = JSON.parse(channel.channelInfo);
    const newChannelInfo = _.pickBy(channelInfoObject, (p) => p ? true : false);
    return JSON.stringify(newChannelInfo, null, 2);
  } catch (error) {
    console.info(`Error in JSON parsing channelInfo.`);
    return undefined;
  }
}
