import React, { Component } from 'react';
import { I18n } from '../../translations';
// import * as _ from 'lodash';
// import './css/StatBox.min.css';
const ScaleText = require('react-scale-text');

export interface Props {
  text: string,
  value?: number | string,
  icon: string
  scalableValue?: boolean,
  info?: string,
}

export class StatBox extends Component<Props> {
  calcFontSize = (value?: number | string): string => {
    if (!value) {
      return '';
    }
    const length = value.toString().length;
    if (length > 9) {
      return '20px';
    } else if (length > 7) {
      return '24px';
    } else if (length > 5) {
      return '32px';
    } else {
      return '48px';
    }
  }
  render() {
    const { text, value, icon, scalableValue, info } = this.props;
    const loader = <i className="fa fa-spinner fa-spin"></i>
    const iconPath = icon ? "/assets/icons/" + icon + ".svg" : undefined
    const valueText = !scalableValue ?
      (value != undefined ? value.toLocaleString() : loader) :
      (value != undefined ? <ScaleText widthOnly={true} minFontSize={20} maxFontSize={48}>{value.toLocaleString()}</ScaleText> : loader);
    return (
      <div className="StatBox">
        {iconPath ?
          <img src={iconPath} /> :
          ''
        }
        <div className="valueDiv">{valueText}</div>
        <div className="textDiv"><I18n t={text} n='Metrics' /></div>
        {info &&
          <div className="infoDiv" data-tippy-content={info} data-tippy-placement="top"><i className='material-icons-outlined'>info</i></div>
        }
      </div>
    );
  }
}

export default StatBox;
