import classNames from 'classnames';
import React from 'react';
import { Button } from 'reactstrap';
import { BuilderQueryOperator } from '../../domain';
import { BuilderHelper, styles } from '../AudienceBuilder';

interface Props {
  index: number,
  builderQueryOperator: BuilderQueryOperator,
  noRights: boolean,
  onRemove: (index: number) => void
}

export const ConditionToolbar = ({ index, builderQueryOperator, noRights, onRemove }: Props) => {
  return (
    <div className={styles.conditionToolbar}>
      <div className={styles.operatorLabel}>{BuilderHelper.getQueryOperatorLabel(builderQueryOperator)}</div>
      <div style={{ flex: 1 }}><hr /></div>
      <div className={classNames(styles.deleteCondition, { disabled: noRights })} onClick={(e) => { if (!noRights) { onRemove(index) } }}>
        <Button disabled={noRights} color="secondary" outline><i className={'fa fa-trash'} ></i></Button>
      </div>
    </div>
  )

}
