import React, { useMemo } from 'react';
import styles from '../NcSlidesManager.module.scss';
import { NcSlideshowFormData } from '../NcSlideshowDetails';
import { ControllerRenderProps, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { NcSlideConfigToggle } from '../../../components/NcSlideConfigToggle';
import _ from 'lodash';
import { NcSlideConfigSelect } from '../../../components/NcSlideConfigSelect';
import { Profile } from '../../../../../../domain';
import { NcSlideConfigTextInput } from '../../../components/NcSlideConfigTextInput';
import { NcSlideConfigNumberInput } from '../../../components/NcSlideConfigNumberInput';
import { TippyReact } from '../../../../../components';
import classNames from 'classnames';

/**
 * Regex found in https://stackoverflow.com/a/50777192 
 */
const VIMEO_VALID_LINK_REGEX = new RegExp(/(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/g);


interface Props {
  magazine: number,
  profile: Profile,
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  activeIndex: number
  setError: UseFormSetError<NcSlideshowFormData>
  clearErrors: UseFormClearErrors<NcSlideshowFormData>
}

export const NcSlideTypeConfig_Video = ({ magazine, profile, field, activeIndex, setError, clearErrors }: Props) => {

  const slide = _.find(field.value, (s) => s.sort === activeIndex);
  const playFullVideo = slide?.config.playFullVideo ? true : false;
  const url = _.get(slide?.config, 'url');
  const from = _.get(slide?.config, 'from') || 0;
  const to = _.get(slide?.config, 'to') || 1;


  const isValidVimeoUrl = useMemo(() => {
    if (!url) {
      return false;
    }
    return url.match(VIMEO_VALID_LINK_REGEX);
  }, [url])

  useMemo(() => {
    if (!isValidVimeoUrl) {
      setError(`slides.${activeIndex}.config.url`, { type: 'videoSlideValidation', message: 'No valid vimeo url for video slide' });
    } else {
      clearErrors(`slides.${activeIndex}.config.url`);
    }
  }, [isValidVimeoUrl, activeIndex, setError, clearErrors])



  return (
    <>
      <div className={styles.flexRow}>
        <div style={{ flex: 2 }}>
          <NcSlideConfigSelect
            {...{ field, activeIndex }}
            fieldName={`videoType`}
            label={`Video type:`}
            options={[
              { value: 'vimeo', label: 'Vimeo' },
              // { value: 'fill', label: 'Fill' }
            ]}
          />
        </div>
        <TippyReact content={`A video url should be entered!`} config={{ placement: 'bottom', disabled: isValidVimeoUrl ? true : false }}>
          <div style={{ flex: 4 }} className={classNames({ [styles.requiredValueMissing]: !isValidVimeoUrl ? true : false })}>
            <NcSlideConfigTextInput
              {...{ field, activeIndex }}
              fieldName={`url`}
              label={`URL:`}
              placeholder={`Add video url`}
            />
          </div>
        </TippyReact>
        <div style={{ flex: 1 }}>
          <NcSlideConfigToggle
            {...{ field, activeIndex }}
            fieldName={`mute`}
            label={`Mute:`}
          />
        </div>
        <div style={{ flex: 1 }}>
          <NcSlideConfigToggle
            {...{ field, activeIndex }}
            fieldName={`playFullVideo`}
            label={`Play full video:`}
          />
        </div>
      </div>
      {!playFullVideo &&
        <div className={styles.flexRow}>
          <div style={{ minWidth: '100px' }}>
            <NcSlideConfigNumberInput
              {...{ field, activeIndex }}
              fieldName={`from`}
              label={`From:`}
              min={-0}
              inputWrapperClass={styles.durationInput}
            />
          </div>
          <TippyReact content={`This number should be larger than from`} config={{ placement: 'bottom', disabled: (to > from) ? true : false }}>
            <div style={{ minWidth: '100px' }} className={classNames({ [styles.requiredValueMissing]: (to <= from) ? true : false })}>
              <NcSlideConfigNumberInput
                {...{ field, activeIndex }}
                fieldName={`to`}
                label={`To:`}
                min={from ? (from + 1) : 1}
                inputWrapperClass={styles.durationInput}
              />
            </div>
          </TippyReact>
          <div style={{ flex: 1 }}></div>
        </div>
      }
    </>
  )
};
