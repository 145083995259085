import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import './css/Forms.min.css'

import { Profile } from '../../../../../domain';
// import { ArticleSelector } from '../../../../components';
import moment from 'moment';
import { FormsOverviewTable } from './FormsOverviewTable';
import { FormDetails } from './FormDetails';
import { SelectTypeModal } from './SelectTypeModal';
import { FormResults } from './FormResults';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as ph from '../../../../utils/routerHelper';

export interface Props extends RouteComponentProps {
  magazine: string,
  profile: Profile,
}
export interface State {
  createForm: boolean,
  newFormType?: string,
  historyLastUpdate: number
}

export class Forms extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      createForm: false,
      historyLastUpdate: moment().valueOf()
    };
  }

  clickFormHandler(form: number, formtype?: string) {
    ph.setQueryParams(this.props.history, { form });
    if (formtype) {
      this.setState({ newFormType: formtype, createForm: false });
    }
  }

  clickFormResultsHandler(form: number) {
    ph.setQueryParams(this.props.history, { formResults: form });
  }

  refreshHistoryHandler() {
    this.setState({ historyLastUpdate: moment().valueOf() });
  }


  closeFormDetailsHandler(updateOverview?: boolean) {
    ph.setQueryParams(this.props.history, { form: undefined });
    if (updateOverview) {
      this.refreshHistoryHandler();
    }
  }
  closeFormResultsHandler(updateOverview?: boolean) {
    ph.setQueryParams(this.props.history, { formResults: undefined });
    if (updateOverview) {
      this.refreshHistoryHandler();
    }
  }

  selectTypeModalCloseHandler = () => {
    this.setState({ createForm: false });
  }

  createNewFormHandler = () => {
    this.setState({ createForm: true });
  }

  render() {
    const { magazine, profile } = this.props;
    const shownForm = ph.getQueryParamNumber(this.props.location, 'form');
    const shownResults = ph.getQueryParamNumber(this.props.location, 'formResults');

    const { historyLastUpdate, createForm, newFormType } = this.state;
    return (
      <Fragment>
        {!shownForm ? null :
          <FormDetails magazine={magazine} profile={profile} formId={shownForm} newFormType={newFormType} closeHandler={this.closeFormDetailsHandler.bind(this)} />
        }
        {!shownResults ? null :
          <FormResults magazine={magazine} profile={profile} formId={shownResults} closeHandler={this.closeFormResultsHandler.bind(this)} />
        }
        <div className={(shownForm || shownResults) ? 'limitedHeight' : ''}>
          <FormsOverviewTable key={`formsOverview-${historyLastUpdate}`} magazine={magazine} profile={profile} clickFormHandler={this.clickFormHandler.bind(this)} clickResultsHandler={this.clickFormResultsHandler.bind(this)} createNewFormHandler={this.createNewFormHandler.bind(this)} />
        </div>
        {!createForm ? null :
          <SelectTypeModal magazine={magazine} createHandler={this.clickFormHandler.bind(this)} closeHandler={this.selectTypeModalCloseHandler.bind(this)} />
        }
      </Fragment>

    );
  }
}
export const FormsWithRouter = withRouter(Forms);
export default Forms;
