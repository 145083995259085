import _, { isNumber } from "lodash";
import moment from "moment";
import { FieldConfig } from "../../People/domain";
import { BuilderQueryOperator, Condition, ConditionOperator } from "../domain";

const getKeyOfMongoObject = (object: Object): string => {
  try {
    return Object.keys(object)[0];
  } catch (error) {
    return ''
  }
}

const getValueOfMongoObject = (object: any): any => {
  try {
    return object[getKeyOfMongoObject(object)];
  } catch (error) {
    return undefined;
  }
}

export const getQueryOperator = (query: Object): BuilderQueryOperator => {
  const key = getKeyOfMongoObject(query);
  return _.includes(['$and', '$or'], key) ? key as BuilderQueryOperator : '$and';
}

export const getQueryExpressions = (query: Object): Condition[] => {
  return getValueOfMongoObject(query);
}

export const getQueryOperatorLabel = (op: BuilderQueryOperator): 'and' | 'or' => {
  switch (op) {
    case '$and':
      return 'and';
    case '$or':
      return 'or';
  }
}

export const getExpressionAsCondition = (expr: any): Condition => {
  let key = getKeyOfMongoObject(expr);
  let mongoValue = getValueOfMongoObject(expr);
  let operator = getKeyOfMongoObject(mongoValue);
  let value = getValueOfMongoObject(mongoValue);

  if (key == '$expr') { //date
    key = getValueOfMongoObject(getValueOfMongoObject(expr))[0];
    if (key && _.isString(key)) {
      key = key.substr(1);
    }
    operator = getKeyOfMongoObject(getValueOfMongoObject(expr));
    value = getValueOfMongoObject(getValueOfMongoObject(getValueOfMongoObject(getValueOfMongoObject(expr))[1]));
    if (value == 'new Date()') { //lessXdays or moreXdays
      value = getValueOfMongoObject(getValueOfMongoObject(getValueOfMongoObject(expr))[1]);
      const op = getKeyOfMongoObject(getValueOfMongoObject(expr));
      operator = op == '$lt' ? 'moreXdays' : 'lessXdays';
    }
  }

  return {
    key,
    operator: operator as ConditionOperator,
    value
  }
}

export const getConditionFieldType = (condition: Condition, peopleConfig: FieldConfig[]): string => {
  return getFieldType(condition.key, peopleConfig);
}
export const getFieldType = (fieldKey: string, peopleConfig: FieldConfig[]): string => {
  const field = _.find(peopleConfig, (f) => f.key == fieldKey);
  return field?.type ?? '';
}

export const getConditionDisabledOperatorOptions = (condition: Condition, peopleConfig: FieldConfig[]): ConditionOperator[] => {
  const type = getConditionFieldType(condition, peopleConfig);
  switch (type) {
    case 'date':
      return ['$in', '$nin'];
    case 'checkbox':
      return ['$in', '$nin', '$gt', '$lt', 'lessXdays', 'moreXdays']
    default:
      return ['lessXdays', 'moreXdays'];
  }
}

export const convertElementToMongoExpression = (condition: Condition, fieldType?: string): any => {
  if (fieldType == 'date') {
    if (condition.operator == 'lessXdays') {
      return {
        "$expr": {
          "$gt": [`$${condition.key}`, { "$subtract": ["new Date()", daysToMiliseconds(condition.value)] }]
        }
      }
    }
    if (condition.operator == 'moreXdays') {
      return {
        "$expr": {
          "$lt": [`$${condition.key}`, { "$subtract": ["new Date()", daysToMiliseconds(condition.value)] }]
        }
      }
    }
    return {
      "$expr": {
        [condition.operator]: [`$${condition.key}`, { "$dateFromString": { "dateString": moment(condition.value, 'YYYY-MM-DD').utc(true).format(`YYYY-MM-DDT00:00:00[Z]`) } }]
      }
    }
  }
  return {
    [condition.key]: {
      [condition.operator]: condition.value
    }
  }
}

export const adjustConditionValueAccordingToConditionOperator = (condition: Condition, operator: ConditionOperator, fieldType: string): any => {
  const { value } = condition;

  try {
    if (_.includes(['$in', '$nin'], operator)) {
      if (_.isArray(value)) {
        return value;
      }
      return _.isEmpty(value) ? [] : [value];
    } else if (_.includes(['lessXdays', 'moreXdays'], operator)) {
      return _.includes(['lessXdays', 'moreXdays'], condition.operator) ? milisecondsToDays(value[1]) : 0;
    } else {
      if (fieldType == 'checkbox') {
        return _.isEmpty(value) || value === 'false' ? false : true;
      }
      if (_.isArray(value)) {
        return value[0] ?? '';
      }
      return value;
    }

  } catch (error) {
    return value;
  }
}

export const adjustConditionOperatorAccordingToConditionFieldType = (condition: Condition, fieldType: string): ConditionOperator => {
  const { operator } = condition;
  switch (fieldType) {
    case 'date':
      if (operator === '$in') {
        return '$eq';
      } else if (operator === '$nin') {
        return '$ne';
      }
      return operator;
    case 'checkbox':
      if (operator === '$nin') {
        return '$ne';
      }
      return '$eq';
    default:
      if (_.includes(['lessXdays', 'moreXdays'], operator)) {
        return '$eq'
      }
      return operator;
  }
}

export const defaultElementExpression = (): Condition => {
  return {
    key: 'uid',
    operator: '$eq',
    value: ''
  }
}

export const daysToMiliseconds = (days: number): number => {
  if (!days) {
    return 0;
  }
  return days * 24 * 60 * 60 * 1000;
}

export const milisecondsToDays = (ms: number): number => {
  if (!ms) {
    return 0;
  }
  return ms / (24 * 60 * 60 * 1000);
}

export const isQuerySupported = (query: Object): boolean => {
  try {
    const queryOperator = getKeyOfMongoObject(query);
    if (!_.includes(['$and', '$or'], queryOperator)) {

      return false;
    }

    const conditions = _.map(getQueryExpressions(query), getExpressionAsCondition);

    const unsupportedCondition = _.find(conditions, (c) => _.includes(['$and', '$or'], c.key));

    return unsupportedCondition ? false : true;

  } catch (error) {
    return false;
  }

} 