import React, { Component, Fragment } from 'react';
import { StatsFilter, Profile } from '../domain';

export interface FilterContextInterface {
  statsFilter: StatsFilter,
  periodFilterHandler: (period: string) => void,
  audienceFilterHandler: (audience: number) => void,
}

const ctxt = React.createContext<FilterContextInterface | null>(null);

export const FilterContextProvider = ctxt.Provider;
export const FilterContextConsumer = ctxt.Consumer;