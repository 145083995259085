import React, { ReactNode } from 'react';
import styles from './FullPageModal.module.scss';

interface Props {
  children: ReactNode
  isLoading: boolean
  onClose: () => void
}

export const FullPageModalHeader = ({ children, isLoading, onClose }: Props) => {
  return (
    <div className={styles.FullPageModalHeader}>
      <img src="/assets/icons/bt_close_bigger.svg" onClick={() => onClose()} style={{ cursor: 'pointer' }} />
      {!isLoading && children}
    </div>
  )
}
