import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { z } from 'zod';

import { ModalBody, Form, FormGroup, Label, Col, Input, ModalFooter, Button, FormText } from 'reactstrap';
import { EdErrorHandler, LoadingButton, Swal, SwalError, SwalSuccess, UploadingInfo, UploadProgressBar } from '../../../widgets';
import { BucketPart, UploadPromise, UploadPromiseError } from '../domain';
import { API } from '../../../apis';
import * as tus from 'tus-js-client';
import Toggle from 'react-toggle';

import Bottleneck from 'bottleneck';
import { isVimeoBucket, pathJoin } from '../MediaManagerSelectorHelper';
import { CreatableSingleSelect, string2SelectOption } from '../../ReactSelect/ReactSelect';
import { GenericModal } from '../../GenericModal/GenericModal';
import GenericModalHeader from '../../GenericModal/GenericModalHeader';
import { Media } from '../../../../domain';

import styles from './MediaUploadModal.module.scss';
import classNames from 'classnames';
import { MediaHelper } from '../../../utils';
import { ImageCropper } from '../../ImageCropper/ImageCropper';
import { ExtensionIcon } from '../../ExtensionIcon/ExtensionIcon';
import { Crop } from 'react-image-crop';
import TippyReact from '../../TippyReact';
import { ImmutArray } from '../../../utils/ImmutablesHelper';
import { ImagePreviewIcon } from './ImagePreviewIcon';


interface CroppableFile {
  file: File,
  crop: Crop | undefined, // the crop area 
  blob: Blob | undefined, // the cropped image
}

interface Props {
  magazine: number,
  closeModal: (reload?: boolean) => void,
  bucket: string,
  part: BucketPart
  folderPath: string
  subFolders: string[]
  allBucketMedia: Media[]
}



export default function MediaUploadModal(props: Props) {
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [croppableFiles, setCroppableFiles] = useState<CroppableFile[] | undefined>(undefined)
  const [subFolder, setSubFolder] = useState<string | undefined>(undefined)
  const [fileInputKey, setFileInputKey] = React.useState(Date.now())
  const [zipMode, setZipMode] = useState(false)
  const [uploadingInfo, setUploadingInfo] = useState<UploadingInfo | undefined>(undefined)
  const [cropperFileIndex, setCropperFileIndex] = useState<number>();

  const files = useMemo(() => {
    if (!croppableFiles) {
      return undefined;
    }
    return _.map(croppableFiles, (cf) => cf.file);
  },
    [croppableFiles]);

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const triggerFileInputClick = useCallback(() => {
    fileInputRef.current?.click();
  },
    [],
  )

  const { allBucketMedia, magazine, bucket, part, closeModal, folderPath } = props;
  const subFolderPath = subFolder ? pathJoin(folderPath, subFolder) : folderPath
  const onFileChange = (fs: FileList) => {
    const fileArray = Array.from(fs);
    setCroppableFiles(_.map(fileArray, (f) => {
      return {
        file: f,
        crop: undefined,
        blob: undefined,
      }
    }))
    if (!isExtractable(fileArray) && zipMode) {
      setZipMode(false)
    }
  }
  const validSubFolder = !subFolder || isValidResourcePart(subFolder, 'folder')

  const uploadMedia = async () => {
    if (!files) {
      Swal.fire({
        title: "Error!",
        text: 'You have to select at least one file first'
      });
      return;
    }

    if (isVimeoBucket(bucket)) {
      const videoFiles = _.filter(files, (f) => _.includes(f.type, 'video'));
      const uploadPromises = _.compact(_.map(videoFiles, f => uploadVideoToVimeo(magazine, f)));
      try {
        setIsModalLoading(true)
        await Promise.all(uploadPromises);
        Swal.fire({
          title: "Success!",
          text: `${videoFiles.length} Video${videoFiles.length == 1 ? ' has' : 's have'} been uploaded successfully!`,
        })
      } catch (error) {
        EdErrorHandler(error, `uploading video files to vimeo`);
      } finally {
        setIsModalLoading(false)
        closeModal(true);
      }
    } else {

      const uploadFileList = _.map(files, (f, index) => {
        const croppedImageBlob = croppableFiles ? croppableFiles[index]?.blob : undefined;
        return croppedImageBlob ? new File([croppedImageBlob], f.name, { type: "image/png" }) : f;
      });

      const uploadPromises: UploadPromise[] = createUploadPromises(magazine, bucket, part, uploadFileList, zipMode, subFolderPath, allBucketMedia)
      if (_.isEmpty(uploadPromises)) {
        setCroppableFiles(undefined)
        setFileInputKey(Date.now())
        closeModal(true);
        return;
      }
      setIsModalLoading(true)

      let uploaded = 0;
      setUploadingInfo({ totalItems: uploadPromises.length, uploaded })

      const { successes, errorReport } = await runUploadPromises(zipMode, uploadPromises, () => {
        uploaded++;
        setUploadingInfo({ totalItems: uploadPromises.length, uploaded })
      })

      handleErrorReport(errorReport, successes, uploadPromises.length)
      setUploadingInfo(undefined)
      setIsModalLoading(false)
      closeModal(true);
    }
  }

  const openCropper = (index: number) => {
    setCropperFileIndex(index);
  }
  const closeCropper = () => {
    setCropperFileIndex(undefined);
  }

  const onRemoveFromUploadList = (index: number) => {
    setCroppableFiles((prevState) => {
      if (!prevState) {
        return undefined;
      }
      return ImmutArray.remove(prevState, index);
    });
  }

  const onCrop = (crop: Crop | undefined, blob: Blob | undefined) => {
    if (cropperFileIndex === undefined) {
      return;
    }
    setCroppableFiles((prevState) => {
      if (!prevState) {
        return undefined;
      }
      return ImmutArray.update(prevState, cropperFileIndex, { file: prevState[cropperFileIndex].file, crop, blob });
    })
  }

  const cropperFile = useMemo(() => {
    if (cropperFileIndex === undefined || !files || !files[cropperFileIndex]) {
      return undefined;
    }
    return files[cropperFileIndex];
  }, [cropperFileIndex, files]);

  return (
    <>
      <GenericModal isOpen={true} id={'ImportModal'} toggle={() => closeModal()} size={'lg'} keyboard={!isModalLoading} backdrop={isModalLoading ? false : true} scrollable>
        <GenericModalHeader title='Upload media' onClose={() => closeModal(false)} />
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label className={styles.inputLabel} sm={12}>{`Media`}</Label>
              <Col sm={12}>
                <input key={fileInputKey}
                  ref={fileInputRef}
                  className={styles.fileInputButton}
                  type="file"
                  accept={isVimeoBucket(bucket) ? 'video/*' : undefined}
                  multiple={true}
                  onChange={(e) => { e.target.files ? onFileChange(e.target.files) : null }}
                />
                <div className={classNames(styles.fileInputText, styles.text, styles.opacity7)} onClick={() => triggerFileInputClick()}>{!files || _.isEmpty(files) ? `No files chosen` : `${files.length} file${files.length !== 1 ? 's' : ''}`}</div>
              </Col>
            </FormGroup>
            {!_.isEmpty(files) &&
              <FormGroup row>
                <Col sm={12}>
                  <ul className={styles.fileListPreview}>
                    {_.map(files, (f, index) => {
                      const extension = MediaHelper.extractExtensionFromPath(f.name);
                      if (MediaHelper.isImageExtension(extension)) {
                        const croppedImage = croppableFiles ? croppableFiles[index]?.blob : undefined;
                        const imgFile = croppedImage || f;
                        return (
                          <li key={`${f.name}-${index}`} className={styles.flexRowContainer}>
                            <ImagePreviewIcon
                              imageFile={imgFile}
                            />
                            <span style={{ flex: 1 }}>{f.name}</span>
                            {extension !== 'svg' &&
                              <TippyReact content={`Crop Image`}><div><i className={classNames(`fa fa-crop`, styles.clickable)} onClick={() => openCropper(index)}></i></div></TippyReact>
                            }
                            <TippyReact content={`Remove`}><div><i className={classNames(`fa fa-times`, styles.clickable, styles.red)} onClick={() => onRemoveFromUploadList(index)}></i></div></TippyReact>
                          </li>
                        );
                      }
                      return (
                        <li key={`${f.name}-${index}`} className={styles.flexRowContainer}>
                          <ExtensionIcon magazine={magazine} extension={extension} link={''} size={40} />
                          <span style={{ flex: 1 }}>{f.name}</span>
                          <TippyReact content={`Remove`}><div><i className={classNames(`fa fa-times`, styles.clickable, styles.red)} onClick={() => onRemoveFromUploadList(index)}></i></div></TippyReact>
                        </li>
                      )
                    })}
                  </ul>
                </Col>
              </FormGroup>
            }
            {isExtractable(files) &&
              <FormGroup row>
                <Col sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <Toggle checked={zipMode} onChange={(e) => setZipMode(e.target.checked)} />
                  <Label sm={3}>Extract zip</Label>
                </Col>
              </FormGroup>
            }
            {!isVimeoBucket(bucket) &&
              <FormGroup row>
                <Label className={styles.labelRow} sm={12}>
                  <span className={styles.inputLabel}>{'Folder'}  </span>
                  <img src="/assets/icons/16/info.svg" />
                  <span className={classNames(styles.text, styles.opacity7)}>Leave it empty to upload to the current folder</span>
                </Label>
                <Col sm={12}>
                  <CreatableSingleSelect
                    invalid={!validSubFolder}
                    isClearable
                    placeholder='Select an existing folder or type the name of a new folder'
                    formatCreateLabel={v => `Click here to create "${v}" folder`}
                    value={subFolder}
                    options={props.subFolders.map(string2SelectOption)}
                    onChange={(v) => { setSubFolder(v) }}
                    menuPosition='fixed'
                  />
                  {!validSubFolder && <FormText color={'danger'}>Allowed characters: "a-z A-Z 0-9 _ - . ~ @"</FormText>}
                </Col>
              </FormGroup>
            }
          </Form>
        </ModalBody>
        <ModalFooter>
          {uploadingInfo ?
            <UploadProgressBar uploadingInfo={uploadingInfo} max={uploadingInfo.totalItems} value={uploadingInfo.uploaded} />
            : <>
              <div style={{ flexGrow: 1 }}></div>
              <Button outline color="secondary" onClick={() => { closeModal(false) }}>Cancel</Button>
              <LoadingButton disabled={!validSubFolder || !files?.length} loading={isModalLoading} onClick={uploadMedia} text={`Upload`} />
            </>
          }
        </ModalFooter>
      </GenericModal >
      {cropperFile && cropperFileIndex !== undefined &&
        <ImageCropper
          magazine={magazine}
          file={cropperFile}
          initCrop={croppableFiles ? croppableFiles[cropperFileIndex]?.crop : undefined}
          onCrop={onCrop}
          onClose={closeCropper}
        />
      }
    </>
  )

}

// ─── Helper Functions ────────────────────────────────────────────────────────

const isExtractable = (files?: File[]): boolean => {
  if (!files || files.length !== 1) {
    return false;
  }

  return files[0].type == 'application/zip';
}


const generateErrorReport = (errorReport: UploadPromiseError[]) => {
  if (_.isEmpty(errorReport)) {
    return '';
  }
  const listItems = _.map(errorReport, (er) => {
    const msg = er.error?.response?.status == 413 ? `File too large! Files in media manager should be less than 20MB.` : `${er.error?.response?.data || er.error?.message || er.error}`
    return `<li>${er.fileName} <pre><code style="white-space:normal">${msg}</code></pre></li>`;
  });

  return `<div class="alert alert-danger">
  <div>The following ${errorReport.length} file${errorReport.length == 1 ? '' : 's'} failed to upload:</div>
  <div style="overflow:auto;max-height:calc(100vh - 450px)"><ul>
  ${listItems.join('')}
  </ul></div>
  </div>`
}

const handleErrorReport = (errorReport: UploadPromiseError[], successes: number, outOf: number) => {
  const errors = errorReport.length;

  if (errors == 0) {
    SwalSuccess.fire({
      title: 'Success!',
      text: `${successes == 1 ? '' : 'All '}${successes} item${successes == 1 ? ' has' : 's have'} been uploaded successfully!`
    })
  } else if (successes == 0) {
    SwalError.fire({
      title: 'Error!',
      text: `${errors == 1 ? '' : 'All '}${errors} item${errors == 1 ? ' has' : 's have'} NOT been uploaded successfully!`,
      footer: generateErrorReport(errorReport)
    })
  } else {
    Swal.fire({
      type: 'warning',
      title: 'Warning',
      text: `Only ${successes} out of ${outOf} ${successes == 1 ? 'has' : 'have'} been uploaded successfully!`,
      footer: generateErrorReport(errorReport)
    })
  }
}

const uploadVideoToVimeo = async (magazine: number, file: File) => {
  try {
    const { data } = await API.vimeo.uploadVideo(magazine, file);
    const { upload_link } = data;
    // const reader = new FileReader();
    // const fileData = reader.readAsBinaryString(file);
    const upload = new tus.Upload(file, {
      uploadUrl: upload_link,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onError: function (error) {
        console.log("Failed because: " + error)
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
        console.log(bytesUploaded, bytesTotal, percentage + "%")
      },
      onSuccess: function () {
        console.log("Download %s from %s", _.get(upload, 'file.name'), upload.url);
      }
    });
    upload.start();

  } catch (error) {
    EdErrorHandler(error, `uploading video file to vimeo`);
  }
}


const FOLDER_PART_REGEX = /^[\w\-\.\~\@]+$/ //for now we block space and (+)
const FILE_PART_REGEX = /^[\w\-\.\~\@\+\ ]+$/

/**
 * Validates that a resource part only contains allowed characters
 * @param part
 */
const isValidResourcePart = (part: string, type: 'folder' | 'file') => {
  if (part.length > 300) {
    return false;
  }
  // allowed chars: "a-z A-Z 0-9 _ - . ~ @ +"
  // Also single space is allowed but converted to + by dapi
  const regex = type === 'file' ? FILE_PART_REGEX : FOLDER_PART_REGEX;
  return regex.test(part);
}

const createResourcePath = (folderPath: string, fileName: string) => {
  const fullPath = pathJoin(folderPath, fileName)
  const resourcePath = encodeURI(_.replace(fullPath, new RegExp(' ', 'g'), '+'))
  return resourcePath

}

const createUploadPromises = (magazine: number, bucket: string, part: string, files: File[], zipMode: boolean, folderPath: string, allBucketMedia: Media[]) => {
  const uploadPromises: UploadPromise[] = _.compact(_.map(files, (f) => {
    let fileName = f.name;
    const allResourcePaths = allBucketMedia.map(m => m.resourcePath);

    while (!fileName
      || !isValidResourcePart(fileName, 'file')
      || _.includes(allResourcePaths, createResourcePath(folderPath, fileName))
    ) {
      const newName = window.prompt
        (
          (fileName
            ? (isValidResourcePart(fileName, 'file')
              ? `There is already a file with this name! Please give a new name.`
              : `File name has invalid characters! Please give a new name. Allowed characters "a-z A-Z 0-9 _ - . ~ @" and single space.`)
            : `File name cannot be empty! Please give a name.`
          ) + '\nWarning: please make sure to keep the extension (i.e. .png or .pdf) in your new file name.',
          fileName ? fileName : f.name
        );
      if (newName == null) {
        return null;
      }
      fileName = newName;
    }

    return {
      fileName,
      promise: () => {
        const data = new FormData();
        data.append('file', f, fileName || f.name);
        data.append('folder', folderPath);
        return API.ics.uploadBucketPartMedia(magazine, bucket, part, data, zipMode);
      }
    };
  }));
  return uploadPromises
}


const runUploadPromises = async (zipMode: boolean, uploadPromises: UploadPromise[], onPromiseSettledCb: () => void) => {
  let successes = 0;
  const errorReport: UploadPromiseError[] = [];

  await Promise.all(uploadPromises.map((p) => {
    return new Bottleneck({ minTime: 333, maxConcurrent: 6 }).schedule(() => p.promise())
      .then(async (axiosPromise) => {
        if (!zipMode) {
          successes++;
          return
        }
        const parsedData = z.object({ files: z.number(), uploaded: z.number() }).safeParse(axiosPromise.data)
        if (parsedData.success && parsedData.data.uploaded !== parsedData.data.files) {
          errorReport.push({
            fileName: p.fileName,
            error: `${parsedData.data.files - parsedData.data.uploaded}/${parsedData.data.files} zip extracted files failed to upload. Please try to upload them one by one.`
          })
        } else {
          successes++;
        }
      })
      .catch((e) => {
        errorReport.push({ fileName: p.fileName, error: e })
      })
      .finally(() => { onPromiseSettledCb() })
  }))

  return { successes, errorReport }
}