import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Input } from 'reactstrap';
import { ciHelper } from '.';
import { PluginEntrypointSelect } from './PluginEntrypointSelect';
import { PluginWithEntrypoints } from './pluginsDomain';

import { FieldValueInput } from './FieldValueInput';
import { MediaItem } from './MediaItem';
import { MediaSelectButton } from '../../../../../components';
import { AssetType } from '../../../../../components/MediaSelectButton/MediaSelectButton';

interface Props {
  magazine: number,
  plugins: PluginWithEntrypoints[],
  fieldKey: string,
  fieldValue: string,
  onChange: (key: string, newValue: string) => void,
  openMediaSelector: (key: string, restrictedMediaTypes?: AssetType[]) => void
}

export const FieldValue = ({ magazine, plugins, fieldKey, fieldValue, onChange, openMediaSelector }: Props) => {

  const renderFieldValue = () => {
    switch (ciHelper.keyToKeyType(fieldKey)) {
      case 'thumbIcon':
      case 'thumbImage':
        return fieldValue ?
          <MediaItem magazine={magazine} url={fieldValue} h={38} w={38} onRemove={() => { onChange(fieldKey, '') }} /> :
          <MediaSelectButton text={`Click here to select an image`} onClick={() => openMediaSelector(fieldKey, ['images'])} type={'images'} />
      case 'downloadUrl':
        return fieldValue ?
          <MediaItem magazine={magazine} url={fieldValue} h={38} w={38} onRemove={() => { onChange(fieldKey, '') }} /> :
          <MediaSelectButton text={`Click here to select a document`} onClick={() => openMediaSelector(fieldKey, ['files'])} />
      case 'plugin':
        return <PluginEntrypointSelect plugins={plugins} fieldKey={fieldKey} value={fieldValue} onChange={onChange} />
      default:
        return <FieldValueInput {...{ fieldKey, fieldValue, onChange }} />
    }
  }

  return (
    <div style={{ flex: 1 }}>
      {renderFieldValue()}
    </div>
  )

}
