import { AxiosPromise } from 'axios';
import { dashApi, endpointWithParams } from "../api";
import { Comment } from '../pages/Content/subpages/comments/domain';

export const getComments = (magazine: number): AxiosPromise<Array<Comment>> => {
  return dashApi.get(magazine, `comments`);
}
export const getCommentsWithinDates = (magazine: number, from: string, until: string, channel?: string, source?: string): AxiosPromise<Array<Comment>> => {
  return dashApi.get(magazine, endpointWithParams(`comments`, { from, until, channel, source }));
}

export const getCommentsOfArticle = (magazine: number, articleId: number): AxiosPromise<Array<Comment>> => {
  return dashApi.get(magazine, `comments/article/${articleId}`);
}

export const deleteComments = (magazine: number, commentIds: string[]): AxiosPromise<void> => {
  return dashApi.delete(magazine, endpointWithParams(`comments`, { comments: commentIds }));
}
