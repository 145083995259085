import { AxiosPromise } from "axios";
import { dashApi } from "../api";
import { Audience } from "../pages/Advanced/subpages/Audiences/domain";


export const getAudiences = (magazine: number, count?: boolean): Promise<any> => {
    return dashApi.get(magazine, `audiences${count ? '?count=true' : ''}`);
}

export const getAudienceDetails = (magazine: number, audience: number): Promise<any> => {
    return dashApi.get(magazine, `audiences/${audience}?includeEditors=true`);
}
export const saveAudience = (magazine: number, audience: Audience): Promise<any> => {
    return dashApi.post(magazine, `audiences`, { audience });
}

export const getQueryCount = (magazine: number, query: Object): Promise<any> => {
    return dashApi.post(magazine, `audiences/queryCount`, { query });
}

export const getAudienceAsTargetInfo = (magazine: number, audience: number): Promise<any> => {
    return dashApi.get(magazine, `audiences/${audience}/asTargetInfo`);
}

export const deleteAudience = (magazine: number, audience: number): Promise<any> => {
    return dashApi.delete(magazine, `audiences/${audience}`);
}

export const saveAudienceEstimatedSize = (magazine: number, audience: number, estimatedSize?: number): Promise<any> => {
    return dashApi.post(magazine, `audiences/${audience}/estimatedSize`, { estimatedSize });
}

export const createNobodyAudience = (magazine: number): AxiosPromise<void> => {
    return dashApi.post(magazine, `audiences/createNobody`);
}