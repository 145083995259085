import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { AppMeta, Article } from '../../../domain';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import { I18n, Translations } from '../../translations';
import * as Api from '../../api';
import { DateHelper } from '../../utils';

declare const $: any;


export interface Props {
  magazine: string,
  article?: Article,
  // toggleArticleStatus: () => void
  toggleArticleStatusHandler: (status: boolean) => void
}

export interface State {
  appMeta?: AppMeta
}


export class ArticleInfo extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getAppMeta();
  }

  getAppMeta() {

    Api.getAppMeta(parseInt(this.props.magazine)).then((resp) => {
      if (!resp.data || (resp.data as any).error) {
        console.log('Error in getting app meta');
      }
      this.setState({ appMeta: resp.data });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }

  getUniversalLink = (): string => {
    const { appMeta } = this.state;
    const { magazine, article } = this.props;
    if (!appMeta || !article) {
      return ``;
    }
    return appMeta.universalLinksBaseUrl ? `${appMeta.universalLinksBaseUrl}/a/${article.id}` : `${appMeta.globalLandingPage}/${magazine}/a/${article.id}`;
  }

  componentDidUpdate() {
    console.log('didUpdate');
    $('#qrCodeDiv').html('');

    const { appMeta } = this.state;
    const { magazine, article } = this.props;
    if (!appMeta) {
      console.log('meta NOT exists');
      return;
    }

    console.log(`meta exists: ${appMeta.icon}`);

    const size = 200;
    const el = document.getElementById('qrCodeDiv');
    if (el && article) {
      const text = this.getUniversalLink();
      // if (appMeta.icon) {
      //   const img = new Image();
      //   img.src = appMeta.icon;
      //   img.onload = function () {
      //     $(el).qrcode({
      //       size: size,
      //       render: 'canvas',
      //       text: text,
      //       quiet: 5,
      //       mode: 4,
      //       mSize: 0.15,
      //       image: img,
      //       ecLevel: 'H',
      //       minVersion: 1
      //     });

      //     const canvas: any = el.childNodes[0];
      //     var ctx = canvas.getContext('2d');
      //     ctx.fillStyle = 'black';
      //     ctx.fillText('powered by', 55, size - 3);
      //     ctx.font = 'bold 12px tahoma';
      //     ctx.fillStyle = "#354052";
      //     // ctx.fillText('ORTEC relevance', size - 120, size - 3);
      //     ctx.fillText('ORTEC', 55 + 55, size - 3);
      //   }
      // } else {
      $(el).qrcode({
        size: size,
        render: 'canvas',
        text: text,
        quiet: 5,
        mode: 4,
        ecLevel: 'H',
        // mSize:0.15,
        // image: img,
        minVersion: 1
      });

      const canvas: any = el.childNodes[0];
      var ctx = canvas.getContext('2d');
      ctx.fillStyle = 'black';
      ctx.fillText('powered by', 55, size - 3);
      ctx.font = 'bold 12px tahoma';
      ctx.fillStyle = "#354052";
      // ctx.fillText('ORTEC relevance', size - 120, size - 3);
      ctx.fillText('ORTEC', 55 + 55, size - 3);
      // }


    }


  }

  toDate(date: string): string {
    return DateHelper.dateTimeToLocaleString(date);
  }

  toTime(secs: number): string {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.round(secs - minutes * 60);
    return minutes + 'm' + ' ' + (seconds ? (seconds + 's') : '');
  }

  formatStatus(status: number) {
    const tr = Translations.Translate(Translations, 'ArticleDetails');
    return status ? tr('active') : tr('inactive');
  }

  formatChannels(channels: any) {
    if (!channels) {
      return '-';
    }
    const channelNames = channels.map((c: any) => { return c.label });
    return channelNames.join(', ');
  }
  formatSources(sources: any) {
    if (!sources) {
      return '-';
    }
    const sourceNames = sources.map((s: any) => { return s.label || s.name });
    return sourceNames.join(', ');
  }

  toggleArticleStatus() {
    const { article } = this.props;
    if (article) {
      this.props.toggleArticleStatusHandler(article.sourcesStatus ? false : true)
    }

  }



  render() {
    const { article, magazine } = this.props;
    const { appMeta } = this.state;
    // const data = [articleStats]
    const tr = Translations.Translate(Translations, 'ArticleDetails');
    const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>

    return (
      <div id="ArticleInfo">
        {Translations.EnableTranslations(Translations)}
        <div className='header'><I18n t={'information'} n={'ArticleDetails'} /> <i className='fa fa-info-circle' data-tippy-content={tr('informations_section_info')}></i></div>
        <div className="content">
          {!article ?
            // <div>No info</div>
            loader
            :
            <div className="articleInfoContainer">
              <div className="articleInfoTableContainer">
                <table className="articleInfoTable">
                  <tbody>
                    <tr><td>{tr('id')}:</td><td>{article.id}</td></tr>
                    <tr><td>{tr('universalLink')}: </td><td><a href={this.getUniversalLink()} target='_blank'>{this.getUniversalLink()}</a></td></tr>
                    <tr><td>{tr('published')}:</td><td>{this.toDate(article.cdate)}</td></tr>
                    <tr><td>{tr('last_updated')}:</td><td>{this.toDate(article.mdate)}</td></tr>
                    <tr><td>{tr('status')}:</td><td>{this.formatStatus(article.sourcesStatus ? 1 : 0)}</td></tr>
                    <tr><td>{tr('channels')}: </td><td>{this.formatChannels(article.channels)}</td></tr>
                    <tr><td>{tr('sources')}: </td><td>{this.formatSources(article.sources)}</td></tr>
                    <tr><td>{tr('visible')}: </td><td><Toggle checked={article.sourcesStatus ? true : false} onChange={this.toggleArticleStatus.bind(this)} /></td></tr>
                  </tbody>
                </table>
              </div>
              <div className="qrCodeContainer">
                <div id="qrCodeDiv"></div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ArticleInfo;
