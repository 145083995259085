import React from 'react';
import { ArticlePreview } from './preview/components/ArticlePreview';
import { NlLayoutColumnIndex, NlLayoutNumberOfColumns, NlSlotItem, NlTemplateConfiguration_Content } from './domain';

import styles from './NewsletterContentBuilderRow.module.scss';
import { NewsletterContentSlot } from './NewsletterContentSlot';
import _ from 'lodash';
import { TippyReact } from '../../../../components';
import classNames from 'classnames';

interface Props {
  magazine: number
  templateConfiguration: NlTemplateConfiguration_Content
  onDelete: () => void
  columns: NlLayoutNumberOfColumns
  rowIndex: number
  slotItems: NlSlotItem[]
  openArticleSelector: (rowIndex: number, columnIndex: NlLayoutColumnIndex, openFreeTextEditorWithText?: string) => void
  readOnly?: boolean
}

export const NewsletterContentBuilderRow = ({ magazine, templateConfiguration, onDelete, columns, rowIndex, slotItems, openArticleSelector, readOnly }: Props) => {

  const isRowWithEmptySlots = hasEmptySlots(slotItems, rowIndex, columns);

  return (
    <div className={classNames(styles.NewsletterContentBuilderRow, { [styles.rowWithErrors]: isRowWithEmptySlots })}>
      {isRowWithEmptySlots &&
        <TippyReact content={`Row contains empty slot!`}><div className={styles.errorIcon}><i className={'fa fa-warning'}></i></div></TippyReact>
      }
      {/* <div className={styles.actionIcon}><i className={'fa fa-grip-vertical'}></i></div> */}
      <div className={styles.flexRow} style={{ flex: 1 }}>
        <NewsletterContentSlot readOnly={readOnly} oneColumn={columns !== 1} magazine={magazine} rowIndex={rowIndex} columnIndex={0} templateConfiguration={templateConfiguration} slotItem={findSlotItem(slotItems, rowIndex, 0)} openArticleSelector={openArticleSelector} />
        {columns == 2 &&
          <NewsletterContentSlot readOnly={readOnly} oneColumn magazine={magazine} rowIndex={rowIndex} columnIndex={1} templateConfiguration={templateConfiguration} slotItem={findSlotItem(slotItems, rowIndex, 1)} openArticleSelector={openArticleSelector} />
        }
      </div>
      {!readOnly &&
        <div className={styles.actionIcon} onClick={onDelete}><i className={'fa fa-trash-o'}></i></div>
      }
    </div>
  )

}

// ─── Helper Functions ────────────────────────────────────────────────────────

const findSlotItem = (slotItems: NlSlotItem[], rowIndex: number, columnIndex: NlLayoutColumnIndex): NlSlotItem | undefined => {
  return _.find(slotItems, (s) => {
    return (s.rowIndex == rowIndex) && (s.columnIndex == columnIndex)
  })
}

export const hasEmptySlots = (slotItems: NlSlotItem[], rowIndex: number, columns: NlLayoutNumberOfColumns): boolean => {
  if (columns == 2) {
    return findSlotItem(slotItems, rowIndex, 0) && findSlotItem(slotItems, rowIndex, 1) ? false : true;
  }

  return findSlotItem(slotItems, rowIndex, 0) ? false : true;
}