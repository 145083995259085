import { MagazineLanguages } from "../../domain";

export const DefaultMagazineLanguages: MagazineLanguages = {
    "primary": "en",
    "languages": [
        {
            "key": "en",
            "name": "english",
            "enabled": true
        },
        {
            "key": "nl",
            "name": "dutch",
            "enabled": false
        },
        {
            "key": "de",
            "name": "german",
            "enabled": false
        },
        {
            "key": "es",
            "name": "spanish",
            "enabled": false
        }
    ]
}