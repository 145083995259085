import './css/WidgetSelector.min.css';

import React from 'react';
import { Translations } from "../../translations";
import { WidgetManager } from '../../modules/WidgetManager/WidgetManager';

export interface Props {
    magazine: number,
    closeHandler: () => void,
    selectHandler: (w: any) => void
}

export function WidgetSelector(props: Props) {

    const { magazine, closeHandler, selectHandler } = props;


    const tr = Translations.Translate(Translations, 'WidgetManager');

    return (
        <div id="WidgetSelector">
            <div className='header'>
                <img src="/assets/icons/bt_close_bigger.svg" onClick={closeHandler} style={{ cursor: 'pointer' }} />
                <div className="headerTitle">{tr('selectWidget')}</div>
            </div>
            <div className='content simple'>
                <WidgetManager magazine={magazine} selectHandler={selectHandler} />
            </div>
        </div>
    )
}