import React, { Component } from 'react';

export interface Props {
  magazine: string,
  message: string | JSX.Element,
  logout?: boolean,
  page?: boolean,
  otherType?: string
}

export class Error extends Component<Props> {
  render() {
    const { message, page, logout, magazine, otherType } = this.props;
    return (
      <div id="GlobalError" className={(page ? "globalErrorPage" : "globalError") + ` alert alert-${otherType || 'danger'}`}>
        <div className="message">{message}</div>
        {logout ?
          <div className="buttons"><a href={`/auth/${magazine}/logout`} className="btn btn-danger">OK</a></div>
          : ''}
      </div>
    );
  }
}

export const ErrorMessage = Error;

export default Error;
