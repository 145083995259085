import _ from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { API } from '../../../../apis';
import { EdErrorHandler, LoadingButton, OrtecLoader } from '../../../../widgets';
import { DEFAULT_CONFIGURATIONS, DEFAULT_TEMPLATESET_WITH_CONFIGURATIONS } from './defaults';
import { NlContentTemplate_1, NlContentTemplate_2, NlEditionTemplate, NlFooterTemplate, NlHeaderTemplate, NlLandingPageTemplate, NlLinkedEditionsTemplate, NlTemplate, NlTemplateConfiguration, NlTemplateConfiguration_Content, NlTemplateConfiguration_Edition, NlTemplateConfiguration_Footer, NlTemplateConfiguration_Header, NlTemplateConfiguration_Wrapper, NlTemplateImage, NlTemplatesetTemplateConfigurations, NlTemplatesetTemplates, NlTemplatesetWithTemplateConfigurations, NlTemplatesetWithTemplates, NlUnsubscribePageTemplate, NlWrapperTemplate } from './domain';
import { NewsletterTemplateContent } from './NewsletterTemplateContent';
import { NewsletterTemplateFooter } from './NewsletterTemplateFooter';
import { NewsletterTemplateGeneralSettings } from './NewsletterTemplateGeneralSettings';
import { NewsletterTemplateHeader } from './NewsletterTemplateHeader';

import styles from './NewsletterTemplatesetDetails.module.scss';
import { NewsletterTemplatesetPreview } from './preview/NewsletterTemplatesetPreview';
import { NewsletterTemplateWrapper } from './NewsletterTemplateWrapper';
import { MagazineLanguages, Profile } from '../../../../../domain';
import { HtmlTemplates } from './htmlTemplates';
import { TippyReact } from '../../../../components';
import { NewsletterTemplateEdition } from './NewsletterTemplateEdition';

interface Props {
  magazine: number,
  profile: Profile,
  magazineLanguages: MagazineLanguages
  templatesetId: number,
  onClose: (reload?: boolean) => void
}

export const NewsletterTemplatesetDetails = ({ magazine, profile, magazineLanguages, templatesetId, onClose }: Props) => {

  const [templateset, setTemplateset] = useState<NlTemplatesetWithTemplateConfigurations>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [headerHasErrors, setHeaderHasErrors] = useState<boolean>(false);
  const [footerHasErrors, setFooterHasErrors] = useState<boolean>(false);
  const [nameHasErrors, setNameHasErrors] = useState<boolean>(false);

  const hasErrors = headerHasErrors || footerHasErrors || nameHasErrors;

  const isNew = templatesetId === -1;

  const loadTemplateset = useCallback(
    async () => {
      try {
        setDataLoading(true);
        const { data } = await API.newsletters.getNewsletterTemplateset(magazine, templatesetId);
        const parsedTemplateset = parseTemplateset(data);
        setTemplateset(parsedTemplateset);
        setHeaderHasErrors(true);
        setHeaderHasErrors(await doImageValidation(parsedTemplateset.configurations.header.image, 640));
        setFooterHasErrors(true);
        setFooterHasErrors(await doImageValidation(parsedTemplateset.configurations.footer.image, 640));
        setNameHasErrors(doNameValidation(parsedTemplateset.name))
      } catch (error) {
        EdErrorHandler(error, `loading templateset`)
      } finally {
        setDataLoading(false);
      }
    },
    [magazine, templatesetId],
  )

  useEffect(() => {
    if (isNew) {
      setTemplateset({ ...DEFAULT_TEMPLATESET_WITH_CONFIGURATIONS, language: magazineLanguages.primary });
      setHeaderHasErrors(true);
      setFooterHasErrors(true);
      setNameHasErrors(true);
    } else {
      loadTemplateset()
    }

  }, [isNew, magazineLanguages.primary, loadTemplateset]);




  const onChangeHeaderConfiguration = async (configuration: NlTemplateConfiguration_Header) => {
    if (!templateset) {
      return;
    }
    setTemplateset({ ...templateset, configurations: { ...templateset.configurations, header: configuration } });
    setHeaderHasErrors(true);
    setHeaderHasErrors(await doImageValidation(configuration.image, 640));
  }

  const onChangeWrapperConfiguration = (configuration: NlTemplateConfiguration_Wrapper) => {
    if (!templateset) {
      return;
    }
    setTemplateset({ ...templateset, configurations: { ...templateset.configurations, wrapper: configuration } });
  }

  const onChangeFooterConfiguration = async (configuration: NlTemplateConfiguration_Footer) => {
    if (!templateset) {
      return;
    }
    setTemplateset({ ...templateset, configurations: { ...templateset.configurations, footer: configuration } });
    setFooterHasErrors(true);
    setFooterHasErrors(await doImageValidation(configuration.image, 640));
  }

  const onChangeEditionConfiguration = async (configuration: NlTemplateConfiguration_Edition) => {
    if (!templateset) {
      return;
    }
    setTemplateset({ ...templateset, configurations: { ...templateset.configurations, edition: configuration } });
  }

  const onChangeContentConfiguration = (configuration: NlTemplateConfiguration_Content) => {
    if (!templateset) {
      return;
    }
    setTemplateset({ ...templateset, configurations: { ...templateset.configurations, content: configuration } });
  }

  const onChangeName = (newName: string) => {
    if (!templateset) {
      return;
    }
    setNameHasErrors(doNameValidation(newName));
    setTemplateset({ ...templateset, name: newName });
  }

  const onSave = async () => {

    if (!templateset) {
      return;
    }

    try {
      setSaveLoading(true);
      await API.newsletters.saveNewsletterTemplateset(magazine, convertConfigurationsToTemplates(templateset));
      onClose(true);
    } catch (error) {
      EdErrorHandler(error, `saving template`);
    } finally {
      setSaveLoading(false);
    }
  }


  return (
    <div className={styles.NewsletterTemplatesetDetails}>
      <div className={styles.header}>
        <img src="/assets/icons/bt_close_bigger.svg" onClick={() => onClose()} style={{ cursor: 'pointer' }} />
        {templateset &&
          <Fragment>
            <Input invalid={nameHasErrors} className={styles.nameInput} value={templateset.name || ''} placeholder={`Enter your template name here...`} onChange={(e) => { onChangeName(e.target.value) }}></Input>
            <TippyReact config={{ disabled: !hasErrors }} content={nameHasErrors ? 'Name is required!' : 'There are errors in the configuration below!'}>
              <div>
                <LoadingButton disabled={hasErrors} loading={saveLoading} onClick={() => { onSave() }} text={isNew ? 'create' : 'save'} />
              </div>
            </TippyReact>
          </Fragment>
        }
      </div>
      <div className={styles.content}>
        {dataLoading || !templateset ? <OrtecLoader /> :
          <Fragment>
            <div className={styles.leftPart} style={{ flex: 1, overflowY: 'auto' }}>
              <NewsletterTemplatesetPreview
                magazine={magazine}
                templateset={templateset}
              />
            </div>
            <div className={styles.rightPart} style={{ flex: 1, overflowY: 'auto', minWidth: '350px' }}>
              <div className={styles.settingsContainer}>
                {/** GENERAL SETTINGS */}
                <NewsletterTemplateGeneralSettings
                  magazineLanguages={magazineLanguages}
                  templateset={templateset}
                  onChange={setTemplateset}
                />
                <NewsletterTemplateWrapper
                  configuration={templateset.configurations.wrapper}
                  onChange={onChangeWrapperConfiguration}
                />
                <hr />
                {/** HEADER */}
                <NewsletterTemplateHeader
                  {...{ magazine, profile }}
                  configuration={templateset.configurations.header}
                  onChange={onChangeHeaderConfiguration}
                />
                <hr />
                {/** CONTENT */}
                <NewsletterTemplateContent
                  configuration={templateset.configurations.content}
                  onChange={onChangeContentConfiguration}
                />
                <hr />
                {/** FOOTER */}
                <NewsletterTemplateFooter
                  {...{ magazine, profile }}
                  configuration={templateset.configurations.footer}
                  onChange={onChangeFooterConfiguration}
                />
                <hr />
                {/** EDITION */}
                <NewsletterTemplateEdition
                  {...{ magazine, profile }}
                  configuration={templateset.configurations.edition}
                  onChange={onChangeEditionConfiguration}
                />
              </div>
            </div>
          </Fragment>
        }
      </div>
    </div>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

export const parseTemplateset = (templateset: NlTemplatesetWithTemplates): NlTemplatesetWithTemplateConfigurations => {
  // const {id, name, mail_sender_email, mail_sender_name, mail_subject, language, width} = templateset;
  return {
    ...templateset,
    configurations: {
      wrapper: getParsedConfiguration(templateset, 'wrapper') as NlTemplateConfiguration_Wrapper,
      header: getParsedConfiguration(templateset, 'header') as NlTemplateConfiguration_Header,
      footer: getParsedConfiguration(templateset, 'footer') as NlTemplateConfiguration_Footer,
      content: getParsedConfiguration(templateset, 'content1') as NlTemplateConfiguration_Content,
      edition: getParsedConfiguration(templateset, 'edition') as NlTemplateConfiguration_Edition,
      linkedEditions: getParsedConfiguration(templateset, 'linkedEditions') as NlTemplateConfiguration,
      landingPage: getParsedConfiguration(templateset, 'landingPage') as NlTemplateConfiguration,
      unsubscribePage: getParsedConfiguration(templateset, 'unsubscribePage') as NlTemplateConfiguration,

    }
  }
}

const getConfiguration = (templateset: NlTemplatesetWithTemplates, templateKey: keyof NlTemplatesetTemplates): string => {
  return templateset.templates[templateKey].configuration as string
}

const getParsedConfiguration = (templateset: NlTemplatesetWithTemplates, templateKey: keyof NlTemplatesetTemplates): Object => {
  try {

    const conf = JSON.parse(getConfiguration(templateset, templateKey));
    conf.language = templateset.language;
    return conf.version == 3 ? conf : { ...DEFAULT_CONFIGURATIONS[templateKeyToConfigurationKey(templateKey)] };

  } catch (error) {
    console.warn(`Error in parsing configuration of ${templateKey} template`);
    return { ...DEFAULT_CONFIGURATIONS[templateKeyToConfigurationKey(templateKey)] }
  }
}

const convertConfigurationsToTemplates = (templateset: NlTemplatesetWithTemplateConfigurations): NlTemplatesetWithTemplates => {
  const { id, name, mail_sender_email, mail_sender_name, mail_subject, language, width } = templateset;
  return {
    id, name, mail_sender_email, mail_sender_name, mail_subject, language, width,
    templates: {
      wrapper: configurationToTemplate(templateset, 'wrapper', 'wrapper') as NlWrapperTemplate,
      header: configurationToTemplate(templateset, 'header', 'header') as NlHeaderTemplate,
      content1: configurationToTemplate(templateset, 'content', 'content1') as NlContentTemplate_1,
      content2: configurationToTemplate(templateset, 'content', 'content2') as NlContentTemplate_2,
      footer: configurationToTemplate(templateset, 'footer', 'footer') as NlFooterTemplate,
      edition: configurationToTemplate(templateset, 'edition', 'edition') as NlEditionTemplate,
      linkedEditions: configurationToTemplate(templateset, 'linkedEditions', 'linkedEditions') as NlLinkedEditionsTemplate,
      landingPage: configurationToTemplate(templateset, 'landingPage', 'landingPage') as NlLandingPageTemplate,
      unsubscribePage: configurationToTemplate(templateset, 'unsubscribePage', 'unsubscribePage') as NlUnsubscribePageTemplate,

    }
  }
}

const configurationToTemplate = (templateset: NlTemplatesetWithTemplateConfigurations, configurationType: keyof NlTemplatesetTemplateConfigurations, templateType: keyof NlTemplatesetTemplates): NlTemplate => {
  const configuration = templateset.configurations[configurationType];
  return {
    id: templateset.templates[templateType]?.id || -1,
    name: templateset.name,
    type: templateType,
    template: templateType == 'header' ? HtmlTemplates[templateType].parse(configuration as any, templateset.configurations['content']) : HtmlTemplates[templateType].parse(configuration as any),
    configuration: configuration
  }
}

const templateKeyToConfigurationKey = (templateKey: keyof NlTemplatesetTemplates): keyof NlTemplatesetTemplateConfigurations => {
  if (templateKey == 'content1' || templateKey == 'content2') {
    return 'content';
  }
  return templateKey;
}

export const verifyImageSize = async (src: string, sizeInPixels: number): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      resolve(img.naturalWidth !== sizeInPixels);
    };
    img.onerror = () => {
      console.warn('Image Did NOT load:', img.src);
      resolve(true);
    }
  });
}


const doImageValidation = async (configurationImage: NlTemplateImage, sizeInPixels: number): Promise<boolean> => {
  if (!configurationImage.enabled) {
    return false;
  } else {
    if (configurationImage.src) {
      return await verifyImageSize(configurationImage.src, sizeInPixels);
    } else {
      return true;
    }
  }
}

const doNameValidation = (name: string): boolean => {
  return name ? false : true;
}