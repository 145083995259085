import React, { useMemo, useRef, useState } from 'react';
import { GenericModal } from '../GenericModal/GenericModal';
import GenericModalHeader from '../GenericModal/GenericModalHeader';
import styles from './ImageCropper.module.scss';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import ReactCrop, { type Crop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';

interface Props {
  magazine: number,
  file: File,
  initCrop: Crop | undefined,
  onCrop: (crop: Crop | undefined, blob: Blob | undefined) => void,
  onClose: () => void
}

export const ImageCropper = ({ magazine, file, initCrop, onCrop, onClose }: Props) => {

  const [crop, setCrop] = useState<Crop | undefined>(initCrop);

  const imgRef = useRef(null);


  const imgSrc = useMemo(() => {
    return URL.createObjectURL(file);
  }, [file]);

  const onClickCrop = async () => {

    const croppedImg = await getCroppedImg(imgRef.current, crop);

    onCrop(crop, croppedImg);

    onClose();
  }

  return (
    <GenericModal className={styles.ImageCropper} isOpen={true} fullHeight size={'xxl'} scrollable>
      <GenericModalHeader title='Crop Image' onClose={onClose} />
      <ModalBody>
        <ReactCrop
          crop={crop}
          onChange={c => setCrop(c)}
        // onImageLoaded={onLoad}
        >
          <img
            ref={imgRef}
            src={imgSrc}
            onLoad={() => URL.revokeObjectURL(imgSrc)}
          />
        </ReactCrop>
      </ModalBody>
      <ModalFooter>
        <Button outline color="secondary" onClick={onClose}>Cancel</Button>
        <Button color={'primary'} onClick={onClickCrop}>Crop</Button>
      </ModalFooter>
    </GenericModal>
  )
}

const getCroppedImg = async (sourceImage: any, crop: Crop | undefined): Promise<Blob | undefined> => {
  if (!crop) {
    return undefined;
  }
  const canvas = document.createElement("canvas");
  const scaleX = sourceImage.naturalWidth / sourceImage.width;
  const scaleY = sourceImage.naturalHeight / sourceImage.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return undefined;
  }
  ctx.drawImage(
    sourceImage,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );
  try {
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        if (!file) {
          resolve(undefined);
          return;
        }
        resolve(file);
        // resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  } catch (error) {
    console.log(error);
    return undefined;
  }
}