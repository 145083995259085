import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Col } from 'reactstrap';
import { Article, Likes, UsersList, ArticleStats } from '../../../domain';
import './css/UsersListModal.min.css';

import * as _ from 'lodash';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import uuid from 'uuid';
import { ArticleComments } from './ArticleComments';
import { setArticleStatus } from '../../api';



export interface Props {
  article?: Article,
  closeModal: () => void
  deleteSelectedCommentsHanlder: (selectedComments: string[]) => void,
  articleStats?: ArticleStats | 'loading',
}

export class ArticleCommentsModal extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  calcTotalComments = () => {
    const { articleStats } = this.props;

    return articleStats && articleStats !== 'loading' && articleStats.comments_ids ? articleStats.comments_ids.length : 0;
  }

  articleWithFilteredComments = (): Article | undefined => {
    const { article, articleStats } = this.props
    if (!article || !articleStats || articleStats === 'loading' || _.isEmpty(articleStats.comments_ids)) {
      return article;
    }

    const comments = _.filter(article.comments, (c) => _.includes(articleStats.comments_ids, c.id));

    return { ...article, comments };
  }

  render() {
    const { article, articleStats } = this.props
    const closeBtn = <img className="close" onClick={this.props.closeModal.bind(this)} src="/assets/icons/bt-close.svg" />
    const options = {
      // noDataText: 'There are no likes for this article',
      // toolBar: this.renderToolbar.bind(this),
    };

    return (
      <Modal isOpen={true} centered toggle={() => this.props.closeModal()} id="ArticleLikesModal" className={'usersListModal'}>
        <ModalHeader ><span className="headerTitle"><i className={'fa fa-commenting-o'}></i> Comments in article ({this.calcTotalComments()})</span>{closeBtn}</ModalHeader>
        <ModalBody>
          <ArticleComments article={this.articleWithFilteredComments()} deleteSelectedCommentsHanlder={this.props.deleteSelectedCommentsHanlder.bind(this)} readOnly={true} />
        </ModalBody>
      </Modal>
    );
  }
}

export default ArticleCommentsModal;
