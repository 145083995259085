import qs from 'query-string';
import * as H from 'history';

export function setQueryParams(history: H.History, newParams: any) {
    const params = qs.parse(history.location.search)
    const url = `${history.location.pathname}?${qs.stringify({ ...params, ...newParams })}`;
    history.push(url);
}

export function getQueryParam(location: H.Location, param: string): string | undefined {
    return qs.parse(location.search)[param];
}
export function getQueryParamNumber(location: H.Location, param: string): number | undefined {
    const value = getQueryParam(location, param);
    if (!value) {
        return undefined;
    }
    const numValue = parseInt(value);
    return numValue || undefined;
}