import _ from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Toggle from 'react-toggle';
import { Button, Col, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { MagazineLanguage, MagazineLanguages, Profile, Variations } from '../../../../../../domain';
import { API } from '../../../../../apis';
import { EditorsSelector, TippyReact } from '../../../../../components';
import { GenericModal } from '../../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../../components/GenericModal/GenericModalHeader';
import { EdErrorHandler, LoadingButton, OrtecLoader, Swal, SwalDelete, SwalError } from '../../../../../widgets';

import styles from './CmsFeedDetails.module.scss';
import { CmsFeedVariations } from './CmsFeedVariations';
import { CMSFeedDetails, DEFAULT_VARIATIONS } from '../CmsDomain';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface Props {
  magazine: number,
  profile: Profile,
  feedId: number,
  articleRetentionWarning?: string,
  close: (changeToFeed?: number) => void,
  updateProfile: () => void
}

export const CmsFeedDetails = ({ magazine, profile, feedId, articleRetentionWarning, close, updateProfile }: Props) => {

  const queryClient = useQueryClient();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [feed, setFeed] = useState<CMSFeedDetails>();

  const isUserInEditors = _.includes(feed?.editors, profile.uid);

  const magazineLanguagesQuery = useMagazineLanguagesQuery(magazine);

  const feedDetailsQuery = useFeedDetailsQuery(magazine, feedId);

  const loading = magazineLanguagesQuery.isFetching || feedDetailsQuery.isFetching || deleteLoading;


  const disableVariationsToggle = useMemo(() => {
    return (!feedDetailsQuery.data?.deletable && feedDetailsQuery.data?.variations?.enabled) ? true : false
  }, [feedDetailsQuery.data]);

  useEffect(() => {
    const newFeed = feedWithNewListOfVariations(feedId, profile, feedDetailsQuery.data, magazineLanguagesQuery.data);
    if (newFeed) {
      setFeed(newFeed);
    }
  }, [profile, feedId, feedDetailsQuery.data, magazineLanguagesQuery.data])


  const onSave = async () => {
    if (!feed) {
      return;
    }
    if (feed.editors.length == 0) { //should not happen without trying to hac from browser, as the save button is disabled in this case
      SwalError.fire({
        title: `Something went wrong!`,
        text: `No editors have been selected`
      });
      return;
    }

    if (!isUserInEditors) {
      const { value: confirm } = await Swal.fire({
        title: 'Are you sure?',
        type: 'warning',
        text: `You have not select yourself to access this feed! This means that the feed won't be accessible to you after save!`,
        showCancelButton: true,
        confirmButtonText: 'Yes, I am ok with that!',
        focusCancel: true,
      });
      if (!confirm) {
        return;
      }
    }
    try {
      setSaveLoading(true);
      const { data } = await API.cms.saveCmsFeedDetails(magazine, feedId, feed);
      await updateProfile();
      if (!isUserInEditors) {
        close(profile.feedsIds[0] ? _.toNumber(profile.feedsIds[0]) : undefined)
      }
      else if (feedId == -1) {
        close(data.id);
      } else {
        close();
      }
      queryClient.invalidateQueries({ queryKey: ['cmsFeed', data.id] });
      queryClient.invalidateQueries({ queryKey: ['cmsItems', magazine, data.id] });

    } catch (error) {
      EdErrorHandler(error, `saving feed`);
    } finally {
      setSaveLoading(false);
    }
  }

  const onVariationsChange = (variations: Variations) => {
    if (!feed) {
      return;
    }
    setFeed({ ...feed, variations });
  }

  const onEditorChange = (uid: string, checked: boolean) => {
    if (!feed) {
      return;
    }
    if (checked) {
      setFeed({ ...feed, editors: [...(feed.editors || []), uid] });
    } else {
      setFeed({ ...feed, editors: _.filter(feed.editors, (e) => e !== uid) });
    }

  }

  const onMultipleEditorsChange = (uids: string[], checked: boolean) => {
    if (!feed) {
      return;
    }
    if (checked) {
      setFeed({ ...feed, editors: uids });
    } else {
      setFeed({ ...feed, editors: [] });
    }
  }

  const onDelete = async () => {

    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      text: `This action will delete feed <${feed?.displayName || feed?.name}>. ${feed?.categories ? `This feed contains ${feed.categories} ${feed.categories > 1 ? 'categories' : 'category'}. ${feed.categories > 1 ? 'They' : 'It'} will be deleted as well!` : ''}`,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
    });

    if (!confirm) {
      return;
    }

    try {
      setDeleteLoading(true);
      await API.cms.deleteCmsFeed(magazine, feedId);
      await updateProfile();
      close(profile.feedsIds[0] ? _.toNumber(profile.feedsIds[0]) : undefined)
    } catch (error) {
      EdErrorHandler(error, `deleting CMS feed`);
    } finally {
      setDeleteLoading(false);
    }
  }

  return (
    <GenericModal isOpen centered toggle={() => close()} size={'lg'}>
      <GenericModalHeader
        onClose={close}
        title={`${feedId == -1 ? 'Create' : 'Edit'} content feed`}
        icon={`/assets/icons/icon-content-feed.svg`}
      />
      <ModalBody>
        {loading && <OrtecLoader />}
        <Fragment>
          <Row className={styles.centeredItems}>
            <Col md={2}>
              <div className={styles.nameWrapper}>
                <label>Name:</label>
                {articleRetentionWarning &&
                  <TippyReact content={`Article Retention enabled: ${articleRetentionWarning}`}>
                    <div className={styles.warningIcon}>
                      <i className='fa fa-warning'></i>
                    </div>
                  </TippyReact>
                }
              </div>
            </Col>
            <Col md={10}>{feed && <Input placeholder={`Display name ${feed?.name ? `for <${feed.name}>` : ''}`} value={feed?.displayName} onChange={(e) => setFeed({ ...feed, displayName: e.target.value })} />}</Col>
          </Row>
          <hr />
          <Row className={styles.centeredItems}>
            <Col md={2}><Label>Variations:</Label></Col>
            <Col md={10}>
              {feed &&
                <div className={styles.centeredItems}>
                  <TippyReact content={`You cannot disable variations in a non-empty feed!`} config={{ disabled: !disableVariationsToggle }}>
                    <div className={styles.centeredItems}>
                      <Toggle disabled={disableVariationsToggle} checked={feed.variations?.enabled || false} onChange={(e) => setFeed({ ...feed, variations: { ...(feed.variations ?? DEFAULT_VARIATIONS), enabled: e.target.checked } })} />
                    </div>
                  </TippyReact>
                </div>
              }
            </Col>
            <Col md={12}>
              {feed?.variations?.enabled &&
                <CmsFeedVariations variations={feed.variations} onChange={onVariationsChange} />
              }
            </Col>
          </Row>
          <hr />
          <Row className={styles.centeredItems}>
            <Col md={12}>
              <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                <Label>Access Rights:</Label>
                {feed && (feed?.editors.length == 0 || !isUserInEditors) &&
                  <TippyReact content={feed?.editors.length == 0 ? `You have to select at least one editor in order to save!` : `You have not select yourself to access this feed! This means that the feed won't be accessible to you after save!`}>
                    <i className={'material-icons'} style={{ verticalAlign: 'middle', fontSize: '16px', marginLeft: '5px', color: '#B00020', cursor: 'default' }}>info</i>
                  </TippyReact>
                }
                <div style={{ flex: 1 }}></div>
                {feed &&
                  <div>{`${feed?.editors.length} editor${feed?.editors.length !== 1 ? 's' : ''}`}</div>
                }
              </div>
              {feed &&
                <EditorsSelector
                  magazine={magazine}
                  selected={feed?.editors ?? []}
                  onEditorChange={onEditorChange}
                  onMultipleEditorsChange={onMultipleEditorsChange}
                />
              }
            </Col>
          </Row>
          {feed?.deletable &&
            <Row>
              <Col md={12}>
                <Button className={styles.deleteButton} size={'lg'} block outline color="secondary" onClick={onDelete}>Delete content feed</Button>
              </Col>
            </Row>
          }
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <Button style={{ flex: 1 }} size={'lg'} outline color="secondary" onClick={() => close()}>Cancel</Button>
        <LoadingButton style={{ flex: 1 }} color="primary" onClick={onSave} text={`Save`} loading={saveLoading} disabled={!feed?.editors.length ? true : false} />
      </ModalFooter>
    </GenericModal>
  )
}

// ─── Helper Functions ───────────────────────────────────────────────────────────

const generateNewFeed = (profile: Profile): CMSFeedDetails => {
  return {
    id: -1,
    name: '',
    displayName: '',
    editors: [profile.uid],
    variations: { ...DEFAULT_VARIATIONS },
    categories: 0,
    deletable: false
  }
}

const calcListOfVariations = (feedDetails?: CMSFeedDetails, magazineLanguages?: MagazineLanguages): Variations => {
  const existingVariations = feedDetails?.variations || { ...DEFAULT_VARIATIONS };
  const enabledLanguagesFromMagazine = _.compact(_.map(magazineLanguages?.languages, (m) => {
    if (m.enabled && !_.includes(_.map(existingVariations.languages, (l) => l.key), m.key)) {
      return {
        ...m,
        enabled: false
      };
    }
  }));

  const variations = {
    ...existingVariations,
    languages: [...existingVariations.languages, ...enabledLanguagesFromMagazine]
  };
  return variations;
}

const feedWithNewListOfVariations = (feedId: number, profile: Profile, feedDetails?: CMSFeedDetails, magazineLanguages?: MagazineLanguages): CMSFeedDetails | undefined => {
  const variations = calcListOfVariations(feedDetails, magazineLanguages);

  if (feedId === -1) {
    return {
      ...generateNewFeed(profile),
      variations
    }
  }
  if (feedDetails) {
    return {
      ...feedDetails,
      variations
    };
  }
}

// ─── Query Hooks ─────────────────────────────────────────────────────────────

const useMagazineLanguagesQuery = (magazine: number) => {
  return useQuery({
    queryKey: [`magazineLanguages`, magazine],
    queryFn: async () => {
      try {
        const { data } = await API.oldApi.getLanguages(magazine);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting magazine languages`);
      }
    }
  });
}

const useFeedDetailsQuery = (magazine: number, feedId: number) => {
  return useQuery({
    queryKey: [`feedDetails`, feedId],
    enabled: feedId !== -1,
    queryFn: async () => {
      try {
        const { data } = await API.cms.getCmsFeedDetails(magazine, feedId);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting CMS feed details`);
      }
    }
  });
}