import React, { useState } from 'react'
import { SourceTypeSettingsProps } from '../SourceTypeSettings'
import { Alert, Button, Col, Label } from 'reactstrap'
import styles from '../SourceDetails.module.scss'
import _ from 'lodash'
import { FeedSelectorModal } from '../../../../../components/FeedSelector/FeedSelectorModal'
import { ConnectedFeed } from '../../../../../../domain'

export const Feed = ({ magazine, source, onChangeSource, profile }: SourceTypeSettingsProps) => {

  const [openFeedSelector, setOpenFeedSelector] = useState(false);

  const isNewRecord = source.id === -1;

  const connectedFeed = source.connectedFeed;

  const onFeedSelect = (feeds: ConnectedFeed[]) => {
    if (!_.isEmpty(feeds)) {
      onChangeSource({ ...source, connectedFeed: feeds[0] });
    }
  }

  return (
    <>
      <Col md={8}>
        <Label>{isNewRecord ? 'Selected' : 'Connected'} Feed:</Label>
        {connectedFeed ?
          <Alert color={'info'}>{`[${connectedFeed.id}] ${connectedFeed.label}`}</Alert>
          :
          <Alert color={'warning'}>{`No ${isNewRecord ? `selected` : `connected`} feed`}</Alert>
        }
      </Col>
      <Col md={4} className={styles.verticalBottomCentered}>
        {isNewRecord &&
          <Button style={{ height: '38px' }} onClick={() => setOpenFeedSelector(true)}>Select Feed</Button>
        }
      </Col>
      {openFeedSelector &&
        <FeedSelectorModal preselected={connectedFeed ? [connectedFeed] : []} magazine={magazine} onClose={() => setOpenFeedSelector(false)} onSelect={onFeedSelect} />
      }
    </>
  )
}
