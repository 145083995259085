import React from 'react';
import { NlLayout, NlLayoutColumnIndex, NlLayoutNumberOfColumns, NlSlotItem, NlTemplatesetWithTemplateConfigurations } from './domain';

import styles from './NewsletterBuilder.module.scss';
import { NewsletterContentBuilder } from './NewsletterContentBuilder';
import { NewsletterTemplatesetPreview_Footer } from './preview/NewsletterTemplatesetPreview_Footer';
import { NewsletterTemplatesetPreview_Header } from './preview/NewsletterTemplatesetPreview_Header';

interface Props {
  magazine: number,
  templateset: NlTemplatesetWithTemplateConfigurations
  layout: NlLayoutNumberOfColumns[]
  onLayoutChange: (layout: NlLayout, slotItems: NlSlotItem[]) => void
  slotItems: NlSlotItem[]
  openArticleSelector: (rowIndex: number, columnIndex: NlLayoutColumnIndex, openFreeTextEditorWithText?: string) => void
  readOnly?: boolean
}
export const NewsletterBuilder = ({ magazine, templateset, layout, onLayoutChange, slotItems, openArticleSelector, readOnly }: Props) => {

  const { wrapper, header, content, footer } = templateset.configurations;

  return (
    <div className={styles.NewsletterBuilder}>
      <div className={styles.wrapper} style={{ fontFamily: wrapper.font_family || 'Roboto' }}>
        <NewsletterTemplatesetPreview_Header magazine={magazine} configuration={header} />
        <NewsletterContentBuilder magazine={magazine} templateConfiguration={content} layout={layout} onLayoutChange={onLayoutChange} slotItems={slotItems} openArticleSelector={openArticleSelector} readOnly={readOnly} />
        <NewsletterTemplatesetPreview_Footer magazine={magazine} configuration={footer} />
      </div>
    </div>
  )
}
