import React, { Fragment } from 'react';

import { Widget } from '../../../domain';
import { Translations } from '../../translations';
import { Col, Button, Row } from 'reactstrap';
import _ from 'lodash';
import { NavLink as RouterNavLink } from 'react-router-dom';
import Toggle from 'react-toggle';
import { DateHelper } from '../../utils';
import DataTable, { ExtendedColumnDescription } from '../../components/DataTable/DataTable';

export interface Props {
    magazine: number,
    widgets: Widget[],
    // closeHandler: () => void,
    selectHandler?: (w: any) => void
    openWidgetDetailsModal: (widget: Widget) => void
    deleteWidgetHandler: (widget: Widget) => void
    openSelectTypeModal: () => void
    openDataModal: (widget: Widget) => void
    analyticsMode?: boolean,

}

export function WidgetOverview(props: Props) {
    const { widgets, selectHandler, openWidgetDetailsModal, openSelectTypeModal, analyticsMode, deleteWidgetHandler, openDataModal, magazine } = props;
    const trt = Translations.Translate(Translations, 'Tables');
    const tr = Translations.Translate(Translations, 'WidgetManager');

    const colStyle: any = { textAlign: 'left' };
    const headerStyle: any = { fontWeight: 'bold', textTransform: 'uppercase', fontSize: '12px', color: '#9faaae' };

    const titleFormatter = (cell: string, row: Widget) => {
        if (!row.article) {
            return <>{cell}</>;
        }

        const { title, id, feed, kind } = row.article;

        return (
            <Row>
                <Col md={12} style={colStyle}>
                    <div style={headerStyle}>{tr('article')}:</div>
                    <div><a target={'_blank'} href={`/${magazine}/content?feed=${feed}&item=${id}&tab=${kind == 'event' ? 'agenda' : 'articles'}`}>{title || id || feed}</a></div>
                </Col>
            </Row>
        )

    }

    const searchableTitleFormatter = (cell: any, row: Widget) => {
        if(!row.article) {
            return row.title;
        }

        const { title, id, feed } = row.article;

        return title || id || feed;
    }


    const eventRegistrationFormatter = (row: any) => {
        const { startDate, endDate, available_spots } = row.config;
        const registrations = _.get(row, 'data.registrations', 0);
        return (
            <Row>
                <Col md={4} style={colStyle}>
                    <div style={headerStyle}>{tr('startDate')}:</div>
                    <div>{DateHelper.dateToLocaleString(startDate)}</div>
                </Col>
                <Col md={4} style={colStyle}>
                    <div style={headerStyle}>{tr('endDate')}:</div>
                    <div>{DateHelper.dateToLocaleString(endDate)}</div>
                </Col>
                <Col md={4} style={colStyle}>
                    <div style={headerStyle}>{tr('spots')}:</div>
                    <div>{registrations}/{available_spots}</div>
                </Col>
            </Row>
        )

    }

    const confirmationFormatter = (row: any) => {
        const { enabled, label } = row.config;
        const confirmations = _.get(row, 'data.confirmations', 0);
        return (
            <Row>
                <Col style={colStyle}>
                    <div style={headerStyle}> {tr('label')}:</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>{label}</div>
                </Col>
                <Col style={colStyle}>
                    <div style={headerStyle}> {tr('status')}:</div>
                    <div><Toggle checked={enabled} /></div>
                </Col>
                <Col style={colStyle}>
                    <div style={headerStyle}>{tr('confirmed')}:</div>
                    <div>{confirmations}</div>
                </Col>
            </Row>
        )

    }

    const configFormatter = (cell: any, row: any) => {
        try {
            switch (row.type) {
                case 'event-registration':
                    return <>{eventRegistrationFormatter(row)}</>;
                case 'confirmation':
                    return <>{confirmationFormatter(row)}</>;
                default:
                    return <>{JSON.stringify(cell)}</>;
            }
        } catch (error) {
            return <>{''}</>
        }
    }
    const typeFormatter = (cell: any, row: any) => {
        try {
            switch (row.type) {
                case 'event-registration':
                    return <>{tr(`eventRegistration`)}</>;
                case 'confirmation':
                    return <>{tr(`confirmationButton`)}</>;
                default:
                    return <>{JSON.stringify(cell)}</>;
            }
        } catch (error) {
            return <>{''}</>
        }
    }

    const labelFormatter = (cell: any, row: any) => {
        return row?.config?.label || '';
    }

    const showDataButtonText = (row: any) => {
        try {
            switch (row.type) {
                case 'event-registration':
                    return tr('viewRegisteredUsers')
                case 'confirmation':
                    return tr('viewConfirmedUsers')
                default:
                    return tr('showData');
            }
        } catch (error) {
            return ''
        }
    }
    const actionsFormatter = (cell: any, row: any) => {
        const ActionButton = (props: { text: string, hoverText?: string, extraClass?: string, clickHandler: (r: any) => void }) => {
            return (
                <Button className={`secondary ${props.extraClass}`} data-tippy-content={props.hoverText || undefined}
                    onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.clickHandler(row);
                        // closeHandler();
                        return false;
                    }}>
                    {props.text}
                </Button>
            )
        }

        return (
            <div className='actionHoveringDiv'>
                {analyticsMode ?
                    <ActionButton text={showDataButtonText(row)} clickHandler={openDataModal} hoverText={showDataButtonText(row)} />
                    :
                    <Fragment>
                        {<ActionButton text={tr('edit')} clickHandler={openWidgetDetailsModal} hoverText={tr('edit')} />}
                        {<ActionButton text={tr('delete')} clickHandler={deleteWidgetHandler} hoverText={tr('delete')} />}
                        {selectHandler && <ActionButton text={tr('select')} clickHandler={selectHandler} hoverText={tr('select')} extraClass={'selectButton'} />}
                    </Fragment>
                }
            </div>
        )
    }

    const columns: ExtendedColumnDescription[] = [
        {
            dataField: 'id',
            hidden: true,
            text: 'ID',
            sort: true,
        },
        {
            dataField: 'title',
            text: '',
            formatter: titleFormatter,
            sort: true,
            headerAlign: 'center', 
            classes: 'bordered'
        },
        {
            dataField: 'title',
            text: 'Title',
            formatter: searchableTitleFormatter,
            hidden: true,
            sort: true
        },
        {
            dataField: 'type',
            text: '',
            headerFormatter: (column, colIndex) => {
                return <><img data-tippy-content={tr("widgetType")} src="/assets/icons/16/form_type.svg" /></>
            },
            formatter: typeFormatter,
            sort: true,
            headerAlign: 'center',
            align: 'center',
            classes: 'bordered hideOnHover'
        },
        {
            dataField: 'config',
            text: 'Label',
            formatter: labelFormatter,
            hidden: true
        },
        {
            dataField: 'config',
            text: '',
            headerFormatter: (column, colIndex) => {
                return <><img data-tippy-content={tr("extraInfo")} src="/assets/icons/16/more_details.svg" /></>
            },
            formatter: configFormatter,
            sort: true,
            headerAlign: 'center',
            classes: 'bordered hideOnHover',
        },
        {
            dataField: '',
            isDummyField: true,
            sort: false,
            text: '',
            headerStyle: { width: '25px' },
            classes: 'actionsColumn',
            formatter: actionsFormatter
        }
    ]

    return (
        <div id="WidgetOverview">
            <div className="tableDiv">
                <div style={{ position: 'relative' }}>
                    <DataTable
                        data={widgets}
                        columns={columns}
                        keyField={'id'}
                        defaultSizePerPage={25}
                        onRowClick={!analyticsMode ? openWidgetDetailsModal : () => {}}
                        createNewButtonText={!analyticsMode ? tr('createNewWidget') : undefined}
                        onCreateNew={!analyticsMode ? openSelectTypeModal : undefined}
                        searchFiltersStyle={{ marginBottom: '0px' }}
                        secondaryElements={analyticsMode ? <RouterNavLink className={'nav-link navigationLink'} to={`advanced?tab=widgets`}>{tr('manageWidgets')} <i className={'fa fa-chevron-right'}></i></RouterNavLink> : undefined}
                    />
                </div>
            </div>
        </div>
    )
}