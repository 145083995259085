import { NlTemplateConfiguration } from "../domain";

const parse = (configuration: NlTemplateConfiguration): string => {
    return `
<!DOCTYPE html>

<!--[if lt IE 7]> <html lang="en" class="no-js lt-ie9 lt-ie8 lt-ie7"> <![endif]-->

<!--[if IE 7]> <html lang="en" class="no-js lt-ie9 lt-ie8"> <![endif]-->

<!--[if IE 8]> <html lang="en" class="no-js lt-ie9"> <![endif]-->

<!--[if gt IE 8]><!-->
<html lang="en" class="no-js">
<!--<![endif]-->

<head>
    <meta charset="utf-8" />

    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />

    <title>{{ appTitle }}</title>

    <meta name="description" content="" />

    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <style>
    @import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    body {
        background-color: #f6faf6;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
    }

    .nl-container {
        display: flex;
        width: 320px;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #e5e5e5;
        background: #fff;
    }
    .image {
        max-height: 60px;
    }

    .text {
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    </style>
</head>

<body>
    <div class="nl-container">
    {% if appIcon %}
    <img class="image" src="{{ appIcon }}" alt="Unsubscribe header image" />
    {% endif %}
    <div class="text">You are now unsubscribed from our newsletter.</div>
    </div>
</body>
</html>
    `;
}

export const unsubscribePage = {
    parse
}