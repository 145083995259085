import { AxiosPromise } from "axios";
import { dashApi, endpointWithParams } from "../api";
import { FlaggedContent, ReportedContent } from "../pages/Content/subpages/reportedContent/ReportedContentDomain";

export const getReportedContents = (magazine: number, from?: string, until?: string): AxiosPromise<ReportedContent[]> => {
  return dashApi.get(magazine, endpointWithParams(`reportedContent`, { from, until }));
}

export const getReportedContent = (magazine: number, articleId: number): AxiosPromise<FlaggedContent[]> => {
  return dashApi.get(magazine, `reportedContent/${articleId}`);
}

export const markReportAsReviewed = (magazine: number, reportId: number): AxiosPromise<void> => {
  return dashApi.post(magazine, `flaggedContent/${reportId}/markAsReviewed`);
}

export const markAllReportsAsReviewed = (magazine: number, articleId: number): AxiosPromise<void> => {
  return dashApi.post(magazine, `reportedContent/${articleId}/markAsReviewed`);
}

export const rejectPost = (magazine: number, articleId: number): AxiosPromise<void> => {
  return dashApi.post(magazine, `reportedContent/${articleId}/rejectPost`);
}

export const reenablePost = (magazine: number, articleId: number): AxiosPromise<void> => {
  return dashApi.post(magazine, `reportedContent/${articleId}/reenablePost`);
}