import React, { Component, Fragment } from 'react';
import { Col, Button } from 'reactstrap';
import './css/SourceSelector.min.css'

import { Profile, Source } from '../../../../../domain';
import { Translations } from '../../../../translations';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as _ from 'lodash';
import * as Api from '../../../../api';
import { OrtecLoader } from '../../../../widgets';
import classNames from 'classnames';
import { SourcesHelper } from '../../../../utils';


export interface Props {
  magazine: string,
  profile: Profile,
  title: string,
  sources?: Source[]
  selected?: number[]
  selectionHandler: (id: number, isSelected: boolean) => void
  selectAllHandler: (ids: number[]) => void
  clearAllHandler: () => void

}
export interface State {
  sources?: Source[],
  showOnlySelected: boolean
}

export class SourceSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showOnlySelected: false
    };
  }

  componentDidMount() {
    this.getSources();
  }



  getSources = () => {
    Api.getSources(parseInt(this.props.magazine)).then((resp) => {

      if (!resp.data || resp.data.error) {
        console.log('Error in getting chart data');
        this.setState({ sources: [] });
      }
      this.setState({ sources: resp.data ? resp.data : [] });
    })
      .catch((error) => {
        console.log('An error occured:' + error.message)
      });
  }

  getAudiencesIds = () => {
    const { profile } = this.props;

    let audiences: number[] = [];

    if (!_.isEmpty(profile.audienceIds)) {
      audiences = _.map(profile.audienceIds, _.toInteger);
    }

    if (profile.globalAudience) {
      audiences.unshift(1);
    }

    return audiences;

  }

  openSourceDetailPage = (sourceId: number) => {

    const link = `/${this.props.magazine}/advanced?source=${sourceId}&tab=sources`;
    window.open(link, '_blank');
    return;
  }

  buttonsFormatter = (cell: any, row: any) => {
    return (
      <div className={'actionHoveringDiv'}>
        <Button onClick={(e: any) => { e.preventDefault(); e.stopPropagation(); this.openSourceDetailPage(row.id); return false; }}>open</Button>
      </div>
    )
  }

  audienceFormatter = (cell: any, row: any) => {
    const { profile } = this.props;
    const tr = Translations.Translate(Translations, 'Channels');
    const audiences: any[] = [];
    if (!cell) {
      return '-';
    }

    cell.map((a: number) => {
      audiences.push((a == 1) ? tr('all_employees') : _.get(_.find(profile.audiences, ['id', a]), 'label'));
    });

    if (audiences.length == 0) {
      return '-'
    } else if (audiences.length == 1) {
      return <>{audiences[0]}</>;
    } else {
      return <span data-tippy-content={audiences.join(', ')}>{audiences.length}</span>;
    }
  }

  onRowSelect = (row: any, isSelected: boolean, e: any) => {
    this.props.selectionHandler(row.id, isSelected)
  }

  onSelectAll = (isSelected: boolean, rows: any[]) => {
    if (isSelected) {
      this.props.selectAllHandler(_.map(rows, (r) => r.id));
    } else {
      this.props.clearAllHandler();
    }
  }

  renderToolbar = (props: any) => {
    const tr = Translations.Translate(Translations, 'Channels');
    return (
      <Fragment>
        <Col md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <div className='titleDiv'>{this.props.title}</div>
        </Col>
        <Col md={6}>{props.components.searchField}</Col>
      </Fragment>
    );
  }

  renderPaginationPanel(props: any) {
    let max = 1;
    let min = 1;
    if (this.state.sources) {
      max = (this.state.sources.length / 10) + 1;
    }
    const tr = Translations.Translate(Translations, 'Tables');

    return (
      <Fragment>
        <Col md={8}>{props.components.pageList} {/*props.components.sizePerPageDropdown*/}</Col>
        <Col md={4} className={'d-none d-xl-block'}>
          <div className="goToPageDiv">
            <input id="goToPageInput" onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                let val = e.target.value;
                if (val < min) {
                  val = min;
                } else if (val > max) {
                  val = max;
                }
                props.changePage(val);
                e.target.value = '';
              }
            }} className='form-control' type="number" placeholder={tr('go_to_page')} style={{ width: '115px' }} />
            <button className="btn" onClick={(e) => {
              var k: any = document.getElementById('goToPageInput');
              let val = k.value;
              if (val < min) {
                val = min;
              } else if (val > max) {
                val = max;
              }
              props.changePage(val);
              k.value = ''
            }}>{'>'}</button>
          </div>
        </Col>
      </Fragment>
    );
  }

  sourcesFilter() {
    const { showOnlySelected } = this.state;
    return (
      <div className={'sourcesFilter'}>
        <span className={classNames({ showOption: true, showAll: true, selected: !showOnlySelected })} onClick={() => { this.setState({ showOnlySelected: false }) }}>show all</span>
        <span className={'separator'}>/</span>
        <span className={classNames({ showOption: true, showAll: true, selected: showOnlySelected })} onClick={() => { this.setState({ showOnlySelected: true }) }}>show selected</span>
      </div>
    )

  }

  render() {
    const { magazine, profile, selected } = this.props;
    const { sources, showOnlySelected } = this.state;
    const tr = Translations.Translate(Translations, 'Channels');
    const trt = Translations.Translate(Translations, 'Tables');

    const options: any = {
      toolBar: this.renderToolbar,
      paginationPanel: this.renderPaginationPanel.bind(this),
      defaultSortName: 'cdate',  // default sort column name
      defaultSortOrder: 'desc',  // default sort order
      sizePerPage: 10,
      sizePerPageList: [
        { text: `10 ${trt('entries')}`, value: 10 },
        { text: `25 ${trt('entries')}`, value: 25 },
        { text: `50 ${trt('entries')}`, value: 50 },
        { text: `100 ${trt('entries')}`, value: 100 },
      ]
    }


    const selectRow: any = {
      mode: 'checkbox',
      clickToSelect: false,
      clickToExpand: true,
      selected: selected,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll
    }


    return (
      <Fragment>
        <div id="SourcesSelector" className="">
          <div className="tableDiv">
            {Translations.EnableTranslations(Translations)}
            {sources ?
              <BootstrapTable ref="sourcesSelector" data={showOnlySelected ? _.filter(sources, (s) => _.includes(selected, s.id)) : sources} bordered={false} search={true} hover={false} options={options} version='4' selectRow={selectRow} pagination={true}>
                <TableHeaderColumn isKey dataField='id' hidden></TableHeaderColumn>
                <TableHeaderColumn dataField='name' tdStyle={{ cursor: 'pointer' }} dataFormat={(cell, row) => SourcesHelper.sourceEdConfigName(row, profile.edConfig)}>{this.sourcesFilter()}</TableHeaderColumn>
                <TableHeaderColumn dataField='srctype' dataAlign='center' width='200px'>
                  <img data-tippy-content={tr("sourceType")} src="/assets/icons/16/form_type.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='audiences' hidden dataAlign='center' width='200px' dataFormat={this.audienceFormatter.bind(this)}>
                  <img data-tippy-content={tr("audiences")} src="/assets/icons/16/audience.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='articleCount' dataAlign='center' width='80px'>
                  <img data-tippy-content={tr("articles")} src="/assets/icons/16/publications.svg" />
                </TableHeaderColumn>
                <TableHeaderColumn dataField='cdate' hidden></TableHeaderColumn>
                <TableHeaderColumn dataFormat={this.buttonsFormatter.bind(this)} width='5px'></TableHeaderColumn>
              </BootstrapTable>
              : <OrtecLoader />
            }
          </div>

        </div>
      </Fragment>

    );
  }
}

export default SourceSelector;
