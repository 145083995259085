import React, { Fragment, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Label, Input, FormFeedback, FormText } from "reactstrap";

import Toggle from 'react-toggle';
import "react-toggle/style.css";

import { CreatableMultiTextInput } from '../../../../components/ReactSelect/ReactSelect';

import ReactDatePicker from 'react-datepicker3';
import "react-datepicker3/dist/react-datepicker.css";

import Select from 'react-select';

import styles from "./PeopleEditModal.module.scss";
import { FieldConfig } from './domain';
import _ from 'lodash';
import moment from 'moment';
import { ActionMeta } from 'react-select/src/types';
import { ED_ROLES, ED_ROLES_LABELS } from '../../../../accessRights/accessRights';
import { EdRole } from '../../../../accessRights/domain';
import { FeedBasicProps } from '../../../Content/subpages/cms/CmsDomain';
import { DateHelper, FeedsHelper } from '../../../../utils';
import { ThreeStateToggle } from '../../../../components/ThreeStateToggle/ThreeStateToggle';

const KeyCodes = {
    comma: 188,
    enter: 13,
    space: 32,
    tab: 9,
}

const delimiters = [
    // KeyCodes.comma,
    // KeyCodes.space,
    KeyCodes.enter,
    KeyCodes.tab,
];

interface OptionItem {
    id: number,
    label: string
}

interface Props {
    magazine: number
    peopleConfig: FieldConfig[]
    editProfile: any,
    audiences: any[],
    feeds: FeedBasicProps[],
    onSave: (p: any) => void
    onClose: () => void
}
export default function PeopleEditModal({ magazine, peopleConfig, editProfile, audiences, feeds, onSave, onClose }: Props) {

    const isNewEntry = (editProfile.uid == '-1') ? true : false;

    const [profile, setProfile] = useState({ ...editProfile, uid: isNewEntry ? undefined : editProfile.uid });



    const isRequired = (field: FieldConfig): boolean => {
        switch (field.key) {
            case 'uid':
                return field.isEditable;
            case 'accounts.0.username':
                const uidField = _.find(peopleConfig, ['key', 'uid']);
                return (!uidField || uidField.isEditable || !isNewEntry) ? false : true;
            default:
                return false;
        }
    }

    const isValidEmail = (value: string) => {
        if (!value) {
            return true;
        }
        // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/;
        const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const re = new RegExp(emailPattern);
        const isValid = re.test(value);
        return isValid;
    }

    const hasValueChanged = (key: string, value: string) => {
        return value !== _.get(editProfile, key)
    }

    const renderEdRoleFields = (f: FieldConfig) => {

        const isEditor = _.get(profile, f.key, false);

        const globalAudienceKey = `attributes.globalAudience`;
        const isAdmin = _.get(profile, globalAudienceKey, false);

        const edRoleKey = `attributes.ed.role`;
        const edRoleValue = isAdmin ? 'admin' : _.get(profile, edRoleKey, '');

        const edRoleOptions: Array<{ value: string, label: string }> = _.map(_.filter(ED_ROLES, (r) => r !== 'none'), (o: EdRole) => {
            return {
                value: o,
                label: ED_ROLES_LABELS[o]
            }
        });

        return (
            <Fragment>
                <FormGroup row key={f.key}>
                    <Label md={3} for={f.key}>{f.label} {isRequired(f) ? '*' : null}</Label>
                    <Col md={9}><div className={styles.fieldContainer}>{renderField(f)}</div></Col>
                </FormGroup>
                {isEditor &&
                    <FormGroup row key={`attributes.ed.role`}>
                        <Label md={3} for={`attributes.ed.role`}>ED Role</Label>
                        <Col md={9}>
                            <div className={styles.fieldContainer}>
                                <div className={styles.advancedSelectWrapper}>
                                    <Select
                                        value={_.find(edRoleOptions, (o) => o.value == edRoleValue)}
                                        name={edRoleKey}
                                        isClearable={true}
                                        options={edRoleOptions}
                                        onChange={(newValue, actionMeta) => {
                                            if (newValue?.value == 'admin') {
                                                const newProfile = {
                                                    ...profile,
                                                    [globalAudienceKey]: true,
                                                    [edRoleKey]: ''
                                                }
                                                setProfile(newProfile);
                                            } else {
                                                const newProfile = {
                                                    ...profile,
                                                    [globalAudienceKey]: false,
                                                    [edRoleKey]: newValue?.value
                                                }
                                                setProfile(newProfile);
                                            }

                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </FormGroup>
                }
            </Fragment>
        )
    }

    const renderFields = () => {
        return _.map(peopleConfig, (f) => {
            if (f.key == `attributes.editor`) {
                return renderEdRoleFields(f);
            }
            if (f.key !== 'attributes.ed.role' && f.key !== 'attributes.globalAudience' && f.key !== 'status' && !f.disabled) {
                return (
                    <FormGroup row key={f.key}>
                        <Label md={3} for={f.key}>{f.label} {isRequired(f) ? '*' : null}</Label>
                        <Col md={9}><div className={styles.fieldContainer}>{renderField(f)}</div></Col>
                    </FormGroup>
                )
            }
        });
    }

    const changeField = (key: string, value: any) => {
        const newProfile = {
            ...profile,
            [key]: value
        }

        setProfile(newProfile)
    }

    const splitCommaSeparatedTags = (text: string): string[] => {
        return text.split(/[\,]+/).map((t) => _.trim(t));
    }

    const renderSelectOptionsField = (field: FieldConfig, optionsArray: OptionItem[]) => {
        const value = _.get(profile, field.key, '');

        const valueArray = value;

        const options: Array<{ value: string, label: string }> = _.map(optionsArray, (a) => {
            return {
                value: _.toString(a.id),
                label: a.label
            }
        });

        const notSelectableOptions: Array<{ value: string, label: string, isFixed?: boolean }> = _.compact(_.map(valueArray, (v) => {
            if (!_.find(options, (o) => o.value == v)) {
                return {
                    value: v,
                    label: v,
                    isFixed: true
                }
            }
            return null;
        }));

        const allOptions = [...notSelectableOptions, ...options];

        const selectStyles = {
            multiValue: (base: any, state: any) => {
                return state.data.isFixed ? { ...base, backgroundColor: 'grey' } : base
            },
            multiValueLabel: (base: any, state: any) => {
                return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: '6px' } : base
            },
            multiValueRemove: (base: any, state: any) => {
                return state.data.isFixed ? { ...base, display: 'none' } : base
            },
        }

        return (
            <div className={styles.advancedSelectWrapper}>
                <Select
                    isDisabled={!field.isEditable}
                    isClearable={field.isEditable}
                    value={_.filter(allOptions, (o) => _.includes(value, o.value))}
                    isMulti
                    name={field.key}
                    styles={selectStyles}
                    options={options}
                    onChange={(newValue, actionMeta) => {
                        let val = _.map(newValue, (v: any) => v.value);
                        switch (actionMeta.action) {
                            case 'remove-value':
                            case 'pop-value':
                                if (_.get(actionMeta, `removedValue.isFixed`)) {
                                    return;
                                }
                                break;
                            case 'clear':
                                val = _.map(notSelectableOptions, (v: any) => v.value);
                                break;
                        }
                        changeField(field.key, val);
                    }}
                />
            </div>
        )
    }

    const renderSingleSelectOptionField = (field: FieldConfig, optionsArray: string[]) => {
        const value = _.get(profile, field.key, '');

        // const valueArray = value;

        const options: Array<{ value: string, label: string }> = _.map(optionsArray, (o) => {
            return {
                value: o,
                label: o
            }
        });

        return (
            <div className={styles.advancedSelectWrapper}>
                <Select
                    value={_.find(options, (o) => o.value == value)}
                    name={field.key}
                    isClearable={true}
                    options={options}
                    onChange={(newValue, actionMeta) => {
                        let val = _.find(options, (o: any) => o.value == newValue?.value)
                        changeField(field.key, val?.value);
                    }}
                />
            </div>
        )
    }
    // const renderFeedsField = (field: FieldConfig) => {
    //     const value = _.get(profile, field.key, '');
    //     const options: Array<{ value: string, label: string }> = _.map(feeds, (f) => {
    //         return {
    //             value: _.toString(f.id),
    //             label: f.label
    //         }
    //     });
    //     return (
    //         <div className={styles.advancedSelectWrapper}>
    //             <Select
    //                 value={_.filter(options, (o) => _.includes(value, o.value))}
    //                 isMulti
    //                 name={field.key}
    //                 options={options}
    //                 onChange={(newValue) => {
    //                     const val = _.map(newValue, (v: any) => v.value);
    //                     changeField(field.key, val);
    //                 }}
    //             />
    //         </div>
    //     )
    // }

    const stringToDate = (d: string, type: 'date' | 'dateStr'): Date | null => {
        const timestamp = Date.parse(d);
        if (isNaN(timestamp)) { //invalid date
            return null;
        }

        const date = type == 'dateStr' ? moment(d).utc().toDate() : new Date(d);

        return date;

    }

    const renderField = (field: FieldConfig) => {
        if (field.key === 'attributes.audiences') {
            return renderSelectOptionsField(field, audiences);
        }
        if (field.key === 'attributes.ed.feeds') {
            return renderSelectOptionsField(field, _.map(feeds, (f) => { return { id: f.id, label: FeedsHelper.calcFeedLabel(_.toString(magazine), f) } }));
        }
        // if (field.key === 'attributes.ed.role') {
        //     return renderSingleSelectOptionField(field, ED_ROLES);
        // }

        const value = _.get(profile, field.key, '');

        switch (field.type) {
            case "checkbox":
                return <ThreeStateToggle value={_.get(profile, field.key, undefined)} disabled={!field.isEditable} onChange={(v) => { changeField(field.key, v) }} unsetValue={""} />
            case "date": case "dateStr":
                return (
                    <div className={styles.datePickerWrapper}>
                        <ReactDatePicker
                            disabled={!field.isEditable}
                            dateFormat={field.type == 'date' && !field.isEditable ? `${DateHelper.dailyDatePickerFormatBasedOnLocale()} HH:mm` : DateHelper.dailyDatePickerFormatBasedOnLocale()}
                            selected={stringToDate(value, field.type)}
                            isClearable={field.isEditable ? true : false}
                            customInput={<Input type={'text'} />}
                            popperPlacement={'auto'}
                            onChangeRaw={(e) => {
                                if (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                                return false;
                            }}
                            onChange={(d: Date, e: any) => {
                                let dateStr = '';
                                if (d) {
                                    try {
                                        const m = moment(d);
                                        if (m.isValid()) {
                                            dateStr = m.format('YYYY-MM-DD');
                                            changeField(field.key, dateStr)
                                        }
                                    } catch (error) {
                                        changeField(field.key, '')
                                    }
                                } else {
                                    changeField(field.key, '')
                                }
                                if (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                                return false;
                            }}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>
                )
            case "email":
                return (
                    <Fragment>
                        <Input invalid={!isValidEmail(value)} type="email" name={field.key} placeholder={`${field.label}..`} value={value} disabled={!field.isEditable} onChange={(e) => { changeField(field.key, e.target.value) }} />
                        <FormFeedback>This is not a valid email address</FormFeedback>
                    </Fragment>
                )
            case "textarea":
                return <Input type="textarea" name={field.key} placeholder={`${field.label}..`} value={value} disabled={!field.isEditable} onChange={(e) => { changeField(field.key, e.target.value) }} />;
            case "password":
                return (
                    <Fragment>
                        <Input type="text" name={field.key} placeholder={`${field.label}..`} value={hasValueChanged(field.key, value) ? value : ''} disabled={!field.isEditable} onChange={(e) => { changeField(field.key, e.target.value) }} />
                        <FormText>Leave empty to keep current password</FormText>
                    </Fragment>
                )
            case "arrayText":
            case "Multiple texts":
                const isWrongType = (!value || _.isEmpty(value) || value == '') ? false : (_.isArray(value) ? false : true);
                const feedback = isWrongType ? <FormText color='danger'>Stored value <code>{_.toString(value)}</code> is in wrong format! Please edit field and save to fix it! </FormText> : null;
                const val = (!value || _.isEmpty(value) || value == '') ? [] : (_.isArray(value) ? value : []);
                return <div className={styles.advancedSelectWrapper}>
                    <CreatableMultiTextInput
                        value={val}
                        onChange={vs => { changeField(field.key, _.uniq(vs)) }}
                        isDisabled={!field.isEditable}
                        placeholder={`Add ${field.label}..`}
                    />{feedback}</div>
            case 'number':
                return (
                    <Fragment>
                        <Input invalid={isRequired(field) && !value} type={'number'} name={field.key} placeholder={`${field.label}..`} value={value} disabled={!field.isEditable} onChange={(e) => { changeField(field.key, _.toNumber(e.target.value)) }} />
                        <FormFeedback>This field is required!</FormFeedback>
                    </Fragment>
                )
            default:
                return (
                    <Fragment>
                        <Input invalid={isRequired(field) && !value} type="text" name={field.key} placeholder={`${field.label}..`} value={value} disabled={!field.isEditable} onChange={(e) => { changeField(field.key, e.target.value) }} />
                        <FormFeedback>This field is required!</FormFeedback>
                        {(field.key == "accounts.0.username" && isRequired(field)) &&
                            <FormText>This will be copied to uid on save</FormText>
                        }
                    </Fragment>
                )
        }
    }

    const refactorAndSave = (p: any) => {
        if (!hasValueChanged('accounts.0.password', _.get(p, 'accounts.0.password'))) {
            _.set(p, 'accounts.0.password', undefined);
        }
        if (isNewEntry) {
            p.isNewEntry = true;
        }
        onSave(p);
    }

    const closeBtn = <img className="close" onClick={onClose} src="/assets/icons/bt-close.svg" />

    return (
        <Modal isOpen={true} centered toggle={() => onClose()} size={'lg'} className={styles.modal}>
            <ModalHeader><div className='headerTitle'><img src="/assets/icons/16/widget.svg" style={{ marginRight: '10px' }}></img> {isNewEntry ? 'Create' : 'Editing'} profile {isNewEntry ? '' : `- ${profile.uid}`}</div>{closeBtn}</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup row>
                        <Label md={3}>Profile Enabled:</Label>
                        <Col md={9}> <div className={styles.fieldContainer}><Toggle checked={profile.status} onChange={(e) => changeField('status', e.target.checked)} /></div></Col>
                    </FormGroup>
                    <hr />
                    {renderFields()}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button style={{ flex: 1 }} size={'lg'} outline color="secondary" onClick={() => onClose()}>Cancel</Button>
                <Button style={{ flex: 1 }} size={'lg'} color="primary" onClick={() => refactorAndSave(profile)}>Save</Button>{' '}
            </ModalFooter>
        </Modal>
    )
}
