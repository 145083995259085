import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import * as _ from 'lodash';
import * as queryString from 'query-string';

import { StatsFilter, Source, Form, MagazineLanguages, AppMeta } from '../domain';
import { ErrorWithStatus } from './utils';
import { findCurrentMagazineBasedOnLocation } from './utils/genericHelper';
import { Report } from '../client/pages/Report/domain';

let REDIRECT_LOCK: boolean = false
export const reload = (redirect: string) => {
  if (REDIRECT_LOCK === true) {
    console.warn(`already redirecting. wait.....`)
  } else {
    REDIRECT_LOCK = true
    window.location.replace(redirect)
  }
}

export function wrapAxios(p: AxiosPromise) {
  return p.catch(e => {
    const status = _.get(e, 'response.status')
    if (status === 498) {
      const redirect = _.get(e, 'response.data.redirect');
      console.log("Redirect url:", redirect);
      reload(redirect || `/auth/${findCurrentMagazineBasedOnLocation()}/login`)
      throw new ErrorWithStatus(498, `Token has expired. Redirecting...`)
    } else {
      throw e
    }
  })
}

export const endpointWithParams = (endpoint: string, params?: any): string => {
  return `${endpoint}${params ? `?${queryString.stringify(params, { arrayFormat: 'bracket' })}` : ``}`
}

const dashApiGet = (magazine: number, endpoint: string, config?: AxiosRequestConfig) => {
  return wrapAxios(axios.get(`/api/${magazine}/dashApi/${endpoint}`, config));
}
const dashApiPost = (magazine: number, endpoint: string, data?: any, config?: AxiosRequestConfig,) => {
  return wrapAxios(axios.post(`/api/${magazine}/dashApi/${endpoint}`, data, config));
}
const dashApiPostStream = (magazine: number, endpoint: string, data?: any, config?: AxiosRequestConfig,) => {
  return wrapAxios(axios.post(`/api/${magazine}/dashApiStream/${endpoint}`, data, config));
}
const dashApiDelete = (magazine: number, endpoint: string, config?: AxiosRequestConfig,) => {
  return wrapAxios(axios.delete(`/api/${magazine}/dashApi/${endpoint}`, config));
}


export const dashApi = {
  get: dashApiGet,
  post: dashApiPost,
  postStream: dashApiPostStream,
  delete: dashApiDelete,
}

axios.defaults.headers.get.Pragma = 'no-cache';

export const me = (magazine: string): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/me`));
};

export const loadChartData = (chart: string, params: StatsFilter, magazine: string): Promise<any> => {
  // return axios.get(`/api/${magazine}/analytics?chart='+chart+'&magazine='+magazine+'&audience='+audience);
  return wrapAxios(axios.post(`/api/${magazine}/analytics`, { chart: chart, params: params }));
};

export const getAudiences = (ids: any[], magazine: string): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/getAudiences`, { audiences: ids }));
}

export const loadAudienceKpi = (magazineId: number, audienceId?: number): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazineId}/getAudienceKPI`, { audience: audienceId }));
}

export const loadArticleContent = (article: number, magazine: string, includeCmsContent?: boolean, includeVariations?: boolean): Promise<any> => {
  return dashApi.post(_.toNumber(magazine), `getArticleContent`, { article: article, includeCmsContent, includeVariations });
}

export const setArticleStatus = (article: number, status: boolean, magazine: string): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/setArticleStatus`, { article: article, status: status }));
}

export const deleteSelectedComments = (comments: string[], magazine: string): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/deleteComments`, { comments: comments }));
}

export const getNotificationsHistory = (magazine: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/notifications`));
}

export const getNotification = (magazine: number, id: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/notifications/${id}`));
}

export const cancelNotification = (magazine: number, id: number): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/notifications/${id}/cancel`));
}

export const getProfilesAndDevicesFromAudiences = (magazine: number, audiences: number[], mode?: string): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/getProfilesAndDevicesFromAudiences`, { audiences: audiences, mode }));
}
export const sendNotification = (magazine: number, notification: any): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/sendNotification`, { notification: notification }));
}
export const getMagazineChannels = (magazine: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/getMagazineChannels`));
}
export const getArticleTitle = (magazine: number, article: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/articles/${article}/title`));
}
export const getArticleLikes = (magazine: number, article: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/articles/${article}/likes`));
}
export const getAudiencesByQueries = (magazine: number, queries: any[]): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/audiencesByQueries`, { queries: queries }));
}
//TODO: Deprecate this along with the server side related code
// export const getMagazineArticles = (magazine: number,limit?:number, channels?:number[], preferredVariationLanguage?: string, fallbackVariationLanguage?: string): Promise<any> => {
//   return wrapAxios(axios.post(`/api/${magazine}/getMagazineArticles`,{limit:limit,channels:channels, preferredVariationLanguage, fallbackVariationLanguage}));
// }
export const downloadReport = (magazine: number, report: Report): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/downloadReport`, { report: report }, { responseType: 'blob' }));
}
export const generateReport = (magazine: number, report: Report): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/generateReport`, { report: report }, { responseType: 'blob' }));
}
export const sendReport = (magazine: number, report: any): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/sendReport`, { report: report }));
}
export const getReports = (magazine: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/reports`));
}
export const getReportsArchive = (magazine: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/reportsArchive`));
}
// export const toggleReport = (magazine: number, params: any): Promise<any> => {
//   return wrapAxios(axios.post(`/api/${magazine}/toggleReport`, params));
// }
export const saveReport = (magazine: number, params: any): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/saveReport`, params));
}
export const scheduleReport = (magazine: number, params: any): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/scheduleReport`, params));
}
export const deleteReport = (magazine: number, params: any): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/deleteReport`, params));
}
export const deleteReportsArchive = (magazine: number, params: any): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/deleteReportsArchive`, params));
}

export const getAppMeta = (magazine: number): AxiosPromise<AppMeta> => {
  return wrapAxios(axios.get(`/api/${magazine}/appMeta`));
}

export const getChannelsInChannel = (magazine: number): Promise<any> => {
  // return wrapAxios(axios.get(`/api/${magazine}/cic${_.isEmpty(audiences)?'':`?audiences=${audiences.join()}`}`));
  return wrapAxios(axios.get(`/api/${magazine}/cic`));
}
export const getSources = (magazine: number): Promise<any> => {
  // return wrapAxios(axios.get(`/api/${magazine}/sources${_.isEmpty(audiences)?'':`?audiences=${audiences.join()}`}`));
  return wrapAxios(axios.get(`/api/${magazine}/sources`));
}
export const getSource = (magazine: number, source: number): Promise<any> => {
  // return wrapAxios(axios.get(`/api/${magazine}/source/${source}${_.isEmpty(audiences)?'':`?audiences=${audiences.join()}`}`));
  return wrapAxios(axios.get(`/api/${magazine}/source/${source}`));
}
export const getFeedOfSource = (magazine: number, source: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/source/${source}/feed`));
}
export const getFeeds = (magazine: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/feeds`));
}
export const saveSource = (magazine: number, source: Source): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/source`, { source }));
}
export const saveFeed = (magazine: number, source: number, channels: number[]): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/feedSource`, { source, channels }));
}

export const deleteSource = (magazine: number, source: number): Promise<any> => {
  return wrapAxios(axios.delete(`/api/${magazine}/source/${source}`));
}
export const getForms = (magazine: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/forms`, { headers: { Pragma: 'no-cache' } }));
}
export const getForm = (magazine: number, form: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/form/${form}`));
}
export const getFormResults = (magazine: number, form: number, audienceId?: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/form/${form}/results${audienceId ? `?audience=${audienceId}` : ''}`));
}
export const saveForm = (magazine: number, form: Form): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/form`, { form }));
}
export const duplicateForm = (magazine: number, form: number): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/form/${form}/duplicate`, {}));
}
export const changeStatus = (magazine: number, form: number, status: number): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/form/${form}/changeStatus`, { status }));
}
export const deleteForm = (magazine: number, form: number): Promise<any> => {
  return wrapAxios(axios.delete(`/api/${magazine}/form/${form}`));
}
export const exportFormResults = (magazine: number, form: number, mode: string, audienceId?: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/form/${form}/results/export?mode=${mode}${audienceId ? `&audience=${audienceId}` : ''}`));
}


// ────────────────────────────────────────────────────────────────────────────────

//
// ─── PROFILES ────────────────────────────────────────────────────────────────────────
//

export const getUsers = (magazine: number, uids: string[]): Promise<any> => {
  return dashApi.post(magazine, `profiles/getUsers`, { uids })
}

// ────────────────────────────────────────────────────────────────────────────────

export const getMonitoringRecords = (magazine: number): Promise<any> => {
  return wrapAxios(axios.get(`/api/${magazine}/monitoring`));
}

//
// ─── CHANNELS ────────────────────────────────────────────────────────────────────────
//

export const getArticleIdsInChannel = (magazine: number, channel: number): Promise<any> => {
  return dashApi.get(magazine, `channels/${channel}/articleIds`)
}

// ────────────────────────────────────────────────────────────────────────────────

//
// ─── WIDGETS ────────────────────────────────────────────────────────────────────────
//

// export const getWidgets = (magazine: number): Promise<any> => {
//   return dashApi.get(magazine,`widgets`)
// }
// export const saveWidget = (magazine: number,widget: Widget): Promise<any> => {
//   return dashApi.post(magazine,`widgets/save`,{widget})
// }
// export const deleteWidget = (magazine: number,id: string): Promise<any> => {
//   return dashApi.delete(magazine,`widget/${id}`)
// }
// export const getRegisteredUsers = (magazine: number, id: string): Promise<any> => {
//   return dashApi.get(magazine,`widget/${id}/registeredUsers`)
// }

// ────────────────────────────────────────────────────────────────────────────────

//
// ─── TRANSLATIONS ────────────────────────────────────────────────────────────────────────
//

export const getLanguages = (magazine: number): AxiosPromise<MagazineLanguages> => {
  return dashApi.get(magazine, `languages`);
}
export const getTranslations = (magazine: number, type: string, itemId: string): Promise<any> => {
  return dashApi.get(magazine, `translations/${type}/${itemId}`);
}


//
// ────────────────────────────────────────────────────RSS ─────
//
export const parseRSS = (magazine: number | string, url: string): AxiosPromise<{ title: string, size: number }> => {
  return wrapAxios(axios.get(`/api/${magazine}/parseRSS?${queryString.stringify({ url })}`))
}

// ────────────────────────────────────────────────────────────────────────────────


export const getChangelogContent = (magazine: number): Promise<any> => {
  return wrapAxios(axios.post(`/api/${magazine}/getChangelogContent`));
}