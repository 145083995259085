import { AxiosPromise } from 'axios';
import { dashApi } from "../api";

interface ContentFeed_DB {
  id: number,
  name: string,
  apikey: string,
  autodiscover: 1,
  baseurl: string | null,
  options?: string
}

export const getContentFeeds = (magazine: number): AxiosPromise<ContentFeed_DB[]> => {
  return dashApi.get(magazine, `contentFeeds`);
}
