import React from 'react'
import { media } from '@imgzine/imgzine-core-frontend';


function DetailMediaScriptOrIFrameInner(props: { mediaObj: media.ScriptMediaObject | media.IframeMediaObject }) {
  const ref = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML += props.mediaObj.html
    }
  }, [])
  return <div ref={ref} />
}

interface Props {
  mediaObj: media.ScriptMediaObject | media.IframeMediaObject
}

export default function DetailMediaScriptOrIFrame({ mediaObj }: Props) {
  return <DetailMediaScriptOrIFrameInner mediaObj={mediaObj} key={`${mediaObj.id}_${mediaObj.html}`} />
}