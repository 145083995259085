
import _ from 'lodash';
import React, { useState } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label, Input, FormFeedback } from "reactstrap";
import { CmsCategory } from './domain';


export interface Props {
  existingCategories: CmsCategory[]
  parentCategory?: CmsCategory
  closeHandler: () => void,
  createHandler: (name: string, parent?: CmsCategory) => void,
}

export function CreateCategoryModal({ existingCategories, parentCategory, closeHandler, createHandler }: Props) {
  const [name, setName] = useState('');

  const isNameInValid: boolean = _.find(existingCategories, (c) => {
    return c.category.name == name || _.find(c.children, (ch) => ch.name == name) ? true : false;
  }) ? true : false;

  const hasCommas: boolean = name.match(/\,+/g) ? true : false;

  const closeBtn = <img className="close" onClick={closeHandler} src="/assets/icons/bt-close.svg" />

  return (
    <Modal isOpen={true} centered id="CreateCategoryModal" toggle={() => closeHandler()}>
      <ModalHeader><div className='headerTitle'><img src="/assets/icons/16/widget.svg" style={{ marginRight: '10px' }}></img> Create New {parentCategory ? 'Child' : ''} Category</div>{closeBtn}</ModalHeader>
      <ModalBody>
        {parentCategory &&
          <Row style={{ marginBottom: '10px' }}>
            <Col md={2}><Label>Parent:</Label></Col>
            <Col md={10}>{parentCategory.category.name}</Col>
          </Row>
        }
        <Row>
          <Col md={2}><Label style={{ lineHeight: '38px' }}>Name:</Label></Col>
          <Col md={10}>
            <Input invalid={isNameInValid || hasCommas} type="text" value={name} onChange={(e) => setName(e.target.value)} />
            {isNameInValid && <FormFeedback>There is already a category with this name.</FormFeedback>}
            {hasCommas && <FormFeedback>Commas are not allowed in category names.</FormFeedback>}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!name || isNameInValid || hasCommas} color="primary" block size="lg" onClick={() => createHandler(name, parentCategory)} style={{ textTransform: 'uppercase' }}>create category</Button>
      </ModalFooter>
    </Modal>
  )
}