import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { StatsFilter } from '../../../../../domain';
import { API } from '../../../../apis';

import './css/VisitsPerOsChart.min.css';

const RADIAN = Math.PI / 180;

const COLORS = ['#002e67', '#009BC2', '#ee7d11', '#96BF0D', '#AB0076',];

export interface Props {
    magazine: number,
    statsFilter: StatsFilter,
}

type idValuePair = {
    id: string,
    value: number
}
export default function VisitsPerOsChart(props: Props) {
    const { magazine, statsFilter } = props;

    const [visitsPerOs, setVisitsPerOs] = useState<idValuePair[] | undefined | "!">(undefined);

    const [loading, setLoading] = useState<boolean>(true);

    const calcVisitsPerOs = async () => {
        try {
            setLoading(true);
            setVisitsPerOs(undefined);
            const { data: resp } = await API.analytics.loadChartData('visitsPerOs', magazine, statsFilter);
            setVisitsPerOs(resp.data);
        } catch (error) {
            console.error('An error occured:' + error.message);
            setVisitsPerOs('!');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        calcVisitsPerOs();
    }, [statsFilter.period, statsFilter.audience])


    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5 - 15;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const percentNumber = (percent * 100);
        if (percentNumber < 5) {
            return null;
        }

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${percentNumber.toFixed(0)}%`}
            </text>
        );
    };

    const drawChart = () => {

        if (loading) {
            return <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>

        }
        if (!visitsPerOs || visitsPerOs == "!") {
            return null;
        }

        const data = _.orderBy(visitsPerOs, ['value'], ['desc']);

        return (
            <div className='chartBody'>
                <ResponsiveContainer height={280}>
                    <PieChart>
                        <Pie
                            data={data}
                            nameKey="id"
                            dataKey="value"
                            label={renderCustomizedLabel}
                            // cx={420}
                            // cy={75}
                            innerRadius={50}
                            outerRadius={100}
                            fill="#8884d8"
                            labelLine={false}
                        // paddingAngle={5} 
                        >
                            {
                                data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                            }
                        </Pie>
                        <Tooltip />
                        <Legend verticalAlign={'bottom'} align="center" height={50} />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        )
    }

    const drawChartPanel = () => {

        return (
            <div className='chartPanel'>
                <div className='chartTitle'>Visits by Device</div>
                <div className="separator"></div>
            </div>
        )
    }

    return (
        <div id="VisitsPerOsChart" className="chartContainer">
            {drawChartPanel()}
            {drawChart()}
        </div>
    )

}
