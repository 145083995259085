import React, { Fragment } from 'react';
import { BrowserRouter as Router, RouteComponentProps, Route, Redirect, Switch } from "react-router-dom";
import * as _ from 'lodash';

import './app.min.css';
import Root from './Root';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

export interface Props {
}

const App = () =>
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Router>
      <Fragment>
        <Switch>
          <Route path="/:magazine" exact={true} render={(props: RouteComponentProps<{ magazine: string }>) => {
            const magazine = props.match.params.magazine;
            return <Redirect to={`/${magazine}/overview`} />;
          }} />
          <Route path="/:magazine/:component" render={(props: RouteComponentProps<{ magazine: string, component: string }>) => {
            const magazine = props.match.params.magazine;
            return <Root magazine={magazine} />
          }} />
        </Switch>
      </Fragment>
    </Router>
  </QueryClientProvider>


export default App;
