import React, { useMemo } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import { Input } from 'reactstrap';
import { NC_DEFAULT_FONTS, NC_DEFAULT_SETTINGS_FONTS } from '../subpages/NcSettings/NarrocastingSettingsDefaults';
import _ from 'lodash';
import { NcSettingsFormData } from '../subpages/NcSettings/NcSettingsConfiguration/NcSettingsConfiguration';
import { useQuery } from '@tanstack/react-query';
import { API } from '../../../apis';
import { EdErrorHandler, OrtecLoader } from '../../../widgets';
import styles from './NcSettingsFontSelect.module.scss';
import classNames from 'classnames';

interface Props {
  magazine: number,
  control: Control<NcSettingsFormData, any>,
  fieldName: FieldPath<NcSettingsFormData>
  flex1?: boolean
  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSettingsFontSelect = ({ magazine, control, fieldName, flex1 }: Props) => {

  const ncFontsQuery = useQuery({
    queryKey: ['ncFonts', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcSettingsFonts(magazine);
        return data;
      } catch (error) {
        if (error?.response?.status === 422) { //not valid schema. probably legacy record.
          return [...NC_DEFAULT_SETTINGS_FONTS];
        } else if (error?.response?.status === 404) {
          return [...NC_DEFAULT_SETTINGS_FONTS];
        } else {
          EdErrorHandler(error, `getting NC settings fonts`);
        }
      }
    }
  });

  const allAvailableFonts = useMemo(() => {
    return ncFontsQuery.isFetching
      ? [] :
      [...NC_DEFAULT_FONTS, ..._.map(ncFontsQuery.data, (f) => f.name)];

  }, [ncFontsQuery.data, ncFontsQuery.isFetching])


  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => {
        return (
          <div className={classNames({ [styles.fontsSelectLoadingWrapper]: ncFontsQuery.isFetching })}>
            <Input type={'select'} value={_.toString(field.value) || ''} onChange={(e) => { field.onChange(e.target.value) }} style={{ width: 'auto', flex: flex1 ? 1 : undefined }}>
              {_.map(allAvailableFonts, (f) => {
                return <option key={f} value={f}>{f}</option>
              })}
            </Input>
            {ncFontsQuery.isFetching && <div className={styles.loaderWrapper}><OrtecLoader size={'icon'} /></div>}
          </div>
        )
      }}
    />
  )
}
