import React, { useState } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import { GenericModal } from '../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../components/GenericModal/GenericModalHeader';
import { Controller, useForm } from 'react-hook-form';
import { EdErrorHandler, LoadingButton, SwalSuccess } from '../../widgets';
import styles from './NcDeviceCreateModal.module.scss';
import DataTable, { ExtendedColumnDescription } from '../../components/DataTable/DataTable';
import { NcDevice, NcGroup } from './NarrowcastingDomain';
import { SelectRowProps } from 'react-bootstrap-table-next';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { API } from '../../apis';

interface NewDeviceFormData {
  label: string
}

interface Props {
  magazine: number
  devices: NcDevice[]
  groups: NcGroup[]
  onClose: () => void
}

export const NcDeviceCreateModal = ({ magazine, devices, groups, onClose }: Props) => {

  const queryClient = useQueryClient();

  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [showOnlySelectedGroups, setShowOnlySelectedGroups] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const { control, handleSubmit, reset, formState: { errors } } = useForm<NewDeviceFormData>({
    defaultValues: {
      label: '',
    }
  });

  const onSave = handleSubmit(async (data) => {
    try {
      setSaveLoading(true);
      await API.narrowcasting.createNcDevice(magazine, data.label, selectedGroups);
      SwalSuccess.fire({
        title: 'Success!',
        text: `Device <${data.label}> has been created successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['ncDevices'] })
      onClose();
    } catch (error) {
      EdErrorHandler(error, `creating device`);
    } finally {
      setSaveLoading(false);
    }
  })

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'label',
      text: '',
      headerFormatter: () => {
        return (
          <div className={styles.showFilters}>
            <span className={showOnlySelectedGroups ? styles.showOption : styles.showSelectedOption} onClick={() => { setShowOnlySelectedGroups(false) }}>Show all</span>
            <span className={styles.filterSeparator}>/</span>
            <span className={!showOnlySelectedGroups ? styles.showOption : styles.showSelectedOption} onClick={() => { setShowOnlySelectedGroups(true) }}>Show selected</span>
          </div>
        )
      }
    },
  ];

  const selectRow: SelectRowProps<NcGroup> = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: [...selectedGroups],
    onSelect: (ncGroup, isSelect, rowIndex, e) => {
      const newSelected = isSelect ? [...selectedGroups, ncGroup.id] : _.filter(selectedGroups, (s) => s !== ncGroup.id);
      setSelectedGroups(newSelected);
    },
    onSelectAll: (isSelect, ncGroups, e) => {
      setSelectedGroups(isSelect ? [..._.map(ncGroups, 'id')] : [])
    }
  }

  return (
    <GenericModal isOpen={true} centered size={'lg'}>
      <GenericModalHeader
        onClose={onClose}
        title={`add new device`}
      />
      <ModalBody>
        <FormGroup>
          <Label style={{ fontWeight: 'bold' }}>Device name:</Label>
          <Controller
            name={'label'}
            control={control}
            rules={{ required: true, validate: (v: string) => !_.includes(_.map(devices, (d) => d.label), v) }}
            render={({ field }) => {
              return (
                <Input invalid={errors.label ? true : false} {...field} onChange={(e) => field.onChange(e.target.value)} />
              )
            }}
          />
          {errors.label && <FormFeedback>{errors.label.type == 'required' ? `Device name is required.` : `Device name already exists.`}</FormFeedback>}
        </FormGroup>
        <hr />
        <FormGroup>
          <Label className={styles.groupLabel}>Groups</Label>
          <DataTable
            data={showOnlySelectedGroups ? _.filter(groups, (g) => _.includes(selectedGroups, g.id)) : groups}
            columns={columns}
            keyField={'id'}
            onRowClick={() => { }}
            selectRow={selectRow}
            hideSearchBar
            noPagination
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button style={{ flex: 1 }} size={'lg'} outline color="secondary" onClick={onClose}>Cancel</Button>
        <LoadingButton style={{ flex: 1 }} text={'save'} loading={saveLoading} onClick={onSave} />
      </ModalFooter>
    </GenericModal>
  )
}

