import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Bar, BarChart, CartesianGrid, Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { StatsFilter } from '../../../../../domain';
import { API } from '../../../../apis';
import { DashBarChart } from '../../../../charts';
import { ChartsHelper } from '../../../../utils/ChartsHelper';

import './css/UserHabitsChart.min.css';

const RADIAN = Math.PI / 180;

const COLORS = ['#002e67', '#009BC2', '#ee7d11', '#96BF0D', '#AB0076',];

export interface Props {
    magazine: number,
    statsFilter: StatsFilter,
}

type idValuePair = {
    id: string,
    value: number
}
export default function UserHabitsChart(props: Props) {
    const { magazine, statsFilter } = props;

    const [visitsPerOs, setVisitsPerOs] = useState<idValuePair[] | undefined | "!">(undefined);
    const [visitsPerPersonDistribution, setVisitsPerPersonDistribution] = useState<idValuePair[] | undefined | "!">(undefined);

    const [loading, setLoading] = useState<boolean>(true);
    const [visitsPerPersonDistributionLoading, setVisitsPerPersonDistributionLoading] = useState<boolean>(true);

    const calcVisitsPerOs = async () => {
        try {
            setLoading(true);
            setVisitsPerOs(undefined);
            const { data: resp } = await API.analytics.loadChartData('visitsPerOs', magazine, statsFilter);
            setVisitsPerOs(resp.data);
        } catch (error) {
            console.error('An error occured:' + error.message);
            setVisitsPerOs('!');
        } finally {
            setLoading(false);
        }
    }

    const calcVisitsPerPersonDistribution = async () => {
        try {
            setVisitsPerPersonDistributionLoading(true);
            setVisitsPerPersonDistribution(undefined);
            const { data: resp } = await API.analytics.loadChartData('visitsPerPersonDistribution', magazine, statsFilter);
            setVisitsPerPersonDistribution(resp.data);
        } catch (error) {
            console.error('An error occured:' + error.message);
            setVisitsPerPersonDistribution('!');
        } finally {
            setVisitsPerPersonDistributionLoading(false);
        }
    }

    useEffect(() => {
        calcVisitsPerOs();
        calcVisitsPerPersonDistribution();
    }, [statsFilter.period, statsFilter.audience])


    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent
    }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5 - 15;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const percentNumber = (percent * 100);
        if (percentNumber < 5) {
            return null;
        }

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${percentNumber.toFixed(0)}%`}
            </text>
        );
    };

    const drawChart = () => {

        if (loading) {
            return <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>

        }
        if (!visitsPerOs || visitsPerOs == "!") {
            return null;
        }

        const data = _.orderBy(visitsPerOs, ['value'], ['desc']);

        return (
            <ResponsiveContainer height={280}>
                <PieChart>
                    <Pie
                        data={data}
                        nameKey="id"
                        dataKey="value"
                        label={renderCustomizedLabel}
                        // cx={420}
                        // cy={75}
                        innerRadius={50}
                        outerRadius={100}
                        fill="#8884d8"
                        labelLine={false}
                    // paddingAngle={5} 
                    >
                        {
                            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign={'bottom'} align="center" height={50} />
                </PieChart>
            </ResponsiveContainer>
        )
    }
    const yTickFormatter = (t: any) => {
        return `${t}${_.toNumber(t) == 10 ? '+' : ''}`
    }
    const tooltipLabelFormatter = (t: any) => {
        return `${yTickFormatter(t)} visit${_.toNumber(t) != 1 ? 's' : ''} ${ChartsHelper.isPeriodForMonthlyGroup(statsFilter.period) ? 'per month' : (ChartsHelper.isSmartPeriod(statsFilter.period) ? 'per day' : '')}`
    }

    const tooltipFormatter = (t: any) => {

        if (ChartsHelper.isSmartPeriod(statsFilter.period) || ChartsHelper.isPeriodForMonthlyGroup(statsFilter.period)) {
            return t.toFixed(2);
        }

        return t;

    }

    const drawChart_visitsPerPersonDistribution = () => {

        if (loading) {
            return <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>

        }
        if (!visitsPerPersonDistribution || visitsPerPersonDistribution == "!") {
            return null;
        }

        const fill = '#002e67';
        const stroke = '#002e67';

        console.log('visitsPerPersonDistribution: ', visitsPerPersonDistribution);
        return (
            <ResponsiveContainer height={280}>
                <BarChart data={visitsPerPersonDistribution} layout={'vertical'}>
                    <CartesianGrid horizontal={false} />
                    <XAxis allowDecimals={false} type={'number'}>
                    </XAxis>
                    <YAxis dataKey="id" tickFormatter={yTickFormatter} allowDecimals={false} type={'category'} interval={0} />
                    <Tooltip labelFormatter={tooltipLabelFormatter} formatter={tooltipFormatter} />
                    <Legend formatter={() => 'Users per frequency of visits'} />
                    <Bar dataKey={'value'} name={`${(ChartsHelper.isSmartPeriod(statsFilter.period) || ChartsHelper.isPeriodForMonthlyGroup(statsFilter.period)) ? 'Avg. ' : ''}Users`} fill={fill} stroke={stroke} animationDuration={500} />
                </BarChart>
            </ResponsiveContainer>
        )
    }

    const drawChartPanel = () => {

        return (
            <div className='chartPanel'>
                <div className='chartTitle'>user habits</div>
                <div className="separator"></div>
            </div>
        )
    }

    return (
        <div id="UserHabitsChart" className="chartContainer">
            {drawChartPanel()}
            <div className='chartBody'>
                <Row>
                    <Col md={12}>
                        {drawChart_visitsPerPersonDistribution()}
                    </Col>
                    {/* <Col md={6}>
                        {drawChart()}
                    </Col> */}
                </Row>
            </div>

        </div>
    )

}
