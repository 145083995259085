import React, { useMemo } from 'react'
import { LanguageContextConsumer } from '../LanguageContext';
import * as _ from 'lodash';
import { TranslateFunction, TranslationsObject } from '../../domain';
import en from './en';
import nl from './nl';

const translations: any = {
  en: en,
  nl: nl,
}


const language = 'en';

const EnableTranslations = (self: TranslationsObject) => {
  return (
    <LanguageContextConsumer>
      {languageContext => { self.language = languageContext ? languageContext.language : 'en'; return null; }}
    </LanguageContextConsumer>
  )
}

const Translate = (self: TranslationsObject, n?: string): TranslateFunction => {
  const file = translations[self.language] ? translations[self.language] : translations['en'];
  if (n) { //if namespace is given
    // return (key) => (file[n] && file[n][key])?file[n][key]: (translations['en'][n] && translations['en'][n][key])?translations['en'][n][key]: file[key] || translations['en'][key] || key;
    return (key) => _.get(file, `${n}.${key}`) || _.get(translations['en'], `${n}.${key}`) || _.get(file, `${'Default'}.${key}`) || _.get(translations['en'], `${'Default'}.${key}`) || key;
  } else {  //if namespace is NOT given
    // return (key) => file[key]?file[key]:translations['en'][key] || key;
    return (key) => _.get(file, `${'Default'}.${key}`) || _.get(translations['en'], `${'Default'}.${key}`) || key;
  }
}

export function useTranslations(n?: string): TranslateFunction {
  return useMemo(
    () => Translations.Translate(Translations, n),
    [n]
  )
}

export const Translations = {
  EnableTranslations,
  Translate,
  language,
  useTranslations,
}