import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Input } from 'reactstrap';
import { ciHelper } from '.';
import { TippyReact } from '../../../../../components';
import { ALL_KEY_TYPES } from './domain';

import styles from './KeyTypeSelect.module.scss';

interface Props {
  typeKey: string,
  typeValue: string,
  onChange: (key: string, newTypeValue: string) => void
  doesKeyExist: (key: string) => boolean
}

export const KeyTypeSelect = ({ typeKey, typeValue, onChange, doesKeyExist }: Props) => {
  return (
    <div className={styles.keyTypeSelect}>
      <Input type="select" value={ciHelper.keyToKeyType(typeKey)} onChange={(e) => onChange(typeKey, e.target.value)} disabled={typeValue ? true : false} className={styles.selectBox}>
        {_.map(ALL_KEY_TYPES, (k) => {
          return <option key={k.key} value={k.key} disabled={doesKeyExist(k.key)}>{k.label}</option>
        })}
      </Input>
      <TippyReact content={ciHelper.keyTypePlaceholder(typeKey)}>
        <span className={classNames('material-icons', styles.infoIcon)}>info</span>
      </TippyReact>
    </div>
  )
}
