import React from 'react';
import { SearchingProps } from './domain';
import styles from './ArticlesFilters.module.scss';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import classNames from 'classnames';

import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { ChannelsSelect, Channel } from '../../../../components/ChannelsSelect/ChannelsSelect';
import { Source, SourcesSelect } from '../../../../components/SourcesSelect/SourcesSelect';
import _ from 'lodash';
import Creatable from 'react-select/creatable';
import { useDebouncedCallback } from 'use-debounce';
import { Profile } from '../../../../../domain';
import { DateHelper } from '../../../../utils';

interface Props extends SearchingProps {
  toggleFilters: () => void,
  changeFilters: (field: string, newVal: any) => void,
  changeSearchText: (text: string) => void,
  channels: Channel[]
  sources: Source[]
  tags: string[]
  categories: string[]
  profile: Profile
}

export const ArticlesFilters = ({ searchText, filtersEnabled, filters, toggleFilters, changeFilters, changeSearchText, channels, sources, tags, categories, profile }: Props) => {

  const sourcesInChannel = (channel: string): Source[] => {
    if (!channel) {
      return [...sources];
    }
    return _.filter(sources, (s) => {
      return _.includes(s.parentChannels, _.toNumber(channel));
    })
  }

  const onChangeSearchInput = useDebouncedCallback(
    // function
    (val: string) => {
      changeSearchText(val);
    },
    // delay in ms
    300
  );

  const tagsToOptions = (vals: string[]): Array<{ value: string, label: string }> => {
    return _.map(vals, (v) => {
      return {
        value: v,
        label: v
      }
    })
  }

  const categoriesToOptions = (cats: string[]): Array<{ value: string, label: string }> => {
    return _.map(cats, (c) => {
      return {
        value: c,
        label: c.replace('_cat:', '')
      }
    })
  }

  return (
    <div className={styles.ArticlesFilters}>
      <div className={styles.searching}>
        <Input className={styles.searchInput} type="search" placeholder={'Search for Title or ID'} defaultValue={searchText} onChange={(e) => onChangeSearchInput(e.target.value)} />
        <Button className={classNames('toggleButton', { 'active': filtersEnabled })} onClick={() => toggleFilters()} color={''}> {filtersEnabled ? `Disable` : `Apply`} Filters  <img style={{ marginLeft: "5px", verticalAlign: "text-bottom" }} src={`/assets/icons/16/advanced_${filtersEnabled ? 'grey' : 'blue'}.svg`} /></Button>
      </div>
      {filtersEnabled &&
        <div className={styles.filters}>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label>Channel:</Label>
                <ChannelsSelect
                  channels={channels}
                  selected={filters.channel}
                  onChange={(id: string) => {
                    changeFilters('channel', id);
                  }}
                  channelGraphName={profile.edConfig?.channelGraphName}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Source:</Label>
                <SourcesSelect
                  sources={sourcesInChannel(filters.channel)}
                  selected={filters.channel}
                  onChange={(id: string) => {
                    changeFilters('source', id);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>From:</Label>
                <div className={styles.dateFieldWrapper}>
                  <Datepicker
                    dateFormat={DateHelper.dailyDatePickerFormatBasedOnLocale()}
                    selected={filters.since ? new Date(filters.since) : null}
                    isClearable={true}
                    maxDate={filters.until ? new Date(filters.until) : null}
                    placeholderText={`Select a date`}
                    customInput={<Input type={'text'} placeholder={``} readOnly />}
                    onChange={(d: Date) => {
                      if (!d) {
                        changeFilters(`since`, ``)
                      } else {
                        changeFilters(`since`, moment(d).format('YYYY-MM-DD'))
                      }
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>To:</Label>
                <div className={styles.dateFieldWrapper}>
                  <Datepicker
                    dateFormat={DateHelper.dailyDatePickerFormatBasedOnLocale()}
                    selected={filters.until ? new Date(filters.until) : null}
                    isClearable={true}
                    minDate={filters.since ? new Date(filters.since) : null}
                    placeholderText={`Select a date`}
                    customInput={<Input type={'text'} placeholder={``} readOnly />}
                    onChange={(d: Date) => {
                      if (!d) {
                        changeFilters(`until`, ``)
                      } else {
                        changeFilters(`until`, moment(d).format('YYYY-MM-DD'))
                      }
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label>Categories & Tags:</Label>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, maxWidth: "50%" }}>
                    <Creatable
                      isClearable
                      isMulti
                      // menuIsOpen={false}
                      placeholder="Add category"
                      formatCreateLabel={(val: string) => `Add this category: "${val}"`}
                      value={_.map(filters.categories, (c) => { return { label: c, value: c } })}
                      onChange={(newCats: any) => {
                        changeFilters('categories', _.map(newCats, (c) => {
                          return _.startsWith(c.value, '_cat:') ? c.value : `_cat:${c.value}`
                        }))
                      }}
                      options={categoriesToOptions(categories)}

                    />
                  </div>
                  <div style={{ width: "10px" }}></div>
                  <div style={{ flex: 1, maxWidth: "50%" }}>

                    <Creatable
                      isClearable
                      isMulti
                      // menuIsOpen={false}
                      placeholder="Add tag"
                      formatCreateLabel={(val: string) => `Add this tag: "${val}"`}
                      value={_.map(filters.tags, (t) => { return { label: t, value: t } })}
                      onChange={(newTags: any) => {
                        changeFilters('tags', _.map(newTags, (t) => t.value))
                      }}
                      options={tagsToOptions(tags)}

                    />
                  </div>
                </div>

              </FormGroup>
            </Col>

          </Row>
        </div>
      }
    </div>
  )
}