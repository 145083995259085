import React from 'react'
import cn from 'classnames'
import { media, MediaHelper, UrlProxyMapper } from '@imgzine/imgzine-core-frontend'

import styles from './DetailFeaturedImage.module.scss'
import Image from './Image'

interface Props {
  social?: boolean
  featuredImage: {
    image: media.Image;
    link: MediaHelper.ImageLink;
  },
  urlProxyMapper: UrlProxyMapper
}

export default function DetailFeaturedImage(props: Props) {
  const { featuredImage, social, urlProxyMapper } = props
  const aspect = getAspectRatio(featuredImage.link) || 3 //fallback to wide
  const cat = social ? 'social' : aspect <= 1 ? 'portrait' : aspect <= 2 ? 'landscape' : 'wide'
  switch (cat) {
    case 'portrait':
      // crop to 4:3
      return <div className={styles.portrait1}>
        <div className={styles.portrait2}>
          <div className={cn(styles.link, styles.inner)}>
            <Image link={featuredImage.link} urlProxyMapper={urlProxyMapper} />
          </div>
        </div>
      </div>
    case 'landscape':
      // crop to 2:1
      return <div className={styles.landscape}>
        <div className={cn(styles.link, styles.inner)} >
          <Image link={featuredImage.link} urlProxyMapper={urlProxyMapper} />
        </div>
      </div>
    case 'social':
    case 'wide':
      //show as is.no crop
      return <div className={cn(styles.link)}>
        <Image link={featuredImage.link} customRenderer={({ url }) => <img className={styles.fullWidth} src={url} alt="featured" />} urlProxyMapper={urlProxyMapper} />
      </div>
  }
}

function getAspectRatio(imageLink: { w?: number; h?: number }): number | undefined {
  if (imageLink.h === undefined || imageLink.w === undefined) {
    return undefined
  }
  return imageLink.w / imageLink.h
}