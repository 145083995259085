import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import { Profile } from '../../../domain';
import { API } from '../../apis';
import { TippyReact } from '../../components';
import DataTable, { ExtendedColumnDescription } from '../../components/DataTable/DataTable';
import { GenericModal } from '../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../components/GenericModal/GenericModalHeader';
import { EdErrorHandler, OrtecLoader, SwalDelete, SwalSuccess, LoadingButton } from '../../widgets';
import { NcDevice, NcDeviceStatus, NcGroup } from './NarrowcastingDomain';
import { NcDeviceCreateModal } from './NcDeviceCreateModal';
import styles from './NcDevices.module.scss';
import { NcGroupsModal } from './NcGroupsModal';
import { useHistory } from 'react-router-dom';
import { DateHelper, HistoryHelper } from '../../utils';
import { NcDeviceDetails } from './NcDeviceDetails';
import moment from 'moment';
import { NarrowcastingHelper } from './NarrowcastingHelper';

interface Props {
  magazine: number,
  profile: Profile
}

export const NcDevices = ({ magazine, profile }: Props) => {

  const queryClient = useQueryClient();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isGroupsModalOpen, setIsGroupsModalOpen] = useState<boolean>(false);

  const history = useHistory();
  const deviceParam = HistoryHelper.getQueryParam(history, 'device');
  const editDeviceId = deviceParam ? _.toNumber(deviceParam) : undefined;

  const ncDevicesQuery = useQuery({
    queryKey: ['ncDevices', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcDevices(magazine);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting NC devices`);
      }
    }
  });

  const ncGroupsQuery = useQuery({
    queryKey: ['ncGroups', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcGroups(magazine);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting NC groups`);
      }
    },

  });

  const ncDeviceDeleteMutation = useMutation({
    mutationKey: ['ncDeviceDelete'],
    mutationFn: (d: NcDevice) => API.narrowcasting.deleteNcDevice(magazine, d.id),
    onError: (error, d) => {
      EdErrorHandler(error, `deleting device [${d.id}] ${d.label}`);
    },
    onSuccess: (data, d) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `Device <${d.label}> has been deleted successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['ncGroups'] })
      queryClient.invalidateQueries({ queryKey: ['ncDevices'] })
    }
  })

  const onDelete = async (device: NcDevice) => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      html: `This action will delete device <code>[${device.id}] ${device.label}</code>.`
    });
    if (!confirm) {
      return;
    }
    ncDeviceDeleteMutation.mutate(device);
  }


  const isLoading = ncDevicesQuery.isFetching || ncGroupsQuery.isFetching;

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'deleted',
      sort: false,
      text: '',
      headerStyle: { width: '50px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <><img data-tippy-content={'Status'} src={'/assets/icons/16/stats.svg'} /><span></span></>
      },
      formatter: (deleted: boolean, row: NcDevice) => {
        const status = calcDeviceStatus(row.unique_id, row.last_seen);
        const tippyContent = _.capitalize(status);

        return <i className={`fa fa-circle status status-${status}`} data-tippy-content={tippyContent}></i>;

      }
    },
    {
      dataField: 'last_seen',
      text: '',
      hidden: true,
      formatter: (deleted: boolean, row: NcDevice) => {
        const status = calcDeviceStatus(row.unique_id, row.last_seen);
        return status;
      }
    },
    {
      dataField: 'label',
      sort: true,
      text: 'device name'
    },
    {
      dataField: 'group_ids',
      sort: false,
      text: 'device groups',
      formatter: (cell: string, row: NcDevice) => {
        try {
          const ids = cell || [];
          if (_.isEmpty(ids)) {
            return <></>;
          }
          const names = _.compact(_.map(ids, (id) => NarrowcastingHelper.findGroupName(_.toNumber(id), ncGroupsQuery.data || [])));

          if (ids.length <= 2) {
            return <span>{names.join(', ')}</span>
          }
          return <TippyReact config={{ placement: 'left' }} content={names.join(', ')}><div><span>{ids.length} groups</span></div></TippyReact>
        } catch (error) {
          return <span>-</span>;
        }
      }
    },
    {
      dataField: 'slideshow_ids',
      sort: true,
      hidden: true, //TODO: Probably remove this entire column
      text: 'slideshows',
      headerStyle: { width: '140px' },
      align: 'center',
      headerAlign: 'center',
      formatter: (cell: string, row: NcDevice) => {
        try {
          const ids = cell || [];
          return <>{ids.length}</>
        } catch (error) {
          return <span>-</span>;
        }
      }
    },
    {
      dataField: 'totalSlides',
      sort: true,
      text: 'slides',
      headerStyle: { width: '100px' },
      align: 'center',
      headerAlign: 'center',
      formatter: (totalSlides: number, row: NcDevice) => {
        return <>{totalSlides}</>
      }
    },
    {
      dataField: 'dfActions',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatter: (cell, row: NcDevice) => {
        return (
          <div className={'actionHoveringDiv'}>
            <Button color={'secondary'} onClick={(e) => { onDelete(row); e.preventDefault(); e.stopPropagation(); return false; }}>Delete</Button>
          </div>
        )
      }
    },
    {
      dataField: 'dfConnect',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '100px' },
      classes: 'actionsColumn',
      formatter: (cell, row: NcDevice) => {
        if (row.reg_code) {
          return (
            <DisconnectLink magazine={magazine} device={row} />
          )
        } else {
          return (
            <ConnectButton magazine={magazine} device={row} />
          )
        }
      },
    }
  ];

  const onOpenDevice = (deviceId: number) => {
    HistoryHelper.setQueryParams(history, { device: deviceId });
  }

  const onCloseDevice = () => {
    HistoryHelper.setQueryParams(history, { device: undefined });
  }

  return (
    <div className={styles.NcDevices}>
      {isLoading && !editDeviceId && <OrtecLoader />}
      <DataTable
        data={ncDevicesQuery.data || []}
        columns={columns}
        keyField={'id'}
        onRowClick={(row: NcDevice) => { onOpenDevice(row.id) }}
        onCreateNew={() => setIsCreateModalOpen(true)}
        createNewButtonText={`add new device`}
        secondaryElements={
          <Button style={{ marginRight: '15px' }} outline color={'secondary'} onClick={() => { setIsGroupsModalOpen(true) }}>view groups</Button>
        }
      />
      {isCreateModalOpen && <NcDeviceCreateModal magazine={magazine} groups={ncGroupsQuery.data || []} devices={ncDevicesQuery.data || []} onClose={() => setIsCreateModalOpen(false)} />}
      {isGroupsModalOpen && <NcGroupsModal magazine={magazine} groups={ncGroupsQuery.data || []} devices={ncDevicesQuery.data || []} onClose={() => setIsGroupsModalOpen(false)} />}
      {editDeviceId && <NcDeviceDetails magazine={magazine} devices={ncDevicesQuery.data || []} ncGroupsQuery={ncGroupsQuery} onClose={onCloseDevice} deviceId={editDeviceId} />}
    </div>
  )
}

// ─────────────────────────────────────────────────────────────────────────────

interface DisconnectLinkProps {
  magazine: number
  device: NcDevice
}

export const DisconnectLink = ({ magazine, device }: DisconnectLinkProps) => {

  const queryClient = useQueryClient();

  const ncDeviceDisconnectMutation = useMutation({
    mutationKey: ['ncDeviceDisconnect'],
    mutationFn: async (deviceId: number) => {
      await API.narrowcasting.disconnectDevice(magazine, deviceId)

    },
    onError: (error, deviceId) => {
      EdErrorHandler(error, `disconnecting device: ${device.label}`)
    },
    onSuccess: async (data, deviceId) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `Device <${device.label}> has been disconnected successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 1000,
      });
      queryClient.invalidateQueries({ queryKey: ['ncDevices'] })
      queryClient.invalidateQueries({ queryKey: ['ncDevice', device.id] })
    }
  });

  const onDisconnectDevice = async (d: NcDevice) => {

    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: `Yes, disconnect it!`,
      focusCancel: true,
      html: `This action will permanently disconnect the device <code>${d.label}</code>.`
    });

    if (!confirm) {
      return;
    }

    ncDeviceDisconnectMutation.mutate(d.id)

  }

  if (ncDeviceDisconnectMutation.isLoading || ncDeviceDisconnectMutation.isSuccess) {
    return (
      <div style={{ textAlign: 'center' }}><OrtecLoader size={'icon'} /></div>
    )
  }

  return (
    <div className={styles.disconnectLink} onClick={(e) => { onDisconnectDevice(device); e.preventDefault(); e.stopPropagation(); return false; }}>
      Disconnect
    </div>
  )
}

// ─────────────────────────────────────────────────────────────────────────────

interface ConnectFormData {
  reg_code: string
}

interface ConnectButtonProps {
  magazine: number
  device: NcDevice
}

export const ConnectButton = ({ magazine, device }: ConnectButtonProps) => {

  const [isConnectModalOpen, setIsConnectModalOpen] = useState<boolean>(false);
  const { control, handleSubmit, reset, formState: { errors } } = useForm<ConnectFormData>({
    defaultValues: {
      reg_code: ''
    }
  });

  const onConnect = handleSubmit((data) => {
    ncDeviceConnectMutation.mutate(data.reg_code)
  })


  const queryClient = useQueryClient();

  const ncDeviceConnectMutation = useMutation({
    mutationKey: ['ncDeviceConnect'],
    mutationFn: async (reg_code: string) => {
      await API.narrowcasting.connectDevice(magazine, device.id, reg_code);

    },
    onError: (error, reg_code) => {
      EdErrorHandler(error, `connecting device: ${device.label}`)
    },
    onSuccess: async (data, reg_Code) => {
      onCloseConnectModal();
      SwalSuccess.fire({
        title: 'Success!',
        text: `Device <${device.label}> has been connected successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 1000,
      });
      queryClient.invalidateQueries({ queryKey: ['ncDevices'] })
      queryClient.invalidateQueries({ queryKey: ['ncDevice', device.id] })
    }
  });

  const onOpenConnectModal = () => {
    setIsConnectModalOpen(true);
  }

  const onCloseConnectModal = () => {
    setIsConnectModalOpen(false);
  }

  if (ncDeviceConnectMutation.isSuccess) {
    return (
      <div style={{ textAlign: 'center' }}><OrtecLoader size={'icon'} /></div>
    )
  }

  return (
    <>
      <div>
        <Button color={'secondary'} onClick={(e) => { onOpenConnectModal(); e.preventDefault(); e.stopPropagation(); return false; }}>Connect</Button>
      </div>
      <GenericModal isOpen={isConnectModalOpen} centered>
        <GenericModalHeader
          onClose={onCloseConnectModal}
          title={`connect device`}
        // icon={`/assets/icons/16/devices.svg`}
        />
        <ModalBody>
          <FormGroup>
            <Label style={{ fontWeight: 'bold' }}>Code:</Label>
            <Controller
              name={'reg_code'}
              control={control}
              rules={{ required: true, maxLength: 5 }}
              render={({ field }) => {
                return (
                  <Input invalid={errors.reg_code ? true : false} {...field} onChange={(e) => field.onChange(e.target.value)} />
                )
              }}
            />
            {errors.reg_code && <FormFeedback>Code is required and should have max 5 characters.</FormFeedback>}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <LoadingButton block text={'connect'} loading={ncDeviceConnectMutation.isLoading} onClick={onConnect} />
        </ModalFooter>
      </GenericModal>
    </>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const ONLINE_THRESSHOLD_IN_SECONDS = 600  //600=10 minutes (use 102124851 for 3 years before);

export const calcDeviceStatus = (unique_id?: string, last_seen?: string): NcDeviceStatus => {
  if (!unique_id) {
    return 'disconnected';
  }

  const lastSeenDate = DateHelper.stringToDate(last_seen || '');

  if (lastSeenDate && moment().diff(moment(lastSeenDate), 'seconds') < ONLINE_THRESSHOLD_IN_SECONDS) {
    return 'online';
  }
  return 'offline';
}