import React from 'react';
import _ from 'lodash';
import cn from 'classnames'
import { Controlled as CodeMirror } from 'react-codemirror2';
require('codemirror/mode/javascript/javascript');
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

import styles from './ChannelDetailsChannelInfo.module.scss'
import { PageSwitch } from '../../../../../components/PageSwitch/PageSwitch';
import { ChannelInfoPairs } from '../../Channels/ChannelInfoPairs/ChannelInfoPairs';
import { Profile } from '../../../../../../domain';


function isValidJson(value: string) {
  try {
    if (!value) {
      return true;
    }
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}

export default function ChannelDetailsChannelInfo({ magazine, value, onChange, profile }: {
  magazine: number,
  profile: Profile
  value: string,
  onChange: (v: string) => void
}) {
  const [showCode, setShowCode] = React.useState(false)

  return <>
    <PageSwitch label={'Tile Settings:'} optionLeft={'key-value'} optionRight={'code'} isRightActive={showCode} onClick={() => { setShowCode(!showCode) }} />
    {!showCode
      ? <ChannelInfoPairs magazine={magazine} channelInfo={value} changeChannelInfo={onChange} profile={profile} />
      : <CodeMirror
        // value={ ios || ''}
        value={value}
        className={cn(isValidJson(value) ? '' : 'hasErrors', styles.codemirror)}
        options={{
          mode: {
            name: "javascript",
            jsonld: true
          },
          theme: "material",
          lineNumbers: true
        }}
        onBeforeChange={(editor, data, value) => {
          onChange(value);
        }}
        editorDidMount={(editor) => {
          editor.refresh();
        }}
      />
    }
  </>


}