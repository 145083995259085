import { AxiosPromise } from 'axios';
import { dashApi } from "../api";
import { Newsletter, NewsletterCampaignList, NewsletterTagList, NlAnalytics, NlTemplateset, NlTemplatesetWithTemplates } from '../pages/Engage/subpages/Newsletters/domain';

export const getNewsletters = (magazine: number): AxiosPromise<Newsletter[]> => {
  return dashApi.get(magazine, `newsletters`);
}

export const getNewsletter = (magazine: number, newsletterId: number): AxiosPromise<Newsletter> => {
  return dashApi.get(magazine, `newsletters/${newsletterId}`);
}

export const getNewsletterTemplatesets = (magazine: number): AxiosPromise<NlTemplateset[]> => {
  return dashApi.get(magazine, `newsletterTemplatesets`);
}

export const getNewsletterTemplateset = (magazine: number, templatesetId: number): AxiosPromise<NlTemplatesetWithTemplates> => {
  return dashApi.get(magazine, `newsletterTemplatesets/${templatesetId}`);
}

export const saveNewsletterTemplateset = (magazine: number, templateset: NlTemplatesetWithTemplates): AxiosPromise<void> => {
  return dashApi.post(magazine, `newsletterTemplatesets`, { templateset });
}

export const deleteNewsletterTemplateset = (magazine: number, templatesetId: number): AxiosPromise<void> => {
  return dashApi.delete(magazine, `newsletterTemplatesets/${templatesetId}`);
}

export const saveNewsletter = (magazine: number, newsletter: Newsletter): AxiosPromise<Newsletter> => {
  return dashApi.post(magazine, `newsletters`, { newsletter });
}

export const getNewsletterTagsForMagazine = (magazine: number): AxiosPromise<NewsletterTagList> => {
  return dashApi.get(magazine, `newsletterTags`);
}

export const getNewsletterCampaignsForMagazine = (magazine: number): AxiosPromise<NewsletterCampaignList> => {
  return dashApi.get(magazine, `newsletterCampaigns`);
}

export const getNewsletterAnalytics = (magazine: number, newsletterId: number): AxiosPromise<NlAnalytics> => {
  return dashApi.get(magazine, `newsletters/${newsletterId}/analytics`);
}

export const deleteNewsletter = (magazine: number, newsletterId: number): AxiosPromise<void> => {
  return dashApi.delete(magazine, `newsletters/${newsletterId}`);
}