import { NlTemplateConfiguration_Edition } from "../domain";

const parse = (configuration: NlTemplateConfiguration_Edition): string => {
  return `
    <!DOCTYPE html>
    <!--[if lt IE 7]>      <html lang="en" class="no-js lt-ie9 lt-ie8 lt-ie7"> <![endif]-->
    <!--[if IE 7]>         <html lang="en" class="no-js lt-ie9 lt-ie8"> <![endif]-->
    <!--[if IE 8]>         <html lang="en" class="no-js lt-ie9"> <![endif]-->
    <!--[if gt IE 8]><!--> <html lang="en" class="no-js"> <!--<![endif]-->
    <head>
      <meta charset="utf-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
      <title>Nieuwsbrief</title>
      <meta name="description" content="">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.18.1/moment.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.12/jquery.bxslider.js"></script>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.12/jquery.bxslider.min.css" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css" />
      <!-- Latest compiled and minified CSS -->
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous">
      <link rel="shortcut icon" href="https://storage-imgzine-dev.imgzine.com/public/86/images/ortec_favicon.png" type="image/x-icon" security="none"/>
      <!-- Latest compiled and minified JavaScript -->
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js" integrity="sha384-Tc5IQib027qvyjSMfHjOMaLkfuWVxZxUPnCJA7l2mCWNIpG9mGCD8wGNIcPD7Txa" crossorigin="anonymous"></script>
      <style>
        @import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);

        .nl-heading{ width: 100%; height: 80px; line-height: 80px;background-color: white;}
        .nl-body{ background-color: #eff3f6; height: 100%;}
        .article-menu>li>a{ padding: 15px 20px; border: 1px solid #eff3f6; background: white;}
        .menu-box{ margin-top:50px;}
        .article-menu>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover { color: #fff !important; background-color: ${configuration.primaryColor} !important;}
        article>img{width: 100%;}
        article{background: white; margin: 0px 30px 15px 15px; padding: 10px 0px; max-width: 870px;}
        .article-menu{ margin: 0px 15px 0px 30px;}
        article>div{margin:15px;}
        .menu-title{padding: 15px 20px; border: 1px solid #eff3f6; background: white; color: #606876; text-decoration: none;}
        .hamburger-button{margin-top: 20px;}
        .header-nav{margin-bottom: 0px;}
        .page-title{margin: 0px 30px;}
        .page-title img{width: auto;}
        .articles-number{ float: right; font-size: 80%;}
        article img,article iframe{max-width: 100%;}
        body{font-family: Roboto, sans-serif;}
        .article-menu>li+li{margin-top: 0px;}
        .previous-editions-link{ color:#6b8893; float: right; margin-right: 45px; font-family: Roboto;}
        .previous-editions-link:hover{ color:#6b8893; text-decoration: none !important;}
        .article-title{ color: ${configuration.primaryColor}; margin-top: 30px; margin-bottom:0px;}
        .Iz_image.featured{ margin: -10px 0px 0px 0px; }
        .Iz_image.featured img {width:100%;}
        .Iz_image{max-width: 100%}
        .time-div{margin-bottom:20px;}
        p{margin: 0px 0 40px; font-weight: lighter; line-height: 32px; font-size: 16px; }
        li{margin: 0px 0 0px; font-weight: lighter; font-size: 16px; line-height: 32px;}
        li nav{margin: 0px 0 0px; font-weight: lighter; font-size: 16px; }
        time{color: #c5cdd6; font-size: small;}
        .article-menu li a{color:#606876;}
        .nav-stacked.article-menu{ width:400px; position: fixed; top: 130px;}
        .entry-content{font-family: Roboto;}
        .bxslider li div img{margin-left:auto; margin-right:auto;}
        .article-menu{font-family: Roboto;}
        .bx-wrapper .bx-caption span{font-family: Roboto;}
        .articles-container a{text-decoration: underline;}
        .article-div:not(:first-of-type){ padding-top:20px;}
        .entry-content div.Iz_image.center{ text-align: center; width: 100%;}
        .entry-content div.Iz_image.left{ float: left; clear: left; padding-right: 10px;}
        .entry-content div.Iz_image.right{ float: right; clear: right; padding-left: 10px;}
        .overlay{display:none; position: fixed; width: 100%; height: 100%; background-color: rgba(0,0,0,0.7); z-index: 1;}
        .back-to-top{ width:100%; text-align: right; font-size: 12px; cursor:pointer;}
        .back-to-top-glyphicon{ padding-left: 5px}


        @media only screen and (max-width: 767px) {
          .full-size-article-menu{display: none;} .mobile-article-menu{display: block; overflow: scroll;} .previous-editions-link{display: none;}    article{background: white; margin: 0px 6px 6px 6px;}   .article-menu{ margin: 0px 6px 0px 6px;} .menu-box{ margin-top:6px; padding-left: 0px; padding-right: 0px; }
          .navbar-collapse{ padding-left: 0px; padding-right: 0px;}
          .nl-heading{ position: fixed;top: 0px;border-bottom: 1px solid #e2e2e2;z-index: 999; }
          .navbar-collapse{ position: fixed;z-index: 9999;width: 100%;top: 80px;}
          .nav>li>a:hover {text-decoration: none; background-color: #eee !important;}
        }
        @media only screen and (min-width: 768px) {.full-size-article-menu{display: inline-block; width: 445px;} .full-size-article-menu{ width: 400px;} .mobile-article-menu{display: none;} .previous-editions-link{display: block;}  .articles-container{ width: calc( 100% - 445px); display: inline-block; float:right;} .articles{padding-left: 0px; padding-right: 0px;}}
        @media only screen and (min-width: 768px) and (max-width: 992px) { .articles-container{ width: calc( 100% - 300px);} .full-size-article-menu{ width: 300px;} .nav-stacked.article-menu{width: 260px;}}
      </style>
      <script>
        var additionalSpace = 130 //130 is the navbar height plus the empty space.
        var navbarHeight = 80 //in px.
        var currentActive;
        var smallestDistance  = -100000000;
        var currentDivId;
        var clickedMenu = false;

      function activateFirstMenuLi(){
          $('.article-menu-li').parent().removeClass('active');
          var parentClass = $('.article-menu-li').first().parent().attr('class').split(' ');
          $('.'+parentClass[0]).addClass('active');
        }

        function changeMenu(marginTop){
          var newHeight;
          var ulHeight = $('.full-size-article-menu').height();
          var windowHeight = $(window).height();
          if(ulHeight > 0 && ulHeight < windowHeight - marginTop ){
            newHeight = ulHeight  + 'px';
            $('.web-article-menu').css('overflow','hidden');
          }else{
            newHeight = $(window).height() - marginTop +'px';
            $('.web-article-menu').css('overflow','scroll');
          }
          $('.web-article-menu').css('height',newHeight);
        }




        $(function() {
          $( window ).resize(function() {
            if(window.innerWidth > 768  && ($('.full-size-article-menu').height()) == 0){
              if( $(window).scrollTop() > additionalSpace  ){
                $('.nav-stacked.article-menu').css('top',0);
                changeMenu(0);
              }else{
                $('.nav-stacked.article-menu').css('top',additionalSpace - $(window).scrollTop());
                changeMenu(additionalSpace);
              }
              $('.full-size-article-menu').height($('.web-article-menu').height())
            }
          });

          $('.articles').children().find('a').attr('target', function() {
            return '_blank'
          });

          $('a[href^="#"]').on('click',function (e) {
              e.preventDefault();
              clickedMenu = true;
              var target = this.hash;
              var $target = $(target);
              var offsetToUse = (window.innerWidth < 768) ? $target.offset().top-navbarHeight: $target.offset().top

              $('html, body').stop().animate({
                  'scrollTop': offsetToUse
              }, 900, 'swing', function () {
                  clickedMenu = false;
              });
          });

        $('.full-size-article-menu').height($('.web-article-menu').height())

        $(window).scroll(function(){
              if( $(window).scrollTop() > additionalSpace  ){
                $('.nav-stacked.article-menu').css('top',0);
                changeMenu(0);
              }else{
                $('.nav-stacked.article-menu').css('top',additionalSpace - $(window).scrollTop());
                changeMenu(additionalSpace);
              }

              if(clickedMenu == false){
                $('.articles-container').find('.articles').children().each(function(){
                    var scrollTop     = $(window).scrollTop() +5
                    var elementOffset = $('#'+this.id).offset().top
                    if($(window).width() < 768){scrollTop = scrollTop + navbarHeight;}  //in 'mobile' menu versions
                    var distance      = (elementOffset - scrollTop);
                    if(distance < 0 ){  currentDivId = this.id }
                    if(currentActive != currentDivId){
                      currentActive = currentDivId;
                      $('.article-menu').find('.active').removeClass('active');
                      $('.article-menu').find('.'+currentDivId).addClass('active');
                    }
                })
              }

              // if(window.innerWidth < 768){
              //   $('.navbar-collapse').collapse('hide');
              //   $('.overlay').hide();
              // }

          });

          $('.bxslider').bxSlider({
            captions: true,
          });
          var bareSlider = $('.bxsliderBare').bxSlider({
            pager: false,
            controls: true,
            //auto: true,
            autoStart: true
          });
          if( bareSlider.length) bareSlider.startAuto();

          $('time').each(function(i, e) {
            var time = moment($(e).text()).format('LL');
            $(e).text(time);
          });

          activateFirstMenuLi();

          $('.article-menu-li').click(function(){
            var activeArticle = $(this).parent().attr('class').split(' ');
            $('.article-menu-li').parent().removeClass('active');
            $('.'+activeArticle[0]).addClass('active');
            if(window.innerWidth < 768 ){
              $('.navbar-collapse').collapse('hide');
              $('.overlay').hide();
            }
          });

          $('.overlay').click(function( event ) {
            $('.navbar-collapse').collapse('hide');
            $('.overlay').hide();
          });

          $('.hamburger-button').click(function(){
            if(window.innerWidth < 768 && $( ".mobile-article-menu" ).is( ":hidden" )){
              $('.overlay').show();
            }else if(window.innerWidth < 768 && $( ".mobile-article-menu" ).is( ":visible" )){
              $('.overlay').hide();
            }
          })

          $('.navbar-collapse').on('shown.bs.collapse', function() {
            if($('.mobile-article-menu').height() > $(window).height() - navbarHeight){
              $('.mobile-article-menu').css('height',$(window).height()-navbarHeight+'px');
            }
          });

          $('.back-to-top').click(function(){
              document.body.scrollTop = 0; // For Chrome, Safari and Opera
              document.documentElement.scrollTop = 0; // For IE and Firefox
              activateFirstMenuLi();
          });
        });
      </script>
    </head>
    <body>
          <nav class="navbar navbar-default header-nav">
            <div class="navbar-header nl-heading">
                <span class="page-title"><img style="height:70px" src="${configuration.logo ? configuration.logo : 'https://static.ortec-relevance.com/newsletter/ortec_relevance_nospace.png'}" alt="Edition logo" /> </span>
                <button type="button" class="navbar-toggle collapsed hamburger-button" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="previous-editions-link" href="{{linkedEditionsUrl}}"></a>
            </div>

            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul class="nav navbar-nav mobile-article-menu article-menu">
                  <li role="presentation" class = "menu-title">In this publication <span class='articles-number'>{{ articles | length }} articles</span></li>
                  {% for article in articles %}
                    <li role="presentation" class="{{article.id}}"><a href="#{{article.id}}" class="article-menu-li">{{article.title}}</a></li>
                  {% endfor %}
                </ul>
            </div>
        </nav>
        <div class='overlay'></div>
        <div class="nl-body" style="margin:auto;">
          <div class="container-fluid ">
            <div class="row">
              <div class="menu-box full-size-article-menu">
                      <ul class="nav nav-pills nav-stacked article-menu web-article-menu">
                        <li role="presentation" class = "menu-title">In this publication <span class='articles-number'>{{ articles | length }} articles</span></li>
                        {% for article in articles %}
                          <li role="presentation" class="{{article.id}}"><a href="#{{article.id}}" class="article-menu-li">{{article.title}}</a></li>
                        {% endfor %}
                      </ul>
              </div>
              <div class='articles-container'>
                <div class="col-sm-12 menu-box articles">

                  {% for article in articles %}
                    <div id="{{article.id}}" class='article-div'>
                      <article class="">
                      {{article.featuredImageHtml}}
                      <div class="entry-content">
                          <div class="bottom-box">
                          <h1 class='article-title'>{{article.title}}</h1>
                          <div class='time-div'><time datetime="2015-04-02">{{article.cdate}}</time></div>
                            {{article.content | safe}}
                          </div>
                          <div class='back-to-top'>Back to Top<span class="back-to-top-glyphicon glyphicon glyphicon-menu-up" aria-hidden="true"></span></div>
                        </div>
                      </article>
                    </div>
                  {% endfor %}

                </div>
              </div>
            </div>
          </div>
        </div>
    </body>
    </html>

  `;
}

export const edition = {
  parse
}