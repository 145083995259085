import React, { Component } from 'react';
import { Input, Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import { StatsFilter, ArticleStats, Article } from '../../../domain';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as _ from 'lodash';
import { I18n, Translate } from '../../translations';
import { LanguageContextConsumer } from '../../LanguageContext';
import { DateHelper } from '../../utils';
//
// ─── ENABLE TRANSLATIONS FIXED CODE ─────────────────────────────────────────────
//
let Language = 'en';
const enableTranslations = () => {
  return (
    <LanguageContextConsumer>
      {languageContext => { Language = languageContext ? languageContext.language : 'en'; return '' }}
    </LanguageContextConsumer>
  )
}
// ────────────────────────────────────────────────────────────────────────────────


export interface Props {
  article?: Article,
  deleteSelectedCommentsHanlder: (selectedComments: string[]) => void
  readOnly?: boolean
}

export interface State {
  selectedComments: string[]
}

export class ArticleComments extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { selectedComments: [] };
  }

  toDate(date: string): string {
    return DateHelper.dateTimeToLocaleString(date);
  }

  formatter(cell: any, row: any) {
    return (
      <div>
        <div className="text">{cell}</div>
        <div className="info"><span className="author">{row.author_name}</span> - <span className="date">{this.toDate(row.cdate)}</span></div>
      </div>
    )
  }
  onRowSelect(row: any, isSelected: boolean, e: any) {
    const selectedComments = _.cloneDeep(this.state.selectedComments)
    if (isSelected) {
      selectedComments.push(row.id);
    } else {
      _.remove(selectedComments, (e) => {
        return e == row.id;
      })
    }
    this.setState({ selectedComments: selectedComments })
    return true;
  }
  deleteSelectedComments() {
    // console.log(this.state.selectedComments);
    const totalComments = this.state.selectedComments.length;
    const message = totalComments + (totalComments == 1 ? ' comment is' : ' comments are') + ' selected.' + ' Are you sure you want to delete ' + (totalComments == 1 ? 'it' : 'them') + '?';
    if (window.confirm(message)) {
      this.props.deleteSelectedCommentsHanlder(this.state.selectedComments);
      this.setState({ selectedComments: [] });
    }

  }

  render() {
    const { selectedComments } = this.state;
    const { article } = this.props;
    const data = article ? _.orderBy(_.filter(article.comments, (c) => c.status === 1), ['cdate'], ['desc']) : undefined;
    const tr = Translate(Language, 'ArticleDetails');
    return (
      <div id="ArticleComments">
        {enableTranslations()}
        {!selectedComments.length ? '' :
          <Button className="deleteCommentsButton" onClick={this.deleteSelectedComments.bind(this)} disabled={(article && article.comments && article.comments.length > 0) ? false : true}><I18n t={'delete_selected_comments'} n="ArticleDetails" /></Button>
        }
        <div className='header'>
          {/* <I18n t={'comments'} n="ArticleDetails"/>  */}
          {/* <i className='fa fa-info-circle' data-tippy-content={tr('comments_section_info')}></i> */}
        </div>
        <div className='content'>
          {!data ?
            <div></div>
            :
            <BootstrapTable ref="articleCommentsTable" data={data} bordered={false} hover={false} version='4' selectRow={this.props.readOnly ? undefined : { mode: 'checkbox', onSelect: this.onRowSelect.bind(this) }} options={{ noDataText: 'There are no comments on this article' }}>
              <TableHeaderColumn dataField='id' hidden isKey><img src="/assets/icons/16/users.svg" /></TableHeaderColumn>
              <TableHeaderColumn dataField='comment' dataAlign={'center'} headerAlign={'center'} dataFormat={this.formatter.bind(this)}></TableHeaderColumn>
            </BootstrapTable>
          }
        </div>
      </div>
    );
  }
}

export default ArticleComments;
