import React, { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { CmsItemFormData } from '../CmsItemDetails';
import { Badge, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { TranslateFunction } from '../../../../../../../domain';
import { Translations } from '../../../../../../translations';
import styles from './CmsAgendaItemMeta.module.scss';
import _ from 'lodash';
import Creatable from 'react-select/creatable';
import classNames from 'classnames';
import { ArticleHelper, DateHelper } from '../../../../../../utils';
import "react-datepicker/dist/react-datepicker.css";
import Datepicker from 'react-datepicker';
import moment from 'moment';
import Toggle from 'react-toggle';

interface Props {
  magazine: number,
  control: Control<CmsItemFormData>
}

export const CmsAgendaItemMeta = ({ magazine, control, }: Props) => {


  const { field: start } = useController({ name: 'meta.start', control, rules: { required: true } });
  const { field: end } = useController({ name: 'meta.end', control, rules: { required: true } });
  const { field: speaker } = useController({ name: 'meta.speaker', control });
  const { field: location } = useController({ name: 'meta.location', control });
  const { field: timezone } = useController({ name: 'meta.timezone', control });
  const { field: all_day } = useController({ name: 'meta.all_day', control });
  const { field: userLocalTime } = useController({ name: 'meta.userLocalTime', control });

  const tr: TranslateFunction = Translations.Translate(Translations, 'Agenda');

  return (
    <div className={styles.CmsAgendaItemMeta}>
      <Row>
        <Col md={6}>
          <div className={styles.startDateWrapper}>
            <div style={{ flex: 1 }}>
              <Label>{tr('start')}:</Label>
              <div className={styles.datepickerWrapper} >
                <Datepicker
                  dateFormat={`${DateHelper.dailyDatePickerFormatBasedOnLocale()}${all_day.value ? '' : ' - HH:mm'}`}
                  timeFormat="HH:mm"
                  timeInputLabel="Time:"
                  selected={start.value ? new Date(moment(start.value).toISOString()) : null}
                  isClearable={true}
                  placeholderText={tr(all_day.value ? 'selectOnlyDate' : 'selectDateAndTime')}
                  customInput={<Input type={'text'} invalid={start.value ? false : true} disabled />}
                  calendarClassName={start.value ? '' : 'hideTimeInput'}
                  onChangeRaw={(e) => {
                    if (e) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                    return false;

                  }}
                  onChange={(d: Date, e: any) => {
                    let dateStr = '';
                    if (d) {
                      try {
                        const m = moment(d);
                        if (m.isValid()) {
                          if (!start.value) {
                            m.hours(8);
                          }
                          dateStr = m.format(all_day.value ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss');
                          start.onChange(dateStr);
                        }
                      } catch (error) {
                        start.onChange('');
                      }
                    } else {
                      start.onChange('');
                    }
                    if (e) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                    return false;
                  }}
                  showTimeInput={all_day.value ? false : true}
                  showMonthDropdown
                  showYearDropdown
                  shouldCloseOnSelect={start.value ? false : true}
                  dropdownMode="select"
                />
              </div>
            </div>
            <div>
              <Label>{tr('allDay')}:</Label>
              <div className={styles.allDayWrapper}>
                <Toggle checked={all_day.value ? true : false} onChange={(e) => all_day.onChange(e.target.checked)} />
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <Label>{tr('end')}:</Label>
          <div className={styles.datepickerWrapper}>
            <Datepicker
              dateFormat={`${DateHelper.dailyDatePickerFormatBasedOnLocale()}${all_day.value ? '' : ' - HH:mm'}`}
              timeFormat="HH:mm"
              timeInputLabel="Time:"
              selected={end.value ? new Date(moment(end.value).toISOString()) : null}
              isClearable={true}
              placeholderText={tr(all_day.value ? 'selectOnlyDate' : 'selectDateAndTime')}
              customInput={<Input type={'text'} invalid={end.value ? false : true} disabled />}
              calendarClassName={end.value ? '' : 'hideTimeInput'}
              onChangeRaw={(e) => {
                if (e) {
                  e.preventDefault();
                  e.stopPropagation();
                }
                return false;

              }}
              onChange={(d: Date, e: any) => {
                let dateStr = '';
                if (d) {
                  try {
                    const m = moment(d);
                    if (m.isValid()) {
                      if (!end.value) {
                        m.hours(8);
                      }
                      dateStr = m.format(all_day.value ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss');
                      end.onChange(dateStr);
                    }
                  } catch (error) {
                    end.onChange('');
                  }
                } else {
                  end.onChange('');
                }
                if (e) {
                  e.preventDefault();
                  e.stopPropagation();
                }
                return false;
              }}
              showTimeInput={all_day.value ? false : true}
              showMonthDropdown
              showYearDropdown
              shouldCloseOnSelect={end.value ? false : true}
              dropdownMode="select"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Label>{tr('location')}:</Label>
          <Input value={location.value} onChange={(e) => location.onChange(e.target.value)} />
        </Col>
        <Col md={4}>
          <Label>{tr('timezone')}:</Label>
          <Input type={'select'} disabled={all_day.value} value={timezone.value} onChange={(e) => timezone.onChange(e.target.value)}>
            {_.map(ArticleHelper.timeZoneOffsets, (t) => {
              return <option key={t} value={t}>{t}</option>
            })}
          </Input>
        </Col>
        <Col md={4}>
          <Label>{tr('displayTime')}:</Label>
          <div className={classNames(styles.displayTimeWrapper)}>
            <label ><input disabled={all_day.value} checked={!userLocalTime.value ? true : false} type={'radio'} name={'displayTime'} onChange={(e) => { userLocalTime.onChange(!e.target.checked) }} /> {tr('Location')}</label>
            <label ><input disabled={all_day.value} checked={userLocalTime.value ? true : false} type={'radio'} name={'displayTime'} onChange={(e) => { userLocalTime.onChange(e.target.checked) }} /> {tr('User')}</label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Label>{tr('speakers')}:</Label>
          <Input value={speaker.value} onChange={(e) => speaker.onChange(e.target.value)} />
        </Col>
      </Row>
    </div>
  )
}
