import { NcAlertSlideConfig, NcSettingsFontsMedia, NcSettingsGalleryMedia } from "../../NarrowcastingDomain";
import { NcSettingsFormData } from "./NcSettingsConfiguration/NcSettingsConfiguration"

export const NC_DEFAULT_FONTS: string[] = [
  'Roboto-Regular',
  'Roboto-Thin',
  'Roboto-Light',
  'Roboto-Medium',
  'Roboto-Bold',
]

export const NC_DEFAULT_SETTINGS_CONFIGURATION: NcSettingsFormData = {
  general: {
    companyLogo: {
      logoUri: '',
      logoPosition: 0
    },
    fontNames: {
      titleFontName: 'Roboto-Regular',
      bodyFontName: 'Roboto-Regular',
      metaFontName: 'Roboto-Regular',
    },
    backgroundImage: '',
    backgroundColor: '#ffffff',
    slideHeadingFont: {
      color: '#000000',
      fontName: 'Roboto-Regular',
      size: 14
    },
    defaultDuration: 0,
    cornerRadius: 0,
    kenBurnsMaxScale: 0,
    maxScaleForImages: null,
    disableShadows: false,
    disable3dAnimations: false,
    reduceBlurEffects: false,
    locale: 'en',
    mobileApp: {
      mobileAppShowIcon: true,
      mobileAppIconUri: '',
      mobileAppName: ''
    },
  },
  colors: {
    themeColor: '#009bc2',
    alternativeThemeColor: '#ee7d02',
    boxBackgroundColor: '#009bc2',
    alternativeBoxBackgroundColor: '#ee7d02',
    titleTextColor: '#009bc2',
    alternativeTitleTextColor: '#ee7d02',
    bodyTextColor: '#009bc2',
    alternativeBodyTextColor: '#ee7d02',
    metaTextColor: '#009bc2',
    alternativeMetaTextColor: '#ee7d02',
    hashtagTextColor: '#009bc2',
    alternativeHashtagTextColor: '#ee7d02',
    mentionTextColor: '#009bc2',
    alternativeMentionTextColor: '#ee7d02',
    linkTextColor: '#009bc2',
    alternativeLinkTextColor: '#ee7d02',
    barColor: '#009bc2',
    alternativeBarColor: '#ee7d02',
    barBackgroundColor: '#009bc2',
    alternativeBarBackgroundColor: '#ee7d02',
  },
  ticker: {
    channelId: '',
    articleCount: 0,
    defaultEnabled: false,
    showDate: false,
    backgroundColor: '#ffffff',
    separatorIconUri: '',
    textFont: {
      color: '#000000',
      fontName: 'Roboto-Regular',
      size: 14
    },
    metaFont: {
      color: '#000000',
      fontName: 'Roboto-Regular',
      size: 14
    },
    showCurrentTime: false,
    currentTimeFont: {
      color: '#000000',
      fontName: 'Roboto-Regular',
      size: 14
    },
    currentTimeBackgroundColor: '#ffffff',
    articleMaxAge: 10,
  }
}

export const NC_DEFAULT_SETTINGS_GALLERY: NcSettingsGalleryMedia = [
];

export const NC_DEFAULT_SETTINGS_FONTS: NcSettingsFontsMedia = [
];

export const NC_DEFAULT_SETTINGS_ALERT_SLIDE: NcAlertSlideConfig = {
  title: '',
  message: '',
  theme: 'emergency',
  isEnabled: false,
  targetAll: false,
  targetGroups: []
}