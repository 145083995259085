import React from 'react';
import { NlTemplateConfiguration_Header } from '../domain';
import { ImagePlaceholder } from './components/ImagePlaceholder';
import { TextWithUIPlaceholder } from './components/TextWithUIPlaceholder';

import styles from './NewsletterTemplatesetPreview_Header.module.scss';

interface Props {
  magazine: number
  configuration: NlTemplateConfiguration_Header
}
export const NewsletterTemplatesetPreview_Header = ({ magazine, configuration }: Props) => {
  return (
    <div className={styles.NewsletterTemplatesetPreview_Header}>
      <ImagePlaceholder
        magazine={magazine}
        configuration={configuration.image}
        text={'header image'}
        imageWidth={640}
      />
      <div className={styles.headerLinks} style={{ backgroundColor: configuration.openInBrowserLink.enabled ? configuration.openInBrowserLink.backgroundColor : configuration.downloadAppLink.backgroundColor }}>
        <TextWithUIPlaceholder
          configuration={configuration.downloadAppLink}
        />
        {configuration.openInBrowserLink.enabled && configuration.downloadAppLink.enabled &&
          <span>|</span>
        }
        <TextWithUIPlaceholder
          configuration={configuration.openInBrowserLink}
        />
      </div>
      <TextWithUIPlaceholder
        longText
        configuration={configuration.introductionText}
        defaultText={'This is the Introduction text. You can change its style on the right hand side panel and save a prefilled text to have as default for the template.'} />
    </div>
  )
}
