import React from 'react';
import { ControllerRenderProps, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import { Input, Label } from 'reactstrap';
import { NcSlideConfigFormData, NcSlideshowFormData } from '../subpages/NcSlideshows/NcSlideshowDetails';
import { NarrowcastingHelper } from '../NarrowcastingHelper';

interface Props {
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  fieldName: FieldPath<NcSlideConfigFormData>
  activeIndex: number
  label?: string
  placeholder?: string
  maxLength?: number
  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSlideConfigTextInput = ({ field, fieldName, activeIndex, label, placeholder, maxLength }: Props) => {

  const val = NarrowcastingHelper.getValueOfSlideConfigField(field, activeIndex, fieldName) || '';

  return (
    <>
      <Label>{label}</Label>
      <Input
        placeholder={placeholder}
        maxLength={maxLength}
        value={val}
        onChange={(e) => {
          NarrowcastingHelper.setValueOfSlideConfigField(field, activeIndex, fieldName, e.target.value)
        }}
      />
    </>
  )
}
