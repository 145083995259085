import React from 'react';
import { Profile } from '../../../../../domain';
import { ColorPicker } from '../../../../components';
import { ImageComponent } from './components/ImageComponent';
import { NlTemplateConfiguration_Edition } from './domain';
import styles from './NewsletterTemplatesetDetails.module.scss';

interface Props {
  magazine: number,
  profile: Profile,
  configuration: NlTemplateConfiguration_Edition,
  onChange: (newConfiguration: NlTemplateConfiguration_Edition) => void
}

export const NewsletterTemplateEdition = ({ magazine, profile, configuration, onChange }: Props) => {

  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <div className={styles.sectionTitle}>edition settings</div>
        <div>(optional)</div>
      </div>
      <div className={styles.sectionContent}>
        <div className={styles.flexRow} style={{ marginBottom: '15px' }}>
          <div style={{ flex: 1 }}>
            <div className={styles.label}>Primary colour:</div>
            <ColorPicker color={configuration.primaryColor || '#000000'} onChange={(c) => { onChange({ ...configuration, primaryColor: c.toString() }) }} />
          </div>
          {/* <div style={{ flex: 1 }}>
            <div className={styles.label}>Secondary colour:</div>
            <ColorPicker color={configuration.secondaryColor || '#000000'} onChange={(c) => { onChange({ ...configuration, secondaryColor: c.toString() }) }} />
          </div> */}
        </div>
        <ImageComponent
          {...{ magazine, profile }}
          name={`Logo`}
          configuration={{ enabled: true, src: configuration.logo }}
          onChange={(c) => onChange({ ...configuration, logo: c.src })}
          hideEnableToggle
        />
      </div>
    </div>
  )
}
