import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react'
import { Badge, Button, Input, Label } from 'reactstrap';
import { API } from '../../../../apis';
import { CategoriesHelper } from '../../../../utils';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import styles from './CmsCategoriesSection.module.scss';
import { CreateCategoryModal } from './CreateCategoryModal';
import { CmsCategory, CmsCategoryObject } from './domain';

export interface Props {
  magazine: number,
  activeFeed: number,
  activeFeedLabel: string,
  articleCategories: string[],
  onCategoryChange: (checked: boolean, category: string) => void

}

export const CmsCategoriesSection = ({ magazine, activeFeed, activeFeedLabel, articleCategories, onCategoryChange }: Props) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<CmsCategory[]>([]);
  const [collapsedCategories, setCollapsedCategories] = useState<number[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [creatingCategory, setCreatingCategory] = useState(false);
  const [legacyCategories, setLegacyCategories] = useState<string[]>([]);

  useEffect(() => {
    initLoad();
  }, []);

  const initLoad = async () => {
    await loadCategories();
  }

  const loadCategories = async () => {
    try {
      setLoading(true);
      const { data } = await API.cms.getCmsCategories(magazine, activeFeed);
      const cats = CategoriesHelper.excludeDefaultFeedCategoryFromCategories(data, activeFeedLabel);
      setCategories(cats);
      setLegacyCategories(CategoriesHelper.extractLegacyCategories(articleCategories, cats));
    } catch (error) {
      EdErrorHandler(error, `getting categories`);
    } finally {
      setLoading(false);
    }
  }

  const createCategory = async (name: string, parent?: number) => {
    try {
      // setCreateLoading(true);
      const { data } = await API.cms.createCmsCategory(magazine, activeFeed, name, parent);
    } catch (error) {
      EdErrorHandler(error, `creating a category`);
    } finally {
      // setCreateLoading(false);
    }
  }

  const openCreateNewCategory = () => {
    setCreatingCategory(true);
  }

  const closeCreateNewCategory = () => {
    setCreatingCategory(false);
  }

  const onCreateNewCategory = async (name: string, parentCategory?: CmsCategory) => {
    setLoading(true);
    closeCreateNewCategory();
    await createCategory(name);
    await loadCategories();
    onCategoryChange(true, name);
  }

  const onLegacyCategoryDelete = async (legacyCategory: string) => {
    setLegacyCategories(_.filter(legacyCategories, (c) => c !== legacyCategory));
    onCategoryChange(false, legacyCategory);
  }

  const isCategorySelected = (categoryName: string): boolean => {
    return _.includes(articleCategories, categoryName);
  }

  const onCollapse = (id: number) => {
    setCollapsedCategories([...collapsedCategories, id]);
  }
  const onExpand = (id: number) => {
    setCollapsedCategories(_.filter(collapsedCategories, (c) => c !== id));
  }

  const onSearchChange = (val: string) => {
    setSearchText(val);
  }

  const isPartOfSearch = (category: CmsCategoryObject, children?: CmsCategoryObject[]): boolean => {
    if (!searchText) {
      return true;
    }
    if (_.includes(_.toLower(category.name), _.toLower(searchText))) {
      return true;
    }
    if (!children) {
      return false;
    }
    const searchChildren = _.filter(children, (c) => _.includes(_.toLower(c.name), _.toLower(searchText)));
    return !_.isEmpty(searchChildren);
  }

  const renderCategory = (cat: CmsCategory) => {
    const hasChildren = !_.isEmpty(cat.children);
    const isCollapsed = _.includes(collapsedCategories, cat.category.id);
    return (
      <div key={cat.category.id} className={classNames([{ [styles.expandedCategory]: (hasChildren && !isCollapsed) }, { 'hidden': !isPartOfSearch(cat.category, cat.children) }])}>
        <div className={styles.categoryItem}>
          <div className={styles.expandColumn} onClick={() => { isCollapsed ? onExpand(cat.category.id) : onCollapse(cat.category.id) }}><i className={`fa fa-${isCollapsed ? 'plus' : 'minus'} ${hasChildren ? '' : 'invisible'}`}></i></div>
          <div className={''}><Label check><Input type="checkbox" checked={isCategorySelected(cat.category.name)} onChange={(e) => onCategoryChange(e.target.checked, cat.category.name)} /> {cat.category.name}</Label></div>
        </div>
        {hasChildren && !isCollapsed &&
          _.map(cat.children, (ch) => {
            return (
              <div key={ch.id} style={{ paddingLeft: '40px' }} className={classNames(styles.categoryItem, { 'hidden': !isPartOfSearch(ch) })}>
                <div className={''}><Label check><Input type="checkbox" checked={isCategorySelected(ch.name)} onChange={(e) => onCategoryChange(e.target.checked, ch.name)} /> {ch.name}</Label></div>
              </div>
            )
          })
        }
      </div>
    )
  }

  return (
    <div className={styles.CmsCategoriesSection}>
      <div className={styles.sectionTitle}>
        <Label>Categories</Label>
        <Button color={'secondary'} onClick={openCreateNewCategory}>create new category</Button>
      </div>
      <div className={styles.searchContainer}>
        <Input placeholder={'Search'} type="search" value={searchText} onChange={(e) => onSearchChange(e.target.value)} />
      </div>
      <div className={styles.categoryList}>
        {loading ? <OrtecLoader size={"small"} /> :
          _.map(categories, (c) => {
            return renderCategory(c)
          })}
      </div>
      {!loading && creatingCategory && <CreateCategoryModal existingCategories={categories} createHandler={onCreateNewCategory} closeHandler={closeCreateNewCategory} />}
      {!_.isEmpty(legacyCategories) &&
        <Fragment>
          <div className={styles.sectionTitle} style={{ margin: "20px 0 10px 0" }}>
            <Label>Legacy categories</Label>
          </div>
          <div className={styles.tagsContainer}>
            {_.map(legacyCategories, (cat) => {
              return <Badge>{cat} <i className={'fa fa-times iconDelete'} onClick={() => { onLegacyCategoryDelete(cat) }}></i></Badge>
            })}
          </div>
        </Fragment>
      }
    </div>
  )
}
