import React, { Component, Fragment } from 'react';
import { FilterBarWithRouter as FilterBar } from '../../../Overview/FilterBar';
import { StatsFilter, Profile } from '../../../../../domain';
import AllArticlesStats from './AllArticlesStats';
import { ArticleDetails } from '../../../../components';
import * as _ from 'lodash';
import moment from 'moment';
import * as ph from '../../../../utils/routerHelper';

import { withRouter, RouteComponentProps } from 'react-router'

import './css/Analytics_articles.min.css';
import { FilterContextConsumer } from '../../../../FilterContext';
import { Translations } from '../../../../translations';
import { Button } from 'reactstrap';

export interface Props {
  magazine: string,
  profile: Profile,
}

export interface State {
  mounted: boolean,
}

export class Analytics_articles extends Component<RouteComponentProps & Props, State> {
  constructor(props: RouteComponentProps & Props) {
    super(props);
    this.state = {
      mounted: false,
    };
  }
  componentDidMount() {
    this.setState({ mounted: true })
  }
  clickArticleHandler(article: number) {
    ph.setQueryParams(this.props.history, { article });
  }
  closeArticleDetailsHandler() {
    ph.setQueryParams(this.props.history, { article: undefined });
  }
  render() {
    const { magazine, profile } = this.props;
    const shownArticle = ph.getQueryParamNumber(this.props.location, 'article');
    const { mounted } = this.state;
    const tr = Translations.Translate(Translations, 'Modules');
    return (
      <FilterContextConsumer>
        {filterContext => {
          if (!filterContext) {
            return ('')
          }
          const statsFilter: StatsFilter = filterContext.statsFilter;
          return (

            <Fragment>
              {Translations.EnableTranslations(Translations)}
              {!shownArticle ? null :
                <ArticleDetails magazine={magazine} statsFilter={statsFilter} articleId={shownArticle} closeHandler={this.closeArticleDetailsHandler.bind(this)} profile={profile} />
              }
              <div id="Analytics_articles" /*style={{ display: shownArticle ? 'none' : 'block' }}*/ className={shownArticle ? 'limitedHeight' : ''}>
                <FilterBar magazine={magazine} periodFilterHandler={filterContext.periodFilterHandler} audienceFilterHandler={filterContext.audienceFilterHandler} statsFilter={statsFilter} audiences={profile.audiences ? profile.audiences : []} globalAudience={profile.globalAudience} />
                {!mounted ? null :
                  <AllArticlesStats key={JSON.stringify(statsFilter)} magazine={magazine} profile={profile} statsFilter={statsFilter} clickArticleHandler={this.clickArticleHandler.bind(this)} />
                }
              </div>
            </Fragment>
          )
        }}
      </FilterContextConsumer>
    );

  }
}
export const Analytics_articlesWithRouter = withRouter(Analytics_articles);

export default Analytics_articles;
