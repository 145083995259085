import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Profile } from "../../../../../domain";
import { API } from '../../../../apis';
import { TippyReact } from '../../../../components';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { AccessRightsHelper, DataTableHelper, RouterHelper } from '../../../../utils';
import { EdErrorHandler, OrtecLoader, SwalDelete } from '../../../../widgets';
import { BuilderHelper } from './AudienceBuilder/AudienceBuilder';
import { AudienceDetails } from './AudienceDetails';
import styles from './Audiences.module.scss';
import { Audience, AudienceAsTargetInfo } from './domain';
import { NobodyAudienceAlert } from './NobodyAudienceAlert';

interface Props extends RouteComponentProps {
  magazine: number,
  profile: Profile,
  updateProfile: () => void
}

export const Audiences = ({ magazine, profile, updateProfile, location, history }: Props) => {

  const [loadingAudiences, setLoadingAudiences] = useState(true);
  const [audiences, setAudiences] = useState<Audience[]>([]);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const loading = loadingAudiences || deleteLoading;

  const editAudience = RouterHelper.getQueryParamNumber(location, 'audience');

  const doesNobodyAudienceExists = _.find(audiences, (a) => a.type === 'nobody') ? true : false;


  useEffect(() => {
    loadAudiences();
  }, [])

  const loadAudiences = async () => {
    try {
      setLoadingAudiences(true);
      const { data } = await API.audiences.getAudiences(magazine, profile.edConfig?.disableAudienceSizesInOverview ? false : true);
      setAudiences(data);
    } catch (error) {
      EdErrorHandler(error, 'getting audiences');
      setAudiences([])
    } finally {
      setLoadingAudiences(false);
    }
  }

  const onAudienceCreate = () => {
    RouterHelper.setQueryParams(history, { audience: -1 })
  }

  const onAudienceEdit = (audience: Audience) => {
    RouterHelper.setQueryParams(history, { audience: audience.id })
  }
  const onEditModalClose = () => {
    RouterHelper.setQueryParams(history, { audience: undefined });
    loadAudiences();
  }

  const onDelete = async (audience: Audience) => {
    const { data } = await API.audiences.getAudienceAsTargetInfo(magazine, audience.id);
    const { forms, channels, sources } = data as AudienceAsTargetInfo;

    let html = `This action will delete audience <code>${audience.label}</code>.`;

    if (!_.isEmpty(forms) || !_.isEmpty(channels) || !_.isEmpty(sources)) {
      html = `
      <div class="alert alert-warning">
        The audience <code>${audience.label}</code> is used as target in <b>${forms.length} form${forms.length != 1 ? 's' : ''}</b>, <b>${channels.length} channel${channels.length != 1 ? 's' : ''}</b> and <b>${sources.length} source${sources.length != 1 ? 's' : ''}</b>. This targeting will be removed as well.
      </div>
      `
    }

    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      html: html
    });
    if (!confirm) {
      return;
    }
    await deleteAudience(audience.id);
    updateProfile();
    loadAudiences();
  }

  const deleteAudience = async (id: number) => {
    try {
      setDeleteLoading(true);
      const { data } = await API.audiences.deleteAudience(magazine, id);
    } catch (error) {
      EdErrorHandler(error, `deleting an audience`);
    } finally {
      setDeleteLoading(false);
    }
  }

  const onNobodyAudienceCreate = async () => {
    try {
      await API.audiences.createNobodyAudience(magazine);
      loadAudiences();
    } catch (error) {
      EdErrorHandler(error, `creating nobody audience.`)
    } finally {
      setLoadingAudiences(false);
    }
  }

  const isAdmin = (AccessRightsHelper.getUserRole(profile) === 'admin') ? true : false;

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'label',
      text: '',
      searchable: false,
      formatter: (cell, row: Audience) => {
        if (row.type === 'nobody') {
          return (<Fragment><TippyReact content={'Nobody audience'}><span><i className={classNames(styles.labelIcon, 'material-icons')}>person_off</i> </span></TippyReact>{cell}</Fragment>)
        }
        if (!BuilderHelper.isQuerySupported(_.omit(row.query, 'magazine') as Object)) {
          return (<Fragment><TippyReact content={'Query not available for editing'}><span><i className={classNames(styles.labelIcon, 'material-icons')}>warning_amber</i></span></TippyReact>{cell}</Fragment>)
        }
        return cell;
      },
    },
    {
      dataField: 'label',
      text: '',
      hidden: true,
    },
    {
      dataField: 'label',
      text: '',
      hidden: true,
      formatter: (cell, row: Audience) => {
        if (!BuilderHelper.isQuerySupported(_.omit(row.query, 'magazine') as Object)) {
          return `complex unsupported noneditable`
        }
        return '';
      }
    },
    {
      dataField: 'count.totalProfiles',
      text: '',
      sort: true,
      hidden: profile.edConfig?.disableAudienceSizesInOverview ? true : false,
      ...DataTableHelper.numbersColumnConfig('id', 'Profiles')
    },
    {
      dataField: 'count.totalDevices',
      text: '',
      sort: true,
      hidden: profile.edConfig?.disableAudienceSizesInOverview ? true : false,
      ...DataTableHelper.numbersColumnConfig('device', 'Devices')
    },
    {
      dataField: 'excludeFromAnalytics',
      text: '',
      sort: true,
      ...DataTableHelper.booleanReverseColumnConfig('analytics', 'In analytics'),
    },
    {
      dataField: 'cdate',
      text: '',
      sort: true,
      ...DataTableHelper.dateColumnConfig('date', 'Creation Date'),
    },
    {
      dataField: 'mdate',
      text: '',
      sort: true,
      ...DataTableHelper.dateColumnConfig('last-updated', 'Last Updated'),
    },
  ];

  if (isAdmin) {
    columns.push({
      dataField: 'dfActions',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatter: (cell, row: Audience) => {

        if (row.type === 'nobody') {
          return '';
        }
        return (
          <div className={'actionHoveringDiv'}>
            <Button color={'secondary'} onClick={(e) => { onDelete(row); e.preventDefault(); e.stopPropagation(); return false; }}>Delete</Button>
          </div>
        )
      }
    })
  }

  return (
    <Fragment>
      <div className={styles.Audiences}>
        {!loading && !doesNobodyAudienceExists && <NobodyAudienceAlert onButtonClick={onNobodyAudienceCreate} />}
        <Fragment>
          <DataTable
            data={audiences}
            columns={columns}
            keyField={'id'}
            onCreateNew={isAdmin ? onAudienceCreate : undefined}
            onRowClick={onAudienceEdit}
            defaultSorted={[{ dataField: 'cdate', order: 'desc' }]}
          />
          {loading && !editAudience && <OrtecLoader />}
        </Fragment>
      </div>
      {editAudience && <AudienceDetails magazine={magazine} profile={profile} updateProfile={updateProfile} onClose={onEditModalClose} audienceId={editAudience} />}
    </Fragment>

  )
}

export const AudiencesWithRouter = withRouter(Audiences);
