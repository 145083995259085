import React, { CSSProperties, ReactNode } from 'react';
import styles from './FullPageModal.module.scss';
import { OrtecLoader } from '../../widgets';

interface Props {
  children: ReactNode
  isLoading: boolean
  cssStyle?: CSSProperties,
}

export const FullPageModalContent = ({ children, isLoading, cssStyle }: Props) => {
  return (
    <div className={styles.FullPageModalContent} style={{ ...cssStyle }}>
      {isLoading ? <OrtecLoader /> : children}
    </div>
  )
}
