import React, { useState, useEffect } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label, Input } from "reactstrap";
import _ from 'lodash';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ChatGroup } from './domain';
// import { UsersList } from '../../../../../domain';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import { UsersList } from '../../../../../domain';
import { API } from '../../../../apis';

export interface Props {
  magazine: number,
  group: ChatGroup
  onClose: () => void,
}

export const MembersModal = ({ magazine, group, onClose }: Props) => {

  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<UsersList>();

  useEffect(() => {
    getGroupMemebers();
  }, []);

  const getGroupMemebers = async () => {
    try {
      setLoading(true);
      const resp = await API.chatGroups.getGroupMembers(magazine, group.id);
      setMembers(resp.data);
    } catch (error) {
      EdErrorHandler(error, `getting group members`);
    } finally {
      setLoading(false);
    }
  }
  const membersToTableData = (): Array<{ id: string, name: string }> => {
    if (!members || _.isEmpty(members.uids)) {
      return [];
    }

    const anonymous = members.anonymous.length;

    const named = _.compact(_.map(members.named, (u) => {

      return {
        id: u.uid,
        name: `${_.get(u, 'firstName', '')} ${_.get(u, 'lastName', '')}`
      }
    }));

    if (anonymous) {
      return [...named, { id: '__anonymous', name: `${_.isEmpty(named) ? '' : '...and'} ${anonymous} ${_.isEmpty(named) ? '' : ' other'} anonymous user${anonymous > 1 ? 's' : ''}` }];
    }

    return named;

  }

  const renderToolbar = (props: any) => {
    return (
      <Col md={12}>{props.components.searchField}</Col>
    );
  }


  const closeBtn = <img className="close" onClick={onClose} src="/assets/icons/bt-close.svg" />

  const options = {
    toolBar: renderToolbar,
  };
  return (
    <Modal isOpen={true} centered id="DataModal" toggle={() => onClose()}>
      <ModalHeader><div className='headerTitle'><img src="/assets/icons/16/registration.svg" style={{ marginRight: '10px' }}></img> {group.name} members ({group.totalMembers})</div> {closeBtn}</ModalHeader>
      <ModalBody>
        {loading && <OrtecLoader />}
        <div className="tableDiv">
          <BootstrapTable data={membersToTableData()} bordered={false} hover={false} version='4' options={options} search searchPlaceholder={`Search for name`}>
            <TableHeaderColumn dataField='id' hidden isKey></TableHeaderColumn>
            <TableHeaderColumn dataField='name'></TableHeaderColumn>
          </BootstrapTable>
        </div>
      </ModalBody>
    </Modal>
  )
}