import React from 'react'
import TippyReact from '../TippyReact'
import styles from './ArticlePreview.module.scss'

interface Props {
  to: string
  children: React.ReactNode

  //not used
  id?: string
  className?: string
  currentArticle?: any
}

export default function DetailContentLink(props: Props) {
  const tooltip = props.to.startsWith('o4c-mention://')
    ? 'Link to profile'
    : props.to
  return <TippyReact content={<span className={styles.linkTooltip}>{tooltip}</span>}>
    <span className={styles.link} onClick={() => openExternalLink(props.to)}>{props.children}</span>
  </TippyReact>
}

const openExternalLink = (url: string, _target?: string) => {
  window.open(url, _target ?? "_blank");
};