import React from 'react';
import { Profile } from '../../../../../domain';
import { NcSlideshowFormData } from './NcSlideshowDetails';
import { Control, Controller, useController, useForm } from 'react-hook-form';

import styles from './NcSlideshowPlanDetails.module.scss';
import { GenericModal } from '../../../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../../../components/GenericModal/GenericModalHeader';
import { Button, Label, ModalBody, ModalFooter, Row } from 'reactstrap';
import { DailyDatePicker } from '../../../../components';
import { HoursSelect } from '../../../../components/DatePicker';
import { MinutesSelect } from '../../../../components/DatePicker/MinutesSelect';
import { TimezoneSelect } from '../../../../components/DatePicker/TimezoneSelect';
import { NcScheduleDatetime } from '../../NarrowcastingDomain';
import _ from 'lodash';
import moment from 'moment';

interface NcPlanFormData {
  startDate: string,
  startHours: string,
  startMinutes: string,
  startTimezone: string,
  endDate: string,
  endHours: string,
  endMinutes: string,
  endTimezone: string,
}

interface Props {
  slideshowControl: Control<NcSlideshowFormData>,
  onClose: () => void

}

export const NcSlideshowPlanDetails = ({ slideshowControl, onClose }: Props) => {

  const { field: schedule, fieldState } = useController({ control: slideshowControl, name: 'schedule' });

  const { control, handleSubmit, reset, formState: { errors } } = useForm<NcPlanFormData>({
    defaultValues: {
      startDate: schedule.value?.start?.date || '',
      startHours: schedule.value?.start?.time ? schedule.value?.start?.time.split(':')[0] : '',
      startMinutes: schedule.value?.start?.time ? schedule.value?.start?.time.split(':')[1] : '',
      startTimezone: schedule.value?.start?.timezone || '',
      endDate: schedule.value?.end?.date || '',
      endHours: schedule.value?.end?.time ? schedule.value?.end?.time.split(':')[0] : '',
      endMinutes: schedule.value?.end?.time ? schedule.value?.end?.time.split(':')[1] : '',
      endTimezone: schedule.value?.end?.timezone || '',
    }
  });

  const onClear = () => {
    reset({
      startDate: '',
      startHours: '',
      startMinutes: '',
      startTimezone: '',
      endDate: '',
      endHours: '',
      endMinutes: '',
      endTimezone: '',
    });
  }

  const onSave = handleSubmit(async (data) => {

    const { startDate, startHours, startMinutes, startTimezone, endDate, endHours, endMinutes, endTimezone } = data;

    const startTime = `${startHours || '00'}:${startMinutes || '00'}`;

    const start: NcScheduleDatetime | undefined = !startDate ? undefined : {
      date: startDate,
      time: startTime,
      timezone: startTimezone || undefined,
      ts: startTimezone ? moment(`${startDate}T${startTime}${startTimezone}`).valueOf() : undefined //in ms
    }

    const endTime = `${endHours || '23'}:${endMinutes || (endHours ? '00' : '59')}`;

    const end: NcScheduleDatetime | undefined = !endDate ? undefined : {
      date: endDate,
      time: endTime,
      timezone: endTimezone || undefined,
      ts: endTimezone ? moment(`${endDate}T${endTime}${endTimezone}`).valueOf() : undefined //in ms
    }

    schedule.onChange({ start, end })
    onClose();
  });


  return (
    <GenericModal className={styles.NcSlideshowPlanDetails} isOpen={true} size={'lg'}>
      <GenericModalHeader
        title={'Edit Slideshow Plan'}
        onClose={onClose}
      />
      <ModalBody>
        <div className={styles.starEndContainer}>
          <div>
            <div className={styles.formRow}>
              <Label>Start date:</Label>
              <Controller
                name={'startDate'}
                control={control}
                render={({ field }) => {
                  return (
                    <DailyDatePicker
                      period={field.value}
                      changePeriod={field.onChange}
                      noMaxLimit
                      fixedPosition
                    />
                  )
                }}
              />
            </div>
            <div className={styles.formRow}>
              <Label>Time:</Label>
              <div style={{ display: 'flex', gap: 16 }}>
                <div style={{ flex: 1 }}>
                  <Controller
                    name={'startHours'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <HoursSelect
                          value={field.value}
                          onChange={field.onChange}
                          isClearable
                        />
                      )
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Controller
                    name={'startMinutes'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <MinutesSelect
                          value={field.value}
                          onChange={field.onChange}
                          isClearable
                        />
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.formRow}>
              <Label>Timezone:</Label>
              <Controller
                name={'startTimezone'}
                control={control}
                render={({ field }) => {
                  return (
                    <TimezoneSelect
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={`Device location`}
                      isClearable
                    />
                  )
                }}
              />
            </div>
          </div>
          <div>
            <div className={styles.formRow}>
              <Label>End date:</Label>
              <Controller
                name={'endDate'}
                control={control}
                render={({ field }) => {
                  return (
                    <DailyDatePicker
                      period={field.value}
                      changePeriod={field.onChange}
                      noMaxLimit
                      fixedPosition
                    />
                  )
                }}
              />
            </div>
            <div className={styles.formRow}>
              <Label>Time:</Label>
              <div style={{ display: 'flex', gap: 16 }}>
                <div style={{ flex: 1 }}>
                  <Controller
                    name={'endHours'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <HoursSelect
                          value={field.value}
                          onChange={field.onChange}
                          isClearable
                        />
                      )
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Controller
                    name={'endMinutes'}
                    control={control}
                    render={({ field }) => {
                      return (
                        <MinutesSelect
                          value={field.value}
                          onChange={field.onChange}
                          isClearable
                        />
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.formRow}>
              <Label>Timezone:</Label>
              <Controller
                name={'endTimezone'}
                control={control}
                render={({ field }) => {
                  return (
                    <TimezoneSelect
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={`Device location`}
                      isClearable
                    />
                  )
                }}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button outline color="secondary" onClick={onClear}>Clear</Button>
        <div style={{ flex: 1 }}></div>
        <Button outline color="secondary" onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={onSave}>Save</Button>
      </ModalFooter>
    </GenericModal>
  )
}
