import React, { CSSProperties, ReactNode } from 'react';
import styles from './FullPageModal.module.scss';
import classNames from 'classnames';

interface Props {
  children?: ReactNode
  flex?: number,
  cssStyle?: CSSProperties,
  mainPart?: boolean
  withBackground?: boolean
}

export const FullPageModalContentPart = ({ children, flex, cssStyle, mainPart, withBackground }: Props) => {
  return (
    <div
      className={classNames(
        styles.FullPageModalContentPart,
        {
          [styles.mainPart]: mainPart,
          [styles.withBackground]: withBackground
        }
      )}
      style={{ flex: flex || 1, ...cssStyle }}
    >
      {children}
    </div>
  )
}
