import React, { ReactNode } from 'react';
import styles from './FullPageModal.module.scss';
import classNames from 'classnames';
import 'animate.css';

interface Props {
  children: ReactNode
}

export const FullPageModal = ({ children }: Props) => {
  return (
    <div className={classNames(styles.FullPageModal, 'animate__animated', 'animate__fast', 'animate__slideInRight')}>
      {children}
    </div>
  )
}
