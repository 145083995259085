import React, { Component } from 'react';
import { Profile } from '../../../domain';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { ReportScheduler } from './ReportScheduler';
import './css/Report.min.css'
import { Translations } from '../../translations';
import classnames from 'classnames';
import ReportTableScheduled from './ReportTableScheduled';
import moment from 'moment';
import { GenerateReports } from './GenerateReports';
import _ from 'lodash';
import { RouterHelper } from '../../utils';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';


export interface Props extends RouteComponentProps {
  magazine: string,
  profile: Profile
}
export interface State {
  scheduledLastUpdate: number
}

export class Report extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      scheduledLastUpdate: moment().valueOf(),
    };
  }
  toggleTab = (tab: string) => {
    RouterHelper.setQueryParams(this.props.history, { tab });

  }
  refreshScheduledHandler = () => {
    this.setState({ scheduledLastUpdate: moment().valueOf() });
  }
  render() {
    const { magazine, profile, location } = this.props;
    const { scheduledLastUpdate } = this.state;

    const activeTab = RouterHelper.getQueryParam(location, 'tab');

    if (!activeTab) {
      return <Redirect to='report?tab=generate' />
    }

    const tr = Translations.Translate(Translations, 'Report');

    return (
      <div id="Report" className={'TabPageNavigation'}>
        {Translations.EnableTranslations(Translations)}
        <div className="PageTitle">{tr('report')}</div>
        <Nav tabs>
          <NavItem><NavLink className={classnames({ active: activeTab === 'generate' })} onClick={() => { this.toggleTab('generate') }}>{tr('generate')}</NavLink></NavItem>
          <NavItem><NavLink className={classnames({ active: activeTab === 'schedule' })} onClick={() => { this.toggleTab('schedule') }}>{tr('schedule')}</NavLink></NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          {activeTab === 'generate' &&
            <TabPane tabId="generate">
              <GenerateReports magazine={_.toNumber(magazine)} profile={profile} />
            </TabPane>
          }
          {activeTab === 'schedule' &&
            <TabPane tabId="schedule">
              <Row style={{ paddingTop: '20px' }}>
                <Col md={3} xl={3}>
                  <ReportScheduler magazine={magazine} profile={profile} refreshTableHandler={this.refreshScheduledHandler.bind(this)} />
                </Col>
                <Col md={9} xl={9} className="">
                  <ReportTableScheduled key={`scheduled-${scheduledLastUpdate}`} magazine={magazine} profile={profile} />
                </Col>
              </Row>
            </TabPane>
          }
        </TabContent>
      </div>
    );
  }
}
export const ReportWithRouter = withRouter(Report);
export default Report;
