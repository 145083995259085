import classNames from 'classnames';
import React, { Fragment, useState } from 'react';
import Toggle from 'react-toggle';
import { FormFeedback } from 'reactstrap';
import { Profile } from '../../../../../../domain';
import { MediaSelectButton, MediaSelector } from '../../../../../components';
import { NlTemplateImage } from '../domain';
import { verifyImageSize } from '../NewsletterTemplatesetDetails';
import styles from '../NewsletterTemplatesetDetails.module.scss';
import ImageComponentStyles from './ImageComponent.module.scss';
import { MediaHelper } from '../../../../../utils';

interface Props {
  magazine: number,
  profile: Profile
  name: string,
  extraText?: string,
  configuration: NlTemplateImage,
  onChange: (configuration: NlTemplateImage) => void,
  validImageWidth?: number //in pixels
  hideEnableToggle?: boolean
}


export const ImageComponent = ({ magazine, profile, name, extraText, configuration, onChange, validImageWidth, hideEnableToggle }: Props) => {

  const [mediaSelector, setMediaSelector] = useState(false);
  const [isImageSizeInvalid, setIsImageSizeInvalid] = useState(false);

  const isImageMissing = configuration.enabled && !configuration.src && !hideEnableToggle;

  const openMediaSelector = () => {
    setMediaSelector(true);
  }
  const closeMediaSelector = () => {
    setMediaSelector(false);
  }

  const onMediaHandlerSelect = async (mediaUrl: string[]) => {
    onChange({ ...configuration, src: mediaUrl[0] });
    closeMediaSelector();
    setIsImageSizeInvalid(validImageWidth ? await verifyImageSize(mediaUrl[0], validImageWidth) : false);
  }

  const clearSelectedImage = () => {
    onChange({ ...configuration, src: undefined });
    setIsImageSizeInvalid(false);
  }

  return (
    <Fragment>
      <div className={styles.toggleRow}>
        <div className={styles.label}>{name} {extraText ? <span className={ImageComponentStyles.extraText}>{extraText}</span> : null}</div>
        {!hideEnableToggle && <Toggle checked={configuration.enabled} onChange={(e) => { onChange({ ...configuration, enabled: e.target.checked }) }} />}
      </div>
      <MediaSelectButton inputClass={(isImageMissing || isImageSizeInvalid) ? ImageComponentStyles.withError : undefined} disabled={!configuration.enabled} text={`Click here to set an image`} onClick={openMediaSelector} type={'images'} />
      {isImageMissing &&
        <div className={ImageComponentStyles.errorMsg}>You have not selected an image!</div>
      }
      {configuration.enabled && configuration.src &&
        <>
          <div className={classNames(ImageComponentStyles.imagePreview, { [ImageComponentStyles.withError]: isImageSizeInvalid })} style={{ backgroundImage: `url("${MediaHelper.proxify(magazine, configuration.src)}")` }}>
            <span className={'fa fa-times'} onClick={clearSelectedImage}></span>
          </div>
          {isImageSizeInvalid &&
            <div className={ImageComponentStyles.errorMsg}>Image must be {validImageWidth}px wide!</div>
          }
        </>
      }
      {mediaSelector && <MediaSelector magazine={magazine} profile={profile} closeHandler={closeMediaSelector} selectHandler={onMediaHandlerSelect} restrictedMediaTypes={['images']} />}

    </Fragment >
  )
}
