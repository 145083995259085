import React, { useState } from 'react';
import styles from './AudienceEstimatedSizeModal.module.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Label, Input } from "reactstrap";
import _ from 'lodash';
import { Profile } from '../../../domain';
import { Translations } from '../../translations';
import { EdErrorHandler, LoadingButton } from '../../widgets';
import { API } from '../../apis';

interface Props {
  magazine: number,
  audience: number,
  savedEstimatedSize?: number,
  profile: Profile,
  onClose: () => void
  // onSave: (audience: number, estimatedSize: number) => void
}
export const AudienceEstimatedSizeModal = ({ magazine, audience, savedEstimatedSize, profile, onClose }: Props) => {

  const [estimatedSize, setEstimatedSize] = useState(savedEstimatedSize);
  const [saving, setSaving] = useState(false);

  const tr = Translations.Translate(Translations, 'FilterBar');

  const audienceName = () => {
    if (audience == 1) {
      return tr('all_employees');
    }
    const aud = _.find(profile.audiences, (a) => a.id == audience);
    return aud?.label || aud?.id;
  }

  const closeBtn = <img className="close" onClick={onClose} src="/assets/icons/bt-close.svg" />

  const onSave = async () => {
    try {
      setSaving(true);
      await API.audiences.saveAudienceEstimatedSize(magazine, audience, estimatedSize);

    } catch (error) {
      EdErrorHandler(error, `saving audience estimated size`);
    } finally {
      setSaving(false);
      onClose();
    }
  }

  return (
    <Modal isOpen={true} centered className={styles.AudienceEstimatedSizeModal} toggle={onClose}>
      <ModalHeader><div className='headerTitle'><img src="/assets/icons/16/widget.svg" style={{ marginRight: '10px' }}></img> Audience: {audienceName()}</div>{closeBtn}</ModalHeader>
      <ModalBody>
        <Label>Estimated Size:</Label>
        <Input min={0} type="number" value={estimatedSize || ''} onChange={(e) => setEstimatedSize(_.toNumber(e.target.value))} />
        <div className={styles.sectionContent}>
          The estimated maximum number of unique profiles that belong to this audience. This will be the 100% when calculating reach metrics in analytics.
        </div>
      </ModalBody>
      <ModalFooter>
        {/* <Button color="primary" block size="lg" onClick={onSave} style={{ textTransform: 'uppercase' }}>save</Button> */}
        <LoadingButton text={'save'} loading={saving} onClick={onSave} block />
      </ModalFooter>
    </Modal>
  )
}
