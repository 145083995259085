import React, { useState } from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { FormGroup, Input, Label, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput, Collapse } from 'reactstrap';
import { ChannelDetail } from '../domain';

import styles from './ChannelDetailsTranslations.module.scss'
import { MagazineLanguages } from '../../../../../../domain';
import { TranslationsComponent } from '../../../../../components';

export interface Props {
  magazine: number,
  languages: MagazineLanguages,
  channel: ChannelDetail,
  updateChannel: (channel: Partial<ChannelDetail>) => void
}

export function ChannelDetailsTranslations(props: Props) {
  const { languages, channel, magazine, updateChannel } = props;

  function updateTranslationValue(language: string, key: string, value: string) {
    const translations = channel.translations || []
    const existingTranslation = _.find(translations, (t) => (t.lang == language && t.key == key));
    const newTranslations = !existingTranslation
      ? [...translations, {
        lang: language,
        item_type: 'channel',
        item_id: _.toString(channel.id),
        magazine: _.toNumber(magazine),
        key,
        value
      }]
      : _.map(translations, (t) => (t.lang == language && t.key == key) ? { ...t, value } : t);

    updateChannel({ translations: newTranslations });
  }
  const boxElement = <ChannelBoxElement {...{ channel, updateTranslationValue }} />;
  return (
    <div >
      <TranslationsComponent {...{ languages, boxElement }} />
    </div>
  )
}

function parseJson(s: string) {
  try {
    return JSON.parse(s)
  } catch (error) {
    console.log(`Not a valid json value`);
    return {}
  }
}

interface ChannelBoxElementProps {
  channel: ChannelDetail
  primary?: boolean
  language?: string
  updateTranslationValue: (lang: string, key: string, value: string) => void,
}

function ChannelBoxElement(props: ChannelBoxElementProps) {
  const [opened, setOpened] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { channel, primary, language, updateTranslationValue } = props;

  const lang = language || '';
  const channelInfoObject = parseJson(channel.channelInfo || '')
  const channelInfoTranslationsStringified = getTranslationValue('channelinfo')
  const channelInfoTranslations = channelInfoTranslationsStringified ? parseJson(channelInfoTranslationsStringified) : {}

  const toggleActiveKey = (key: string, checked: boolean) => {
    if (!checked) {
      removeTranslationChannelInfoValue(key);
    } else {
      updateTranslationChannelInfoValue(key, '');
    }
  }

  function getTranslationValue(key: string): string | undefined {
    const tr = _.find(channel.translations, (t) => (t.lang === language && t.key === key));
    return tr ? tr.value : undefined;
  }


  function updateChannelInfoValueString(channelInfoObject: {}): void {
    updateTranslationValue(lang, 'channelinfo', JSON.stringify(channelInfoObject, null, 2));
  }

  const getTranslationChannelInfoValue = (key: string): string | undefined => {
    return _.get(channelInfoTranslations, key, undefined);
  }

  const removeTranslationChannelInfoValue = (key: string): void => {
    const newChannelInfoObject = _.omit(channelInfoTranslations, [key]);
    updateChannelInfoValueString(newChannelInfoObject)
  }

  const updateTranslationChannelInfoValue = (key: string, value: string): void => {
    const newChannelInfoObject = { ...channelInfoTranslations, [key]: value }
    updateChannelInfoValueString(newChannelInfoObject)
  }


  return (
    <div>
      <FormGroup>
        <Label>Label:</Label>
        <Input type='text' readOnly={primary} defaultValue={primary ? channel.label : getTranslationValue('label') || ''} onBlur={(e) => updateTranslationValue(lang, 'label', e.target.value)} />
      </FormGroup>
      <Label
        className={cn(styles.toggle, { [styles.opened]: opened })}
        onClick={() => setOpened(!opened)}
      >Tile Settings:</Label>
      <Collapse isOpen={opened}>
        <div >
          {_.map(channelInfoObject, (value, key) => {
            if (primary || getTranslationChannelInfoValue(key) !== undefined) {
              return (
                <Row key={key} style={{ marginBottom: '10px' }}>
                  <Col md={6}>
                    <Input type='text' defaultValue={key} readOnly={true} />
                  </Col>
                  <Col md={6}>
                    <Input type='text' defaultValue={primary ? value : getTranslationChannelInfoValue(key)} readOnly={primary} onBlur={(e) => updateTranslationChannelInfoValue(key, e.target.value)} />
                  </Col>
                </Row>
              )
            }
          })}
          {!primary &&
            <div >
              <Dropdown isOpen={dropdownOpen} toggle={() => { setDropdownOpen(!dropdownOpen) }}>
                <DropdownToggle>add key translation</DropdownToggle>
                <DropdownMenu>
                  {_.map(channelInfoObject, (value, key) => {
                    return (
                      <DropdownItem key={key} toggle={false} className={styles.dropdown}>
                        <CustomInput
                          id={`${lang}_${key}`}
                          type={'checkbox'}
                          checked={getTranslationChannelInfoValue(key) !== undefined}
                          label={key}
                          inline
                          onChange={(e: any) => toggleActiveKey(key, e.target.checked)} />
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          }
        </div>
      </Collapse>
    </div>
  )
}