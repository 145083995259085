import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Button, } from 'reactstrap';
import { MagazineLanguages, Profile } from '../../../../../domain';
import { API } from '../../../../apis';
import { TippyReact } from '../../../../components';
import DataTable, { ExtendedColumnDescription } from '../../../../components/DataTable/DataTable';
import { SelectOption } from '../../../../components/ReactSelect/ReactSelect';
import { DataTableHelper, HistoryHelper } from '../../../../utils';
import { DefaultMagazineLanguages } from '../../../../utils/translationsHelper';
import { EdErrorHandler, OrtecLoader, Swal, SwalDelete, SwalSuccess } from '../../../../widgets';
import { Newsletter, NlStatus } from './domain';
import { NewsletterDetails } from './NewsletterDetails';

import styles from './Newsletters.module.scss';
import { parseNewsletterConfiguration, stringifyNewsletterConfiguration } from './NewslettersHelper';
import { NewsletterTemplatesets } from './NewsletterTemplatesets';


interface Props {
  magazine: number,
  profile: Profile,
}

export const Newsletters = ({ magazine, profile }: Props) => {

  const [magazineLanguages, setMagazineLanguages] = useState<MagazineLanguages>({ ...(profile.magazineLanguages || DefaultMagazineLanguages) })
  const [newsletters, setNewsletters] = useState<Newsletter[]>([]);
  const [loadingNewsletters, setLoadingNewsletters] = useState<boolean>(false);
  const [campaignListOptions, setCampaignListOptions] = useState<SelectOption<number>[]>();
  const [campaignListLoading, setCampaignListLoading] = useState<boolean>(false);
  const [selectedCampaignFilter, setSelectedCampaignFilter] = useState<SelectOption<number>>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [duplicateLoading, setDuplicateLoading] = useState<boolean>(false);
  const [analyticsLoading, setAnalyticsLoading] = useState<boolean>(false);


  const loading = loadingNewsletters || campaignListLoading || deleteLoading || duplicateLoading || analyticsLoading;

  const history = useHistory();
  const viewTemplates = HistoryHelper.getQueryParam(history, 'templates') ? true : false;
  const newsletterParam = HistoryHelper.getQueryParam(history, 'newsletter');
  const editNewsletterId = newsletterParam ? _.toNumber(newsletterParam) : undefined;

  useEffect(() => {
    loadMagazineLanguages();
    loadOverview();
  }, []);

  const loadMagazineLanguages = async () => {
    try {
      const { data } = await API.oldApi.getLanguages(magazine);
      setMagazineLanguages(data);
    } catch (error) {
      EdErrorHandler(error, `loading magazine languages`);
    }
  }

  const loadOverview = () => {
    loadNewsletters();
    loadCampaignList();
  }

  const loadNewsletters = async () => {
    try {
      setLoadingNewsletters(true);
      const { data } = await API.newsletters.getNewsletters(magazine);
      setNewsletters(data);
    } catch (error) {
      EdErrorHandler(error, `loading newsletters`);
    } finally {
      setLoadingNewsletters(false);
    }
  }

  const loadCampaignList = async () => {
    try {
      setCampaignListLoading(true);
      const { data } = await API.newsletters.getNewsletterCampaignsForMagazine(magazine);
      setCampaignListOptions(_.map(data, (c) => { return { value: c.id, label: c.name } }));
    } catch (error) {
      EdErrorHandler(error, `loading campaign list`)
    } finally {
      setCampaignListLoading(false);
    }
  }

  const onOpenTemplates = () => {
    HistoryHelper.setQueryParams(history, { templates: 'true' });
  }

  const onCloseTemplates = () => {
    HistoryHelper.setQueryParams(history, { templates: undefined });
  }

  const onOpenNewsletter = (newsletterId: number) => {
    HistoryHelper.setQueryParams(history, { newsletter: newsletterId });
  }

  const onCloseNewsletter = () => {
    HistoryHelper.setQueryParams(history, { newsletter: undefined });
  }

  const onDelete = async (newsletter: Newsletter) => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      html: `This action will delete newsletter <code>[${newsletter.id}] ${newsletter.name}</code>.`
    });
    if (!confirm) {
      return;
    }
    await deleteNewsletter(newsletter.id);
    loadNewsletters();
  }


  const deleteNewsletter = async (newsletterId: number) => {
    try {
      setDeleteLoading(true);
      const { data } = await API.newsletters.deleteNewsletter(magazine, newsletterId);
      SwalSuccess.fire({
        title: 'Success!',
        text: `Newsletter with id ${newsletterId} has been deleted `,
        customClass: {
          popup: 'noBounce'
        },
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (error) {
      EdErrorHandler(error, `deleting a newsletter`);
    } finally {
      setDeleteLoading(false);
    }
  }

  const onDuplicate = async (newsletter: Newsletter) => {
    const { value: confirm } = await Swal.fire({
      type: 'question',
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, duplicate it!',
      focusCancel: true,
      html: `This action will duplicate newsletter <code>[${newsletter.id}] ${newsletter.name}</code>.`
    });
    if (!confirm) {
      return;
    }
    await duplicateNewsletter(newsletter.id);
    loadNewsletters();
  }

  const duplicateNewsletter = async (newsletterId: number) => {
    try {
      setDuplicateLoading(true);
      const { data } = await API.newsletters.getNewsletter(magazine, newsletterId);
      const parsedNewsletter = parseNewsletterConfiguration(data);
      const n = stringifyNewsletterConfiguration({
        ...parsedNewsletter,
        id: -1,
        status: 'draft',
        name: `copy of ${parsedNewsletter.name}`
      });
      await API.newsletters.saveNewsletter(magazine, n);
      SwalSuccess.fire({
        title: 'Success!',
        text: `Newsletter has been duplicated successfully!`,
        customClass: {
          popup: 'noBounce'
        },
        showConfirmButton: false,
        timer: 1000,
      })
    } catch (error) {
      EdErrorHandler(error, `duplicating newsletter`);
    } finally {
      setDuplicateLoading(false);
    }
  }

  const onShowAnalytics = async (newsletter: Newsletter) => {
    try {
      setAnalyticsLoading(true);
      const { data } = await API.newsletters.getNewsletterAnalytics(magazine, newsletter.id);
      Swal.fire({
        title: `Newsletter Analytics`,
        type: 'info',
        html: `
        <div style="text-align:left;">
          <div style="font-size:16px;font-weight:bold;margin-bottom:10px;">${newsletter.name}</div>
          <div class="alert alert-info" style="font-size:14px">
            <table>
              <tbody>
                ${newsletter.status == 'sent' ? `<tr><th>Sent:</th><td style="padding:0 15px">${data.sent}</td></tr>` : ''}
                <tr><th>Clicks:</th><td style="padding:0 15px">${data.clicked}</td></tr>
                <tr><th>Unsubscribed:</th><td style="padding:0 15px">${data.unsubscribed}</td></tr>
              </tbody>
            </table>
          </div>
        </div?
        `
      })
    } catch (error) {
      EdErrorHandler(error, `getting analytics for newsletter [${newsletter.id}] ${newsletter.name}.`)
    } finally {
      setAnalyticsLoading(false);
    }
  }


  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'status',
      sort: false,
      text: '',
      headerStyle: { width: '50px' },
      align: 'center',
      headerAlign: 'center',
      headerFormatter: (column, colIndex) => {
        return <Fragment><img data-tippy-content={'Status'} src={'/assets/icons/16/stats.svg'} /><span></span></Fragment>
      },
      formatter: (cell: NlStatus, row: Newsletter) => {
        let status = cell;
        const tippyContent = status == 'manuallySent' ? 'Manually Sent' : _.capitalize(status);

        return <i className={`fa fa-circle status status-${status}`} data-tippy-content={tippyContent}></i>;

      }
    },
    {
      dataField: 'status',
      text: '',
      hidden: true,
      formatter: (cell: NlStatus, row: Newsletter) => {
        return cell;
      }
    },
    {
      dataField: 'name',
      sort: true,
      text: 'Name'
    },
    {
      dataField: 'campaign',
      sort: true,
      text: 'Campaign',
      formatter: (cell: { id: number, name: string }, row: Newsletter) => {
        return cell?.name || '';
      },
      sortValue: (cell: { id: number, name: string }, row: Newsletter) => {
        return cell?.name || '';
      },
    },
    {
      dataField: 'templateset',
      sort: true,
      text: 'Template',
      formatter: (cell: { id: number, name: string }, row: Newsletter) => {
        return cell?.name || '';
      },
      sortValue: (cell: { id: number, name: string }, row: Newsletter) => {
        return cell?.name || '';
      },
    },
    {
      dataField: 'tags',
      sort: true,
      text: 'Tags',
      headerStyle: { width: '150px' },
      formatter: (cell: string[], row: Newsletter) => {
        if (_.isEmpty(cell)) {
          return '';
        }
        const totalTags = cell.length;
        return totalTags == 1 ? cell[0] : <TippyReact content={cell.join(', ')}><span>{totalTags} tags</span></TippyReact>
      },
      sortValue: (cell: string[], row: Newsletter) => {
        return cell.length;
      },
      searchable: false
    },
    {
      dataField: 'tags',
      sort: false,
      hidden: true,
      text: 'Tags',
      formatter: (cell: string[], row: Newsletter) => {
        return cell ? cell.join() : ''
      },
    },
    {
      dataField: 'cdate',
      sort: true,
      text: 'Creation Date',
      headerStyle: { width: '180px' },
      formatter: DataTableHelper.dateFormatter
    },
    {
      dataField: 'dfActions',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatter: (cell, row: Newsletter) => {
        return (
          <div className={'actionHoveringDiv'}>
            {!isLegacyNewsletter(row) && <Button style={{ marginRight: '10px' }} color={'secondary'} onClick={(e) => { onDuplicate(row); e.preventDefault(); e.stopPropagation(); return false; }}>Duplicate</Button>}
            {isLegacyNewsletter(row) && _.includes(['sent', 'manuallySent'], row.status) && <Button style={{ marginRight: '10px' }} color={'secondary'} onClick={(e) => { onShowAnalytics(row); e.preventDefault(); e.stopPropagation(); return false; }}>Analytics</Button>}
            <Button color={'secondary'} onClick={(e) => { onDelete(row); e.preventDefault(); e.stopPropagation(); return false; }}>Delete</Button>
          </div>
        )
      }
    }
  ];

  const rowClasses = (row: Newsletter, rowIndex: number) => {
    if (isLegacyNewsletter(row)) {
      return styles.legacyRow;
    }
    return '';
  }

  return (
    <div className={styles.Newsletters}>
      {loading && !editNewsletterId && <OrtecLoader />}
      <DataTable
        data={filterNewslettersBasedOnCampaign(newsletters, selectedCampaignFilter?.value)}
        columns={columns}
        keyField={'id'}
        onCreateNew={() => onOpenNewsletter(-1)}
        onRowClick={(row: Newsletter) => {
          if (isLegacyNewsletter(row)) {
            return;
          }
          onOpenNewsletter(row.id)
        }}
        defaultSorted={[{ dataField: 'cdate', order: 'desc' }]}
        secondaryElements={
          <Button style={{ marginRight: '15px' }} outline color={'secondary'} onClick={onOpenTemplates}>view templates</Button>
        }
        searchFilters={
          <Select
            options={campaignListOptions}
            value={selectedCampaignFilter}
            onChange={(o) => { setSelectedCampaignFilter(o || undefined) }}
            placeholder={`Select a campaign`}
            isClearable
          />
        }
        rowClasses={rowClasses}
      />
      {viewTemplates &&
        <NewsletterTemplatesets
          {...{ magazine, profile, magazineLanguages }}
          onClose={onCloseTemplates}
        />
      }
      {editNewsletterId &&
        <NewsletterDetails
          {...{ magazine, profile }}
          newsletterId={editNewsletterId}
          onClose={onCloseNewsletter}
          openNewsletter={onOpenNewsletter}
          reloadOverview={loadOverview}
        />
      }
    </div>
  )
}

// ─── Helper Functions ────────────────────────────────────────────────────────

const filterNewslettersBasedOnCampaign = (newsletters: Newsletter[], campaignId?: number) => {
  if (!campaignId) {
    return newsletters;
  }

  return _.filter(newsletters, (n) => n.campaign?.id == campaignId);
}

const isLegacyNewsletter = (newsletter: Newsletter): boolean => {
  try {
    const c = JSON.parse(newsletter.configuration);
    return c.version != 2;
  } catch (error) {
    return true;
  }
}