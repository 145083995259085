import React, { Fragment, useMemo } from 'react';

import { Profile } from '../../../../../domain';
import { Translations } from '../../../../translations';
import { useHistory } from 'react-router-dom';

import * as _ from 'lodash';
import { API } from '../../../../apis';
import { EdErrorHandler, OrtecLoader } from '../../../../widgets';
import { CmsOverview } from './CmsOverview/CmsOverview';
import { CmsFeedInfo, CmsItemDetails } from './CmsItemDetails/CmsItemDetails';
import { CmsStage } from './CmsDomain';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HistoryHelper } from '../../../../utils';

export interface Props {
  magazine: number,
  profile: Profile,
  activeFeed: number
  agendaMode?: boolean
  setFeed: (feed: number) => void
  updateProfile: () => void
}

export const Cms = (props: Props) => {
  const { magazine, profile, agendaMode, activeFeed, updateProfile } = props;

  const queryClient = useQueryClient();

  const history = useHistory();

  const cmsFeedQuery = useQuery({
    queryKey: ['cmsFeed', activeFeed],
    queryFn: async () => {
      try {
        const { data } = await API.cms.getCmsFeed(magazine, activeFeed);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting CMS feed`);
      }
    }
  });

  const feedLoading = cmsFeedQuery.isFetching;

  const tags = useMemo(() => {
    if (!cmsFeedQuery.data) {
      return [];
    }
    return _.filter(cmsFeedQuery.data.tags, (t) => !_.startsWith(t.tag, '_cat:'));
  }, [cmsFeedQuery.data]);

  const cmsFeed: CmsFeedInfo | undefined = useMemo(() => {
    if (!cmsFeedQuery.data) {
      return undefined;
    }
    const { label, personalizedFeed, variations } = cmsFeedQuery.data;
    return {
      id: activeFeed,
      isPersonalized: personalizedFeed,
      label,
      variations
    }
  }, [activeFeed, cmsFeedQuery.data])


  const refreshHistoryHandler = () => {
    queryClient.invalidateQueries({ queryKey: ['cmsFeed', activeFeed] })
  }


  const onOpenItemDetails = (itemId: string, subTab?: CmsStage) => {
    HistoryHelper.setQueryParams(history, { item: encodeURIComponent(itemId), subTab: subTab || 'published' })
  }

  const onCloseItemDetails = (publishedArticleId?: string) => {
    if (publishedArticleId) {
      refreshHistoryHandler();
      HistoryHelper.setQueryParams(history, { item: undefined, subTab: 'published' });
    } else {
      HistoryHelper.setQueryParams(history, { item: undefined });
    }
  }

  const shownItem = HistoryHelper.getQueryParam(history, 'item');
  const cmsStage: CmsStage = HistoryHelper.getQueryParam(history, 'subTab') as CmsStage;

  return (
    <Fragment>
      {feedLoading && !cmsFeed && <OrtecLoader />}
      {Translations.EnableTranslations(Translations)}
      {shownItem && cmsFeed &&
        <CmsItemDetails
          magazine={_.toInteger(magazine)}
          profile={profile}
          activeFeed={cmsFeed}
          cmsStage={cmsStage}
          existingTags={tags}
          itemId={shownItem}
          agendaMode={agendaMode}
          onClose={onCloseItemDetails}
        />
      }
      {cmsFeed &&
        <div className={shownItem ? 'limitedHeight' : ''}>
          <CmsOverview
            magazine={magazine}
            profile={profile}
            onClickItem={onOpenItemDetails}
            activeFeed={cmsFeed}
            setFeed={props.setFeed}
            agendaMode={agendaMode}
            updateProfile={updateProfile} />
        </div>
      }
    </Fragment>

  );
}