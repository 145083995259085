import React from 'react';
import { NlSlotItem_freeText, NlTemplateConfiguration_Content } from './domain';
import styles from './NewsletterContentFreeText.module.scss';
import { HtmlTemplatesHelper } from './htmlTemplates';

interface Props {
  slotItem: NlSlotItem_freeText
  templateConfiguration: NlTemplateConfiguration_Content
  onClick: (text: string) => void
}

export const NewsletterContentFreeText = ({ slotItem, templateConfiguration, onClick }: Props) => {
  const { fontSize, textColor } = templateConfiguration.articleDescription;
  return (
    <div className={styles.NewsletterContentFreeText} onClick={() => onClick(slotItem.text)} style={{ fontSize: `${HtmlTemplatesHelper.fontSizeToPixels(fontSize)}px`, color: `${textColor}` }}>
      <div dangerouslySetInnerHTML={{ __html: slotItem.text }} />
    </div>
  )
}
