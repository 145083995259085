import React from 'react';
import { ControllerRenderProps, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import { Label } from 'reactstrap';
import { NcSlideConfigFormData, NcSlideshowFormData } from '../subpages/NcSlideshows/NcSlideshowDetails';
import styles from './NcSlideConfigToggle.module.scss';
import classNames from 'classnames';
import Toggle from 'react-toggle';
import { NarrowcastingHelper } from '../NarrowcastingHelper';

interface Props {
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  fieldName: FieldPath<NcSlideConfigFormData>
  activeIndex: number
  label?: string
  inputWrapperClass?: string

  // errors: FieldErrors<NcSettingsFormData>
}

export const NcSlideConfigToggle = ({ field, fieldName, activeIndex, label, inputWrapperClass }: Props) => {
  const val = NarrowcastingHelper.getValueOfSlideConfigField(field, activeIndex, fieldName);
  return (
    <>
      <Label>{label}</Label>
      <div className={classNames(styles.toggleWrapper, inputWrapperClass)}>
        <Toggle
          checked={_.isBoolean(val) ? val : false}
          onChange={(e) => {
            NarrowcastingHelper.setValueOfSlideConfigField(field, activeIndex, fieldName, e.target.checked)
          }}
        />
      </div>
    </>
  )
}
