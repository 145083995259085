import React, { Component, Fragment } from 'react';
import { Input, Row, Col, Label, Button, CustomInput, Alert, Table } from 'reactstrap';
import moment from 'moment';
import * as _ from 'lodash';
import * as Api from '../../../../api';
import { Profile, Audience, Form, FormConfig } from '../../../../../domain';

import { Translations } from '../../../../translations';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import classnames from 'classnames';
import Toggle from 'react-toggle';
import "react-toggle/style.css"

import * as FormsHelper from './FormsHelper';
import { SwalError, SwalSuccess } from '../../../../widgets';

export interface Props {
  magazine: number
  form: Form,
  results: any[],
  quizSuccesses?: string[]
}

// export interface State {
//   results: any[],
//   notFound?: boolean,
//   loading: boolean

// }

export class FormResultsDetailed extends Component<Props> {
  constructor(props: Props) {
    super(props);
    // this.state = {
    //   loading: true,
    //   results: [],
    // }
  }

  // componentDidMount() {
  //   this.getResults();
  // }

  // getResults = () => {
  //   this.setState({ loading: true });
  //   Api.getFormResults( this.props.magazine, this.props.form.id).then((resp) => {
  //     if (!resp.data || resp.data.error) {
  //       console.log('Error in getting resutls');
  //       this.setState({ results: [], loading: false, notFound: true });
  //     }

  //     this.setState({ results: resp.data, loading: false, notFound: false });
  //   })
  //   .catch((error) => {
  //     console.log('An error occured:' + error.message)
  //     this.setState({ results: [], loading: false, notFound: true });
  //   });
  // }

  renderResponse = (response: any) => {
    if (!response || !response.response) {
      return '-';
    }
    const { form } = this.props;
    const question = _.find(form.config.questions, ['id', response.id]);
    if (!question) {
      return '~';
    }
    switch (question.type) {
      case 2:
        if (!question.answers) {
          return '~~';
        }

        const answers = _.map(response.response, (r) => {
          const answer = _.find(question.answers, ['id', _.toInteger(r)]);
          // if(answer){
          //   return answer.text;
          // }
          return answer ? answer.text : r;

        })
        return <span className={'multipleAnswers'}>{answers.join()}</span>
      case 3:
        if (!question.answers) {
          return '~~';
        }
        const answer = _.find(question.answers, ['id', response.response]);
        return <span className={`singleAnswer ${answer && answer.id == question.correctAnswer ? 'correct' : ''}`}>{answer ? answer.text : `~ ${response.response} ~`}</span>
      case 4:
        return <span className={'freeText'} data-tippy-content={response.response} data-tippy-arrow="false">{response.response}</span>
      case 5:
        return <span className={'star'}>{`${response.response ? response.response : 'No'} star${response.response == 1 ? '' : 's'}`}</span>
      default:
        return <span>{response.response}</span>
    }
  }
  renderTableHeaderColumns = () => {
    const { form } = this.props;
    let num = 0;
    return (
      <Fragment>
        {form.config.questions.map((q, index) => {
          return _.includes([1, 7, 9], q.type) ? null : <th key={`q-${num}`}><span data-tippy-placement={'right'} data-tippy-content={q.text}>{`Question ${(++num)}`} <i className={'material-icons'} style={{ verticalAlign: 'middle' }}>error_outline</i></span></th>
        })}
      </Fragment>
    )
  }
  renderTableHeaderColumns_survey = () => {
    console.log('survey');

    const { form } = this.props;
    const sections = form.config.sections ? form.config.sections : [];
    let num = 0;
    return (
      <Fragment>
        {sections.map((s, sIndex) => {
          num = 0;
          return s.questions.map((qId, index) => {
            const q = _.find(form.config.questions, ['id', qId]);
            return (!q || q.type == 9) ? null : <th key={`q-${num}`} className={!num ? 'sectionStart' : ''}><span data-tippy-placement={'right'} data-tippy-content={q.text}>{`Question ${(++num)}`} <i className={'typeIcon'} data-type={`${q.type}${q.multiline ? 'm' : ''}`}></i></span></th>
          })
        })
        }
      </Fragment>
    )
  }
  renderSectionHeader = () => {
    const { form } = this.props;
    const sections = form.config.sections ? form.config.sections : [];

    return (
      <tr>
        <th></th>
        {sections.map((s, sIndex) => {
          return <th key={`section-${sIndex}`} colSpan={s.questions.length}>Section {sIndex + 1}</th>
        })
        }
      </tr>
    )

  }
  renderTableHeader = () => {
    const { form } = this.props;
    return (
      <thead>
        {/* {form.type !== 'survey'?null:this.renderSectionHeader()} */}
        <tr>
          <th></th>
          {form.type == 'survey' ? this.renderTableHeaderColumns_survey() : this.renderTableHeaderColumns()}
        </tr>
      </thead>
    )

  }
  render() {
    const { form, results } = this.props;
    // const { results, loading, notFound} = this.state;
    const tr = Translations.Translate(Translations, 'Forms');

    const totalQuestions = _.size(_.filter(form.config.questions, (q) => q.type != 9));

    // const loader = <div className="loaderContainer"><div className="loaderWrapper"><i className="fa fa-spinner fa-spin"></i></div></div>;
    // const notFoundMessage = <div className="notFoundContainer alert alert-danger">{tr('formNotFound')}</div>;

    return (
      <div id="FormResultsDetailed">
        {Translations.EnableTranslations(Translations)}
        <div className={'tableDiv'}>
          <Table>
            {this.renderTableHeader()}
            <tbody>
              {_.isEmpty(results) ?
                <tr><td colSpan={(totalQuestions + 1)}>There are no results.</td></tr>
                :
                results.map((r, ind) => {
                  return (
                    <tr key={`r-${ind}`}>
                      <td className={'userCell'}><i className={'material-icons'} data-tippy-content={`UID: ${r.uid}`}>person_outline</i></td>
                      {form.config.questions.map((q, index) => {
                        if (!_.includes([1, 7, 9], q.type)) {
                          const response = _.find(r.responses, ['id', q.id]);
                          return <td key={`r-${ind}-a-${index}`}>{this.renderResponse(response)}</td>
                        }
                      })}
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default FormResultsDetailed;
