import React, { useMemo } from 'react';
import { SelectOption, SingleSelect } from '../ReactSelect/ReactSelect';
import _ from 'lodash';
import { constructOptions } from './HoursSelect';

interface Props {
  value: string
  onChange: (v: string) => void,
  step?: number,
  isClearable?: boolean,
  placeholder?: string,
}

export const MinutesSelect = ({ value, onChange, step, isClearable, placeholder }: Props) => {

  const options: SelectOption<string>[] = useMemo(() => {
    return constructOptions(0, 60, step || 5)
  }, [step]);

  return (
    <SingleSelect
      value={value === '' ? undefined : value}
      onChange={(o) => { o === undefined ? onChange('') : onChange(o) }}
      options={options}
      isClearable={isClearable}
      placeholder={placeholder !== undefined ? placeholder : 'Minutes'}
      menuPosition={'fixed'}
    />
  )
}
