import _ from 'lodash';
import React from 'react';
import { Input } from 'reactstrap';
import { ciHelper } from '.';

interface Props {
  fieldKey: string,
  onChange: (key: string, newKeyValue: string) => void
}

export const KeyInput = ({ fieldKey, onChange }: Props) => {
  if (!ciHelper.isOtherKeyType(fieldKey)) {
    return null;
  }
  return (
    <div style={{ flex: 1 }}>
      <Input type='text' defaultValue={fieldKey} placeholder={`Key`} onBlur={(e) => onChange(fieldKey, e.target.value)} />
    </div>
  )
}
