import React from 'react';
import cn from 'classnames'
import _ from 'lodash'
import Modal, { ModalProps } from 'reactstrap/lib/Modal'

import styles from './GenericModal.module.scss'

interface Props extends ModalProps {
  scrollable?: boolean
  fullHeight?: boolean
}

/**
 * normal reactstrap Modal. extra options
 * * size:'xxl'
 * * scrollable: true
 */
export function GenericModal(props: Props) {
  const modalProps: ModalProps = _.omit(props, 'fullHeight')

  return <Modal
    {...modalProps}
    className={cn(
      styles.main,
      { [styles.scrollable]: true },
      { [styles.fullHeight]: props.fullHeight },
      { [styles.xxl]: props.size === 'xxl' },
    )} />
}