import React, { useState } from 'react';
import { Button, FormGroup, ModalBody, } from 'reactstrap';
import { GenericModal } from '../../components/GenericModal/GenericModal';
import GenericModalHeader from '../../components/GenericModal/GenericModalHeader';
import { EdErrorHandler, OrtecLoader, SwalDelete, SwalSuccess } from '../../widgets';
import DataTable, { ExtendedColumnDescription } from '../../components/DataTable/DataTable';
import { NcDevice, NcGroup } from './NarrowcastingDomain';
import _ from 'lodash';
import { NcGroupDetailsModal } from './NcGroupDetailsModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from '../../apis';

export const DEFAULT_NC_GROUP: NcGroup = {
  id: -1,
  magazine: -1,
  label: '',
  deleted: false,
  device_ids: [],
}

interface Props {
  magazine: number
  groups: NcGroup[]
  devices: NcDevice[]
  onClose: () => void
}

export const NcGroupsModal = ({ magazine, groups, devices, onClose }: Props) => {

  const queryClient = useQueryClient();

  const [editGroup, setEditGroup] = useState<NcGroup>();

  const ncGroupDeleteMutation = useMutation({
    mutationKey: ['ncGroupDelete'],
    mutationFn: (g: NcGroup) => API.narrowcasting.deleteNcGroup(magazine, g.id),
    onError: (error, g) => {
      EdErrorHandler(error, `deleting group [${g.id}] ${g.label}`);
    },
    onSuccess: (data, g) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `Group <${g.label}> has been deleted successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['ncGroups'] })
      queryClient.invalidateQueries({ queryKey: ['ncDevices'] })
    }
  })

  const onDelete = async (group: NcGroup) => {
    const { value: confirm } = await SwalDelete.fire({
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      focusCancel: true,
      html: `This action will delete group <code>[${group.id}] ${group.label}</code>.`
    });
    if (!confirm) {
      return;
    }
    ncGroupDeleteMutation.mutate(group);
  }

  const columns: ExtendedColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Id'
    },
    {
      dataField: 'label',
      text: 'Group Name',
    },
    {
      dataField: 'device_ids',
      text: 'Number of Devices',
      headerAlign: 'center',
      align: 'center',
      formatter: (deviceIds: number[], ncGroup: NcGroup) => {
        return ncGroup?.device_ids?.length || 0;
      }
    },
    {
      dataField: 'dfActions',
      isDummyField: true,
      sort: false,
      text: '',
      headerStyle: { width: '25px' },
      classes: 'actionsColumn',
      formatter: (cell, row: NcGroup) => {
        return (
          <div className={'actionHoveringDiv'}>
            <Button color={'secondary'} onClick={(e) => { onDelete(row); e.preventDefault(); e.stopPropagation(); return false; }}>Delete</Button>
          </div>
        )
      }
    }
  ];

  return (
    <GenericModal isOpen={true} centered size={'lg'}>
      <GenericModalHeader
        onClose={onClose}
        title={`device groups`}
      />
      <ModalBody>
        <FormGroup>
          {ncGroupDeleteMutation.isLoading && <OrtecLoader size={'medium'} />}
          <DataTable
            data={groups}
            columns={columns}
            keyField={'id'}
            onRowClick={(g: NcGroup) => { setEditGroup(g) }}
            onCreateNew={() => { setEditGroup({ ...DEFAULT_NC_GROUP, magazine }) }}
            createNewButtonText={'add new group'}
            noPagination
          />
        </FormGroup>
      </ModalBody>
      {editGroup && <NcGroupDetailsModal {...{ magazine, devices, groups }} group={editGroup} onClose={() => setEditGroup(undefined)} />}
    </GenericModal>
  )
}

