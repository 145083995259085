import { NlTemplateConfiguration } from "../domain";

const parse = (configuration: NlTemplateConfiguration): string => {
  return `
      <!DOCTYPE html>
      <!--[if lt IE 7]>      <html lang="en" class="no-js lt-ie9 lt-ie8 lt-ie7"> <![endif]-->
      <!--[if IE 7]>         <html lang="en" class="no-js lt-ie9 lt-ie8"> <![endif]-->
      <!--[if IE 8]>         <html lang="en" class="no-js lt-ie9"> <![endif]-->
      <!--[if gt IE 8]><!--> <html lang="en" class="no-js"> <!--<![endif]-->
      <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <title>Newsletter Edition</title>
        <meta name="description" content="">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.18.1/moment.min.js"></script>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css" />
        <!-- Latest compiled and minified CSS -->
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous">
        <!-- Latest compiled and minified JavaScript -->
        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js" integrity="sha384-Tc5IQib027qvyjSMfHjOMaLkfuWVxZxUPnCJA7l2mCWNIpG9mGCD8wGNIcPD7Txa" crossorigin="anonymous"></script>
        <style>
          @import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
          .nl-heading{ width: 100%; height: 80px; line-height: 80px;}
          .nl-body{ background-color: #eff3f6; height: 100%;}
          .article-menu>li>a{ padding: 20px 20px; border: 1px solid #eff3f6; background: white;}
          .menu-box{ margin-top:50px;}
          .article-menu>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover { color: #fff; background-color: #1aa2db;}
          article>img{width: 100%;}
        article{background: white; margin: 0px 30px 50px 15px; padding: 10px 0px;}
          .article-menu{ margin: 0px 15px 0px 30px;}
          article>div{margin:15px;}
          .menu-title{padding: 20px 20px; border: 1px solid #eff3f6; background: white; color: #337ab7; text-decoration: none;}
          .hamburger-button{margin-top: 20px;}
          .header-nav{margin-bottom: 0px;}
          .page-title{margin: 0px 30px;}
          .page-title img{width: 120px;}
          .articles-number{ float: right; font-size: 80%;}
          article img, article iframe{max-width: 100%;}
          body{font-family: Roboto, sans-serif;}
          .article-menu>li+li{margin-top: 0px;}

          @media only screen and (max-width: 768px) {.full-size-article-menu{display: none;} .mobile-article-menu{display: block;}     article{background: white; margin: 0px 30px 50px 30px;}   .article-menu{ margin: 0px 30px 0px 30px;}}
          @media only screen and (min-width: 768px) {.full-size-article-menu{display: block;} .mobile-article-menu{display: none;}}
        </style>
        <script>
          $(function() {
            $('time').each(function(i, e) {
              var time = moment($(e).text()).format('LL');
              $(e).text(time);
            });
          });
        </script>
      </head>
      <body>

            <nav class="navbar navbar-default header-nav">
              <div class="navbar-header nl-heading">
                  <span class="page-title"><img src="https://static.ortec-relevance.com/newsletter/ortec_relevance_nospace.png" alt="" /> </span>
                  <button type="button" class="navbar-toggle collapsed hamburger-button" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                      <span class="sr-only">Toggle navigation</span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                  </button>
              </div>

              <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                  <ul class="nav navbar-nav mobile-article-menu article-menu">
                    <li role="presentation" class = "menu-title">In this publication <span class='articles-number'>{{ "articles" | length }} articles</span></li>
                    {% for article in articles %}
                      <li role="presentation"><a href="#{{article.id}}">{{article.title}}</a></li>
                    {% endfor %}
                  </ul>
              </div>
          </nav>

          <div class="nl-body" style="margin:auto;">
            <div class="container-fluid ">
              <div class="row">

                <div class="menu-box">

                  {% for newsletter in newsletters %}
                    <div id="{{newsletter.id}}">
                      <article class="col-sm-4 ">
                      {{newsletter.image}}
                      <div class="entry-content">
                          <div class="bottom-box">
                            <h3>{{newsletter.name}}</h3> 			<a href={{newsletter.url}}>link</a>
                            </div>
                        </div>
                      </article>
                    </div>
                  {% endfor %}

                </div>
              </div>
            </div>
          </div>
      </body>
      </html>

    `;
}

export const linkedEditions = {
  parse
}