import './css/SourcesTranslations.min.css';

import React from 'react';
import classnames from 'classnames';
import { MagazineLanguage, MagazineLanguages, Source, Translation } from '../../../../../domain';
import _ from 'lodash';
import { FormGroup, Input, Label, Row } from 'reactstrap';
import { TranslationsComponent } from '../../../../components';

export interface Props {
    magazine: number,
    source?: Source,
    languages: MagazineLanguages,
    translations: Translation[],
    updateTranslationValue: (language: string, key: string, value: string) => void,

}

export function SourcesTranslations(props: Props) {
    const { magazine, languages, source, translations, updateTranslationValue } = props;
    const boxElement = <SourceBoxElement {...{ source, translations, updateTranslationValue }} />;

    return (
        <div id={'SourcesTranslations'}>
            <TranslationsComponent {...{ magazine, languages, boxElement }} />
        </div>
    )
}

interface SourceBoxElementProps {
    source?: Source
    primary?: boolean
    language?: string
    translations: Translation[]
    updateTranslationValue: (language: string, key: string, value: string) => void,

}

function SourceBoxElement(props: SourceBoxElementProps) {

    const { source, primary, language, translations, updateTranslationValue } = props;
    if (!source) {
        return null;
    }

    const lang = language || '';

    const getTranslationValue = (language: string, key: string): string => {
        const tr = _.find(translations, (t) => {
            return (t.lang === language && t.key === key);
        });
        return tr ? tr.value : '';
    }

    return (
        <div className={'boxElement'}>
            <FormGroup>
                <Label>Label:</Label>
                <Input type='text' readOnly={primary} defaultValue={primary ? source.label : getTranslationValue(lang, 'label')} onBlur={(e) => updateTranslationValue(lang, 'label', e.target.value)} />
            </FormGroup>
        </div>
    )
}