import React from 'react';
import { Profile } from '../../../../../domain';
import styles from './NcSettings.module.scss';
import { NcSettingsTile } from './NcSettingsTile';
import { useHistory } from 'react-router-dom';
import { HistoryHelper } from '../../../../utils';
import { NcSettingsConfiguration } from './NcSettingsConfiguration/NcSettingsConfiguration';
import { NcSettingsGallery } from './NcSettingsGallery/NcSettingsGallery';
import { NcSettingsFonts } from './NcSettingsFonts/NcSettingsFonts';
import { NcSettingsAlert } from './NcSettingsAlert/NcSettingsAlert';
import { useQuery } from '@tanstack/react-query';
import { API } from '../../../../apis';
import Swal from 'sweetalert2';
import { EdErrorHandler } from '../../../../widgets';

interface Props {
  magazine: number,
  profile: Profile
}

type SettingsTile = 'configuration' | 'gallery' | 'fonts' | 'alert';

export const NcSettings = ({ magazine, profile }: Props) => {

  const ncAlertSlideQuery = useQuery({
    queryKey: ['ncAlertSlide', magazine],
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcSettingsAlertSlide(magazine);
        return data;
      } catch (error) {
        if (error?.response?.status === 422) { //not valid schema. probably legacy record.
          Swal.fire({
            type: 'warning',
            title: 'Invalid schema!',
            text: 'Stored nc settings configuration is in an invalid schema',
            footer: `<div class="alert alert-info" style="width:100%;">The default values will be loaded instead and the new configuration will be stored when you click save.</div>`
          })
        } else if (error?.response?.status === 404) {
          Swal.fire({
            type: 'warning',
            title: 'No settings found!',
            text: 'No Nc settings configuration is currently stored',
            footer: `<div class="alert alert-info" style="width:100%;">The default values will be loaded and the new configuration will be stored when you click save.</div>`
          })
        } else {
          EdErrorHandler(error, `getting NC alert slide configuration`);
        }
      }
    }
  });

  const history = useHistory();
  const activeTile = HistoryHelper.getQueryParam(history, 'tile') as SettingsTile | undefined;

  const openTile = (tile: SettingsTile) => {
    HistoryHelper.setQueryParams(history, { tile });
  }

  const closeTile = () => {
    HistoryHelper.setQueryParams(history, { tile: undefined });
  }

  const isActiveTile = (tile: SettingsTile) => {
    return activeTile === tile
  }

  return (
    <div className={styles.NcSettings}>
      <NcSettingsTile
        title={`Configuration`}
        description={`Configure the colors, images and fonts of your device.`}
        onClick={() => openTile('configuration')}
      />
      <NcSettingsTile
        title={`Gallery`}
        description={`Upload and manage your images.`}
        onClick={() => openTile('gallery')}
      />
      <NcSettingsTile
        title={`Fonts`}
        description={`Upload and manage your fonts.`}
        onClick={() => openTile('fonts')}
      />
      <NcSettingsTile
        title={`Alert slide`}
        description={`Configure the alert slide.`}
        onClick={() => openTile('alert')}
        additionalInfo={ncAlertSlideQuery.data?.isEnabled ?
          <span style={{ fontWeight: 'bold', color: '#63bd04' }}>Enabled</span>
          : undefined
        }
      />
      {isActiveTile('configuration') &&
        <NcSettingsConfiguration {...{ magazine, profile }} onClose={closeTile} />
      }
      {isActiveTile('gallery') &&
        <NcSettingsGallery {...{ magazine, profile }} onClose={closeTile} />
      }
      {isActiveTile('fonts') &&
        <NcSettingsFonts {...{ magazine, profile }} onClose={closeTile} />
      }
      {isActiveTile('alert') &&
        <NcSettingsAlert key={ncAlertSlideQuery.dataUpdatedAt} {...{ magazine, profile, ncAlertSlideQuery }} onClose={closeTile} />
      }
    </div>
  )
}


