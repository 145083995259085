import React, { useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';
import * as Api from './api';
import { Profile } from '../domain';
import SideBar from './SideBar';
import Main from './Main';
import Error from './Error';
import { LanguageContextInterface, LanguageContextProvider } from './LanguageContext';
import { OrtecLoader, EdErrorHandler } from './widgets';
import { useQuery } from '@tanstack/react-query';
import { API } from './apis';
import { ED_PAGES } from './accessRights/accessRights';
import { AccessRightsHelper } from './utils';


export interface Props {
  magazine: string
}

export interface State {
  languageContext: LanguageContextInterface
  profile: Profile | undefined,
  loading: boolean
  isForbidden?: boolean
}

export const Root = (props: Props) => {

  const { magazine } = props

  const [profile, setProfile] = useState<Profile>()
  const [loading, setLoading] = useState(true)
  const [isForbidden, setIsForbidden] = useState(false);
  const [languageContext, setLanguageContext] = useState({language: 'en'});

  useEffect(() => {
    getMyProfile()
  }, [])

  const fetchReportedContent = useMemo(() => {
    if(!profile) {
      return false;
    };

    if(!profile.edConfig?.reportedContent) {
      return false;
    }

    return AccessRightsHelper.shouldUserAccessPage(profile, ED_PAGES, 'reportedContent', 'content');

  }, [profile])

  const getMyProfile = async () => {
    try {
      const { data } = await Api.me(magazine)
      const profile: Profile = data;
      const language = profile.language || 'en';
      setProfile(profile);
      setLanguageContext({language});
    } catch (error) {
      if (_.get(error, `response.status`) == 403) {
        setIsForbidden(true)
      } else {
        EdErrorHandler(error, 'getting your profile', _.get(error, `response.status`) == 500);
      }
    } finally {
      setLoading(false)
    }
  }

  //TODO: Change this when you add ReactQueryKeys
  useQuery({
    queryKey: ['reportedContents', Number(magazine)],
    queryFn: async () => {
      try {
        const { data } = await API.reportedContent.getReportedContents(Number(magazine));
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting reported contents`);
      }
    },
    enabled: fetchReportedContent
  });

  if (loading) {
    return <OrtecLoader />
  }
  else if (isForbidden || (profile && !profile.editor)) {
    return (
      <Error magazine={magazine} message="You are not authorised to use this service. Please contact your system administrator!" logout={true} page={true} />
    )
  } else if (!profile) {
    return (
      <Error magazine={magazine} message="Configuration for this magazine seems to be wrong or missing. Please contact your system administrator!" logout={true} page={true} />
    )
  } else if (profile && !profile.globalAudience && _.isEmpty(profile.audiences)) {
    return (
      <Error magazine={magazine} message="You are not authorised to see any audiences. Please contact your system administrator!" logout={true} page={true} />
    )
  } else {
    return (
      <LanguageContextProvider value={languageContext}>
        {profile !== undefined && <SideBar magazine={magazine} profile={profile} />}
        {profile !== undefined && <Main magazine={magazine} profile={profile} updateProfile={getMyProfile.bind(this)} />}
      </LanguageContextProvider>
    )
  }
}

export default Root;