import React, { useEffect } from 'react';

import styles from './NcSlideshowDetails.module.scss';
import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { NcDevice, NcGroup, NcSchedule, NcSlideConfig, NcSlideshow, NcSlideshow_save } from '../../NarrowcastingDomain';
import { Profile } from '../../../../../domain';
import { EdErrorHandler, LoadingButton, OrtecLoader, SwalSuccess } from '../../../../widgets';
import { API } from '../../../../apis';
import { FullPageModal, FullPageModalContent, FullPageModalContentPart, FullPageModalHeader, FullPageModalSettings, FullPageModalSettingsSection, FullPageModalSettingsSectionRow } from '../../../../components/FullPageModal';
import { TippyReact } from '../../../../components';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'reactstrap';
import _ from 'lodash';
import { NcSlidesManager } from './NcSlidesManager';
import { NarrowcastingHelper } from '../../NarrowcastingHelper';
import { NcSlideshowPlan } from './NcSlideshowPlan';

export type NcSlideConfigFormData = NcSlideConfig;

export interface NcSlideFormData {
  type: string,
  renderer: string,
  sort: number,
  config: NcSlideConfigFormData

}

export interface NcSlideshowFormData {
  id: number,
  label: string,
  device_ids: number[],
  group_ids: number[],
  slides: NcSlideFormData[],
  schedule?: NcSchedule
}

interface Props {
  magazine: number,
  profile: Profile,
  slideshows: NcSlideshow[]
  ncGroupsQuery: UseQueryResult<NcGroup[] | undefined>
  ncDevicesQuery: UseQueryResult<NcDevice[] | undefined>
  slideshowId: number,
  onClose: () => void,
}

export const NcSlideshowDetails = ({ magazine, profile, slideshows, ncGroupsQuery, ncDevicesQuery, slideshowId, onClose }: Props) => {

  const isNew = (slideshowId === -1);

  const queryClient = useQueryClient();

  const ncSlideshowQuery = useQuery({
    queryKey: ['ncSlideshow', slideshowId],
    enabled: !isNew,
    queryFn: async () => {
      try {
        const { data } = await API.narrowcasting.getNcSlideshow(magazine, slideshowId);
        return data;
      } catch (error) {
        EdErrorHandler(error, `getting NC slideshow`);
      }
    }
  });

  const { control, handleSubmit, clearErrors, setError, reset, formState: { errors } } = useForm<NcSlideshowFormData>({
    defaultValues: {
      id: slideshowId,
      label: '',
      device_ids: [],
      group_ids: [],
      slides: [],
      schedule: undefined
    }
  });

  const nameHasErrors = errors.label ? true : false;
  const slidesHaveErrors = errors.slides ? true : false;
  const hasErrors = nameHasErrors || slidesHaveErrors;

  const ncSlideshowSaveMutation = useMutation({
    mutationKey: ['ncSlideshowUpdate'],
    mutationFn: (s: NcSlideshow_save) => API.narrowcasting.saveNcSlideshow(magazine, s),
    onError: (error, s) => {
      EdErrorHandler(error, `saving slideshow`);
    },
    onSuccess: (data, s) => {
      SwalSuccess.fire({
        title: 'Success!',
        text: `Slideshow <${s.label}> has been saved successfully`,
        showConfirmButton: false,
        customClass: {
          popup: 'noBounce'
        },
        timer: 2000,
      });
      queryClient.invalidateQueries({ queryKey: ['ncSlideshows'] })
      onClose();
    }
  })

  useEffect(() => {
    reset({
      id: slideshowId,
      label: ncSlideshowQuery.data?.label || '',
      device_ids: ncSlideshowQuery.data?.device_ids || [],
      group_ids: ncSlideshowQuery.data?.group_ids || [],
      slides: ncSlideshowQuery.data?.slides || [],
      schedule: ncSlideshowQuery.data?.config?.schedule || {}
    })
  }, [ncSlideshowQuery.data, reset, slideshowId])

  const loading = ncSlideshowQuery.isFetching;

  const onSave = handleSubmit(async (data) => {

    const { id, label, device_ids, group_ids, slides, schedule } = data;

    const config = schedule && (schedule.start || schedule.end) ? { schedule } : undefined;

    const s: NcSlideshow_save = {
      id,
      label,
      group_ids,
      device_ids,
      config,
      slides: NarrowcastingHelper.adjustSlideRenderers(slides),
    };

    ncSlideshowSaveMutation.mutate(s);
  });

  return (
    <FullPageModal>
      <FullPageModalHeader isLoading={loading} onClose={onClose}>
        <Controller
          name={'label'}
          control={control}
          rules={{ required: true, validate: (v: string) => !_.includes(_.map(_.filter(slideshows, (s) => s.id !== slideshowId), (s) => s.label), v) }}
          render={({ field }) => {
            return (
              <Input {...field} invalid={nameHasErrors} className={styles.nameInput} placeholder={`Enter your slideshow name here...`} onChange={(e) => { field.onChange(e.target.value) }} maxLength={200}></Input>
            )
          }}
        />
        <TippyReact config={{ disabled: !hasErrors }} content={nameHasErrors ? (errors.label?.type == 'required' ? 'Name is required!' : 'Slideshow name already exists!') : 'There are validation errors in your slides!'}><div><LoadingButton disabled={hasErrors} loading={ncSlideshowSaveMutation.isLoading} onClick={() => { onSave() }} text={isNew ? 'create' : 'save'} /></div></TippyReact>
      </FullPageModalHeader>
      <FullPageModalContent isLoading={loading}>
        <FullPageModalContentPart flex={3} cssStyle={{ display: 'flex', flexDirection: 'column' }} mainPart>
          <NcSlidesManager
            {...{ magazine, profile, control, setError, clearErrors }}
          />
        </FullPageModalContentPart>
        <FullPageModalContentPart cssStyle={{ overflowY: 'auto', minWidth: '250px' }} withBackground>
          <FullPageModalSettings>
            <NcSlideshowPlan
              {...{ control }}
            />
            <FullPageModalSettingsSection title={'slideshow target'} separator>
              <FullPageModalSettingsSectionRow>
                <div className={styles.label}>Device groups</div>
                <div className={styles.targetingBox}>
                  {ncGroupsQuery.isFetching ? <OrtecLoader size={'small'} /> :
                    _.isEmpty(ncGroupsQuery.data) ? <div className={''}>There are no groups</div> :
                      <Controller
                        name={'group_ids'}
                        control={control}
                        render={({ field }) => {
                          return (
                            <>
                              {_.map(ncGroupsQuery.data || [], (g) => {
                                return (
                                  <div key={g.id} className={styles.targetingBoxRow}>
                                    <input id={`ncGroup-${g.id}`} type="checkbox" checked={_.includes(field.value, g.id)} onChange={(e) => {
                                      if (e.target.checked) {
                                        field.onChange(_.isEmpty(field.value) ? [g.id] : [...field.value, g.id])
                                      } else {
                                        field.onChange(_.filter(field.value, (v) => g.id !== v))
                                      }
                                    }} />
                                    <label htmlFor={`ncGroup-${g.id}`}> {g.label}</label>
                                  </div>
                                )
                              })}
                            </>
                          )
                        }}
                      />
                  }
                </div>
              </FullPageModalSettingsSectionRow>
              <FullPageModalSettingsSectionRow>
                <div className={styles.label}>Single Devices</div>
                <div className={styles.targetingBox}>
                  {ncDevicesQuery.isFetching ? <OrtecLoader size={'small'} /> :
                    _.isEmpty(ncDevicesQuery.data) ? <div className={''}>There are no devices</div> :
                      <Controller
                        name={'device_ids'}
                        control={control}
                        render={({ field }) => {
                          return (
                            <>
                              {_.map(ncDevicesQuery.data || [], (d) => {
                                return (
                                  <div key={d.id} className={styles.targetingBoxRow}>
                                    <input id={`ncDevice-${d.id}`} type="checkbox" checked={_.includes(field.value, d.id)} onChange={(e) => {
                                      if (e.target.checked) {
                                        field.onChange(_.isEmpty(field.value) ? [d.id] : [...field.value, d.id])
                                      } else {
                                        field.onChange(_.filter(field.value, (v) => d.id !== v))
                                      }
                                    }} />
                                    <label htmlFor={`ncDevice-${d.id}`}> {d.label}</label>
                                  </div>
                                )
                              })}
                            </>
                          )
                        }}
                      />
                  }
                </div>
              </FullPageModalSettingsSectionRow>
            </FullPageModalSettingsSection>
          </FullPageModalSettings>
        </FullPageModalContentPart>
      </FullPageModalContent>
    </FullPageModal>
  )
}
