import { Newsletter, NlTemplate, NlTemplateConfiguration, NlTemplateConfiguration_Content, NlTemplateConfiguration_Edition, NlTemplateConfiguration_Footer, NlTemplateConfiguration_Header, NlTemplateConfiguration_Wrapper, NlTemplatesetTemplateConfigurations, NlTemplatesetWithTemplateConfigurations } from "./domain";

export const DEFAULT_CONFIGURATION_TEMPLATE: NlTemplateConfiguration = {
  templateset: -1,
  language: 'en',
  version: 3,
}

export const DEFAULT_CONFIGURATION_HEADER: NlTemplateConfiguration_Header = {
  ...DEFAULT_CONFIGURATION_TEMPLATE,
  image: {
    enabled: true,
    src: undefined
  },
  openInBrowserLink: {
    enabled: true,
    text: 'Open in browser',
    backgroundColor: '#E5E5E5',
    textColor: '#666666',
    fontSize: 'small'
  },
  downloadAppLink: {
    enabled: true,
    text: 'Download app',
    backgroundColor: '#E5E5E5',
    textColor: '#666666',
    fontSize: 'small'
  },
  introductionText: {
    enabled: true,
    text: '',
    backgroundColor: '#F6F6F6',
    textColor: '#222222',
    fontSize: 'medium'
  },
}

export const DEFAULT_CONFIGURATION_FOOTER: NlTemplateConfiguration_Footer = {
  ...DEFAULT_CONFIGURATION_TEMPLATE,
  image: {
    enabled: true,
    src: undefined
  },
  footerText: {
    enabled: true,
    text: '',
    backgroundColor: '#FFFFFF',
    textColor: '#222222',
    fontSize: 'small'
  },
  unsubscribeLink: {
    enabled: false,
    text: 'Unsubscribe',
    backgroundColor: '#FFFFFF',
    textColor: '#666666',
    fontSize: 'small'
  },
}

export const DEFAULT_CONFIGURATION_CONTENT: NlTemplateConfiguration_Content = {
  ...DEFAULT_CONFIGURATION_TEMPLATE,
  articleTitle: {
    enabled: true,
    backgroundColor: '#FFFFFF',
    textColor: '#002E67',
    fontSize: 'large'
  },
  articleDescription: {
    enabled: true,
    backgroundColor: '#FFFFFF',
    textColor: '#222222',
    fontSize: 'medium'
  },
  articleDate: {
    enabled: true,
    backgroundColor: '#FFFFFF',
    textColor: '#666666',
    fontSize: 'small'
  },
  readMoreLink: {
    enabled: true,
    text: `Read More`,
    backgroundColor: '#FFFFFF',
    textColor: '#EE7D11',
    fontSize: 'small'
  }
}

export const DEFAULT_CONFIGURATION_WRAPPER: NlTemplateConfiguration_Wrapper = {
  ...DEFAULT_CONFIGURATION_TEMPLATE,
  font_family: 'Roboto'
}

export const DEFAULT_CONFIGURATION_EDITION: NlTemplateConfiguration_Edition = {
  ...DEFAULT_CONFIGURATION_TEMPLATE,
  logo: undefined,
  primaryColor: '#3C5E90',
  secondaryColor: '#3C5E90'
}

export const DEFAULT_TEMPLATE: NlTemplate = {
  id: -1,
  name: '',
  type: 'wrapper',
  template: '',
  configuration: ''
}


export const DEFAULT_CONFIGURATIONS: NlTemplatesetTemplateConfigurations = {
  wrapper: { ...DEFAULT_CONFIGURATION_WRAPPER },
  header: { ...DEFAULT_CONFIGURATION_HEADER },
  footer: { ...DEFAULT_CONFIGURATION_FOOTER },
  content: { ...DEFAULT_CONFIGURATION_CONTENT },
  edition: { ...DEFAULT_CONFIGURATION_EDITION },
  linkedEditions: { ...DEFAULT_CONFIGURATION_TEMPLATE },
  landingPage: { ...DEFAULT_CONFIGURATION_TEMPLATE },
  unsubscribePage: { ...DEFAULT_CONFIGURATION_TEMPLATE },
}

export const DEFAULT_TEMPLATESET_WITH_CONFIGURATIONS: NlTemplatesetWithTemplateConfigurations = {
  id: -1,
  name: '',
  mail_sender_name: '',
  mail_sender_email: '',
  mail_subject: '',
  width: 680,
  language: 'en',
  templates: {
    wrapper: { ...DEFAULT_TEMPLATE, type: 'wrapper' },
    header: { ...DEFAULT_TEMPLATE, type: 'header' },
    footer: { ...DEFAULT_TEMPLATE, type: 'footer' },
    content1: { ...DEFAULT_TEMPLATE, type: 'content1' },
    content2: { ...DEFAULT_TEMPLATE, type: 'content2' },
    edition: { ...DEFAULT_TEMPLATE, type: 'edition' },
    linkedEditions: { ...DEFAULT_TEMPLATE, type: 'linkedEditions' },
    landingPage: { ...DEFAULT_TEMPLATE, type: 'landingPage' },
    unsubscribePage: { ...DEFAULT_TEMPLATE, type: 'unsubscribePage' },

  },
  configurations: { ...DEFAULT_CONFIGURATIONS }
}

export const DEFAULT_NEWSLETTER: Newsletter = {
  id: -1,
  magazine: -1,
  name: '',
  cdate: '',
  configuration: '',
  status: 'draft',
  layout: [1],
  slotItems: [],
  audienceTargeting: {
    audiences: [],
    combineAudiencesWithAnd: false
  },
  tags: [],
  edition: false
}