import React, { Component, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import './css/PushNotifications.min.css'

import { NotificationScheduler } from './NotificationScheduler';
import { HistoryTable } from './HistoryTable';
import { Profile } from '../../../../../domain';
import { ArticleSelector } from '../../../../components';
import NotificationDetails from './NotificationDetails';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as ph from '../../../../utils/routerHelper';

export interface SelectedArticle {
  id: number,
  source: number,
  title: string
}

export interface Props extends RouteComponentProps {
  magazine: string,
  profile: Profile,
}
export interface State {
  isArticleSelectorOpen?: boolean,
  selectedArticle?: SelectedArticle,
  historyLastUpdate: number
}

export class PushNotifications extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      historyLastUpdate: moment().valueOf()
    };
  }
  clickNotificationHandler(notification: number) {
    ph.setQueryParams(this.props.history, { notification });
  }
  closeNotificationDetailsHandler(updateOverview?: boolean) {
    ph.setQueryParams(this.props.history, { notification: undefined });
    if (updateOverview) {
      this.refreshHistoryHandler();
    }
  }
  openArticleSelectorHandler() {
    this.setState({ isArticleSelectorOpen: true });
  }
  closeArticleSelectorHandler() {
    this.setState({ isArticleSelectorOpen: false });
  }
  selectArticleSelectorHandler(articleId: number, sourceId: number, title: string) {
    this.setState({ selectedArticle: { id: articleId, source: sourceId, title: title } });
  }
  clearArticleHandler() {
    this.setState({ selectedArticle: undefined });
  }
  refreshHistoryHandler() {
    this.setState({ historyLastUpdate: moment().valueOf() });
  }
  render() {
    const { magazine, profile } = this.props;
    const shownNotification = ph.getQueryParamNumber(this.props.location, 'notification');;
    const { isArticleSelectorOpen, selectedArticle, historyLastUpdate } = this.state;
    return (
      <Fragment>
        {shownNotification ?
          <NotificationDetails magazine={magazine} profile={profile} notificationId={shownNotification} closeHandler={this.closeNotificationDetailsHandler.bind(this)} />
          :
          ''
        }
        {isArticleSelectorOpen ?
          <ArticleSelector magazine={magazine} profile={profile} closeHandler={this.closeArticleSelectorHandler.bind(this)} selectHandler={this.selectArticleSelectorHandler.bind(this)} limit={1000} variationLanguage={profile.magazineLanguages?.primary} />
          :
          ''
        }
        <div id="PushNotifications" className={(shownNotification || isArticleSelectorOpen) ? 'limitedHeight' : ''} /*style={{ display: (shownNotification || isArticleSelectorOpen) ? 'none' : 'block' }}*/>
          <div style={{ flex: 1, minWidth: '370px', maxWidth: '450px' }}>
            <NotificationScheduler magazine={magazine} profile={profile} openArticleSelectorHandler={this.openArticleSelectorHandler.bind(this)} selectedArticle={selectedArticle} clearArticleHandler={this.clearArticleHandler.bind(this)} refreshHistoryHandler={this.refreshHistoryHandler.bind(this)} />
          </div>
          <div style={{ flex: 2, paddingLeft: '20px' }}>
            <HistoryTable key={`history-${historyLastUpdate}`} magazine={magazine} profile={profile} clickNotificationHandler={this.clickNotificationHandler.bind(this)} />
          </div>
        </div>
      </Fragment>

    );
  }
}
export const PushNotificationsWithrRouter = withRouter(PushNotifications);
export default PushNotifications;
