import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { Button } from 'reactstrap'

import { Profile } from '../../../../../domain'
import { API } from '../../../../apis'
import { EdErrorHandler, OrtecLoader, Swal, SwalDelete, SwalError, SwalSuccess } from '../../../../widgets'
import { BatchAction, CSVAnalysis, FieldConfig, ImportProgress, SearchConfig, SearchParams, SortConfig } from './domain'
import PeopleOverviewTable from './PeopleOverviewTable'
import styles from "./People.module.scss";
import moment from 'moment';
import * as ph from '../../../../utils/routerHelper';
import PeopleEditModal from './PeopleEditModal'
import PeopleImportModal from './PeopleImportModal'
import { DateHelper } from '../../../../utils'

const FileDownload = require('js-file-download');

export interface Props extends RouteComponentProps {
    magazine: number,
    propProfile: Profile,
}

export default function People({ magazine, propProfile, location, history }: Props) {

    const [configLoading, setConfigLoading] = useState<boolean>(true);
    const [audienceLoading, setAudienceLoading] = useState<boolean>(true);
    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const [peopleConfig, setPeopleConfig] = useState<FieldConfig[]>([]);
    const [audiences, setAudiences] = useState<any[]>(propProfile.audiences || []);
    const [profiles, setProfiles] = useState<any[]>([]);
    const [totalProfiles, setTotalProfiles] = useState<number>(0);

    const [searchConfig, setSearchConfig] = useState<SearchConfig>({
        field: 'uid',
        fieldType: 'text',
        operator: 'c',
        val: '',
        includeAnonymous: false
    });

    const [sortConfig, setSortConfig] = useState<SortConfig>({
        field: 'modified',
        order: 'desc'
    });

    const [currentPage, setCurrentPage] = useState<number>(1);

    // const editProfile = ph.getQueryParam(location,'uid');
    const [editProfile, setEditProfile] = useState(undefined);

    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [exportProgress, setExportProgress] = useState<number>(0);

    const [importModal, setImportModal] = useState(false);
    const [importLoading, setImportLoading] = useState(false);
    const [importProgress, setImportProgress] = useState<ImportProgress>();

    const feeds = propProfile.feeds || [];

    const loading = configLoading || audienceLoading;

    const sizePerPage = 20;

    useEffect(() => {
        loadDependencies();
    }, [])

    useEffect(() => {
        loadProfiles();
    }, [searchConfig.field, searchConfig.operator, searchConfig.val, searchConfig.includeAnonymous, sortConfig.field, sortConfig.order])

    const loadDependencies = async () => {
        loadPeopleConfig();
        loadAudiences();
        // await loadFeeds();
    }


    const loadAudiences = async () => {
        try {
            setAudienceLoading(true);
            const { data } = await API.audiences.getAudiences(magazine);
            setAudiences(data);
        } catch (error) {
            EdErrorHandler(error, 'getting audiences');
            setAudiences([])
        } finally {
            setAudienceLoading(false);
        }
    }

    const loadPeopleConfig = async () => {
        try {
            setConfigLoading(true);
            const { data } = await API.people.loadPeopleConfig(magazine);
            setPeopleConfig(data);
        } catch (error) {
            EdErrorHandler(error, `loading people config`);
        } finally {
            setConfigLoading(false);
        }
    }

    const calcSearchParams = (forceParams?: Partial<SearchParams>): SearchParams => {
        const searchParams: SearchParams = {
            offset: 0,
            limit: sizePerPage,
            sort: `${sortConfig.order == 'desc' ? '-' : ''}${sortConfig.field}`,
            anonymous: searchConfig.includeAnonymous ? null : 0,
            q: searchConfig.val || undefined,
            q_f: searchConfig.val ? searchConfig.field : undefined,
            q_o: searchConfig.val ? searchConfig.operator : undefined,
            q_t: searchConfig.val ? searchConfig.fieldType : undefined,
        };
        return { ...searchParams, ...forceParams };
    }

    const loadProfiles = async (forceParams?: Partial<SearchParams>) => {
        try {
            setDataLoading(true);
            const { data } = await API.people.getProfiles(magazine, calcSearchParams(forceParams));
            setProfiles(data.profiles);
            setTotalProfiles(data.total);
            if (!forceParams || !forceParams.offset) {
                setCurrentPage(1);
            }
        } catch (error) {
            EdErrorHandler(error, `loading profiles`);
        } finally {
            setDataLoading(false);
        }
    }

    const onCurrentPageChange = async (page: number) => {
        await loadProfiles({ offset: (page - 1) * sizePerPage })
        setCurrentPage(page);
    }
    // const people: any[] = _.map(new Array(100).fill(null), (i, index) => {
    //     return {
    //         uid: `uid - ${index + 1}`,
    //         'accounts.0.username': `name - ${index + 1}`,
    //         'attributes.editor': index % 5 ? false : true,
    //         status: index % 3 ? true : false,
    //     }
    // });

    const dataFormmatter = (cell: any, row: any, rowIndex: any, extraData: any) => {
        const val = _.get(row, extraData.key);
        switch (extraData.type) {
            case 'checkbox':
                return (val == true) ? <span className='material-icons'>check</span> : '';
            case 'date':
                return val ? DateHelper.dateTimeToLocaleString(val) : '';
            default:
                return val;
        }
    }

    const onBatchAction = async (action: BatchAction, selectedProfiles: any[]) => {
        switch (action) {
            case 'enable':
            case 'disable':
                return toggleProfiles(selectedProfiles, action);
            case 'resetPassword':
                return resetPasswords(selectedProfiles);
            default:
                SwalError.fire({
                    title: 'Something went wrong!',
                    text: `Batch action <${action}> is not supported!`,
                });
        }
    }

    const toggleProfiles = async (selectedProfiles: any[], action: 'enable' | 'disable') => {
        const enableMode = (action == 'enable');

        const { value: confirm } = await Swal.fire({
            title: `Batch Action: ${enableMode ? 'Enable' : 'Disable'} Profiles`,
            text: `Are you sure you want to ${enableMode ? 'enable' : 'disable'} ${selectedProfiles.length} profiles?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: `Yes, ${enableMode ? 'enable' : 'disable'} them!`,
            focusCancel: true,
        });
        if (!confirm) {
            return;
        }

        const promises = _.map(selectedProfiles, (p) => enableMode ? API.people.saveProfile(magazine, { uid: p.uid, status: true }) : API.people.disableProfile(magazine, p.uid));
        try {
            setDataLoading(true);
            await Promise.all(promises);
            await loadProfiles();
            showSuccessActionMessage(`${selectedProfiles.length} selected profiles have been succussfully ${enableMode ? 'enabled' : 'disabled'}!`);
        } catch (error) {
            EdErrorHandler(error, `${enableMode ? 'enabling' : 'disabling'} selected profiles`);
        } finally {
            setDataLoading(false);
        }
    }
    const resetPasswords = async (selectedProfiles: any[]) => {
        const { value: confirm } = await Swal.fire({
            title: `Batch Action: Reset Passwords`,
            text: `Are you sure you want to reset passwords of ${selectedProfiles.length} profiles?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: `Yes, reset them!`,
            focusCancel: true,
        });
        if (!confirm) {
            return;
        }
        try {
            setDataLoading(true);
            const promises = _.map(selectedProfiles, (p) => resetProfilePassword(p));

            const responses = await Promise.all(promises);

            const successes = _.filter(responses, (r) => !r.error);
            const errors = _.map(_.filter(responses, (r) => r.error), 'uid');

            if (successes.length == selectedProfiles.length) {
                showSuccessActionMessage(`Passwords for all ${selectedProfiles.length} selected profiles have been sucessfully reset!`);
            } else if (errors.length == selectedProfiles.length) {
                SwalError.fire({
                    title: 'Something went wrong!',
                    text: `Passwords for all ${selectedProfiles.length} selected profiles have NOT been reset!`,
                    footer: `<div class="alert alert-danger">Please ensure that <code>${getUsernameLabel()}</code> is not empty and they have at least one valid email address on an email field!</div>`
                });
                await loadProfiles();
            } else {
                Swal.fire({
                    title: `Warning!`,
                    type: `warning`,
                    text: `Passwords for only ${successes.length} out of the ${selectedProfiles.length} selected profiles have been reset!`,
                    footer: `<div class="alert alert-warning">Please check the following profiles and ensure that <code>${getUsernameLabel()}</code> is not empty and they have at least one valid email address on an email field! <hr></hr> <p>${errors.join(', ')}</p> </div>`
                })
                await loadProfiles();
            }

        } catch (error) {
            EdErrorHandler(error, `resetting passwords of selected profiles`);
        } finally {
            setDataLoading(false);
        }

    }

    const resetProfilePassword = async (profile: any): Promise<{ error: any, uid: string }> => {
        const { uid } = profile;
        try {
            const username = _.get(profile, 'accounts.0.username');
            if (!username) {
                return { error: `No username!`, uid };
            }
            await API.people.resetPassword(magazine, username);
            return { error: false, uid }
        } catch (error) {
            return { error, uid }
        }
    }

    const enableProfile = async (profile: any) => {
        const p = {
            uid: profile.uid,
            status: true,
        }
        try {
            setDataLoading(true);
            await API.people.saveProfile(magazine, p);
            await loadProfiles();
            showSuccessActionMessage(`Profile has been successfully enabled!`);
        } catch (error) {
            EdErrorHandler(error, `enabling profile`);
        } finally {
            setDataLoading(false);
        }
    }

    const disableProfile = async (profile: any) => {
        try {
            setDataLoading(true);
            await API.people.disableProfile(magazine, profile.uid);
            await loadProfiles();
            showSuccessActionMessage(`Profile has been successfully disabled!`);
        } catch (error) {
            EdErrorHandler(error, `disabling profile`);
        } finally {
            setDataLoading(false);
        }
    }

    const getUsernameLabel = (): string => {
        const label = _.get(_.find(peopleConfig, { key: 'accounts.0.username' }), 'label');
        return label || 'accounts.0.username';
    }

    const onResetPassword = async (profile: any) => {
        const username = _.get(profile, 'accounts.0.username');
        if (!username) {
            SwalError.fire({
                title: 'No Username!',
                text: `Username is missing for uid: ${[profile.uid]}`,
                footer: `<div class="alert alert-danger">Please ensure that <code>${getUsernameLabel()}</code> is not empty!</div>`
            });
            return;
        }

        const { value: confirm } = await SwalDelete.fire({
            title: 'Are you sure?',
            text: `This action will reset password for username: ${username}`,
            showCancelButton: true,
            confirmButtonText: 'Yes, reset it!',
            focusCancel: true,
        });

        if (!confirm) {
            return;
        }

        try {
            setDataLoading(true);
            await API.people.resetPassword(magazine, username);
            showSuccessActionMessage(`Password has been reset successfully!`);
        } catch (error) {
            EdErrorHandler(error, `resetting password for username: ${username}`);
        } finally {
            setDataLoading(false);
        }
    }

    const showSuccessActionMessage = (message: string) => {
        SwalSuccess.fire({
            title: 'Success!',
            text: message,
            showConfirmButton: false,
            timer: 1000,
        });
    }

    const peopleConfigToColumns = (): ColumnDescription[] => {
        const leftDefaultColumns: ColumnDescription[] = [
            {
                dataField: 'status',
                sort: true,
                text: 'Enabled',
                headerStyle: { width: '80px' },
                headerClasses: `fixedColumn fixed1`,
                classes: 'fixedColumn fixed1',
                formatExtraData: { key: 'status', type: 'checkbox' },
                onSort: (field: string, order: 'asc' | 'desc') => {
                    setSortConfig({ field, order });
                },
                formatter: (cell, row) => <div className={'toggleWrapper'} onClick={(e) => { e.preventDefault(); e.stopPropagation(); return false; }}><Toggle checked={cell} onChange={(e) => { e.target.checked ? enableProfile(row) : disableProfile(row) }} /></div>,
            } as ColumnDescription
        ];

        const configColumns: ColumnDescription[] = _.compact(_.map(peopleConfig, (f) => {
            if (f.disabled) {
                return null;
            }
            return {
                dataField: f.key,
                sort: true,
                text: f.label,
                hidden: !f.showInOverview,
                formatExtraData: { key: f.key, type: f.type },
                onSort: (field: string, order: 'asc' | 'desc') => {
                    setSortConfig({ field, order });
                },
                formatter: dataFormmatter
            }
        }));

        const rightDefaultColumns: ColumnDescription[] = [
            {
                dataField: 'dfActions',
                isDummyField: true,
                sort: false,
                text: '',
                headerStyle: { width: '0px' },
                headerClasses: `fixedColumn fixedLast`,
                classes: 'fixedColumn fixedLast actionsColumn',
                formatter: (cell, row) => <div className='actionHoveringDiv'><Button className='secondary' onClick={(e) => { e.preventDefault(); e.stopPropagation(); onResetPassword(row) }}>reset password</Button></div>
            }
        ]

        //return [...leftDefaultColumns, ...configColumns, ...rightDefaultColumns];
        return [...leftDefaultColumns, ...configColumns]; //TODO: Reuse the rightDefaultColumns when keycloak sync is back
    }

    const columns = peopleConfigToColumns();

    const onEditModalClose = () => {
        ph.setQueryParams(history, { uid: undefined });
        setEditProfile(undefined);

    }

    const onProfileEdit = (p: any) => {
        ph.setQueryParams(history, { uid: p.uid });
        setEditProfile(p);
    }
    const onProfileCreate = () => {
        onProfileEdit({ uid: '-1', status: true });
    }

    const typeDefaultValue = (type: string) => {
        switch (type) {
            case 'checkbox':
                return undefined;
            case "arrayText":
            case "Multiple texts":
                return [];
            case "password":
                return undefined;
            default:
                return '';
        }
    }

    const smoothenProfile = (profile: any) => {
        const username = _.get(profile, 'accounts.0.username');
        const uid = profile.uid ? profile.uid : username;
        _.set(profile, 'uid', _.trim(uid));
        _.set(profile, 'accounts.0.username', _.trim(username));
    }

    const removeNonEditables = (profile: any) => {
        const { uid, isNewEntry } = profile;
        const p: any = {
            uid,
            isNewEntry
        };
        _.map(_.filter(peopleConfig, (f: FieldConfig) => (!f.disabled && f.isEditable) || f.key === 'anonymous'), (f: FieldConfig) => {
            p[f.key] = _.get(profile, f.key, typeDefaultValue(f.type));
        });
        smoothenProfile(p);
        return p;
    }

    const onProfileSave = async (profile: any) => {
        try {
            setDataLoading(true);
            const p = removeNonEditables(profile);
            await API.people.saveProfile(magazine, p);
            if(profile.status === false) {
                //Need to use disable endpoint because post-api takes status true
                //as default on save.
                await API.people.disableProfile(magazine, profile.uid);
            }
            await loadProfiles();
            onEditModalClose();
            showSuccessActionMessage(`Profile has been saved successfully!`);
        } catch (error) {
            EdErrorHandler(error, `saving profile`);
        } finally {
            setDataLoading(false);
        }
    }

    //
    // ─── CSV EXPORT  ────────────────────────────────────────────────────────────────────────
    //

    const onExport = async (all?: boolean) => {

        let totalProfilesForExport = totalProfiles;

        if (all) {

            const { data } = await API.people.getProfiles(magazine, { limit: 1 });
            totalProfilesForExport = data.total ? data.total : totalProfiles;
        }

        const { value: confirm } = await Swal.fire({
            title: 'Are you sure?',
            type: 'info',
            text: all ? `You are about to export all (${totalProfilesForExport}) profiles in this magazine sorted by uid. Filters will not apply to this export!` : `You are about to export all (${totalProfilesForExport}) profiles based on your current search!`,
            showCancelButton: true,
            confirmButtonText: 'Yes, export them!',
            focusCancel: true,
        });

        if (!confirm) {
            return;
        }

        try {
            setExportLoading(true);
            setExportProgress(0);
            const { data } = await API.people.exportProfilesStream(magazine, all ? null : calcSearchParams({ limit: undefined }), (fetched: number) => {
                const progress = _.round((fetched * 100) / totalProfilesForExport);
                setExportProgress(progress);
            });
            FileDownload(data, `profiles-${magazine}.csv`);
            SwalSuccess.fire({
                title: 'Success!',
                text: `${totalProfilesForExport} profiles have been exported successfully!`
            });
        } catch (error) {
            EdErrorHandler(error, `export ${all ? `all` : 'CSV'}`);
        } finally {
            setExportLoading(false);
        }
    }

    const onImportModalOpen = () => {
        setImportModal(true);
    }
    const onImportModalClose = () => {
        setImportModal(false);
    }

    const csvAnalysisReport = (analysis: CSVAnalysis): string => {
        const csvLabels = _.compact(_.map(peopleConfig, 'csvLabel'));
        const unknown = _.filter(analysis.nonemptyColumns, (c) => !_.includes(csvLabels, c));
        const known = _.filter(analysis.nonemptyColumns, (c) => _.includes(csvLabels, c));
        const alertType = _.isEmpty(unknown) && _.isEmpty(analysis.emptyColumns) && _.includes(known, 'uid') ? 'success' : 'warning';

        const knownColumns = _.isEmpty(known) ? `` : `<li>Known Columns: ${_.size(known)} <hr/><code>${known.join()}</code></li>`;
        const unknownCoulumns = _.isEmpty(unknown) ? `` : `<li>Unknown Columns: ${_.size(unknown)} (They will be ignored) <hr/><code>${unknown.join()}</code><div class="extraInfo"><i class="material-icons">info</i>Column names must match with CSV labels in <b>People Config</b></div></li>`;
        const emptyColumns = _.isEmpty(analysis.emptyColumns) ? `` : `<li>Empty Columns: ${_.size(analysis.emptyColumns)} (They will be ignored)</li>`;

        const totalRows = `<li>Total Profile Rows: ${analysis.rows}</li>`;
        const totalColumns = `<li>Total Columns: ${_.size(analysis.columns)} <ul class="sublist">${knownColumns}${unknownCoulumns}${emptyColumns}</ul></li>`;
        const uidError = _.includes(known, 'uid') ? `` : `<div class="analysis alert alert-danger"><code>uid</code> column is missing. Nothing will be imported!</div>`;

        const report = `<div class="analysis alert alert-${alertType}">${uidError}<ul>${totalRows}${totalColumns}</ul></div>`;
        return report;

    }

    const checkImportCSV = async (magazie: number, data: any): Promise<number | undefined> => {
        try {
            const { data: analysis } = await API.people.checkImportCSV(magazine, data);

            const { value: confirm } = await Swal.fire({
                type: 'info',
                title: `CSV analysis`,
                text: `Check the CSV analysis below! Are you sure you want to procceed?`,
                showCancelButton: true,
                confirmButtonText: `Confirm`,
                focusCancel: true,
                footer: csvAnalysisReport(analysis)
            });

            return confirm ? analysis.rows : undefined;
        } catch (error) {
            EdErrorHandler(error, `checking import CSV`);
            return undefined;
        }
    }

    const onImport = async (file: File) => {

        try {
            setImportLoading(true);
            const data = new FormData();
            data.append('file', file, file.name);

            const totalProfilesSent = await checkImportCSV(magazine, data);
            if (!totalProfilesSent) {
                return;
            }

            setImportProgress({ total: totalProfilesSent, imported: 0 });

            // importResults here is a comma separated list of stringified user-api results.
            const { data: importResults } = await API.people.importProfilesStream(magazine, data, (imported: number) => {
                setImportProgress({ total: totalProfilesSent, imported });
            });
            const succeed = calcSuccessfulResults(importResults);
            if (succeed == 0) {
                SwalError.fire({
                    title: 'Error!',
                    text: `No profiles have been imported or updated.`
                })
            } else if (succeed < totalProfilesSent) {
                Swal.fire({
                    type: 'warning',
                    title: 'Warning!',
                    text: `Only ${succeed} out of ${totalProfilesSent} profiles have been successfully imported or updated.`
                })
            } else {
                SwalSuccess.fire({
                    title: 'Success!',
                    text: `${succeed} ${succeed == 1 ? 'profile has' : 'profiles have'} been successfully imported or updated.`
                });
            }

            loadProfiles();
            setImportModal(false);

        } catch (error) {
            EdErrorHandler(error, `importing profiles`);
        } finally {
            setImportLoading(false);
            setImportProgress(undefined);
        }

    }

    return (
        <div className={styles.People}>
            {loading ? <OrtecLoader /> :
                <Fragment>
                    <PeopleOverviewTable {...{ data: profiles, totalData: totalProfiles, columns, searchConfig, setSearchConfig, sizePerPage, sortConfig, setSortConfig, currentPage, onCurrentPageChange, keyField: 'uid', onRowClick: onProfileEdit, onCreateNew: onProfileCreate, onBatchAction, exportLoading, exportProgress, onExport, onImportModalOpen }} />
                    {dataLoading && <OrtecLoader />}
                    {editProfile && <PeopleEditModal {...{ magazine, peopleConfig, editProfile, audiences, feeds }} onSave={onProfileSave} onClose={onEditModalClose} />}
                    {importModal && <PeopleImportModal loading={importLoading} onImport={onImport} onClose={onImportModalClose} importProgress={importProgress} />}
                </Fragment>
            }
        </div>
    )
}


export const PeopleWithRouter = withRouter(People);

// ─── Helper Functions ────────────────────────────────────────────────────────

/**
 * The resultString here is a comma separated list of stringified user-api results. 
 * Each user-api result has a value of {status: "ok"} if it is a success or {error:"xyz"} if it is a fail.
 * Translating the string into array and counting the status: "ok" elementes conveys the number of the successfully
 * imported/updated profiles.
 * @param resultsString 
 * @returns {number} Number of succesful results
 */
const calcSuccessfulResults = (resultsString: string): number => {
    if (!resultsString) {
        return 0;
    }
    const results = resultsString.split(',');
    return _.size(_.filter(results, (r) => {
        const j = JSON.parse(r);
        return j.status == 'ok';
    }));
}